import Constants from "~/helpers/enums/Constants";
import DashGraphEnum from "~/components/dashboard/common/DashGraphEnum";

const mountState = (state, checked = false) => {
    return { [state.name]: { ...state, checked } };
};

const INITIAL_STATES = {
    ...mountState(DashGraphEnum.KM_RUN, true),
    ...mountState(DashGraphEnum.PERIOD_TRIPS, true),
    ...mountState(DashGraphEnum.ROAD_OFFSET, true),
    ...mountState(DashGraphEnum.TOP_TEN_DRIVER_VEHICLE, true),
    ...mountState(DashGraphEnum.TOP_TEN_MAX_VELOCITY_VEHICLE, true),
    ...mountState(DashGraphEnum.TOP_TEN_MAX_VELOCITY_DRIVER, true),
    ...mountState(DashGraphEnum.ANALYSIS_ITEMS_TITLE),
    ...mountState(DashGraphEnum.TOP_TEN_ANALYZE_DRIVER_VEHICLE),
    ...mountState(DashGraphEnum.DRIVABILITY_SCORE),
    ...mountState(DashGraphEnum.RISK_EXPOSURE_SCORE),
    ...mountState(DashGraphEnum.KM_RUN_PERIOD_WEEK, true),
    ...mountState(DashGraphEnum.FLEET_EVOLUTION),
    ...mountState(DashGraphEnum.ACCIDENT_PROBABILITY),
    // ...mountState(DashGraphEnum.KM_RUN_BUSINESS_HOURS, true),
    // ...mountState(DashGraphEnum.KM_RUN_OUTSIDE_HOURS, true),
    // ...mountState(DashGraphEnum.KM_RUN_WEEKEND, true),
};

function dashboardToolbarReducer(state = INITIAL_STATES, action = null) {
    switch (action.type) {
        case Constants.TOGGLE_MARKED_FILTER:
            return {
                ...state,
                [action.name]: {
                    ...state[action.name],
                    checked: action.checked,
                },
            };
        default:
            return state;
    }
}

export default dashboardToolbarReducer;
