import http from "~/config/httpRequest/http";

const saveGroup = (req) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/GrupoPontoInteresseApi/SalvarGrupoInteresse`, req)
			.then((res) => resolve(res.data))
			.catch((error) => reject(error));
	});
};

const getGroupsPoints = () => {
	return new Promise((resolve, reject) => {
		http
			.get(`/GrupoPontoInteresseApi/BuscarGruposPontoInteresse`)
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const getPointsLinkedGroupById = (groupId, pagination = {}) => {
	return new Promise((resolve, reject) => {
		const req = {
			Vinculado: true,
			GrupoId: groupId,
			FiltroGrid: {
				Length: pagination.length || 10,
				Index: pagination.page || 0,
				Search: pagination.search || "",
				SearchColumns: [
					{
						Name: "Nome",
						Data: "Nome",
						OrderTable: true,
						OrderDirection: "asc"
					}
				]
			},
		};
		http
			.post(`/GrupoPontoInteresseApi/PesquisarPontosInteresseDoGrupo`, req)
			.then((res) => resolve(res.data))
			.catch((error) => reject(error));
	});
};

const getAvailablePointsForGroupById = (groupId, pagination = {}) => {
	return new Promise((resolve, reject) => {
		const req = {
			Vinculado: false,
			GrupoId: groupId,
			FiltroGrid: {
				Length: pagination.length || 10,
				Index: pagination.page || 0,
				Search: pagination.search || "",
				SearchColumns: [
					{
						Name: "Nome",
						Data: "Nome",
						OrderTable: true,
						OrderDirection: "asc"
					}
				]
			},
		};
		http
			.post(`/GrupoPontoInteresseApi/PesquisarPontosInteresseDoGrupo`, req)
			.then((res) => resolve(res.data))
			.catch((error) => reject(error));
	});
};

const saveLinkPoints = (groupId, pointsSelected = []) => {
	return new Promise((resolve, reject) => {
		const body = {
			Vinculado: false,
			GrupoId: groupId,
			PontosSelecionados: pointsSelected,
		};
		http
			.post(`/GrupoPontoInteresseApi/SalvarPontosInteresseDoGrupo`, body)
			.then((res) => resolve(res.data))
			.catch((error) => reject(error));
	});
};

const saveUnbindPoints = (groupId, pointsSelected = []) => {
	return new Promise((resolve, reject) => {
		const body = {
			Vinculado: true,
			GrupoId: groupId,
			PontosSelecionados: pointsSelected,
		};
		http
			.post(`/GrupoPontoInteresseApi/SalvarPontosInteresseDoGrupo`, body)
			.then((res) => resolve(res.data))
			.catch((error) => reject(error));
	});
};

export default {
	saveGroup,
	getGroupsPoints,
	getPointsLinkedGroupById,
	getAvailablePointsForGroupById,
	saveLinkPoints,
	saveUnbindPoints,
};
