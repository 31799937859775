export const PDF = {
	value: "pdf",
	description: "PDF",
};

export const XLS = {
	value: "xlsx",
	description: "Excel",
};

export const LIST = [PDF, XLS];

export default { PDF, XLS, LIST };
