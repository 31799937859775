import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Field } from "formik";
import ColorPicker from "~/components/common/colorPicker/ColorPicker";
import "./RegisterComponents.scss";

const RegisterColorPicker = (props) => {
	const { label, ...fieldProps } = props;

	return (
		<Box className="register-field">
			<Typography variant="subtitle1" className="register-field-label">
				{label}
			</Typography>

			<Field name={props.name}>
				{({ field, form: { touched, errors, setFieldValue }, meta }) => {
					const error = errors[props.name];
					const isTouched = touched[props.name];

					return (
						<ColorPicker
							helperText={isTouched && error}
							error={isTouched && !!error}
							fullWidth
							variant="outlined"
							margin="dense"
							{...fieldProps}
							value={field.value}
							onChange={(color) => {
								setFieldValue(props.name, color);
							}}
						/>
					);
				}}
			</Field>
		</Box>
	);
};

export default RegisterColorPicker;
