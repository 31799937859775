import {
    ComercialColorSolid,
    names,
    colors,
    colorSolid,
    getFullValue,
} from "~/components/common/dashboard/KmRunFormatterUtils";

const customChart = ({ data: { Veiculo } }) => {
    const {
        DistanciaComercialPercentual,
        DistanciaComercial,
        DistanciaNaoComercialPercentual,
        DistanciaNaoComercial,
        DistanciaFimSemanaPercentual,
        DistanciaFimSemana,
        Distancia,
        MediaDistancia,
    } = Veiculo;

    const kmRun = Object.entries({
        DistanciaComercialPercentual,
        DistanciaNaoComercialPercentual,
        DistanciaFimSemanaPercentual,
    }).map((time) => ({
        name: names(time[0].substring(9, time[0].length - 10)),
        value: time[1],
        fullValue: getFullValue(time[0].substring(9, time[0].length - 10), {
            DistanciaComercial,
            DistanciaNaoComercial,
            DistanciaFimSemana,
        }),
        itemStyle: {
            color: colors(time[0].substring(9, time[0].length - 10)),
        },
        label: {
            color: colorSolid(time[0].substring(9, time[0].length - 10)),
        },
    }));

    const payload = {
        kmRun,
        Distancia,
        MediaDistancia,
    };

    return payload;
};

const extractData = ({ data: { Veiculo }, ...rest }) => {
    const {
        DistanciaComercial,
        DistanciaComercialMedia,
        DistanciaFimSemana,
        DistanciaFimSemanaMedia,
        DistanciaNaoComercial,
        DistanciaNaoComercialMedia,
    } = Veiculo;

    const payload = {
        DistanciaComercial,
        DistanciaComercialMedia,
        DistanciaFimSemana,
        DistanciaFimSemanaMedia,
        DistanciaNaoComercial,
        DistanciaNaoComercialMedia,
    };

    return payload;
};

const emptyChart = () => {
    return {
        kmRun: [
            {
                value: 0,
                name: 0,
                fullValue: 0,
                itemStyle: {
                    color: {
                        type: "linear",
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: ComercialColorSolid,
                            },
                            {
                                offset: 1,
                                color: "#eef2f5",
                            },
                        ],
                        global: true,
                    },
                },
            },
        ],
        Distancia: 0,
        MediaDistancia: 0,
    };
};

export default { customChart, extractData, emptyChart };
