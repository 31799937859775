import http from "~/config/httpRequest/http";

const list = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/Geolocalizacaoapi/BuscarObjetosRastreaveis`, {
				Index: filter.index,
				Length: filter.limit,
				Search: filter.term,
				SearchColumns: filter.SearchColumns,
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const searchVehicleList = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Veiculo/Buscar?}`, {
				params: {
					Index: filter.page,
					Length: filter.limit,
					Search: filter.search
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const getVehicleById = (id) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Veiculo/BuscarPorId?`, {
				params: {
					id
				}
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const getOdometerById = (objetoRastreavelId) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Veiculo/BuscarOdometroPorId?`, {
				params: {
					objetoRastreavelId
				}
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const saveVehicle = (vehicle) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/Veiculo/Salvar/`, {
				...vehicle,
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const saveOdometer = (odometer) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/Veiculo/SalvarOdometro/`, {
				...odometer,
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
}

export default { list, searchVehicleList, getVehicleById, saveVehicle, getOdometerById, saveOdometer };
