import React, { useEffect, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import BaseLayout from "~/components/app/layout/BaseLayout";
import { useTranslation } from "react-i18next";
import Routers from "~/components/app/routers/Routers";
import signupAction from "~/actions/authAction";
import Utils from "~/helpers/Utils";
import Constants from "~/helpers/enums/Constants";
import Labels from "~/helpers/enums/Labels";
import "react-toastify/dist/ReactToastify.css";
import CookieConsent from "react-cookie-consent";
import AcceptTermsView from "~/views/acceptTerms/AcceptTermsView"
import profileService from "~/services/profileService";

// const VERSION = require("../../../package.json").version;
const appInfo = Utils.getAppInfo();

const appStyles = {
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  minHeight: '100vh',
};
const App = () => {
  const { isAuthenticated,infos } = useSelector((state) => state.auth); 
  const { language } = useSelector((state) => state.language); 
  const { profile } = useSelector((state) => state.profile);     
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const acceptTerm = localStorage.getItem('AceiteTermo')

  useEffect(() => {
    dispatch(signupAction.verifyCredentialsAuthentication(isAuthenticated));
    i18n.changeLanguage(language);
  }, [dispatch, isAuthenticated, language, i18n]);

  const hasTokenValid = Utils.hasTokenValid(); 
  const hasAcceptedTerms = acceptTerm !== null || infos.AceiteTermo === true;

  window.onload = () => {
    if (localStorage.getItem(Constants.TOKEN_INVALID) === "true") {
      localStorage.setItem(Constants.TOKEN_INVALID, false);

      Utils.showTranslatedToast({
        type: Constants.WARNING,
        description: Labels.SESSION_EXPIRED,
      });
    }
  };

  return (
      <Suspense fallback="loading">
          <Helmet>
              {Object.keys(appInfo).map((i) => (
                  <meta key={i} name={i} content={appInfo[i]} />
              ))}
          </Helmet>
          <CookieConsent
              location="bottom"
              buttonText="Aceitar"
              cookieName="accept-cookie"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "16px" }}
              textStyle={{ fontSize: "16px" }}
              expires={150}>
              Este site não utiliza cookies persistentes ou de terceiros, apenas aqueles essenciais à sua navegação.
              Para entender melhor o que são cookies e como levamos a sério a sua privacidade, conheça a nossa&nbsp;
              <a
                  href="https://ceabs-sites.s3.amazonaws.com/CPS/Pol%C3%ADtica+de+Privacidade_Servi%C3%A7os+Conectados+Gest%C3%A3o+de+Frotas_Latam_PT_V.1_Rev.2023.06.12+(1).pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-auth="NotApplicable"
                  title="Política de Privacidade"
                  data-linkindex="0"
                  data-ogsc=""
                  class="cookie-link">
                  Política de Privacidade.
              </a>

          </CookieConsent>
                
          {!hasAcceptedTerms && hasTokenValid? (
            <div style={appStyles}>
              <AcceptTermsView></AcceptTermsView>

              <BaseLayout.AuthenticatedLayout>
                    <Routers.Authenticated />
                </BaseLayout.AuthenticatedLayout>
            </div>

          ) : isAuthenticated || hasTokenValid ? (
              <BaseLayout.AuthenticatedLayout>
                  <Routers.Authenticated />
              </BaseLayout.AuthenticatedLayout>
          ) : (
              <BaseLayout.AnonymousLayout>
                  <Routers.Anonymous />
              </BaseLayout.AnonymousLayout>
          )}
      </Suspense>
  );
};

export default App;
