import React from "react";
import ReactEcharts from "echarts-for-react";
import { useSelector } from "react-redux";
import { getOption } from "~/components/common/defaultsCharts/defaultChartPie";
import nounDayNight from "~/assets/images/noun_day_night.svg";

export default function PeriodTripsChartPie() {
    const data = useSelector((state) => state.dashboardVehicleTravels.periodTrips);
    const { waitingTravel } = useSelector((state) => state.app.loading);

    return (
        <ReactEcharts
            option={getOption(data, nounDayNight)}
            showLoading={waitingTravel}
            loadingOption={{ text: "Carregando" }}
        />
    );
}
