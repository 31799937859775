import React from "react";

const ContractSummary = ({ vehicles = [], dueDate, onDateChange, planPrice, duration }) => {
    function formatMoney(valor) {
        return valor?.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        });
    }
    return (
        <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
            <h2>Veículo(s) Contratado(s)</h2>

            <div style={{ maxHeight: "300px", overflowY: "scroll", position: "relative" }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead style={{ position: "sticky", top: 0, backgroundColor: "#f9f9f9", zIndex: 1 }}>
                        <tr>
                            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Placa</th>
                            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Chassi</th>
                            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Valor mensal</th>
                            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Vigência</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vehicles.map((vehicle, index) => (
                            <tr key={index}>
                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>{vehicle.Placa}</td>
                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>{vehicle.Chassi}</td>
                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>{formatMoney(planPrice)}</td>
                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>{duration} meses</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div style={{ justifyContent: 'end' }}>
                <h2 style={{ marginTop: "20px" }}>
                    Total do contrato: {formatMoney(vehicles.reduce((total) => total + planPrice * duration, 0))}
                </h2>

                <div style={{ marginTop: "20px" }}>
                    <label>Selecione a melhor data para vencimento:</label>
                    <select value={dueDate} onChange={onDateChange} style={{ marginLeft: "10px", padding: "5px" }}>
                        <option value="">Selecione</option>
                        <option value="20">Dia 20</option>
                        <option value="25">Dia 25</option>
                        <option value="30">Dia 30</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default ContractSummary;
