import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Container } from "@material-ui/core";
import Page from "~/components/common/page/Page";
import AlertMain from "~/components/alert/AlertMain";
import OptionExportButton from "~/components/common/exportButton/OptionExportButton";
import registerAlertAction from "~/actions/registerAlertAction";

const AlertView = (props) => {
    const dispatch = useDispatch();
    const { alertFilter } = useSelector((state) => state.alert);

    return (
        <Page
            bottomStyle={{ justifyContent: "flex-end" }}
            topButtons={
                <div id="export-container">
                    <OptionExportButton
                        onClick={(type) => {
                            dispatch(
                                registerAlertAction.exportAlert({
                                    Status: alertFilter.status,
                                    Search: alertFilter.search,
                                    RelatorioExecucao: {
                                        Formato: type.value.toLowerCase(),
                                    },
                                })
                            );
                        }}
                    />
                </div>
            }>
            <main>
                <Container maxWidth="lg" className="container-map-view">
                    <AlertMain />
                </Container>
            </main>
        </Page>
    );
};

export default withRouter(AlertView);
