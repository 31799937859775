import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "~/components/common/expansionCard/Card";
import { PeriodPanel } from "~/components/common/reportTab/ReportPanel";
import reportingAction from "~/actions/reportingAction";
import TableCard from "~/components/tableCard/TableCardReUse";
import ProgressButton from "~/components/common/progressButton/ProgressButton";
import { DownloadIcon } from "~/components/common/icons/Icons";
import useFuso from "~/components/common/fuso/useFuso";
import Pagination from "~/components/common/pagination/Pagination";
import MenuRange from "~/components/common/menuRange/MenuRange";
import Utils from "~/helpers/Utils";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import "~/components/reporting/Reporting.scss";

const EventsPeriod = (props) => {
    const { data, pagination, page } = useSelector((state) => state.reporting.perPeriod);
    const { lastRecord } = useSelector((state) => state.eventReport);
    const dispatch = useDispatch();
    const { fromUtc } = useFuso();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(
            reportingAction.getReportPerPeriod({
                relatorioId: 22,
                ParametroInfo1: 60,
                Background: true,
                Formato: "pdf",
                ...pagination,
            })
        );

        const refreshLoop = setInterval(() => {
            dispatch(
                reportingAction.getReportPerPeriod({
                    relatorioId: 22,
                    ParametroInfo1: 60,
                    Background: true,
                    Formato: "pdf",
                    ...pagination,
                })
            );
        }, 59000);
        return () => {
            clearInterval(refreshLoop);
        };
    }, [dispatch, pagination, lastRecord]);

    const COLUMNS = [
        {
            name: "ObjetoGrupoNome",
            label: "Parâmetros",
            render: (plate) => <span className="strong-plate">{plate}</span>,
        },
        {
            name: "Formato",
            label: "Modo de Exibição",
            render: (name) => <p className="exhibition-mode-events">{name}</p>,
        },
        {
            name: "DataInicial",
            label: t(Labels.REPORT_DISTANCE_INITIAL_DATE),
            render: (date) => (
                <span>
                    {fromUtc(date, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")}
                    <span style={{ color: "#AFC3D2", margin: "auto 5px" }}>|</span>
                    {fromUtc(date, "YYYY-MM-DDTHH:mm:ss").format("HH:mm:ss")}
                </span>
            ),
        },
        {
            name: "DataFinal",
            label: t(Labels.REPORT_DISTANCE_FINAL_DATE),
            render: (date) => (
                <span>
                    {fromUtc(date, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")}
                    <span style={{ color: "#AFC3D2", margin: "auto 5px" }}>|</span>
                    {fromUtc(date, "YYYY-MM-DDTHH:mm:ss").format("HH:mm:ss")}
                </span>
            ),
        },
        {
            name: "DataHoraCadastro",
            label: t(Labels.REPORT_DISTANCE_REPORT_REGISTER_DATE),
            render: (date) => (
                <span>
                    {fromUtc(date, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")}
                    <span style={{ color: "#AFC3D2", margin: "auto 5px" }}>|</span>
                    {fromUtc(date, "YYYY-MM-DDTHH:mm:ss").format("HH:mm:ss")}
                </span>
            ),
        },
        {
            name: "Quantidade",
            label: t(Labels.REPORT_DISTANCE_REPORT_AMOUNT_VEHICLES)
        },
        {
            name: "Processado",
            label: t(Labels.REPORT_DISTANCE_PROCESSING_PERCENT),
            render: (percent, row) => (
                <span className="events-period-button">
                    <ProgressButton
                        progress={row.Processado}
                        error={row.Restante}
                        onClick={() => {
                            Utils.downloadToLink(row.Arquivo);
                        }}>
                        <DownloadIcon width={15} style={{ marginRight: 5 }} /> {t(Labels.DOWNLOAD)}
                    </ProgressButton>
                </span>
            ),
        },
    ];

    return (
        <PeriodPanel className="report-panel">
            <Card title={t(Labels.RESULTS)} className={"card-report"}>
                <div className="pagination-table-period">
                    <Pagination
                        onChange={(_, value) => {
                            dispatch(
                                reportingAction.updatePaginationPerPeriod({
                                    ...pagination,
                                    Index: value - 1,
                                })
                            );
                        }}
                        page={pagination.Index + 1}
                        count={Math.ceil(page?.recordsTotal / pagination.Length)}
                    />

                    <MenuRange
                        id={"report-period-menu-range"}
                        label={t(Labels.MENU_RANGE_SHOW)}
                        value={pagination.Length}
                        onChange={(event) => {
                            dispatch(
                                reportingAction.updatePaginationPerPeriod({
                                    ...pagination,
                                    Index: 0,
                                    Length: event.target.value,
                                })
                            );
                        }}
                        options={[10, 25, 50, 100, 250, 500]}
                    />
                </div>
                <TableCard columns={COLUMNS} dataSource={data} />
            </Card>
        </PeriodPanel>
    );
};

export default EventsPeriod;
