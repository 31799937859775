import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import FormikAutocomplete from "~/components/common/autocomplete/FormikAutocomplete";
import driverAction from "~/actions/driverAction";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { Box, Typography } from "@material-ui/core";
import { Field } from "formik";
import Labels from "~/helpers/enums/Labels";
import "../ReportingCommon.scss";
import "~/components/reporting/Reporting.scss";
const DriverAutoComplete = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { dataDriver } = useSelector((state) => state.driver);
	const { loading } = useSelector((state) => state.app);
	return (
		<Box className={`${props.isRegisterForm ? "register-field" : "report-field"}`}>
			<Typography variant="subtitle1" className={`${props.isRegisterForm ? "register-field-label" : "report-field-label"}`}>
				{props.label}
			</Typography>
			<Field
				name={props.name}
				getOptionLabel={(option) => option.text || ""}
				getOptionSelected={(option, value) => option.text === value.text}
				component={FormikAutocomplete}
				onInputChange={(_, value) => {
					dispatch(driverAction.driverData({ description: value }));
				}}
				options={dataDriver}
				noOptionsText={t(Labels.NO_DATA)}
				loading={loading.driverDataSearch}
				popupIcon={<ExpandMoreRoundedIcon viewBox="0 0 25 25" />}
				textFieldProps={{
					variant: "outlined",
					fullWidth: true,
					margin: "dense",
					InputProps: {
						placeholder: props.placeholder,
					},
					inputProps: {
						autoComplete: "nope",
					},
				}}
			/>
		</Box>
	);
};

export default DriverAutoComplete;
