import React, { forwardRef, cloneElement } from "react";

import SelectInputPopper from "./SelectInputPopper";
import formControlState from "@material-ui/core/FormControl/formControlState";
import useFormControl from "@material-ui/core/FormControl/useFormControl";
import ArrowDropDownIcon from "@material-ui/core/internal/svg-icons/ArrowDropDown";
import { styles as nativeSelectStyles } from "@material-ui/core/NativeSelect/NativeSelect";

import { mergeClasses, withStyles } from "@material-ui/styles";
import { Input, FilledInput, OutlinedInput } from "@material-ui/core";

export const styles = nativeSelectStyles;

const SelectPopper = forwardRef(function SelectPopper(props, ref) {
	const {
		autoWidth = false,
		children,
		classes,
		displayEmpty = false,
		IconComponent = ArrowDropDownIcon,
		id,
		input,
		inputProps,
		label,
		labelId,
		labelWidth = 0,
		MenuProps,
		multiple = false,
		onClose,
		onOpen,
		open,
		renderValue,
		SelectDisplayProps,
		variant: variantProps = "standard",
		...other
	} = props;

	const muiFormControl = useFormControl();
	const fcs = formControlState({
		props,
		muiFormControl,
		states: ["variant"],
	});

	const variant = fcs.variant || variantProps;

	const InputComponent =
		input ||
		{
			standard: <Input />,
			outlined: <OutlinedInput label={label} labelWidth={labelWidth} />,
			filled: <FilledInput />,
		}[variant];

	return cloneElement(InputComponent, {
		inputComponent: SelectInputPopper,
		inputProps: {
			children,
			IconComponent,
			variant,
			type: undefined,
			multiple,
			autoWidth,
			displayEmpty,
			labelId,
			MenuProps,
			onClose,
			onOpen,
			open,
			renderValue,
			SelectDisplayProps: { id, ...SelectDisplayProps },
			...inputProps,
			classes: inputProps
				? mergeClasses({
						baseClasses: classes,
						newClasses: inputProps.classes,
						Component: SelectPopper,
				  })
				: classes,
			...(input ? input.props.inputProps : {}),
		},
		ref,
		...other,
	});
});

SelectPopper.muiName = "Select";
export default withStyles(styles, { name: "MuiSelect" })(SelectPopper);
