//alerts
export const GET_ALERTS_VIOLATED = "GET_ALERTS_VIOLATED";
export const GET_ALERTS_DETAILS = "GET_ALERTS_DETAILS";
export const CLEAN_ALERTS_DETAILS = "CLEAN_ALERTS_DETAILS";
export const GET_ALERTS_HISTORY = "GET_ALERTS_HISTORY";
export const SAVE_ALERTS_DETAILS = "SAVE_ALERTS_DETAILS";
export const SET_ALERT_ID_SELECTED = "SET_ALERT_ID_SELECTED";
export const MARK_DONE_ALL_ALERTS = "MARK_DONE_ALL_ALERTS";
export const BOARD_MARK_DONE_ALERTS = "BOARD_MARK_DONE_ALERTS";

//Dashboard vehicle
export const GET_DATA_VEHICLE_INDIVIDUAL = "GET_DATA_VEHICLE_INDIVIDUAL";
export const GET_DATA_VEHICLE_MODE_PRIVATE = "GET_DATA_VEHICLE_MODE_PRIVATE";
export const SET_VEHICLE = "SET_VEHICLE";
export const DASHBOARD_VEHICLE_HIDE_ALL_FILTERS = "DASHBOARD_VEHICLE_HIDE_ALL_FILTERS";
export const DASHBOARD_VEHICLE_SHOW_CURRENT_FILTERS = "DASHBOARD_VEHICLE_SHOW_CURRENT_FILTERS";

// Header PAGE
export const FETCH_LOGIN = "FETCH_LOGIN";
export const FALSE = "FALSE";
export const TRUE = "TRUE";
export const RECOVER_LOGIN = "RECOVER_LOGIN";
export const VISIBLE_RECOVER_LOGIN = "VISIBLE_RECOVER_LOGIN";

export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_LOGIN = "UPDATE_LOGIN";

export const TOOGLE_TOOLBAR = "TOOGLE_TOOLBAR";

export const LOGOUT = "LOGOUT";
export const TOKEN = "TOKEN";
export const USER = "USER";

export const LOGIN = "LOGIN";

// Login page
export const LOGIN_TAB = 0;
export const REGISTER_TAB = 1;
export const TAB_LOGIN_FILTERS = "TAB_LOGIN_FILTERS";
export const TAB_LOGIN_FORM_UPDATE = "TAB_LOGIN_FORM_UPDATE";
export const TAB_LOGIN_RESET = "TAB_LOGIN_RESET";
export const TAB_LOGIN_FORM_UPDATE_DOCUMENT = "TAB_LOGIN_FORM_UPDATE_DOCUMENT";

// REPORTING
export const CLEAR_AUTO_COMPLETE = "CLEAR_AUTO_COMPLETE";
export const CLEAR_ALL = "CLEAR_ALL";
export const GET_REPORTING_PER_PERIOD = "GET_REPORTING_PER_PERIOD";
export const UPDATE_PAGINATION_PER_PERIOD = "UPDATE_PAGINATION_PER_PERIOD";
export const GENERATE_REPORTING_PER_PERIOD = "GENERATE_REPORTING_PER_PERIOD";
export const GET_REPORTING_PER_OBJECT = "GET_REPORTING_PER_OBJECT";

export const GET_DISTANCE_REPORT_SCHEDULING = "GET_DISTANCE_REPORT_SCHEDULING";
export const GET_EVENTS_REPORT_SCHEDULING = "GET_EVENTS_REPORT_SCHEDULING";
export const GET_TRAVELS_REPORT_SCHEDULING = "GET_TRAVELS_REPORT_SCHEDULING";

export const GET_DISTANCE_REPORT_SENT = "GET_DISTANCE_REPORT_SENT";
export const GET_EVENTS_REPORT_SENT = "GET_EVENTS_REPORT_SENT";
export const GET_TRAVELS_REPORT_SENT = "GET_TRAVELS_REPORT_SENT";

export const EDIT_DISTANCE_REPORT_SCHEDULING = "EDIT_DISTANCE_REPORT_SCHEDULING";
export const EDIT_EVENTS_REPORT_SCHEDULING = "EDIT_EVENTS_REPORT_SCHEDULING";
export const EDIT_TRAVELS_REPORT_SCHEDULING = "EDIT_TRAVELS_REPORT_SCHEDULING";

export const REQUEST_REPORTING_PER_OBJECT = "REQUEST_REPORTING_PER_OBJECT";
export const REPORTING_EVENTS_DATA_FILTER = "REPORTING_EVENTS_DATA_FILTER";

//VEHICLE SEARCH
export const GET_DATA_VEHICLE = "GET_DATA_VEHICLE";

// System
export const SEARCH_PAGED_SYSTEMS = "SEARCH_PAGED_SYSTEM";
export const UPDATE_SYSTEM_FILTER = "UPDATE_SYSTEM_FILTER";

// Map
export const TOOGLE_MARKED_MAP_FILTER = "TOOGLE_MARKED_MAP_FILTER";
export const GET_POINT_INTEREST_GROUP = "GET_POINT_INTEREST_GROUP";
export const GET_POINT_INTEREST = "GET_POINT_INTEREST";
export const UPDATE_PAGINATION_POINT_INTEREST = "UPDATE_PAGINATION_POINT_INTEREST";
export const GET_ROUTES = "GET_ROUTES";
export const DATA_FILTER_MAP = "DATA_FILTER_MAP";
export const UPDATE_DATA_FILTER = "UPDATE_DATA_FILTER";

// Dashboard e Dashboard Vehicle
export const GET_KM_RUN_WEEK = "GET_KM_RUN_WEEK";
export const TOGGLE_DETAILS_KM_RUN = "TOGGLE_DETAILS_KM_RUN";
export const DASHBOARD_VEHICLE_TOGGLE_DETAILS_KM_RUN = "DASHBOARD_VEHICLE_TOGGLE_DETAILS_KM_RUN";
export const TOGGLE_MARKED_FILTER = "TOGGLE_MARKED_FILTER";
export const DASHBOARD_VEHICLE_TOGGLE_MARKED_FILTER = "DASHBOARD_VEHICLE_TOGGLE_MARKED_FILTER";
export const SET_FLEET_LIST = "SET_FLEET_LIST";
export const SET_SUB_FLEET_LIST = "SET_SUB_FLEET_LIST";
export const SELECT_FLEET = "SELECT_FLEET";
export const SELECT_SUB_FLEET = "SELECT_SUB_FLEET";
export const SET_RANGE_DATE = "SET_RANGE_DATE";
export const SET_RANGE_DATE_AND_ID = "SET_RANGE_DATE_AND_ID";
export const RESET_DASHBOARD_VEHICLE_RANGE_DATE = "RESET_DASHBOARD_VEHICLE_RANGE_DATE";
export const GET_PERIOD_TRIPS = "GET_PERIOD_TRIPS";
export const GET_FLEET_EVOLUTION = "GET_FLEET_EVOLUTION";
export const GET_MONTHLY_FLEET_EVOLUTION = "GET_MONTHLY_FLEET_EVOLUTION";
export const GET_KM_RUN = "GET_KM_RUN";
export const GET_KM_RUN_INDICATOR = "GET_KM_RUN_INDICATOR";
export const DASHBOARD_VEHICLE_GET_PERIOD_TRIPS = "DASHBOARD_VEHICLE_GET_PERIOD_TRIPS";
export const DASHBOARD_VEHICLE_EVOLUTION_FLEET = "DASHBOARD_VEHICLE_EVOLUTION_FLEET";
export const DASHBOARD_VEHICLE_GET_KM_RUN = "DASHBOARD_VEHICLE_GET_KM_RUN";
export const DASHBOARD_VEHICLE_GET_KM_RUN_INDICATOR = "DASHBOARD_VEHICLE_GET_KM_RUN_INDICATOR";
export const DASHBOARD_VEHICLE_GET_KM_RUN_PERIOD = "DASHBOARD_VEHICLE_GET_KM_RUN_PERIOD";
export const DASHBOARD_VEHICLE_GET_KM_RUN_DAY_PERIOD = "DASHBOARD_VEHICLE_GET_KM_RUN_DAY_PERIOD";
export const DASHBOARD_VEHICLE_GET_ANALYSIS_ITEMS = "DASHBOARD_VEHICLE_GET_ANALYSIS_ITEMS";
export const DASHBOARD_VEHICLE_GET_ROAD_OFFSET = "DASHBOARD_VEHICLE_GET_ROAD_OFFSET";
export const DASHBOARD_VEHICLE_GET_VEHICLE_HEATMAP = " DASHBOARD_VEHICLE_GET_VEHICLE_HEATMAP";
export const DASHBOARD_VEHICLE_CLEAN_DASHBOARD_TRAVELS = "DASHBOARD_VEHICLE_CLEAN_DASHBOARD_TRAVELS";
export const DASHBOARD_BASE_COMPARATIVE_KM_RUN = "DASHBOARD_BASE_COMPARATIVE_KM_RUN";
export const GET_ANALYSIS_ITEMS = "GET_ANALYSIS_ITEMS";
export const CLEAN_DASHBOARD_TRAVELS = "CLEAN_DASHBOARD_TRAVELS";
export const GET_ROAD_OFFSET = "GET_ROAD_OFFSET";
export const GET_TRAVELS = "GET_TRAVELS";
export const GET_SCORE = "GET_SCORE";
export const DASHBOARD_VEHICLE_GET_SCORE = "DASHBOARD_VEHICLE_GET_SCORE";
export const DASHBOARD_VEHICLE_COMPOSITION_RADAR = "DASHBOARD_VEHICLE_COMPOSITION_RADAR";
export const GET_ACCIDENT_PROBABILITY = "GET_ACCIDENT_PROBABILITY";
export const GET_TOP_TEN_MAX_VELOCITY_VEHICLE = "GET_TOP_TEN_MAX_VELOCITY_VEHICLE";
export const GET_TOP_TEN_MAX_VELOCITY_DRIVER = "GET_TOP_TEN_MAX_VELOCITY_DRIVER";
export const GET_TRAVEL_MAX_VELOCITY = "GET_TRAVEL_MAX_VELOCITY";
export const GET_TRAVEL_MAX_VELOCITY_POSITIONS = "GET_TRAVEL_MAX_VELOCITY_POSITIONS";

export const FIND_TRAVELS_VEHICLE = "FIND_TRAVELS_VEHICLE";
export const FIND_LAST_POSITIONS_WITHOUT_DATA_TABLE = "FIND_LAST_POSITIONS_WITHOUT_DATA_TABLE";
export const FIND_LAST_POSIIONS = "FIND_LAST_POSIIONS";

// Fleet
export const FLEET_DATA_FILTER = "FLEET_DATA_FILTER";
export const FLEET_GET_DATA_FILTER = "FLEET_GET_DATA_FILTER";
export const FLEET_GET_VEHICLE_DATA = "FLEET_GET_VEHICLE_DATA";

//Driver
export const GET_DATA_DRIVER = "GET_DATA_DRIVER";
export const GET_DATA_DRIVER_ALL = "GET_DATA_DRIVER_ALL";
export const SET_SEARCH_KEY = "SET_SEARCH_KEY";
export const SET_STATUS = "SET_STATUS";
export const GET_DATA_DRIVER_SEARCH = "GET_DATA_DRIVER_SEARCH";
export const UPDATE_PAGINATION_REGISTER_DRIVER = "UPDATE_PAGINATION_REGISTER_DRIVER";
export const GET_DRIVER_BY_ID = "GET_DRIVER_BY_ID";
export const CLEAN_DRIVER = "CLEAN_DRIVER";

//Group of Drivers
export const GET_GROUP_DRIVERS_BY_ID = "GET_GROUP_DRIVERS_BY_ID";
export const GET_DRIVERS_LINKED_GROUP = "GET_DRIVERS_LINKED_GROUP";
export const GET_AVAILABLE_DRIVERS_FOR_GROUP = "GET_AVAILABLE_DRIVERS_FOR_GROUP";
export const GET_GROUPS_DRIVERS = "GET_GROUPS_DRIVERS";
export const DELETE_GROUP_DRIVERS_BY_ID = "DELETE_GROUP_DRIVERS_BY_ID";
export const CLEAN_DRIVERS_LINKED_GROUP = "CLEAN_DRIVERS_LINKED_GROUP";
export const CLEAN_AVAILABLE_DRIVERS_FOR_GROUP = "CLEAN_AVAILABLE_DRIVERS_FOR_GROUP";

//Group of Points
export const GET_GROUP_POINTS_BY_ID = "GET_GROUP_POINTS_BY_ID";
export const GET_POINTS_LINKED_GROUP = "GET_POINTS_LINKED_GROUP";
export const GET_AVAILABLE_POINTS_FOR_GROUP = "GET_AVAILABLE_POINTS_FOR_GROUP";
export const GET_GROUPS_POINTS = "GET_GROUPS_POINTS";
export const DELETE_GROUP_POINTS_BY_ID = "DELETE_GROUP_POINTS_BY_ID";
export const CLEAN_POINTS_LINKED_GROUP = "CLEAN_POINTS_LINKED_GROUP";
export const CLEAN_AVAILABLE_POINTS_FOR_GROUP = "CLEAN_AVAILABLE_POINTS_FOR_GROUP";

//User
export const GET_CARS_LINKED_FOR_USER = "GET_CARS_LINKED_FOR_USER";
export const GET_AVAILABLE_CARS_FOR_USER = "GET_AVAILABLE_CARS_FOR_USER";
export const CLEAN_CARS_LINKED_FOR_USER = "CLEAN_CARS_LINKED_FOR_USER";
export const CLEAN_AVAILABLE_CARS_FOR_USER = "CLEAN_AVAILABLE_CARS_FOR_USER";
export const GET_FUNCTIONALITY_FOR_USER = "GET_FUNCTIONALITY_FOR_USER";
export const GET_FLEET_BY_USER = "GET_FLEET_BY_USER";
export const GET_PERMISSIONS_BY_USER = "GET_PERMISSIONS_BY_USER";
export const GET_USERS = "GET_USERS";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const USER_ACTIVATE_ID = "USER_ACTIVATE_ID";
export const DISPLAY_FLEET_USER = "DISPLAY_FLEET_USER";
export const SET_USER_SEARCH = "SET_USER_SEARCH";

// Address
export const ADDRESS_GET_DATA = "ADDRESS_GET_DATA";
export const ADDRESS_DATA_FILTER = "ADDRESS_DATA_FILTER";

// TAB FILTERS
export const TAB_ACTIVE_FILTERS = "TAB_ACTIVE_FILTERS";

//Logs
export const SEARCH_PAGED_LOG = "SEARCH_PAGED_LOG";
export const UPDATE_LOG_FILTER = "UPDATE_LOG_FILTER";

// Messages types
export const DEFAULT = "default";
export const SUCCESS = "success";
export const ERROR = "error";
export const INFO = "info";
export const WARNING = "warning";

// Choose language
export const LANGUAGE = "LANGUAGE";

// Google Maps
export const UPDATE_MAPS_CONTEXT = "UPDATE_MAPS_CONTEXT";
export const UPDATE_MAPS_OBJECTS = "UPDATE_MAPS_OBJECTS";
export const UPDATE_MAPS_GROUP_POINTS = "UPDATE_MAPS_GROUP_POINTS";
export const UPDATE_MAPS_POINTS = "UPDATE_MAPS_POINTS";
export const UPDATE_MAPS_ROUTES = "UPDATE_MAPS_ROUTES";
export const UPDATE_MAPS_DIRECTIONS = "UPDATE_MAPS_DIRECTIONS";
export const CLEAN_MAPS_OBJECTS = "CLEAN_MAPS_OBJECTS";
export const CLEAN_MAPS_GROUP_POINTS = "CLEAN_MAPS_GROUP_POINTS";
export const CLEAN_MAPS_POINTS = "CLEAN_MAPS_POINTS";
export const CLEAN_MAPS_ROUTES = "CLEAN_MAPS_ROUTES";
export const CLEAN_MAPS_ALL = "CLEAN_MAPS_ALL";
export const CLEAN_MAPS_DIRECTIONS = "CLEAN_MAPS_DIRECTIONS";
export const CLEAN_MAPS_EVENTS = "CLEAN_MAPS_EVENTS";
export const CENTRALIZE_MAPS = "CENTRALIZE_MAPS";
export const UPDATE_MAPS_EVENTS = "UPDATE_MAPS_EVENTS";
export const ADD_EVENT_ON_MAP = "ADD_EVENT_ON_MAP";
export const CLEAN_EVENTS_ON_MAP = "CLEAN_EVENTS_ON_MAP";
export const ADD_SYNC_FUNC = "ADD_SYNC_FUNC";
export const CLEAN_SYNC_FUNC = "CLEAN_SYNC_FUNC";
export const ARROW_SVG =
    "M11.869.563A11.306,11.306,0,1,1,.563,11.869,11.3,11.3,0,0,1,11.869.563ZM6.58,13.874h5.288v3.232a.547.547,0,0,0,.935.388l5.211-5.238a.542.542,0,0,0,0-.77L12.8,6.243a.547.547,0,0,0-.935.388V9.863H6.58a.549.549,0,0,0-.547.547v2.918A.549.549,0,0,0,6.58,13.874Z";
export const POLYGON = "polygon";
export const POINT = "ponto";
// info
export const GET_API_KEY = "GET_API_KEY";

// export document
export const CHANGE_EXPORT_FUNCTION = "CHANGE_EXPORT_FUNCTION";
export const CLEAN_EXPORT_FUNCTION = "CLEAN_EXPORT_FUNCTION";

// Report tabs
export const CHANGE_TAB_VALUE = "CHANGE_TAB_VALUE";
export const TAB_OBJECT = "TAB_OBJECT";
export const TAB_PERIOD = "TAB_PERIOD";
export const TAB_SCHEDULED = "TAB_SCHEDULED";

// Distance Report
export const CALL_EXPORT_DOCUMENT_DISTANCE = "CALL_EXPORT_DOCUMENT_DISTANCE";
export const CLEAN_CALL_EXPORT_DOCUMENT_DISTANCE = "CLEAN_CALL_EXPORT_DOCUMENT_DISTANCE";
export const UPDATE_FILTER_DISTANCE_OBJECT = "UPDATE_FILTER_DISTANCE_OBJECT";

// Event Report
export const UPDATE_FILTER_EVENT_OBJECT = "UPDATE_FILTER_EVENT_OBJECT";
export const UPDATE_FILTER_EVENT_PERIOD = "UPDATE_FILTER_EVENT_PERIOD";
export const UPDATE_FORM_EVENT_PERIOD = "UPDATE_FORM_EVENT_PERIOD";
export const UPDATE_FORM_EVENT_OBJECT = "UPDATE_FORM_EVENT_OBJECT";
export const UPDATE_FORM_EVENT_SCHEDULED = "UPDATE_FORM_EVENT_SCHEDULED";
export const UPDATE_FILTER_EVENT_SCHEDULED = "UPDATE_FILTER_EVENT_SCHEDULED";
export const CLEAN_FILTER_EVENT_OBJECT = "CLEAN_FILTER_EVENT_OBJECT";
export const CLEAN_FILTER_EVENT_PERIOD = "CLEAN_FILTER_EVENT_PERIOD";
export const CLEAN_FILTER_EVENT_SCHEDULED = "CLEAN_FILTER_EVENT_SCHEDULED";
export const REQUEST_EVENT_PER_OBJECT = "REQUEST_EVENT_PER_OBJECT";
export const UPDATE_FILTER_EVENT_LAST_RECORD = "UPDATE_FILTER_EVENT_LAST_RECORD";

//Travels Report
export const UPDATE_FILTER_TRAVELS_OBJECT = "UPDATE_FILTER_TRAVELS_OBJECT";
export const UPDATE_FILTER_TRAVELS_PERIOD = "UPDATE_FILTER_TRAVELS_PERIOD";
export const UPDATE_FILTER_TRAVELS_SCHEDULED = "UPDATE_FILTER_TRAVELS_SCHEDULED";
export const UPDATE_FORM_TRAVELS_PERIOD = "UPDATE_FORM_TRAVELS_PERIOD";
export const UPDATE_FORM_TRAVELS_OBJECT = "UPDATE_FORM_TRAVELS_OBJECT";
export const UPDATE_FORM_TRAVELS_SCHEDULED = "UPDATE_FORM_TRAVELS_SCHEDULED";
export const CLEAN_FILTER_TRAVELS_OBJECT = "CLEAN_FILTER_TRAVELS_OBJECT";
export const CLEAN_FILTER_TRAVELS_PERIOD = "CLEAN_FILTER_TRAVELS_PERIOD";
export const CLEAN_FILTER_TRAVELS_SCHEDULED = "CLEAN_FILTER_TRAVELS_SCHEDULED";
export const REQUEST_TRAVELS_PER_OBJECT = "REQUEST_TRAVELS_PER_OBJECT";

//Distance Report

export const UPDATE_FORM_DISTANCE_OBJECT = "UPDATE_FORM_DISTANCE_OBJECT";
export const UPDATE_FORM_DISTANCE_PERIOD = "UPDATE_FORM_DISTANCE_PERIOD";
export const UPDATE_FORM_DISTANCE_SHEDULED = "UPDATE_FORM_DISTANCE_SHEDULED";

//SPEEDING REPORT
export const UPDATE_FORM_SPEEDING_PERIOD = "UPDATE_FORM_SPEEDING_PERIOD";
export const UPDATE_FORM_SPEEDING_OBJECT = "UPDATE_FORM_SPEEDING_OBJECT";
export const UPDATE_FORM_SPEEDING_SCHEDULED = "UPDATE_FORM_SPEEDING_SCHEDULED";
export const UPDATE_FILTER_SPEEDING_PERIOD = "UPDATE_FILTER_SPEEDING_PERIOD";
export const UPDATE_FILTER_SPEEDING_OBJECT = "UPDATE_FILTER_SPEEDING_OBJECT";

//FLEETS
export const GET_DATA_FLEETS_LIST = "GET_DATA_FLEETS_LIST";
export const GET_DATA_GROUP_FLEETS = "GET_DATA_GROUP_FLEETS";
export const SAVE_FLEETS_DATA = "SAVE_FLEETS_DATA";
export const IMPORT_FILE_FLEETS = "IMPORT_FILE_FLEETS";
export const EXPORT_FILE_FLEET_DATA = "EXPORT_FILE_FLEET_DATA";
export const SAVE_LINKED_FLEET_OBJECT = "SAVE_LINKED_FLEET_OBJECT";
export const SELECT_FLEETS_DATA = "SELECT_FLEETS_DATA";
export const SAVE_UNLINKED_FLEET_OBJECT = "SAVE_UNLINKED_FLEET_OBJECT";
export const GET_DATA_FLEETS_OBJ_AVAILABLE = "GET_DATA_FLEETS_OBJ_AVAILABLE";
export const GET_DATA_FLEETS_OBJ_NOT_AVAILABLE = "GET_DATA_FLEETS_OBJ_NOT_AVAILABLE";
export const CLEAN_CARS_LINKED_FOR_FLEETS = "CLEAN_CARS_LINKED_FOR_FLEETS";
export const CLEAN_AVAILABLE_CARS_FOR_FLEETS = "CLEAN_AVAILABLE_CARS_FOR_FLEETS";
export const UPDATE_REGISTER_FLEET_FILTER = "UPDATE_REGISTER_FLEET_FILTER";

//import driver
export const LIST_GROUP_DRIVER = "LIST_GROUP_DRIVER";
export const IMPORT_FILE_DRIVER = "IMPORT_FILE_DRIVER";
export const IMPORT_FILE_POINTS = "IMPORT_FILE_POINTS";

//Point Interest
export const GET_POINT_INTEREST_TYPES = "GET_POINT_INTEREST_TYPES";
export const GET_POINT_INTEREST_BY_ID = "GET_POINT_INTEREST_BY_ID";
export const CLEAN_POINT_INTEREST = "CLEAN_POINT_INTEREST";
export const GET_PENDING_NOTICES = "GET_PENDING_NOTICES";
export const GET_ALL_COMMUNICATED = "GET_ALL_COMMUNICATED";
export const COMMUNICATE_CHECK_NOTIFICATION = "COMMUNICATE_CHECK_NOTIFICATION";
export const GET_LIST_POINT_INTEREST = "GET_LIST_POINT_INTEREST";
export const GET_OBJECTS_POINT_INTEREST = "GET_OBJECTS_POINT_INTEREST";
export const SAVE_POINT_INTEREST = "SAVE_POINT_INTEREST"

//Manual home
export const TUTORIAL_GET_DATA = "TUTORIAL_GET_DATA";
export const TUTORIAL_FILTER_DATA = "TUTORIAL_FILTER_DATA";
//

// Top 10 VEHICLES
export const GET_KM_RUN_VEHICLE = "GET_KM_RUN_VEHICLE";
export const GET_KM_RUN_COMMERCIAL_VEHICLE = "GET_KM_RUN_COMMERCIAL_VEHICLE";
export const GET_KM_RUN_NOT_COMMERCIAL_VEHICLE = "GET_KM_RUN_NOT_COMMERCIAL_VEHICLE";
export const GET_KM_RUN_WEEKEND_VEHICLE = "GET_KM_RUN_WEEKEND_VEHICLE";

// Top 10 Drivers
export const GET_KM_RUN_DRIVER = "GET_KM_RUN_DRIVER";
export const GET_KM_RUN_COMMERCIAL_DRIVER = "GET_KM_RUN_COMMERCIAL_DRIVER";
export const GET_KM_RUN_NOT_COMMERCIAL_DRIVER = "GET_KM_RUN_NOT_COMMERCIAL_DRIVER";
export const GET_KM_RUN_WEEKEND_DRIVER = "GET_KM_RUN_WEEKEND_DRIVER";

// Top 10 ANALYZE Vehicles
export const GET_KM_RUN_ANALYZE_SPEEDING_VEHICLE = "GET_KM_RUN_ANALYZE_VEHICLE";
export const GET_KM_RUN_ANALYZE_DISPLACEMENT_NOT_COMMERCIAL_VEHICLE =
    "GET_KM_RUN_ANALYZE_DISPLACEMENT_NOT_COMMERCIAL_VEHICLE";
export const GET_KM_RUN_ANALYZE_DISPLACEMENT_WEEKEND_VEHICLE = "GET_KM_RUN_ANALYZE_DISPLACEMENT_WEEKEND_VEHICLE";
export const GET_KM_RUN_ANALYZE_BRUSQUE_BRAKED_VEHICLE = "GET_KM_RUN_ANALYZE_BRUSQUE_BRAKED_VEHICLE";
export const GET_KM_RUN_ANALYZE_BRUSQUE_CURVE_VEHICLE = "GET_KM_RUN_ANALYZE_BRUSQUE_CURVE_VEHICLE";
export const GET_KM_RUN_ANALYZE_BRUSQUE_ACCELARATION_VEHICLE = "GET_KM_RUN_ANALYZE_BRUSQUE_ACCELARATION_VEHICLE";

// Top 10 ANALYZE Drivers
export const GET_KM_RUN_ANALYZE_SPEEDING_DRIVER = "GET_KM_RUN_ANALYZE_DRIVER";
export const GET_KM_RUN_ANALYZE_DISPLACEMENT_NOT_COMMERCIAL_DRIVER =
    "GET_KM_RUN_ANALYZE_DISPLACEMENT_NOT_COMMERCIAL_DRIVER";
export const GET_KM_RUN_ANALYZE_DISPLACEMENT_WEEKEND_DRIVER = "GET_KM_RUN_ANALYZE_DISPLACEMENT_WEEKEND_DRIVER";
export const GET_KM_RUN_ANALYZE_BRUSQUE_BRAKED_DRIVER = "GET_KM_RUN_ANALYZE_BRUSQUE_BRAKED_DRIVER";
export const GET_KM_RUN_ANALYZE_BRUSQUE_CURVE_DRIVER = "GET_KM_RUN_ANALYZE_BRUSQUE_CURVE_DRIVER";
export const GET_KM_RUN_ANALYZE_BRUSQUE_ACCELARATION_DRIVER = "GET_KM_RUN_ANALYZE_BRUSQUE_ACCELARATION_DRIVER";

export const CNH_CATEGORIES = ["A", "B", "C", "D", "E", "AB", "AC", "AD", "AE", "ACC"];

//Profile
export const GET_PROFILE = "GET_PROFILE";
export const GET_FUSOS_BY_COUNTRY = "GET_FUSOS_BY_COUNTRY";
export const CLEAN_PROFILE = "CLEAN_PROFILE";

// Report Scheduling
export const EDIT_SPEEDING_REPORT_SCHEDULING = "EDIT_SPEEDING_REPORT_SCHEDULING";
export const GET_SPEEDING_REPORT_PER_OBJECT = "GET_SPEEDING_REPORT_PER_OBJECT";
export const GET_SPEEDING_REPORT_SCHEDULING = "GET_SPEEDING_REPORT_SCHEDULING";
export const GET_SPEEDING_REPORT_SENT = "GET_SPEEDING_REPORT_SENT";

// Cadastro de alertas
export const GET_ALERT_BY_ID = "GET_ALERT_BY_ID";
export const GET_ALERTS_HISTORY_ID = "GET_ALERTS_HISTORY_ID";
export const CLEAN_ALERTS_HISTORY = "CLEAN_ALERTS_HISTORY";
export const LIST_ALERTS = "LIST_ALERTS";
export const UPDATE_ALERT_DETAIL = "UPDATE_ALERT_DETAIL";
export const IMPORT_FILE_ALERT = "IMPORT_FILE_ALERT";
export const CLEAN_ALERT = "CLEAN_ALERT";
export const UPDATE_FILTER_ALERT_OBJECT = "UPDATE_FILTER_ALERT_OBJECT";
export const IMPORT_ALERT_REPORT = "IMPORT_ALERT_REPORT";
export const UPDATE_FILTER_ALERT_PAGINATION = "UPDATE_FILTER_ALERT_PAGINATION";
export const SAVE_EXPORT_ALERT = "SAVE_EXPORT_ALERT";
export const GET_ALERT_REPORT_HISTORY = "GET_ALERT_REPORT_HISTORY";
export const ALERT_REPORT_FILTER_DATA = "ALERT_REPORT_FILTER_DATA";
export const ALERT_REPORT_CLEAN = "ALERT_REPORT_CLEAN";
export const UPDATE_FORM_ALERT = "UPDATE_FORM_ALERT";
export const UPDATE_FILTER_ALERT_ORDER = "UPDATE_FILTER_ALERT_ORDER";
export const LIST_ALERTS_CONDITIONS = "LIST_ALERTS_CONDITIONS";
export const LIST_ALERTS_ACTIONS = "LIST_ALERTS_ACTIONS";
export const UPDATE_INSERT_FORM = "UPDATE_INSERT_FORM";
export const UPDATE_EDIT_FORM = "UPDATE_EDIT_FORM";
export const SET_OPEN_INSERT_FORM = "SET_OPEN_INSERT_FORM";
export const SET_OPEN_EDIT_FORM = "SET_OPEN_EIDT_FORM";
export const LIST_ALERTS_LINKED_TRUE = "LIST_ALERTS_LINKED_TRUE";
export const LIST_ALERTS_LINKED_FALSE = "LIST_ALERTS_LINKED_FALSE";
export const LIST_ALERTS_VEICULOS = "LIST_ALERTS_VEICULOS";
export const LIST_ALERTS_LINKED_SAVE = "LIST_ALERTS_LINKED_SAVE";
export const UPDATE_ALERT_FILTER = "UPDATE_ALERT_FILTER";
export const CLEAN_REPORT_ALERT_DETAIL = "CLEAN_REPORT_ALERT_DETAIL";

// Cadastro de veículos
export const UPDATE_VEHICLE_FILTER = "UPDATE_VEHICLE_FILTER";
export const LIST_VEHICLES = "LIST_VEHICLES";
export const CLEAN_VEHICLE_LIST = "CLEAN_VEHICLE_LIST";
export const GET_VEHICLE_BY_ID = "GET_VEHICLE_BY_ID";
export const GET_ODOMETER_BY_ID = "GET_ODOMETER_BY_ID";
export const UPDATE_VEHICLE_EDIT_FORM = "UPDATE_VEHICLE_EDIT_FORM";
export const UPDATE_VEHICLE_ODOMETER_FORM = "UPDATE_VEHICLE_ODOMETER_FORM";

// Routes
export const LIST_ROUTES = "LIST_ROUTES";
export const CLEAN_ROUTES = "CLEAN_ROUTES";
export const GET_OBJECTS_ROUTES = "GET_OBJECTS_ROUTES";
export const CLEAN_OBJECTS_ROUTES = "CLEAN_OBJECTS_ROUTES";
export const DELETE_OBJECTS_ROUTES = "DELETE_OBJECTS_ROUTES";
export const GET_ROUTES_BY_ID = "GET_ROUTES_BY_ID";
export const CLEAN_ROUTE = "CLEAN_ROUTE";
export const SEARCH_ROUTE_NAME = "SEARCH_ROUTE_NAME";
export const CLEAN_ROUTE_SEARCH = "CLEAN_ROUTE_SEARCH";

//Map
export const CLEAN_MAP_POINT_INTEREST_GROUP = "CLEAN_MAP_POINT_INTEREST_GROUP";
export const CLEAN_MAP_POINT_INTEREST = "CLEAN_MAP_POINT_INTEREST";
export const CLEAN_MAP_ROUTES = "CLEAN_MAP_ROUTES";
export const CLEAN_MAP_FILTER = "CLEAN_MAP_FILTER";

export default {
    GET_CARS_LINKED_FOR_USER,
    GET_AVAILABLE_CARS_FOR_USER,
    CLEAN_CARS_LINKED_FOR_USER,
    CLEAN_AVAILABLE_CARS_FOR_USER,
    GET_FUNCTIONALITY_FOR_USER,
    GET_FLEET_BY_USER,
    GET_PERMISSIONS_BY_USER,
    DISPLAY_FLEET_USER,
    GET_USERS,
    GET_USER_BY_ID,
    USER_ACTIVATE_ID,
    FETCH_LOGIN,
    GET_DATA_VEHICLE_INDIVIDUAL,
    GET_DATA_VEHICLE_MODE_PRIVATE,
    SET_VEHICLE,
    GET_ALL_COMMUNICATED,
    COMMUNICATE_CHECK_NOTIFICATION,
    UPDATE_LOADING,
    TOOGLE_TOOLBAR,
    GET_DATA_FLEETS_LIST,
    GET_DATA_GROUP_FLEETS,
    SAVE_LINKED_FLEET_OBJECT,
    SELECT_FLEETS_DATA,
    EXPORT_FILE_FLEET_DATA,
    GET_DATA_FLEETS_OBJ_AVAILABLE,
    GET_DATA_FLEETS_OBJ_NOT_AVAILABLE,
    SAVE_FLEETS_DATA,
    IMPORT_FILE_FLEETS,
    GET_PENDING_NOTICES,
    UPDATE_LOGIN,
    LOGOUT,
    TOKEN,
    USER,
    SUCCESS,
    ERROR,
    DEFAULT,
    INFO,
    WARNING,
    FALSE,
    TRUE,
    GET_DATA_VEHICLE,
    LANGUAGE,
    CLEAR_AUTO_COMPLETE,
    CLEAR_ALL,
    UPDATE_PAGINATION_REGISTER_DRIVER,
    GET_REPORTING_PER_PERIOD,
    UPDATE_PAGINATION_PER_PERIOD,
    GENERATE_REPORTING_PER_PERIOD,
    LOGIN,
    GET_DATA_DRIVER,
    GET_DATA_DRIVER_ALL,
    SET_SEARCH_KEY,
    SET_STATUS,
    GET_DRIVER_BY_ID,
    CLEAN_DRIVER,
    SEARCH_PAGED_SYSTEMS,
    UPDATE_SYSTEM_FILTER,
    UPDATE_LOG_FILTER,
    UPDATE_PAGINATION_POINT_INTEREST,
    SEARCH_PAGED_LOG,
    TOOGLE_MARKED_MAP_FILTER,
    UPDATE_MAPS_CONTEXT,
    TOGGLE_DETAILS_KM_RUN,
    DASHBOARD_VEHICLE_TOGGLE_DETAILS_KM_RUN,
    TOGGLE_MARKED_FILTER,
    DASHBOARD_VEHICLE_TOGGLE_MARKED_FILTER,
    SET_FLEET_LIST,
    SET_SUB_FLEET_LIST,
    SELECT_FLEET,
    CLEAN_CARS_LINKED_FOR_FLEETS,
    CLEAN_AVAILABLE_CARS_FOR_FLEETS,
    TUTORIAL_GET_DATA,
    TUTORIAL_FILTER_DATA,
    SELECT_SUB_FLEET,
    SET_RANGE_DATE,
    SET_RANGE_DATE_AND_ID,
    CALL_EXPORT_DOCUMENT_DISTANCE,
    GET_PERIOD_TRIPS,
    GET_ROAD_OFFSET,
    GET_FLEET_EVOLUTION,
    GET_MONTHLY_FLEET_EVOLUTION,
    GET_KM_RUN,
    GET_KM_RUN_WEEK,
    GET_KM_RUN_INDICATOR,
    DASHBOARD_VEHICLE_GET_KM_RUN,
    DASHBOARD_VEHICLE_GET_KM_RUN_INDICATOR,
    DASHBOARD_VEHICLE_GET_KM_RUN_PERIOD,
    GET_ANALYSIS_ITEMS,
    GET_TRAVELS,
    UPDATE_MAPS_OBJECTS,
    UPDATE_MAPS_GROUP_POINTS,
    UPDATE_MAPS_POINTS,
    UPDATE_MAPS_ROUTES,
    GET_DATA_DRIVER_SEARCH,
    UPDATE_MAPS_DIRECTIONS,
    UPDATE_MAPS_EVENTS,
    CENTRALIZE_MAPS,
    ADD_EVENT_ON_MAP,
    ADD_SYNC_FUNC,
    CLEAN_EVENTS_ON_MAP,
    CLEAN_MAPS_OBJECTS,
    CLEAN_MAPS_GROUP_POINTS,
    CLEAN_MAPS_POINTS,
    CLEAN_MAPS_ROUTES,
    CLEAN_MAPS_DIRECTIONS,
    CLEAN_MAPS_EVENTS,
    CLEAN_SYNC_FUNC,
    CLEAN_MAPS_ALL,
    GET_POINT_INTEREST_GROUP,
    GET_POINT_INTEREST,
    GET_LIST_POINT_INTEREST,
    GET_ROUTES,
    GET_REPORTING_PER_OBJECT,
    GET_DISTANCE_REPORT_SCHEDULING,
    GET_EVENTS_REPORT_SCHEDULING,
    DASHBOARD_VEHICLE_GET_KM_RUN_DAY_PERIOD,
    GET_TRAVELS_REPORT_SCHEDULING,
    GET_DISTANCE_REPORT_SENT,
    GET_EVENTS_REPORT_SENT,
    GET_TRAVELS_REPORT_SENT,
    EDIT_DISTANCE_REPORT_SCHEDULING,
    EDIT_EVENTS_REPORT_SCHEDULING,
    EDIT_TRAVELS_REPORT_SCHEDULING,
    DATA_FILTER_MAP,
    UPDATE_DATA_FILTER,
    FLEET_DATA_FILTER,
    FLEET_GET_DATA_FILTER,
    FLEET_GET_VEHICLE_DATA,
    GET_API_KEY,
    ADDRESS_GET_DATA,
    ADDRESS_DATA_FILTER,
    CHANGE_EXPORT_FUNCTION,
    LIST_ALERTS_LINKED_SAVE,
    CLEAN_EXPORT_FUNCTION,
    ARROW_SVG,
    TAB_ACTIVE_FILTERS,
    CHANGE_TAB_VALUE,
    TAB_OBJECT,
    TAB_PERIOD,
    TAB_SCHEDULED,
    REQUEST_REPORTING_PER_OBJECT,
    UPDATE_FILTER_EVENT_OBJECT,
    UPDATE_FILTER_EVENT_PERIOD,
    UPDATE_FORM_EVENT_PERIOD,
    UPDATE_FORM_EVENT_OBJECT,
    UPDATE_FORM_EVENT_SCHEDULED,
    UPDATE_FILTER_EVENT_SCHEDULED,
    CLEAN_FILTER_EVENT_OBJECT,
    CLEAN_FILTER_EVENT_PERIOD,
    CLEAN_FILTER_EVENT_SCHEDULED,
    UPDATE_FILTER_TRAVELS_OBJECT,
    UPDATE_FILTER_TRAVELS_PERIOD,
    UPDATE_FORM_TRAVELS_PERIOD,
    UPDATE_FILTER_TRAVELS_SCHEDULED,
    CLEAN_FILTER_TRAVELS_OBJECT,
    CLEAN_FILTER_TRAVELS_PERIOD,
    CLEAN_FILTER_TRAVELS_SCHEDULED,
    UPDATE_FORM_TRAVELS_OBJECT,
    UPDATE_FORM_TRAVELS_SCHEDULED,
    REQUEST_TRAVELS_PER_OBJECT,
    REPORTING_EVENTS_DATA_FILTER,
    REQUEST_EVENT_PER_OBJECT,
    LIST_GROUP_DRIVER,
    DASHBOARD_BASE_COMPARATIVE_KM_RUN,
    IMPORT_FILE_DRIVER,
    GET_GROUP_DRIVERS_BY_ID,
    GET_DRIVERS_LINKED_GROUP,
    GET_AVAILABLE_DRIVERS_FOR_GROUP,
    CNH_CATEGORIES,
    GET_GROUPS_DRIVERS,
    DELETE_GROUP_DRIVERS_BY_ID,
    CLEAN_DRIVERS_LINKED_GROUP,
    CLEAN_AVAILABLE_DRIVERS_FOR_GROUP,
    GET_POINT_INTEREST_TYPES,
    GET_POINT_INTEREST_BY_ID,
    CLEAN_POINT_INTEREST,
    GET_GROUP_POINTS_BY_ID,
    GET_POINTS_LINKED_GROUP,
    GET_AVAILABLE_POINTS_FOR_GROUP,
    GET_GROUPS_POINTS,
    DELETE_GROUP_POINTS_BY_ID,
    CLEAN_POINTS_LINKED_GROUP,
    CLEAN_AVAILABLE_POINTS_FOR_GROUP,
    IMPORT_FILE_POINTS,
    MARK_DONE_ALL_ALERTS,
    BOARD_MARK_DONE_ALERTS,
    GET_ALERTS_VIOLATED,
    CLEAN_ALERTS_DETAILS,
    GET_ALERTS_DETAILS,
    GET_ALERTS_HISTORY,
    GET_ALERTS_HISTORY_ID,
    SAVE_ALERTS_DETAILS,
    SET_ALERT_ID_SELECTED,
    LIST_ALERTS_LINKED_TRUE,
    LIST_ALERTS_LINKED_FALSE,
    LIST_ALERTS_VEICULOS,
    GET_OBJECTS_POINT_INTEREST,
    SAVE_POINT_INTEREST,
    GET_SCORE,
    DASHBOARD_VEHICLE_GET_SCORE,
    DASHBOARD_VEHICLE_COMPOSITION_RADAR,
    GET_TOP_TEN_MAX_VELOCITY_VEHICLE,
    GET_TOP_TEN_MAX_VELOCITY_DRIVER,
    GET_TRAVEL_MAX_VELOCITY,
    GET_TRAVEL_MAX_VELOCITY_POSITIONS,
    FIND_TRAVELS_VEHICLE,
    FIND_LAST_POSITIONS_WITHOUT_DATA_TABLE,
    CLEAN_CALL_EXPORT_DOCUMENT_DISTANCE,
    UPDATE_FILTER_DISTANCE_OBJECT,
    FIND_LAST_POSIIONS,
    DASHBOARD_VEHICLE_GET_PERIOD_TRIPS,
    DASHBOARD_VEHICLE_EVOLUTION_FLEET,
    DASHBOARD_VEHICLE_GET_ANALYSIS_ITEMS,
    DASHBOARD_VEHICLE_GET_ROAD_OFFSET,
    DASHBOARD_VEHICLE_GET_VEHICLE_HEATMAP,
    GET_KM_RUN_VEHICLE,
    GET_KM_RUN_COMMERCIAL_VEHICLE,
    GET_KM_RUN_NOT_COMMERCIAL_VEHICLE,
    GET_KM_RUN_WEEKEND_VEHICLE,
    GET_KM_RUN_DRIVER,
    GET_KM_RUN_COMMERCIAL_DRIVER,
    GET_KM_RUN_NOT_COMMERCIAL_DRIVER,
    GET_KM_RUN_WEEKEND_DRIVER,
    GET_ACCIDENT_PROBABILITY,
    GET_KM_RUN_ANALYZE_SPEEDING_VEHICLE,
    GET_KM_RUN_ANALYZE_DISPLACEMENT_NOT_COMMERCIAL_VEHICLE,
    GET_KM_RUN_ANALYZE_DISPLACEMENT_WEEKEND_VEHICLE,
    GET_KM_RUN_ANALYZE_BRUSQUE_BRAKED_VEHICLE,
    GET_KM_RUN_ANALYZE_BRUSQUE_CURVE_VEHICLE,
    GET_KM_RUN_ANALYZE_BRUSQUE_ACCELARATION_VEHICLE,
    GET_KM_RUN_ANALYZE_SPEEDING_DRIVER,
    GET_KM_RUN_ANALYZE_DISPLACEMENT_NOT_COMMERCIAL_DRIVER,
    GET_KM_RUN_ANALYZE_DISPLACEMENT_WEEKEND_DRIVER,
    GET_KM_RUN_ANALYZE_BRUSQUE_BRAKED_DRIVER,
    GET_KM_RUN_ANALYZE_BRUSQUE_CURVE_DRIVER,
    GET_KM_RUN_ANALYZE_BRUSQUE_ACCELARATION_DRIVER,
    GET_PROFILE,
    GET_FUSOS_BY_COUNTRY,
    CLEAN_PROFILE,
    // ALERT_REPORT //
    UPDATE_FILTER_ALERT_OBJECT,
    UPDATE_FILTER_ALERT_PAGINATION,
    IMPORT_ALERT_REPORT,
    UPDATE_ALERT_DETAIL,
    SAVE_EXPORT_ALERT,
    GET_ALERT_REPORT_HISTORY,
    ALERT_REPORT_FILTER_DATA,
    UPDATE_FORM_ALERT,
    UPDATE_FILTER_ALERT_ORDER,
    // ***** SPEEDING_REPORT ***** //
    UPDATE_FORM_SPEEDING_PERIOD,
    UPDATE_FORM_SPEEDING_OBJECT,
    UPDATE_FORM_SPEEDING_SCHEDULED,
    UPDATE_FILTER_SPEEDING_PERIOD,
    UPDATE_FILTER_SPEEDING_OBJECT,
    GET_SPEEDING_REPORT_PER_OBJECT,
    RECOVER_LOGIN,
    VISIBLE_RECOVER_LOGIN,
    GET_ALERT_BY_ID,
    LIST_ALERTS,
    IMPORT_FILE_ALERT,
    CLEAN_ALERT,
    LIST_ALERTS_CONDITIONS,
    LIST_ALERTS_ACTIONS,
    UPDATE_INSERT_FORM,
    UPDATE_EDIT_FORM,
    UPDATE_ALERT_FILTER,
    CLEAN_REPORT_ALERT_DETAIL,
    SET_OPEN_INSERT_FORM,
    SET_OPEN_EDIT_FORM,
    POLYGON,
    POINT,
    LIST_ROUTES,
    CLEAN_ROUTES,
    GET_OBJECTS_ROUTES,
    DELETE_OBJECTS_ROUTES,
    CLEAN_OBJECTS_ROUTES,
    GET_ROUTES_BY_ID,
    CLEAN_ROUTE,
    UPDATE_VEHICLE_FILTER,
    LIST_VEHICLES,
    CLEAN_VEHICLE_LIST,
    GET_VEHICLE_BY_ID,
    UPDATE_VEHICLE_EDIT_FORM,
    GET_ODOMETER_BY_ID,
    UPDATE_VEHICLE_ODOMETER_FORM,
    RESET_DASHBOARD_VEHICLE_RANGE_DATE,
    CLEAN_MAP_POINT_INTEREST_GROUP,
    CLEAN_MAP_POINT_INTEREST,
    CLEAN_MAP_ROUTES,
    CLEAN_MAP_FILTER,
    EDIT_SPEEDING_REPORT_SCHEDULING,
    GET_SPEEDING_REPORT_SCHEDULING,
    GET_SPEEDING_REPORT_SENT,
    UPDATE_REGISTER_FLEET_FILTER,
    LOGIN_TAB,
    REGISTER_TAB,
    TAB_LOGIN_FILTERS,
    TAB_LOGIN_FORM_UPDATE,
    TAB_LOGIN_RESET,
    TAB_LOGIN_FORM_UPDATE_DOCUMENT,
    UPDATE_FILTER_EVENT_LAST_RECORD,
    ALERT_REPORT_CLEAN,
    DASHBOARD_VEHICLE_CLEAN_DASHBOARD_TRAVELS,
    CLEAN_DASHBOARD_TRAVELS,
    UPDATE_FORM_DISTANCE_OBJECT,
    UPDATE_FORM_DISTANCE_PERIOD,
    UPDATE_FORM_DISTANCE_SHEDULED,
    SEARCH_ROUTE_NAME,
    DASHBOARD_VEHICLE_HIDE_ALL_FILTERS,
    DASHBOARD_VEHICLE_SHOW_CURRENT_FILTERS,
    CLEAN_ALERTS_HISTORY,
    SET_USER_SEARCH,
    CLEAN_ROUTE_SEARCH
};
