import React from "react";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
	
	container: {
		display: "flex",
	},
	paper: {
		//margin: theme.spacing(1),
	},
	svg: {
		width: 100,
		height: 100,
	},
	polygon: {
		fill: theme.palette.common.white,
		stroke: theme.palette.divider,
		strokeWidth: 1,
	},
}));

const ExampleToolbar = (props) => {

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				fda sf sad fds
			</div>
		</div>
	);
};

export default ExampleToolbar;
