import React from "react";
import FormikSelectPopper from "~/components/common/popperComponents/FormikSelectPopper";
import { Box, Typography, MenuItem, CircularProgress, FormControl, FormHelperText } from "@material-ui/core";
import { Field } from "formik";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import "./RegisterComponents.scss";

const RegisterSelect = (props) => {
	const { label, options, loading, name, multiple, disabled } = props;
	let { text, identifier } = props;

	text = text || "label";
	identifier = identifier || "value";

	const MiniCircularProfess = () => <CircularProgress size={17} style={{ marginRight: "1rem" }} />;

	const error = props.errors && props.errors[props.name];
	const touched = props.touched && props.touched[props.name];

	return (
		<Box className={`register-field ${props.noPadding ? "no-padding" : ""}`}>
			<FormControl fullWidth margin="dense">
				<Typography variant="subtitle1" className="register-field-label">
					{label}
				</Typography>
				<Field
					name={name}
					component={FormikSelectPopper}
					type="text"
					multiple={multiple}
					fullWidth
					disabled={disabled}
					variant="outlined"
					margin="dense"
					error={touched && !!error}
					IconComponent={loading ? MiniCircularProfess : ExpandMoreRoundedIcon}>
					{options &&
						options.map((option, idx) => {
							let _value;
							let _text;

							if (typeof option === "string") {
								_value = _text = option;
							} else {
								_value = option[identifier];
								_text = option[text];
							}

							return (
								<MenuItem key={idx} value={_value}>
									{_text}
								</MenuItem>
							);
						})}
				</Field>
				<FormHelperText error margin="dense" variant="outlined">
					{touched && error}
				</FormHelperText>
			</FormControl>
		</Box>
	);
};

export default RegisterSelect;
