import React from "react";

import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

import { Field } from "formik";
import { Box, Typography } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

import FormikAutocomplete from "~/components/common/autocomplete/FormikAutocomplete";
import "~/components/reporting/common/ReportingCommon.scss";
import "~/components/reporting/Reporting.scss";

const Autocomplete = (props) => {
  const { t } = useTranslation();

	return (
		<Box className={`${props.isRegisterForm ? "register-field" : "report-field"}`}>
			<Typography variant="subtitle1" className={`${props.isRegisterForm ? "register-field-label" : "report-field-label"}`}>
				{props.label}
			</Typography>
			<Field
				id={props.id}
				name={props.name}
				disabled={props.disabled}
				getOptionLabel={(option) => option.text || ""}
				getOptionSelected={(option, value) => option.text === value.text}
				component={FormikAutocomplete}
				onChange={props.onChange}
				onInputChange={props.inputChange}
				options={props.dataSource}
				noOptionsText={t(Labels.NO_DATA)}
				loading={props.loading}
				popupIcon={<ExpandMoreRoundedIcon viewBox="0 0 25 25" />}
				textFieldProps={{
					variant: "outlined",
					fullWidth: true,
					margin: "dense",
					InputProps: {
						placeholder: props.placeholder,
					},
				}}
			/>
		</Box>
	);
};

export default Autocomplete;
