import React from "react";

import { fade, makeStyles } from '@material-ui/core';

import TabMain from '~/components/common/tab/TabMain'
import FormEditUser from "./FormEditUser";
import FormPermissionsUser from "./FormPermissionsUser"


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTabs-root":{
      background: fade(theme.palette.primary.main, 0.15),
      padding: '20px 0px 0 22px'
    }
  },
}));

const FormEditionUser = (props) => {

  const TABS = [
    {
      id: 0,
      label: "Editar Usuário",
      children: (<FormEditUser onDisplay={props.onDisplay} />)
    },
    {
      id: 1,
      label: "Permissões",
      children: (<FormPermissionsUser onDisplay={props.onDisplay} />)
    },
  ]

  const classes = useStyles();
  return (
    <div className="">
      <TabMain
        id="TabsUsers"
        tabs={TABS}
        className={classes.root}
        classNameLabelRefactor="tab-label-refactor"
      />
    </div>
  );
};

export default FormEditionUser;
