import pointsInterestService from "~/services/pointsInterestService";
import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";

const getPointInterestTypes = (callback = () => {}, LOADING_IDENTIFICATOR = "pointInterest") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return pointsInterestService
        .getPointInterestTypes()
        .then((response) => {
            const pointInterestTypes = response.data;
            dispatch({ type: Constants.GET_POINT_INTEREST_TYPES, payload: { pointInterestTypes } });
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const savePointInterest = (pointInterest, callback = () => {}, LOADING_IDENTIFICATOR = "pointInterest") => (
    dispatch
) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return pointsInterestService
        .savePointInterest(pointInterest)
        .then(() => {
            callback(false);
            dispatch({ type: Constants.SAVE_POINT_INTEREST, payload: pointInterest });
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getPointInterestById = (id, callback = () => {}, LOADING_IDENTIFICATOR = "pointInterest") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return pointsInterestService
        .getPointInterestById(id)
        .then((response) => {
            const pointInterest = response.data;
            dispatch({ type: Constants.GET_POINT_INTEREST_BY_ID, payload: { pointInterest } });
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const cleanPointInterest = () => (dispatch) => {
    dispatch({ type: Constants.CLEAN_POINT_INTEREST });
};

const pointsInterestList = (filter, LOADING_IDENTIFICATOR = "requestGridPointsInterest") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return pointsInterestService
        .list(filter)
        .then((response) => {
            let { Index, recordsTotal, recordsFiltered, data } = response.data;
            const pagination = {
                page: Index,
                limit: recordsFiltered,
                recordsTotal: recordsTotal,
                search: filter.search,
            };
            dispatch({ type: Constants.GET_LIST_POINT_INTEREST, payload: { data: data, pagination: pagination } });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getObjectsPerPoints = (filter, LOADING_IDENTIFICATOR = "getObjectsPerPoints") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return pointsInterestService
        .getObjectsPerPoints(filter)
        .then((response) => {
            let payload = response.data;
            dispatch({ type: Constants.GET_OBJECTS_POINT_INTEREST, payload });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const pointsInterestDelete = (id, fnCallback, LOADING_IDENTIFICATOR = "requestGridPointsInterest") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return pointsInterestService
        .deletePointInterest(id)
        .then((response) => {
            fnCallback();
            Utils.showTranslatedToast({
                type: "info",
                description: response.data,
            });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

export default {
    getPointInterestTypes,
    savePointInterest,
    getPointInterestById,
    cleanPointInterest,
    pointsInterestList,
    pointsInterestDelete,
    getObjectsPerPoints,
};
