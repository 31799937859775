import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Button } from "@material-ui/core";
import { withRouter } from "react-router";
import Page from "~/components/common/page/Page";
import Labels from "~/helpers/enums/Labels";
import { ComeBackIcon } from "~/components/common/icons/Icons";
import { useTranslation } from "react-i18next";
import DriverForm from "~/components/drivers/register/DriverForm";
import driverAction from "~/actions/driverAction";

const RegisterDriver = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { driver } = useSelector((state) => state.driver);

	const { id } = props.match.params;

	useEffect(() => {
		dispatch(driverAction.cleanDriver());
		if (id) dispatch(driverAction.getDriverById(parseInt(id)));

		return () => {
			dispatch(driverAction.cleanDriver());
		};
	}, [dispatch, id]);

	const comeBack = () => {
		props.history.push("/register/drivers/list");
	};

	return (
		<Page
			topButtons={
				<Button
					className="comeback-button comeback-top"
					id="extractReport"
					variant="contained"
					size="small"
					disableElevation
					startIcon={<ComeBackIcon />}
					onClick={() => {
						comeBack();
					}}>
					{t(Labels.REGISTER_DRIVER_COMEBACK)}
				</Button>
			}
			bottomStyle={{ justifyContent: "flex-end" }}
			bottomButtons={
				<Button
					className="comeback-button"
					id="extractReport"
					variant="contained"
					size="small"
					disableElevation
					startIcon={<ComeBackIcon />}
					onClick={() => {
						comeBack();
					}}>
					{t(Labels.REGISTER_DRIVER_COMEBACK)}
				</Button>
			}>
			<main>
				<Container maxWidth="lg" className="container-map-view">
					{((id && driver) || !id) && <DriverForm driver={driver} comeback={comeBack} />}
				</Container>
			</main>
		</Page>
	);
};

export default withRouter(RegisterDriver);
