import http from "~/config/httpRequest/http";

export const getRastreableObjectVehicle = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/ExportarDocumentos/ExportarRastreaveisPDFOUExcel`, {
				params: {
					...filter,
				},
			})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};
export const getRastreableObjectFleets = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/ExportarDocumentos/ExportarPDFOuExcelListarGrupos`, {
				params: {
					...filter,
				},
			})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const getRastreableObjectOneFleet = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/ExportarDocumentos/ExportarPDFOuExcelListaDeRastreaveisGrupo`, {
				params: {
					...filter,
				},
			})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const extractDocumentPoints = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/PontoInteresseApi/ExportarPontosInteresse`, {
				params: {
					Formato: filter.format,
					Search: filter.search,
				},
			})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const extractDocumentDrivers = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Motoristaapi/ExportarMotoristas`, {
				params: {
					Formato: filter.format,
					Status: filter.status,
					Filtro: filter.filtro,
				},
			})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const extractDocumentVehicles = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Veiculo/Exportar?`, {
				params: {
					Formato: filter.format,
					search: filter.search
				},
			})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export default {
	getRastreableObjectVehicle,
	getRastreableObjectFleets,
	getRastreableObjectOneFleet,
	extractDocumentPoints,
	extractDocumentDrivers,
	extractDocumentVehicles
};
