import React, { useEffect, useState } from "react";

import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";

import Utils from "~/helpers/Utils";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import userAction from "~/actions/userAction";

import { useHistory } from "react-router-dom";

import { Formik, Form } from "formik";
import { Grid, Button, CircularProgress, Typography, Box } from "@material-ui/core";

import { ConfirmIcon, CancelIcon, WhiteVehicleIcon, CloseSvgIcon } from "~/components/common/icons/Icons";
import RegisterInputText from "~/components/common/registerInputs/RegisterInputText";
import RegisterSwitch from "~/components/common/registerInputs/RegisterSwitch";
import "./FormEditUser.scss";

const regexName = RegExp(/^(?=[a-z-A-Z]*[a-z-A-Z])([a-z-A-Z_\\1]*[a-z-A-Z])([a-z-A-Z{.\\1}]*[a-z-A-Z\\1])$/);

const FormEditionUser = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let history = useHistory();

    const { loading } = useSelector((state) => state.app);
    const { user, displayFleet } = useSelector((state) => state.user);

    const [initialValues, setInitialValues] = useState("");

    useEffect(() => {
        if (user) {
            setInitialValues({
                Nome: user.Nome,
                Email: user.Email,
                Login: user.Login,
                Senha: "",
                RepetirSenha: "",
                TodosVeiculos: user.TodosVeiculos,
            });
        }
    }, [user]);

    useEffect(() => {
        dispatch(userAction.setDisplayFleet(user.TodosVeiculos));
    }, [dispatch, user]);

    const getResponseMsg = (_res) => {
        if (typeof _res === "object") {
            Utils.showTranslatedToast({
                type: "error",
                description: "Não foi possível atualizar o usuário. Favor entrar em contato com a central.",
            });
        } else {
            Utils.showTranslatedToast({
                type: "success",
                description: _res,
            });
            updateListUsers();
            props.onDisplay();
        }
    };

    const updateListUsers = () => {
        const query = {
            index: 0,
            length: 25,
            search: "",
        };

        dispatch(userAction.setUserSearch(query.search));
        dispatch(userAction.getUsers({ ...query }));
    };

    const unLinkUser = () => {
        const query = {
            id: user.Id,
            activate: !user.Ativo,
        };

        dispatch(userAction.userActivationById({ ...query }));
        updateListUsers();
    };

    const checkFuncs = (_user) => {
        let funcionalidades = [];

        if (_user.Funcionalidades.length === 1) {
            if (_user.Funcionalidades[0] === "") {
                funcionalidades = ["0"];
                return funcionalidades;
            }
        }

        if (_.isEmpty(_user.Funcionalidades)) {
            funcionalidades = ["0"];
            return funcionalidades;
        }

        return _user.Funcionalidades;
    };

    const verifyLogin = (_login, search) => {
        let count = (_login.match(new RegExp(search, "g")) || []).length;
        return count;
    };

    const displayFleetUser = () => {
        dispatch(userAction.setDisplayFleet(!displayFleet));
    };

    return (
        <Box className={"cardEditUser"}>
            <Formik
                enableReinitialize
                validateOnBlur={true}
                validateOnChange={true}
                initialValues={initialValues}
                validate={(values) => {
                    const errors = {};

                    if (!values.Nome || values.Nome === "") {
                        errors.Nome = t(Labels.GROUP_POINTS_REGISTER_REQUIRED);
                    }
                    if (values.Nome.length > 50) {
                        errors.Nome = "Nome com muitos caracteres.";
                    }
                    if (!values.Email || values.Email === "") {
                        errors.Email = "Informe o E-mail";
                    }

                    if (!values.Email || values.Email === "") {
                        errors.Email = t(Labels.PROFILE_PERSONAL_INFORMATION_EMAIL_REQUIRED);
                    } else if (!Utils.isValidEmail(values.Email)) {
                        errors.Email = t(Labels.PROFILE_PERSONAL_INFORMATION_EMAIL_INVALID);
                    }

                    if (!values.Login || values.Login === "") {
                        errors.Login = "Informe o Login";
                    }

                    if (!regexName.test(values.Login)) {
                        errors.Login = "Login utiliza padrão inválido";
                    }

                    if (verifyLogin(values.Login, "_") >= 2) {
                        errors.Login = "Login utiliza padrão inválido";
                    }

                    if (!values.Senha && values.Senha !== "") {
                        if (!Utils.isValidPassword(values.Senha)) {
                            errors.Senha = t(Labels.PROFILE_PERSONAL_INFORMATION_NEW_PASSWORD_INVALID);
                        }
                    }

                    if (values.Senha) {
                        if (!values.RepetirSenha) {
                            errors.RepetirSenha = t(Labels.PROFILE_PERSONAL_INFORMATION_REPEAT_PASSWORD_REQUIRED);
                        }
                        if (values.Senha !== values.RepetirSenha) {
                            errors.RepetirSenha = t(Labels.PROFILE_PERSONAL_INFORMATION_REPEAT_PASSWORD_NOT_EQUAL);
                        }
                    }

                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    let userReq = {
                        Id: user.Id,
                        Nome: values.Nome,
                        Email: values.Email,
                        Login: values.Login,
                        Senha: values.Senha,
                        RepetirSenha: values.RepetirSenha,
                        TodosVeiculos: values.TodosVeiculos,
                        Funcionalidades: checkFuncs(user),
                        FrotasId: user.Frotas,
                        UsuarioCupId: user.UsuarioCupId,
                    };
                    dispatch(userAction.editUser(userReq, getResponseMsg));
                }}
                render={({ submitForm, setFieldValue, validateForm }) => {
                    return (
                        <>
                            <Form className="ceabs-register-form">
                                <Grid className="boxFormEditUser" container spacing={2}>
                                    <Grid item xs={12} className="boxGridBtn">
                                        <Grid item xs={4}>
                                            <Button
                                                startIcon={<WhiteVehicleIcon />}
                                                id="speeding-filter-period-save-button"
                                                className="top-button"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                disableElevation
                                                onClick={() => {
                                                    history.push(`/register/user/linkCars`);
                                                }}>
                                                {t(Labels.ALERT_EDIT_BUTTON_BOND)}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button
                                                id="cancelButton"
                                                startIcon={<CloseSvgIcon />}
                                                className="top-button"
                                                fullWidth
                                                variant="contained"
                                                size="large"
                                                disableElevation
                                                onClick={() => {
                                                    unLinkUser();
                                                    props.onDisplay();
                                                }}>
                                                {user.Ativo ? "Desativar" : "Ativar"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RegisterInputText name="Nome" label={"Nome"} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RegisterInputText name="Email" label={t(Labels.REPORT_SHEDULED_EMAIL)} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RegisterInputText name="Login" label={"Login"} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RegisterInputText type="password" name="Senha" label={"Senha"} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RegisterInputText
                                            type="password"
                                            name="RepetirSenha"
                                            label={"Repetir Senha"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} id="user-view-vehicles" className="itemViewVehicles">
                                        <Typography>Visualizar todos os Veículos</Typography>
                                        <RegisterSwitch
                                            onClick={displayFleetUser}
                                            name="TodosVeiculos"
                                            color="#71C154"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" justify="flex-end" style={{ height: "100%" }} spacing={0}>
                                            <Grid className="button-form button-form-refactor" item xs={3}>
                                                <Button
                                                    id="cancelButton"
                                                    className="cancel-button cancel-button-refactor"
                                                    fullWidth
                                                    variant="contained"
                                                    disableElevation
                                                    onClick={props.onDisplay}>
                                                    <CancelIcon />
                                                    {t(Labels.VEHICLE_FORM_BUTTON_CANCEL)}
                                                </Button>
                                            </Grid>
                                            <Grid className="button-form button-form-refactor" item xs={5}>
                                                <Button
                                                    id="speeding-filter-period-save-buttont"
                                                    className="save-button save-button-refactor"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    disableElevation
                                                    disabled={loading.editUser}
                                                    startIcon={
                                                        loading.editUser ? (
                                                            <CircularProgress size={18} />
                                                        ) : (
                                                            <ConfirmIcon />
                                                        )
                                                    }
                                                    onClick={() => {
                                                        submitForm();
                                                    }}>
                                                    Salvar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        </>
                    );
                }}
            />
        </Box>
    );
};

export default FormEditionUser;
