import React from "react";
import { useSelector } from "react-redux";
import TableCard from "~/components/tableCard/TableCardReUse";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Labels from "~/helpers/enums/Labels";
import { Typography } from "@material-ui/core";
import useFuso from "~/components/common/fuso/useFuso";

const OdometerHistory = () => {
    const { t } = useTranslation();
    const { odometer } = useSelector((state) => state.vehicle);

    const { fromUtc } = useFuso();

    const COLUMNS_HISTORY = [
        {
            name: "DataHoraCadastro",
            label: "Data de Cadastro",
            render: (date) => (<span>
                {moment(fromUtc(date)).format("DD/MM/YYYY")}
                <span style={{ color: "#AFC3D2", margin: "auto 5px" }}>|</span>
                {moment(fromUtc(date)).format("HH:mm:ss")}
            </span>)
        },
        {
            name: "VeiculoOdometro",
            label: "Odômetro Atual no Painel do Veículo (KM)",
            render: (name) => <span>{name}</span>,
        },
        {
            name: "DispositivoOdometro",
            label: "Odômetro Atual do Rastreador (KM)",
            render: (name) => <span>{ name && Math.round(name / 1000)}</span>,
        }
    ];

    return (
        <>
            <Typography variant="h4">
                {t(Labels.VEHICLE_FORM_VEHICLE_ODOMETER_HISTORY)}
            </Typography>
            <TableCard
                columns={COLUMNS_HISTORY}
                dataSource={odometer.Historico}
            />
        </>)
}

export default OdometerHistory