import recoverService from '~/services/login/recoverService';
import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";

const recoverLogin = (values, callback = () => { }, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	return recoverService
		.recoverPassword(values)
		.then((response) => {
			if (response) {
				console.log(response)
				callback(response.data);
				dispatch({
					type: Constants.RECOVER_LOGIN,
					payload: { email: values },
				});
			}
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const confirmRecoverPassword = (password, code, username, callback = () => { }, LOADING_IDENTIFICATOR = "") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

    return recoverService
        .confirmRecoverPassword(password, code, username)
        .then((response) => {
            if (response) {
                console.log(response);
                callback(response.data);
                dispatch({
                    type: Constants.CONFIRM_RECOVER_PASSWORD,
                    payload: { /* If you have any specific payload for confirmation, add it here */ },
                });
            }
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};
const visibleFormRecover = (visible, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch({ type: Constants.VISIBLE_RECOVER_LOGIN, payload: { visible } });
};


export default {
	recoverLogin,
	confirmRecoverPassword,
	visibleFormRecover
};
