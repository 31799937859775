import axios from "axios";

// Variavel env representa o endpoint do json de dicas
const getTutorialData = () => {
	const config = {
		baseURL: process.env.REACT_APP_DICAS_API,
	};

	if (process.env.NODE_ENV !== "development") {
		config["timeout"] = process.env.TIMEOUT || 5000;
	}
	
	let http = axios.create(config);

	return new Promise((resolve, reject) => {
		http
			.get("/CPS/Tutorial06092023_stellantis.json")
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

export default {
	getTutorialData,
};
