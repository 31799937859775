import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

//import { useTranslation } from "react-i18next";
//import Labels from "~/helpers/enums/Labels";
import { useHistory } from "react-router-dom";

import { Grid } from "@material-ui/core";

import DualList from "~/components/common/dualList/DualList";
import userAction from "~/actions/userAction";

const UserLinkCars = (props) => {
  //const { t } = useTranslation();
	const dispatch = useDispatch();
  let history = useHistory();

  const { loading } = useSelector((state) => state.app);
  const auth = useSelector((state) => state.auth);
	const { carsLinked, availableCars, user } = useSelector((state) => state.user);

	useEffect(() => {
		dispatch(userAction.getAvailableTraceable(user.Id));
		dispatch(userAction.getTraceableLinked(user.Id));
	}, [dispatch, user]);

	useEffect(() => {
		dispatch(userAction.cleanCarsLinked());
		dispatch(userAction.cleanAvailableCars());
	}, [dispatch]);

	const CARS_COLUMNS = [
		{
			name: "Placa",
			label: "Placa",
			render: (fleet) => <strong>{fleet}</strong>,
		},
		{
			name: "Identificacao",
			label: "Identificação",
		},
		{
			name: "Marca",
			label: "Marca",
		},
		{
			name: "Modelo",
			label: "Modelo",
		},
	];

	const getIdOfselectedRows = (rows) => {
		return rows.map((r) => r.Id);
	};

	const handleLinkDriver = (selectedRows) => {
		const selectedRowsId = getIdOfselectedRows(selectedRows);
		dispatch(userAction.saveLinkTraceable(user.Id, selectedRowsId));
	};

	const handleUnbindDriver = (selectedRows) => {
		const selectedRowsId = getIdOfselectedRows(selectedRows);
		dispatch(userAction.saveUnLinkTraceable(user.Id, selectedRowsId));
	};

	const handlerSearchPagingAvailable = (search, pagination) => {
		const filter = {
			length: pagination.length,
			page: pagination.current - 1,
			search: search,
		};
		dispatch(userAction.getAvailableTraceable(user.Id, filter));
	};

	const handlerSearchPagingLinked = (search, pagination) => {
		const filter = {
			length: pagination.length,
			page: pagination.current - 1,
			search: search,
		};
		dispatch(userAction.getTraceableLinked(user.Id, filter));
  };

  useEffect(() =>{
    if(!user.Id){
      history.push(`/register/user`);
    }
  },[user, history])

	return (
		<div>
			<Grid container justify="right" alignItems="flex-end" spacing={1}>
				<Grid item xs={12}>
					<DualList
						actionAdd={handleLinkDriver}
						actionRemove={handleUnbindDriver}
						tableAvailable={{
							key: "Available",
							title: "Disponíveis",
							columns: CARS_COLUMNS,
							dataSource: availableCars.data,
							rowSelection: {
								refColName: "Placa",
								onChange: (selectedRows) => {
									console.log("selectedRows", selectedRows);
								},
							},
							pagination: {
								current: availableCars.index,
								itemsPerPage: availableCars.recordsFiltered,
								totalItems: availableCars.recordsTotal,
							},
							onChange: handlerSearchPagingAvailable,
							loading: loading.getAvailableTraceable,
						}}
						tableLinked={{
							key: "Linked",
							title: (
								<span>
									Vinculados
									<span style={{ display: "block", fontWeight: 200, fontSize: 12 }}>Usuário:{user.Nome}</span>
								</span>
							),
							columns: CARS_COLUMNS,
							dataSource: carsLinked.data,
							rowSelection: {
								refColName: "Placa",
								onChange: (selectedRows) => {
									console.log("selectedRows", selectedRows);
								},
							},
							loading: loading.getTraceableLinked,
							search: true,
							pagination: {
								current: carsLinked.index,
								itemsPerPage: carsLinked.recordsFiltered,
								totalItems: carsLinked.recordsTotal,
							},
							onChange: handlerSearchPagingLinked,
							backgroundColor: auth.user.colors.topBarColor,
						}}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default UserLinkCars;
