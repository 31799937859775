import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconPointInterestGroup } from "~/assets/icons/pointInterestGroup.svg";
import { ReactComponent as IconPointInterest } from "~/assets/icons/pointInterest.svg";
import { ReactComponent as IconRoutes } from "~/assets/icons/routes.svg";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import googleMapsDataAction from "~/actions/googleMapsDataAction";
import mapAction from "~/actions/mapAction";
import ButtonAutocomplete from "~/components/common/buttonAutocomplete/ButtonAutocomplete";
import { Grid } from "@material-ui/core";
import "./mapFilter.scss";

const MapFilter = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { pointInterestGroup, pointInterest, routes } = useSelector((state) => state.map);

    const [groupSelected, setGroupSelected] = useState(null);
    const [pointsSelected, setPointsSelected] = useState([]);
    const [routesSelected, setRoutesSelected] = useState([]);

    useEffect(() => {
        return () => {
            dispatch(googleMapsDataAction.cleanGroupPoints());
            dispatch(googleMapsDataAction.cleanPoints());
            dispatch(googleMapsDataAction.cleanRoutes());
        };
    }, [dispatch]);

    return (
        <div className="mapFilter">
            <Grid container direction="row" spacing={1}>
                <Grid item md={4}>
                    <ButtonAutocomplete
                        id={t(Labels.MAP_FILTER_GROUP)}
                        disableCloseOnSelect
                        iconComponent={IconPointInterestGroup}
                        button={t(Labels.MAP_FILTER_GROUP)}
                        placeholder={t(Labels.MAP_FILTER_PLACEHOLDER)}
                        value={groupSelected}
                        onChange={(event, value) => {
                            setGroupSelected(value);
                            if (value) {
                                dispatch(
                                    mapAction.getPointInterestForGroup(
                                        {
                                            grupoId: parseInt(value.id),
                                            quantidade: 10,
                                        },
                                        value.text,
                                        (erros, points) => {
                                            !erros && dispatch(googleMapsDataAction.updateGroupPoints(points));
                                        }
                                    )
                                );
                            } else {
                                dispatch(googleMapsDataAction.cleanGroupPoints());
                            }
                        }}
                        options={pointInterestGroup}
                        text="text"
                        onInputChange={(event, value, callBack) => {
                            if (value) {
                                dispatch(
                                    mapAction.getPointInterestGroup(
                                        {
                                            descricao: value,
                                        },
                                        () => callBack()
                                    )
                                );
                            } else {
                                callBack();
                            }
                        }}
                    />
                </Grid>
                <Grid item md={4}>
                    <ButtonAutocomplete
                        id={t(Labels.MAP_FILTER_POINT_INTEREST)}
                        disableCloseOnSelect
                        multiple
                        iconComponent={IconPointInterest}
                        button={t(Labels.MAP_FILTER_POINT_INTEREST)}
                        placeholder={t(Labels.MAP_FILTER_PLACEHOLDER)}
                        value={pointsSelected}
                        onChange={(event, value) => {
                            setPointsSelected(value);
                            if (value) {
                                dispatch(googleMapsDataAction.updatePoints(value));
                            } else {
                                dispatch(googleMapsDataAction.cleanPoints());
                            }
                        }}
                        options={pointInterest}
                        text="title"
                        onInputChange={(event, value, callBack) => {
                            if (value) {
                                dispatch(
                                    mapAction.getPointInterest(
                                        {
                                            descricao: value,
                                        },
                                        () => callBack()
                                    )
                                );
                            } else {
                                callBack();
                            }
                        }}
                    />
                </Grid>

                <Grid item md={4}>
                    <ButtonAutocomplete
                        id={t(Labels.MAP_FILTER_ROUTES)}
                        disableCloseOnSelect
                        multiple
                        iconComponent={IconRoutes}
                        button={t(Labels.MAP_FILTER_ROUTES)}
                        placeholder={t(Labels.MAP_FILTER_PLACEHOLDER)}
                        value={routesSelected}
                        onChange={(event, value) => {
                            setRoutesSelected(value);
                            if (value) {
                                dispatch(googleMapsDataAction.updateRoutes(value));
                            } else {
                                dispatch(googleMapsDataAction.cleanRoutes());
                            }
                        }}
                        options={routes}
                        text="title"
                        onInputChange={(event, value, callBack) => {
                            if (value) {
                                dispatch(
                                    mapAction.getRoutes(
                                        {
                                            descricao: value,
                                        },
                                        () => callBack()
                                    )
                                );
                            } else {
                                callBack();
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default MapFilter;
