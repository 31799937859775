import React, { useState } from "react";
import { Checkbox, TextField, Popper, Button, Box, InputAdornment, SvgIcon, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { SearchIcon } from "~/components/common/icons/Icons";
import { makeStyles } from "@material-ui/core/styles";
import "./ButtonAutocomplete.scss";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: "none",
        margin: 0,
    },
    option: {
        minHeight: "auto",
        alignItems: "flex-start",
        padding: 0,
        paddingTop: 1,
        paddingBottom: 1,
        '&[aria-selected="true"]': {
            backgroundColor: "transparent",
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    popperDisablePortal: {
        position: "relative",
    },
}));

const ButtonAutocomplete = (props) => {
    const text = props.text || "text";
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const isOpen = Boolean(anchorEl);
    const { t } = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(isOpen ? null : event.currentTarget);
    };

    return (
        <>
            <Button
                data-id={String("button" + props.id)
                    .replace(/ de /g, "")
                    .replace(/\s/g, "")}
                fullWidth
                className={`button-popover ${isOpen ? "on" : "off"}`}
                variant="contained"
                onClick={handleClick}>
                <SvgIcon component={props.iconComponent} color="inherit" /> {props.button}
            </Button>

            <Popper open={isOpen} anchorEl={anchorEl} className="popper-autocomplete">
                <Box className="box-autocomplete">
                    <span className="container-arrow"></span>
                    <Autocomplete
                        fullWidth
                        disablePortal
                        open
                        classes={{
                            paper: classes.paper,
                            option: classes.option,
                            popperDisablePortal: classes.popperDisablePortal,
                        }}
                        loading={isLoading}
                        multiple={props.multiple}
                        disableCloseOnSelect={props.disableCloseOnSelect}
                        value={props.value}
                        onChange={props.onChange}
                        options={isLoading ? [] : props.options}
                        noOptionsText={t(Labels.AUTOCOMPLETE_BUTTON_NO_OPTIONS)}
                        loadingText={t(Labels.AUTOCOMPLETE_LOADING)}
                        onClose={(event, reason) => {
                            if (reason === "toggleInput") {
                                return;
                            }

                            if (anchorEl) {
                                anchorEl.focus();
                            }

                            setAnchorEl(null);
                        }}
                        onInputChange={(event, value) => {
                            setLoading(true);
                            props.onInputChange && props.onInputChange(event, value, () => setLoading(false));
                        }}
                        getOptionLabel={(option) => option[text] || ""}
                        getOptionSelected={(option, value) => option[text] === value[text]}
                        renderOption={(option, { selected }) => (
                            <div className="autocomplete-option">
                                <Checkbox
                                    id={`checkbox-${option[text]}`.replace(/ de /g, "").replace(/\s/g, "")}
                                    data-id={`checkbox-${option[text]}`.replace(/ de /g, "").replace(/\s/g, "")}
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    checked={selected}
                                />
                                {option[text]}
                            </div>
                        )}
                        renderInput={(params) => {
                            params.InputProps = {
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {isLoading ? <CircularProgress size={20} /> : <SearchIcon />}
                                    </InputAdornment>
                                ),
                                startAdornment: null,
                                autoFocus: true,
                            };
                            params.inputProps = {
                                ...params.inputProps,
                                "data-id": String("pesquisar" + props.id)
                                    .replace(/ de /g, "")
                                    .replace(/\s/g, ""),
                                type: "search",
                            };
                            return (
                                <TextField
                                    {...params}
                                    {...params}
                                    autoFocus
                                    variant="outlined"
                                    placeholder={props.placeholder}
                                />
                            );
                        }}
                    />
                </Box>
            </Popper>
        </>
    );
};

export default ButtonAutocomplete;
