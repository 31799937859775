import http from "~/config/httpRequest/http";

//http://apicps-solutis.ceabsservicos.com.br/PortalGestaoFrotas/api/Pesquisa/BuscarFrota?quantidade=10&descricao=

const list = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Pesquisa/BuscarFrota`)
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const getGroupFleets = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Frota/ObterGrupos`, {
				params: {
					GrupoId: filter.groupId,
					Vinculado: filter.linked,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const getDataFleetsList = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/Frota/Buscar`, {
				status: filter.status,
				subfrota: filter.subFleet,
				FiltroGrid: {
					Index: filter.page,
					Length: filter.limit,
					Search: filter.search,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const saveFleets = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/Frota/SalvarFrota`, {
				Nome: filter.name,
				Ativo: filter.active,
				GrupoPaiId: filter.parentGroup,
				TipoDispositivo: "",
				...filter,
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const downloadFile = () => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Frota/ObterPlanilhaFrotas`)
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const importFileFleet = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/Frota/ImportarObjetosParaFrota`, {
				AssocId: filter.fleetId.id,
				RemoverObjetos: filter.removeObj,
				Documento: {
					FileName: filter?.file?.fileName,
					File: filter?.file?.fileData,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const exportFleet = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/Frota/Exportar`, {
				status: filter.status,
				subFrota: filter.subFleet,
				search: filter.search,
				RelatorioExecucao: {
					Formato: filter.format,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const saveFleetLinkedObject = (groupId, carsSelected = []) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/Frota/SalvarObjetosFrota`, {
				grupoId: groupId,
				ObjetosSelecionados: carsSelected,
				Vinculado: true,
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const saveFleetUnlinkedObject = (groupId, carsSelected = []) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/Frota/SalvarObjetosFrota`, {
				grupoId: groupId,
				ObjetosSelecionados: carsSelected,
				Vinculado: false,
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const getObjectsFleetAvailable = (groupId, filter = { page: 0, limit: 10, search: "", parentGroupId: null }) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/Frota/PesquisarObjetosFrota`, {
				grupoId: groupId,
				grupoPaiId: filter.parentGroupId || null,
				Vinculado: false,
				FiltroGrid: {
					Index: filter.page || 0,
					Length: filter.limit || 10,
					Search: filter.search || "",
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const getObjectsFleetNotAvailable = (groupId, filter = { page: 0, limit: 10, search: "", parentGroupId: null }) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/Frota/PesquisarObjetosFrota`, {
				grupoId: groupId,
				grupoPaiId: filter.parentGroupId || null,
				Vinculado: true,
				FiltroGrid: {
					Index: filter.page || 0,
					Length: filter.limit || 10,
					Search: filter.search || "",
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

export default {
	list,

	downloadFile,
	saveFleets,
	importFileFleet,
	getDataFleetsList,
	exportFleet,
	saveFleetLinkedObject,
	saveFleetUnlinkedObject,
	getObjectsFleetAvailable,
	getObjectsFleetNotAvailable,
	getGroupFleets,
};
