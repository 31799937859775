import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { FormGroup, FormControl, Grid, Slider, Typography } from "@material-ui/core";
import Card from "~/components/common/expansionCard/Card";
import dashboardFiltersAction from "~/actions/dashboardVehicle/dashboardFiltersAction";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import "moment/locale/pt-br";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    svg: {
        width: 100,
        height: 100,
    },
    polygon: {
        fill: theme.palette.common.white,
        stroke: theme.palette.divider,
        strokeWidth: 1,
    },
    root: {
        display: "flex",
    },
    formControlCombo: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "end",
    },
    formControl: {
        width: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    combo: {
        width: "70%",
    },
}));

const CustomSlider = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        height: 10,
        width: "95%",
    },
    thumb: {
        height: 20,
        width: 20,
    },
    mark: {
        width: 6,
        height: "inherit",
        opacity: 0.5,
    },
    markActive: {
        backgroundColor: "inherit",
    },
    markLabel: {
        marginTop: 10,
        textTransform: "capitalize",
        fontSize: "14px",
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)",
        marginTop: 10,
    },
    track: {
        height: 10,
    },
    rail: {
        height: 10,
    },
}))(Slider);

const marks = [];

const values = [6, 5, 4, 3, 2, 1];

for (let i = 5; i >= 0; i--) {
    const date = moment().subtract(i, "month");
    marks.push({ value: values[i], label: date.format("MMM|YY"), mes: date.month() + 1, ano: date.year() });
}

const DashboardVehicleFilter = ({ vehicle }) => {
    const { language } = useSelector((state) => state.language);
    const classes = useStyles();
    const dispatch = useDispatch();
    moment.locale(language);

    const { rangeDate } = useSelector((state) => state.dashboardVehicleFilter);
    const [months, setMonths] = useState([rangeDate.ValueInicio, rangeDate.ValueFim]);

    useEffect(() => {
        let newDate = new Date();

        const date = {
            ValueInicio: marks[5].value,
            MesInicio: newDate.getMonth() + 1,
            AnoInicio: newDate.getFullYear(),
            ValueFim: marks[5].value,
            MesFim: newDate.getMonth() + 1,
            AnoFim: newDate.getFullYear(),
        };

        dispatch(dashboardFiltersAction.setRangeDateAndId(date, vehicle.ObjetoRastreavelId));
    }, [dispatch, vehicle]);

    useEffect(() => {
        setMonths([rangeDate.ValueInicio, rangeDate.ValueFim])
    }, [rangeDate])

    return (
        <div className={classes.container}>
            <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                    <Card>
                        <Typography
                            style={{ fontSize: 20, padding: 0 }}
                            variant="subtitle1"
                            className="report-field-label">
                            Filtros
                        </Typography>
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                            <CustomSlider
                                aria-label="pretto slider"
                                step={1}
                                marks={marks}
                                value={months}
                                onChange={(event, newValue) => {
                                    if (_.isEqual(newValue, months)) return;

                                    const date = {
                                        ValueInicio: marks[newValue[0] - 1].value,
                                        MesInicio: marks[newValue[0] - 1].mes,
                                        AnoInicio: marks[newValue[0] - 1].ano,
                                        ValueFim: marks[newValue[1] - 1].value,
                                        MesFim: marks[newValue[1] - 1].mes,
                                        AnoFim: marks[newValue[1] - 1].ano,
                                    };

                                    dispatch(
                                        dashboardFiltersAction.setRangeDateAndId(date, vehicle.ObjetoRastreavelId)
                                    );
                                }}
                                aria-labelledby="range-slider"
                                min={1}
                                max={6}
                            />
                        </Grid>
                    </Card>
                </FormGroup>
            </FormControl>
        </div>
    );
};

export default DashboardVehicleFilter;
