import authService from "~/services/authService";
import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import jwtDecode from "jwt-decode";

const sendCredentials = (values, callback = () => {}, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	return authService
		.login(values)
		.then(({ data }) => {
			if (!data) return;
			callback();
			dispatch({
				type: Constants.LOGIN,
				payload: {
					user: {
						name: data.Nome,
						logo: data.Logotipo,
						colors: {
							topBarColor: data.CorBarraTopo,
							colorPanels: data.CorPaineis,
							colorBarPanels: data.CorBarraPaineis,
						},
						verPopUpRenovacaoContratual: data.VerPopUpRenovacaoContratual
					},
					fusoHorario: data.TzId,
					infos: data,
				},
			});
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const verifyCredentialsAuthentication = (isAuthenticated) => (dispatch) => {
	if (Utils.not(isAuthenticated) && Utils.hasTokenValid()) {
		dispatch({ type: Constants.LOGIN, payload: {} });
	} else if (Utils.not(Utils.hasTokenValid())) {
		dispatch({ type: Constants.LOGOUT });
	}
};

const logout = () => (dispatch) => {
	return authService.logout().then(() => {
		localStorage.removeItem('AceiteTermo');
	}).catch((error) => {
		console.log(error);
	}).finally(() => {
		dispatch({ type: Constants.LOGOUT });
	});
};

const getProfile = () => (dispatch) => {
	const token = Utils.getToken();
	if (token) {
		const jwt = jwtDecode(token);
		dispatch({
			type: Constants.LOGIN,
			payload: {
				user: {
					name: jwt.unique_name,
				},
			},
		});
	}
};

export default {
	sendCredentials,
	verifyCredentialsAuthentication,
	logout,
	getProfile,
};
