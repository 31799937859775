import Constants from "~/helpers/enums/Constants";

const setLoginTab = (tabId) => (dispatch) => {
	let payload = {
		tabActive: tabId,
	};
	dispatch({ type: Constants.TAB_LOGIN_FILTERS, payload });
};

const updateLoginForm = (payload) => (dispatch) => {
	dispatch({ type: Constants.TAB_LOGIN_FORM_UPDATE, payload });
};

const resetForm = () => (dispatch) => {
	dispatch({ type: Constants.TAB_LOGIN_RESET });
};

const updateDocument = (payload) => (dispatch) => {
	dispatch({ type: Constants.TAB_LOGIN_FORM_UPDATE_DOCUMENT, payload });
}; 

export default {
    setLoginTab,
    updateLoginForm,
    resetForm,
    updateDocument
};
