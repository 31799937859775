import { Button, CircularProgress } from "@material-ui/core";
import Labels from "~/helpers/enums/Labels";
import React from "react";
import { useTranslation } from "react-i18next";
import { DownloadIcon } from "../icons/Icons";

const ExportButton = (props) => {
	const { t } = useTranslation();

	return (
		<Button
			id={props.id || "extractReport"}
			variant="contained"
			color="primary"
			size="small"
			style={{ width: "max-content", fontSize: "14px" }}
			disableElevation
			className="export-button"
			disabled={props.disabled || props.loading}
			startIcon={props.loading ? <CircularProgress size={18} /> : <DownloadIcon style={{width: "28px"}} />}
			onClick={props.onClick}>
			{t(Labels.EXTRACT_REPORT)}
		</Button>
	);
};

export default ExportButton;
