import React from "react";
import { useSelector } from "react-redux";
import ReactEcharts from "echarts-for-react";
import ScoreColor from "~/helpers/enums/ScoreChartColor";

const SECONDARY = "SECONDARY";

const getOption = (value = 0, color = "primary", isShow = false) => {
    const percent = value / 100 || 0;
    const blankSpace = percent > 0 ? percent + 0.02 : 0;
    const gradient = color.toUpperCase() === SECONDARY ? ScoreColor.secondaryColor : ScoreColor.primaryColor;
    return {
        series: [
            {
                name: "Score",
                type: "gauge",
                startAngle: 180,
                endAngle: 0,
                align: "center",
                center: ["50%", "80%"],
                radius: 160,
                splitNumber: 1,
                axisLine: {
                    lineStyle: {
                        color: [
                            [percent, gradient],
                            [blankSpace, "transparent"],
                            [1, "#EEF2F5"],
                        ],
                        width: 75,
                    },
                },
                splitLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    distance: 0,
                    formatter: function (v) {
                        switch (v + "") {
                            case "0":
                                return "0";
                            case "100":
                                return "100";
                            default:
                                return "";
                        }
                    },
                    textStyle: {
                        padding: [30, 0, 0, 0],
                        color: "#AFC3D2",
                        fontSize: 20,
                        fontWeight: "bolder",
                    },
                },
                pointer: {
                    show: false,
                },
                title: {
                    show: false,
                },
                detail: {
                    show: isShow,
                    backgroundColor: "transparent",
                    borderWidth: 0,
                    width: 20,
                    height: 10,
                    offsetCenter: [0, -30],
                    formatter: "{value}%",
                    textStyle: {
                        fontSize: 30,
                        color: "#3D5564",
                        fontWeight: "bold",
                    },
                },
                data: [{ value }],
            },
        ],
    };
};

const getOptionRadar = (value) => {
    return {
        tooltip: { show: true },
        radar: {
            name: {
                textStyle: {
                    color: "#000",
                },
            },
            indicator: [
                { name: "Horário", max: 100 },
                { name: "Eventos\nBruscos", max: 100 },
                { name: "Velocidade", max: 100 },
                { name: "Distância", max: 100 },
            ],
            shape: "polygon",
            splitNumber: 0,
        },
        series: [
            {
                type: "radar",
                label: {
                    fontSize: 15,
                },
                data: [
                    {
                        value,
                        name: "Totais",
                    },
                ],
                symbolSize: 18,
                lineStyle: {
                    color: "#64C8FF",
                    width: 5,
                },
                itemStyle: {
                    color: "#64C8FF",
                    borderColor: "#fff",
                    borderWidth: 3,
                },
                symbol: "circle",
            },
        ],
    };
};

export default function ScoreChartGauge(props) {
    const { color, nameProperty } = props;
    const { waitingScore } = useSelector((state) => state.app.loading);
    const { score, compositionRadar } = useSelector((state) => state.dashboardVehicleCharts);

    const data = score.hasOwnProperty(nameProperty) ? score[nameProperty] : 0;

    return (
        <div style={nameProperty === "ScoreExposicaoRisco" ? { display: "flex" } : {}}>
            <ReactEcharts
                option={getOption(data, color, !waitingScore)}
                showLoading={waitingScore}
                style={nameProperty === "ScoreExposicaoRisco" ? { width: "50%" } : {}}
                loadingOption={{ text: "Carregando" }}
            />
            {nameProperty === "ScoreExposicaoRisco" && (
                <ReactEcharts
                    option={getOptionRadar(compositionRadar)}
                    showLoading={waitingScore}
                    style={{ width: "50%" }}
                    loadingOption={{ text: "Carregando" }}
                />
            )}
        </div>
    );
}
