import React, {
	forwardRef,
	useRef,
	useState,
	useEffect,
	useImperativeHandle,
	Children,
	isValidElement,
	cloneElement,
	Fragment,
} from "react";
import { isFragment } from "react-is";
import clsx from "clsx";
// import Menu from "@material-ui/core/Menu/Menu";
import MenuPopper from "./MenuPopper"
import { isFilled } from "@material-ui/core/InputBase/utils";
import ownerDocument from "@material-ui/core/utils/ownerDocument";
import useForkRef from "@material-ui/core/utils/useForkRef";
import capitalize from "@material-ui/core/utils/capitalize";
import useControlled from "@material-ui/core/utils/useControlled";

function areEqualValues(a, b) {
	if (typeof b === "object" && b !== null) {
		return a === b;
	}

	return String(a) === String(b);
}

function isEmpty(display) {
	return display == null || (typeof display === "string" && !display.trim());
}

const SelectInputPopper = forwardRef(function SelectInputPopper(props, ref) {
	const {
		"aria-label": ariaLabel,
		autoFocus,
		autoWidth,
		children,
		classes,
		className,
		defaultValue,
		disabled,
		displayEmpty,
		IconComponent,
		inputRef: inputRefProp,
		labelId,
		MenuProps = {},
		multiple,
		name,
		onBlur,
		onChange,
		onClose,
		onFocus,
		onOpen,
		open: openProp,
		readOnly,
		renderValue,
		SelectDisplayProps = {},
		tabIndex: tabIndexProp,
		// catching `type` from Input which makes no sense for SelectInput
		type,
		value: valueProp,
		variant = "standard",
		...other
	} = props;

	const [value, setValue] = useControlled({
		controlled: valueProp,
		default: defaultValue,
		name: "Select",
	});

	const inputRef = useRef(null);
	const [displayNode, setDisplayNode] = useState(null);
	const { current: isOpenControlled } = useRef(openProp != null);
	const [menuMinWidthState, setMenuMinWidthState] = useState();
	const [openState, setOpenState] = useState(false);
	const handleRef = useForkRef(ref, inputRefProp);

	useImperativeHandle(
		handleRef,
		() => ({
			focus: () => {
				displayNode.focus();
			},
			node: inputRef.current,
			value,
		}),
		[displayNode, value]
	);

	useEffect(() => {
		if (autoFocus && displayNode) {
			displayNode.focus();
		}
	}, [autoFocus, displayNode]);

	useEffect(() => {
		if (displayNode) {
			const label = ownerDocument(displayNode).getElementById(labelId);
			if (label) {
				const handler = () => {
					if (getSelection().isCollapsed) {
						displayNode.focus();
					}
				};
				label.addEventListener("click", handler);
				return () => {
					label.removeEventListener("click", handler);
				};
			}
		}

		return undefined;
	}, [labelId, displayNode]);

	const update = (open, event) => {
		if (open) {
			if (onOpen) {
				onOpen(event);
			}
		} else if (onClose) {
			onClose(event);
		}

		if (!isOpenControlled) {
			setMenuMinWidthState(autoWidth ? null : displayNode.clientWidth);
			setOpenState(open);
		}
	};

	const handleMouseDown = (event) => {
		// Ignore everything but left-click
		if (event.button !== 0) {
			return;
		}
		// Hijack the default focus behavior.
		event.preventDefault();
		// displayNode.focus();

		update(true, event);
	};

	const handleClose = (event) => {


		update(false, event);
	};

	const childrenArray = Children.toArray(children);

	// Support autofill.
	const handleChange = (event) => {
		const index = childrenArray.map((child) => child.props.value).indexOf(event.target.value);

		if (index === -1) {
			return;
		}

		const child = childrenArray[index];
		setValue(child.props.value);

		if (onChange) {
			onChange(event, child);
		}
	};

	const handleItemClick = (child) => (event) => {
		if (!multiple) {
			update(false, event);
		}

		let newValue;

		if (multiple) {
			newValue = Array.isArray(value) ? value.slice() : [];
			const itemIndex = value.indexOf(child.props.value);
			if (itemIndex === -1) {
				newValue.push(child.props.value);
			} else {
				newValue.splice(itemIndex, 1);
			}
		} else {
			newValue = child.props.value;
		}

		if (child.props.onClick) {
			child.props.onClick(event);
		}

		if (value === newValue) {
			return;
		}

		setValue(newValue);

		if (onChange) {
			event.persist();
			// Preact support, target is read only property on a native event.
			Object.defineProperty(event, "target", { writable: true, value: { value: newValue, name } });
			onChange(event, child);
		}
	};

	const handleKeyDown = (event) => {
		if (!readOnly) {
			const validKeys = [
				" ",
				"ArrowUp",
				"ArrowDown",
				// The native select doesn't respond to enter on MacOS, but it's recommended by
				// https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
				"Enter",
			];

			if (validKeys.indexOf(event.key) !== -1) {
				event.preventDefault();
				update(true, event);
			}
		}
	};

	const open = displayNode !== null && (isOpenControlled ? openProp : openState);

	const handleBlur = (event) => {
		// if open event.stopImmediatePropagation
		if (!open && onBlur) {
			event.persist();
			// Preact support, target is read only property on a native event.
			Object.defineProperty(event, "target", { writable: true, value: { value, name } });
			onBlur(event);
		}
	};

	delete other["aria-invalid"];

	let display;
	let displaySingle;
	const displayMultiple = [];
	let computeDisplay = false;
	let foundMatch = false;

	// No need to display any value if the field is empty.
	if (isFilled({ value }) || displayEmpty) {
		if (renderValue) {
			display = renderValue(value);
		} else {
			computeDisplay = true;
		}
	}

	const items = childrenArray.map((child) => {
		if (!isValidElement(child)) {
			return null;
		}

		if (process.env.NODE_ENV !== "production") {
			if (isFragment(child)) {
				console.error(
					[
						"Material-UI: The Select component doesn't accept a Fragment as a child.",
						"Consider providing an array instead.",
					].join("\n")
				);
			}
		}

		let selected;

		if (multiple) {
			if (!Array.isArray(value)) {
				throw new Error(
					"Material-UI: The `value` prop must be an array when using the `Select` component with `multiple`."
				);
			}

			selected = value.some((v) => areEqualValues(v, child.props.value));
			if (selected && computeDisplay) {
				displayMultiple.push(child.props.children);
			}
		} else {
			selected = areEqualValues(value, child.props.value);
			if (selected && computeDisplay) {
				displaySingle = child.props.children;
			}
		}

		if (selected) {
			foundMatch = true;
		}

		return cloneElement(child, {
			"aria-selected": selected ? "true" : undefined,
			onClick: handleItemClick(child),
			onKeyUp: (event) => {
				if (event.key === " ") {
					event.preventDefault();
				}

				if (child.props.onKeyUp) {
					child.props.onKeyUp(event);
				}
			},
			role: "option",
			selected,
			value: undefined, // The value is most likely not a valid HTML attribute.
			"data-value": child.props.value, // Instead, we provide it as a data attribute.
		});
	});

	if (process.env.NODE_ENV !== "production") {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (!foundMatch && !multiple && value !== "") {
				const values = childrenArray.map((child) => child.props.value);
				console.warn(
					[
						`Material-UI: You have provided an out-of-range value \`${value}\` for the select ${
							name ? `(name="${name}") ` : ""
						}component.`,
						"Consider providing a value that matches one of the available options or ''.",
						`The available values are ${
							values
								.filter((x) => x != null)
								.map((x) => `\`${x}\``)
								.join(", ") || '""'
						}.`,
					].join("\n")
				);
			}
		}, [foundMatch, childrenArray, multiple, name, value]);
	}

	if (computeDisplay) {
		display = multiple ? displayMultiple.join(", ") : displaySingle;
	}

	// Avoid performing a layout computation in the render method.
	let menuMinWidth = menuMinWidthState;

	if (!autoWidth && isOpenControlled && displayNode) {
		menuMinWidth = displayNode.clientWidth;
	}

	let tabIndex;
	if (typeof tabIndexProp !== "undefined") {
		tabIndex = tabIndexProp;
	} else {
		tabIndex = disabled ? null : 0;
	}

	const buttonId = SelectDisplayProps.id || (name ? `mui-component-select-${name}` : undefined);

	return (
		<Fragment>
			<div
				className={clsx(
					classes.root, // TODO v5: merge root and select
					classes.select,
					classes.selectMenu,
					classes[variant],
					{
						[classes.disabled]: disabled,
					},
					className
				)}
				ref={setDisplayNode}
				tabIndex={tabIndex}
				role="button"
				aria-disabled={disabled ? "true" : undefined}
				aria-expanded={open ? "true" : undefined}
				aria-haspopup="listbox"
				aria-label={ariaLabel}
				aria-labelledby={[labelId, buttonId].filter(Boolean).join(" ") || undefined}
				onKeyDown={handleKeyDown}
				onMouseDown={disabled || readOnly ? null : handleMouseDown}
				onBlur={handleBlur}
				onFocus={onFocus}
				{...SelectDisplayProps}
				// The id is required for proper a11y
				id={buttonId}>
				{/* So the vertical align positioning algorithm kicks in. */}
				{isEmpty(display) ? (
					// eslint-disable-next-line react/no-danger
					<span dangerouslySetInnerHTML={{ __html: "&#8203;" }} />
				) : (
					display
				)}
			</div>
			<input
				value={Array.isArray(value) ? value.join(",") : value}
				name={name}
				ref={inputRef}
				aria-hidden
				onChange={handleChange}
				tabIndex={-1}
				className={classes.nativeInput}
				autoFocus={autoFocus}
				{...other}
			/>
			<IconComponent
				className={clsx(classes.icon, classes[`icon${capitalize(variant)}`], {
					[classes.iconOpen]: open,
					[classes.disabled]: disabled,
				})}
			/>
			<MenuPopper
				id={`menu-${name || ""}`}
				anchorEl={displayNode}
				open={open}
				onClose={handleClose}
				{...MenuProps}
				MenuListProps={{
					"aria-labelledby": labelId,
					role: "listbox",
					disableListWrap: true,
					...MenuProps.MenuListProps,
				}}
				PaperProps={{
					...MenuProps.PaperProps,
					style: {
						minWidth: menuMinWidth,
						...(MenuProps.PaperProps != null ? MenuProps.PaperProps.style : null),
					},
				}}>
				{items}
			</MenuPopper>
		</Fragment>
	);
});

export default SelectInputPopper;
