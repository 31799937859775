import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import dashboardActionsTopTen from "~/actions/dashboard/dashboarTopTenAction";

import { Typography } from "@material-ui/core";
import { prefixWithZeros } from "~/helpers/Utils";

import Avatar from "@material-ui/core/Avatar";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import BoxTab from "~/components/common/boxTab/BoxTab";
import ProgressBar from "~/components/common/progressBar/ProgressBar";

const TopAnalyzeDrivers = ({ filter }) => {
    const dispatch = useDispatch();
    const [kmActive, setKmActive] = useState("KmRunAnalyzeSpeedingDrivers");
    const { t } = useTranslation();

    const { dataAnalyzeDriversTop } = useSelector((state) => state.dashboardTopTen);
    const { loading } = useSelector((state) => state.app);

    const getKmRunAnalyzeSpeedingDrivers = () => {
        setKmActive("KmRunAnalyzeSpeedingDrivers");
        dispatch(dashboardActionsTopTen.getKmRunAnalyzeExcessSpeedingDrivers({ ...filter }));
    };

    const getKmRunAnalyzeBrusqueAccelerationDrivers = () => {
        setKmActive("KmRunAnalyzeBrusqueAccelerationDrivers");
        dispatch(dashboardActionsTopTen.getKmRunAnalyzeBrusqueAccelerationDrivers({ ...filter }));
    };

    const getKmRunAnalyzeBrusqueCurveDrivers = () => {
        setKmActive("KmRunAnalyzeBrusqueCurveDrivers");
        dispatch(dashboardActionsTopTen.getKmRunAnalyzeBrusqueCurveDrivers({ ...filter }));
    };

    const getKmRunAnalyzeBrusqueBrakedDrivers = () => {
        setKmActive("KmRunAnalyzeBrusqueBrakedDrivers");
        dispatch(dashboardActionsTopTen.getKmRunAnalyzeBrusqueBrakedDrivers({ ...filter }));
    };

    const getKmRunAnalyzeDisplacementNotCommercialDrivers = () => {
        setKmActive("KmRunAnalyzeDisplacementNotCommercialDrivers");
        dispatch(dashboardActionsTopTen.getKmRunAnalyzeDisplacementNotCommercialDrivers({ ...filter }));
    };

    const getKmRunAnalyzeDisplacementWeekendDrivers = () => {
        setKmActive("KmRunAnalyzeDisplacementWeekendDrivers");
        dispatch(dashboardActionsTopTen.getKmRunAnalyzeDisplacementWeekendDrivers({ ...filter }));
    };

    useEffect(() => {
        if (!filter) return;

        switch (kmActive) {
            case "KmRunAnalyzeSpeedingDrivers":
                getKmRunAnalyzeSpeedingDrivers();
                break;
            case "KmRunAnalyzeBrusqueAccelerationDrivers":
                getKmRunAnalyzeBrusqueAccelerationDrivers();
                break;
            case "KmRunAnalyzeBrusqueCurveDrivers":
                getKmRunAnalyzeBrusqueCurveDrivers();
                break;
            case "KmRunAnalyzeBrusqueBrakedDrivers":
                getKmRunAnalyzeBrusqueBrakedDrivers();
                break;
            case "KmRunAnalyzeDisplacementNotCommercialDrivers":
                getKmRunAnalyzeDisplacementNotCommercialDrivers();
                break;
            case "KmRunAnalyzeDisplacementWeekendDrivers":
                getKmRunAnalyzeDisplacementWeekendDrivers();
                break;
            default:
                getKmRunAnalyzeSpeedingDrivers();
                break;
        }

        // eslint-disable-next-line
    }, [filter]);

    const getDataHighestValue = useCallback(
        (dist) => {
            let highest = dataAnalyzeDriversTop[0]["Valor"];
            return (dist / highest) * 100;
        },
        [dataAnalyzeDriversTop]
    );

    const LISTBUTTON = [
        {
            label: t(Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_SPEEDING),
            onClick: () => {
                getKmRunAnalyzeSpeedingDrivers();
            },
            column: "KmRunAnalyzeSpeeding",
        },
        {
            label: t(Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_BRUSQUE_ACCELERATION),
            onClick: () => {
                getKmRunAnalyzeBrusqueAccelerationDrivers();
            },
            column: "KmRunAnalyzeBrusqueAcceleration",
        },
        {
            label: t(Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_BRUSQUE_CURVE),
            onClick: () => {
                getKmRunAnalyzeBrusqueCurveDrivers();
            },
            column: "KmRunAnalyzeBrusqueCurve",
        },
        {
            label: t(Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_BRUSQUE_BRAKED),
            onClick: () => {
                getKmRunAnalyzeBrusqueBrakedDrivers();
            },
            column: "KmRunAnalyzeBrusqueBraked",
        },
        {
            label: t(Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_DISPLACEMENT_NOT_COMMERCIAL),
            onClick: () => {
                getKmRunAnalyzeDisplacementNotCommercialDrivers();
            },
            column: "KmRunAnalyzeDisplacementNotCommercial",
        },
        {
            label: t(Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_DISPLACEMENT_WEEKEND),
            onClick: () => {
                getKmRunAnalyzeDisplacementWeekendDrivers();
            },
            column: "KmRunAnalyzeDisplacementWeekend",
        },
    ];

    const defaultLine = useMemo(() => {
        return [
            {
                name: "",
                label: "",
                width: "50px",
                render: (itemName, item, count) => (
                    <Typography className="countItem">{prefixWithZeros(count + 1, 2)}</Typography>
                ),
            },
            {
                name: "UrlImagem",
                label: "",
                width: "10%",
                render: (url) => (
                    <span className="flex-justify-center">
                        <Avatar className="imgTop10Driver" src={url ? url.trim() : null} />
                    </span>
                ),
            },
            {
                name: "NomeMotorista",
                label: t(Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TABLE_DRIVER),
                width: "200px",
            },
            {
                name: "Valor",
                label: t(Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TABLE_VALUE),
                render: (dist) => (
                    <span className="progressAnalyze" style={{ display: "flex" }}>
                        <ProgressBar value={getDataHighestValue(dist)} />
                        <Typography className="gridDataFont rowFont fontDist progressBar-label">
                            {dist ? dist.toLocaleString("pt-br") : 0}
                        </Typography>
                    </span>
                ),
            },
        ];
    }, [getDataHighestValue, t]);

    const COLUMNS = [
        {
            KmRunAnalyzeSpeeding: [...defaultLine],
            KmRunAnalyzeBrusqueAcceleration: [...defaultLine],
            KmRunAnalyzeBrusqueCurve: [...defaultLine],
            KmRunAnalyzeBrusqueBraked: [...defaultLine],
            KmRunAnalyzeDisplacementNotCommercial: [...defaultLine],
            KmRunAnalyzeDisplacementWeekend: [...defaultLine],
        },
    ];

    return (
        <BoxTab
            data={dataAnalyzeDriversTop}
            listButton={LISTBUTTON}
            columns={COLUMNS}
            loading={loading.getKmRunAnalyzeDrivers}
            defaultColumn={"KmRunAnalyzeSpeeding"}
            className="driver-box"
        />
    );
};

export default TopAnalyzeDrivers;
