import React from "react";
import { Box, Typography } from "@material-ui/core";
import ReportTab from "./ReportTab";
import { ObjectPanel, PeriodPanel, ScheduledPanel } from "./ReportPanel";
import Constants from "~/helpers/enums/Constants";
import Card from "~/components/common/expansionCard/Card";
import "./ReportComponents.scss";

const ReportFilter = (props) => {
	return (
		<>
			<Typography className={"report-filter-label"}>{props.label}</Typography>
			<Card className="report-filter">
				<ReportTab
					initTab={props.initTab}
					hasObject={!!props.objectFilter}
					hasPeriod={!!props.periodFilter}
					hasScheduled={!!props.scheduledFilter}
					tabs={props.tabs}
					onCleans={{
						[Constants.TAB_OBJECT]: props.onCleanObject,
						[Constants.TAB_PERIOD]: props.onCleanPeriod,
						[Constants.TAB_SCHEDULED]: props.onCleanScheduled,
					}}
				/>
				<Box className="report-filter-fields">
					<ObjectPanel> {props.objectFilter} </ObjectPanel>
					<PeriodPanel> {props.periodFilter} </PeriodPanel>
					<ScheduledPanel> {props.scheduledFilter} </ScheduledPanel>
					{props.panels}
				</Box>
			</Card>
		</>
	);
};

export default ReportFilter;
