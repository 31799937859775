import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

import EmptySvg from "~/assets/images/empty.svg";
import ArrowSvg from "~/assets/images/arrow.svg";
import CalendarSvg from "~/assets/images/calendar.svg";

import VehicleSvg from "~/assets/icons/card/vehicle.svg";
import TruckSvg from "~/assets/icons/card/truck.svg";
import MotorcycleSvg from "~/assets/icons/card/motorcycle.svg";

import SearchSvg from "~/assets/icons/search.svg";
import GooglePlacesSvg from "~/assets/icons/marrocos.svg";

import List from "@material-ui/icons/List";
import ExpandMore from "@material-ui/icons/ExpandMore";
import NotificationsNone from "@material-ui/icons/NotificationsNone";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import HelpOutline from "@material-ui/icons/HelpOutline";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import IconPointInterestGroupSvg from "~/assets/icons/pointInterestGroup.svg";
import IconPointInterestSvg from "~/assets/icons/pointInterest.svg";
import IconRoutesSvg from "~/assets/icons/routes.svg";

import CenterSvg from "~/assets/icons/center.svg";
import MeasureRulerSvg from "~/assets/icons/measure-ruler.svg";
import MeasureArrowSvg from "~/assets/icons/measure-arrow.svg";
import ZoomSvg from "~/assets/icons/zoom.svg";

import NotificationSvg from "~/assets/icons/notification.svg";
import CleanSvg from "~/assets/icons/fechar.svg";
import CloseWhite from "~/assets/icons/close-white.svg";
import DatePickerSvg from "~/assets/icons/calendario.svg";
import SaveReportSvg from "~/assets/icons/corrigir.svg";
import DownloadSvg from "~/assets/icons/download.svg";
import Add from "~/assets/icons/mais.svg";
import RegisterTitleSvg from "~/assets/icons/register-title.svg";

import CancelSvg from "~/assets/icons/cancel.svg";
import ConfirmSvg from "~/assets/icons/confirm.svg";
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import ExitToAppTwoToneIcon from "@material-ui/icons/ExitToAppTwoTone";
import CameraSvg from "~/assets/icons/camera.svg";
import UserSvg from "~/assets/icons/user.svg";
import EditSvg from "~/assets/icons/editIcon.svg";

import DeleteSvg from "~/assets/icons/delete.svg";
import ForwardSvg from "~/assets/icons/forward.svg";
import InfoSvg from "~/assets/icons/info.svg";

import AlertSvg from "~/assets/icons/alert.svg";
import ArchiveSvg from "~/assets/icons/archive.svg";
import CalendarDarkSvg from "~/assets/icons/calendar.svg";
import CheckCircleSvg from "~/assets/icons/check-circle.svg";
import ForwardNoFillSvg from "~/assets/icons/forward-no-fill.svg";
import SpeedometerSvg from "~/assets/icons/speedometer.svg";
import CloseCircleSvg from "~/assets/icons/closeCircle.svg";
import CloseLightGraySvg from "~/assets/icons/closeLightGray.svg";

import IconTruckYellow from "~/assets/icons/iconTruckYellow.svg";
import IconCarBlue from "~/assets/icons/iconCarBlue.svg";

import AlertWeekendPng from "~/assets/icons/alerts/weekend.png";
import AlertOutHourPng from "~/assets/icons/alerts/out-hour.png";
import AlertVelocityPng from "~/assets/icons/alerts/velocity.png";
import AlertEnteringRiskPng from "~/assets/icons/alerts/entering-risk.png";
import AlertLeavingRiskPng from "~/assets/icons/alerts/leaving-risk.png";
import AlertPrivatePng from "~/assets/icons/alerts/private.png";

import UconnectWhitePng from "~/assets/icons/uconnect-white.png";

import { ReactComponent as IconRefresh } from "~/assets/icons/refresh.svg";
import { ReactComponent as ProfileIconSvg } from "~/assets/icons/profileIcon.svg";
import { ReactComponent as ExitIconSvg } from "~/assets/icons/exitIcon.svg";
import { ReactComponent as IconDwGreen } from "~/assets/icons/iconDownloadGreen.svg";
import { ReactComponent as IconImportGreen } from "~/assets/icons/importGreen.svg";
import { ReactComponent as VehicleIcon } from "~/assets/icons/vehicle.svg";
import { ReactComponent as MoreSvg } from "~/assets/icons/moreDark.svg";
import { ReactComponent as EditIconWhiteComp } from "~/assets/icons/editWhite.svg";
import { ReactComponent as EditIconComp } from "~/assets/icons/edit.svg";
import { ReactComponent as DeleteIconSvg } from "~/assets/icons/delete.svg";
import { ReactComponent as AddMoreIcon } from "~/assets/icons/addMore.svg";
import { ReactComponent as ChooseImageSvg } from "~/assets/icons/icon-escolher.svg";
import { ReactComponent as WhiteTrashSvg } from "~/assets/icons/icon-trash-white.svg";
import { ReactComponent as AddSvg } from "~/assets/icons/add.svg";
import { ReactComponent as WhiteVehicleSvg } from "~/assets/icons/white-vehicle.svg";
import { ReactComponent as BlackCloseSvg } from "~/assets/icons/blackClose.svg";
import { ReactComponent as ColoredEditSvg } from "~/assets/icons/edit.svg";
import { ReactComponent as ExcelIconSvg } from "~/assets/icons/excel.svg";
import { ReactComponent as PdfIconSvg } from "~/assets/icons/pdf.svg";

import "./Icons.scss";

export const ListIcon = (props) => <List {...props} />;
export const ExpandMoreIcon = (props) => <ExpandMore {...props} />;
export const NotificationsNoneIcon = (props) => <NotificationsNone {...props} />;
export const HelpOutlineIcon = (props) => <HelpOutline {...props} />;

export const Icon = (props) => <img src={props.url} alt={props.alt} {...props} />;

export const UserIcon = (props) => <img src={UserSvg} alt={"User"} {...props} />;
export const EmptyIcon = (props) => <img src={EmptySvg} alt={"Empty"} {...props} />;
export const ArrowDownIcon = (props) => <img src={ArrowSvg} alt={"Arrow"} {...props} />;
export const CalendarIcon = (props) => <img src={CalendarSvg} alt={"Arrow"} {...props} />;

export const VechicleIcon = (props) => <img src={VehicleSvg} alt={"Vehicle"} {...props} />;
export const TruckIcon = (props) => <img src={TruckSvg} alt={"Truck"} {...props} />;
export const MotorcycleIcon = (props) => <img src={MotorcycleSvg} alt={"Motorcycle"} {...props} />;

export const SearchIcon = (props) => <img src={SearchSvg} alt={"Serach"} {...props} />;
export const GooglePlacesIcon = (props) => <img src={GooglePlacesSvg} alt="Gooogle Places" {...props} />;

export const PointInterestGroupIcon = (props) => (
    <img src={IconPointInterestGroupSvg} alt={"Point Interest group"} {...props} />
);
export const PointInterestIcon = (props) => <img src={IconPointInterestSvg} alt={"Point Interest"} {...props} />;
export const RoutesIcon = (props) => <img src={IconRoutesSvg} alt={"Icon route"} {...props} />;

// Map Control Icons
export const CenterIcon = (props) => <img src={CenterSvg} alt={"Icon Center"} {...props} />;
export const MeasureRulerIcon = (props) => <img src={MeasureRulerSvg} alt={"Icon MeasureRuler"} {...props} />;
export const MeasureArrowIcon = (props) => <img src={MeasureArrowSvg} alt={"Icon MeasureArrow"} {...props} />;
export const ZoomIcon = (props) => <img src={ZoomSvg} alt={"Icon Zoom"} {...props} />;

export const Notification = (props) => <img src={NotificationSvg} alt={"Icon Zoom"} {...props} />;
export const CloseIcon = (props) => <img src={CleanSvg} alt={"Icon Close"} {...props} />;
export const DatePickerIcon = (props) => <img src={DatePickerSvg} alt={"Icon Date picker"} {...props} />;
export const SaveReportIcon = (props) => <img src={SaveReportSvg} alt={"Icon Save Report"} {...props} />;
export const DownloadIcon = (props) => <img src={DownloadSvg} alt={"Icon Download"} {...props} />;

export const RegisterTitleIcon = (props) => <img src={RegisterTitleSvg} alt={"Icon Register Title"} {...props} />;

export const CancelIcon = (props) => <img src={CancelSvg} alt={"Icon Cancel"} {...props} />;
export const ConfirmIcon = (props) => <img src={ConfirmSvg} alt={"Icon Confirm"} {...props} />;
// driver import
export const BackIcon = (props) => <ExitToAppTwoToneIcon {...props} />;
export const CloseIconWhite = (props) => <img src={CloseWhite} alt={"Icon Close"} {...props} />;
export const AddIcon = (props) => <img src={Add} alt={"Icon Add"} {...props} />;
export const IconBack = (props) => <ArrowBackSharpIcon {...props} />;

export const CameraIcon = (props) => <img src={CameraSvg} alt={"Icon Close"} {...props} />;

export const DeleteIcon = (props) => <img src={DeleteSvg} alt={"Icon Delete"} {...props} />;
export const ForwardIcon = (props) => <img src={ForwardSvg} alt={"Icon Forward"} {...props} />;
export const IconDelete = (props) => <SvgIcon component={DeleteIconSvg} viewBox="0 0 50 50" {...props} />;
export const IconView = (props) => <VisibilityOutlinedIcon {...props} />;
export const IconEditMat = (props) => <EditSharpIcon {...props} />;
export const IconDeleteMat = (props) => <DeleteOutlinedIcon {...props} />;
export const InfoIcon = (props) => <img src={InfoSvg} alt={"Icon Info"} {...props} />; //<InfoSvg {...props} />
export const IconEdit = (props) => <EditIconComp {...props} />;
export const EditIconWhite = (props) => <EditIconWhiteComp {...props} />;
export const AddMore = (props) => <AddMoreIcon {...props} />;
export const ComeBackIcon = (props) => <ExitToAppTwoToneIcon className="rotate-plus-180" {...props} />;
export const EditIcon = (props) => <img src={EditSvg} alt={"Icon Add"} {...props} />;

export const AlertIcon = (props) => <img src={AlertSvg} alt={"Ícone Alerta"} {...props} />;
export const ArchiveIcon = (props) => <img src={ArchiveSvg} alt={"Ícone Histórico"} {...props} />;
export const CalendarDarkIcon = (props) => <img src={CalendarDarkSvg} alt={"Ícone Calendário"} {...props} />;
export const CheckCircleIcon = (props) => <img src={CheckCircleSvg} alt={"Ícone Ativo"} {...props} />;
export const ForwardNoFillIcon = (props) => <img src={ForwardNoFillSvg} alt={"Ícone Ações"} {...props} />;
export const SpeedometerIcon = (props) => <img src={SpeedometerSvg} alt={"Ícone Condições"} {...props} />;
export const CloseCircleIcon = (props) => <img src={CloseCircleSvg} alt={"Ícone GPS Off"} {...props} />;

export const CarBlueIcon = (props) => <img src={IconCarBlue} alt={"Ícone carro top 10"} {...props} />;
export const TruckYellowIcon = (props) => <img src={IconTruckYellow} alt={"Ícone truck top 10"} {...props} />;
export const IconVehicle = (props) => <SvgIcon component={VehicleIcon} {...props} />;
export const MoreIcon = (props) => <SvgIcon component={MoreSvg} {...props} />;

export const RefreshIcon = (props) => <SvgIcon component={IconRefresh} {...props} />;

export const ProfileIcon = (props) => <SvgIcon component={ProfileIconSvg} {...props} />;
export const IconDwGreenIcon = (props) => <SvgIcon component={IconDwGreen} {...props} />;
export const ImportGreenIcon = (props) => <SvgIcon component={IconImportGreen} {...props} />;
export const CloseLightGrayIcon = (props) => <img src={CloseLightGraySvg} {...props} alt={"Ícone"} />;
export const ExitIcon = (props) => <SvgIcon component={ExitIconSvg} {...props} />;

export const ChooseImageIcon = (props) => <SvgIcon component={ChooseImageSvg} {...props} />;
export const WhiteTrashIcon = (props) => <SvgIcon component={WhiteTrashSvg} {...props} />;

export const NoColorAddIcon = (props) => <SvgIcon component={AddSvg} {...props} />;

export const AlertWeekendIcon = (props) => <img src={AlertWeekendPng} alt={"Alert Weekend  icon"} {...props} />;
export const AlertOutHourIcon = (props) => <img src={AlertOutHourPng} alt={"Alert Out Hour icon"} {...props} />;
export const AlertVelocityIcon = (props) => <img src={AlertVelocityPng} alt={"Alert Velocity  icon"} {...props} />;
export const AlertEnteringRiskIcon = (props) => (
    <img src={AlertEnteringRiskPng} alt={"Alert Entering Risk icon"} {...props} />
);
export const AlertLeavingRiskIcon = (props) => (
    <img src={AlertLeavingRiskPng} alt={"Alert Leaving Risk icon"} {...props} />
);
export const AlertPrivateIcon = (props) => <img src={AlertPrivatePng} alt={"Alert Private icon"} {...props} />;

export const WhiteVehicleIcon = (props) => <SvgIcon component={WhiteVehicleSvg} {...props} />;
export const CloseSvgIcon = (props) => <SvgIcon component={BlackCloseSvg} viewBox="0 0 20 18" {...props} />;
export const ColoredEditIcon = (props) => (
    <SvgIcon component={ColoredEditSvg} viewBox="0 0 15 18" fontSize="large" {...props} />
);

export const UconnectWhiteIcon = (props) => <img src={UconnectWhitePng} alt={"Uconnect"} {...props} />;

export const PdfIcon = (props) => <SvgIcon component={PdfIconSvg} viewBox="0 0 24 24" fontSize="large" {...props} />;
export const ExcelIcon = (props) => (
    <SvgIcon component={ExcelIconSvg} viewBox="0 0 24 24" fontSize="large" {...props} />
);
