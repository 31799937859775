import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { fieldToTextField } from "formik-material-ui";
import React from "react";
import "./FormikAutocomplete.scss";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

const FormikAutocomplete = ({ textFieldProps, ...props }) => {
    const {
        form: { setFieldValue },
    } = props;
    const { error, helperText, ...field } = fieldToTextField(props);
    const { name } = field;
    const { t } = useTranslation();

    return (
        <Autocomplete
            {...props}
            {...field}
            value={field.value || null}
            openText={t(Labels.AUTOCOMPLETE_OPEN)}
            clearText={t(Labels.AUTOCOMPLETE_CLEAR_TEXT)}
            closeText={t(Labels.AUTOCOMPLETE_CLOSE)}
            loadingText={t(Labels.AUTOCOMPLETE_LOADING)}
            noOptionsText={t(Labels.AUTOCOMPLETE_NO_OPTIONS)}
            onChange={(_, value) => {
                setFieldValue(name, value);
                props.onChange && props.onChange(_, value);
            }}
            onBlur={(event) => {
                field.onBlur(event);
            }}
            renderInput={(props) => {
                const startAdornment = props.InputProps.startAdornment;
                props.InputProps.startAdornment = undefined;

                return (
                    <>
                        <TextField
                            {...props}
                            {...textFieldProps}
                            InputProps={{
                                ...props.InputProps,
                                ...textFieldProps.InputProps,
                            }}
                            inputProps={{
                                ...textFieldProps.inputProps,
                                ...props.inputProps,
                                autoComplete: "nope",
                            }}
                            helperText={helperText}
                            error={!!error}
                        />
                        <div className={"autocomplete-final-adorment"}> {startAdornment}</div>
                    </>
                );
            }}
        />
    );
};

export default FormikAutocomplete;
