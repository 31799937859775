import React from "react";
import ReactEcharts from "echarts-for-react";
import { useDispatch, useSelector } from "react-redux";
import dashboardChartsAction from "~/actions/dashboardVehicle/dashboardChartsAction";
import MedidorCinza from "~/assets/icons/dashboard/icon-medidor-cinza.svg";
import Arrow from "~/assets/images/arrow-up-cinza.png";
import { t } from "~/config/language/i18n";
import Labels from "~/helpers/enums/Labels";
import { brazilianStringNumberToFloat } from "~/helpers/Utils";

export default function KmRunChartPie() {
    const dispatch = useDispatch();
    const { kmRun: data, distancia, isVisibleDetailsKmRun } = useSelector((state) => state.dashboardVehicleTravels);
    const { waitingTravel } = useSelector((state) => state.app.loading);

    const getOption = () => {
        return {
            legend: {
                orient: "vertical",
                icon: "circle",
                top: 0,
                right: 0,
                align: "left",
                itemGap: 2,
                textStyle: {
                    fontSize: 15,
                },
            },
            tooltip: {
                show: true,
                formatter: function (params) {
                    const circleColor = params.color.colorStops[0].color;
                    const circle =
                        '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
                        circleColor +
                        '"></span>';
                    const header = "<span>" + circle + " " + params.name + "</span>";

                    const body = `</br> <span>${t(
                        Labels.DASHBOARD_CHARTS_FORMATTER_TOTAL
                    )}: ${brazilianStringNumberToFloat(params.data.fullValue)} km</span>
					</br> <span>${t(Labels.DASHBOARD_FLEET_EVOLUTION_TAB_PERCENT)}: ${params.value}%  </span>`;

                    return header + body;
                },
            },
            graphic: [
                {
                    type: "image",
                    cursor: "default",
                    right: "center",
                    bottom: "55%",
                    style: {
                        image: data.length > 0 ? MedidorCinza : "",
                        width: 60,
                        opacity: 0.8,
                    },
                },
                {
                    type: "text",
                    right: "center",
                    bottom: "35%",
                    cursor: "default",
                    style: {
                        text: data.length > 0 ? `${distancia?.toLocaleString("pt-BR")} km` : "",
                        font: "bold 25px Trebuchet MS",
                        textAlign: "center",
                        fill: "#3D5564",
                    },
                },
            ],
            series: [
                {
                    type: "pie",
                    radius: ["45%", "70%"],
                    center: ["50%", "60%"],
                    avoidLabelOverlap: true,
                    data,
                    width: "auto",
                    height: "auto",
                    hoverOffset: 2,
                    startAngle: 120,
                    label: {
                        formatter: "{c}%",
                        fontSize: 18,
                    },
                    labelLine: {
                        length: 6,
                        lineStyle: {
                            width: 3,
                        },
                    },
                    itemStyle: {
                        borderColor: "rgba(255, 255, 255, 1)",
                        borderWidth: 7.5,
                    },
                },
            ],
        };
    };

    const toggleDetails = () => {
        dispatch(dashboardChartsAction.toggleDetailsKmRun(!isVisibleDetailsKmRun));
    };

    return (
        <>
            <ReactEcharts option={getOption()} showLoading={waitingTravel} loadingOption={{ text: "Carregando" }} />
            <img
                src={Arrow}
                alt="ícone para detalhar os card de km rodados"
                width="20"
                onClick={() => toggleDetails()}
                style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: 13,
                    bottom: 13,
                    transform: isVisibleDetailsKmRun ? "rotate(0deg)" : "rotate(180deg)",
                    transition: "all .5s",
                }}
            />
        </>
    );
}
