import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import googleMapsDataAction from "~/actions/googleMapsDataAction";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import InterestPointMarker from "~/assets/icons/interestPointMarker.svg";
import { getLatLngPathsFromString } from "~/helpers/Utils";

const PointsInterestSyncMap = (props) => {
    const dispatch = useDispatch();

    const [drawingMananger, setDrawingMananger] = useState(null);
    const [marker, setMarker] = useState(null);
    const [fence, setFence] = useState(null);
    const [radiusCircle, setRadiusCircle] = useState(null);

    const { values, setFieldValue, name } = props;
    const { TipoPonto, Endereco, Latitude, Longitude, Raio, Coordenadas, Id } = values;
    const { mapContext } = useSelector((state) => state.googleMapsData);

    const svgOptions = useMemo(() => {
        return {
            fillColor: "#FF0000",
            fillOpacity: 0.45,
            strokeColor: "#AA2143",
            strokeWeight: 2,
            strokeOpacity: 0.8,
            zIndex: 1,
        };
    }, []);

    // Utility Functions

    const updateValues = useCallback(
        (latLng) => {
            if (TipoPonto === "Ponto") {
                setFieldValue("Latitude", latLng.lat);
                setFieldValue("Longitude", latLng.lng);
            }

            getGeocode({
                location: latLng,
            }).then((result) => {
                setFieldValue("Endereco", {
                    sugestion: { description: result[0].formatted_address },
                });
            });
        },
        [setFieldValue, TipoPonto]
    );

    const createMarker = useCallback(
        (latLng, updateFunction, draggable) => {
            const newMarker = new window.google.maps.Marker({
                position: latLng,
                map: mapContext,
                zIndex: 2,
            });

            newMarker.setIcon({
                url: InterestPointMarker,
            });

            mapContext.setCenter(latLng);
            newMarker.setDraggable(draggable);

            newMarker.addListener("dragend", (eventPosition) => {
                updateFunction && updateFunction({ lat: eventPosition.latLng.lat(), lng: eventPosition.latLng.lng() });
            });

            let bounds = new window.google.maps.LatLngBounds();
            bounds.extend(latLng);
            mapContext.fitBounds(bounds);

            setMarker((oldMarker) => {
                oldMarker && oldMarker.setMap(null);
                return newMarker;
            });
        },
        [mapContext, setMarker]
    );

    // Clean change form type function

    useEffect(() => {
        if (!mapContext) return;

        if (TipoPonto === "Ponto") {
            drawingMananger && drawingMananger.setMap(null);
            fence && fence.setMap(null);
            radiusCircle && radiusCircle.setMap(mapContext);

            if ((!Latitude || !Longitude) && Endereco.sugestion && Endereco.sugestion.description) {
                getGeocode({ address: Endereco.sugestion.description })
                    .then((results) => getLatLng(results[0]))
                    .then((latlng) => {
                        setFieldValue("Latitude", latlng.lat);
                        setFieldValue("Longitude", latlng.lng);
                        createMarker(latlng, updateValues, true);
                    });
            }
        } else if (TipoPonto === "Poligono") {
            dispatch(googleMapsDataAction.cleanEventsOnMap());

            fence && fence.setMap(mapContext);
            radiusCircle && radiusCircle.setMap(null);
        }
    }, [
        dispatch,
        mapContext,
        TipoPonto,
        Endereco,
        Latitude,
        Longitude,
        drawingMananger,
        fence,
        radiusCircle,
        setFieldValue,
        updateValues,
        createMarker,
    ]);

    // Point form functions

    useEffect(() => {
        if (TipoPonto !== "Ponto") return;

        dispatch(
            googleMapsDataAction.addEventOnMap(name, "rightclick", (event, map) => {
                if (marker) marker.setMap(null);
                const latLng = { lat: event.latLng.lat(), lng: event.latLng.lng() };
                updateValues(latLng);
                createMarker(latLng, updateValues, true);
            })
        );

        return () => {
            dispatch(googleMapsDataAction.cleanEventsOnMap());
        };
    }, [updateValues, createMarker, name, dispatch, TipoPonto, marker]);

    useEffect(() => {
        dispatch(
            googleMapsDataAction.addSyncFunc(name, (placesInfo) => {
                dispatch(googleMapsDataAction.cleanEventsOnMap());
                if (marker) marker.setMap(null);

                if (!placesInfo.latlng.lat) return;
                createMarker(placesInfo.latlng, updateValues, true);
            })
        );
    }, [dispatch, updateValues, createMarker, name, TipoPonto, marker]);

    useEffect(() => {
        if (!Id || marker || TipoPonto !== "Ponto") return;
        createMarker({ lat: Latitude, lng: Longitude }, updateValues, true);
    }, [Id, marker, TipoPonto, Latitude, Longitude, createMarker, updateValues]);

    // Point Radius function

    useEffect(() => {
        if (!Latitude || !Longitude || !Raio) return;

        const _radiusCircle = new window.google.maps.Circle({
            ...svgOptions,
            map: mapContext,
            center: { lat: Latitude, lng: Longitude },
            radius: Raio,
            editable: true,
        });

        _radiusCircle.addListener("center_changed", () => {
            if (marker) marker.setMap(null);

            const center = _radiusCircle.getCenter();
            const latLng = { lat: center.lat(), lng: center.lng() };

            updateValues(latLng);
            createMarker(latLng, updateValues, true);
        });

        _radiusCircle.addListener("radius_changed", () => {
            setFieldValue("Raio", Math.ceil(_radiusCircle.getRadius()));
        });

        mapContext.fitBounds(_radiusCircle.getBounds());

        setRadiusCircle((oldRadiusCircle) => {
            oldRadiusCircle && oldRadiusCircle.setMap(null);
            return _radiusCircle;
        });
    }, [
        marker,
        mapContext,
        Latitude,
        Longitude,
        Raio,
        setFieldValue,
        svgOptions,
        createMarker,
        setRadiusCircle,
        updateValues,
    ]);

    // Fence form functions

    const updateFenceValue = useCallback(
        (shape) => {
            setFieldValue(
                "Coordenadas",
                shape
                    .getPath()
                    .getArray()
                    .map((latlng) => `${latlng.lat()},${latlng.lng()}`)
                    .join(";")
            );
        },
        [setFieldValue]
    );

    const applyTriggersOnFence = useCallback(
        (shape) => {
            const path = shape.getPath();
            ["insert_at", "set_at", "remove_at"].forEach((ev) =>
                path.addListener(ev, (_, __) => {
                    updateFenceValue(shape);
                })
            );
        },
        [updateFenceValue]
    );

    useEffect(() => {
        if (TipoPonto !== "Poligono" || !mapContext) return;

        if (fence) {
            setDrawingMananger((oldDrawingMananger) => {
                oldDrawingMananger && oldDrawingMananger.setMap(null);
                return null;
            });
            return;
        }

        let options = {
            drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
            drawingControl: false,
        };

        const shapeOptions = {
            ...svgOptions,
            editable: true,
        };

        options["polygonOptions"] = shapeOptions;

        const _drawingManager = new window.google.maps.drawing.DrawingManager(options);
        _drawingManager.setMap(mapContext);

		

        setDrawingMananger((oldDrawingMananger) => {
            oldDrawingMananger && oldDrawingMananger.setMap(null);
            return _drawingManager;
        });

        window.google.maps.event.addListener(_drawingManager, "overlaycomplete", (e) => {
            if (e.type !== window.google.maps.drawing.OverlayType.MARKER) {
                _drawingManager.setDrawingMode(null);
            }

            const shape = e.overlay;

            setFence((oldFence) => {
                oldFence && oldFence.setMap(null);
                return shape;
            });

            applyTriggersOnFence(shape);
            updateFenceValue(shape);
        });
    }, [
        dispatch,
        mapContext,
        TipoPonto,
        marker,
        radiusCircle,
        setFieldValue,
        fence,
        svgOptions,
        applyTriggersOnFence,
        updateFenceValue,
        setDrawingMananger,
    ]);

    useEffect(() => {
        if (!Id || !mapContext || !Coordenadas || fence) return;
        const paths = getLatLngPathsFromString(Coordenadas);

        const bounds = new window.google.maps.LatLngBounds();
        paths.forEach((latlng) => {
            bounds.extend(latlng);
        });
        mapContext.fitBounds(bounds);

        const _fence = new window.google.maps.Polygon({
            ...svgOptions,
            map: mapContext,
            paths: paths,
            editable: true,
        });

        setFence(_fence);
        applyTriggersOnFence(_fence);
    }, [Id, mapContext, fence, Coordenadas, svgOptions, updateValues, applyTriggersOnFence]);

    return null;
};

export default PointsInterestSyncMap;
