import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
    periodTrips: [],
    roadOffset: [],
    kmRun: [],
    isVisibleDetailsKmRun: false,
    distancia: 0,
    distanciaMedia: 0,
    distanciaComercial: 0,
    distanciaComercialMedia: 0,
    distanciaFimSemana: 0,
    distanciaFimSemanaMedia: 0,
    distanciaNaoComercial: 0,
    distanciaNaoComercialMedia: 0,
    qtdAceleracaoBrusca: 0,
    qtdCurvaAgressiva: 0,
    qtdFreadaBrusca: 0,
    qtdLimiteVelocidadeVia: 0,
    qtdViagemNaoComercial: 0,
    qtdViagemFimSemana: 0,
};

function dashboardTravelsReducer(state = INITIAL_STATE, action = null) {
    switch (action.type) {
        case Constants.GET_PERIOD_TRIPS:
            return {
                ...state,
                periodTrips: action.payload,
            };
        case Constants.GET_ROAD_OFFSET:
            return {
                ...state,
                roadOffset: action.payload,
            };
        case Constants.GET_KM_RUN:
            return {
                ...state,
                kmRun: action.payload.kmRun,
                distancia: action.payload.Distancia,
                distanciaMedia: action.payload.DistanciaMedia,
            };
        case Constants.TOGGLE_DETAILS_KM_RUN:
            return {
                ...state,
                isVisibleDetailsKmRun: action.payload,
            };
        case Constants.GET_KM_RUN_INDICATOR:
            return {
                ...state,
                distanciaComercial: action.payload.DistanciaComercial,
                distanciaComercialMedia: action.payload.DistanciaComercialMedia,
                distanciaFimSemana: action.payload.DistanciaFimSemana,
                distanciaFimSemanaMedia: action.payload.DistanciaFimSemanaMedia,
                distanciaNaoComercial: action.payload.DistanciaNaoComercial,
                distanciaNaoComercialMedia: action.payload.DistanciaNaoComercialMedia,
            };
        case Constants.GET_ANALYSIS_ITEMS:
            return {
                ...state,
                qtdAceleracaoBrusca: action.payload.QtdAceleracaoBrusca,
                qtdCurvaAgressiva: action.payload.QtdCurvaAgressiva,
                qtdFreadaBrusca: action.payload.QtdFreadaBrusca,
                qtdLimiteVelocidadeVia: action.payload.QtdLimiteVelocidadeVia,
                qtdViagemNaoComercial: action.payload.QtdViagemNaoComercial,
                qtdViagemFimSemana: action.payload.QtdViagemFimSemana,
            };
        case Constants.CLEAN_DASHBOARD_TRAVELS:
            return INITIAL_STATE;
        default:
            return state;
    }
}

export default dashboardTravelsReducer;
