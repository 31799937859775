import moment from "moment";
//! transparent linear-gradient(302deg, #6EC8AF 0%, #6EC8B1 46%, #64C8FF 100%)
const baseColorSolid = "#6EC8AF";
export const baseColors = {
	type: "linear",
	x: 0,
	y: 0,
	x2: 0,
	y2: 1,
	colorStops: [
		{
			offset: 0,
			color: baseColorSolid, // color at 0% position
		},
		{
			offset: 0.46,
			color: "#6EC8B1", // color at 0% position
		},
		{
			offset: 1,
			color: "#64C8FF", // color at 100% position
		},
	],
	global: false,
};

//! transparent linear-gradient(209deg, #FFDA5D 0%, #FF9464 100%)
const veiculoColorSolid = "#FFDA5D";
export const veiculoColors = {
	type: "linear",
	x: 0,
	y: 0,
	x2: 0,
	y2: 1,
	colorStops: [
		{
			offset: 0,
			color: veiculoColorSolid, // color at 0% position
		},
		{
			offset: 1,
			color: "#FF9464", // color at 100% position
		},
	],
	global: false,
};

const formatDay = (dateStart, dateEnd) => {
	return `${moment(dateStart).format("DD/MM/YYYY")} - ${moment(dateEnd).format("DD/MM/YYYY")}`;
};

const customChart = ({ data }) => {
	const payload = data.map((values) => ({
		Intervalo: formatDay(values.DataInicial, values.DataFinal),
		Urbano: values.DistanciaUrbano,
		Rodoviário: values.DistanciaRodoviario,
	}));

	return payload;
};

const extractData = ({ data, ...rest }) => {
	const { Base, Veiculo } = data;

	const payload = {
		Base,
		Veiculo,
	};
	return payload;
};

export default { extractData, customChart };
