import React from "react";
import { Box, Typography } from "@material-ui/core";
import { DatePicker } from "formik-material-ui-pickers";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

import "~/components/reporting/Reporting.scss";
import "~/components/reporting/common/ReportingCommon.scss";

const ReportDateField = (props) => {
    const { t } = useTranslation();

    const { label, ...fieldProps } = props;

    return (
        <Box className="report-field">
            <Typography variant="subtitle1" className="report-field-label">
                {label}
            </Typography>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
                <Field
                    component={DatePicker}
                    inputVariant="outlined"
                    variant="inline"
                    format="DD/MM/yyyy"
                    placeholder="   /   /   "
                    margin="dense"
                    fullWidth
                    minDateMessage={t(Labels.LESS_MINIMAL)}
                    {...fieldProps}
                />
            </MuiPickersUtilsProvider>
        </Box>
    );
};

export default ReportDateField;
