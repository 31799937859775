import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Button, Grid, Box, Typography } from "@material-ui/core";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import routeAction from "~/actions/routeAction";
import Page from "~/components/common/page/Page";
import { AddIcon } from "~/components/common/icons/Icons";
import MapCard from "~/components/map/MapCard";
import RoutesGrid from "~/components/route/finder/RoutesGrid";
import ExpansionCard from "~/components/common/expansionCard/ExpansionCard";
import ExpansionGrid from "~/components/common/expansionCard/ExpansionGrid";
import ExpansionCardEnum from "~/components/common/expansionCard/ExpansionCardEnum";
import Search from "~/components/common/search/Search";
import OptionExportButton from "~/components/common/exportButton/OptionExportButton";

import "./FinderRoutesView.scss";

const FinderRoutesView = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const [alignment, setAlignment] = useState(ExpansionCardEnum.HORIZONTAL);
    const { routes, searchField } = useSelector((state) => state.route);
    const loading = useSelector((state) => state.app.loading);

    useEffect(() => {
		return () => {
			dispatch(routeAction.cleanSearch());
		};
	}, [dispatch]);

    return (
        <Page
            topButtons={
                <div id="export-container">
                    <OptionExportButton
                        loading={loading.requestObjectDoc}
                        onClick={(type) => {
                            dispatch(routeAction.exportRoutes({ Formato: type.value, Search: searchField }));
                        }}
                    />
                </div>
            }>
            <main>
                <Container id="container-finder-routes-view" className="container-map-view">
                    <Grid container direction="row" alignItems="stretch" spacing={0}>
                        <Grid item xs={4}>
                            <Box
                                style={{
                                    margin: "1rem",
                                }}>
                                <Button
                                    id="add-button-route"
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    fullWidth
                                    className="btn-add-group-items btn-add-group-items-smaller"
                                    onClick={() => {
                                        history.push("/register/route/");
                                    }}>
                                    <Typography className="import-font">
                                        <AddIcon />
                                        {t(Labels.ROUTE_ADD_ROUTE)}
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <ExpansionGrid alignment={alignment} fnClick={setAlignment} style={{}}>
                                <ExpansionCard
                                    title={t(Labels.ROUTE_LIST_TITLE)}
                                    className="routes-grid card-group-refactor"
                                    flex={"0 1 36%"}
                                    key={"1"}
                                    alignment={alignment}
                                    fnClick={setAlignment}>
                                    <Box className="card-group-header" id="routes-search-header">
                                        <Search
                                            id="search-routes"
                                            search={(value) => {
                                                dispatch(routeAction.searchName(routes, value))
                                            }}
                                            className="card-group-search"
                                            classNameRefactor="search-refactor"
                                        />
                                    </Box>
                                    <RoutesGrid mode={alignment} />
                                </ExpansionCard>
                                <ExpansionCard
                                    title={t(Labels.ROUTE_MAP)}
                                    notPadded
                                    className="expansion-map-card card-group-refactor"
                                    flex={"1 0 64%"}
                                    key={"2"}
                                    alignment={alignment}
                                    fnClick={setAlignment}>
                                    <div className="mapScreen">
                                        <MapCard className="map-card-refactor" />
                                    </div>
                                </ExpansionCard>
                            </ExpansionGrid>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </Page>
    );
};

export default FinderRoutesView;
