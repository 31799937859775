import Constants from "~/helpers/enums/Constants";
import ReportTypeEnum from "~/helpers/enums/ReportTypeEnum";
import reportingService from "~/services/reportingService";
import Utils from "~/helpers/Utils";

const clearAutoComplete = (flag, name) => (dispatch) => {
    if (flag !== undefined) {
        dispatch({
            type: Constants.CLEAR_AUTO_COMPLETE,
            payload: {
                clearFlag: flag,
                nameField: name,
            },
        });
    }
};

const clearAll = (flag) => (dispatch) => {
    if (flag !== undefined) {
        dispatch({
            type: Constants.CLEAR_ALL,
            payload: {
                clear: flag,
            },
        });
    }
};

const generateReportingPerPeriod = (filter, fnCallback = () => {}, LOADING_IDENTIFICATOR = "requestPeriod") => (
    dispatch
) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return reportingService
        .generateReportingPerPeriod(filter)
        .then((response) => {
            fnCallback(response.data);
        })
        .catch((error) => {
            console.log(error);
            fnCallback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const generateReportingPerPeriodDynamic = (filter, fnCallback = () => {}) => (dispatch) => {
    return reportingService
        .generateReportingPerPeriodDynamic(filter)
        .then((response) => {
            dispatch(
                getReportingPerPeriod({
                    relatorioId: filter.RelatorioTipoId,
                    Start: 0,
                    Index: 0,
                    Length: 10,
                })
            );
        })
        .catch((error) => {
            console.log(error);
        });
};

const getReportingPerPeriod = (params) => (dispatch) => {
    return reportingService
        .getReportingPerPeriod(params)
        .then((response) => {
            const { index, recordsTotal, recordsFiltered, data } = response;
            //TODO Apply pagination
            const pagination = {
                index,
                recordsTotal,
                recordsFiltered,
            };
            dispatch({ type: Constants.GET_REPORTING_PER_PERIOD, payload: { data: data.data, pagination } });
        })
        .catch((error) => {
            console.log(error);
        });
};

const getReportPerPeriod = (params) => (dispatch) => {
    return reportingService
        .getReportingPerPeriod(params)
        .then((response) => {
            const { index, recordsTotal, recordsFiltered, data } = response.data;
            const page = {
                index,
                recordsTotal,
                recordsFiltered,
            };

            dispatch({ type: Constants.GET_REPORTING_PER_PERIOD, payload: { data: data, page } });
        })
        .catch((error) => {
            console.log(error);
        });
};

const updatePaginationPerPeriod = (pagination) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_PAGINATION_PER_PERIOD, payload: { pagination } });
};

const getDistanceReportSent = (params) => (dispatch) => {
    const reportTypeId = ReportTypeEnum.TRAVELLED_DISTANCE.id;
    return reportingService
        .getReportSentByType(params, reportTypeId)
        .then((response) => {
            const { index, recordsTotal, recordsFiltered, data } = response;
            //TODO Apply pagination
            const pagination = {
                index,
                recordsTotal,
                recordsFiltered,
            };
            dispatch({ type: Constants.GET_DISTANCE_REPORT_SENT, payload: { data: data.data, pagination } });
        })
        .catch((error) => {
            console.log(error);
        });
};

const getTravelsReportSent = (params) => (dispatch) => {
    const reportTypeId = ReportTypeEnum.TRAVELS.id;
    return reportingService
        .getReportSentByType(params, reportTypeId)
        .then((response) => {
            const { index, recordsTotal, recordsFiltered, data } = response;
            //TODO Apply pagination
            const pagination = {
                index,
                recordsTotal,
                recordsFiltered,
            };
            dispatch({ type: Constants.GET_TRAVELS_REPORT_SENT, payload: { data: data.data, pagination } });
        })
        .catch((error) => {
            console.log(error);
        });
};
const getSpeedingReportSent = (params) => (dispatch) => {
    const reportTypeId = ReportTypeEnum.SPEEDING_ON_ROAD.id;
    return reportingService
        .getReportSentByType(params, reportTypeId)
        .then((response) => {
            const { index, recordsTotal, recordsFiltered, data } = response;
            //TODO Apply pagination
            const pagination = {
                index,
                recordsTotal,
                recordsFiltered,
            };
            dispatch({ type: Constants.GET_SPEEDING_REPORT_SENT, payload: { data: data.data, pagination } });
        })
        .catch((error) => {
            console.log(error);
        });
};

const getDistanceReportScheduling = (params) => (dispatch) => {
    const reportTypeId = ReportTypeEnum.TRAVELLED_DISTANCE.id;

    return reportingService
        .getReportSchedulingByType(params, reportTypeId)
        .then((response) => {
            const { data } = response.data;
            dispatch({ type: Constants.GET_DISTANCE_REPORT_SCHEDULING, payload: { data } });
        })
        .catch((error) => {
            console.log(error);
        });
};

const getTravelsReportScheduling = (params) => (dispatch) => {
    const reportTypeId = ReportTypeEnum.TRAVELS.id;

    return reportingService
        .getReportSchedulingByType(params, reportTypeId)
        .then((response) => {
            const { data } = response.data;
            dispatch({ type: Constants.GET_TRAVELS_REPORT_SCHEDULING, payload: { data } });
        })
        .catch((error) => {
            console.log(error);
        });
};

const getSpeedingReportScheduling = (params) => (dispatch) => {
    const reportTypeId = ReportTypeEnum.SPEEDING_ON_ROAD.id;

    return reportingService
        .getReportSchedulingByType(params, reportTypeId)
        .then((response) => {
            const { data } = response.data;
            dispatch({ type: Constants.GET_SPEEDING_REPORT_SCHEDULING, payload: { data } });
        })
        .catch((error) => {
            console.log(error);
        });
};

const getDistanceReportSchedulingById = (id) => (dispatch) => {
    return reportingService
        .getDistanceReportSchedulingById(id)
        .then((response) => {
            const { data } = response;

            dispatch({ type: Constants.EDIT_DISTANCE_REPORT_SCHEDULING, payload: data.data });
        })
        .catch((error) => {
            console.log(error);
        });
};

const getReportingPerObject = (params, callback = () => {}, LOADING_IDENTIFICATOR = "requestDistanceObject") => (
    dispatch
) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return reportingService
        .generateReportingPerObject(params)
        .then(({ data }) => {
            callback();
            if (data.data) {
                dispatch({
                    type: Constants.GET_REPORTING_PER_OBJECT,
                    payload: {
                        data: data,
                    },
                });
            } else if (data.Message) {
                if (!data.Success) {
                    Utils.showError(data.Message);
                    return;
                }
                Utils.downloadToLink(data.Message);
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const saveDistanceReportScheduling = (report, callback = () => {}) => (dispatch) => {
    const reportTypeId = ReportTypeEnum.TRAVELLED_DISTANCE.id;
    return reportingService
        .saveDistanceReportScheduling(report, reportTypeId)
        .then((response) => {
            callback(false, response);
        })
        .catch((error) => {
            callback(error);
            console.error(error);
        });
};

const deleteReportScheduling = (id, fnCallback) => (dispatch) => {
    return reportingService
        .deleteReportScheduling(id)
        .then((response) => {
            fnCallback(response);
        })
        .catch((error) => {
            console.log(error);
        });
};

const finishEditionDistanceReportScheduling = () => (dispatch) => {
    dispatch({ type: Constants.EDIT_DISTANCE_REPORT_SCHEDULING, payload: {} });
};

const requestReportingPerObject = (params, callback = () => {}, LOADING_IDENTIFICATOR = "requestObject") => (
    dispatch
) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return reportingService
        .requestReportingPerObject(params)
        .then((response) => {
            let data = response.data;

            let payload = {
                data: data,
            };

            dispatch({ type: Constants.REQUEST_REPORTING_PER_OBJECT, payload });
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const updateReportingDataFilter = (filter) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FILTER_EVENT_PERIOD, payload: { filter } });
};

const updateFilterEventLastRecord = (id) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FILTER_EVENT_LAST_RECORD, payload: { id } });
};

export default {
    clearAutoComplete,
    clearAll,
    getReportingPerPeriod,
    updatePaginationPerPeriod,
    generateReportingPerPeriod,
    getReportingPerObject,
    getDistanceReportScheduling,
    getDistanceReportSchedulingById,
    getDistanceReportSent,
    getTravelsReportSent,
    getSpeedingReportSent,
    saveDistanceReportScheduling,
    deleteReportScheduling,
    finishEditionDistanceReportScheduling,
    getTravelsReportScheduling,
    getSpeedingReportScheduling,
    requestReportingPerObject,
    updateReportingDataFilter,
    getReportPerPeriod,
    generateReportingPerPeriodDynamic,
    updateFilterEventLastRecord,
};
