import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Button, CircularProgress } from "@material-ui/core";
import Page from "~/components/common/page/Page";
import Labels from "~/helpers/enums/Labels";
import { ComeBackIcon } from "~/components/common/icons/Icons";
import { useTranslation } from "react-i18next";
import ProfileForm from "~/components/profile/ProfileForm";
import profileAction from "~/actions/profileAction";
import { useHistory } from "react-router-dom";

import "./ProfileView.scss";

const ProfileView = (props) => {
    const dispatch = useDispatch();
    let history = useHistory();

    const { t } = useTranslation();
    const { profile } = useSelector((state) => state.profile);
    const { language } = useSelector((state) => state.language);

    useEffect(() => {
        dispatch(profileAction.getProfile());
        return () => {
            dispatch(profileAction.cleanProfile());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(profileAction.getFusosByCountry("BR", language));
    }, [dispatch, language]);

    const comeBack = () => {
        history.goBack();
    };

    return (
        <Page
            topButtons={
                <Button
                    className="comeback-button"
                    id="extractReport"
                    variant="contained"
                    size="small"
                    disableElevation
                    startIcon={<ComeBackIcon />}
                    onClick={() => {
                        comeBack();
                    }}>
                    {t(Labels.PROFILE_COMEBACK)}
                </Button>
            }>
            <main>
                <Container maxWidth="lg" className="container-profile-view">
                    {profile ? <ProfileForm profile={profile} comeBack={comeBack} /> : <CircularProgress />}
                </Container>
            </main>
        </Page>
    );
};

export default ProfileView;
