import React from 'react';

import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

import { Typography } from '@material-ui/core';

import Card from "~/components/common/expansionCard/Card";

import { InfoIcon } from '~/components/common/icons/Icons';

const GridInfo = () => {
  const { t } = useTranslation();

  return (
    <Card
      title={
        <span className="import-title-info">
          <span>
            <InfoIcon />
          </span>
          {t(Labels.IMPORT_INFO_TITLE)}
        </span>
      } className="card-import card-import-info"
    >
      <div className="main-info">
        <div className="item-info">
          <span>
            <Typography>
            {t(Labels.TITLE_COLUMN_IMPORT)}
            </Typography>
          </span>
          <div className="item-info-internal">
            <span>
              <Typography>
              {t(Labels.LABEL_NAME_COLUMN_IMPORT)}
            </Typography>
            </span>
            <span>
              <Typography>
              {t(Labels.LABEL_TYPE_IMPORT)}
            </Typography>
            </span>
            <span>
              <Typography>
              {t(Labels.LABEL_TYPE_ELEMENT_IMPORT)}
            </Typography>
            </span>
          </div>
        </div>
        <div className="item-info">
          <span>
            <Typography>
            {t(Labels.TITLE_COLUMN_POINT_IMPORT)}
            </Typography>
          </span>
          <div className="item-info-internal">
            <span>
              <Typography>
              {t(Labels.LABEL_LAT_IMPORT)}
            </Typography>
            </span>
            <span>
              <Typography>
              {t(Labels.LABEL_LONG_IMPORT)}
            </Typography>
            </span>
          </div>
        </div>
        <div className="item-info">
          <span>
            <Typography>
            {t(Labels.TITLE_COLUMN_FENCE_IMPORT)}
            </Typography>
          </span>
          <div className="item-info-internal">
            <span>
              <Typography>
              {t(Labels.LABEL_COORDINATES_IMPORT)}
              </Typography>
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default GridInfo;
