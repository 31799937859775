import React, { useState, useRef, useEffect } from "react";
import { Stepper, Step, StepLabel, Button, CircularProgress, makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import VehicleSelection from "~/views/Renew/VehicleSelection";
import AddressContact from "~/views/Renew/AddressContact";
import ContractSummary from "~/views/Renew/ContractSummary";
import renewalActions from "~/actions/renewalActions";
import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import vehicleRenewalService from "~/services/vehicleRenewalService";
import RenewInfoAlert from "~/components/common/renewInfoAlert/renewInfoAlert";
import "./styles.scss";

const steps = ["Veículos", "Endereço e Contatos", "Resumo do Pedido"];

const useStyles = makeStyles((theme) => ({
    stepLabel: {
        fontSize: "20px",
        display: "flex",
        alignItems: "center",
    },
    icon: {
        marginRight: theme.spacing(1),
    },
}));

const StepForm = ({ contractDetails, vehicles, onBack }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [selectedVehicles, setSelectedVehicles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();
    let history = useHistory();
    const { contact } = useSelector((state) => state.renewalReducer);
    const {
        user: { colors },
    } = useSelector((state) => state.auth);
    const classes = useStyles();

    const activeBgColor = colors.topBarColor;
    const inactiveBgColor = "#999999";

    const addressContactRef = useRef(null);
    const [contactInfo, setContactInfo] = useState({
        celular: "",
        email: "",
        cep: "",
        rua: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        estado: "",
    });
    const [dueDate, setDueDate] = useState("");
    const allSelected = selectedVehicles?.length === vehicles?.length;

    useEffect(() => {
        if (contact) {
            setContactInfo({
                celular: contact.Telefone || "",
                email: contact.Email || "",
                cep: "",
                rua: "",
                numero: "",
                complemento: "",
                bairro: "",
                cidade: "",
                estado: "",
            });
        }
    }, [contact]);

    const handleNext = () => {
        if (activeStep === 1 && addressContactRef.current) {
            const valid = addressContactRef.current.submitForm();
            if (!valid) return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            onBack();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleVehicleSelection = (vehicleIndex) => {
        if (selectedVehicles.includes(vehicleIndex)) {
            setSelectedVehicles(selectedVehicles.filter((v) => v !== vehicleIndex));
        } else {
            setSelectedVehicles([...selectedVehicles, vehicleIndex]);
        }
    };

    const selectAll = (event) => {
        if (event.target.checked) {
            setSelectedVehicles(vehicles);
        } else {
            setSelectedVehicles([]);
        }
    };

    const handleDateChange = (event) => {
        setDueDate(event.target.value);
    };

    const save = async () => {
        setIsLoading(true);
        const payload = {
            produtoContratoId: contractDetails.id,
            diaPagamento: dueDate || "30",
            cep: contactInfo.cep,
            rua: contactInfo.rua,
            numero: contactInfo.numero,
            complemento: contactInfo.complemento || "",
            bairro: contactInfo.bairro,
            cidade: contactInfo.cidade,
            estado: contactInfo.estado,
            telefone: contactInfo.celular,
            email: contactInfo.email,
            objetoRatreavelId: selectedVehicles.map((vehicle) => vehicle.ObjetoRastreavelId),
        };

        try {
            await vehicleRenewalService.saveRenewal(payload);
            dispatch(renewalActions.getRenewal());
            setIsModalOpen(true);
        } catch (error) {
            Utils.showTranslatedToast({
                type: Constants.ERROR,
                description: "Ops, parece que houve um erro! tente novamente mais tarde!",
            });
        } finally {
            setIsLoading(false);
        }
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <VehicleSelection
                        selectedVehicles={selectedVehicles}
                        handleVehicleSelection={handleVehicleSelection}
                        vehicles={vehicles}
                        selectAll={selectAll}
                        allSelected={allSelected}
                    />
                );
            case 1:
                return (
                    <AddressContact ref={addressContactRef} contactInfo={contactInfo} setContactInfo={setContactInfo} />
                );
            case 2:
                return (
                    <ContractSummary
                        vehicles={selectedVehicles}
                        dueDate={dueDate}
                        onDateChange={handleDateChange}
                        planPrice={contractDetails.valorMensal}
                        duration={contractDetails.vigencia}
                    />
                );
            default:
                return "Unknown step";
        }
    };

    const validation = () => {
        switch (activeStep) {
            case 0:
                return selectedVehicles.length === 0;
            case 2:
                return !dueDate;
            default:
                return false;
        }
    };

    const getStepIcon = (index) => {
        const stepIcons = [
            <DirectionsCarIcon className="step-icon" style={{ fontSize: 30 }} />,
            <ContactMailIcon className="step-icon" style={{ fontSize: 30 }} />,
            <ShoppingCartIcon className="step-icon" style={{ fontSize: 30 }} />,
        ];

        return (
            <div
                className={`step-icon-wrapper ${activeStep === index ? "active" : "inactive"}`}
                style={{
                    color: activeStep === index ? activeBgColor : inactiveBgColor,
                }}>
                {stepIcons[index]}
            </div>
        );
    };

    return (
        <div className="step-container">
            <Stepper style={{ backgroundColor: "transparent" }} activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel
                            StepIconComponent={() => getStepIcon(index)}
                            className={activeStep === index ? "step-label active" : "step-label inactive"}
                            style={{
                                color: activeStep === index ? activeBgColor : inactiveBgColor,
                            }}
                            classes={{ label: classes.stepLabel }}>
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {getStepContent(activeStep)}
                <div className="button-container">
                    <Button
                        style={{ fontSize: "16px", marginRight: 10 }}
                        disabled={isLoading}
                        onClick={handleBack}>
                        Voltar
                    </Button>
                    <Button
                        style={{ fontSize: "16px" }}
                        variant="contained"
                        color="primary"
                        disabled={validation() || isLoading}
                        onClick={activeStep === steps.length - 1 ? save : handleNext}>
                        {isLoading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : activeStep === steps.length - 1 ? (
                            "Contratar"
                        ) : (
                            "Próximo"
                        )}
                    </Button>
                </div>
            </div>

            <RenewInfoAlert
                isOpen={isModalOpen}
                onClose={() => {
                    history.push(`/`, { notShowPopup: true });
                    setIsModalOpen(false);
                }}
            />
        </div>
    );
};

export default StepForm;
