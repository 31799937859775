import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
  loading: {},
  pageToolbar: false
};

function appReducer(state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case Constants.UPDATE_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.loading,
        },
      };
      case Constants.TOOGLE_TOOLBAR:
        return {
          ...state,
          pageToolbar: action.payload,
        };
    default:
      return state;
  }
}

export default appReducer;
