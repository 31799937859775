// REGISTER
import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import React from "react";
import AlertPopup from "~/components/alert/alertPopup/AlertPopup";

import alertService from "~/services/alertService";

const getListAlerts = (filter, LOADING_IDENTIFICATOR = "getListAlerts") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertService
        .searchAlertList(filter)
        .then((res) => {
            let payload = res;
            dispatch({ type: Constants.LIST_ALERTS, payload });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getAlertsLinkedTrue = (filter, LOADING_IDENTIFICATOR = "getListAlerts") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertService
        .searchAlertLinked(filter)
        .then((res) => {
            let payload = res;
            dispatch({ type: Constants.LIST_ALERTS_LINKED_TRUE, payload });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getAlertsLinkedFalse = (filter, LOADING_IDENTIFICATOR = "getListAlerts") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertService
        .searchAlertLinked(filter)
        .then((res) => {
            let payload = res;
            dispatch({ type: Constants.LIST_ALERTS_LINKED_FALSE, payload });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const postAlertsLinked = (data, callback = () => {}, LOADING_IDENTIFICATOR = "getListAlerts") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertService
        .saveAlertBond(data)
        .then((res) => {
            let payload = res;
            dispatch({ type: Constants.LIST_ALERTS_LINKED_SAVE, payload });
            callback();
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getListAlertsByID = (_id, callback = () => {}, LOADING_IDENTIFICATOR = "getListAlertsByID") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertService
        .getAlertById(_id)
        .then((res) => {
            let payload = res.data;
            dispatch({ type: Constants.GET_ALERT_BY_ID, payload });
            callback();
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getAlertAlterHistory = (_id, LOADING_IDENTIFICATOR = "getAlertAlterHistory") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertService
        .getAlertHistory(_id)
        .then((res) => {
            let payload = res.data;
            //@Splice remove the first element from array because is never data for HistoryId in the first position
            payload.splice(0, 1);
            dispatch({ type: Constants.GET_ALERTS_HISTORY, payload });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const importFileAlert = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "importFileAlert") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertService
        .importCarsAlerts(filter)
        .then((response) => {
            let data = response.data;
            let payload = {
                data: data,
            };
            dispatch({ type: Constants.IMPORT_FILE_ALERT, payload });
            callback(data, "info");
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getAlertAlterHistoryId = (_id, LOADING_IDENTIFICATOR = "getAlertAlterHistoryId") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertService
        .getAlertByHistoryId(_id)
        .then((res) => {
            let payload = res.data;
            dispatch({ type: Constants.GET_ALERTS_HISTORY_ID, payload });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const cleanAlertAlterHistory = () => (dispatch) => {
    dispatch({type: Constants.CLEAN_ALERTS_HISTORY});
}

const getNewAlertPopup = (LOADING_IDENTIFICATOR = "getNewAlert") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertService
        .getNewAlertPopup()
        .then(({ data }) => {
            if (data.length > 0) {
                data.forEach((item) => {
                    const toastComponentProps = {
                        placa: item.Placa,
                        nomeAlerta: item.Nome,
                        condicao: item.Condicao,
                    };

                    Utils.showComponentToast(
                        {
                            type: "info",
                            content: <AlertPopup {...toastComponentProps} />,
                        },
                        { autoClose: 30000, position: "bottom-right" }
                    );
                });
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

export default {
    getListAlerts,
    getListAlertsByID,
    importFileAlert,
    getAlertAlterHistoryId,
    getAlertAlterHistory,
    getAlertsLinkedTrue,
    getAlertsLinkedFalse,
    postAlertsLinked,
    getNewAlertPopup,
    cleanAlertAlterHistory
};
