import Constants from "~/helpers/enums/Constants";

const initialState = {
	filter: {
		name: "",
		startDate: "",
		endDate: "",
		page: 0,
		limit: 10,
	},
	logs: [],
	pages: {
		logs: [],
		total: null,
		totalPages: 0,
	}
};

function logReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.SEARCH_PAGED_LOG:
			return {
				...state,
				pages: {
					logs: action.payload.logs,
					total: action.payload.total,
					totalPages: action.payload.totalPages,
				},
			};
		case Constants.UPDATE_LOG_FILTER:
			return {
				...state,
				filter: {
					...state.filter,
					...action.payload.filter,
				},
			};
		default:
			return state;
	}
}

export default logReducer;
