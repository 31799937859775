import http from "~/config/httpRequest/http";
import ReportTypeEnum from "~/helpers/enums/ReportTypeEnum";

const getReportingPerPeriod = (params) => {
    return new Promise((resolve, reject) => {
        http.get(`/ExportarDocumentos/RelatorioStatus`, {
            params: {
                ...params,
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const generateReportingPerPeriod = (filter) => {
    return new Promise((resolve, reject) => {
        http.post(
            `/ExportarDocumentos/GerarRelatorioBackground`,
            {
                RelatorioTipoId: 3,
                Formato: "xlsx",
                ...filter,
            },
            {
                timeout: 120000,
            }
        )
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const generateReportingPerPeriodDynamic = (filter) => {
    return new Promise((resolve, reject) => {
        http.post(
            `/ExportarDocumentos/GerarRelatorioBackground`,
            {
                ...filter,
            },
            {
                timeout: 120000,
            }
        )
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const getReportSchedulingByType = (filter, RelatorioTipoId = ReportTypeEnum.TRAVELLED_DISTANCE.id) => {
    return new Promise((resolve, reject) => {
        http.get(`/ExportarDocumentos/ListaAgendamentos`, {
            params: { RelatorioTipoId },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const getDistanceReportSchedulingById = (id) => {
    return new Promise((resolve, reject) => {
        http.get(`/ExportarDocumentos/BuscarAgendamentoPorId`, {
            params: {
                relatorioAgendamentoId: id,
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const getReportSentByType = (filter, RelatorioTipoId = ReportTypeEnum.TRAVELLED_DISTANCE.id) => {
    return new Promise((resolve, reject) => {
        http.post(`/ExportarDocumentos/RelatorioStatusAgendado`, {
            RelatorioTipoId,
            GridRequest: {
                index: 0,
                Start: 0,
                Length: 25,
                Search: filter.Search || "",
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const saveDistanceReportScheduling = (report, RelatorioTipoId = ReportTypeEnum.TRAVELLED_DISTANCE.id) => {
    return new Promise((resolve, reject) => {
        http.post(`/ExportarDocumentos/SalvarAgendamento`, {
            RelatorioTipoId,
            Background: false,
            ParametroOpcao1: true,
            ...report,
            // Id: "json",
            // ObjetoRastreavelId: 773969,
            // Formato: "pdf",
            // PeriodicidadeDia: 1,
            // DataInicio: "15/08/2020",
            // Email: "email.teste@gmail.com",
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const generateReportingPerObject = (filter) => {
    return new Promise((resolve, reject) => {
        http.post(
            `/ExportarDocumentos/GerarRelatorio`,
            {
                RelatorioExecucao: {
                    GrupoId: filter?.fleet?.id,
                    DataFinal: filter.period.endDate,
                    DataInicial: filter.period.startDate,
                    Formato: filter?.format ? filter?.format : "json",
                    HasRelatorioAgendamento: false,
                    IsFrota: false,
                    DiasSemana: filter?.state?.diaDaSemana?.toString(),
                    ObjetoRastreavelId: filter?.vehicle?.id,
                    ParametroOpcao1: false,
                    RastreadorNumeroSerie: null,
                    RelatorioTipoId: 3,
                    ShowOnEventos: false,
                },
                GridRequest: {
                    Index: filter.index,
                    Length: filter.limit,
                },
            },
            {
                timeout: 120000,
            }
        )
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const deleteReportScheduling = (id) => {
    return new Promise((resolve, reject) => {
        http.delete(`/ExportarDocumentos/RemoverAgendamento?Id=${id}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const requestReportingPerObject = (filter) => {
    return new Promise((resolve, reject) => {
        http.post(
            `/ExportarDocumentos/GerarRelatorio`,
            {
                RelatorioExecucao: {
                    GrupoId: filter.fleet?.id,
                    DataInicial: filter.period?.startDate,
                    DataFinal: filter.period?.endDate,
                    Formato: filter?.format ? filter?.format : "json",
                    HasRelatorioAgendamento: false,
                    IsFrota: false,
                    ParametroOpcao1: filter.details ? filter.details : false,
                    ObjetoRastreavelId: filter.vehicle?.id,
                    MotoristaId: filter.driver?.id,
                    RastreadorNumeroSerie: null,
                    RelatorioTipoId: filter.typeReport,
                    ShowOnEventos: false,
                    Velocidade: filter.speed,
                },
                GridRequest: {
                    Index: filter.index,
                    Length: filter.limit,
                    SearchColumns: filter.SearchColumns,
                },
            },
            {
                timeout: 120000,
            }
        )
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

export default {
    generateReportingPerPeriod,
    getReportingPerPeriod,
    generateReportingPerObject,
    getReportSchedulingByType,
    getDistanceReportSchedulingById,
    getReportSentByType,
    saveDistanceReportScheduling,
    deleteReportScheduling,
    requestReportingPerObject,
    generateReportingPerPeriodDynamic,
};
