import http from "~/config/httpRequest/http";

const getPendingNotices = (id) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Comunicado/ExisteNotificacoesPendentes`)
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const getAllCommunicated = (id) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Comunicado/Buscar`)
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const checkNotification = (id) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Comunicado/VerificarNotificacao?id=${id}`)
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};



export default { getPendingNotices, getAllCommunicated, checkNotification };
