import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
	fleetEvolution: {},
	monthlyFleetEvolution: [],
	score: {},
	compositionRadar: [],
	accidentProbability: [],
	topTenMaxVelocityVehicle: [],
	topTenMaxVelocityDriver: [],
	travelMaxVelocity: {},
	travelMaxVelocityPositions: [],
	heatmapData: [],
};

function dashboardChartsReducer(state = INITIAL_STATE, action = null) {
	switch (action.type) {
		case Constants.GET_FLEET_EVOLUTION:
			return {
				...state,
				fleetEvolution: action.payload,
			};
		case Constants.GET_MONTHLY_FLEET_EVOLUTION:
			return {
				...state,
				monthlyFleetEvolution: action.payload,
			};
		case Constants.DASHBOARD_VEHICLE_GET_SCORE:
			return {
				...state,
				score: action.payload || {},
			};
		case Constants.DASHBOARD_VEHICLE_COMPOSITION_RADAR:
			return {
				...state,
				compositionRadar: action.payload || {},
			};
		case Constants.GET_TOP_TEN_MAX_VELOCITY_VEHICLE:
			return {
				...state,
				topTenMaxVelocityVehicle: action.payload,
			};
		case Constants.GET_TOP_TEN_MAX_VELOCITY_DRIVER:
			return {
				...state,
				topTenMaxVelocityDriver: action.payload,
			};
		case Constants.GET_TRAVEL_MAX_VELOCITY:
			return {
				...state,
				travelMaxVelocity: action.payload,
			};
		case Constants.GET_TRAVEL_MAX_VELOCITY_POSITIONS:
			return {
				...state,
				travelMaxVelocityPositions: action.payload,
			};
		case Constants.GET_ACCIDENT_PROBABILITY:
			return {
				...state,
				accidentProbability: action.payload,
			};
		case Constants.DASHBOARD_VEHICLE_GET_VEHICLE_HEATMAP:
			return {
				...state,
				heatmapData: action.payload,
			};
		default:
			return state;
	}
}

export default dashboardChartsReducer;
