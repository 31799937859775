import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "~/components/common/expansionCard/Card";
import { Grid } from "@material-ui/core";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import Utils from "~/helpers/Utils";
import TabMain from "~/components/common/tab/TabMain";
import Labels from "~/helpers/enums/Labels";
import Constants from "~/helpers/enums/Constants";
import profileAction from "~/actions/profileAction";
import PersonalInformationForm from "./PersonalInformationForm";
import CustomizationForm from "./CustomizationForm";
import "./Profile.scss";

const ProfileForm = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { loading } = useSelector((state) => state.app);
    const { infos } = useSelector((state) => state.auth);
    const { fusos } = useSelector((state) => state.profile);

    const getObjectOrCreateOrNull = (list, value, valueAtt = "Valor", labelAtt = "Campo") => {
        if (!value) return "";
        return list.find((i) => i[valueAtt] === value) || { [labelAtt]: value, [valueAtt]: value };
    };

    let profile = {
        ImagemUsuarioFile: {},
        Login: "",
        Nome: "",
        Email: "",
        TzId: {},
        Senha: "",
        RepetirSenha: "",
        LogotipoFile: {},
        CorBarraTopo: "",
        CorPaineis: "",
        ...props.profile,
        MostrarLogoRelatorio: true, // Foi requisitado que este valor sempre fosse "true"
    };

    profile = {
        ...profile,
        TzId: getObjectOrCreateOrNull(fusos, profile.TzId, "Value", "Text"),
        ImagemUsuarioFile: {
            url: !Utils.isNullOrBlank(profile.UrlImagemUsuario) ? profile.UrlImagemUsuario : null,
        },
        LogotipoFile: {
            url: !Utils.isNullOrBlank(profile.Logotipo) ? profile.Logotipo : null,
        },
        Senha: profile.Senha || "",
        RepetirSenha: profile.RepetirSenha || "",
    };

    return (
        <Formik
            initialValues={{
                ...profile,
            }}
            validate={(values) => {
                const errors = {};

                if (
                    values.ImagemUsuarioFile.file &&
                    !Utils.isFile(values.ImagemUsuarioFile.file.name, [".png", "jpg", "jpeg"])
                ) {
                    errors.ImagemUsuarioFile = t(Labels.PROFILE_PERSONAL_INFORMATION_IMAGE_INVALID);
                }

                if (!values.Nome) {
                    errors.Nome = t(Labels.PROFILE_PERSONAL_INFORMATION_NAME_REQUIRED);
                }

                if (!values.Email) {
                    errors.Email = t(Labels.PROFILE_PERSONAL_INFORMATION_EMAIL_REQUIRED);
                } else if (!Utils.isValidEmail(values.Email)) {
                    errors.Email = t(Labels.PROFILE_PERSONAL_INFORMATION_EMAIL_INVALID);
                }

                if (!values.TzId.Value) {
                    errors.TzId = t(Labels.PROFILE_PERSONAL_INFORMATION_TIMEZONE_REQUIRED);
                }

                if (values.Senha) {
                    if (!values.RepetirSenha) {
                        errors.RepetirSenha = t(Labels.PROFILE_PERSONAL_INFORMATION_REPEAT_PASSWORD_REQUIRED);
                    } else if (values.Senha !== values.RepetirSenha) {
                        errors.RepetirSenha = t(Labels.PROFILE_PERSONAL_INFORMATION_REPEAT_PASSWORD_NOT_EQUAL);
                    }

                    if (!Utils.isValidPassword(values.Senha)) {
                        errors.Senha = t(Labels.PROFILE_PERSONAL_INFORMATION_NEW_PASSWORD_INVALID);
                    }
                }

                if (!values.CorBarraTopo) {
                    errors.CorBarraTopo = t(Labels.PROFILE_CUSTOMIZATION_COLOR_CONFIGURATION_PRIMARY_REQUIRED);
                }

                if (!values.CorPaineis) {
                    errors.CorPaineis = t(Labels.PROFILE_CUSTOMIZATION_COLOR_CONFIGURATION_SECONDARY_REQUIRED);
                }

                if (values.LogotipoFile.file && !Utils.isFile(values.LogotipoFile.file.name, [".png", "jpg", "jpeg"])) {
                    errors.LogotipoFile = t(Labels.PROFILE_CUSTOMIZATION_LOGO_INVALID_TYPE);
                } else if (
                    values.LogotipoFile.file &&
                    (values.LogotipoFile.img.width < 100 ||
                        values.LogotipoFile.img.width > 800 ||
                        values.LogotipoFile.img.height < 25 ||
                        values.LogotipoFile.img.height > 400)
                ) {
                    errors.LogotipoFile = t(Labels.PROFILE_CUSTOMIZATION_LOGO_INVALID_SIZE);
                }

                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                const fileOfNull = (imageFile) => {
                    if (!imageFile.base64) return null;

                    return {
                        FileName: imageFile.file.name,
                        File: Utils.stringBase64ToObjectBase64(imageFile.base64).data,
                    };
                };

                const files = {
                    ImagemUsuarioFile: fileOfNull(values.ImagemUsuarioFile),
                    LogotipoFile: fileOfNull(values.LogotipoFile),
                };

                const newProfile = {
                    ...values,
                    ...files,
                    Pais: values.Pais.Valor,
                    TzId: values.TzId.Value,
                    TimezoneId: values.TzId.Value,
                    Senha: values.Senha || null,
                    RepetirSenha: values.RepetirSenha || null,
                    excluirImagem: !values.ImagemUsuarioFile.url && !values.ImagemUsuarioFile.file,
                    UrlImagemUsuario: files.ImagemUsuarioFile ? null : (values.UrlImagemUsuario || ""),
                    Logotipo: files.LogotipoFile ? null : (values.LogotipoFile.url || ""),
                }

                dispatch(
                    profileAction.saveProfile(newProfile, (error) => {
                        setSubmitting(false);
                        if (error) {
                            Utils.showError(error);
                            return;
                        }

                        Utils.showTranslatedToast({
                            type: Constants.SUCCESS,
                            description: Labels.PROFILE_FORM_MSG_SUCCESS,
                        });

                        props.comeBack();
                    })
                );
            }}>
            {(form) => {
                const hasPeronsalInformationError =
                    form.errors["Imagem"] ||
                    form.errors["Login"] ||
                    form.errors["Nome"] ||
                    form.errors["Email"] ||
                    form.errors["Pais"] ||
                    form.errors["TzId"] ||
                    form.errors["Senha"] ||
                    form.errors["RepetirSenha"];

                const hasCustomizationError =
                    form.errors["Logo"] ||
                    form.errors["MostrarLogoRelatorio"] ||
                    form.errors["CorBarraTopo"] ||
                    form.errors["CorPaineis"];

                const tabs = [
                    {
                        id: 0,
                        label: t(Labels.PROFILE_PERSONAL_INFORMATION_TITLE),
                        children: (
                            <PersonalInformationForm form={form} loading={loading.profile} comeBack={props.comeBack} />
                        ),
                        hasError: hasPeronsalInformationError,
                    },
                ];

                if (infos.Master) {
                    tabs.push({
                        id: 1,
                        label: t(Labels.PROFILE_CUSTOMIZATION_TITLE),
                        children: <CustomizationForm form={form} loading={loading.profile} comeBack={props.comeBack} />,
                        hasError: hasCustomizationError,
                    });
                }

                return (
                    <Form className="ceabs-register-form">
                        <Grid container direction="row">
                            <Grid item xs={9}>
                                <Card className={"form-card profile-form-card"}>
                                    <TabMain classNameLabelRefactor="tab-label-refactor" tabs={tabs} />
                                </Card>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ProfileForm;
