import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import MedidorCinza from "~/assets/icons/dashboard/icon-medidor-cinza.svg";
import AceleracaoBrusca from "~/assets/icons/dashboard/icon-aceleracaobrusca-cinza.svg";
import CurvaBrusca from "~/assets/icons/dashboard/icon-curvabrusca-cinza.svg";
import DeslocamentoFds from "~/assets/icons/dashboard/icon-deslocamentofds-cinza.svg";
import DeslocamentoForaDeHorario from "~/assets/icons/dashboard/icon-deslocamentoforadehr-cinza.svg";
import FreadaBrusca from "~/assets/icons/dashboard/icon-freadabrusca-cinza.svg";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";

export default function AnalysisItems() {
    const {
        qtdAceleracaoBrusca,
        qtdCurvaAgressiva,
        qtdFreadaBrusca,
        qtdLimiteVelocidadeVia,
        qtdViagemNaoComercial,
        qtdViagemFimSemana
    } = useSelector((state) => state.dashboardTravels);
    const { t } = useTranslation();

    const painel = (icon, name, value) => {
        return (
            <div
                style={{
                    display: "flex",
                    backgroundColor: "rgba(175, 195, 210, .2)",
                    padding: 18,
                    margin: 10,
                    boxSizing: "border-box",
                    minHeight: "120px",
                    borderRadius: "3px",
                }}>
                <img src={icon} style={{ width: 50, marginRight: 15 }} alt={`Icone que representa ${name}`} />
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                        style={{
                            font: "bold 30px Trebuchet MS",
                            color: "#3D5564",
                        }}>
                        {value.toLocaleString("pt-BR")}
                    </span>
                    <span
                        style={{
                            font: "bold 18px Trebuchet MS",
                            color: "#3D5564",
                        }}>
                        {name}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Grid container>
                <Grid
                    item
                    xs={3}
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}>
                    <div
                        style={{
                            textAlign: "left",
                            font: "bold 20px Trebuchet MS",
                            color: "#3D5564",
                            borderBottom: "1px solid #AFC3D2",
                            width: "100%",
                            paddingBottom: 15,
                        }}>
                        {t(Labels.DASHBOARD_ANALYSIS_ITEMS_TITLE)}
                    </div>
                </Grid>
                <Grid item xs={3} style={{ display: "flex", flexDirection: "column" }}>
                    {painel(
                        AceleracaoBrusca,
                        t(Labels.DASHBOARD_ANALYSIS_ITEMS_QTD_ACELERACAO_BRUSCA),
                        qtdAceleracaoBrusca
                    )}
                    {painel(
                        MedidorCinza,
                        t(Labels.DASHBOARD_ANALYSIS_ITEMS_QTD_EXCESSO_VELOCIDADE),
                        qtdLimiteVelocidadeVia
                    )}
                </Grid>
                <Grid item xs={3} style={{ display: "flex", flexDirection: "column" }}>
                    {painel(CurvaBrusca, t(Labels.DASHBOARD_ANALYSIS_ITEMS_QTD_CURVA_AGRESSIVA), qtdCurvaAgressiva)}
                    {painel(
                        DeslocamentoForaDeHorario,
                        t(Labels.DASHBOARD_ANALYSIS_ITEMS_QTD_VIAGEM_NAO_COMERCIAL),
                        qtdViagemNaoComercial
                    )}
                </Grid>
                <Grid item xs={3} style={{ display: "flex", flexDirection: "column" }}>
                    {painel(FreadaBrusca, t(Labels.DASHBOARD_ANALYSIS_ITEMS_QTD_FREADA_BRUSCA), qtdFreadaBrusca)}
                    {painel(
                        DeslocamentoFds,
                        t(Labels.DASHBOARD_ANALYSIS_ITEMS_QTD_VIAGEM_FIM_SEMANA),
                        qtdViagemFimSemana
                    )}
                </Grid>
            </Grid>
        </div>
    );
}
