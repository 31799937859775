import Labels from "~/helpers/enums/Labels";
import { t } from "~/config/language/i18n";

export const getOption = (data, image = null, tooltipPrefix = "") => {
    return {
        legend: {
            orient: "vertical",
            icon: "circle",
            top: 0,
            right: 0,
            align: "left",
            itemGap: 2,
            textStyle: {
                fontSize: 15,
            },
        },
        tooltip: {
            show: true,
            formatter: function (params) {
                const circleColor = params.color.colorStops[0].color;
                const circle = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${circleColor}"></span>`;
                const header = `<span>${circle} ${params.name}</span>`;

                const body = `</br> <span>${t(Labels.DASHBOARD_CHARTS_FORMATTER_TOTAL)}: ${Math.round(
                    parseFloat(params.data.fullValue / 1000)
                ).toLocaleString()} ${tooltipPrefix}</span>
				</br> <span>${t(Labels.DASHBOARD_FLEET_EVOLUTION_TAB_PERCENT)}: ${params.value}%  </span>`;
                return header + body;
            },
        },
        graphic: [
            {
                type: "image",
                id: "logo",
                position: [0, 0],
                left: "45%",
                top: "51%",
                z: -10,
                bounding: "raw",
                style: {
                    image: data.length > 0 ? image : "",
                    width: 50,
                    height: 50,
                    opacity: 0.8,
                },
            },
        ],
        series: [
            {
                type: "pie",
                radius: ["30%", "70%"],
                center: ["50%", "60%"],
                avoidLabelOverlap: true,
                data,
                width: "auto",
                height: "auto",
                hoverOffset: 2,
                startAngle: 120,
                label: {
                    formatter: "{c}%",
                    fontSize: 18,
                },
                labelLine: {
                    length: 6,
                    lineStyle: {
                        width: 3,
                    },
                },
                itemStyle: {
                    borderColor: "rgba(255, 255, 255, 1)",
                    borderWidth: 7.5,
                },
            },
        ],
    };
};
