import React from "react";
import { Container, Grid, Button } from "@material-ui/core";
import Page from "~/components/common/page/Page";
import UserLinkCars from "~/components/user/linkCars/UserLinkCars";
import { ComeBackIcon } from "~/components/common/icons/Icons";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import { useHistory } from "react-router-dom";

const UserLinkCarsView = () => {
	const { t } = useTranslation();
	let history = useHistory();

	const comeBack = () => {
		history.push("/register/user");
	};

	return (
		<>
			<Page
				topButtons={
					<Button
						className="comeback-button"
						id="extractReportPoints"
						variant="contained"
						size="small"
						disableElevation
						startIcon={<ComeBackIcon />}
						onClick={() => {
							comeBack();
						}}>
						{t(Labels.GROUP_POINTS_COMEBACK)}
					</Button>
				}>
				<main>
					<Container maxWidth="lg" className="containerUserLinkCars container-map-view">
						<Grid container>
							<Grid item xs={12}>
								<UserLinkCars />
							</Grid>
						</Grid>
					</Container>
				</main>
			</Page>
		</>
	);
};

export default UserLinkCarsView;
