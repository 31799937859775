import React, { useRef } from "react";
import ReactToPrint from "react-to-print";

import Page from "~/components/common/page/Page";
import DashboardToolbar from "~/components/dashboardVehicle/common/DashboardToolbar";
import DashboardVehicle from "~/components/dashboardVehicle/DashboardVehicle.jsx";
import { Container, Grid } from "@material-ui/core";
import ExportButton from "~/components/common/exportButton/ExportButton";

const DashboardView = () => {
    const componentRef = useRef();

    return (
        <Page
            bar={<DashboardToolbar />}
            topButtons={
                <ReactToPrint
                    documentTitle="Dashboard"
                    trigger={() => <ExportButton />}
                    content={() => componentRef.current}
                />
            }>
            <Container maxWidth="lg" className="container-map-view">
                <Grid container>
                    <DashboardVehicle ref={componentRef} />
                </Grid>
            </Container>
        </Page>
    );
};

export default DashboardView;
