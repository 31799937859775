import http from "~/config/httpRequest/http";

export const getGoogleApiKey = () => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Configuracao/ObterConfiguracoes`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const getPointInterestGroup = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Pesquisa/BuscarGruposPontosDeInteresse`, {
				params: {
					...filter,
				},
			})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const getPointInterest = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Pesquisa/BuscarPontosDeInteresse`, {
				params: {
					...filter,
				},
			})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const getRoutes = (params) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Pesquisa/BuscarRotas`, params)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export default { getGoogleApiKey, getPointInterestGroup, getPointInterest, getRoutes };
