import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";

import dashboardService from "~/services/dashboardVehicle/dashboardService";

const findTravelsVehicle = (filter, LOADING_IDENTIFICATOR = "findTravels") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return dashboardService
		.findTravelsVehicle(filter)
		.then(({ data }) => {
			dispatch({ type: Constants.FIND_TRAVELS_VEHICLE, payload: data });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const findLastPositionsWithoutDataTable = (filter, LOADING_IDENTIFICATOR = "findLastPositions") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return dashboardService
		.findLastPositionsWithoutDataTable(filter)
		.then((payload) => {
			dispatch({ type: Constants.FIND_LAST_POSITIONS_WITHOUT_DATA_TABLE, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const findLastPositions = (filter, LOADING_IDENTIFICATOR = "findLastPositions") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return dashboardService
		.findLastPositions(filter)
		.then(({ data }) => {
			dispatch({ type: Constants.FIND_LAST_POSIIONS, payload: data });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

export default {
	findTravelsVehicle,
	findLastPositionsWithoutDataTable,
	findLastPositions,
};
