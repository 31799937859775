import http from "~/config/httpRequest/http";

const list = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/Pontointeresseapi/PesquisarPontosInteresse`, {
				Index: filter.page,
				Length: filter.limit,
				Search: filter.search,
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const deletePointInterest = (id) => {
	return new Promise((resolve, reject) => {
		http
			.delete(`/PontoInteresseApi/RemoverPontoInteresse?id=${id}`)
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

export const getPointInterestTypes = () => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Pontointeresseapi/ListarTipoPontoInteresse`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const savePointInterest = (pointInterest) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/Pontointeresseapi/SalvarPontoInteresse`, {
				...pointInterest,
			})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const getPointInterestById = (id) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/PontoInteresseApi/BuscarPontoInteressePorId`, {
				params: {
					id: id,
				},
			})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const getObjectsPerPoints = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Pontointeresseapi/ListarMapaObjetosRastreaveis`, {
				params: {
          latitude: filter.latitude,
          longitude: filter.longitude
				},
			})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export default { list, deletePointInterest, getPointInterestTypes, savePointInterest, getPointInterestById, getObjectsPerPoints };
