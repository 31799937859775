import React from "react";
import { Typography, Box } from "@material-ui/core";
import "../GoogleMaps.scss";

const GoogleMapMarkerInfoView = (props) => {
	const element = props.item;

	return (
		<Box className="marker-info-view">
			<Box className="header on">
				<Typography variant="h6"> {element.title} </Typography>
			</Box>
			<Box className="body">
				<div className="line">
					<div className="label">
						<Typography variant="body2"> {element.description} </Typography>
					</div>
					<div className="value">
						<Typography variant="body2"> {element.value} </Typography>
					</div>
				</div>
			</Box>
		</Box>
	);
};

export default GoogleMapMarkerInfoView;
