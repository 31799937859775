import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		margin: "5px",
		"& > * + *": {
			marginLeft: theme.spacing(2),
		},
	},
}));

export default function CircularIndeterminate(props) {
	const classes = useStyles();

	const tam = props.size ? props.size : 60;

	return (
		<div className={classes.root}>
			<CircularProgress id="loading" size={tam} color="primary" />
		</div>
	);
}
