import React, { useState, useEffect } from "react";
import StepPlans from "~/views/Renew/StepPlans";
import DataFormStep from "~/views/Renew/DataFormStep";
import Page from "~/components/common/page/Page";
import { useDispatch } from "react-redux";
import renewalActions from "~/actions/renewalActions";
import vehicleRenewalService from "~/services/vehicleRenewalService";

const Renew = () => {
    const [step, setStep] = useState(0);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isRenewalLoading, setIsRenewalLoading] = useState(true);
    const [vehicles, setVehicles] = useState([]);
    const dispatch = useDispatch();

    const handlePlanSelect = (valorMensal, vigencia, id) => {
        setSelectedPlan({ valorMensal, vigencia, id });
        setStep(step + 1);
    };

    const handleGoBackToPlans = () => {
        setStep(0);
    };

    useEffect(() => {
        const fetchRenewalData = async () => {
            try {
                await dispatch(renewalActions.getContact());
                const response = await vehicleRenewalService.listVehicleRenewal(false);
                setVehicles(response.data);
            } catch (error) {
                console.error("Erro ao buscar dados de renovação ou veículos:", error);
            } finally {
                setIsRenewalLoading(false);
            }
        };

        fetchRenewalData();
    }, [dispatch]);

    return (
        <Page>
            {step === 0 ? (
                <StepPlans onSelectPlan={handlePlanSelect} isLoading={isRenewalLoading} />
            ) : (
                <DataFormStep
                    contractDetails={selectedPlan}
                    vehicles={vehicles}
                    onBack={handleGoBackToPlans}
                />
            )}
        </Page>
    );
};

export default Renew;
