// REGISTER

import Constants from "~/helpers/enums/Constants";

const initialState = {
    alertReport: {},
    alertReportFilter: {},
    filterForm: null,
    perObject: {
        filters: {
            AlertaId: "",
            ObjetoRastreavelId: null,
            Inicio: "",
            Final: "",
            Opcao: null,
            GrupoId: null,
            GridRequest: {
                Index: 0,
                Length: 10,
                SearchColumns: [
                    {
                        Name: "Violacao.DataHoraEvento",
                        Data: "DataHoraEvento",
                        OrderTable: true,
                        OrderDirection: "desc",
                    },
                ],
            },
        },
        data: {
            data: [],
        },
    },
    alertDetail: {},
    alertHistory: [],
    alertExport: {
        Tipo: "",
        Alerta: null,
        DataInicio: null,
        DataFim: null,
        Serial: null,
    },
    searchField: "",
};

function alertReducer(state = initialState, action = null) {
    switch (action.type) {
        case Constants.IMPORT_ALERT_REPORT:
            return {
                ...state,
                alertReport: action.payload.data,
                alertReportFilter: initialState.alertReportFilter,
            };
        case Constants.UPDATE_ALERT_DETAIL:
            return {
                ...state,
                alertDetail: action.payload,
            };

        case Constants.GET_ALERT_REPORT_HISTORY:
            return {
                ...state,
                alertHistory: action.payload,
            };
        case Constants.UPDATE_FILTER_ALERT_OBJECT:
            return {
                ...state,
                perObject: {
                    ...state.perObject,
                    filters: {
                        ...state.perObject.filters,
                        ...action.payload.filter,
                    },
                },
                searchField: initialState.searchField,
            };
        case Constants.SAVE_EXPORT_ALERT: {
            return {
                ...state,
                alertExport: {
                    ...state.alertExport,
                    ...action.payload.filter,
                },
            };
        }
        case Constants.UPDATE_FILTER_ALERT_PAGINATION:
            return {
                ...state,
                perObject: {
                    ...state.perObject,
                    filters: {
                        ...state.perObject.filters,
                        GridRequest: {
                            ...state.perObject.filters.GridRequest,
                            ...action.payload.filter,
                        },
                    },
                },
            };
        case Constants.UPDATE_FILTER_ALERT_ORDER:
            return {
                ...state,
                perObject: {
                    ...state.perObject,
                    filters: {
                        ...state.perObject.filters,
                        GridRequest: {
                            ...state.perObject.filters.GridRequest,
                            SearchColumns: {
                                ...state.perObject.filters.GridRequest.SearchColumns,
                                ...action.payload.filter,
                            },
                        },
                    },
                },
            };
        case Constants.ALERT_REPORT_FILTER_DATA:
            return {
                ...state,
                alertReportFilter: action.payload.alertReportFilter,
                searchField: action.payload.searchField,
            };
        case Constants.UPDATE_FORM_ALERT:
            return {
                ...state,
                filterForm: action.payload.form,
            };
        case Constants.CLEAN_REPORT_ALERT_DETAIL:
            return {
                ...state,
                alertDetail: initialState.alertDetail,
            };
        case Constants.ALERT_REPORT_CLEAN:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}

export default alertReducer;
