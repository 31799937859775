import React from "react";
import Page from "~/components/common/page/Page";
import { Container, Box } from "@material-ui/core";

const NotFound = () => {
	return (
		<Page>
			<Container>
				<Box> Not Found </Box>
			</Container>
		</Page>
	);
};

export default NotFound;
