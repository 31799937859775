import React from "react";
import { useSelector } from "react-redux";
import ReactEcharts from "echarts-for-react";

const getRichAtribute = ({ fontSize = 18 }) => {
    const defaultOption = {
        textBorderColor: "white",
		fontSize: fontSize,
		fontWeight: "bold"
    };
    return {
        blue: {
            ...defaultOption,
            color: "#52AFE2",
        },
        green: {
            ...defaultOption,
            color: "#6EC8AF",
        },
        purple: {
            ...defaultOption,
            color: "#9B6EF1",
        },
        orange: {
            ...defaultOption,
            color: "#FFA262",
        },
        red: {
            ...defaultOption,
            color: "#CC4848",
        },
    };
};

const getColorObject = (start, final) => ({
    type: "linear",
    x: 1,
    y: 0,
    x2: 0,
    y2: 0,
    colorStops: [
        {
            offset: 0,
            color: start,
        },
        {
            offset: 1,
            color: final,
        },
    ],
});

const options = (data = []) => {
    const defaultOptions = {
        name: "Probabilidade de Acidentes",
        type: "funnel",
        x: 0,
        y: 0,
        width: "50%",
        height: "100%",
        sort: "ascending",
    };

    const color = [
        getColorObject("#8E63EF", "#DEAAFF"),
        getColorObject("#52AFE2", "#9ED0EB"),
        getColorObject("#6EC8AF", "#64C8FF"),
        getColorObject("#FF9464", "#FFDA5D"),
        getColorObject("#CC4848", "#FFC1C1"),
    ];

    return {
        color,
        tooltip: {
            show: true,
            formatter: function (params) {
                const circleColor = params.color.colorStops[0].color;
                const circle =
                    '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
                    circleColor +
                    '"></span>';
                const head = "<span>" + params.seriesName + "</span> </br>";
                const body = "<span>" + circle + " " + params.name + ": " + params.data.fullValue + "</span>";
                return head + body;
            },
        },
        series: [
            {
                ...defaultOptions,
                data,
                itemStyle: {
                    normal: {
                        label: {
                            formatter: function (value) {
                                return `{${value.data.className}|${value.name}}`;
                            },
                            rich: getRichAtribute({ fontSize: 16 }),
                        },
                        labelLine: {
                            show: true,
                            length: 50,
                            lineStyle: {
                                width: 2,
                                type: "dotted",
                            },
                        },
                    },
                },
            },
            {
                ...defaultOptions,
                data,
                itemStyle: {
                    normal: {
                        label: {
                            width: 12,
                            height: 12,
                            padding: 15,
                            borderRadius: 20,
                            backgroundColor: "#fff",
                            borderColor: "rgb(255,250,250, 0.4)",
                            borderWidth: 10,
                            position: "inside",
                            formatter: function (value) {
                                return `{${value.data.className}|${value.data.formatedValue}}`;
                            },
                            rich: getRichAtribute({ fontSize: 18 }),
                        },
                    },
                },
            },
        ],
    };
};

export default function AccidentProbabilityChartFunnel() {
    const { waitingTravel } = useSelector((state) => state.app.loading);
    const data = useSelector((state) => state.dashboardCharts.accidentProbability);

    return (
        <div>
            <ReactEcharts option={options(data)} showLoading={waitingTravel} loadingOption={{ text: "Carregando" }} />
        </div>
    );
}
