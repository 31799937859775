import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, IconButton } from "@material-ui/core";
import { NoColorAddIcon } from "~/components/common/icons/Icons";
import Labels from "~/helpers/enums/Labels";
import {
    AlertWeekendIcon,
    AlertOutHourIcon,
    AlertVelocityIcon,
    AlertEnteringRiskIcon,
    AlertLeavingRiskIcon,
    AlertPrivateIcon,
} from "~/components/common/icons/Icons";
import { useTranslation } from "react-i18next";
import { resolveValue } from "./conditionshelper";
import registerAlertAction from "~/actions/registerAlertAction";
import Card from "~/components/common/expansionCard/Card";
import "./AlertCards.scss";

const AlertCards = (props) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const { insertform } = useSelector((state) => state.alert);

    const updateFields = (values) => {
        if (!insertform || !insertform.current) return;
        insertform.current.resetForm();

        Object.entries(values).forEach(([key, value]) => {
            insertform.current.setFieldValue(key, value);
        });
    };

    const helpCards = [
        {
            title: t(Labels.ALERT_CARD_WEEKEND_TITLE),
            text: t(Labels.ALERT_CARD_WEEKEND_TEXT),
            icon: <AlertWeekendIcon />,
            values: {
                Nome: "Movimentação no fim de semana",
                DiasSemana: "17",
                AlertaCondicaoTipoId: "1",
                HorarioValidacaoInicial: "",
                HorarioValidacaoFinal: "",
                DataValidade: null,
                AlertasAcoes: [{}], // TODO
                AlertasCondicoes: [
                    { ...resolveValue({ Codigo: "11_2", Nome: "Velocidade" }), Valor: 10 },
                    resolveValue({ Codigo: "10_1", Nome: "Ignição" }),
                ], // TODO
            },
        },
        {
            title: t(Labels.ALERT_CARD_OUT_HOURS_TITLE),
            text: t(Labels.ALERT_CARD_OUT_HOURS_TEXT),
            icon: <AlertOutHourIcon />,
            values: {
                Nome: "Movimentação fora do horário permitido",
                DiasSemana: "1234567",
                AlertaCondicaoTipoId: "1",
                HorarioValidacaoInicial: "20:00",
                HorarioValidacaoFinal: "06:00",
                DataValidade: null,
                AlertasAcoes: [{}],
                AlertasCondicoes: [
                    { ...resolveValue({ Codigo: "11_2", Nome: "Velocidade" }), Valor: 10 },
                    resolveValue({ Codigo: "10_1", Nome: "Ignição" }),
                ],
            },
        },
        {
            title: t(Labels.ALERT_CARD_VELOCITY_TITLE),
            text: t(Labels.ALERT_CARD_VELOCITY_TEXT),
            icon: <AlertVelocityIcon />,
            values: {
                Nome: "Excesso de velocidade em área monitorada",
                DiasSemana: "1234567",
                AlertaCondicaoTipoId: "1",
                HorarioValidacaoInicial: "20:00",
                HorarioValidacaoFinal: "06:00",
                DataValidade: null,
                AlertasAcoes: [{}],
                AlertasCondicoes: [
                    resolveValue({ Codigo: "28_11", Nome: "Grupo de Elemento Geográfico" }),
                    { ...resolveValue({ Codigo: "11_2", Nome: "Velocidade" }), Valor: 80 },
                    resolveValue({ Codigo: "10_1", Nome: "Ignição" }),
                ],
            },
        },
        {
            title: t(Labels.ALERT_CARD_ENTERING_RISK_TITLE),
            text: t(Labels.ALERT_CARD_ENTERING_RISK_TEXT),
            icon: <AlertEnteringRiskIcon />,
            values: {
                Nome: "Entrada em área de risco",
                DiasSemana: "1234567",
                AlertaCondicaoTipoId: "1",
                HorarioValidacaoInicial: "20:00",
                HorarioValidacaoFinal: "06:00",
                DataValidade: null,
                AlertasAcoes: [{}],
                AlertasCondicoes: [resolveValue({ Codigo: "28_11", Nome: "Grupo de Elemento Geográfico" })],
            },
        },
        {
            title: t(Labels.ALERT_CARD_LEAVING_RISK_TITLE),
            text: t(Labels.ALERT_CARD_LEAVING_RISK_TEXT),
            icon: <AlertLeavingRiskIcon />,
            values: {
                Nome: "Saída de área de risco",
                DiasSemana: "1234567",
                AlertaCondicaoTipoId: "1",
                HorarioValidacaoInicial: "20:00",
                HorarioValidacaoFinal: "06:00",
                DataValidade: null,
                AlertasAcoes: [{}],
                AlertasCondicoes: [
                    { ...resolveValue({ Codigo: "11_2", Nome: "Velocidade" }), Valor: 10 },
                    resolveValue({ Codigo: "28_11", Nome: "Grupo de Elemento Geográfico" }),
                ],
            },
        },
        {
            title: t(Labels.ALERT_CARD_NO_SIGNAL_TITLE),
            text: t(Labels.ALERT_CARD_NO_SIGNAL_TEXT),
            icon: <AlertPrivateIcon />,
            values: {
                Nome: "Sem sinal",
                DiasSemana: "1234567",
                AlertaCondicaoTipoId: "1",
                HorarioValidacaoInicial: "",
                HorarioValidacaoFinal: "",
                DataValidade: null,
                AlertasAcoes: [{}],
                AlertasCondicoes: [
                    {
                        ...resolveValue({ Codigo: "14_12", Nome: "Sem sinal" }),
                        Valor: 120,
                    },
                    resolveValue({ Codigo: "27_10", Nome: "Elemento Geográfico" }),
                ],
            },
        },
    ];

    return (
        <Box className="container-alert-card">
            <Card className={"alert-card include-card"}>
                <Box className="main">
                    <IconButton
                        className="include-button"
                        onClick={() => {
                            dispatch(registerAlertAction.setOpenEditForm(false));
                            dispatch(registerAlertAction.setOpenInsertForm(true));

                            updateFields({
                                Nome: "",
                                DiasSemana: "1234567",
                                AlertaCondicaoTipoId: "1",
                                HorarioValidacaoInicial: "",
                                HorarioValidacaoFinal: "",
                                DataValidade: null,
                                AlertasAcoes: [{}],
                                AlertasCondicoes: [{}],
                            });
                        }}>
                        <NoColorAddIcon color="primary" viewBox="0 0 60 55" />
                    </IconButton>

                    <Box className="text">
                        <Typography variant="body1">
                            <span>{t(Labels.ALERT_CARD_INSERT_TITLE)} </span> {t(Labels.ALERT_CARD_INSERT_TEXT)}
                        </Typography>
                    </Box>
                </Box>
            </Card>
            {helpCards.map((hc, i) => (
                <Card className={"alert-card help-card"} key={i}>
                    <Box className="main">
                        <Box className="header">
                            {hc.icon}
                            <Typography variant="body1">{hc.title}</Typography>
                        </Box>
                        <Box className="text">
                            <Typography variant="body1">{hc.text}</Typography>
                        </Box>
                        <Box className="footer">
                            <IconButton
                                className="help-button"
                                onClick={() => {
                                    dispatch(registerAlertAction.setOpenEditForm(false));
                                    dispatch(registerAlertAction.setOpenInsertForm(true));

                                    updateFields(hc.values);
                                }}>
                                <NoColorAddIcon color="primary" viewBox="0 0 60 55" />
                            </IconButton>
                        </Box>
                    </Box>
                </Card>
            ))}
        </Box>
    );
};

export default AlertCards;
