import React from "react";
import Search from "~/components/common/search/Search";
import MenuRange from "~/components/common/menuRange/MenuRange";
import Card from "~/components/common/expansionCard/Card";
import TableCardReUse from "~/components/tableCard/TableCardReUse";
import { Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

const useStyles = makeStyles({
    search: {
        width: 250,
        float: "left",
        marginRight: 10,
    },
    box: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
});

const Scheduling = (props) => {
    const classes = useStyles();
    const [select, setSelect] = React.useState(25);
    const { t } = useTranslation();
    const changePagination = (value, action) => {
        setSelect(value);
        const query = {
            // ...address.filter,
            index: 0,
            limit: value,
        };
        action({ ...query });
    };

    const handleSearch = (value, action) => {
        action({ Search: value });
    };

    return (
        <div className={classes.box}>
            {props.cards.map((card, i) => (
                <Card
                    title={card.title}
                    key={card.key}
                    className={`card-report ${card.className}`}
                    titleButtons={
                        <Grid container direction="row">
                            {!!card.search && (
                                <div className={classes.search}>
                                    <Search
                                        id="searchAddress"
                                        search={(value) => handleSearch(value, card.action)}
                                        className="search-input-refactor"
                                    />
                                </div>
                            )}
                            {!!card.pagination && (
                                <div style={{ marginLeft: "10px" }}>
                                    <MenuRange
                                        label={t(Labels.MENU_RANGE_SHOW)}
										className="menu-range-refactor"
                                        value={select}
                                        onChange={(event) => changePagination(event.target.value, card.action)}
                                        options={[10, 25, 50, 100, 250, 500]}
                                    />
                                </div>
                            )}
                        </Grid>
                    }>
                    <TableCardReUse columns={card.columns} dataSource={card.dataSource} />
                </Card>
            ))}
        </div>
    );
};

export default Scheduling;
