import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import ReportFilter from "~/components/common/reportTab/ReportFilter";
import TravelsObject from "~/components/reporting/travels/perObject/TravelsObject";
import TravelGridResultPeriod from "~/components/reporting/travels/perPeriod/TravelGridResultPeriod";
import TravelsScheduled from "~/components/reporting/travels/scheduled/TravelsScheduled";
import FiltersObject from "~/components/reporting/travels/perObject/FiltersObject";
import TravelFilterPeriod from "~/components/reporting/travels/perPeriod/TravelFilterPeriod";
import TravelsFilterScheduled from "~/components/reporting/travels/scheduled/TravelsFilterScheduled";

const ReportTravels = (props) => {
    const { periodForm, objectForm, scheduleForm } = useSelector((state) => state.travelsReport);
    const { t } = useTranslation();

    return (
        <div>
            <ReportFilter
                label={t(Labels.FILTERS)}
                onCleanObject={() => {
                    objectForm && objectForm.resetForm();
                }}
                onCleanPeriod={() => {
                    periodForm && periodForm.resetForm();
                }}
                onCleanScheduled={() => {
                    scheduleForm && scheduleForm.resetForm();
                }}
                objectFilter={<FiltersObject style />}
                periodFilter={<TravelFilterPeriod />}
                scheduledFilter={<TravelsFilterScheduled />}
            />
            <TravelsObject />
            <TravelGridResultPeriod />
            <TravelsScheduled />
        </div>
    );
};

export default ReportTravels;
