import React from "react";

import TopTabDriverVehicle from "~/components/common/tab/TabMain";
import TopAnalyzeDrivers from "~/components/dashboard/topTen/topAnalyzeDrivers/TopAnalyzeDrivers";
import TopAnalyzeVehicles from "~/components/dashboard/topTen/topAnalyzeVehicles/TopAnalyzeVehicles";

import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";

import "./TabDriverVehicle.scss";

const TabDriverVehicle = ({ filter }) => {

	const { t } = useTranslation();

	const TABS = [
		{
			id: 0,
			label: t(Labels.TAB_ANALYZE_VEHICLE),
			children: <TopAnalyzeVehicles filter={filter} />,
		},
		{
			id: 1,
			label: t(Labels.TAB_ANALYZE_DRIVER),
			children: <TopAnalyzeDrivers filter={filter} />,
		},
	];

	return (
		<div className="cardTabTopTen cardAnalyzeTop">
			<TopTabDriverVehicle id="TopTabAnalyzeVehicleDriver" tabs={TABS} className={"classMainTab"} />
		</div>
	);
};

export default TabDriverVehicle;
