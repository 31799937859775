//! transparent linear-gradient(209deg, #FFDA5D 0%, #FF9464 100%) 0% 0% no-repeat padding-box;
export const RodoviarioColorSolid = "#FC5C75";
export const RodoviarioColors = {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
        {
            offset: 0,
            color: RodoviarioColorSolid, // color at 0% position
        },
        {
            offset: 1,
            color: "#FC5C75", // color at 100% position
        },
    ],
    global: false,
};

//! transparent linear-gradient(302deg, #6EC8AF 0%, #6EC8B1 46%, #64C8FF 100%) 0% 0% no-repeat padding-box;
export const UrbanoColorSolid = "#6EC8AF";
export const UrbanoColors = {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
        {
            offset: 0,
            color: UrbanoColorSolid, // color at 0% position
        },
        {
            offset: 0.46,
            color: "#6EC8AF", // color at 0% position
        },
        {
            offset: 1,
            color: "#6EC8AF", // color at 100% position
        },
    ],
    global: false,
};

export const Colors = (Shift) => {
    const SwitchColor = {
        Rodoviário: RodoviarioColors,
        Urbano: UrbanoColors,
        default: "#FFF",
    };

    return SwitchColor[Shift] || SwitchColor.default;
};

export const ColorSolid = (Shift) => {
    const SwitchColor = {
        Rodoviário: RodoviarioColorSolid,
        Urbano: UrbanoColorSolid,
        default: "#FFF",
    };

    return SwitchColor[Shift] || SwitchColor.default;
};

export const getFullValue = (Shift, objectItens) => {
    const SwitchValue = {
        Rodoviário: objectItens["DistanciaRodoviario"],
        Urbano: objectItens["DistanciaUrbano"],
        default: 0,
    };
    return (SwitchValue[Shift] || SwitchValue.default);
};

export default {
    RodoviarioColorSolid,
    RodoviarioColors,
    UrbanoColorSolid,
    UrbanoColors,
    Colors,
    ColorSolid,
    getFullValue,
};
