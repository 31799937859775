import React, { useEffect, useState } from "react";

import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";

import Loading from "~/components/common/loading/LoadingPanel";

import "./GridData.scss";

const GridData = ({ columns, data, typeColumn, loading }) => {
    const [load, setLoad] = useState(true);

    useEffect(() => {
        setLoad(false);
    }, []);

    return (
        <>
            {(loading || load) && (
                <div className="loadingGridData">
                    <Loading size={40} />
                </div>
            )}
            <Table className="tableGridData" key="tableGridData">
                <TableHead key="tableGridDataHead">
                    <TableRow key="headerGrid">
                        {columns.map((col, i) =>
                            col[typeColumn].map((colItem, j) => (
                                <TableCell
                                    key={j + "-cell-" + i}
                                    style={{ width: colItem.width ? colItem.width : "auto" }}>
                                    {colItem.labelRender ? (
                                        <span key={j + "--span--" + i}>{colItem.labelRender(data)}</span>
                                    ) : (
                                        <Typography key={j + "--font--" + i} className="gridDataFont">
                                            {colItem.label}
                                        </Typography>
                                    )}
                                </TableCell>
                            ))
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, rowKey) => (
                        <TableRow key={rowKey} className={``}>
                            {columns.map((col, iCol) =>
                                col[typeColumn].map((colItem, j) => (
                                    <TableCell
                                        component="td"
                                        key={j + "-" + rowKey + "-cellTable-" + iCol + "-" + typeColumn}>
                                        {colItem.render ? (
                                            colItem.render(item[colItem.name], item, rowKey)
                                        ) : (
                                            <Typography
                                                className="gridDataFont rowFont"
                                                key={rowKey + "--font--" + iCol + "-" + j}>
                                                {item[colItem.name]}
                                            </Typography>
                                        )}
                                    </TableCell>
                                ))
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

export default GridData;
