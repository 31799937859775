import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import groupOfPointsService from "~/services/groupOfPointsService";

const saveGroupOfPoints = (group, fnCallback = () => {}, LOADING_IDENTIFICATOR = "saveGroupOfPoints") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return groupOfPointsService
		.saveGroup(group)
		.then((id) => {
			let payload = {
				Id: id,
				Nome: group.Nome,
				Ativo: group.Ativo,
			};
			fnCallback(payload);
		})
		.catch((error) => {
			fnCallback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const deleteGroupOfPoints = (groupId, fnCallback, LOADING_IDENTIFICATOR = "deleteGroupOfPoints") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return groupOfPointsService
		.deleteGroupsPoints(groupId)
		.then((response) => {
			let payload = response.data;
			fnCallback();
			Utils.showTranslatedToast({
				type: "info",
				description: response.data,
			});
			dispatch({ type: Constants.DELETE_GROUP_POINTS_BY_ID, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getGroupsPoints = (LOADING_IDENTIFICATOR = "getPointsGroup") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return groupOfPointsService
		.getGroupsPoints()
		.then((res) => {
			let payload = res.data.sort((a, b) => {
				if (a.Nome < b.Nome) {
					return -1;
				}
				if (a.Nome > b.Nome) {
					return 1;
				}
				return 0;
			});
			dispatch({ type: Constants.GET_GROUPS_POINTS, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getPointsLinkedGroupById = (groupId, pagination, LOADING_IDENTIFICATOR = "getPointsLinked") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return groupOfPointsService
		.getPointsLinkedGroupById(groupId, pagination)
		.then((res) => {
			dispatch({ type: Constants.GET_POINTS_LINKED_GROUP, payload: res });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getAvailablePointsForGroupById = (groupId, pagination, LOADING_IDENTIFICATOR = "getAvailablePoints") => (
	dispatch
) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return groupOfPointsService
		.getAvailablePointsForGroupById(groupId, pagination)
		.then((res) => {
			dispatch({ type: Constants.GET_AVAILABLE_POINTS_FOR_GROUP, payload: res });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const saveLinkPoints = (groupId, pointsSelected, LOADING_IDENTIFICATOR = "saveLinkPoints") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return groupOfPointsService
		.saveLinkPoints(groupId, pointsSelected)
		.then((res) => {
			console.log(res);
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const saveUnbindPoints = (groupId, pointsSelected, LOADING_IDENTIFICATOR = "saveUnbindPoints") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return groupOfPointsService
		.saveUnbindPoints(groupId, pointsSelected)
		.then((res) => {
			console.log(res);
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const cleanPointsLinked = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_POINTS_LINKED_GROUP });
};

const cleanAvailablePoints = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_AVAILABLE_POINTS_FOR_GROUP });
};

export default {
	saveGroupOfPoints,
	getGroupsPoints,
	getPointsLinkedGroupById,
	getAvailablePointsForGroupById,
	saveLinkPoints,
	saveUnbindPoints,
	deleteGroupOfPoints,
	cleanPointsLinked,
	cleanAvailablePoints,
};
