import React from "react";
import DashVehicleGraphEnum from "../common/DashVehicleGraphEnum";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";

import Card from "~/components/common/expansionCard/Card";
import KmRunBase from "~/components/dashboardVehicle/baseComparative/kmRunBase/KmRunBase";
import AnalysisItemsBase from "~/components/dashboardVehicle/baseComparative/analysisItemsBase/AnalysisItemsBase";

import CardGrid from "~/components/dashboardVehicle/common/CardGrid";
import "./Base.scss";

const BaseComparative = () => {
    const { currentFilters } = useSelector((state) => state.dashboardVehicleToolbar);

    return (
        <>
            <Card
                title={
                    currentFilters[DashVehicleGraphEnum.BASE_COMPARATIVE.name].checked ||
                    currentFilters[DashVehicleGraphEnum.BASE_COMPARATIVE_ITEMS.name].checked
                        ? "Comparativo com a Base"
                        : ""
                }
                className={"cardBaseComparative card-refactor"}
                notPadded
                classTitle="cardTitleBase">
                <Grid container>
                    <CardGrid
                        item
                        xs={6}
                        name={DashVehicleGraphEnum.BASE_COMPARATIVE.name}
                        className="cardBase">
                        <KmRunBase />
                    </CardGrid>
                    <CardGrid
                        item
                        xs={6}
                        name={DashVehicleGraphEnum.BASE_COMPARATIVE_ITEMS.name}
                        className="cardBase cardItems">
                        <AnalysisItemsBase />
                    </CardGrid>
                </Grid>
            </Card>
        </>
    );
};

export default BaseComparative;
