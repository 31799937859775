import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import LoadingPanel from "~/components/common/loading/LoadingPanel";
import OdometerForm from "~/components/vehicle/register/OdometerForm";
import { Box, Grid } from "@material-ui/core";

const Odometer = ({ closeTab }) => {

    const { loading } = useSelector((state) => state.app);
    const { odometer, odometerForm } = useSelector((state) => state.vehicle);

    useEffect(() => {
        if (!odometerForm || !odometerForm.current) return;
        const values = { ...odometer };
        values.DispositivoOdometro = values.DispositivoOdometro ? values.DispositivoOdometro : 0;
        values.VeiculoOdometro = values.VeiculoOdometro ? values.VeiculoOdometro : 0;
        Object.entries(values).forEach(([key, value]) => {
            odometerForm.current.setFieldValue(key, value);
        });
    }, [odometer, odometerForm]);

    return (
        <Box className="edit-vehicle">
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                    <OdometerForm compact
                        comeBack={() => {
                            closeTab();
                        }}
                    />
                </Grid>
            </Grid>
            {(loading.odometer || loading.getOdometerByID) && <LoadingPanel size={40} />}
        </Box>)
}

export default Odometer