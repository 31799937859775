import React, { useEffect } from "react";
import { useLocation,useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Button } from "@material-ui/core";
import { ComeBackIcon } from "~/components/common/icons/Icons";

import RecoverLogin from "~/components/login/recover/RecoverLogin";
import RecoverPassword from "~/components/login/recoverPassword/RecoverPassword";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import loginTabAction from "~/actions/loginTabAction";
import LoginForm from "./Form/LoginForm";
import CreateAccess from "./Form/CreateAccess";
import Constants from "~/helpers/enums/Constants";
import "./TabLogin.scss";

const TabLogin = (props) => {
    const arrLabel = ["Login", "Cadastrar"];
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const location = useLocation(); 
    const history = useHistory();
    const { visibleRecover } = useSelector((state) => state.recoverLogin);
    const { tabActive } = useSelector((state) => state.loginTab);
    const { recoverPassword } = useSelector((state) => state);
const visibleRecoverPassword = recoverPassword ? recoverPassword.visibleRecoverPassword : false;


    const handleChange = (event, newValue) => {

        dispatch(loginTabAction.setLoginTab(newValue));
        if (newValue === Constants.REGISTER_TAB) {
            history.push('/Cadastrar');
        } else if (newValue === Constants.LOGIN_TAB) {
            history.push('/login');
        }else if (newValue === Constants.RECOVER_PASSWORD_TAB) {
            history.push('/RecoverPassword');
        }
    };

    const StyledTab = withStyles((theme) => ({
        root: {
            textAlign: "left",
            fontFamily: "Trebuchet MS",
            fontSize: "25px",
            letterSpacing: "0px",
            color: "#3D5564",
            fontWeight: "bold",
        },
    }))((_props) => <Tab disableRipple {..._props} />);

    useEffect(() => {

        if (location.pathname === '/Cadastrar') {
            dispatch(loginTabAction.setLoginTab(Constants.REGISTER_TAB));
        } else if (location.pathname === '/login') {
            dispatch(loginTabAction.setLoginTab(Constants.LOGIN_TAB));
        }
        else if (location.pathname === '/RecoverPassword') {
            dispatch(loginTabAction.setLoginTab(Constants.RECOVER_PASSWORD_TAB));
        }
    }, [location, dispatch]);
    
    const showSelected = () => {
        if (tabActive === Constants.LOGIN_TAB) {   
            return <LoginForm />;
        }
        if (tabActive === Constants.REGISTER_TAB) {
            return <CreateAccess />;
        }
        if (tabActive === Constants.RECOVER_PASSWORD_TAB) {
            return <RecoverPassword />;
        }
    };

    return (
        <>
            { Constants.RECOVER_PASSWORD_TAB === tabActive ?  (
                <div style={{ width: "100%", boxSizing: "border-box" }}>
                    <RecoverPassword />
                </div>):
                 visibleRecover ? (
                <div style={{ width: "100%", boxSizing: "border-box" }}>
                    < RecoverLogin/>
                </div>
            ) : (
                <>
                    {Constants.LOGIN_TAB !== tabActive && (
                        <div className="tab-login-comeback">
                            <Button                    
                                disableElevation
                                startIcon={<ComeBackIcon style={{ height: 24, width: 24 }} />}
                                onClick={() => {
                                    dispatch(loginTabAction.setLoginTab(Constants.LOGIN_TAB));
                                }}>
                                {t(Labels.COMEBACK)}
                            </Button>
                        </div>
                        
                    )}
                    
                    <div id="tabs-login-container">
                        <Tabs
                            indicatorColor="secondary"
                            TabIndicatorProps={{
                                style: {
                                  backgroundColor: "#243782"
                                },
                                children: <span /> 
                              }}
                            style={{ borderBottom: "1px solid #AFC3D2" }}
                            value={tabActive}
                            onChange={handleChange}>
                            {arrLabel.map((label, index) => {
                                return <StyledTab key={index} label={label} id={`simple-tab-${index}`} onClick={() => handleChange(null, index)}/>;
                            })}
                        </Tabs>
                    </div>
                    
                    {showSelected()}
                    
                </>
                
            )}
            
        </>
    );
};

export default TabLogin;
