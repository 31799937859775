import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Labels from "~/helpers/enums/Labels";
import ReportingObject from "~/components/reporting/distance/perObject/ReportObject";
import ReportingPeriod from "~/components/reporting/distance/perPeriod/ReportPeriod";
import SchedulingReport from "~/components/reporting/distance/scheduled/SchedulingReport";
import ExpansionCard from "~/components/common/expansionCard/ExpansionCard";
import ExpansionGrid from "~/components/common/expansionCard/ExpansionGrid";
import ExpansionCardEnum from "~/components/common/expansionCard/ExpansionCardEnum";
import FilterObject from "~/components/reporting/distance/perObject/FilterObject";
import FilterPeriod from "~/components/reporting/distance/perPeriod/FilterPeriod";
import DistanceFilterScheduling from "~/components/reporting/distance/scheduled/DistanceFilterScheduling";

import MapCard from "~/components/map/MapCard";
import ReportFilter from "~/components/common/reportTab/ReportFilter";
import { ObjectPanel } from "~/components/common/reportTab/ReportPanel";

const ReportDistance = (props) => {
    const { objectForm, periodForm, scheduleForm } = useSelector((state) => state.distanceReport);
    const [alignment, setAlignment] = useState(ExpansionCardEnum.HORIZONTAL);
    const { t } = useTranslation();
    const refSerachDiv = useRef(null);

    return (
        <div>
            <ReportFilter
                label={t(Labels.FILTERS)}
                onCleanObject={() => {
                    objectForm && objectForm.resetForm();
                }}
                onCleanPeriod={() => {
                    periodForm && periodForm.resetForm();
                }}
                onCleanScheduled={() => {
                    scheduleForm && scheduleForm.resetForm();
                }}
                objectFilter={<FilterObject />}
                periodFilter={<FilterPeriod />}
                scheduledFilter={<DistanceFilterScheduling />}
            />
            <div>
                <ObjectPanel>
                    <ExpansionGrid alignment={alignment} fnClick={setAlignment}>
                        <ExpansionCard
                            card={"relatorioDistancia"}
                            flex={"0 1 30%"}
                            key={"1"}
                            title={t(Labels.RESULTS)}
                            alignment={alignment}
                            className="card-refactor"
                            fnClick={setAlignment}
                            refTitleDiv={refSerachDiv}>
                            <ReportingObject mode={alignment} refSerachDiv={refSerachDiv} />
                        </ExpansionCard>
                        <ExpansionCard
                            card={"relatorioDistanciaMapa"}
                            notPadded
                            title={t(Labels.ROUTE_MAP)}
                            flex={"0 1 70%"}
                            key={"2"}
                            alignment={alignment}
                            fnClick={setAlignment}
                            className="reportDistanceMap card-refactor">
                            <div className="mapScreen">
                                <MapCard className="map-card-refactor" />
                            </div>
                        </ExpansionCard>
                    </ExpansionGrid>
                </ObjectPanel>
            </div>
            <div>
                <ReportingPeriod />
            </div>

            <div>
                <SchedulingReport />
            </div>
        </div>
    );
};

export default ReportDistance;
