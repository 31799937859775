import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { lighten } from "polished";
import "./Search.scss";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0px 2px",
        display: "flex",
        alignItems: "center",
        background: lighten(0.2, "#AFC3D2"),
        boxShadow: "unset",
        flex: 1,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 5,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const Search = (props) => {
    const { value } = props;
    const [searchTerm, setSearchTerm] = useState("");
    const classes = useStyles();

    useEffect(() => {
        setSearchTerm(value);
    }, [value]);

    return (
        <Paper component="div" className={`boxSearchComp ${classes.root} ${props.className}`} style={props.style}>
            <input
                id={props.id}
                className={`search ${props.classNameRefactor}`}
                type="search"
                value={searchTerm}
                onChange={(event) => {
                    const _value = event.target.value;

                    setSearchTerm(_value);
                    props.search(_value);
                }}
                placeholder="Pesquisar"
                required
            />
            <IconButton
                id={props.id + " button"}
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                    props.search(searchTerm);
                }}>
                <SearchIcon fontSize="large" />
            </IconButton>
        </Paper>
    );
};

export default Search;
