import Constants from "~/helpers/enums/Constants";

const initialState = {
	tabActive: "object",
};

function vehicleSearchReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.TAB_ACTIVE_FILTERS:
			return {
				...state,
				tabActive: action.payload.tabActive,
			};

		default:
			return state;
	}
}

export default vehicleSearchReducer;
