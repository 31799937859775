import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Portal from "@material-ui/core/Portal";
import TableCard from "~/components/tableCard/TableCard";
import Search from "~/components/common/search/Search";
import Loading from "~/components/common/loading/Loading";
import useFuso from "~/components/common/fuso/useFuso";
import Pagination from "~/components/common/pagination/Pagination";
import MenuRange from "~/components/common/menuRange/MenuRange";
import { useTranslation } from "react-i18next";
import distanceReportAction from "~/actions/reporting/distanceReportAction";
import { directionToWordApproach } from "~/components/map/ArrowResolver";

import googleMapsDataAction from "~/actions/googleMapsDataAction";
import Labels from "~/helpers/enums/Labels";

import Utils from "~/helpers/Utils";

import "./ReportObject.scss";

const ReportResult = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { requestDistanceObject } = useSelector((state) => state.app.loading);
    const { filters: dataFilters, data } = useSelector((state) => state.reporting.perObject);
    const {
        perObject: { filters },
    } = useSelector((state) => state.distanceReport);

    const [dataObject, setDataObject] = useState([]);
    const { fromUtc } = useFuso();

    useEffect(() => {
        if (data.data) {
            setDataObject(data.data);
        } else {
            setDataObject([]);
        }
    }, [data]);

    useEffect(() => {
        return () => {
            dispatch(googleMapsDataAction.cleanDirections());
        };
    }, [dispatch]);

    const FilterDiv = () => (
        <>
            <Search
                className={"search"}
                style={{ margin: 0 }}
                search={(search) => {
                    if (data.data) {
                        let result = Utils.filterData(data.data, search);
                        setDataObject(result);
                    } else {
                        setDataObject([]);
                    }
                }}
            />
            <div style={{ marginLeft: "10px" }}>
                <MenuRange
                    id={"report-events"}
                    label={t(Labels.MENU_RANGE_SHOW)}
                    className="menu-range-refactor"
                    classNameRefactor="menu-label-range-refactor"
                    value={filters.limit}
                    onChange={(event) => {
                        dispatch(
                            distanceReportAction.updateFilterDistanceObject({
                                index: 0,
                                limit: event.target.value,
                            })
                        );
                    }}
                    options={[10, 25, 50, 100, 250, 500]}
                />
            </div>
        </>
    );

    return (
        <div className="contentCard">
            {props.mode.orientation === "horizontal" ? (
                <div className="spaceSearchReportObject">
                    <FilterDiv />
                </div>
            ) : (
                <Portal container={props.refSerachDiv.current}>
                    <div className="spaceSearchReportObjectPortal">
                        <FilterDiv />
                    </div>
                </Portal>
            )}

            {requestDistanceObject ? (
                <div id="loading-object">
                    <Loading />
                </div>
            ) : (
                <TableCard
                    mode={props.mode}
                    filters={dataFilters}
                    className="content-table-card"
                    data={dataObject.map((obj) => {
                        return {
                            ...obj,
                            Data: fromUtc(obj.Data).format("DD/MM/YYYY | HH:mm:ss"),
                            KmFinal: obj.KmFinal?.toLocaleString("pt-br", { maximumFractionDigits: 0 }),
                            KmInicial: obj.KmInicial?.toLocaleString("pt-br", { maximumFractionDigits: 0 }),
                            DistanciaFimSemana: Utils.convertMToKmPrecision(obj.DistanciaFimSemana),
                            DistanciaForaHorarioComercial: Utils.convertMToKmPrecision(
                                obj.DistanciaForaHorarioComercial
                            ),
                            DistanciaHorarioComercial: Utils.convertMToKmPrecision(obj.DistanciaHorarioComercial),
                            DistanciaPercorrida: Utils.convertMToKmPrecision(obj.DistanciaPercorrida),
                        
                        };
                    })}
                    onItemClick={(element) => {
                        dispatch(googleMapsDataAction.cleanDirections());

                        dispatch(googleMapsDataAction.updateDirections([{
                            ...element,
                            Trajeto: element.Trajeto.map(t => {
                                return {
                                    ...t,
                                    direction: directionToWordApproach(t.direction)
                                }
                            })
                        }]));
                    }}
                />
            )}

            {!dataObject ? null : (
                <div className="pagination-tabmap">
                    <Pagination
                        onChange={(event, value) => {
                            dispatch(
                                distanceReportAction.updateFilterDistanceObject({
                                    index: value - 1,
                                })
                            );
                        }}
                        page={filters.index + 1}
                        count={Math.ceil(data.recordsTotal / filters.limit)}
                    />
                </div>
            )}
        </div>
    );
};

export default ReportResult;
