import React, { useState } from "react";
import moment from "moment-timezone";
import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import { setLightness } from "polished";
import useFuso from "~/components/common/fuso/useFuso";
import "./Fuso.scss";

const Fuso = (props) => {

    const { t } = useTranslation();
    const { timeZone } = useFuso()


    const { user } = useSelector((state) => state.auth);
    const [date, setDate] = useState(moment.tz(timeZone).format("HH:mm"));

    setInterval(function x() {
        setDate(moment.tz(timeZone).format("HH:mm"));
    }, 60000);

    const isPattern = timeZone === "America/Sao_Paulo";
    const zoneNumber = moment.tz(timeZone).format("Z");

    return (
        <Box
            className="fuso-horario"
            style={
                props.color
                    ? {
                          background: `#d9d6d6`,
                          color: "#fff",
                          borderLeft: "1px solid #fff",
                      }
                    : null
            }>
            <div className="fuso" style={props.color ? { color: "#fff" } : { color: "#afc3d2" }}>
                <Typography variant="body1">
                    {t(Labels.TIME_ZONE)} {isPattern ? t(Labels.TIME_ZONE_PATTERN) : t(Labels.TIME_ZONE_UTC)}{" "}
                    {`${parseInt(zoneNumber.split(":")[0])} (UTC ${zoneNumber})`} {" - "}
                    {date}
                </Typography>
            </div>
        </Box>
    );
};

export default Fuso;
