import Constants from "~/helpers/enums/Constants";

const initialState = {
    callDoc: [],
    objectForm: null,
    periodForm: null,
    scheduleForm: null,
    perObject: {
        filters: {
            fleet: null,
            period: {},
            vehicle: null,
            DiasSemana: 1234567,
            limit: 25,
            index: 0,
            typeReport: 30,
            status: -1,
        },
    },
};

function distanceReducer(state = initialState, action = null) {
    switch (action.type) {
        case Constants.CALL_EXPORT_DOCUMENT_DISTANCE:
            return {
                ...state,
                callDoc: action.callDoc,
            };
        case Constants.CLEAN_CALL_EXPORT_DOCUMENT_DISTANCE:
            return {
                ...state,
                callDoc: initialState.callDoc,
            };
        case Constants.UPDATE_FORM_DISTANCE_OBJECT:
            return {
                ...state,
                objectForm: action.payload.form,
            };
        case Constants.UPDATE_FORM_DISTANCE_PERIOD:
            return {
                ...state,
                periodForm: action.payload.form,
            };
        case Constants.UPDATE_FORM_DISTANCE_SHEDULED:
            return {
                ...state,
                scheduleForm: action.payload.form,
            };
        case Constants.UPDATE_FILTER_DISTANCE_OBJECT:
            return {
                ...state,
                perObject: {
                    ...state.perObject,
                    filters: {
                        ...state.perObject.filters,
                        ...action.payload.filters,
                    },
                },
            };
        default:
            return state;
    }
}

export default distanceReducer;
