import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
	pointInterestGroup: [],
	pointInterest: [],
	routes: [],
};

function mapReducer(state = INITIAL_STATE, action = null) {
	switch (action.type) {
		case Constants.GET_POINT_INTEREST_GROUP:
			return {
				...state,
				pointInterestGroup: action.payload,
			};
		case Constants.GET_POINT_INTEREST:
			return {
				...state,
				pointInterest: action.payload,
			};
		case Constants.GET_ROUTES:
			return {
				...state,
				routes: action.payload,
			};
		case Constants.CLEAN_MAP_POINT_INTEREST_GROUP:
			return {
				...state,
				pointInterestGroup: INITIAL_STATE.pointInterestGroup,
			};
		case Constants.CLEAN_MAP_POINT_INTEREST:
			return {
				...state,
				pointInterest: INITIAL_STATE.pointInterest,
			};
		case Constants.CLEAN_MAP_ROUTES:
			return {
				...state,
				routes: INITIAL_STATE.routes,
			};
		default:
			return state;
	}
}

export default mapReducer;
