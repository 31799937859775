import React, { useRef } from "react";
import ReactToPrint from 'react-to-print';

import Page from "~/components/common/page/Page";
import DashboardToolbar from "~/components/dashboard/common/DashboardToolbar";
import Dashboard from "~/components/dashboard/Dashboard.jsx";
import { Container, Grid } from "@material-ui/core";
import ExportButton from "~/components/common/exportButton/ExportButton";

const DashboardView = () => {

	const componentRef = useRef();

	// let content = document.getElementById("content");
	// let printter = document.getElementById("ifmcontentstoprint")?.contentWindow;

	// const doDownload = () => {

	// 	printter.document.open();
	// 	printter.document.write(content.innerHTML);
	// 	printter.document.close();
	// 	printter.focus();
	// 	printter.print();

	// }

	return (
		<>
			<Page
				bar={<DashboardToolbar />}
				topButtons={<ReactToPrint
					trigger={() => <ExportButton />}
					content={() => componentRef.current}
				/>}
				// topButtons={<ExportButton onClick={() => doDownload()} />}
				>
				<main>
					<Container  maxWidth="lg" className="container-map-view">
						<Grid id="content" container>
							<Dashboard ref={componentRef} />
							{/* <iframe id="ifmcontentstoprint" style={{ height: 0, width: 0, position: "absolute" }}></iframe> */}
						</Grid>
					</Container>
				</main>
			</Page>
		</>
	);
};

export default DashboardView;
