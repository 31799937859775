import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import Utils from "~/helpers/Utils";

import userAction from "~/actions/userAction";

import { Formik, Form } from "formik";
import { Grid, Button, CircularProgress, Box } from "@material-ui/core";

import { CancelIcon, ConfirmIcon } from "~/components/common/icons/Icons";
import AccordionAlert from "~/components/common/accordion/ContentAccordion";
import RegisterCheckbox from "~/components/common/registerInputs/RegisterCheckbox";
import TableCard from "~/components/tableCard/TableCardReUse";
import CardGroupUserFleets from "~/components/common/card/CardGroup";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

const FormEditionUser = (props) => {
    const dispatch = useDispatch();

    const { loading } = useSelector((state) => state.app);
    const { user, fleets, permissions, displayFleet } = useSelector((state) => state.user);
    const { t } = useTranslation();

    const [checkedObject, setCheckedObject] = React.useState({});
    const [functionalities, setFunctionalities] = React.useState([]);
    const [fleetsSelected, setFleetsSelected] = React.useState([]);
    const [fleetsCompleted, setFleetCompleted] = React.useState(false);
    const [fleetsObj, setFleetsObj] = React.useState([]);

    useEffect(() => {
        dispatch(userAction.getFleetByUserId(user.Id));
        dispatch(userAction.getPermissionsByUserId(user.Id));
    }, [dispatch, user]);

    useEffect(() => {
        //dispatch(userAction.setDisplayFleet(user.TodosVeiculos));
    }, [dispatch, user]);

    const handleChangeChecked = (_item, _data) => {
        if (_item.id === parseInt(_data)) {
            setCheckedObject({
                ...checkedObject,
                [_item.id]: !checkedObject[_item.id],
            });

            let funcs = functionalities.filter(function (e) {
                return parseInt(e) !== _item.id;
            });

            let itemFunc = functionalities.filter(function (e) {
                return parseInt(e) === _item.id;
            });

            if (_.isEmpty(itemFunc)) {
                funcs.push(String(_item.id));
            }

            setFunctionalities(funcs);
        }
    };

    const handleChangeFleets = (newSelected, data, selected, name) => {
        let fleetCheck;

        fleetCheck = fleetsSelected.filter(function (e) {
            return e !== name;
        });

        let fleetCheckAdd = fleetsSelected.filter(function (e) {
            return e === name;
        });

        if (_.isEmpty(fleetCheckAdd)) {
            fleetCheck.push(name);
        }

        if (name === "all" && selected) {
            fleetCheck = [];
        }

        if (name === "all" && !selected) {
            fleetCheck = [];
            data.forEach((item) => {
                fleetCheck.push(item.Value);
            });
        }

        setFleetsSelected(fleetCheck);
    };

    useEffect(() => {
        let func = [];
        let arrayObj = {};
        user.Funcionalidades.forEach((item) => {
            func.push(item === "" ? "0" : item);
            arrayObj = { ...arrayObj, [item]: item };
            setCheckedObject(arrayObj);
            setFunctionalities(func);
        });
    }, [user]);

    useEffect(() => {
        let fleetArray = [];
        if (!_.isEmpty(fleets)) {
            fleets.forEach((item) => {
                if (item.Selected) {
                    fleetArray.push(item.Value);
                }
                setFleetsSelected(fleetArray);
            });
            setFleetCompleted(true);
        }
        if (fleets) {
            setFleetsObj(fleets);
        } else {
            setFleetsObj([]);
        }
    }, [fleetsCompleted, user.Frotas, fleets]);

    const getResponseMsg = (_res) => {
        if (typeof _res === "object") {
            Utils.showTranslatedToast({
                type: "error",
                description: "Error ao editar usuário.",
            });
        } else {
            Utils.showTranslatedToast({
                type: "success",
                description: _res,
            });
            props.onDisplay();
        }
    };

    const CARS_COLUMNS = [
        {
            name: "Text",
            label: "Todos os Veículos",
        },
    ];

    const filterDataArray = (search) => {
        if (fleets) {
            let result = Utils.filterData(fleets, search);
            setFleetsObj(result);
        } else {
            setFleetsObj([]);
        }
    };

    return (
        <Box className={"cardEditUser"}>
            <Formik
                validateOnBlur={true}
                validateOnChange={true}
                initialValues={{ Funcionalidades: [], FrotasId: [] }}
                validate={(values) => {
                    const errors = {};
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    if (_.isEmpty(fleetsSelected)) {
                        Utils.showTranslatedToast({
                            type: "error",
                            description: "Por favor, selecione ao menos uma frota!",
                        });
                        return;
                    }

                    let arrayFunc = [];

                    let addFunc = functionalities.filter(function (e) {
                        return e === "66";
                    });

                    if (_.isEmpty(addFunc)) {
                        arrayFunc = ["66", ...functionalities];
                    } else {
                        arrayFunc = functionalities;
                    }

                    let userReq = {
                        Id: user.Id,
                        Nome: user.Nome,
                        Email: user.Email,
                        Login: user.Login,
                        Funcionalidades: arrayFunc,
                        TodosVeiculos: displayFleet,
                        FrotasId: fleetsSelected,
                        UsuarioCupId: user.UsuarioCupId,
                    };
                    dispatch(userAction.editUser(userReq, getResponseMsg));
                }}
                render={({ submitForm, setFieldValue, validateForm }) => {
                    return (
                        <Form id="permissions-user-form" className="ceabs-register-form">
                            <div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} className={"userPermissions"}>
                                        {loading.getPermissionsByUserId ? (
                                            <CircularProgress
                                                style={{ height: 32, width: 32, marginRight: 8 }}
                                                color="primary"
                                            />
                                        ) : null}
                                        {permissions.length > 0 &&
                                            permissions.map((folder) => {
                                                return (
                                                    <AccordionAlert title={folder.name} titleColor>
                                                        <div className="itemAlerts">
                                                            <div
                                                                className="conditionsList"
                                                                role="group"
                                                                aria-labelledby="checkbox-group">
                                                                {folder.additionalParameters.children.map((item, j) => (
                                                                    <span key={item.id}>
                                                                        <RegisterCheckbox
                                                                            id={item.id}
                                                                            value={item.id}
                                                                            name={item.name}
                                                                            size="medium"
                                                                            color="primary"
                                                                            onChange={(event) => {
                                                                                handleChangeChecked(
                                                                                    item,
                                                                                    event.target.id
                                                                                );
                                                                            }}
                                                                            labelLegend={item.name}
                                                                            checked={
                                                                                checkedObject[item.id]
                                                                                    ? checkedObject[item.id]
                                                                                    : false
                                                                            }
                                                                        />
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </AccordionAlert>
                                                );
                                            })}
                                    </Grid>
                                    <Grid item xs={12} className="boxGridBtn btnPermission">
                                        <Grid className="button-form button-form-refactor" item xs={3}>
                                            <Button
                                                id="cancelButton"
                                                className="cancel-button cancel-button-refactor"
                                                fullWidth
                                                variant="contained"
                                                disableElevation
                                                onClick={props.onDisplay}>
                                                <CancelIcon />
                                                {t(Labels.VEHICLE_FORM_BUTTON_CANCEL)}
                                            </Button>
                                        </Grid>
                                        <Grid className="button-form button-form-refactor" item xs={5}>
                                            <Button
                                                id="speeding-filter-period-save-buttont"
                                                className="save-button save-button-refactor"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                disableElevation
                                                disabled={loading.saveGroupOfPoints}
                                                onClick={submitForm}>
                                                {loading.saveGroupOfPoints ? (
                                                    <CircularProgress
                                                        style={{ height: 14, width: 14, marginRight: 8 }}
                                                        color={"#fff"}
                                                    />
                                                ) : (
                                                    <ConfirmIcon />
                                                )}
                                                Salvar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12}>
                                        {!displayFleet && (
                                            <CardGroupUserFleets
                                                data-id={"cadastrar-fleets-users-id"}
                                                title={"Frotas"}
                                                className="group-items card-fleets-list-tabs"
                                                classHeader={"search-group-item"}
                                                loading={loading.getFleetByUserId}
                                                searchFn={filterDataArray}>
                                                <TableCard
                                                    columns={CARS_COLUMNS}
                                                    checkBox={true}
                                                    rowSelection={{
                                                        refColName: "Value",
                                                        onChange: (newSelected, data, selected, name) => {
                                                            handleChangeFleets(newSelected, data, selected, name);
                                                        },
                                                    }}
                                                    dataSource={fleetsObj}
                                                    isChecked
                                                    fleetsSelecteds={fleetsSelected}
                                                />
                                            </CardGroupUserFleets>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </Form>
                    );
                }}
            />
        </Box>
    );
};

export default FormEditionUser;
