export const TRAVELLED_DISTANCE = {
	id: 3,
	description: "Distância Percorrida",
};

export const EVENTS = {
	id: 22,
	description: "Eventos",
};

export const TRAVELS = {
	id: 30,
	description: "Viagens",
};

export const SPEEDING_ON_ROAD = {
	id: 76,
	description: "Excesso de velocidade na via",
};

export const LIST = [TRAVELLED_DISTANCE, EVENTS, TRAVELS, SPEEDING_ON_ROAD];

export default { TRAVELLED_DISTANCE, EVENTS, TRAVELS, SPEEDING_ON_ROAD, LIST };
