import React, { useState } from "react";
import { useSelector } from "react-redux";
import Constants from "~/helpers/enums/Constants";
//Alerts Filter
import ListAlert from "~/components/reporting/alerts/AlertViolated/ListAlert";
import AlertFilter from "~/components/reporting/alerts/AlertViolated/AlertFilter";
//Sizing Griding
import ExpansionCard from "~/components/common/expansionCard/ExpansionCard";
import ExpansionGrid from "~/components/common/expansionCard/ExpansionGrid";
import ExpansionCardEnum from "~/components/common/expansionCard/ExpansionCardEnum";
import MapCard from "~/components/map/MapCard";
import ReportFilter from "~/components/common/reportTab/ReportFilter";
import AlertViolated from "~/components/reporting/alerts/AlertViolated/AlertViolated";
import { Tab } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import "./AlertContainer.scss";

const ReportEvents = (props) => {
    const [alignment, setAlignment] = useState(ExpansionCardEnum.HORIZONTAL);
    const { filterForm } = useSelector((state) => state.alertReport);
    const { t } = useTranslation();

    return (
        <div>
            <ReportFilter
                onCleanObject={() => {
                    filterForm && filterForm.resetForm();
                }}
                tabs={<Tab value={Constants.TAB_OBJECT} label={t(Labels.FILTERS)} />}
                panels={<AlertFilter />}
            />

            <ListAlert hideAlignments={true} />

            <ExpansionGrid alignment={alignment} fnClick={setAlignment}>
                <ExpansionCard
                    card={"relatorioDistancia"}
                    flex={"0 1 500px"}
                    key={"2"}
                    alignment={alignment}
                    fnClick={setAlignment}
                    className="card-refactor"
                    title={t(Labels.TITLE_ALERT_GRID)}
                    >
                    <AlertViolated mode={alignment} />
                </ExpansionCard>
                <ExpansionCard
                    card={"relatorioDistanciaMapa"}
                    notPadded
                    title={t(Labels.ROUTE_MAP)}
                    flex={"0 1 100%"}
                    key={"3"}
                    alignment={alignment}
                    fnClick={setAlignment}
                    className="reportAlertsMap card-refactor">
                    <div className="mapScreen">
                        <MapCard className="map-card-refactor" />
                    </div>
                </ExpansionCard>
            </ExpansionGrid>
        </div>
    );
};

export default ReportEvents;
