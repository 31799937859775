import React from 'react';

import { useDispatch, useSelector } from "react-redux";

import recoverAction from "~/actions/recover/recoverActions";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import { Button } from '@material-ui/core';

import TabRecover from '~/components/common/tab/TabMain'
import FormRecover from '~/components/login/recover/FormRecover'

import { ComeBackIcon } from "~/components/common/icons/Icons";

import './Recover.scss';

const RecoverLogin = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { visibleRecover } = useSelector((state) => state.recoverLogin)

  const TABS = [
    {
      id: 0,
      label: t(Labels.RECOVER_PASSWORD_TAB_LABEL),
      children: (<FormRecover />)
    }
  ]

  const backToLogin = () => {
    dispatch(recoverAction.visibleFormRecover(!visibleRecover));
  }

  return (
      <div className="recoverPass">
          <Button
              disableElevation
              startIcon={<ComeBackIcon style={{ height: 24, width: 24 }} />}
              onClick={() => {
                  backToLogin()
              }}>
              {t(Labels.COMEBACK)}
          </Button>
          <TabRecover id="TabRecover" tabs={TABS} className={"classMainTabRecover"} />
      </div>
  );
}

export default RecoverLogin;
