import React, { useEffect, useState } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { LinearProgress, Box, Container, Toolbar, Typography, Badge } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Menu from "~/components/app/menu/Menu";
import authAction from "~/actions/authAction";
import communicateActions from "~/actions/communicateActions";
import renewalActions from "~/actions/renewalActions";
import alertActions from "~/actions/alertActions";
import { Notification } from "~/components/common/icons/Icons";
import { Icon, ListIcon, HelpOutlineIcon, ProfileIcon, ExitIcon } from "~/components/common/icons/Icons";
import Dropdown from "~/components/common/dropdown/Dropdown";
import DropdownItem from "~/components/common/dropdown/DropdownItem";
import appAction from "~/actions/appAction";
import Avatar from "@material-ui/core/Avatar";
import "./Layout.scss";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";

const URL_HELP = "https://www.manula.com/manuals/ceabs-servicos/uconnectfleetportal/1/pt/topic/introducao";

const anonymousTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#6EC8AF",
            contrastText: "#fff",
            bgColorAccordion: "#eef2f5",
        },
    },
    typography: {
        fontFamily: ["Ubuntu", "sans-serif"],
    },
});

const AuthenticatedLayout = (props) => {
    const dispatch = useDispatch();
    const { loading, pageToolbar } = useSelector((state) => state.app);
    const { user, infos } = useSelector((state) => state.auth);
    const { communicated } = useSelector((state) => state.communicate);
    const [flagNotice, setFlag] = React.useState(true);
    const { t } = useTranslation();
    let history = useHistory();

    useEffect(() => {
        dispatch(communicateActions.getAllCommunicated());
        dispatch(communicateActions.getPendingNotices());
        dispatch(renewalActions.getRenewal());
        if (!user) dispatch(authAction.getProfile());
    }, [dispatch, user]);

    useEffect(() => {
        const searchNewAlertLoop = setInterval(function () {
            dispatch(alertActions.getNewAlertPopup());
        }, 180000);
        return () => {
            clearInterval(searchNewAlertLoop);
        };
    }, [dispatch]);

    useEffect(() => {
        if (communicated) {
            for (let i = 0; i < communicated?.data?.length; i++) {
                !communicated?.data[i].Visualizado ? setFlag(false) : setFlag(true);
            }
        }
    }, [communicated]);

    const handleToogleToolbar = () => {
        dispatch(appAction.tooglePageToolbar(!pageToolbar));
    };

    const handleOpenCommunicate = () => {
        history.push("/communicated/");
    };

    const autheticatedTheme = createMuiTheme({
        palette: {
            primary: {
                main: user && user.colors && user.colors.topBarColor ? user.colors.topBarColor : "#3f51b5",
                contrastText: "#fff",
                bgColorAccordion: "#eef2f5",
            },
            secondary: {
                main: user && user.colors && user.colors.colorPanels ? user.colors.colorPanels : "#9e9e9e",
                contrastText: "#fff",
            },
        },
        typography: {
            fontFamily: ["Trebuchet MS", "sans-serif"],
            fontSize: 10,
        },
    });

    return (
        <ThemeProvider theme={autheticatedTheme}>
            <Box className="ceabs-layout">
                <Box className="ceabs-header">
                    <Toolbar>
                        <div className="icon-menu-list">
                            <ListIcon onClick={handleToogleToolbar} />
                        </div>

                        <div className="icon-menu-logo">
                            <Icon
                                url="https://ceabs-sites.s3.amazonaws.com/CPS/logo_my_uconnect+fleet.png"
                                alt={"ceabs"}
                            />
                        </div>
                        <div id="info-user-main" className="user-profile">
                            <div style={{ cursor: "pointer" }} id="info-help" className="help">
                                <HelpOutlineIcon
                                    onClick={() => {
                                        window.open(URL_HELP);
                                    }}
                                    style={{ color: "#3D5564", height: 24, width: 24 }}
                                />
                            </div>

                            <div
                                style={{ cursor: "pointer" }}
                                onClick={handleOpenCommunicate}
                                id="info-notification"
                                className="notification">
                                <Badge color="primary" variant="dot" invisible={flagNotice}>
                                    <Notification
                                        style={{ color: "#3D5564", height: "19px", borderRadius: "inherit" }}
                                    />
                                </Badge>
                            </div>

                            <div id="info-user" className="action-info">
                                <Typography id="info-name" className="username">
                                    {user && user.name}
                                </Typography>
                                <Dropdown>
                                    <DropdownItem
                                        onClick={() => {
                                            history.push("/profile");
                                        }}
                                        icon={<ProfileIcon />}
                                        text={t(Labels.MENU_PROFILE)}
                                    />
                                    <DropdownItem
                                        onClick={() => {
                                            dispatch(authAction.logout());
                                        }}
                                        icon={<ExitIcon />}
                                        text={t(Labels.MENU_LOGOUT)}
                                    />
                                </Dropdown>
                            </div>

                            <Avatar size={30} className="user-avatar" src={infos.UrlImagemUsuario} />
                        </div>
                    </Toolbar>
                    {Object.values(loading).some((x) => x) && <LinearProgress />}
                </Box>
                <Box className="ceabs-main">
                    <Box
                        className="ceabs-menu"
                        style={{
                            background: `
              ${user.colors.topBarColor} 
               `,
                        }}>
                        <Menu />
                    </Box>
                    <Box className="ceabs-content">
                        <Container maxWidth={false}>{props.children} </Container>
                    </Box>
                </Box>
            </Box>

            <ToastContainer />
        </ThemeProvider>
    );
};

const AnonymousLayout = (props) => {
    const { loading } = useSelector((state) => state.app);

    return (
        <ThemeProvider theme={anonymousTheme}>
            {loading.header && <LinearProgress />}
            <Box>{props.children}</Box>
            <ToastContainer />
        </ThemeProvider>
    );
};

export default { AuthenticatedLayout, AnonymousLayout };
