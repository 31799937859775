const formatRadar = ({ data }) => {

	const { ScoreRiscoEventosBruscos, ScoreRiscoHorario, ScoreRiscoDistancia, ScoreRiscoVelocidade } = data;

	const payload = [ScoreRiscoHorario, ScoreRiscoEventosBruscos, ScoreRiscoVelocidade, ScoreRiscoDistancia];

	return payload;
};

const customChart = ({ data: { Veiculo } }) => {
	const { ScoreDirecao, ScoreExposicaoRisco } = Veiculo;

	const payload = { ScoreDirecao, ScoreExposicaoRisco };

	return payload;
};

export default { customChart, formatRadar };
