import Constants from "~/helpers/enums/Constants";
import dashboardService from "~/services/dashboard/dashboardService";
import Utils from "~/helpers/Utils";

const toggleMarkedFilter = (name, checked) => (dispatch) => {
	dispatch({ type: Constants.TOGGLE_MARKED_FILTER, name, checked });
};

const getFleets = (LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	return dashboardService
		.fleets()
		.then(({ data }) => {
			dispatch({ type: Constants.SET_FLEET_LIST, payload: { fleetList: data } });
		})
		.catch((error) => console.log(error))
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getSubFleets = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	return dashboardService
		.subFleets(filter)
		.then(({ data }) => {
			clearSubFleets();
			clearSelectedSubFleet();
			dispatch({ type: Constants.SET_SUB_FLEET_LIST, payload: { subFleetList: data } });
		})
		.catch((error) => console.log(error))
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const clearSubFleets = () => (dispatch) => {
	dispatch({ type: Constants.SET_SUB_FLEET_LIST, payload: { subFleetList: [] } });
};

const clearSelectedSubFleet = () => (dispatch) => {
	dispatch({ type: Constants.SELECT_SUB_FLEET, payload: { subFleetSelected: {} } });
};

const setRangeDate = (rangeDate) => (dispatch) => {
	dispatch({
		type: Constants.SET_RANGE_DATE,
		payload: {
			rangeDate,
		},
	});
};

const setFleet = (fleet) => (dispatch) => {
	dispatch({
		type: Constants.SELECT_FLEET,
		payload: {
			fleetSelected: fleet,
		},
	});
};

const setSubFleet = (subFleet) => (dispatch) => {
	dispatch({
		type: Constants.SELECT_SUB_FLEET,
		payload: {
			subFleetSelected: subFleet,
		},
	});
};

export default {
	toggleMarkedFilter,
	getFleets,
	getSubFleets,
	setRangeDate,
	setFleet,
	setSubFleet,
	clearSubFleets,
	clearSelectedSubFleet,
};
