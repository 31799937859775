import Constants from "~/helpers/enums/Constants";

const initialState = {
    carsLinked: [],
    availableCars: [],
    functionality: [],
    fleets: [],
    permissions: [],
    user: {
        Funcionalidades: [],
    },
    users: {},
    displayFleet: false,
    searchTerm: "",
};

function userReducer(state = initialState, action = null) {
    const { payload } = action;
    switch (action.type) {
        case Constants.GET_CARS_LINKED_FOR_USER:
            return {
                ...state,
                carsLinked: payload,
            };
        case Constants.DISPLAY_FLEET_USER:
            return {
                ...state,
                displayFleet: payload,
            };
        case Constants.GET_USERS:
            return {
                ...state,
                users: payload,
            };
        case Constants.GET_USER_BY_ID:
            return {
                ...state,
                user: payload,
            };
        case Constants.GET_AVAILABLE_CARS_FOR_USER:
            return {
                ...state,
                availableCars: payload,
            };
        case Constants.CLEAN_CARS_LINKED_FOR_USER:
            return {
                ...state,
                carsLinked: initialState.carsLinked,
            };
        case Constants.CLEAN_AVAILABLE_CARS_FOR_USER:
            return {
                ...state,
                availableCars: initialState.availableCars,
            };
        case Constants.GET_FUNCTIONALITY_FOR_USER:
            return {
                ...state,
                functionality: payload,
            };
        case Constants.GET_FLEET_BY_USER:
            return {
                ...state,
                fleets: payload,
            };
        case Constants.GET_PERMISSIONS_BY_USER:
            return {
                ...state,
                permissions: payload,
            };
        case Constants.SET_USER_SEARCH:
            return {
                ...state,
                searchTerm: payload,
            };
        default:
            return state;
    }
}

export default userReducer;
