import { Button, CircularProgress, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import groupDriversAction from "~/actions/groupDriversAction";
import Loading from "~/components/common/loading/LoadingPanel";
import DualList from "~/components/common/dualList/DualList";
import Card from "~/components/common/expansionCard/Card";
import { SaveReportIcon } from "~/components/common/icons/Icons";
import CheckboxPGF from "~/components/common/inputs/checkbox/CheckboxPGF";
import RegisterInputText from "~/components/common/registerInputs/RegisterInputText";
import Labels from "~/helpers/enums/Labels";
import "./RegisterGroupOfDrivers.scss";

const RegisterGroupOfDrivers = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	let history = useHistory();
	const { location } = history;

	const { loading } = useSelector((state) => state.app);
	const { user } = useSelector((state) => state.auth);
	const { driversLinked, availableDrivers } = useSelector((state) => state.groupDriversReducer);

	useEffect(() => {
		if ((!loading.saveUnbindDriver || !loading.saveLinkDriver) && location?.state) {
			const filter = {
				length: 10,
				page: 0,
			};
			dispatch(groupDriversAction.getAvailableDriversForGroupById(location.state.Id, filter));
			dispatch(groupDriversAction.getDriversLinkedGroupById(location.state.Id, filter));
		}
	}, [dispatch, location.state, loading.saveUnbindDriver, loading.saveLinkDriver]);

	useEffect(() => {
		if (location?.state) {
			dispatch(groupDriversAction.getDriversLinkedGroupById(location.state.Id));
			dispatch(groupDriversAction.getAvailableDriversForGroupById(location.state.Id));
		}
	}, [dispatch, history, location.pathname, location.state]);

	useEffect(() => {
		if (!location?.state?.Id && location.pathname !== "/register/groupOfDrivers/new") {
			history.replace("/register/groupOfDrivers/new");
		}
	}, [history, location.pathname, location.state]);

	useEffect(() => {
		dispatch(groupDriversAction.cleanDriversLinked());
		dispatch(groupDriversAction.cleanAvailableDrivers());
	}, [dispatch]);

	const GROUP_DRIVERS_COLUMNS = [
		{
			name: "Nome",
			label: t(Labels.GROUP_DRIVERS_REGISTER_NAME),
			render: (nome) => <strong>{nome}</strong>,
		},
		{
			name: "Cpf",
			label: t(Labels.GROUP_DRIVERS_REGISTER_CPF),
			render: (cpf) => <span>{cpf}</span>,
		},
		{
			name: "NumeroCNH",
			label: t(Labels.GROUP_DRIVERS_REGISTER_CNH),
			render: (cpf) => <span>{cpf}</span>,
		},
		{
			name: "CategoriaCNH",
			label: t(Labels.GROUP_DRIVERS_REGISTER_CATEGORIA),
		},
		{
			name: "Identificador",
			label: t(Labels.GROUP_DRIVERS_REGISTER_IDENTIFICADOR),
		},
	];

	const savedGroup = (group) => {
		if (!!location?.state?.Id) {
			history.push({ pathname: `/register/groupOfDrivers/` });
		} else {
			history.push({ pathname: `/register/groupOfDrivers/${group.Id}` }, group);
		}
	};

	const getIdOfselectedRows = (rows) => {
		return rows.map((r) => r.Id);
	};

	const handleLinkDriver = (selectedRows) => {
		const selectedRowsId = getIdOfselectedRows(selectedRows);
		dispatch(groupDriversAction.saveLinkDriver(location.state.Id, selectedRowsId));
	};

	const handleUnbindDriver = (selectedRows) => {
		const selectedRowsId = getIdOfselectedRows(selectedRows);
		dispatch(groupDriversAction.saveUnbindDriver(location.state.Id, selectedRowsId));
	};

	let initialValue = { Nome: location?.state?.Nome || "", Ativo: location?.state?.Ativo || false };

	const handlerSearchPagingAvailable = (search, pagination) => {
		const filter = {
			length: pagination.length,
			page: pagination.current - 1 || 0,
			search: search,
		};
		dispatch(groupDriversAction.getAvailableDriversForGroupById(location.state.Id, filter));
	};

	const handlerSearchPagingLinked = (search, pagination) => {
		const filter = {
			length: pagination.length,
			page: pagination.current - 1 || 0,
			search: search,
		};
		dispatch(groupDriversAction.getDriversLinkedGroupById(location.state.Id, filter));
	};

	return (
		<div>
			<Card>
				<Formik
					validateOnBlur={false}
					validateOnChange={false}
					initialValues={initialValue}
					validate={(values) => {
						const errors = {};

						if (!values.Nome) {
							errors.Nome = t(Labels.GROUP_DRIVERS_REGISTER_REQUIRED);
						}

						return errors;
					}}
					onSubmit={(values, { setSubmitting }) => {
						setSubmitting(false);
						let groupReq = {
							Nome: values.Nome,
							Ativo: !!values.Ativo ? 1 : 0,
						};
						if (location?.state?.Id) {
							groupReq.Id = location.state.Id;
						}

						dispatch(groupDriversAction.saveGroupOfDrivers(groupReq, savedGroup));
					}}
					render={({ submitForm, setFieldValue }) => {
						return (
							<Form id="register-group-drivers-form" className="ceabs-register-form">
								<Grid container justify="right" alignItems="flex-end" spacing={1}>
									<Grid item xs={3}>
										<RegisterInputText
											name="Nome"
											noPadding
											placeholder={t(Labels.GROUP_DRIVERS_REGISTER_NAME_PLACEHOLDER)}
											label={t(Labels.GROUP_DRIVERS_REGISTER_NAME)}
										/>
									</Grid>
									<Grid item>
										<CheckboxPGF
											color="primary"
											id="Ativo"
											name="Ativo"
											label={t(Labels.GROUP_DRIVERS_REGISTER_ACTIVE)}
										/>
									</Grid>

									<Grid className="button-form button-form-refactor" item xs={3}>
										<Button
											id="register-group-drivers-save-button"
											className="save-button save-button-refactor"
											fullWidth
											variant="contained"
											color="primary"
											disableElevation
											disabled={loading.saveGroupOfDrivers}
											onClick={submitForm}>
											{loading.saveGroupOfDrivers ? (
												<CircularProgress style={{ height: 14, width: 14, marginRight: 8 }} color={"#fff"} />
											) : (
												<SaveReportIcon />
											)}
											{t(Labels.GROUP_DRIVERS_REGISTER_BTNSAVE)}
										</Button>
									</Grid>
								</Grid>
							</Form>
						);
					}}
				/>
			</Card>
			{!!location?.state?.Id && (
				<Grid container justify="right" alignItems="flex-end" spacing={1} className="main-group-register">
					<Grid item xs={12}>
						{loading.saveUnbindDriver || loading.saveLinkDriver ? (
							<Loading />
						) : (
							<DualList
								classNameRefactor="dual-list-refactor"
								actionAdd={handleLinkDriver}
								actionRemove={handleUnbindDriver}
								tableAvailable={{
									key: "Available",
									title: t(Labels.GROUP_DRIVERS_AVAILABLES_DRIVERS_LABEL),
									columns: GROUP_DRIVERS_COLUMNS,
									dataSource: availableDrivers.data,
									rowSelection: {
										refColName: "Id",
										onChange: (selectedRows) => {
											console.log("selectedRows", selectedRows);
										},
									},
									pagination: {
										current: availableDrivers.index,
										totalItems: availableDrivers.recordsTotal,
									},
									onChange: handlerSearchPagingAvailable,
									loading: loading.getAvailableDrivers,
								}}
								tableLinked={{
									key: "Linked",
									title: t(Labels.GROUP_DRIVERS_LINKED_LABEL),
									columns: GROUP_DRIVERS_COLUMNS,
									dataSource: driversLinked.data,
									rowSelection: {
										refColName: "Id",
										onChange: (selectedRows) => {
											console.log("selectedRows", selectedRows);
										},
									},
									loading: loading.getDriversLinked,
									search: true,
									pagination: {
										current: driversLinked.index,
										totalItems: driversLinked.recordsTotal,
									},
									onChange: handlerSearchPagingLinked,
									backgroundColor: user.colors.topBarColor,
								}}
							/>
						)}
					</Grid>
				</Grid>
			)}
		</div>
	);
};

export default RegisterGroupOfDrivers;
