import React, { useState } from "react";

import { Grid } from "@material-ui/core";

import { useSelector } from "react-redux";

import Card from "~/components/common/expansionCard/Card";

import MedidorAzul from "~/assets/icons/dashboard/icon-medidor-blue.svg";
import MedidorOrange from "~/assets/icons/dashboard/icon-medidor-orange.svg";
import MedidorPurple from "~/assets/icons/dashboard/icon-medidor-purple.svg";
import Arrow from "~/assets/images/arrow-up-cinza.png";
import { convertMToKm } from "~/helpers/Utils";

import "./KmRunStyles.scss";

export default function KmRunIndicator({ title, action }) {
    const indicator = useSelector((state) => state.dashboardTravels[action]);
    const indicatorMedia = useSelector((state) => state.dashboardTravels[action + "Media"]);

    const [isDetails, setDetails] = useState(false);

    const getIcon = () => {
        const img = {
            distanciaComercial: MedidorAzul,
            distanciaNaoComercial: MedidorOrange,
            distanciaFimSemana: MedidorPurple,
            default: null,
        };

        return <img src={img[action] || img.default} alt="Icone do medidor" />;
    };

    const handlerHideCard = () => {
        setDetails(!isDetails);
    };

    return (
        <Card
            title={title}
            className="indicator card-refactor"
            classTitle="title"
            topButtons={
                <img
                    data-id={"clickIcon"}
                    src={Arrow}
                    alt="ícone para detalhar os card de km rodados"
                    width="20"
                    onClick={() => handlerHideCard()}
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: 10,
                        top: 15,
                        bottom: 0,
                        transform: isDetails ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "all 1s",
                        width: 15,
                    }}
                />
            }>
            <Grid item xs={12} style={{ display: isDetails ? "none" : "flex", transition: ".2s", paddingTop: 20 }}>
                <Grid item xs={4}>
                    {getIcon()}
                </Grid>
                <Grid item xs={8}>
                    <div className="km">{convertMToKm(indicator)} km</div>
                    <div>
                        <span className="media-veiculo">Média por veículo: </span>
                        <span className="media-veiculo-km">{convertMToKm(indicatorMedia)} km</span>
                    </div>
                </Grid>
            </Grid>
        </Card>
    );
}
