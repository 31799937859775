import React from "react";

import _ from "lodash";

import { Box, ListItem, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

import ContentAccordion from "~/components/common/accordion/ContentAccordion";

import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";

import "./AccordionBox.scss";

const useStyles = makeStyles((theme) => ({
	itemAccorddion: {
		"& .Mui-selected": {
			background: `${fade(theme.palette.primary.main, 0.3)} !important`,
		},
	},
}));

const AccordionBox = ({ columns, data, keyColumn, select }) => {
	const { t } = useTranslation();

	const [expanded, setExpanded] = React.useState(false);
	const [idBgColor, setBgColor] = React.useState(null);
	const [idBgColorItem, setBgColorItem] = React.useState(null);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const isBgColor = (_id) => {
		if (_id === idBgColor) {
			return true;
		}
	};

	const isBgColorItem = (_id) => {
		if (_id === idBgColorItem) {
			return true;
		}
	};

	const onSelect = (_event, data) => {
		select(data);
		setBgColor(data.Id);
	};

	const onSelectItem = (_event, data) => {
		select(data);
		setBgColorItem(data.Id);
	};

	const classes = useStyles();

	return (
		<Box className="boxAccordion">
			<div className="itemTitle">
				<span className="name">
					<Typography>{t(Labels.GLOBAL_NAME)}</Typography>
				</span>
				<Divider component="span" orientation="vertical" />
				<span className="status">
					<Typography>{t(Labels.STATUS_LABEL)}</Typography>
				</span>
			</div>
			<div className="boxListItemAccordion">
				{data &&
					data.map((item, i) => {
						return (
							<div className="contentBoxAccordion" key={i}>
								<ContentAccordion
									bgColor
									data-id={item[keyColumn.parent][columns[keyColumn.parent].id]}
									title={item[keyColumn.parent][columns[keyColumn.parent].name]}
									titleColor={isBgColor(item[keyColumn.parent][columns[keyColumn.parent].id])}
									expanded={
										expanded === item[keyColumn.parent][columns[keyColumn.parent].id] || (data && data.length === 1)
									}
									onChange={handleChange(item[keyColumn.parent][columns[keyColumn.parent].id])}
									onClickItem={(event) => {
										onSelect(event, item[keyColumn.parent]);
									}}>
									<div className={`itemAlerts ${classes.itemAccorddion}`}>
										{item[keyColumn.children].map((_item, j) => {
											return (
												<ListItem
													key={j}
													button
													id={"id-click-button"}
													selected={isBgColorItem(_item.Id)}
													className={`itemAccordionBox ${i % 2 === 0 ? "itemBoxcolor" : ""}`}
													onClick={(event) => {
														onSelectItem(event, _item);
													}}>
													<Typography>{_item[columns[keyColumn.parent].name]}</Typography>

													<span className="itemAccordionBoxStatus">
														{_item[columns[keyColumn.parent].status] ? "Ativo" : "Inativo"}
													</span>
												</ListItem>
											);
										})}
										{_.isEmpty(item[keyColumn.children]) ? (
											<span className="itemEmpty itemAccordionBox">
												<Typography>{`Não contém ${keyColumn.children}`}</Typography>
											</span>
										) : null}
									</div>
								</ContentAccordion>
								<Divider className="dividerAccordion" component="span" orientation="vertical" />
								<Box
									component="span"
									bgcolor={
										isBgColor(item[keyColumn.parent][columns[keyColumn.parent].id])
											? "primary.main"
											: "primary.bgColorAccordion"
									}
									className={`itemStatus
										${isBgColor(item[keyColumn.parent][columns[keyColumn.parent].id]) ? "active" : ""}
									`}>
									{item[keyColumn.parent][columns[keyColumn.parent].status] ? "Ativo" : "Inativo"}
								</Box>
								<span className="arrow">
									{isBgColor(item[keyColumn.parent][columns[keyColumn.parent].id]) ? (
										<Typography color="primary" style={{ fontSize: 20 }}>
											{">"}
										</Typography>
									) : null}
								</span>
							</div>
						);
					})}
			</div>
		</Box>
	);
};

export default AccordionBox;
