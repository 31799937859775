export const HORIZONTAL = {
    id: 1,
    orientation: "horizontal",
    direction: "normal",
    classname: "horizontal" 
};
export const VERTICAL = {
    id: 2,
    orientation: "vertical",
    direction: "normal",
    classname: "vertical"
};
export const HORIZONTAL_INVERSE = {
    id: 3,
    orientation: "horizontal",
    direction: "inverse",
    classname: "horizontalInverse"
};
export const VERTICAL_INVERSE = {
    id: 4,
    orientation: "vertical",
    direction: "inverse",
    classname: "verticalInverse"
};

export default {
    HORIZONTAL,
    VERTICAL,
    HORIZONTAL_INVERSE,
    VERTICAL_INVERSE,
}