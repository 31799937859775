import React from "react";
import "./CardAlert.scss";

const AlertCard = (props) => {
    return (
        <div className={`box-alert-card ${props.fullHeight ? "full-height" : ""}`}>
            <div className={"title-box"}>
                <span>
                    <span className={"title-icon"}>{props.icon}</span>
                    {props.title}
                </span>
            </div>
            <div className={"content-box"}>{props.children}</div>
        </div>
    );
};

export const AlertCardItem = (props) => {
    return (
        <div className={"box-card-alert-item"} style={props.style}>
            {props.legend && <div className={"item-legend-box"}>{props.legend}:</div>}
            <span className={"item-content-box"}>{props.children}</span>
        </div>
    );
};

export default AlertCard;
