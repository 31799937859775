import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import fleetAction from "~/actions/fleetAction";
import distanceAction from "~/actions/reporting/distanceReportAction";
import vehicleSearchAction from "~/actions/vehicleSearchAction";

import { Container, Grid } from "@material-ui/core";

import Page from "~/components/common/page/Page";
import ReportDistance from "~/components/reporting/distance/ReportDistance";

import OptionExportButton from "~/components/common/exportButton/OptionExportButton";

const Reporting = () => {
    const dispatch = useDispatch();

    const { value } = useSelector((state) => state.reportTab);

    useEffect(() => {
        let filter = {
            type: "gps",
            functionality: "3",
        };
        dispatch(fleetAction.fleetData(filter));
        dispatch(vehicleSearchAction.vehicleData(filter));
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(distanceAction.cleanExportDocumentReport());
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Page
                topButtons={
                    value === "TAB_OBJECT" ? (
                        <div id="export-container">
                            <OptionExportButton
                                onClick={(type) => {
                                    dispatch(
                                        distanceAction.callExportDocumentReport({
                                            format: type.value.toLowerCase(),
                                        })
                                    );
                                }}
                            />
                        </div>
                    ) : null
                }>
                <Container maxWidth="lg" className="container-map-view">
                    <Grid container>
                        <Grid item xs={12}>
                            <ReportDistance />
                        </Grid>
                    </Grid>
                </Container>
            </Page>
        </>
    );
};

export default Reporting;
