import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import ReportFilter from "~/components/common/reportTab/ReportFilter";
import SpeedingObject from "~/components/reporting/speeding/perObject/SpeedingObject";
import SpeedingGridResultPeriod from "~/components/reporting/speeding/perPeriod/SpeedingGridResultPeriod";
import SpeedingScheduled from "~/components/reporting/speeding/scheduled/SpeedingScheduled";
import SpeedingFilterObject from "~/components/reporting/speeding/perObject/FiltersObject";
import SpeedingFilterPeriod from "~/components/reporting/speeding/perPeriod/SpeedingFilterPeriod";
import SpeedingFilterScheduled from "~/components/reporting/speeding/scheduled/SpeedingFilterScheduled";
import "./ReportSpeeding.scss";

const ReportSpeeding = (props) => {
    const { periodForm, objectForm, scheduleForm } = useSelector((state) => state.speedingReport);
    const { t } = useTranslation();

    return (
        <div id="report-speeding-container">
            <ReportFilter
                label={t(Labels.FILTERS)}
                onCleanObject={() => {
                    objectForm && objectForm.resetForm();
                }}
                onCleanPeriod={() => {
                    periodForm && periodForm.resetForm();
                }}
                onCleanScheduled={() => {
                    scheduleForm && scheduleForm.resetForm();
                }}
                objectFilter={<SpeedingFilterObject />}
                periodFilter={<SpeedingFilterPeriod />}
                scheduledFilter={<SpeedingFilterScheduled />}
            />
            <SpeedingObject />
            <SpeedingGridResultPeriod />
            <SpeedingScheduled />
        </div>
    );
};

export default ReportSpeeding;
