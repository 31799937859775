import React, { useState } from "react";
import { Grid, Button, Checkbox, CircularProgress, FormControlLabel, Typography } from "@material-ui/core";
import { Formik, Form } from "formik";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Utils from "~/helpers/Utils";
import authAction from "~/actions/authAction";
import recoverAction from "~/actions/recover/recoverActions";
import Constants from "~/helpers/enums/Constants";
import Labels from "~/helpers/enums/Labels";
import PrivacyPolicy from "./PrivacyPolicy";
import "./Form.scss";
import { makeStyles } from '@material-ui/core/styles';

const LoginForm = () => {
	const dispatch = useDispatch();
	const { visibleRecover } = useSelector((state) => state.recoverLogin);
	const { loading } = useSelector((state) => state.app);
	const { t } = useTranslation();

	const useStyles = makeStyles((theme) => ({
		customTextField: {
		  '& label.Mui-focused': {
			color: '#243782', 
		  },
		  '& .MuiInput-underline:after': {
			borderBottomColor: '#243782', 
		  },
		  '& .MuiOutlinedInput-root': {
			'&.Mui-focused fieldset': {
			  borderColor: '#243782',
			},
		  },
		},
	  }));
	  
	const classes = useStyles();

	const [state, setState] = useState({ checked: true });
	const handleChangeCheck = (event) => {
		if (event.target.checked === false) {
			localStorage.removeItem("username");
		}
		setState({ ...state, [event.target.name]: event.target.checked });
	};

	const toRecoverForm = () => {
		dispatch(recoverAction.visibleFormRecover(!visibleRecover));
	};

	return (
		<Formik
			initialValues={{
				username: localStorage.getItem("username") || "",
				password: "",
			}}
			validate={(values) => {
				const errors = {};

				if (!values.username) {
					errors.username = t(Labels.LOGIN_USER_REQUIRED);
				}

				if (!values.password) {
					errors.password = t(Labels.LOGIN_PASSWORD_REQUIRED);
				}

				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(false);

				dispatch(
					authAction.sendCredentials(
						values,
						(error) => {
							if (error === undefined) {
								if (state.checked) {
									localStorage.setItem("username", values.username);
								}
							}
							if (!error) return;
							Utils.showTranslatedToast({
								type: Constants.ERROR,
								description: Labels.LOGIN_ACCESS_DENIED,
							});
						},
						"login"
					)
				);
			}}>
			{(form) => {
				return (
					<Form>
						<Grid container className="login-form login-tab-container" spacing={3}>
							<Grid item xs={12}>
								<Field
									name="username"
									id="username"
									label={t(Labels.LOGIN_USER)}
									component={TextField}
									type="text"
									fullWidth
									margin="dense"
									className={classes.customTextField}  
									inputProps={{ maxLength: 150 }}
									onKeyPress={(ev) => {
										if (ev.key === "Enter") {
											form.submitForm();
										}
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Field
									name="password"
									id="password"
									label={t(Labels.LOGIN_PASSWORD)}
									component={TextField}
									type="password"
									fullWidth
									className={classes.customTextField}  
									margin="dense"
									inputProps={{ maxLength: 150 }}
									onKeyPress={(ev) => {
										if (ev.key === "Enter") {
											form.submitForm();
										}
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormControlLabel
									control={
										<Checkbox checked={state.checked} onChange={handleChangeCheck} name="checked" color="default" />
									}
									label={t(Labels.LOGIN_REMEMBER)}
								/>
							</Grid>

							<Grid item xs={6} style={{ display: "flex" }}>
								<Typography
									className="forgot"
									component="span"
									style={{ alignSelf: "center", cursor: "pointer" }}
									onClick={() => {
										toRecoverForm();
									}}>
									{t(Labels.LOGIN_FORGORT_PASSWORD)}
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<Button
									style={{
										padding: "4.5px 0px",
										minWidth: 370,
										backgroundColor: '#243782',
										color: 'white', 
									}}
									className="button-login"
									id="login-submit-button"
									variant="contained"
									color="inherit"
									onClick={form.submitForm}
									size="small"
									fullWidth
									disabled={loading.login}
									startIcon={
										loading.login ? <CircularProgress style={{ height: 14, width: 14, marginRight: 8 }} /> : null
									}
									disableElevation>
									{t(Labels.LOGIN_SUBMIT)}
								</Button>
							</Grid>

              <Grid item xs={12}>
			  <div style={{ fontSize: '12px', marginBottom: '20px' }}>             
                                Suporte My Uconnect Fleet Gestor de Frotas - 0800 701 8010 - opção 4
            </div>
                <PrivacyPolicy />
							</Grid>
						</Grid>
					</Form>
				);
			}}
		</Formik>
	);
};

export default LoginForm;
