const EVENTS_OBJECT_COLUMNS = [
	{
		name: "Placa",
		label: "Placa/Identificação",
		checked: true,
	},
	{
		name: "MotoristaNome",
		label: "Motorista",
		checked: true,
	},

	{
		name: "DataInicio",
		label: "Início",
		checked: true
	},
	{
		name: "DataFim",
		label: "Fim",
		checked: true
	},
	{
		name: "LocalizacaoInicial",
		label: "Localização inicial",
		checked: true,
	},
	{
		name: "LocalizacaoFinal",
		label: "Localização final",
		checked: true,
	},
	{
		name: "TempoViagemStr",
		label: "Tempo da viagem",
		checked: true,
	},
	{
		name: "Distancia",
		label: "Percorrido (KM)",
		checked: true,
	},
	{
		name: "VelocidadeMedia",
		label: "Velocidade média",
		checked: true,
	},
	{
		name: "VelocidadeMaxima",
		label: "Velocidade máxima",
		checked: true,
	},
	{
		name: "MarcaModelo",
		label: "Marca/Modelo",
		checked: true,
	},
	{
		name: "ProprietarioNome",
		label: "Proprietário",
		checked: true,
	},
];

export { EVENTS_OBJECT_COLUMNS };
