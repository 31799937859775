import React from "react";
import { useSelector } from "react-redux";
import GridAlertViolated from "~/components/alerts/grid/GridAlertViolated";
import AlertDetails from "~/components/alerts/contentSide/AlertDetails";
import Card from "~/components/common/expansionCard/Card";
import MapCard from "~/components/map/MapCard";
import Loading from "~/components/common/loading/Loading";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import { Grid, Box } from "@material-ui/core";
import "./AlertMain.scss";

const AlertPanelMain = (props) => {
    const { loading } = useSelector((state) => state.app);
    const { t } = useTranslation();

    return (
        <Grid container spacing={0}>
            <Grid item xs={4}>
                <Card
                    className="cardGridAlerts card-refactor"
                    notExpanded
                    title={t(Labels.TITLE_ALERT_GRID)}
                    style={{
                        minHeight: "80vh",
                    }}>
                   <GridAlertViolated />
                </Card>
            </Grid>
            <Grid item xs={8}>
                <Box className="alert-container" spacing={0}>
                    {(loading.getAlertDetails || loading.markBoardDoneAlertsViolated) && (
                        <div className="loadingAlert">
                            <Loading />
                        </div>
                    )}
                    <Card className="displayAlerts card-refactor" title={"Visualizar alerta"} coloredTitleBox={true}>
                        <AlertDetails />
                    </Card>
                    {/* <Card notPadded className={"alert-map card-refactor"} title="Mapa" coloredTitleBox={true}>
                        <MapCard className="map-card-refactor" />
                    </Card> */}
                </Box>
            </Grid>
        </Grid>
    );
};

export default AlertPanelMain;
