import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import distanceReportAction from "~/actions/reporting/distanceReportAction";

import { Container, Grid } from "@material-ui/core";

import Page from "~/components/common/page/Page";
import ReportEvents from "~/components/reporting/events/ReportEvents";

import fleetAction from "~/actions/fleetAction";
import vehicleSearchAction from "~/actions/vehicleSearchAction";
import eventReportAction from "~/actions/reporting/eventReportAction";
import OptionExportButton from "~/components/common/exportButton/OptionExportButton";

import _ from "lodash";

const Reporting = () => {
    const dispatch = useDispatch();

    const { perObject } = useSelector((state) => state.eventReport);
    const { value } = useSelector((state) => state.reportTab);
    const { loading } = useSelector((state) => state.app);

    useEffect(() => {
        let filter = {
            type: "gps",
            functionality: "3",
        };
        dispatch(fleetAction.fleetData(filter));
        dispatch(vehicleSearchAction.vehicleData(filter));
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(distanceReportAction.cleanExportDocumentReport());
        };
    }, [dispatch]);

    return (
        <>
            <Page
                topButtons={
                    value === "TAB_OBJECT" ? (
                        <div id="export-container">
                            <OptionExportButton
                                loading={loading.requestObjectDoc}
                                onClick={(type) => {
                                    if (_.isEmpty(perObject.filters.period)) {
                                        return;
                                    }

                                    const format = type.value.toLowerCase();
                                    dispatch(
                                        eventReportAction.requestPerObject(
                                            {
                                                ...perObject.filters,
                                                format: format === "xlsx" ? "xls" : format,
                                            },
                                            () => {},
                                            "requestObjectDoc"
                                        )
                                    );
                                }}
                            />
                        </div>
                    ) : null
                }>
                <Container maxWidth="lg" className="container-map-view">
                    <Grid container>
                        <Grid item xs={12}>
                            <ReportEvents />
                        </Grid>
                    </Grid>
                </Container>
            </Page>
        </>
    );
};

export default Reporting;
