import routeService from "~/services/routeService";
import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";

export const listRoutes = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "gridRoutes") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return routeService
		.listRoutes(filter)
		.then(({ data }) => {
			dispatch({ type: Constants.LIST_ROUTES, payload: data });
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

export const getObjectsPerRoutes = (id, filter, callback = () => {}, LOADING_IDENTIFICATOR = "route") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return routeService
		.getObjectsPerRoutes(filter)
		.then(({ data }) => {
			dispatch({
				type: Constants.GET_OBJECTS_ROUTES,
				payload: {
					id: id,
					data: data,
				},
			});
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

export const deleteRoute = (id, callback = () => {}, LOADING_IDENTIFICATOR = "route") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return routeService
		.deleteRoute(id)
		.then(() => {
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

export const saveRoute = (route, callback = () => {}, LOADING_IDENTIFICATOR = "route") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return routeService
		.saveRoute(route)
		.then(() => {
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

export const getRoutesById = (id, callback = () => {}, LOADING_IDENTIFICATOR = "route") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return routeService
		.getRoutesById(id)
		.then(({ data }) => {
			dispatch({ type: Constants.GET_ROUTES_BY_ID, payload: data });
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

export const exportRoutes = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "exportRoute") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return routeService
		.exportRoutes(filter)
		.then(({ data }) => {
			if (data.Success) {
				Utils.downloadToLink(data.Message);
			} else {
				Utils.showError(data.Message);
				callback(data.Message);
			}
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

export const cleanRoute = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_ROUTE });
};

export const cleanRoutes = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_ROUTES });
};

export const cleanObjectRoutes = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_OBJECTS_ROUTES });
};

export const deleteObjectRoutes = (id) => (dispatch) => {
	dispatch({ type: Constants.CLEAN_OBJECTS_ROUTES, payload: id });
};

export const searchName = (arr, searchField) => (dispatch) => {
	const filteredData = Utils.filterData(arr, searchField);
	dispatch({ type: Constants.SEARCH_ROUTE_NAME, payload: { filteredData, searchField }})
}

export const cleanSearch = () => (dispatch) => {
	dispatch({type: Constants.CLEAN_ROUTE_SEARCH});
}

export default {
	listRoutes,
	getObjectsPerRoutes,
	deleteRoute,
	saveRoute,
	getRoutesById,
	exportRoutes,
	cleanRoute,
	cleanRoutes,
	cleanObjectRoutes,
	deleteObjectRoutes,
	searchName,
	cleanSearch
};
