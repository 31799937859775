import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";

import importDriverService from "~/services/driver/importDriverService";

const importFile = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "importDriver") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return importDriverService
		.importFileDriver(filter)
		.then((response) => {
			let data = response.data;
			let payload = {
				data: data,
			};
			dispatch({ type: Constants.IMPORT_FILE_DRIVER, payload });
			callback(data);
		})
		.catch(({ response: { data } }) => {
			callback(data.Message);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const listGroupDriver = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "listGroupDriver") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return importDriverService
		.listGroupDriver(filter)
		.then((response) => {
			let data = response.data;
			let payload = {
				data: data,
			};
			dispatch({ type: Constants.LIST_GROUP_DRIVER, payload });
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

export default {
	importFile,
	listGroupDriver,
};
