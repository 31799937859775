import React, { useEffect, useState } from "react";

import Utils from '~/helpers/Utils'

import _ from 'lodash'

import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";

import { useSelector, useDispatch } from 'react-redux';

import alertsActions from '~/actions/alertsActions';

import { ClickAwayListener, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

import SubMenu from '~/components/app/menu/subMenu/SubMenu';

import routes from "~/config/routes/routes";

import "./Menu.scss";

const Menu = (props) => {
  const [subMenuActive, setSubMenu] = useState(false);
  const [subMenuData, setSubMenuData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const { canRenew } = useSelector((state) => state.renewalReducer);
  const [showRenew, setshowRenew] = useState(false);
  const dispatch = useDispatch();

  const { alerts} = useSelector((state) => state.alertsViolated);
  useEffect(() => {
    dispatch(alertsActions.getAlertsViolated())
  }, [dispatch]);
  useEffect(() => {
    if(canRenew){
      setshowRenew(canRenew)
    }
  }, [canRenew]);

  const { t } = useTranslation();

  const actualRoute = props.location.pathname;

  function isActualRoute(route, isSubMenu, routeSubMenu) {
    if (route === actualRoute) {
      return "active";
    }
    if (isSubMenu && actualRoute?.includes(routeSubMenu)) {
      return "active";
    }
    return undefined;
  }

  function activeSubMenu(hasSubMenu, title, subRoutes) {
    if (hasSubMenu) {
      setSubMenu(true) // !subMenuActive
      setSubMenuData({
        title: title,
        subPaths: subRoutes
      })
    } else {
      setSubMenu(false)
    }
  }

  const verifyIdMenu = (_id, menu) =>{
    let p = '';
    if(!menu){
      p = permissions.filter(f => parseInt(f) === _id)
      if(_.isEmpty(p)){
        return "notPermission"
      }
    }
    return;
  }

  const verifyRenew = (_id) =>{
    if (_id === 1020 && !canRenew) {
      return "notPermission";
    }
    return 
  }

  useEffect(()=>{
    let p = Utils.getPermissions()
    setPermissions(p)
  },[])

  return (
    <ClickAwayListener onClickAway={() => { setSubMenu(false) }}>
      <div className="ceabs-menu-list">
        {
          subMenuActive &&
          <SubMenu
            checked={subMenuActive}
            data={subMenuData}
            fnClick={setSubMenu}
            permission={permissions}
          />
        }
        <List>
          {routes
            .filter((r) => r.showOnMenu)
            .map((route, index) => (
              <ListItem
                id={t(route.menu())}
                key={index}
                button
                onClick={() => {
                  if(route.subMenu){
                    activeSubMenu(route.subMenu, t(route.menu()), route.subRoutes);
                    return;
                  }
                  props.history.push(route.path);
                }}
                className={`
                  menuItem
                  ${isActualRoute(route.path, route.subMenu, route.pathMenu)}
                  ${verifyIdMenu(route.id, route.subMenu)}
                  ${verifyRenew(route.id)}
                `}>
                <ListItemIcon style={{ minWidth: "auto" }}>
                  <img src={route.icon} style={{ height: "auto", width: 32 }} alt={t(route.menu())} />
                  {route.notify && alerts.length > 0 ? (<span className="dot"></span>):(null)}
                </ListItemIcon>
                <ListItemText primary={t(route.menu())} />
              </ListItem>
            ))}
        </List>
      </div>
    </ClickAwayListener>
  );
};

export default withRouter(Menu);
