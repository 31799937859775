import { Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import "./expansionCard.scss";

const useStyles = makeStyles((theme) => ({
    boxColor: {
        backgroundColor: (props) => (props.coloredTitleBox ? `${theme.palette.primary.main}2a` : "transparent"),
    },
    titleButtons: {
        display: "flex",
    },
}));

const Card = (props) => {
    const styleProps = { coloredTitleBox: !!props.coloredTitleBox };
    const classes = useStyles(styleProps);

    return (
        <div className={`card ${props.className} ${props.notPadded ? "notPadded" : ""}`} style={props.style}>
            {props.topButtons && <div className="topButtons">{props.topButtons}</div>}

            {props.title && (
                <>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        className={`boxTitle  ${props.classTitle} ${classes.boxColor}`}>
                        <Grid item className={"itemTitle"}>
                            {props.title}
                        </Grid>
                        <Grid>
                            <Box ref={props.refTitleDiv} />
                        </Grid>
                        <Grid item className={`titleBtn  titleButtons ${classes.titleButtons}`}>
                            {props.titleButtons}
                        </Grid>
                    </Grid>
                    <span className={"card-title-divider"} />
                </>
            )}

            <div className="children">{props.children}</div>
        </div>
    );
};

export default Card;
