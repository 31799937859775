import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import List from "@material-ui/core/List";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { lighten } from "polished";
import ArrowSortOrder from "~/components/common/arrowSortOrder/ArrowSortOrder";

import CardItem from "./CardItem";

import Utils from "~/helpers/Utils";

import "./Card.scss";

const useStyles = makeStyles((theme) => ({
	root: {
		"&:hover .cardItemValue": {
			background: lighten(0.3, theme.palette.primary.main),
			cursor: "pointer",
		},
	},
}));

function CardData(props) {
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			props.cleanAction && dispatch(props.cleanAction());
		};
	}, [dispatch, props]);

	function onClick() {
		props.onItemClick && props.onItemClick(props.data);
	}

	const classes = useStyles();

	return (
		<List id={props.id} className={`${classes.root} ${props.className}`} onClick={onClick}>
			{props.columns
				?.filter((col) => col?.checked)
				.map((col, i) => (
					<div id={props.id + "-card"} data-id={props.id + "-card"} className="borderMain" key={i}>
						<CardItem id={props.id + " card-item"} key={i}>
							<div
								id={props.id + "-card-item-column"}
								data-id={props.id + "-card-item-column"}
								className={`${"cardItem"} ${"cardItemColumn"}`}
								style={{ paddingRight: "5px", cursor: "pointer" }}
								onClick={() => {
									props.onHeaderClick && props.onHeaderClick(col.label);
								}}
								title={col.label}>
								<ArrowSortOrder show={col.label === props.selectedLabel} order={props.order} />
								<Typography
									id={props.id + "-card-item-column-font"}
									data-id={props.id + " card-item-column-font"}
									noWrap
									style={{ fontSize: "14px", textOverflow: "ellipsis" }}>
									{String(col.label).includes("ora do evento")
										? Utils.TextView("Data/Hora", 12)
										: Utils.TextView(col.label, 18)}
								</Typography>
							</div>
							<div
								id={props.id + "-card-item-value"}
								data-id={props.id + "-card-item-value"}
								className={`${"cardItem"} ${"cardItemValue"}`}>
								{col.name === "Ignicao" ? (
									<img
										src={Utils.iconResolver(props.data["Tipo"], props.data[col.name])}
										alt={props.data["Tipo"]}
										height="28"
										width="28"
									/>
								) : (
									<Typography
										id={props.id + "-card-item-value-font"}
										data-id={props.id + "-card-item-value-font"}
										style={{ fontSize: "14px", paddingTop: "4px", textOverflow: "ellipsis" }}
										title={props.data[col.name] ? props.data[col.name] : " - "}>
										{String(col.name).includes("Distancia") || String(col.name).includes("Km")
											? Utils.TextView(props.data[col.name] + " km", 50)
											: Utils.TextView(props.data[col.name], 50)}
									</Typography>
								)}
							</div>
						</CardItem>
						<div className="borderVehicle" />
					</div>
				))}
		</List>
	);
}

export default CardData;
