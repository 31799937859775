import Constants from "~/helpers/enums/Constants";

const initialState = {
	filter: {
		id: null,
	},
	data: [],
};

function vehicleReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.FLEET_GET_VEHICLE_DATA:
			return {
				...state,
				filter: action.payload.filter,
				data: action.payload.data,
			};
		default:
			return state;
	}
}

export default vehicleReducer;
