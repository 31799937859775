import React, { useEffect, useState, useRef, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

import dashboardDatavehicleAction from "~/actions/dashboardVehicle/dashboardDataVehicleActions";
import dashboardService from "~/services/dashboard/dashboardService";
import dashboardFiltersAction from "~/actions/dashboardVehicle/dashboardFiltersAction";
import { Box, Typography } from "@material-ui/core";

import Card from "~/components/common/expansionCard/Card";
import ViewItemData from "~/components/common/viewData/ViewItemData";
import LoadingPanel from "~/components/common/loading/LoadingPanel";
import PrivateMode from "./PrivateMode";
import Utils from "~/helpers/Utils";

import "./DataVhicle.scss";

const DataVehicle = ({ vehicle }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [load, setLoad] = useState(true);
    const { infos } = useSelector((state) => state.auth);
    const { data } = useSelector((state) => state.dashboardDataVehicle);
    const { enableVehicleDashboard } = useSelector((state) => state.dashboardVehicleToolbar);
    const { loading } = useSelector((state) => state.app);

    const prevItemIdRef = useRef();

    useEffect(() => {
        prevItemIdRef.current = vehicle.ObjetoRastreavelId;
    });

    const fetchDataVehicle = useCallback(() => {
        setLoad(true);

        const query = {
            id: vehicle?.ObjetoRastreavelId,
        };

        async function fetchData() {
            if (prevItemIdRef !== vehicle.ObjetoRastreavelId) {
                let isCan = await verifyData(vehicle?.ObjetoRastreavelId);
                if (!isCan) {
                    dispatch(dashboardDatavehicleAction.updateDataVehicle());
                    if (enableVehicleDashboard) {
                        dispatch(dashboardFiltersAction.hideAllFilters());
                    }
                    return;
                }
                if (!enableVehicleDashboard) {
                    dispatch(dashboardFiltersAction.showCurrentFilters());
                }
                dispatch(dashboardDatavehicleAction.getDataVehicle({ ...query }));
            }
        }

        fetchData().finally(() => {
            setLoad(false);
        });
    }, [dispatch, vehicle, enableVehicleDashboard]);

    useEffect(() => {
        fetchDataVehicle();
    }, [fetchDataVehicle]);

    const verifyData = async (id) => {
        const resp = await dashboardService.verifyDataVehicles({ id: id });
        return resp.data;
    };

    const modePrivate = Utils.getPermissions().indexOf("1020") !== -1
    const isFCA = data.NumeroSerie === data.Chassi 
    
    return (
        <Card title={t(Labels.DASHBOARD_VEHICLE_DATA_VEHICLE_TITLE)} className={"dataVhicleStyle card-refactor"}>
            {(loading.getDataVehicle || load) && <LoadingPanel size={30} />}
            <Box className="boxData">
                {_.isEmpty(data) ? (
                    <Typography style={{ fontSize: 14, color: "#AFC3D2" }}>
                        {enableVehicleDashboard
                            ? "Nenhum registro encontrado."
                            : "Seu usuário não possui acesso a essa página, caso tenho alguma dúvida a equipe CEABS está a disposição."}
                    </Typography>
                ) : (
                    <>
                        <ViewItemData
                            data={data}
                            column={{
                                name: "Identificacao",
                                label: t(Labels.DASHBOARD_VEHICLE_DATA_VEHICLE_PLATE),
                            }}
                            render={(plate) => (
                                <Typography
                                    style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                    }}>
                                    {plate}
                                </Typography>
                            )}
                        />
                        <ViewItemData
                            data={data}
                            column={{
                                name: "Marca",
                                label: t(Labels.DASHBOARD_VEHICLE_DATA_VEHICLE_PRODUCER),
                            }}
                        />
                        <ViewItemData
                            data={data}
                            column={{
                                name: "Modelo",
                                label: t(Labels.DASHBOARD_VEHICLE_DATA_VEHICLE_MODEL),
                            }}
                        />
                        <ViewItemData
                            data={data}
                            column={{
                                name: "Chassi",
                                label: t(Labels.DASHBOARD_VEHICLE_DATA_VEHICLE_CHASSI),
                            }}
                        />
                    </>
                )}
            </Box>
            {(data.Chassi && isFCA && (modePrivate || infos.Master)) && <PrivateMode vehicle={data} />}
        </Card>
    );
};

export default DataVehicle;
