//!transparent linear-gradient(224deg, #8E63EF 0%, #DEAAFF 100%)
import Labels from "~/helpers/enums/Labels";
import { t } from "~/config/language/i18n";

export const MadrugadaColorSolid = "#6EC8AF";
export const MadrugadaColors = {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
        {
            offset: 0,
            color: MadrugadaColorSolid, // color at 0% position
        },
        {
            offset: 1,
            color: "#6EC8AF", // color at 100% position
        },
    ],
    global: true,
};

//! transparent linear-gradient(209deg, #FFDA5D 0%, #FF9464 100%)
export const ManhaColorSolid = "#FFDA5D";
export const ManhaColors = {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
        {
            offset: 0,
            color: ManhaColorSolid, // color at 0% position
        },
        {
            offset: 1,
            color: "#FFDA5D", // color at 100% position
        },
    ],
    global: false,
};

//! transparent linear-gradient(330deg, #FC5C75 0%, #FF7E7E 100%)
export const TardeColorSolid = "#FC5C75";
export const TardeColors = {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
        {
            offset: 0,
            color: TardeColorSolid, // color at 0% position
        },
        {
            offset: 1,
            color: "#FC5C75", // color at 100% position
        },
    ],
    global: false,
};

//! transparent linear-gradient(302deg, #6EC8AF 0%, #6EC8B1 46%, #64C8FF 100%)
export const NoiteColorSolid = "#243782";
export const NoiteColors = {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
        {
            offset: 0,
            color: NoiteColorSolid, // color at 0% position
        },
        {
            offset: 0.46,
            color: "#243782", // color at 0% position
        },
        {
            offset: 1,
            color: "#243782", // color at 100% position
        },
    ],
    global: false,
};

export const Colors = (Shift) => {
    const SwitchColor = {
        TempoMadrugadaPorcentagem: MadrugadaColors,
        TempoManhaPorcentagem: ManhaColors,
        TempoTardePorcentagem: TardeColors,
        TempoNoitePorcentagem: NoiteColors,
        default: "#FFF",
    };

    return SwitchColor[Shift] || SwitchColor.default;
};

export const ColorSolid = (Shift) => {
    const SwitchColor = {
        TempoMadrugadaPorcentagem: MadrugadaColorSolid,
        TempoManhaPorcentagem: ManhaColorSolid,
        TempoTardePorcentagem: TardeColorSolid,
        TempoNoitePorcentagem: NoiteColorSolid,
        default: "#FFF",
    };

    return SwitchColor[Shift] || SwitchColor.default;
};

export const getFullValue = (Shift, objectItens) => {
    const SwitchValue = {
        TempoMadrugadaPorcentagem: objectItens["TempoMadrugada"],
        TempoManhaPorcentagem: objectItens["TempoManha"],
        TempoTardePorcentagem: objectItens["TempoTarde"],
        TempoNoitePorcentagem: objectItens["TempoNoite"],
        default: 0,
    };
    return (SwitchValue[Shift] || SwitchValue.default);
};

export const getName = (value) => {
    const SwitchValue = {
        TempoMadrugadaPorcentagem: t(Labels.DASHBOARD_PERIOD_TRIPS_CHARTS_DAYBREAK),
        TempoManhaPorcentagem: t(Labels.DASHBOARD_PERIOD_TRIPS_CHARTS_MORNING),
        TempoTardePorcentagem: t(Labels.DASHBOARD_PERIOD_TRIPS_CHARTS_AFTERNOON),
        TempoNoitePorcentagem: t(Labels.DASHBOARD_PERIOD_TRIPS_CHARTS_NIGHT),
        default: "",
    };
    return (SwitchValue[value] || SwitchValue.default).toLocaleString();
};

export default {
    MadrugadaColorSolid,
    MadrugadaColors,
    ManhaColorSolid,
    ManhaColors,
    TardeColorSolid,
    TardeColors,
    NoiteColorSolid,
    NoiteColors,
    Colors,
    ColorSolid,
    getFullValue,
    getName,
};
