import React from "react";
import { withRouter } from "react-router";
import { Container } from "@material-ui/core";
import Page from "~/components/common/page/Page";
import VehicleMain from "~/components/vehicle/VehicleMain";

import exportDocumentAction from "~/actions/exportDocumentAction";
import { useDispatch, useSelector } from "react-redux";
import OptionExportButton from "~/components/common/exportButton/OptionExportButton";

const VehicleView = (props) => {
    const dispatch = useDispatch();
    const { vehicleFilter } = useSelector((state) => state.vehicle);

    return (
        <Page
            topButtons={
                <div id="export-container">
                    <OptionExportButton
                        onClick={(type) => {
                            dispatch(
                                exportDocumentAction.extractDocumentVehicles({
                                    format: type.value.toLowerCase(),
                                    search: vehicleFilter.search,
                                })
                            );
                        }}
                    />
                </div>
            }>
            <main>
                <Container maxWidth="lg" className="container-map-view">
                    <VehicleMain />
                </Container>
            </main>
        </Page>
    );
};

export default withRouter(VehicleView);
