import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import Constants from "~/helpers/enums/Constants";
import "./ReportComponents.scss";

const ReportPanel = ({ typeTab, children, ...other }) => {
    const { value } = useSelector((state) => state.reportTab);

    const isHidden = value !== typeTab;
    return (
        <Box hidden={isHidden}>
            <Box {...other}>{children}</Box>
        </Box>
    );
};

const ObjectPanel = (props) => <ReportPanel typeTab={Constants.TAB_OBJECT} {...props} />;
const PeriodPanel = (props) => <ReportPanel typeTab={Constants.TAB_PERIOD} {...props} />;
const ScheduledPanel = (props) => <ReportPanel typeTab={Constants.TAB_SCHEDULED} {...props} />;

export default ReportPanel;
export { ObjectPanel, PeriodPanel, ScheduledPanel };
