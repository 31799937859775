import Constants from "~/helpers/enums/Constants";
import ReportTypeEnum from "~/helpers/enums/ReportTypeEnum";
import reportingService from "~/services/reportingService";
import Utils from "~/helpers/Utils";

const TRAVELS_REPORT_ID = ReportTypeEnum.TRAVELS.id;

const getTravelsReportScheduling = (params) => (dispatch) => {
    return reportingService
        .getReportSchedulingByType(params, TRAVELS_REPORT_ID)
        .then((response) => {
            const { data } = response.data;
            dispatch({ type: Constants.GET_TRAVELS_REPORT_SCHEDULING, payload: { data } });
        })
        .catch((error) => {
            console.log(error);
        });
};

const getTravelsReportSent = (params) => (dispatch) => {
    return reportingService
        .getReportSentByType(params, TRAVELS_REPORT_ID)
        .then((response) => {
            const { index, recordsTotal, recordsFiltered, data } = response;
            //TODO Apply pagination
            const pagination = {
                index,
                recordsTotal,
                recordsFiltered,
            };
            dispatch({ type: Constants.GET_TRAVELS_REPORT_SENT, payload: { data: data.data, pagination } });
        })
        .catch((error) => {
            console.log(error);
        });
};

const getTravelsReportSchedulingById = (id) => (dispatch) => {
    return reportingService
        .getDistanceReportSchedulingById(id)
        .then((response) => {
            const { data } = response;
            dispatch({ type: Constants.EDIT_TRAVELS_REPORT_SCHEDULING, payload: data.data });
        })
        .catch((error) => {
            console.log(error);
        });
};

const saveTravelsReportScheduling = (report, callback = () => {}) => (dispatch) => {
    return reportingService
        .saveDistanceReportScheduling(report, TRAVELS_REPORT_ID)
        .then((response) => {
            callback(false, response);
        })
        .catch((error) => {
            callback(error)
            console.log(error);
        });
};

const finishEditionTravelsReportScheduling = () => (dispatch) => {
    dispatch({ type: Constants.EDIT_TRAVELS_REPORT_SCHEDULING, payload: {} });
};

const requestPerObject = (params, callback = () => {}, LOADING_IDENTIFICATOR = "requestObject") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return reportingService
        .requestReportingPerObject(params)
        .then(({ data }) => {
            callback();

            if (data.data) {
                dispatch({ type: Constants.REQUEST_TRAVELS_PER_OBJECT, payload: data });
            } else if (data.Message) {
                if (!data.Success) {
                    Utils.showError(data.Message);
                    return;
                }
                Utils.downloadToLink(data.Message);
            }
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const updateFilterTravelObject = (filter) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FILTER_TRAVELS_OBJECT, payload: { filter } });
};

const updateFilterTravelPeriod = (filter) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FILTER_TRAVELS_PERIOD, payload: { filter } });
};

const updatePeriodForm = (form) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FORM_TRAVELS_PERIOD, payload: { form } });
};

const updateObjectForm = (form) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FORM_TRAVELS_OBJECT, payload: { form } });
};

const updateScheduleForm = (form) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FORM_TRAVELS_SCHEDULED, payload: { form } });
};

const updateFilterTravelScheduled = (filter) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FILTER_TRAVELS_SCHEDULED, payload: { filter } });
};

const cleanFilterTravelObject = () => (dispatch) => {
    dispatch({ type: Constants.CLEAN_FILTER_TRAVELS_OBJECT });
};

const cleanFilterTravelPeriod = () => (dispatch) => {
    dispatch({ type: Constants.CLEAN_FILTER_TRAVELS_PERIOD });
};

const cleanFilterTravelScheduled = () => (dispatch) => {
    dispatch({ type: Constants.CLEAN_FILTER_TRAVELS_SCHEDULED });
};
export default {
    getTravelsReportScheduling,
    getTravelsReportSent,
    getTravelsReportSchedulingById,
    saveTravelsReportScheduling,
    finishEditionTravelsReportScheduling,
    updateFilterTravelObject,
    updateFilterTravelPeriod,
    updatePeriodForm,
    updateObjectForm,
    updateScheduleForm,
    updateFilterTravelScheduled,
    cleanFilterTravelObject,
    cleanFilterTravelPeriod,
    cleanFilterTravelScheduled,
    requestPerObject,
};
