import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import { Form, Formik } from "formik";
import { SaveReportIcon } from "~/components/common/icons/Icons";
import Utils from "~/helpers/Utils";
import AutoCompleteFleet from "~/components/reporting/common/fleet/AutoCompleteFleet";
import VehicleAutocomplete from "~/components/reporting/common/vehicle/VehicleAutocomplete";
import ReportPeriodField from "~/components/reporting/common/ReportPeriodField";
import Input from "~/components/reporting/common/input/Input";

const PeriodFilter = ({ loading, onFormRefUpdate, onSubmit }) => {
    const { t } = useTranslation();
    const periodForm = useRef();

    useEffect(() => {
        onFormRefUpdate && onFormRefUpdate(periodForm);
    }, [onFormRefUpdate, periodForm]);

    return (
        <Formik
            innerRef={periodForm}
            initialValues={{
                vehicle: "",
                fleet: "",
                speed: 0,
                period: Utils.getTodayPeriod(),
                email: "",
            }}
            validate={(values) => {
                const errors = {};

                if (!values.vehicle && !values.fleet) {
                    errors.vehicle = t(Labels.REPORT_EVENT_VEHICLE_MSG_OBLIGATED);
                    errors.fleet = t(Labels.REPORT_EVENT_FLEET_MSG_OBLIGATED);
                }

                if (!values.period) {
                    errors.period = t(Labels.REPORT_EVENT_PERIOD_MSG_OBLIGATED);
                }

                if (!values.email) {
                    errors.email = t(Labels.REPORT_EVENT_EMAIL_MSG_OBLIGATED);
                } else if (!Utils.isValidEmail(values.email)) {
                    errors.email = t(Labels.REPORT_EVENT_EMAIL_MSG_NOT_VALID);
                }

                return errors;
            }}
            onSubmit={onSubmit}
            render={({ submitForm, setFieldValue }) => {
                return (
                    <Form>
                        <Grid container justify="right" alignItems="flex-end" spacing={1}>
                            <Grid item xs={3}>
                                <VehicleAutocomplete
                                    name="vehicle"
                                    label={t(Labels.REPORT_EVENT_VEHICLE_LABEL)}
                                    placeholder={t(Labels.REPORT_EVENT_VEHICLE_PLACEHOLDER)}
                                    onChange={() => {
                                        setFieldValue("fleet", "");
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <AutoCompleteFleet
                                    name="fleet"
                                    label={t(Labels.REPORT_LABEL_FLEET)}
                                    placeholder={t(Labels.REPORT_LABEL_PLACEHOLDER)}
                                    onChange={() => {
                                        setFieldValue("vehicle", "");
                                    }}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <ReportPeriodField label={t(Labels.REPORT_EVENT_PERIOD_LABEL)} name="period" hours />
                            </Grid>

                            <Grid item xs={2}>
                                <Input
                                    name="email"
                                    placeholder={t(Labels.REPORT_EVENT_EMAIL_PLACEHOLDER)}
                                    label={t(Labels.REPORT_EVENT_EMAIL_LABEL)}
                                />
                            </Grid>

                            <Grid item xs={2}>
                                <Button
                                    id="speeding-filter-period-save-button"
                                    className="report-save-button report-save-button-refactor"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    disabled={loading}
                                    startIcon={
                                        loading ? (
                                            <CircularProgress
                                                style={{ height: 14, width: 14, marginRight: 8 }}
                                                color={"#fff"}
                                            />
                                        ) : (
                                            <SaveReportIcon />
                                        )
                                    }
                                    onClick={submitForm}>
                                    {t(Labels.REPORT_EVENT_SAVE_BUTTON)}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        />
    );
};

export default PeriodFilter;
