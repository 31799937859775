import http from "~/config/httpRequest/http";

const list = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Pesquisa/BuscarMotorista`, {
				params: {
					identificador: "",
					Descricao: filter?.description,
					Quantidade: filter?.amount,
					ObjetoRastreavelId: "",
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};
// Index: filter.index,
// 					Length: filter.limit,
// 					Search: filter.term,
const searchDriverList = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/Motoristaapi/PesquisarMotoristas`, {
				Status: filter?.status,
				GridRequest: {
					Index: filter?.Index,
					Length: filter?.Length,
					Search: filter?.Search,
					SearchColumns: [
						{
							Data: "Descricao",
							Name: "Descricao",
							OrderTable: true,
						},
						{
							Data: "VencimentoCarteiraToShow",
							Name: "VencimentoCarteira",
							OrderTable: true,
						},
					],
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const saveDriver = (driver) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/Motoristaapi/SalvarMotorista`, {
				...driver,
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const getDriverByCpf = (cpf) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Motoristaapi/ConsultarMotoristaExistente`, {
				params: {
					cpf: cpf,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const getDriverById = (id) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Motoristaapi/ObterMotoristaPorId`, {
				params: {
					id: id,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

export default { list, saveDriver, getDriverByCpf, getDriverById, searchDriverList };
