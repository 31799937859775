import Constants from "~/helpers/enums/Constants";

const initialState = {
	actionFunction: null,
	params: null,
};

function exportDocumentReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.CHANGE_EXPORT_FUNCTION:
			return {
				...state,
				actionFunction: action.payload.actionFunction,
				params: action.payload.params,
			};
		case Constants.CLEAN_EXPORT_FUNCTION:
			return {
				...state,
				actionFunction: null,
				params: null,
			};
		default:
			return state;
	}
}

export default exportDocumentReducer;
