import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import dashboardActionsTopTen from "~/actions/dashboard/dashboarTopTenAction";
import { Typography } from "@material-ui/core";
import VehicleIconComponent from "~/components/common/vehicleIcon/VehicleIconComponent";
import { prefixWithZeros } from "~/helpers/Utils";

import BoxTab from "~/components/common/boxTab/BoxTab";
import ProgressBar from "~/components/common/progressBar/ProgressBar";
import { Link } from "react-router-dom";

import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";

import { convertMToKm } from "~/helpers/Utils";

const TopVehicles = ({ filter }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const [kmActive, setKmActive] = useState("KmRunVehicles");

    const { dataVehiclesTop } = useSelector((state) => state.dashboardTopTen);
    const { loading } = useSelector((state) => state.app);

    const getKmRunVehicles = () => {
        setKmActive("KmRunVehicles");
        dispatch(dashboardActionsTopTen.getKmRunVehicles({ ...filter }));
    };

    const getKmRunCommercialVehicles = () => {
        setKmActive("KmRunCommercialVehicles");
        dispatch(dashboardActionsTopTen.getKmRunCommercialVehicles({ ...filter }));
    };

    const getKmRunNotCommercialVehicles = () => {
        setKmActive("KmRunNotCommercialVehicles");
        dispatch(dashboardActionsTopTen.getKmRunNotCommercialVehicles({ ...filter }));
    };

    const getKmRunWeekendVehicles = () => {
        setKmActive("KmRunWeekendVehicles");
        dispatch(dashboardActionsTopTen.getKmRunWeekendVehicles({ ...filter }));
    };

    useEffect(() => {
        if (!filter) return;

        switch (kmActive) {
            case "KmRunVehicles":
                getKmRunVehicles();
                break;
            case "KmRunCommercialVehicles":
                getKmRunCommercialVehicles();
                break;
            case "KmRunNotCommercialVehicles":
                getKmRunNotCommercialVehicles();
                break;
            case "KmRunWeekendVehicles":
                getKmRunWeekendVehicles();
                break;
            default:
                getKmRunVehicles();
                break;
        }

        // eslint-disable-next-line
    }, [filter]);

    const getDataHighestValue = (dist) => {
        let highest = dataVehiclesTop[0][DIST_COLUMNS[kmActive]];
        return (dist / highest) * 100;
    };

    const DIST_COLUMNS = {
        KmRunVehicles: "Distancia",
        KmRunCommercialVehicles: "DistanciaComercial",
        KmRunNotCommercialVehicles: "DistanciaNaoComercial",
        KmRunWeekendVehicles: "DistanciaFimSemana",
    };

    const LISTBUTTON = [
        {
            label: t(Labels.KM_RUN_LABEL),
            onClick: () => {
                getKmRunVehicles();
            },
            column: "KmRun",
        },
        {
            label: t(Labels.KM_RUN_COMMERCIAL_LABEL),
            onClick: () => {
                getKmRunCommercialVehicles();
            },
            column: "KmRunCommercial",
        },
        {
            label: t(Labels.KM_RUN_NOT_COMMERCIAL_LABEL),
            onClick: () => {
                getKmRunNotCommercialVehicles();
            },
            column: "KmRunNotCommercial",
        },
        {
            label: t(Labels.KM_RUN_WEEKEND_LABEL),
            onClick: () => {
                getKmRunWeekendVehicles();
            },
            column: "KmRunWeekend",
        },
    ];

    const defaultLine = useMemo(() => {
        return [
            {
                name: "",
                label: "",
                render: (itemName, item, count) => (
                    <Typography className="countItem">{prefixWithZeros(count + 1, 2)}</Typography>
                ),
                width: "50px",
            },
            {
                name: "TipoVeiculo",
                label: "",
                render: (typIcon) => (
                    <span className="flex-justify-center">
                        <VehicleIconComponent type={typIcon} />
                    </span>
                ),
                width: "10%",
            },
            {
                name: "Placa",
                label: "Placa",
                width: "20%",
                render: (itemName, item, count) => (
                    <Link to="/dashboardVehicle/" target="_blank" style={{ textDecoration: "none" }}>
                        <Typography
                            onClick={() => {
                                dispatch(dashboardActionsTopTen.setVehicle(item));
                            }}
                            style={{ cursor: "pointer" }}
                            className="gridDataFont rowFont fontDist">
                            {itemName}
                        </Typography>
                    </Link>
                ),
            },
        ];
    }, [dispatch]);

    const COLUMNS = [
        {
            KmRun: [
                ...defaultLine,
                {
                    name: "Distancia",
                    label: "Km rodado",
                    render: (dist) => (
                        <span className="flex-align-center">
                            <ProgressBar value={getDataHighestValue(dist)} />
                            <Typography className="gridDataFont rowFont fontDist progressBar-label">
                                {dist ? convertMToKm(dist) : 0} Km rodados
                            </Typography>
                        </span>
                    ),
                },
            ],
            KmRunCommercial: [
                ...defaultLine,
                {
                    name: "DistanciaComercial",
                    label: "Km rodado",
                    render: (dist) => (
                        <span className="flex-align-center">
                            <ProgressBar value={getDataHighestValue(dist)} />
                            <Typography className="gridDataFont rowFont fontDist progressBar-label">
                                {dist ? convertMToKm(dist) : 0} Km rodados
                            </Typography>
                        </span>
                    ),
                },
            ],
            KmRunNotCommercial: [
                ...defaultLine,
                {
                    name: "DistanciaNaoComercial",
                    label: "Km rodado",
                    render: (dist) => (
                        <span className="flex-align-center">
                            <ProgressBar value={getDataHighestValue(dist)} />
                            <Typography className="gridDataFont rowFont fontDist progressBar-label">
                                {dist ? convertMToKm(dist) : 0} Km rodados
                            </Typography>
                        </span>
                    ),
                },
            ],
            KmRunWeekend: [
                ...defaultLine,
                {
                    name: "DistanciaFimSemana",
                    label: "Km rodado",
                    render: (dist) => (
                        <span className="flex-align-center">
                            <ProgressBar value={getDataHighestValue(dist)} />
                            <Typography className="gridDataFont rowFont fontDist progressBar-label">
                                {dist ? convertMToKm(dist) : 0} Km rodados
                            </Typography>
                        </span>
                    ),
                },
            ],
        },
    ];

    return (
        <BoxTab
            data={dataVehiclesTop}
            listButton={LISTBUTTON}
            columns={COLUMNS}
            loading={loading.getKmRunVehicles}
            defaultColumn={"KmRun"}
        />
    );
};

export default TopVehicles;
