import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";

import alertService from "~/services/alertService";
import alertsService from "~/services/alertsService";

const importAlertReportGet = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "importFileAlert") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertService
        .importAlertReport(filter)
        .then(({ data }) => {
            dispatch({ type: Constants.IMPORT_ALERT_REPORT, payload: { data } });
            callback(false, data, "info");
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const updateAlertDetail = (params, fnCallback, LOADING_IDENTIFICATOR = "getAlertDetails") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertsService
        .getAlertDetails(params)
        .then((res) => {
            fnCallback(res);
            dispatch({
                type: Constants.UPDATE_ALERT_DETAIL,
                payload: {
                    ...res,
                    params: params,
                },
            });
        })
        .catch((error) => {
            // Colocar uma mesagem de erro no card
            // dispatch({
            //     type: Constants.UPDATE_ALERT_DETAIL,
            //     payload: {
            //         params: {
            //             ...params,
            //             error: true,
            //         },
            //     },
            // });
            Utils.showAllErrors(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const searchListAlerts = (arr, searchField) => (dispatch) => {
    const alertReportFilter = Utils.filterData(arr, searchField);
    dispatch({ type: Constants.ALERT_REPORT_FILTER_DATA, payload: { alertReportFilter, searchField } });
};

const cleanListAlerts = () => (dispatch) => {
    dispatch({ type: Constants.ALERT_REPORT_CLEAN });
};

const getHistory = (params, LOADING_IDENTIFICATOR = "getAlertHistory") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertsService
        .getHistory(params)
        .then((res) => {
            let payload = res;
            dispatch({ type: Constants.GET_ALERT_REPORT_HISTORY, payload });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const updateFilterAlert = (filter) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FILTER_ALERT_OBJECT, payload: { filter } });
};

const updateFilterAlertPagination = (filter) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FILTER_ALERT_PAGINATION, payload: { filter } });
};

const updateFilterAlertOrder = (filter) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FILTER_ALERT_ORDER, payload: { filter } });
};

const saveAlertExport = (filter) => (dispatch) => {
    dispatch({ type: Constants.SAVE_EXPORT_ALERT, payload: { filter } });
};

const exportAlert = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "loadingExport") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertService
        .exportAlertGet(filter)
        .then((response) => {
            if (response.data.Success) {
                Utils.downloadToLink(response.data.Message);
            } else {
                Utils.showError(response.data.Message);
            }

            callback(false, response);
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const updateAlertFilterForm = (form) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FORM_ALERT, payload: { form } });
};

const cleanAlertDetails = () => (dispatch) => {
    dispatch({ type: Constants.CLEAN_REPORT_ALERT_DETAIL });
};

export default {
    importAlertReportGet,
    updateFilterAlert,
    updateFilterAlertPagination,
    updateAlertDetail,
    exportAlert,
    saveAlertExport,
    getHistory,
    updateFilterAlertOrder,
    searchListAlerts,
    cleanListAlerts,
    updateAlertFilterForm,
    cleanAlertDetails,
};
