import { addDays, addMonths, addWeeks, endOfMonth, endOfWeek, startOfMonth, startOfWeek, endOfDay, startOfDay } from "date-fns";
import { DefinedRange } from "./types";

const getDefaultRanges = (date: Date): DefinedRange[] => [
	{
		label: "Hoje",
		startDate: startOfDay(date),
		endDate: endOfDay(date),
	},
	{
		label: "Ontem",
		startDate: startOfDay(addDays(date, -1)),
		endDate: endOfDay(addDays(date, -1)),
	},
	{
		label: "Essa semana",
		startDate: startOfWeek(date),
		endDate: endOfWeek(date),
	},
	{
		label: "Semana passada",
		startDate: startOfWeek(addWeeks(date, -1)),
		endDate: endOfWeek(addWeeks(date, -1)),
	},
	{
		label: "Últimos 7 dias",
		startDate: addWeeks(date, -1),
		endDate: date,
	},
	{
		label: "Esse mês",
		startDate: startOfMonth(date),
		endDate: endOfMonth(date),
	},
	{
		label: "Último mês",
		startDate: startOfMonth(addMonths(date, -1)),
		endDate: endOfMonth(addMonths(date, -1)),
	},
];

export const defaultRanges = getDefaultRanges(new Date());
