import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
    baseVehicle:[],
};

function appReducer(state = INITIAL_STATE, action = null) {
	switch (action.type) {
		case Constants.DASHBOARD_VEHICLE_GET_KM_RUN_DAY_PERIOD:
			return {
				...state,
                baseVehicle: action.payload,
			};
		default:
			return state;
	}
}

export default appReducer;