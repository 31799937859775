import React from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Button, Box, Typography } from "@material-ui/core";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import GridRegister from "~/components/drivers/finder/GridRegister";
import Page from "~/components/common/page/Page";
import { AddIcon, ColoredEditIcon } from "~/components/common/icons/Icons";
import exportDocumentAction from "~/actions/exportDocumentAction";
import OptionExportButton from "~/components/common/exportButton/OptionExportButton";

import "./RegisteredDrivers.scss";

const RegisteredDrivers = (props) => {
    const { history } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.app.loading);
    const { searchKey, status } = useSelector((state) => state.driver);
    const { user } = useSelector((state) => state.auth);

    const handlerEdit = (id, row) => {
        history.push(`/register/driver/${id}`);
    };

    const SENT_COLUMNS = [
        {
            name: "Id",
            label: t(Labels.GROUP_POINTS_COLUMN_ACTIONS),
            render: (Id, row) => (
                <span>
                    <span
                        id="drivers-actions-column"
                        style={{ color: user.colors.topBarColor, marginRight: 10 }}
                        onClick={() => {
                            handlerEdit(Id, row);
                        }}>
                        <ColoredEditIcon style={{ marginRight: 3 }} />
                        Editar
                    </span>
                </span>
            ),
        },
        {
            name: "Nome",
            label: "Nome",
            render: (name) => <strong>{name}</strong>,
        },
        {
            name: "Identificador",
            label: "Identificador",
            render: (id) => <strong>{id}</strong>,
        },
        {
            name: "DataHoraVinculo",
            label: "Data Vínculo Identificador",
            render: (date) => (
                <span>
                    {date === "" ? "" : moment(date, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY")}
                    <span style={{ color: "#AFC3D2", margin: "auto 5px" }}>|</span>
                    {date === "" ? "" : moment(date, "DD/MM/YYYY HH:mm:ss").format("HH:mm:ss")}
                </span>
            ),
        },
        {
            name: "NumeroCNH",
            label: "CNH",
            render: (cnh) => <strong>{cnh}</strong>,
        },
        {
            name: "CategoriaCNH",
            label: "Categoria",
            render: (category) => <strong>{category}</strong>,
        },
        {
            name: "DataRenovacaoCNH",
            label: "Data Renovação CNH",
            render: (date) => <span>{date ? moment(new Date(date)).format("DD/MM/YYYY") : ""}</span>,
        },

        {
            name: "Cpf",
            label: "CPF",
            render: (cpf) => <strong>{cpf}</strong>,
        },
        {
            name: "Ativo",
            label: "Ativo",
            render: (active) => <strong>{active ? "Sim" : "Não"}</strong>,
        },
        {
            name: "Descricao",
            label: "Comentário",
            render: (coment) => <strong>{coment}</strong>,
        },
    ];

    const openForm = () => {
        history.push("/register/driver/");
    };

    const openFormImport = () => {
        history.push("/register/drivers/import");
    };

    return (
        <>
            <Page
                topButtons={
                    <div id="export-container">
                        <OptionExportButton
                            loading={loading.requestObjectDoc}
                            onClick={(type) => {
                                dispatch(
                                    exportDocumentAction.extractDocumentDrivers({
                                        format: type.value.toLowerCase(),
                                        filtro: searchKey,
                                        status: status,
                                    })
                                );
                            }}
                        />
                    </div>
                }>
                <main>
                    <Container maxWidth="lg" className="container-map-view register-driver-view">
                        <Grid container>
                            <Grid item xs={12}>
                                <div id="register-drivers-buttons">
                                    <Box>
                                        <Button
                                            id="add-button-driver"
                                            className="btn-add-group-items"
                                            variant="contained"
                                            color="primary"
                                            disableElevation
                                            onClick={openForm}>
                                            <AddIcon />
                                            <Typography>{t(Labels.REGISTER_DRIVER_BUTTON_ADD_DRIVER)}</Typography>
                                        </Button>
                                    </Box>
                                    <Box style={{ marginLeft: "10px" }}>
                                        <Button
                                            id="button-import"
                                            className="btn-add-group-items"
                                            variant="contained"
                                            color="primary"
                                            disableElevation
                                            onClick={openFormImport}>
                                            <AddIcon />
                                            <Typography>{t(Labels.REGISTER_DRIVER_BUTTON_IMPORT_DRIVER)}</Typography>
                                        </Button>
                                    </Box>

                                    {/* <Typography className={classes.typography}>Filtros</Typography> */}
                                </div>
                                <GridRegister
                                    cards={[
                                        {
                                            key: "registerDriver",
                                            title: `${t(Labels.REGISTER_DRIVER_LIST)}`,
                                            search: true,
                                            pagination: true,
                                            status: true,
                                            columns: SENT_COLUMNS,
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </main>
            </Page>
        </>
    );
};
export default withRouter(RegisteredDrivers);
