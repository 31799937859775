import http from "~/config/httpRequest/http";

const fleets = () => {
	return new Promise((resolve, reject) => {
		http
			.get(`/pesquisa/ConsultarFrota`)
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const subFleets = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/pesquisa/BuscarSubFrota`, { params: { grupoId: filter } })
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const periodTrips = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/dashboard/BuscarViagens`, { params: { ...filter } })
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const peerWeek = (filter) => {

	return new Promise((resolve, reject) => {
		http
			.get(`/dashboard/BuscarKmRodadosSemana`, { params: {...filter} })
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const travel = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/dashboard/BuscarViagens`, { params: { ...filter } })
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const scores = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Dashboard/BuscarScore`, { params: { ...filter } })
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const fleetEvolution = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/dashboard/BuscarEvolucaoFrota`, { params: { ...filter } })
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const monthlyFleetEvolution = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/dashboard/BuscarEvolucaoMensal`, { params: { ...filter } })
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const topTenMaxVelocityVehicle = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/dashboard/BuscarTop10VeiculoVelocidadeMaxima`, {
				params: {
					...filter,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const topTenMaxVelocityDriver = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/dashboard/BuscarTop10MotoristasVelocidadeMaxima`, {
				params: {
					...filter,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const travelMaxVelocity = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/dashboard/BuscarViagemVelocidadeMaxima`, {
				params: {
					...filter,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const travelMaxVelocityPositions = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/dashboardVeiculo/BuscarUltimasPosicoesSemDataTable`, {
				params: {
					...filter,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const getDataVehicles = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/geolocalizacaoapi/BuscarUltimaPosicaoObjetoSolrPorId`, {
				params: {
					id: filter?.id,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const verifyDataVehicles = (filter) => {

	return new Promise((resolve, reject) => {
		http
			.get(`/dashboardVeiculo/VerificarAcessoObjetoRastreavel`, {
				params: {
					objetoRastreavelId: filter?.id,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};




export default {
	fleets,
	subFleets,
	periodTrips,
	scores,
	travel,
	fleetEvolution,
	monthlyFleetEvolution,
	topTenMaxVelocityVehicle,
	topTenMaxVelocityDriver,
	travelMaxVelocity,
  travelMaxVelocityPositions,
  peerWeek,

//individual
  getDataVehicles,
  verifyDataVehicles
};
