import React from "react";

import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Container, Grid, Button } from "@material-ui/core";

import Page from "~/components/common/page/Page";
import FleetsLinkCars from "~/components/fleets/finder/tab/linkCars/FleetsLinkCars";
import { ComeBackIcon } from "~/components/common/icons/Icons";

const LinkCarsView = () => {
	const { t } = useTranslation();
	let history = useHistory();

	const comeBack = () => {
		history.push("/register/fleet");
	};

  return (
		<>
			<Page
				topButtons={
					<Button
						className="comeback-button"
						id="extractReportPoints"
						variant="contained"
						size="small"
						disableElevation
						startIcon={<ComeBackIcon />}
						onClick={() => {
							comeBack();
						}}>
						{t(Labels.GROUP_POINTS_COMEBACK)}
					</Button>
				}>
				<main>
					<Container maxWidth="lg" className="containerFleetsLinkCars container-map-view">
						<Grid container>
							<Grid item xs={12}>
								<FleetsLinkCars />
							</Grid>
						</Grid>
					</Container>
				</main>
			</Page>
		</>
	);
}

export default LinkCarsView;
