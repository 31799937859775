import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { nFormatter } from "~/helpers/Utils";
import echarts from "echarts";
import ReactEcharts from "echarts-for-react";
import "./FleetEvolution.scss";
import { rgba } from "polished";

const useStyles = makeStyles((theme) => ({
	coloredBackgorund: {
		backgroundColor: rgba(theme.palette.primary.main, 0.08),
	},
}));

const FleetEvolutionChart = (props) => {
	const classes = useStyles();
	const { user } = useSelector((state) => state.auth);

	const getOption = useCallback((data, color) => {
		return {
			grid: {
				show: true,
				backgroundColor: "#fff",
				right: "5%",
				left: "5%",
				top: "10%",
				bottom: "10%",
			},
			xAxis: {
				type: "category",
				boundaryGap: false,
				data: data.map((e) => e.label),
				splitLine: {
					show: true,
				},
				axisLine: {
					lineStyle: {
						color: "#999",
					},
				},
			},
			yAxis: {
				type: "value",
				axisLine: {
					lineStyle: {
						color: "#999",
					},
				},
				axisLabel: {
					formatter: function (value, index) {
						return nFormatter(value, 2);
					},
				},
				boundaryGap: ["10%", "40%"],
			},
			series: [
				{
					smooth: true,
					data: data.map((e) => e.value),
					type: "line",
					itemStyle: {
						color: color,
					},
					areaStyle: {
						color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
							{
								offset: 0,
								color: color,
							},
							{
								offset: 0.8,
								color: "#ffffff00",
							},
						]),
					},
				},
			],
		};
	}, []);

	return (
		<Box className={`fleet-evolution-chart ${classes.coloredBackgorund}`}>
			<Box className="title">
				<Typography variant="h4"> {props.title} </Typography>
			</Box>
			<Box className="chart">
				<Box className="x-axis">
					<Typography variant="h4" className={classes.coloredBackgorund}>
						{props.xlabel}
					</Typography>
				</Box>
				<Box className="echart">
					<ReactEcharts
						option={getOption(props.data || [], user.colors.topBarColor)}
						showLoading={props.loading}
						loadingOption={{ text: "Carregando" }}
						style={{ height: "100%", width: "100%" }}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default FleetEvolutionChart;
