import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import Page from "~/components/common/page/Page";
import MapToolbar from "~/components/map/MapToolbar";
import TabMap from "~/components/map/tab/TabMap";
import MapCard from "~/components/map/MapCard";
import ExpansionCard from "~/components/common/expansionCard/ExpansionCard";
import ExpansionGrid from "~/components/common/expansionCard/ExpansionGrid";
import ExpansionCardEnum from "~/components/common/expansionCard/ExpansionCardEnum";
import RefreshInterval from "~/components//common/refreshInterval/RefreshInterval";
import mapAction from "~/actions/mapAction";
import OptionExportButton from "~/components/common/exportButton/OptionExportButton";

import "./MapView.scss";

const MapView = () => {
    const dispatch = useDispatch();
    const { filter } = useSelector((state) => state.mapFilterData);

    const { actionFunction, params } = useSelector((state) => state.exportDocument);

    const [alignment, setAlignment] = useState(ExpansionCardEnum.HORIZONTAL);

    const handleSearchRefresh = () => {
        dispatch(mapAction.dataMapFilter(filter));
    };

    return (
        <Page
            bar={<MapToolbar />}
            topButtons={
                <OptionExportButton
                    disabled={!params}
                    onClick={(type) => {
                        dispatch(
                            actionFunction({
                                ...params,
                                Tipo: type.value.toLowerCase(),
                            })
                        );
                    }}
                />
            }>
            <main>
                <Container maxWidth="lg" className="container-map-view">
                    <Grid container>
                        <Grid item xs={12}>
                            <ExpansionGrid alignment={alignment} fnClick={setAlignment} style={{}}>
                                <ExpansionCard
                                    card={"veículos"}
                                    flex={"0 1 30%"}
                                    key={"1"}
                                    alignment={alignment}
                                    fnClick={setAlignment}
                                    className={"mapViewList card-refactor"}>
                                    <TabMap mode={alignment} />
                                </ExpansionCard>
                                <ExpansionCard
                                    notPadded
                                    title={"Mapa"}
                                    card={"mapa"}
                                    className={`expansion-children-card card-refactor mapViewMap ${
                                        alignment.orientation === "vertical" ? `viewMapVertical` : ""
                                    }`}
                                    titleButtons={
                                        <RefreshInterval
                                            seconds={60}
                                            fnCallback={handleSearchRefresh}
                                            className="refresh-interval-refactor"
                                        />
                                    }
                                    flex={"0 1 70%"}
                                    key={"2"}
                                    alignment={alignment}
                                    fnClick={setAlignment}>
                                    <MapCard className="map-card-refactor" />
                                </ExpansionCard>
                            </ExpansionGrid>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </Page>
    );
};

export default MapView;
