import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import dashboardActionsTopTen from "~/actions/dashboard/dashboarTopTenAction";
import { prefixWithZeros } from "~/helpers/Utils";

import { Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

import BoxTab from "~/components/common/boxTab/BoxTab";
import ProgressBar from "~/components/common/progressBar/ProgressBar";
import { convertMToKm } from "~/helpers/Utils";

import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";

const TopDriver = ({ filter }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const [kmActive, setKmActive] = useState("KmRunDrivers");

    const { dataDriversTop } = useSelector((state) => state.dashboardTopTen);
    const { loading } = useSelector((state) => state.app);

    const getKmRunDrivers = () => {
        setKmActive("KmRunDrivers");
        dispatch(dashboardActionsTopTen.getKmRunDrivers({ ...filter }));
    };

    const getKmRunCommercialDrivers = () => {
        setKmActive("KmRunCommercialDrivers");
        dispatch(dashboardActionsTopTen.getKmRunCommercialDrivers({ ...filter }));
    };

    const getKmRunNotCommercialDrivers = () => {
        setKmActive("KmRunNotCommercialDrivers");
        dispatch(dashboardActionsTopTen.getKmRunNotCommercialDrivers({ ...filter }));
    };

    const getKmRunWeekendDrivers = () => {
        setKmActive("KmRunWeekendDrivers");
        dispatch(dashboardActionsTopTen.getKmRunWeekendDrivers({ ...filter }));
    };

    useEffect(() => {
        if (!filter) return;

        switch (kmActive) {
            case "KmRunDrivers":
                getKmRunDrivers();
                break;
            case "KmRunCommercialDrivers":
                getKmRunCommercialDrivers();
                break;
            case "KmRunNotCommercialDrivers":
                getKmRunNotCommercialDrivers();
                break;
            case "KmRunWeekendDrivers":
                getKmRunWeekendDrivers();
                break;
            default:
                getKmRunDrivers();
                break;
        }
        // eslint-disable-next-line
    }, [filter]);

    const getDataHighestValue = (dist) => {
        let highest = dataDriversTop[0][DIST_COLUMNS[kmActive]];
        return (dist / highest) * 100;
    };

    const DIST_COLUMNS = {
        KmRunDrivers: "Distancia",
        KmRunCommercialDrivers: "DistanciaComercial",
        KmRunNotCommercialDrivers: "DistanciaNaoComercial",
        KmRunWeekendDrivers: "DistanciaFimSemana",
    };

    const defaultLine = useMemo(() => {
        return [
            {
                name: "",
                label: "",
                width: "50px",
                render: (itemName, item, count) => (
                    <Typography className="countItem">{prefixWithZeros(count + 1, 2)}</Typography>
                ),
            },
            {
                name: "UrlImagem",
                label: "",
                width: "10%",
                render: (url) => (
                    <span className="flex-justify-center">
                        <Avatar className="imgTop10Driver" src={url ? url.trim() : null} />
                    </span>
                ),
            },
            {
                name: "MotoristaNome",
                label: "Motorista",
                width: "20%",
            },
        ];
    }, []);

    const COLUMNS = [
        {
            KmRun: [
                ...defaultLine,
                {
                    name: "Distancia",
                    label: "Km rodado",
                    render: (dist) => (
                        <span className="flex-align-center">
                            <ProgressBar value={getDataHighestValue(dist)} />
                            <Typography className="gridDataFont rowFont fontDist progressBar-label">
                                {dist ? convertMToKm(dist) : 0} Km rodados
                            </Typography>
                        </span>
                    ),
                },
            ],
            KmRunCommercial: [
                ...defaultLine,
                {
                    name: "DistanciaComercial",
                    label: "Km rodado",
                    render: (dist) => (
                        <span className="flex-align-center">
                            <ProgressBar value={getDataHighestValue(dist)} />
                            <Typography className="gridDataFont rowFont fontDist progressBar-label">
                                {dist ? convertMToKm(dist) : 0} Km rodados
                            </Typography>
                        </span>
                    ),
                },
            ],
            KmRunNotCommercial: [
                ...defaultLine,
                {
                    name: "DistanciaNaoComercial",
                    label: "Km rodado",
                    render: (dist) => (
                        <span style={{ display: "flex" }}>
                            <ProgressBar value={getDataHighestValue(dist)} />
                            <Typography className="gridDataFont rowFont fontDist progressBar-label">
                                {dist ? convertMToKm(dist) : 0} Km rodados
                            </Typography>
                        </span>
                    ),
                },
            ],
            KmRunWeekend: [
                ...defaultLine,
                {
                    name: "DistanciaFimSemana",
                    label: "Km rodado",
                    render: (dist) => (
                        <span style={{ display: "flex" }}>
                            <ProgressBar value={getDataHighestValue(dist)} />
                            <Typography className="gridDataFont rowFont fontDist progressBar-label">
                                {dist ? convertMToKm(dist) : 0} Km rodados
                            </Typography>
                        </span>
                    ),
                },
            ],
        },
    ];

    const LISTBUTTON = [
        {
            label: t(Labels.KM_RUN_LABEL),
            onClick: () => {
                getKmRunDrivers();
            },
            column: "KmRun",
        },
        {
            label: t(Labels.KM_RUN_COMMERCIAL_LABEL),
            onClick: () => {
                getKmRunCommercialDrivers();
            },
            column: "KmRunCommercial",
        },
        {
            label: t(Labels.KM_RUN_NOT_COMMERCIAL_LABEL),
            onClick: () => {
                getKmRunNotCommercialDrivers();
            },
            column: "KmRunNotCommercial",
        },
        {
            label: t(Labels.KM_RUN_WEEKEND_LABEL),
            onClick: () => {
                getKmRunWeekendDrivers();
            },
            column: "KmRunWeekend",
        },
    ];

    return (
        <BoxTab
            data={dataDriversTop}
            listButton={LISTBUTTON}
            columns={COLUMNS}
            loading={loading.getKmRunDrivers}
            defaultColumn={"KmRun"}
            className="driver-box"
        />
    );
};

export default TopDriver;
