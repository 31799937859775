import { createStyles, Divider, Grid, Paper, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import { differenceInCalendarMonths, format } from "date-fns";
import React from "react";
import { MARKERS } from "../RangeDatePicker";
import { DateRange, DefinedRange, NavigationAction, Setter } from "../types";
import DefinedRanges from "./DefinedRanges";
import Month from "./Month";

const styles = (theme: Theme) =>
	createStyles({
		header: {
			padding: "10px 10px",
		},
		closeButton: {
			flex: 1,
			textAlign: "left",
		},
		headerItem: {
			flex: 2,
			textAlign: "center",
		},
		divider: {
			borderLeft: `1px solid ${theme.palette.action.hover}`,
			marginBottom: 20,
		},
	});

interface MenuProps extends WithStyles<typeof styles> {
	id: string;
	closeButton: Node;
	dateRange: DateRange;
	ranges: DefinedRange[];
	minDate: Date;
	maxDate: Date;
	firstMonth: Date;
	secondMonth: Date;
	setFirstMonth: Setter<Date>;
	setSecondMonth: Setter<Date>;
	setDateRange: Setter<DateRange>;
	hours: boolean;
	helpers: {
		inHoverRange: (day: Date) => boolean;
	};
	handlers: {
		onDayClick: (day: Date) => void;
		onDayHover: (day: Date) => void;
		onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
	};
}

const Menu: React.FunctionComponent<MenuProps> = (props) => {
	const {
		closeButton,
		classes,
		ranges,
		dateRange,
		minDate,
		maxDate,
		firstMonth,
		setFirstMonth,
		secondMonth,
		setSecondMonth,
		setDateRange,
		helpers,
		handlers,
		id,
		hours,
	} = props;
	
	const { startDate, endDate } = dateRange;
	const canNavigateCloser = differenceInCalendarMonths(secondMonth, firstMonth) >= 2;
	const commonProps = { dateRange, minDate, maxDate, helpers, handlers, hours };
	return (
		<Paper elevation={0} square id={id}>
			<Grid container direction="row" wrap="nowrap">
				<Grid>
					<Grid container className={classes.header} alignItems="center">
						<Grid item className={classes.closeButton}>
							{closeButton}
						</Grid>
						<Grid item className={classes.headerItem}>
							<Typography variant="subtitle1">{startDate ? format(startDate, "DD/MM/YYYY") : "Data início"}</Typography>
						</Grid>
						<Grid item className={classes.headerItem}>
							<ArrowRightAlt color="action" />
						</Grid>
						<Grid item className={classes.headerItem}>
							<Typography variant="subtitle1">{endDate ? format(endDate, "DD/MM/YYYY") : "Data fim"}</Typography>
						</Grid>
					</Grid>
					<Divider />
					<Grid container direction="row" justify="center" wrap="nowrap">
						<DefinedRanges selectedRange={dateRange} ranges={ranges} setRange={setDateRange} />

						<div className={classes.divider} />
						<Month
							{...commonProps}
							value={firstMonth}
							setValue={setFirstMonth}
							navState={[true, canNavigateCloser]}
							marker={MARKERS.FIRST_MONTH}
						/>
						<div className={classes.divider} />
						<Month
							{...commonProps}
							value={secondMonth}
							setValue={setSecondMonth}
							navState={[canNavigateCloser, true]}
							marker={MARKERS.SECOND_MONTH}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default withStyles(styles)(Menu);
