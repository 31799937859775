import communicatedService from "~/services/communicatedService";
import Utils from "~/helpers/Utils";
import Constants from "~/helpers/enums/Constants";

const getPendingNotices = (callback = () => {}, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return communicatedService
		.getPendingNotices()
		.then((response) => {
			let data = response.data;
			let payload = {
				data: data,
			};
			dispatch({ type: Constants.GET_PENDING_NOTICES, payload });
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getAllCommunicated = (callback = () => {}, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return communicatedService
		.getAllCommunicated()
		.then((response) => {
			let data = response.data;
			let payload = {
				data: data,
			};
			dispatch({ type: Constants.GET_ALL_COMMUNICATED, payload });
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const checkNotification = (id, callback = () => {}, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return communicatedService
		.checkNotification(id)
		.then((response) => {
			let data = response.data;
			let payload = {
				data: data,
			};
			dispatch({ type: Constants.COMMUNICATE_CHECK_NOTIFICATION, payload });
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};
export default {
	getPendingNotices,
	getAllCommunicated,
	checkNotification
};
