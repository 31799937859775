import React, { useState } from "react";

import ExpansionGrid from "~/components/common/expansionCard/ExpansionGrid";
import ExpansionCardEnum from "~/components/common/expansionCard/ExpansionCardEnum";
import Card from "~/components/common/expansionCard/Card";
import MapCard from "~/components/map/MapCard";
import PointsGrid from "~/components/pointsInterest/finder/GridPoints/PointsGrid";
import "./FinderPoints.scss";

const FinderPoints = () => {
    const [alignment, setAlignment] = useState(ExpansionCardEnum.HORIZONTAL);

    return (
        <ExpansionGrid alignment={alignment} fnClick={setAlignment}>
            <Card
                key={"1"}
                alignment={alignment}
                fnClick={setAlignment}
                notExpanded
                className="card-group-refactor"
                style={{ width: "15vw", height: "auto" }}>
                <PointsGrid />
            </Card>
            <Card title={"Mapa"} notPadded className="expansion-children-card card-group-refactor">
                <MapCard className="map-card-refactor" />
            </Card>
        </ExpansionGrid>
    );
};

export default FinderPoints;
