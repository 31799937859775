import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import { SaveReportIcon } from "~/components/common/icons/Icons";
import Card from "~/components/common/expansionCard/Card";
import DualList from "~/components/common/dualList/DualList";
import RegisterInputText from "~/components/common/registerInputs/RegisterInputText";
import CheckboxPGF from "~/components/common/inputs/checkbox/CheckboxPGF";
import groupPointsAction from "~/actions/groupPointsAction";
import { useHistory } from "react-router-dom";

const RegisterGroupOfPoints = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	let history = useHistory();
	const { location } = history;

	const { loading } = useSelector((state) => state.app);
	const { user } = useSelector((state) => state.auth);
	const { pointsLinked, availablePoints } = useSelector((state) => state.groupPoints);

	useEffect(() => {
		if (location?.state) {
			dispatch(groupPointsAction.getPointsLinkedGroupById(location.state.Id));
			dispatch(groupPointsAction.getAvailablePointsForGroupById(location.state.Id));
		}
	}, [dispatch, history, location.pathname, location.state]);

	useEffect(() => {
		if (!location?.state?.Id && location.pathname !== "/register/groupOfPointsInterest/new") {
			history.replace("/register/groupOfPointsInterest/new");
		}
	}, [history, location.pathname, location.state]);

	useEffect(() => {
		dispatch(groupPointsAction.cleanPointsLinked());
		dispatch(groupPointsAction.cleanAvailablePoints());
	}, [dispatch]);

	const GROUP_POINTS_COLUMNS = [
		{
			name: "Nome",
			label: t(Labels.GROUP_POINTS_COLUMN_POINTS),
			render: (nome) => <strong>{nome}</strong>,
		},
	];

	const savedGroup = (group) => {
		history.push(
			{
				pathname: `/register/groupOfPointsInterest/${group.Id}`,
			},
			group
		);
	};

	const getIdOfselectedRows = (rows) => {
		return rows.map((r) => r.Id);
	};

	const handleLinkDriver = (selectedRows) => {
		const selectedRowsId = getIdOfselectedRows(selectedRows);
		dispatch(groupPointsAction.saveLinkPoints(location.state.Id, selectedRowsId));
	};

	const handleUnbindDriver = (selectedRows) => {
		const selectedRowsId = getIdOfselectedRows(selectedRows);
		dispatch(groupPointsAction.saveUnbindPoints(location.state.Id, selectedRowsId));
	};

	const handlerSearchPagingAvailable = (search, pagination) => {
		const filter = {
			length: pagination.length || 25,
			page: pagination.current - 1 || 0,
			search: search,
		};
		dispatch(groupPointsAction.getAvailablePointsForGroupById(location.state.Id, filter));
	};

	const handlerSearchPagingLinked = (search, pagination) => {
		const filter = {
			length: pagination.length || 25,
			page: pagination.current - 1 || 0,
			search: search,
		};
		dispatch(groupPointsAction.getPointsLinkedGroupById(location.state.Id, filter));
	};

	return (
		<div>
			<Card>
				<Formik
					validateOnBlur={false}
					validateOnChange={false}
					initialValues={{ Nome: location?.state?.Nome || "", Ativo: location?.state?.Ativo || false }}
					validate={(values) => {
						const errors = {};

						if (!values.Nome) {
							errors.Nome = t(Labels.GROUP_POINTS_REGISTER_REQUIRED);
						}

						return errors;
					}}
					onSubmit={(values, { setSubmitting }) => {
						setSubmitting(false);
						let groupReq = {
							Nome: values.Nome,
							Ativo: !!values.Ativo ? 1 : 0,
						};
						if (location?.state?.Id) {
							groupReq.Id = location.state.Id;
						}

						dispatch(groupPointsAction.saveGroupOfPoints(groupReq, savedGroup));
					}}
					render={({ submitForm, setFieldValue }) => {
						return (
							<Form id="register-group-drivers-form" className="ceabs-register-form">
								<Grid container justify="right" alignItems="flex-end" spacing={1}>
									<Grid item xs={3}>
									<RegisterInputText
											name="Nome"
											noPadding
											placeholder={t(Labels.GROUP_POINTS_REGISTER_NAME_PLACEHOLDER)}
											label={t(Labels.GROUP_POINTS_REGISTER_NAME)}
										/>
									</Grid>
									<Grid item>
										<CheckboxPGF
											color="primary"
											id="Ativo"
											name="Ativo"
											label={t(Labels.GROUP_POINTS_REGISTER_ACTIVE)}
										/>
									</Grid>

									<Grid className="button-form button-form-refactor" item xs={3}>
										<Button
											id="speeding-filter-period-save-button"
											className="save-button save-button-refactor"
											fullWidth
											variant="contained"
											color="primary"
											disableElevation
											disabled={loading.saveGroupOfPoints}
											onClick={submitForm}>
											{loading.saveGroupOfPoints ? (
												<CircularProgress style={{ height: 14, width: 14, marginRight: 8 }} color={"#fff"} />
											) : (
												<SaveReportIcon />
											)}
											{t(Labels.GROUP_POINTS_REGISTER_BTNSAVE)}
										</Button>
									</Grid>
								</Grid>
							</Form>
						);
					}}
				/>
			</Card>
			{!!location?.state?.Id && (
				<Grid container justify="right" alignItems="flex-end" spacing={1}>
					<Grid item xs={12}>
						<DualList
							actionAdd={handleLinkDriver}
							actionRemove={handleUnbindDriver}
							tableAvailable={{
								key: "Available",
								title: t(Labels.GROUP_POINTS_COLUMN_POINTS),
								columns: GROUP_POINTS_COLUMNS,
								dataSource: availablePoints.data,
								rowSelection: {
									refColName: "Nome",
									onChange: (selectedRows) => {
										console.log("selectedRows", selectedRows);
									},
								},
								pagination: {
									current: availablePoints.index,
									totalItems: availablePoints.recordsTotal,
								},
								onChange: handlerSearchPagingAvailable,
								loading: loading.getAvailablePoints,
							}}
							tableLinked={{
								key: "Linked",
								title: t(Labels.GROUP_POINTS_OF_INTEREST_LINKED_LABEL),
								columns: GROUP_POINTS_COLUMNS,
								dataSource: pointsLinked.data,
								rowSelection: {
									refColName: "Nome",
									onChange: (selectedRows) => {
										console.log("selectedRows", selectedRows);
									},
								},
								loading: loading.getPointsLinked,
								search: true,
								pagination: {
									current: pointsLinked.index,
									totalItems: pointsLinked.recordsTotal,
								},
								onChange: handlerSearchPagingLinked,
								backgroundColor: user.colors.topBarColor,
							}}
						/>
					</Grid>
				</Grid>
			)}
		</div>
	);
};

export default RegisterGroupOfPoints;
