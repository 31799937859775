import React, { useEffect, useCallback } from "react";
import { Box, InputAdornment, makeStyles, Grid, Portal } from "@material-ui/core";
import TableCard from "~/components/tableCard/TableCard";
import Search from "~/components/common/search/Search";
import Pagination from "~/components/common/pagination/Pagination";
import PlacesAutocomplete from "~/components/common/placesAutocomplete/PlacesAutocomplete";
import MenuRange from "~/components/common/menuRange/MenuRange";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import { useDispatch, useSelector } from "react-redux";

import googleMapsDataAction from "~/actions/googleMapsDataAction";

import addressAction from "~/actions/addressAction";
import { GooglePlacesIcon } from "~/components/common/icons/Icons";

import "./Tab.scss";

const useStyles = makeStyles((theme) => ({
    adornmentPlaces: {
        backgroundColor: theme.palette.primary.main,
        display: "block",
        padding: "0.5rem 0.8rem",
        position: "absolute",
        height: "60%",
        right: 0,
        borderBottomRightRadius: "3px",
        borderTopRightRadius: "3px",
    },
}));

const TabMapAddress = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const isHorizontal = props.mode.orientation === "horizontal";
    const { t } = useTranslation();
    const { filter, menu, data } = useSelector((state) => state.address);

    useEffect(() => {
        dispatch(addressAction.addressGetData(filter));
    }, [dispatch, filter]);

    const SearchField = useCallback(
        ({ filter: _filter }) => (
            <Grid container justify="right" alignItems="flex-end" spacing={1}>
                <Grid item xs={9}>
                    <Search
                        id="searchAddress"
                        className={"search search-input-refactor"}
                        value={_filter.term}
                        search={(term) => {
                            dispatch(
                                addressAction.updateAddressDataFilter({
                                    ..._filter,
                                    index: 0,
                                    term: term,
                                })
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <MenuRange
                        id={"vehicle"}
                        label={t(Labels.MENU_RANGE_SHOW)}
                        className="menu-range-refactor"
                        value={_filter.limit}
                        onChange={(event) => {
                            dispatch(
                                addressAction.updateAddressDataFilter({
                                    ..._filter,
                                    limit: event.target.value,
                                    index: 0,
                                })
                            );
                        }}
                        options={[10, 25, 50, 100, 250, 500]}
                    />
                </Grid>
            </Grid>
        ),
        [dispatch, t]
    );

    return (
        <Box className={"map-tab floating-header"}>
            <Box
                className="map-tab-main"
                style={{
                    paddingTop: isHorizontal ? "100px" : "5rem",
                }}>
                <Box className={`map-tab-header`}>
                    <Grid container justify="right" alignItems="flex-end" spacing={1}>
                        <Grid item xs={12}>
                            <div id="placesAuto">
                                <PlacesAutocomplete
                                    placeholder={t(Labels.MAP_TAB_ADDRESSS)}
                                    onChange={({ latlng, sugestion }) => {
                                        const query = {
                                            ...filter,
                                            latitude: latlng.lat,
                                            longitude: latlng.lng,
                                        };
                                        dispatch(
                                            addressAction.updateAddressDataFilter({
                                                ...query,
                                            })
                                        );
                                    }}
                                    borded
                                    margin="dense"
                                    endAdornment={
                                        <InputAdornment position="end" className={classes.adornmentPlaces}>
                                            <GooglePlacesIcon />
                                        </InputAdornment>
                                    }
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            {isHorizontal ? (
                                <SearchField filter={filter} />
                            ) : (
                                <Portal container={props.containerSerach.current}>
                                    <SearchField filter={filter} />
                                </Portal>
                            )}
                        </Grid>
                    </Grid>
                </Box>

                <div id={"card-item-address-container"}>
                    <TableCard
                        id={"card-item-address"}
                        mode={props.mode}
                        filters={menu}
                        data={data.Data}
                        updateAction={googleMapsDataAction.updateObjects}
                        cleanAction={googleMapsDataAction.cleanObjects}
                        onItemClick={(element) => {
                            dispatch(
                                googleMapsDataAction.centralizePoints([
                                    {
                                        lat: element.Latitude,
                                        lng: element.Longitude,
                                    },
                                ])
                            );
                        }}
                    />
                </div>
            </Box>

            <div className="pagination-tabmap">
                <Pagination
                    onChange={(event, value) => {
                        dispatch(
                            addressAction.updateAddressDataFilter({
                                ...filter,
                                index: value - 1,
                            })
                        );
                    }}
                    page={filter.index + 1}
                    count={Math.ceil(data.iTotalRecords / filter.limit)}
                />
            </div>
        </Box>
    );
};

export default TabMapAddress;
