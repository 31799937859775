import Constants from "~/helpers/enums/Constants";

const initialState = {
	value: Constants.TAB_OBJECT,
};

function reportTabReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.CHANGE_TAB_VALUE:
			return {
				...state,
				value: action.payload.value,
			};
		default:
			return state;
	}
}

export default reportTabReducer;
