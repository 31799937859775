import React from "react";
import { useSelector } from "react-redux";
import { rgba } from "polished";
import { VechicleIcon, TruckIcon, MotorcycleIcon } from "~/components/common/icons/Icons";
import "./VehicleIcon.scss";

const VehicleIconComponent = (props) => {
	const { user } = useSelector((state) => state.auth);

	const getIcon = (type) => {
		switch (type && type.toLowerCase()) {
			case "moto":
				return <MotorcycleIcon />;
			case "carreta":
				return <TruckIcon />;
			case "veiculo":
			default:
				return <VechicleIcon />;
		}
	};

	return (
		<div
			className="vehicle-icon"
			style={{
				boxShadow: `0px 0px 0px 4px ${rgba(props.color || user.colors.topBarColor, 0.5)}`,
				backgroundColor: props.color || user.colors.topBarColor,
				width: props.width || "2.5rem",
				height: props.height || "2.5rem",
			}}>
			{getIcon(props.type)}
		</div>
	);
};

export default VehicleIconComponent;
