import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import reportingAction from "~/actions/reportingAction";
import { ReactComponent as DownloadIcon } from "~/assets/icons/download.svg";
import ProgressButton from "~/components/common/progressButton/ProgressButton";
import Scheduling from "~/components/common/schedulingReport/Scheduling";
import { ScheduledPanel } from "~/components/common/reportTab/ReportPanel";
import { ColoredEditIcon, IconDelete } from "~/components/common/icons/Icons";
import { useTranslation } from "react-i18next";
import useFuso from "~/components/common/fuso/useFuso";
import Labels from "~/helpers/enums/Labels";

const SchedulingReport = (props) => {
    const { scheduling, sent } = useSelector((state) => state.reporting);
    const { user } = useSelector((state) => state.auth);
    const { t } = useTranslation();
    const { fromUtc } = useFuso();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(reportingAction.getDistanceReportScheduling({}));
        dispatch(reportingAction.getDistanceReportSent({}));
    }, [dispatch]);

    const handlerEdit = (id, row) => {
        dispatch(reportingAction.getDistanceReportSchedulingById(id));
    };

    const refreshSchedulingList = () => {
        dispatch(reportingAction.getDistanceReportScheduling({}));
    };
    const handlerDelete = (id, row) => {
        console.log('call handler Delete');
        dispatch(reportingAction.deleteReportScheduling(id, refreshSchedulingList));
    };

    const SCHEDULING_COLUMNS = [
        {
            name: "Id",
            label: t(Labels.GROUP_POINTS_COLUMN_ACTIONS),
            render: (Id, row) => (
                <div className="span-actions">
                    <span
                        data-id={"editar-item-tabela-agendado-distancia-" + Id}
                        style={{ color: user.colors.topBarColor, marginRight: 10 }}
                        onClick={() => {
                            handlerEdit(Id, row);
                        }}>
                        <ColoredEditIcon />
                        {t(Labels.GROUP_DRIVERS_LIST_BTN_EDIT)}
                    </span>
                    <span
                        data-id={"download-item-tabela-agendado-distancia-" + Id}
                        style={{ borderLeft: "1px solid #AFC3D2", paddingLeft: 10, color: user.colors.topBarColor }}
                        onClick={() => {
                            handlerDelete(Id, row);
                        }}>
                        <IconDelete />
                        {t(Labels.ALERT_EDIT_BUTTON_DEACTIVE)}
                    </span>
                </div>
            ),
        },
        {
            name: "ObjetoGrupoNome",
            label: t(Labels.COLUMN_PLATE),
            render: (plate) => <span className="strong-plate">{plate}</span>,
        },
        {
            name: "DataInicio",
            label: t(Labels.COLUMN_START),
            render: (date) => (
                <span>
                    {moment(date).format("DD/MM/YYYY")}
                    <span style={{ color: "#AFC3D2", margin: "auto 5px" }}>|</span>
                    {moment(date).format("HH:mm:ss")}
                </span>
            ),
        },
        {
            name: "Periodicidade",
            label: t(Labels.REPORT_SHEDULED_FREQUENCY),
        },
        {
            name: "Formato",
            label: t(Labels.REPORT_DISTANCE_FILE_FORMAT),
        },
        {
            name: "Email",
            label: t(Labels.REPORT_SHEDULED_EMAIL),
        },
    ];

    const SENT_COLUMNS = [
        {
            name: "ObjetoGrupoNome",
            label: t(Labels.COLUMN_PLATE),
            render: (place) => <strong>{place}</strong>,
        },
        {
            name: "DataHoraCadastro",
            label: t(Labels.REPORT_DISTANCE_FILE_CREATION_DATE),
            render: (date) => (
                <span>
                    {fromUtc(date).format("DD/MM/YYYY")}
                    <span style={{ color: "#AFC3D2", margin: "auto 5px" }}>|</span>
                    {fromUtc(date).format("HH:mm:ss")}
                </span>
            ),
        },
        {
            name: "DataInicial",
            label: t(Labels.REPORT_DISTANCE_INITIAL_DATE),
            render: (date) => (
                <span>
                    {fromUtc(date).format("DD/MM/YYYY")}
                    <span style={{ color: "#AFC3D2", margin: "auto 5px" }}>|</span>
                    {fromUtc(date).format("HH:mm:ss")}
                </span>
            ),
        },
        {
            name: "DataFinal",
            label: t(Labels.REPORT_DISTANCE_FINAL_DATE),
            render: (date) => (
                <span>
                    {fromUtc(date).format("DD/MM/YYYY")}
                    <span style={{ color: "#AFC3D2", margin: "auto 5px" }}>|</span>
                    {fromUtc(date).format("HH:mm:ss")}
                </span>
            ),
        },
        {
            name: "Periodicidade",
            label: t(Labels.REPORT_SHEDULED_FREQUENCY),
        },
        {
            name: "Processado",
            label: t(Labels.REPORT_DISTANCE_PROCESSING_PERCENT),
            render: (percent, row) => (
                <ProgressButton
                    data-id={"download-item-tabela-agendado-distancia-" + row.Id}
                    progress={row.Processado}
                    error={row.Restante}
                    onClick={() => {
                        handleDownloadReport(row.Arquivo);
                    }}>
                    <DownloadIcon style={{ height: "auto", width: 14 }} /> {t(Labels.DOWNLOAD)}
                </ProgressButton>
            ),
        },
    ];

    const handleDownloadReport = (url) => {
        window.open(url, "_blank");
    };

    return (
        <ScheduledPanel>
            <Scheduling
                cards={[
                    {
                        key: "distanceReportScheduling",
                        title: t(Labels.REPORT_DISTANCE_TITLES_REPORT_SCHEDULED),
                        action: (filter) => dispatch(reportingAction.getDistanceReportScheduling(filter)),
                        columns: SCHEDULING_COLUMNS,
                        dataSource: scheduling.data,
                        className: "card-refactor",
                    },
                    {
                        key: "distanceReportSent",
                        title: t(Labels.REPORT_DISTANCE_TITLES_REPORT_SENT),
                        search: true,
                        action: (filter) => dispatch(reportingAction.getDistanceReportSent(filter)),
                        columns: SENT_COLUMNS,
                        dataSource: sent.data,
                        className: "card-refactor",
                    },
                ]}
            />
        </ScheduledPanel>
    );
};

export default SchedulingReport;
