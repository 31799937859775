import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import UnfoldLessIcon from "@material-ui/icons/UnfoldLess";

import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import { ReactComponent as HorizontalIcon } from "~/assets/icons/expansionCard/horizontal.svg";
import { ReactComponent as HorizontalInverseIcon } from "~/assets/icons/expansionCard/horizontalInverse.svg";
import { ReactComponent as VerticalIcon } from "~/assets/icons/expansionCard/vertical.svg";
import { ReactComponent as VerticalInverseIcon } from "~/assets/icons/expansionCard/verticalInverse.svg";

import "./expansionCard.scss";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { Popover } from "@material-ui/core";
import ExpansionCardEnum from "./ExpansionCardEnum";
import Card from "./Card";

const ExpansionCard = (props) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpansionToggle = () => {
        setExpanded(!expanded);
    };

    const formatAlignment = (alignment) => {
        switch (alignment) {
            case 1:
                return ExpansionCardEnum.HORIZONTAL;
            case 2:
                return ExpansionCardEnum.VERTICAL;
            case 3:
                return ExpansionCardEnum.HORIZONTAL_INVERSE;
            case 4:
                return ExpansionCardEnum.VERTICAL_INVERSE;
            default:
                return ExpansionCardEnum.HORIZONTAL;
        }
    };

    const handleAlignment = (event, newAlignment) => {
        const alignmentFormatted = formatAlignment(newAlignment);
        handleClose();
        props.fnClick(alignmentFormatted);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        hideScroll();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        showScroll();
        setAnchorEl(null);
    };

    const getClassName = (expanded) => {
        if (!!expanded) {
            hideScroll();
            return "expandedCard";
        } else {
            showScroll();
            return "";
        }
    };

    const hideScroll = () => {
        document.documentElement.style.overflow = "hidden";
        document.body.scroll = "no";
    };
    const showScroll = () => {
        document.documentElement.style.overflow = "auto";
        document.body.scroll = "yes";
    };

    return (
        <Card
            notPadded={props.notPadded}
            className={`${getClassName(expanded)} ${props.className}`}
            style={{ flex: props.flex || "1 1 auto", ...props.style }}
            title={props.title}
            refTitleDiv={props.refTitleDiv}
            classTitle={props.classTitle}
            titleButtons={props.titleButtons}
            coloredTitleBox={props.coloredTitleBox}
            topButtons={
                <div>
                    {!expanded && !props.hideAlignments && (
                        <IconButton
                            data-id={"button-view-" + props.card}
                            aria-label="splitView"
                            aria-describedby="splitView"
                            onClick={handleClick}>
                            <FlipToFrontIcon />
                        </IconButton>
                    )}

                    {!props.hideResize && (
                        <IconButton
                            data-id={"button-expand-" + props.card}
                            aria-label="expansion"
                            onClick={handleExpansionToggle}>
                            {!!expanded ? (
                                <UnfoldLessIcon style={{ fontSize: 15, transform: "rotate(45deg)" }} />
                            ) : (
                                <UnfoldMoreIcon style={{ fontSize: 15, transform: "rotate(45deg)" }} />
                            )}
                        </IconButton>
                    )}

                    <Popover
                        data-id={"view-" + props.card}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}>
                        <ToggleButtonGroup
                            id={"button-group"}
                            value={props.alignment?.id}
                            exclusive
                            onChange={handleAlignment}
                            aria-label="alignment">
                            <ToggleButton
                                data-id={"horizontal-button-" + props.card}
                                value={1}
                                aria-label="horizontal"
                                className={"expansionCardAlignIcon"}>
                                <HorizontalIcon />
                            </ToggleButton>
                            <ToggleButton
                                data-id={"vertical-button-" + props.card}
                                value={2}
                                aria-label="vertical"
                                className={"expansionCardAlignIcon"}>
                                <VerticalIcon />
                            </ToggleButton>
                            <ToggleButton
                                data-id={"horizontal-inverso-button-" + props.card}
                                value={3}
                                aria-label="horizontal inverso"
                                className={"expansionCardAlignIcon"}>
                                <HorizontalInverseIcon />
                            </ToggleButton>
                            <ToggleButton
                                data-id={"vertical-inverso-button-" + props.card}
                                value={4}
                                aria-label="vertical inverso"
                                className={"expansionCardAlignIcon"}>
                                <VerticalInverseIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Popover>

                    {props.otherButtons}
                </div>
            }>
            {props.children}
        </Card>
    );
};

export default ExpansionCard;
