import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import googleMapsDataAction from "~/actions/googleMapsDataAction";
import MapCard from "~/components/map/MapCard";
import Loading from "~/components/common/loading/LoadingPanel";
import ExpansionCard from "~/components/common/expansionCard/ExpansionCard";

import "./mapVehicle.scss";

const MapVehicle = () => {
    const dispatch = useDispatch();
    const [load, setLoad] = useState(true);
    const { data } = useSelector((state) => state.dashboardDataVehicle);
    const { loading } = useSelector((state) => state.app);


    useEffect(() => {
        if (_.isEmpty(data)) {
            return;
        }

        dispatch(
            googleMapsDataAction.centralizePoints([
                {
                    lat: data.Latitude ? data.Latitude : 0.0,
                    lng: data.Longitude ? data.Longitude : 0.0,
                },
            ])
        );

        dispatch(
            googleMapsDataAction.updateObjects([
                {
                    ...data,
                    Latitude: data.Latitude ? data.Latitude : 0.0,
                    Longitude: data.Longitude ? data.Longitude : 0.0,
                },
            ])
        );

        return () => {
            dispatch(googleMapsDataAction.cleanObjects());
        };
    }, [dispatch, data]);

    useEffect(() => {
        setTimeout(() => {
            setLoad(false);
        }, 2000);
    });

    return (
        <ExpansionCard
            
            notPadded
            title={"Mapa"}
            className="card-refactor"
            card={"mapa"}
            flex={"1 0 100%"}
            hideAlignments={true}>
            <div className="mapScreen dashboardVehicleMainMap">
                {(loading.getDataVehicle || load) && (
                    <div className="loadingGridData">
                        <Loading size={40} />
                    </div>
                )}
                <MapCard className="map-card-refactor" />
            </div>
        </ExpansionCard>
    );
};

export default MapVehicle;
