import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { Tabs, Tab, Box, Typography } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";

import googleMapsDataAction from "~/actions/googleMapsDataAction";
import mapAction from "~/actions/mapAction";
import exportDocumentAction from "~/actions/exportDocumentAction";

import TabMapVehicle from "./TabMapVehicle";
import TabMapFleet from "./TabMapFleet";
import TabMapAddress from "./TabMapAddress";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import "./Tab.scss";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}>
            {value === index && children}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const TabMap = (props) => {
    const dispatch = useDispatch();
    const containerSerach = useRef(null);
    const [indexTab, setIndexTab] = useState(0);
    const { t } = useTranslation();

    const fleet = useSelector((state) => state.fleet);
    const { filter } = useSelector((state) => state.mapFilterData);
    const fleetVehicle = useSelector((state) => state.fleetVehicle);

    useEffect(() => {
        if (indexTab === 0) {
            dispatch(
                exportDocumentAction.changeExportDocumentFunction(exportDocumentAction.getRastreableObjectVehicle, {
                    somenteSleep: false,
                    filtro: filter.term,
                })
            );
        } else if (indexTab === 1) {
            if (fleetVehicle.filter.id) {
                dispatch(
                    exportDocumentAction.changeExportDocumentFunction(
                        exportDocumentAction.getRastreableObjectOneFleet,
                        {
                            grupoId: fleetVehicle.filter.id,
                        }
                    )
                );
            } else {
                dispatch(
                    exportDocumentAction.changeExportDocumentFunction(exportDocumentAction.getRastreableObjectFleets, {
                        filtro: fleet.filter.description,
                    })
                );
            }
        }

        return () => {
            dispatch(exportDocumentAction.cleanExportDocumentFunction());
        };
    }, [dispatch, indexTab, filter.term, fleet.filter, fleetVehicle.filter]);

    useEffect(() => {
        return () => {
            dispatch(googleMapsDataAction.cleanAll());
            dispatch(mapAction.cleanPointInterestGroup());
            dispatch(mapAction.cleanPointInterest());
            dispatch(mapAction.cleanRoutes());
            dispatch(mapAction.cleanFilter());
        };
    }, [dispatch]);

    return (
        <div className={"tabMain"}>
            <div className={"tabs"}>
                <div style={{ width: props.mode.orientation === "horizontal" ? "100%" : "30%" }}>
                    <Tabs
                        id="groupTab"
                        value={indexTab}
                        onChange={(event, tabIndex) => {
                            setIndexTab(tabIndex);
                        }}
                        indicatorColor="primary">
                        <Tab
                            className={"tabTittle"}
                            label={<Typography id="tabVehicle">{t(Labels.MAP_TAB_VEHICLES)}</Typography>}
                            {...a11yProps(0)}
                        />
                        <Tab
                            className={"tabTittle"}
                            label={<Typography id="tabFleet">{t(Labels.MAP_TAB_FLEETS)}</Typography>}
                            {...a11yProps(1)}
                        />
                        <Tab
                            className={"tabTittle"}
                            label={<Typography id="tabAddress">{t(Labels.MAP_TAB_ADDRESSS)}</Typography>}
                            {...a11yProps(2)}
                        />
                    </Tabs>
                </div>
                <Box style={{ width: props.mode.orientation === "horizontal" ? "0" : "30%" }}>
                    <div ref={containerSerach} />
                </Box>
            </div>

            <TabPanel id="tabVehiclePanel" className={"tabPanel"} value={indexTab} index={0}>
                <TabMapVehicle mode={props.mode} containerSerach={containerSerach} />
            </TabPanel>

            <TabPanel id="tabFleetPanel" className={"tabPanel"} value={indexTab} index={1}>
                <TabMapFleet mode={props.mode} containerSerach={containerSerach} />
            </TabPanel>

            <TabPanel id="tabAddressPanel" className={"tabPanel"} value={indexTab} index={2}>
                <TabMapAddress mode={props.mode} containerSerach={containerSerach} />
            </TabPanel>
        </div>
    );
};

export default TabMap;
