import React from "react";

import { useDispatch, useSelector } from "react-redux";

import CardGroup from "~/components/common/card/CardGroup";
import MenuRange from "~/components/common/menuRange/MenuRange";
import { Grid } from "@material-ui/core";

import alertReportAction from "~/actions/reporting/alertReportAction";
import useFuso from "~/components/common/fuso/useFuso";

import OrderSpan from "~/components/reporting/alerts/AlertViolated/OrderAlert/OrderAlert";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import Utils from "~/helpers/Utils";
import "./ListAlert.scss";

export default function ListAlert() {
    const dispatch = useDispatch();
    const {
        alertReport,
        perObject,
        alertReportFilter,
        perObject: { filters },
    } = useSelector((state) => state.alertReport);
    const { importFileAlert } = useSelector((state) => state.app.loading);
    const { t } = useTranslation();

    const { fromUtc } = useFuso();

    const COLUMNS_ALERTS = [
        {
            name: "Placa",
            labelRender: () => (
                <OrderSpan Name="ObjetoRastreavel.Placa" Data="Placa" value={t(Labels.COLUMN_PLATE)}></OrderSpan>
            ),
            render: (name) => <span>{name}</span>,
        },
        {
            name: "Proprietario",
            labelRender: () => (
                <OrderSpan
                    Name="ObjetoRastreavel.ProprietarioNome"
                    Data="Proprietario"
                    value={t(Labels.COLUMN_OWNER)}></OrderSpan>
            ),
            render: (id) => <span>{id}</span>,
        },
        {
            name: "Marca",
            labelRender: () => (
                <OrderSpan
                    Name="ObjetoRastreavel.Marca"
                    Data="Marca"
                    value={t(Labels.COLUMN_BRAND_OR_MODEL)}></OrderSpan>
            ),
            render: (id) => <span>{id}</span>,
        },
        {
            name: "MotoristaNome",
            labelRender: () => (
                <OrderSpan
                    Name="ObjetoRastreavel.MotoristaPadraoNome"
                    Data="MotoristaPadraoNome"
                    value={t(Labels.COLUMN_DRIVER)}></OrderSpan>
            ),
            render: (id) => <span>{id}</span>,
        },
        {
            name: "AlertaNome",
            labelRender: () => (
                <OrderSpan Name="Violacao.AlertaNome" Data="AlertaNome" value={t(Labels.COLUMN_ALERT)}></OrderSpan>
            ),
            render: (id) => <span>{id}</span>,
        },
        {
            name: "DataHoraEvento",
            labelRender: () => (
                <OrderSpan
                    Name="Violacao.DataHoraEvento"
                    Data="DataHoraEvento"
                    value={t(Labels.COLUMN_EVENT_DATE)}></OrderSpan>
            ),
            render: (date) => (
                <span>
                    <div> {date ? fromUtc(date)?.format("DD/MM/YYYY | HH:mm:ss") : "-"} </div>
                </span>
            ),
        },
        {
            name: "DataHoraViolacao",
            labelRender: () => (
                <OrderSpan
                    Name="Violacao.DataHoraEvento"
                    Data="DataHoraEvento"
                    value={t(Labels.COLUMN_REGISTER_DATE)}></OrderSpan>
            ),
            render: (date) => (
                <span>
                    <div> {date ? fromUtc(date)?.format("DD/MM/YYYY | HH:mm:ss") : "-"} </div>
                </span>
            ),
        },
        {
            name: "UsuarioConclusaoNome",
            labelRender: () => (
                <OrderSpan
                    Name="Violacao.UsuarioConclusaoNome"
                    Data="UsuarioConclusaoNome"
                    value={t(Labels.COLUMN_FINISHED_BY)}></OrderSpan>
            ),
            render: (name) => <span>{!!name ? name : "-"}</span>,
        },
        {
            name: "DataHoraConclusao",
            labelRender: () => (
                <OrderSpan
                    Name="Violacao.DataHoraEvento"
                    Data="DataHoraEvento"
                    value={t(Labels.COLUMN_FINISH_DATE)}></OrderSpan>
            ),
            render: (date) => (
                <span>
                    <div> {date ? fromUtc(date)?.format("DD/MM/YYYY | HH:mm:ss") : "-"} </div>
                </span>
            ),
        },
        {
            name: "Velocidade",
            labelRender: () => (
                <OrderSpan Name="Velocidade" Data="Velocidade" value={t(Labels.COLUMN_SPEED_ABBREVIATED)}></OrderSpan>
            ),
            render: (value) => (
                <span>
                    <div>{Utils.formatSpeed(value)}</div>
                </span>
            ),
        },
        {
            name: "RevGeo",
            labelRender: () => (
                <OrderSpan Name="Velocidade" Data="Velocidade" value={t(Labels.COLUMN_LOCATION)}></OrderSpan>
            ),
            render: (value) => <span>{value ? value : ""}</span>,
        },
        {
            name: "Permanencia",
            labelRender: () => (
                <OrderSpan Name="Velocidade" Data="Velocidade" value={t(Labels.COLUMN_STAY)}></OrderSpan>
            ),
            render: (value) => (
                <span>
                    <div>{value}</div>
                </span>
            ),
        },
    ];

    return (
        <Grid container className="cardsAlertsListsReport">
            <CardGroup
                data-id={"cadastrar-alertas-id"}
                title={"Alertas"}
                className="card-alerts-list card-group-refactor"
                classHeader={"search-group-item"}
                loading={importFileAlert}
                columns={COLUMNS_ALERTS}
                source={alertReportFilter.length > 0 ? alertReportFilter : alertReport.data}
                page={filters.GridRequest.Index + 1}
                itemsPerPage={filters.GridRequest.Length}
                totalItems={alertReport.recordsTotal}
                hideSearch
                handlePage={(event, value) => {
                    const query = {
                        ...perObject.filters.GridRequest,
                        Index: value - 1,
                    };
                    dispatch(
                        alertReportAction.updateFilterAlertPagination({
                            ...query,
                        })
                    );
                }}
                select={(value) => {
                    dispatch(alertReportAction.cleanAlertDetails());
                    dispatch(
                        alertReportAction.updateAlertDetail(
                            {
                                alertaId: value.AlertaId,
                                placa: value.Placa,
                                dataHoraViolacao: value.DataHoraViolacao,
                                violacaoId: value.Id,
                            },
                            (res) => {
                                dispatch(alertReportAction.getHistory({ violacaoId: res.Id }));
                            }
                        )
                    );
                }}
                resize
                headerComp={
                    <div>
                        <MenuRange
                            label={t(Labels.MENU_RANGE_SHOW)}
                            className="menu-range-refactor"
                            classNameRefactor="menu-label-range-refactor"
                            value={filters.GridRequest.Length}
                            onChange={(event) => {
                                const value = event.target.value;
                                const query = {
                                    Index: 0,
                                    Length: value,
                                    SearchColumns: [
                                        {
                                            Name: "Violacao.Id",
                                            Data: "function",
                                            OrderTable: true,
                                            OrderDirection: "desc",
                                        },
                                    ],
                                };
                                dispatch(alertReportAction.updateFilterAlertPagination({ ...query }));
                            }}
                            options={[10, 25, 50, 100, 250, 500]}
                        />
                    </div>
                }
            />
        </Grid>
    );
}
