import i18next from "i18next";
import Labels from "~/helpers/enums/Labels";

export const resolveValue = (value, identifier = "Codigo") => {
    const [AlertaRegraId, AlertaRegraTipoId] = value[identifier].split("_");
    const defaultValue = {
        ...value,
        AlertaRegraId: parseInt(AlertaRegraId),
        AlertaRegraTipoId: parseInt(AlertaRegraTipoId),
    };

    switch (defaultValue.AlertaRegraTipoId) {
        case 1:
            return {
                ...defaultValue,
                Ativado: true,
            };
        case 2:
            switch (defaultValue.AlertaRegraId) {
                case 48:
                    return {
                        ...defaultValue,
                        Operacao: "=",
                        Valor: "pf",
                    };
                case 40:
                    return {
                        ...defaultValue,
                        validate: (val) => {
                            if (!val.DataLimite)
                                return i18next.t(Labels.ALERT_FORM_CONDITONS_ERROR_REQUIRED_LIMIT_DATE);
                            return false;
                        },
                        Operacao: ">",
                    };
                case 11:
                    return {
                        ...defaultValue,
                        validate: (val) => {
                            if (!val.Valor) return i18next.t(Labels.ALERT_FORM_CONDITONS_ERROR_REQUIRED_VALUE);
                            return false;
                        },
                        VelocidadeAcima: true,
                        Operacao: ">"
                    };
                case 35:
                case 36:
                case 46:
                case 49:
                default:
                    return {
                        ...defaultValue,
                        validate: (val) => {
                            if (!val.Valor) return i18next.t(Labels.ALERT_FORM_CONDITONS_ERROR_REQUIRED_VALUE);
                            return false;
                        },
                        Operacao: ">",
                    };
            }
        case 4:
            return {
                ...defaultValue,
                validate: (val) => {
                    if (!val.Valor) return i18next.t(Labels.ALERT_FORM_CONDITONS_ERROR_REQUIRED_TEXT);
                    return false;
                },
            };
        case 5:
            return {
                ...defaultValue,
                validate: (val) => {
                    if (!val.RotaId) return i18next.t(Labels.ALERT_FORM_CONDITONS_ERROR_REQUIRED_ROUTE);
                    return false;
                },
                DentroRota: true,
                Operacao: "DENTRO",
            };
        case 10:
            return {
                ...defaultValue,
                validate: (val) => {
                    if (!val.PontoInteresseId)
                        return i18next.t(Labels.ALERT_FORM_CONDITONS_ERROR_REQUIRED_INTEREST_POINT);
                    return false;
                },
                DentroPontoInteresse: true,
                Operacao: "DENTRO",
            };
        case 11:
            return {
                ...defaultValue,
                validate: (val) => {
                    if (!val.GrupoInteresseId)
                        return i18next.t(Labels.ALERT_FORM_CONDITONS_ERROR_REQUIRED_GROUP_INTEREST_POINT);
                    return false;
                },
                DentroGrupoInteresse: true,
                Operacao: "DENTRO",
            };
        case 12:
            return {
                ...defaultValue,
                validate: (val) => {
                    if (!val.Valor) return i18next.t(Labels.ALERT_FORM_CONDITONS_ERROR_REQUIRED_VALUE);

                    if (val.Valor < 5 || val.valor > 1440)
                        return i18next.t(Labels.ALERT_FORM_CONDITONS_ERROR_VALID_MIN_MAX);

                    return false;
                },
            };
        case 13:
            return {
                ...defaultValue,
                Valor: "1",
                validate: (val) => {
                    if (!val.Valor) return i18next.t(Labels.ALERT_FORM_CONDITONS_ERROR_REQUIRED_VALUE);
                    return false;
                },
            };
        case 14:
            return {
                ...defaultValue,
                Operacao: "DENTRO",
                DentroPavimento: true,
                validate: (val) => {
                    if (!val.Valor) return i18next.t(Labels.ALERT_FORM_CONDITONS_ERROR_REQUIRED_VALUE);
                    return false;
                },
            };
        default:
            return defaultValue;
    }
};
