import { Button, Grid } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { darken, lighten } from "polished";
import React, { useEffect } from "react";
import Card from "~/components/common/expansionCard/Card";
import { WhiteTrashIcon, ForwardIcon } from "~/components/common/icons/Icons";
import MenuRange from "~/components/common/menuRange/MenuRange";
import Search from "~/components/common/search/Search";
import TableCardReUse from "~/components/tableCard/TableCardReUse";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

import './DualList.scss'

const DualList = (props) => {
	const [availableData, setAvailableData] = React.useState([]);
	const [linkedData, setLinkedData] = React.useState([]);

	const [availableSelected, setAvailableSelected] = React.useState([]);
	const [linkedSelected, setLinkedSelected] = React.useState([]);

	const [availableSearch, setAvailableSearch] = React.useState("");
	const [linkedSearch, setLinkedSearch] = React.useState("");

	const [availablePagination, setAvailablePagination] = React.useState({ length: 10, current: 1 });
	const [linkedPagination, setLinkedPagination] = React.useState({ length: 10, current: 1 });

	useEffect(() => {
		setAvailableData(props.tableAvailable.dataSource);
	}, [props.tableAvailable.dataSource]);

	useEffect(() => {
		setLinkedData(props.tableLinked.dataSource);
	}, [props.tableLinked.dataSource]);

	const { t } = useTranslation();

	const StyledButton = withStyles((theme) => ({
		root: {
			background: lighten(0, props.tableLinked.backgroundColor || "#6EC8AF"),
			borderRadius: 3,
			border: 0,
			color: "white",
			height: 28,
			padding: "0 10px",
			width: "100%",
			"&:hover": {
				background: darken(0.1, props.tableLinked.backgroundColor || "#6EC8AF"),
			},
		},
		label: {
			textAlign: "right",
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			fontSize: "16px",
			fontWeight: "bold",
		},
	}))(Button);

	const useStyles = makeStyles((theme) => ({
		tableNoColor: {
			marginRight: 0,
			"& .topButtons": {
				top: 25,
			},
		},
		tableColor: {
			marginLeft: 0,
			background: lighten(0.5, props.tableLinked.backgroundColor || "#6EC8AF"),
			"& .MuiPaper-root": {
				background: lighten(0.5, props.tableLinked.backgroundColor || "#6EC8AF"),
			},
			"& thead th, & tbody tr:nth-of-type(2n+0)": {
				background: "#fff",
			},
			"& tr:hover": {
				background: `${lighten(0.4, props.tableLinked.backgroundColor || "#6EC8AF")} !important`,
			},
			"& .topButtons": {
				top: 25,
				"& .MuiPaper-root": {
					background: `${lighten(0.4, props.tableLinked.backgroundColor || "#6EC8AF")} !important`,
				},
			},
		},
		duaList: {},
	}));

	const getSelectedByRefColName = (refColName = "", list = [], namesSelected = [""]) => {
		return _.filter(list, (item) => {
			let flag = false;
			namesSelected.forEach((name) => {
				if (name === item[refColName]) flag = true;
			});
			return flag;
		});
	};

	const moveData = (data, { origin, fnUpdateOrigin }, { destiny, fnUpdateDestiny }) => {
		const addedList = destiny.concat(data);
		fnUpdateDestiny(addedList);
		const listRemoved = _.difference(origin, data);
		fnUpdateOrigin(listRemoved);
	};

	const handlerAddLinked = () => {
		const { refColName } = props.tableAvailable.rowSelection;
		const selected = getSelectedByRefColName(refColName, availableData, availableSelected);
		props.actionAdd(selected);

		setAvailableSelected([]);
		setLinkedSelected([]);
		moveData(
			selected,
			{ origin: availableData, fnUpdateOrigin: setAvailableData },
			{ destiny: linkedData, fnUpdateDestiny: setLinkedData }
		);
	};

	const handlerRemoveLinked = () => {
		const { refColName } = props.tableLinked.rowSelection;
		const selected = getSelectedByRefColName(refColName, linkedData, linkedSelected);
		props.actionRemove(selected);

		setAvailableSelected([]);
		setLinkedSelected([]);

		moveData(
			selected,
			{ origin: linkedData, fnUpdateOrigin: setLinkedData },
			{ destiny: availableData, fnUpdateDestiny: setAvailableData }
		);
	};

	const handlerChangeAvailable = (selectedRows) => {
		setAvailableSelected(selectedRows);
	};

	const handlerChangeLinked = (selectedRows) => {
		setLinkedSelected(selectedRows);
	};

	const handlerSearchLinked = (search = "") => {
		const pagination = {
			...linkedPagination,
			current: 1,
		};
		setLinkedSearch(search);
		setLinkedPagination(pagination);
		props.tableLinked.onChange && props.tableLinked.onChange(search, pagination);
	};

	const handlerSearchAvailable = (search = "") => {
		const pagination = {
			...availablePagination,
			current: 1,
		};
		setAvailableSearch(search);
		setAvailablePagination(pagination);
		props.tableAvailable.onChange && props.tableAvailable.onChange(search, pagination);
	};

	const changeRangePaginationLinked = (length) => {
		const pagination = {
			current: 1,
			length,
		};
		setLinkedPagination(pagination);
		props.tableLinked.onChange && props.tableLinked.onChange(linkedSearch, pagination);
	};
	const changeRangePaginationAvailable = (length) => {
		const pagination = {
			current: 1,
			length,
		};
		setAvailablePagination(pagination);
		props.tableAvailable.onChange && props.tableAvailable.onChange(availableSearch, pagination);
	};

	const changePageLinked = (e, current) => {
		const pagination = {
			...linkedPagination,
			current,
		};
		setLinkedPagination(pagination);
		props.tableLinked.onChange && props.tableLinked.onChange(linkedSearch, pagination);
	};
	const changePageAvailable = (e, current) => {
		const pagination = {
			...availablePagination,
			current,
		};
		setAvailablePagination(pagination);
		props.tableAvailable.onChange && props.tableAvailable.onChange(availableSearch, pagination);
	};

	const classes = useStyles();
	return (
		<div className={`${classes.duaList} dualList`}>
			<Grid container justify="right" alignItems="flex-start" spacing={0}>
				<Grid item xs={6} padding="none">
					<Card
						title={props.tableAvailable.title}
						classTitle="boxTitle-refactor"
						key={props.tableAvailable.key}
						className={classes.tableNoColor}
						titleButtons={
							<>
								<Search id={`search-${props.tableLinked.title}`} classNameRefactor="search-refactor" search={handlerSearchAvailable} />
								<div style={{ marginLeft: "10px" }}>
									<MenuRange
										classNameRefactor="menu-label-range-refactor"
										label={t(Labels.MENU_RANGE_SHOW)}
										value={availablePagination.length}
										onChange={(event) => changeRangePaginationAvailable(event.target.value)}
										options={[10, 25, 50, 100, 250, 500]}
									/>
								</div>
							</>
						}>
						<TableCardReUse
							columns={props.tableAvailable.columns}
							key="1"
							checkBox={true}
							className={classes.table}
							rowSelection={{
								refColName: props.tableAvailable.rowSelection.refColName,
								onChange: handlerChangeAvailable,
							}}
							dataSource={availableData}
							loading={props.tableAvailable.loading}
							page={availablePagination.current }
							itemsPerPage={availablePagination.length}
							totalItems={props.tableAvailable.pagination.totalItems}
							handlePage={changePageAvailable}
						/>
						<StyledButton onClick={handlerAddLinked}>
							{t(Labels.DUAL_LIST_ADD_SELECTED)} <ForwardIcon style={{ height: 24 }} />
						</StyledButton>
					</Card>
				</Grid>
				<Grid item xs={6} padding="none">
					<Card
						title={props.tableLinked.title}
						classTitle="boxTitle-refactor"
						key={props.tableLinked.key}
						//className={classes.tableColor}
						titleButtons={
							<>
								<Search
									id={`search-${props.tableLinked.title}`}
									search={handlerSearchLinked}
									style={{ backgroundColor: "#fcfefd" }}
									classNameRefactor="search-refactor"
								/>
								<div style={{ marginLeft: "10px", backgroundColor: "#fcfefd" }}>
									<MenuRange
										classNameRefactor="menu-label-range-refactor"
										label={t(Labels.MENU_RANGE_SHOW)}
										value={linkedPagination.length}
										onChange={(event) => changeRangePaginationLinked(event.target.value)}
										options={[10, 25, 50, 100, 250, 500]}
									/>
								</div>
							</>
						}>
						<TableCardReUse
							columns={props.tableLinked.columns}
							key="2"
							checkBox={true}
							rowSelection={{
								refColName: props.tableLinked.rowSelection.refColName,
								onChange: handlerChangeLinked,
							}}
							dataSource={linkedData}
							loading={props.tableLinked.loading}
							page={linkedPagination.current}
							itemsPerPage={linkedPagination.length}
							totalItems={props.tableLinked.pagination.totalItems}
							handlePage={changePageLinked}
						/>
						<StyledButton onClick={handlerRemoveLinked}>
							{t(Labels.DUAL_LIST_REMOVE_SELECTED)}
							<WhiteTrashIcon style={{ height: 24, width: 24, margin: "4px 0 0 5px" }} />
						</StyledButton>
					</Card>
				</Grid>
			</Grid>
		</div>
	);
};

export default DualList;
