import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import googleMapsDataAction from "~/actions/googleMapsDataAction";
import eventReportAction from "~/actions/reporting/eventReportAction";

import Portal from "@material-ui/core/Portal";
import Utils from "~/helpers/Utils";
import useFuso from "~/components/common/fuso/useFuso";
import * as _ from "lodash";
import TableCard from "~/components/tableCard/TableCard";
import Search from "~/components/common/search/Search";
import Pagination from "~/components/common/pagination/Pagination";
import Loading from "~/components/common/loading/Loading";
import MenuRange from "~/components/common/menuRange/MenuRange";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import { EVENTS_OBJECT_COLUMNS } from "./Enum/GridResultEnum";

const GridResultObject = (props) => {
    const dispatch = useDispatch();

    const { fromUtc } = useFuso();
    const { t } = useTranslation();

    const { requestObject } = useSelector((state) => state.app.loading);

    const {
        perObject: { filters, data },
    } = useSelector((state) => state.eventReport);

    useEffect(() => {
        if (_.isEmpty(filters.period)) {
            return;
        }

        dispatch(eventReportAction.requestPerObject(filters));
    }, [dispatch, filters]);

    useEffect(() => {
        dispatch(googleMapsDataAction.updateEvents(data.data));

        return () => {
            dispatch(googleMapsDataAction.cleanEvents());
        };
    }, [dispatch, data]);

    const FilterDiv = () => (
        <>
            <Search
                id="search-events"
                className={"search search-input-refactor"}
                value={filters.search || ""}
                style={{ margin: 0 }}
                search={(search) => {
                    dispatch(
                        eventReportAction.updateFilterEventObject({
                            ...filters,
                            search,
                        })
                    );
                }}
            />
            <div style={{ marginLeft: "10px" }}>
                <MenuRange
                    id={"report-events"}
                    label={t(Labels.MENU_RANGE_SHOW)}
                    className="menu-range-refactor"
                    classNameRefactor="menu-label-range-refactor"
                    value={filters.limit}
                    onChange={(event) => {
                        dispatch(
                            eventReportAction.updateFilterEventObject({
                                ...filters,
                                index: 0,
                                limit: event.target.value,
                            })
                        );
                    }}
                    options={[10, 25, 50, 100, 250, 500]}
                />
            </div>
        </>
    );

    return (
        <div className="contentCard">
            {props.mode.orientation === "horizontal" ? (
                <div className="spaceSearchReportObject">
                    <FilterDiv />
                </div>
            ) : (
                <Portal container={props.refSerachDiv.current}>
                    <div className="spaceSearchReportObjectPortal">
                        <FilterDiv />
                    </div>
                </Portal>
            )}
            {requestObject ? (
                <div id="loading-object">
                    <Loading />
                </div>
            ) : (
                <TableCard
                    mode={props.mode}
                    filters={EVENTS_OBJECT_COLUMNS}
                    className="content-table-card"
                    data={Utils.filterData(data.data || [], filters.search || "").map((obj) => {
                        return {
                            ...obj,
                            Placa: obj.Identificacao,
                            PavimentoTipo: obj.TipoPavimento,
                            Velocidade: Utils.formatSpeed(obj.Velocidade),
                            TensaoBateria: Utils.formatVoltage(obj.TensaoBateria),
                            Data: fromUtc(obj.Data, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY | HH:mm:ss"),
                        };
                    })}
                    onItemClick={(element) => {
                        dispatch(
                            googleMapsDataAction.centralizePoints([
                                {
                                    lat: parseFloat(element.Latitude.replace(",", ".")),
                                    lng: parseFloat(element.Longitude.replace(",", ".")),
                                },
                            ])
                        );
                    }}
                />
            )}
            {requestObject ? null : (
                <div className="pagination-tabmap">
                    <Pagination
                        onChange={(event, value) => {
                            dispatch(
                                eventReportAction.updateFilterEventObject({
                                    ...filters,
                                    index: value - 1,
                                })
                            );
                        }}
                        page={filters.index + 1}
                        count={Math.ceil(data.recordsTotal / filters.limit)}
                    />
                </div>
            )}
        </div>
    );
};

export default GridResultObject;
