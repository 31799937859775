import Constants from "~/helpers/enums/Constants";

import tutorialService from "~/services/tutorialService";

const tutorialGetData = () => (dispatch) => {
	return tutorialService
		.getTutorialData()
		.then((response) => {
			let payload = response.data;

			dispatch({ type: Constants.TUTORIAL_GET_DATA, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {});
};

const updateTutorialDataFilter = (arr, searchKey) => (dispatch) => {
	
	const arrFiltred =  arr.filter((obj) => Object.keys(obj).some((key) => obj[key].includes(searchKey)));
	dispatch({ type: Constants.TUTORIAL_FILTER_DATA, payload: arrFiltred });
};

export default {
	tutorialGetData,
	updateTutorialDataFilter,
};
