import React from "react";

import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

import { useHistory } from "react-router-dom";

import { Container, Grid, Button } from "@material-ui/core";
import { ComeBackIcon } from "~/components/common/icons/Icons";
import Page from "~/components/common/page/Page";
import PointsImport from "~/components/pointsInterest/import/PointsImport";
import "./PointsImportView.scss";

const url_back = "/register/pointsInterest/list";

const PointsImportView = () => {
    const { t } = useTranslation();
    let history = useHistory();

    return (
        <>
            <Page
                topButtons={
                    <Button
                        data-id="btn-back-import-driver"
                        className="comeback-button comeback-top"
                        variant="contained"
                        size="small"
                        disableElevation
                        startIcon={<ComeBackIcon />}
                        onClick={() => {
                            history.push(url_back);
                        }}>
                        {t(Labels.REGISTER_DRIVER_COMEBACK)}
                    </Button>
                }
                bottomStyle={{ justifyContent: "flex-end" }}
                bottomButtons={
                    <Button
                        data-id="btn-back-import-driver"
                        className="comeback-button"
                        variant="contained"
                        size="small"
                        disableElevation
                        startIcon={<ComeBackIcon />}
                        onClick={() => {
                            history.push(url_back);
                        }}>
                        {t(Labels.REGISTER_DRIVER_COMEBACK)}
                    </Button>
                }>
                <main>
                    <Container maxWidth="lg" className="container-map-view">
                        <Grid container>
                            <Grid id="points-import-view-grid" item xs={12}>
                                <PointsImport />
                            </Grid>
                        </Grid>
                    </Container>
                </main>
            </Page>
        </>
    );
};

export default PointsImportView;
