import { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { getLatLngPathsFromString } from "~/helpers/Utils";
import GeoJSONReader from "jsts/org/locationtech/jts/io/GeoJSONReader";
import GeoJSONWriter from "jsts/org/locationtech/jts/io/GeoJSONWriter";
import { BufferOp } from "jsts/org/locationtech/jts/operation/buffer";

const RouteSyncMap = (props) => {

	const [directionsService, setDirectionsService] = useState(null);
	const [directionsRenderer, setDirectionsRenderer] = useState(null);
	const {
		user: { colors },
	} = useSelector((state) => state.auth);

	const { values, setFieldValue } = props;
	const { Origem, Destino, Coordenadas, Raio, WayPoints } = values;
	const { mapContext } = useSelector((state) => state.googleMapsData);

	const svgOptions = useMemo(() => {
		return {
			fillColor: colors.colorPanels,
			fillOpacity: 0.2,
			strokeColor: colors.colorPanels,
			strokeWeight: 2,
			strokeOpacity: 0.8,
			zIndex: 1,
		};
	}, [colors]);

	const updateValues = useCallback(
		(response) => {
			const _route = response.routes[0];
			const _waypoints = response.request.waypoints || [];

			const latlngToText = (latlngarr) => latlngarr.map((latlng) => `${latlng.lat()},${latlng.lng()}`).join(";");

			setFieldValue("Coordenadas", latlngToText(_route.overview_path));
			setFieldValue("Distancia", _route.legs[0].distance.text);
			setFieldValue(
				"WayPoints",
				latlngToText(
					_waypoints.map((w) => {
						if (w.location.lat) return w.location;
						return w.location.location;
					})
				)
			);
		},
		[setFieldValue]
	);

	useEffect(() => {
		if (!mapContext) return;

		const _directionsService = new window.google.maps.DirectionsService();
		const _directionsRenderer = new window.google.maps.DirectionsRenderer({
			suppressMarkers: false,
			draggable: true,
			polylineOptions: {
				strokeColor: colors.topBarColor,
				strokeWeight: 5,
				zIndex: 3,
			},
		});
		_directionsRenderer.setMap(mapContext);
		_directionsRenderer.addListener("directions_changed", () => {
			updateValues(_directionsRenderer.getDirections());
		});

		setDirectionsRenderer((old) => {
			old && old.setMap(null);
			return _directionsRenderer;
		});
		setDirectionsService(_directionsService);
	}, [mapContext, updateValues, colors]);

	useEffect(() => {
		if (!Origem.sugestion || !Destino.sugestion || !directionsRenderer) return;

		directionsService.route(
			{
				origin: {
					query: Origem.sugestion.description,
				},
				destination: {
					query: Destino.sugestion.description,
				},
				travelMode: window.google.maps.TravelMode.DRIVING,
				waypoints: getLatLngPathsFromString(WayPoints).map((w) => {
					return {
						location: new window.google.maps.LatLng(w.lat, w.lng),
						stopover: false,
					};
				}),
				optimizeWaypoints: true,
			},
			(response, status) => {
				if (status === "OK") {
					directionsRenderer.setDirections(response);
					updateValues(response);
				}
			}
		);
	}, [Origem, Destino, directionsRenderer, directionsService, updateValues, WayPoints]);

	const inflatePath = useCallback((path, raio) => {
		const pathgeo = path.map((p) => [p.lng, p.lat]);
		var distance = (raio * 0.001) / 111.12; // Roughly 10km

		const geoReader = new GeoJSONReader();
		const geoWriter = new GeoJSONWriter();

		const geometry = geoReader.read({
			type: "LineString",
			coordinates: pathgeo,
		});

		let spBuffer = BufferOp.bufferOp(geometry, distance);

		return geoWriter.write(spBuffer);
	}, []);

	useEffect(() => {
		if (!Coordenadas || !Raio || !mapContext) return;

		const polygon = inflatePath(getLatLngPathsFromString(Coordenadas), Raio);
		const paths = polygon.coordinates[0].map((c) => {
			return {
				lng: c[0],
				lat: c[1],
			};
		});

		const _fence = new window.google.maps.Polygon({
			...svgOptions,
			map: mapContext,
			paths: paths,
		});

		setFieldValue("Cerca", paths.map((latlng) => `${latlng.lat},${latlng.lng}`).join(";"));

		return () => {
			_fence.setMap(null);
		};
	}, [Coordenadas, Raio, svgOptions, inflatePath, mapContext, setFieldValue]);

	return null;
};

export default RouteSyncMap;
