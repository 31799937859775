import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ClickAwayListener, Grow, Paper, Popper, MenuList, IconButton } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
	},
	paper: {
		width: "130px",
		padding: "5px 0",
	},
	menu: {
		padding: 0,
	},
}));

export default function Dropdown(props) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleToggle = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleClose = (event) => {
		setAnchorEl(null);
	};

	return (
		<div className={classes.root}>
			<IconButton style={{ padding: "3px", margin: "3px", minWidth: "0" }} onClick={handleToggle}>
				<ExpandMore size={20} style={{ color: "#3D5564" }} />
			</IconButton>

			<Popper open={!!anchorEl} anchorEl={anchorEl} role={undefined} transition disablePortal placement="bottom-end">
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
						<Paper className={classes.paper}>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									autoFocusItem={!!anchorEl}
									className={classes.menu}
									onKeyDown={(event) => {
										if (event.key === "Tab") {
											event.preventDefault();
											setAnchorEl(null);
										}
									}}>
									{props.children}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
}
