import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import driverService from "~/services/driverService";
import Labels from "~/helpers/enums/Labels";

const driverData = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "driverDataSearch") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return driverService
		.list(filter)
		.then((response) => {
			let data = response.data;
			let payload = {
				data: data,
			};

			dispatch({ type: Constants.GET_DATA_DRIVER, payload });
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const saveDriver = (driver, callback = () => {}, LOADING_IDENTIFICATOR = "driver") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	const saveDisptach = () => {
		return driverService
			.saveDriver(driver)
			.then(() => {
				callback(false);
			})
			.catch((error) => {
				callback(error);
			})
			.finally(() => {
				dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
			});
	};

	if (driver.Id) {
		return saveDisptach();
	}

	return driverService
		.getDriverByCpf(driver.Cpf)
		.then((response) => {
			if (!response.data.data) {
				saveDisptach();
			} else {
				callback(Labels.REGISTER_DRIVER_FORM_MSG_CPF_ALREADY_EXISTS);
				dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
			}
		})
		.catch((error) => {
			callback(error);
		});
};

const getDriverById = (id, callback = () => {}, LOADING_IDENTIFICATOR = "driver") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	return driverService
		.getDriverById(id)
		.then((response) => {
			const driver = response.data;
			dispatch({ type: Constants.GET_DRIVER_BY_ID, payload: { driver } });
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

//Search para o todos os valores, excluindo paginação

const searchDriverList = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "requestGridRegisterDriver") => (
	dispatch
) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return driverService
		.searchDriverList(filter)
		.then((response) => {
			let { index, recordsTotal, recordsFiltered, data } = response.data;
			const page = {
				index,
				recordsTotal,
				recordsFiltered,
			};
			dispatch({ type: Constants.GET_DATA_DRIVER_SEARCH, payload: { data: data, page, searchKey: filter.Search, status: filter.status} });
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const updatePaginationRegisterDriver = (pagination, status) => (dispatch) => {
	let filter = {};
	filter.status = status;
	filter.Index = pagination.Index;
	filter.Length = pagination.Length;
	filter.Search = pagination.Search

	dispatch(searchDriverList(filter));
	dispatch({ type: Constants.UPDATE_PAGINATION_REGISTER_DRIVER, payload: { pagination } });
};

const cleanDriver = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_DRIVER });
};

export default {
	driverData,
	saveDriver,
	cleanDriver,
	getDriverById,
	searchDriverList,
	updatePaginationRegisterDriver,
};
