import React, { useEffect, useState } from "react";
import { Button, IconButton, InputAdornment, Popover, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import dateFns from "date-fns";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import reportingAction from "~/actions/reportingAction";
import { DatePickerIcon } from "~/components/common/icons/Icons";
import RangeDatePicker from "~/components/common/rangeDatePicker/RangeDatePicker";

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: "none",
    },
    paper: {
        // padding: theme.spacing(1),
    },
    text: {
        background: "#eef2f5 0% 0% no-repeat padding-box",
        borderRadius: "3px",
        opacity: "1",
        width: "max-content",
        "& > div": {
            paddingRight: 0,
        },
    },
    datePicker: {
        paddingLeft: "0px",
    },
    typography: {
        textAlign: "left",
        font: "Bold 14px/17px Trebuchet MS",
        letterSpacing: " 0px",
        color: "#3D5564",
        opacity: "1",
    },
    divIcon: {
        backgroundColor: theme.palette.primary.main,
        minHeight: "100%",
        height: "100%",
        justifyContent: "center",
        borderRadius: "3px",
    },
    boxInputs: {
        display: "flex",
        width: "100%",
        flexFlow: "nowrap",
        borderRadius: "9px 0 0 9px",
        "& > div:first-child, & > div:first-child > div": {
            borderRadius: "3px 0 0 3px",
            "& fieldset": {
                borderRight: "none",
            },
        },
        "& > div:last-child, & > div:last-child > div": {
            borderRadius: "0 3px 3px 0",
            "& fieldset": {
                borderLeft: "none",
            },
        },
    },
}));

const DatePickerFilter = (props) => {
    const { handleChangeAuto, disabled} = props;

    const dispatch = useDispatch();
    const classes = useStyles();

    const { clearAll } = useSelector((state) => state.reporting);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const [period, setPeriod] = useState(() => {
        const startDate = new Date();
        const endDate = new Date();

        startDate.setHours(0, 0, 0);
        endDate.setHours(23, 59, 59);

        return {
            startDate: startDate,
            endDate: endDate,
        };
    });

    const handleRequestClose = (ev) => {
        setOpen(false);
        setAnchorEl(null);
    };

    useEffect(() => {
        if (clearAll) {
            dispatch(reportingAction.clearAll(false));

            const startDate = new Date();
            const endDate = new Date();

            startDate.setHours(0, 0, 0);
            endDate.setHours(23, 59, 59);

            setPeriod({
                startDate: startDate,
                endDate: endDate,
            });
        }
    }, [dispatch, clearAll]);

    const handleTouchTap = (ev) => {
        setOpen(true);
        setAnchorEl(ev.currentTarget);
    };

    const handl2 = (ev) => {
        ev.preventDefault();
        handleTouchTap(ev);
        return null;
    };

    return (
        <div style={props.style}>
            <div className={classes.boxInputs}>
                <TextField
                    value={moment(period.startDate).format("DD-MM-YYYY HH:mm:ss")}
                    disabled={disabled}
                    inputProps={{
                        maxLength: 115,
                    }}
                    className={classes.text}
                    variant="outlined"
                    onClick={!disabled && handl2}
                    margin="dense"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    value={moment(period.endDate).format("DD-MM-YYYY HH:mm:ss")}
                    disabled={disabled}
                    inputProps={{
                        maxLength: 115,
                        className: classes.datePicker,
                    }}
                    className={classes.text}
                    variant="outlined"
                    onClick={!disabled && handl2}
                    margin="dense"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" className={classes.divIcon}>
                                <IconButton>
                                    <DatePickerIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}>
                <Button onClick={handleRequestClose}>
                    <HighlightOffIcon />
                </Button>

                <RangeDatePicker
                    maxDate={new Date()}
                    hours
                    dontShowMonthRanges={props.dontShowMonthRanges}
                    open={true}
                    value={period}
                    onChange={(range) => {
                        setPeriod(range);

                        handleChangeAuto("date", {
                            DataInicial: dateFns.format(range.startDate),
                            DataFinal: dateFns.format(range.endDate),
                        });

                        handleRequestClose();
                    }}
                />
            </Popover>
        </div>
    );
};

export default DatePickerFilter;
