import React, { useState, useRef } from "react";
import ExpansionCard from "~/components/common/expansionCard/ExpansionCard";
import ExpansionGrid from "~/components/common/expansionCard/ExpansionGrid";
import ExpansionCardEnum from "~/components/common/expansionCard/ExpansionCardEnum";
import MapCard from "~/components/map/MapCard";
import GridResultsObject from "~/components/reporting/events/perObject/GridResultsObject";
import { ObjectPanel } from "~/components/common/reportTab/ReportPanel";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import "./ReportEvent.scss";

const EventsObject = () => {
    const [alignment, setAlignment] = useState(ExpansionCardEnum.HORIZONTAL);
    const { t } = useTranslation();
    const refSerachDiv = useRef(null);

    return (
        <ObjectPanel>
            <ExpansionGrid alignment={alignment} fnClick={setAlignment}>
                <ExpansionCard
                    card={"relatorioEventos"}
                    title={t(Labels.RESULTS)}
                    flex={"0 1 30%"}
                    key={"1"}
                    alignment={alignment}
                    className={"eventsObjectList card-refactor"}
                    fnClick={setAlignment}
                    refTitleDiv={refSerachDiv}>
                    <GridResultsObject mode={alignment} refSerachDiv={refSerachDiv}/>
                </ExpansionCard>
                <ExpansionCard
                    card={"relatorioEventosMapa"}
                    notPadded
                    title={t(Labels.ROUTE_MAP)}
                    flex={"0 1 70%"}
                    key={"2"}
                    alignment={alignment}
                    fnClick={setAlignment}
                    className="eventsObjectMap card-refactor">
                    <div className="card-report-map">
                        <MapCard className="map-card-refactor" />
                    </div>
                </ExpansionCard>
            </ExpansionGrid>
        </ObjectPanel>
    );
};

export default EventsObject;
