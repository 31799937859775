import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import Utils from "~/helpers/Utils";
import ExpansionCard from "~/components/common/expansionCard/ExpansionCard";
import TableCardReUse from "~/components/tableCard/TableCardReUse";
import Search from "~/components/common/search/Search";
import Loading from "~/components/common/loading/LoadingPanel";

import "./CardGroup.scss";

const CardGroup = (props) => {
    const { source } = props;
    const [dataObject, setDataObject] = useState([]);

    useEffect(() => {
        if (source) {
            setDataObject(source);
        } else {
            setDataObject([]);
        }
    }, [source]);

    const filterDataArray = (search) => {
        if (source) {
            let result = Utils.filterData(source, search);
            setDataObject(result);
        } else {
            setDataObject([]);
        }
    };

    return (
        <ExpansionCard
            hideAlignments={!props.alignments}
            hideResize={!props.resize}
            title={props.title}
            noPadding={props.noPadding}
            className={`${props.className} card-group`}
            titleButtons={
                <Box className="card-group-header">
                    {!props.hideSearch && (
                        <Search
                            id={"search-" + props.title}
                            search={props.searchFn || filterDataArray}
                            className="card-group-search"
                            classNameRefactor="search-refactor"
                            value={props.searchValue}
                        />
                    )}
                    {props.headerComp}
                </Box>
            }
            {...props}>
            {props.loading && (
                <div className="loadingGridData loadingGroupCard">
                    <Loading size={40} />
                </div>
            )}
            {!props.children && (
                <TableCardReUse
                    align="start-end"
                    columns={props.columns}
                    dataSource={dataObject}
                    onSelect={props.select}
                    backgroundChanged={props.backgroundChanged}
                    page={props.page}
                    itemsPerPage={props.itemsPerPage}
                    totalItems={props.totalItems}
                    handlePage={props.handlePage}
                    {...props}
                />
            )}
            {props.children}
        </ExpansionCard>
    );
};

export default CardGroup;
