import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import mapService from "~/services/mapService";

const getGoogleApiKey = (callback = () => {}, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return mapService
		.getGoogleApiKey()
		.then(({ data }) => {
			callback();
			dispatch({
				type: Constants.GET_API_KEY,
				payload: data,
			});
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

export default {
	getGoogleApiKey,
};
