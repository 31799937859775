import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import ReportFilter from "~/components/common/reportTab/ReportFilter";
import EventsObject from "~/components/reporting/events/perObject/EventsObject";
import EventsPeriod from "~/components/reporting/events/perPeriod/EventsPeriod";
import EventsScheduled from "~/components/reporting/events/scheduled/EventsScheduled";
import FiltersObject from "~/components/reporting/events/perObject/FiltersObject";
import EventFilterPeriod from "~/components/reporting/events/perPeriod/EventFilterPeriod";
import EventsFilterScheduled from "~/components/reporting/events/scheduled/EventsFilterScheduled";

const ReportEvents = (props) => {
    const { periodForm, objectForm, scheduleForm } = useSelector((state) => state.eventReport);
    const { t } = useTranslation();

    return (
        <div id="report-events">
            <ReportFilter
                label={t(Labels.FILTERS)}
                onCleanObject={() => {
                    objectForm && objectForm.resetForm();
                }}
                onCleanPeriod={() => {
                    periodForm && periodForm.resetForm();
                }}
                onCleanScheduled={() => {
                    scheduleForm && scheduleForm.resetForm();
                }}
                objectFilter={<FiltersObject />}
                periodFilter={<EventFilterPeriod />}
                scheduledFilter={<EventsFilterScheduled />}
            />
            <EventsObject />
            <EventsPeriod />
            <EventsScheduled />
        </div>
    );
};

export default ReportEvents;
