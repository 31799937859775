import vehicleRenewalService from "~/services/vehicleRenewalService";
import Utils from "~/helpers/Utils";

const getRenewal = (usePopup = true,callback = () => {}, LOADING_IDENTIFICATOR = "") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return vehicleRenewalService
        .listVehicleRenewal(usePopup)
        .then((response) => {
            let data = response.data;
            dispatch({ type: "SET_CAN_RENEW", payload: data.length > 0 });
            dispatch({ type: "ADD_ITEMS", payload: data });
            callback(false);
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getContact = (callback = () => {}, LOADING_IDENTIFICATOR = "") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return vehicleRenewalService
        .getContact()
        .then((response) => {
            let data = response.data;
            dispatch({ type: "SET_CONTACT", payload: data });
            callback(false);
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const saveRenewal = (values, callback = () => {}, LOADING_IDENTIFICATOR = "") => (dispatch) => {
    console.log('aqui')
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return vehicleRenewalService
        .saveRenewal(values)
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

export default {
    getRenewal,
    saveRenewal,
    getContact,
};
