import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Container, Grid } from "@material-ui/core";

import fleetAction from "~/actions/fleetAction";
import vehicleSearchAction from "~/actions/vehicleSearchAction";

import Page from "~/components/common/page/Page";
import ReportAlerts from "~/components/reporting/alerts/ReportAlerts";

import alertReportAction from "~/actions/reporting/alertReportAction";
import OptionExportButton from "~/components/common/exportButton/OptionExportButton";

const ReportAlertsView = () => {
    const dispatch = useDispatch();
    const { alertExport } = useSelector((state) => state.alertReport);

    useEffect(() => {
        let filter = {
            type: "gps",
            functionality: "3",
        };
        dispatch(fleetAction.fleetData(filter));
        dispatch(vehicleSearchAction.vehicleData(filter));

        return () => {
            dispatch(alertReportAction.cleanListAlerts());
        };
    }, [dispatch]);

    return (
        <>
            <Page
                topButtons={
                    <div id="export-container">
                        <OptionExportButton
                            onClick={(type) => {
                                dispatch(alertReportAction.saveAlertExport());
                                dispatch(
                                    alertReportAction.exportAlert({
                                        ...alertExport,
                                        Tipo: type.value.toLowerCase(),
                                    })
                                );
                            }}
                        />
                    </div>
                }>
                <Container maxWidth="lg" className="container-map-view">
                    <Grid container>
                        <Grid item xs={12}>
                            <ReportAlerts />
                        </Grid>
                    </Grid>
                </Container>
            </Page>
        </>
    );
};

export default ReportAlertsView;
