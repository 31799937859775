import React, { forwardRef, useRef, Children, isValidElement, cloneElement, useState, useEffect } from "react";
import { findDOMNode } from "react-dom";
import { isFragment } from "react-is";
import clsx from "clsx";
import { withStyles } from "@material-ui/styles";
import { MenuList, Popper, Grow, ClickAwayListener, Paper } from "@material-ui/core";

import setRef from "@material-ui/core/utils/setRef";

export const styles = {
	/* Styles applied to the `Paper` component. */
	paper: {
		// specZ: The maximum height of a simple menu should be one or more rows less than the view
		// height. This ensures a tapable area outside of the simple menu with which to dismiss
		// the menu.
		maxHeight: "calc(60vh - 100px)",
		overflowY: "auto",
		// Add iOS momentum scrolling.
		WebkitOverflowScrolling: "touch",
	},
	/* Styles applied to the `List` component via `MenuList`. */
	list: {
		// We disable the focus ring for mouse, touch and keyboard users.
		outline: 0,
	},
};

const MenuPopper = forwardRef(function MenuPopper(props, ref) {
	const {
		autoFocus = true,
		children,
		classes,
		disableAutoFocusItem = false,
		MenuListProps = {},
		onClose,
		onEntering,
		open,
		PaperProps = {},
		PopoverClasses,
		variant = "selectedMenu",
		anchorEl,
		...other
	} = props;

	// const theme = useTheme();
	const autoFocusItem = autoFocus && !disableAutoFocusItem && open;
	const menuListActionsRef = useRef(null);
	const [oppened, setOppened] = useState(false);

	const handleListKeyDown = (event) => {
		if (event.key === "Tab") {
			event.preventDefault();

			if (onClose) {
				onClose(event, "tabKeyDown");
			}
		}
	};

	/**
	 * the index of the item should receive focus
	 * in a `variant="selectedMenu"` it's the first `selected` item
	 * otherwise it's the very first item.
	 */
	let activeItemIndex = -1;
	// since we inject focus related props into children we have to do a lookahead
	// to check if there is a `selected` item. We're looking for the last `selected`
	// item and use the first valid item as a fallback
	Children.map(children, (child, index) => {
		if (!isValidElement(child)) {
			return;
		}

		if (process.env.NODE_ENV !== "production") {
			if (isFragment(child)) {
				console.error(
					[
						"Material-UI: The Menu component doesn't accept a Fragment as a child.",
						"Consider providing an array instead.",
					].join("\n")
				);
			}
		}

		if (!child.props.disabled) {
			activeItemIndex = index;
		}
	});

	const items = Children.map(children, (child, index) => {
		if (index === activeItemIndex) {
			return cloneElement(child, {
				ref: (instance) => {
					anchorEl.current = findDOMNode(instance);
					setRef(child.ref, instance);
				},
			});
		}

		return child;
	});

	useEffect(() => {
		if (!open) setOppened(false);
	}, [open]);

	return (
		<Popper open={open} anchorEl={anchorEl} placement={"bottom"} transition {...other}  style={{zIndex: 1500}}>
			{({ TransitionProps, placement }) => (
				<Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
					<Paper
						{...PaperProps}
						classes={{
							...PaperProps.classes,
							root: classes.paper,
						}}>
						<ClickAwayListener
							onClickAway={(event) => {
								if (oppened) {
									onClose(event);
									setOppened(false);
								} else {
									setOppened(true);
								}
							}}>
							<MenuList
								data-mui-test="Menu"
								onKeyDown={handleListKeyDown}
								actions={menuListActionsRef}
								autoFocus={autoFocus && (activeItemIndex === -1 || disableAutoFocusItem)}
								autoFocusItem={autoFocusItem}
								variant={variant}
								{...MenuListProps}
								className={clsx(classes.list, MenuListProps.className)}>
								{items}
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>
	);
});

export default withStyles(styles, { name: "MuiMenu" })(MenuPopper);
