import React from 'react';
import { Container, Grid } from "@material-ui/core";

import Page from "~/components/common/page/Page";

import GroupPointsFinder from "~/components/groupOfPoints/finder/GroupPointsFinder";
import "./GroupPointsFinderView.scss"

function GroupPointsFinderView() {
  return (
		<>
			<Page>
				<main>
					<Container maxWidth="lg" className="containerRegisterGroupPoints container-map-view">
						<Grid container>
							<Grid item xs={12}>
								<GroupPointsFinder />
							</Grid>
						</Grid>
					</Container>
				</main>
			</Page>
		</>
	);
}

export default GroupPointsFinderView;
