import http from "~/config/httpRequest/http";

const list = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/Geolocalizacaoapi/ListaObjetosRastreaveisProximos`, {
				Latitude: filter.latitude,
				Longitude: filter.longitude,
				GridRequest: {
					Index: filter.index,
					Length: filter.limit,
					Search: filter.term,
					SearchColumns: filter.SearchColumns,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

export default { list };
