import React from 'react';
import RecoverPasswordMain from '~/components/login/LoginMain';
import BuildTag from '~/components/common/info/buildTag';

const RecoverPassword = () => {

  return (
    <>
      <BuildTag/>
      <RecoverPasswordMain />
    </>
  );
};

export default RecoverPassword;