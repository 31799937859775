import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";

import alertsService from "~/services/alertsService";

const getAlertsViolated = (LOADING_IDENTIFICATOR = "getAlertsViolated") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertsService
        .getAlertsViolated()
        .then((res) => {
            let payload = res;
            dispatch({ type: Constants.GET_ALERTS_VIOLATED, payload });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getAlertDetails = (params, fnCallback, LOADING_IDENTIFICATOR = "getAlertDetails") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertsService
        .getAlertDetails(params)
        .then((res) => {
            fnCallback(res);
            dispatch({ type: Constants.GET_ALERTS_DETAILS, payload: {
                ...res,
                params: params
            } });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getHistory = (params, LOADING_IDENTIFICATOR = "getAlertHistory") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertsService
        .getHistory(params)
        .then((res) => {
            let payload = res;
            dispatch({ type: Constants.GET_ALERTS_HISTORY, payload });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const saveHistory = (req, fnCallback, LOADING_IDENTIFICATOR = "saveAlertHistory") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertsService
        .saveHistory(req)
        .then((res) => {
            let payload = res;
            fnCallback();
            dispatch({ type: Constants.SAVE_ALERTS_DETAILS, payload });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const setAlertIdSelected = (payload) => (dispatch) => {
    dispatch({ type: Constants.SET_ALERT_ID_SELECTED, payload });
};

const markDoneAllAlertsViolated = (idAlert, fnCallback, LOADING_IDENTIFICATOR = "markDoneAllAlertsViolated") => (
    dispatch
) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertsService
        .markAllDoneAlertsViolated(idAlert)
        .then((res) => {
            let payload = res;
            fnCallback();
            dispatch({ type: Constants.MARK_DONE_ALL_ALERTS, payload });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const markBoardDoneAlertsViolated = (
    filter,
    callback = () => {},
    LOADING_IDENTIFICATOR = "markBoardDoneAlertsViolated"
) => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return alertsService
        .markBoardDoneAlertsViolated(filter)
        .then((res) => {
            dispatch({ type: Constants.BOARD_MARK_DONE_ALERTS, payload: res });
            callback(false);
        })
        .catch((error) => {
            callback(error);
            console.error(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const cleanAlertDetails = (filter) => (dispatch) => {
    dispatch({ type: Constants.CLEAN_ALERTS_DETAILS });
};

export default {
    getAlertsViolated,
    getAlertDetails,
    getHistory,
    saveHistory,
    setAlertIdSelected,
    markDoneAllAlertsViolated,
    markBoardDoneAlertsViolated,
    cleanAlertDetails,
};
