import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import dashboardDatavehicleAction from "~/actions/dashboardVehicle/dashboardDataVehicleActions";
import { Box, Typography, Button, IconButton, CircularProgress } from "@material-ui/core";
import { RefreshIcon } from "~/components/common/icons/Icons";
import Utils from "~/helpers/Utils";
import Constants from "~/helpers/enums/Constants";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LoadingPanel from "~/components/common/loading/LoadingPanel";
import useFuso from "~/components/common/fuso/useFuso";
import "./DataVhicle.scss";

const PrivateMode = ({ vehicle }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { privateMode } = useSelector((state) => state.dashboardDataVehicle);
    const { loading } = useSelector((state) => state.app);
    const { fromUtc } = useFuso();


    const getPrivateMode = useCallback(() => {
        if (!vehicle.Chassi) {
            return;
        }

        dispatch(dashboardDatavehicleAction.getPrivateMode(vehicle.Chassi));
    }, [dispatch, vehicle]);

    useEffect(() => {
        getPrivateMode();
    }, [getPrivateMode]);

    console.log("aaaa", privateMode)

    return (
        <Box className="boxPrivateMode">
            <Box className="privateModeTitle">
                <Typography>{t(Labels.DASHBOARD_VEHICLE_PRIVATE_MODE_TITLE)}</Typography>
            </Box>
            <Box className="privateModeBody">
                {loading.getPrivateMode && (
                    <Box className="privateModeLoading">
                        <LoadingPanel size={40} />
                    </Box>
                )}
                {!loading.getPrivateMode && (
                    <>
                        <Box className="privateModelInfo">
                            {!privateMode && (
                                <Box className="privateModelabelStatus">
                                    <span className={`semaphore inactive`} />
                                    <span className="labelprivateModelStatus">
                                        <Typography>{t(Labels.DASHBOARD_VEHICLE_PRIVATE_MODE_NOT_INFO)}</Typography>
                                    </span>
                                </Box>
                            )}
                            {privateMode && (
                                <>
                                    <Box className="privateModelabelStatus">
                                        <span className="privateModelock">
                                            {privateMode.Ativado ? (
                                                <LockIcon style={{ fontSize: 30 }} />
                                            ) : (
                                                <LockOpenIcon style={{ fontSize: 30 }} />
                                            )}
                                        </span>
                                        <span className="labelprivateModelStatus">
                                            <Typography>
                                                {privateMode.Ativado
                                                    ? t(Labels.DASHBOARD_VEHICLE_PRIVATE_MODE_ACTIVE)
                                                    : t(Labels.DASHBOARD_VEHICLE_PRIVATE_MODE_INACTIVE)}
                                            </Typography>
                                        </span>
                                    </Box>
                                    <Box className="privateModeDate">
                                        {fromUtc(privateMode.DataMudanca).format("DD/MM/YYYY HH:mm")}{" "}
                                    </Box>
                                    <Button
                                        className="privateModeButton"
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        disabled={loading.switchPrivateMode}
                                        startIcon={loading.switchPrivateMode && <CircularProgress size={18} />}
                                        disableElevation
                                        onClick={() => {
                                            dispatch(
                                                dashboardDatavehicleAction.switchPrivateMode(
                                                    {
                                                        chassi: vehicle.Chassi,
                                                        status: !privateMode.Ativado,
                                                    },
                                                    (error, data) => {
                                                        if (error) {
                                                            Utils.showTranslatedToast(
                                                                {
                                                                    type: Constants.ERROR,
                                                                    description:
                                                                        Labels.DASHBOARD_VEHICLE_PRIVATE_MODE_MSGS_ERROR_REFRESH,
                                                                },
                                                                { position: "bottom-right" }
                                                            );
                                                            return;
                                                        }

                                                        Utils.showTranslatedToast(
                                                            {
                                                                type: Constants.SUCCESS,
                                                                description:
                                                                    Labels.DASHBOARD_VEHICLE_PRIVATE_MODE_MSGS_SUCESS_SAVE,
                                                            },
                                                            { position: "bottom-right" }
                                                        );

                                                        getPrivateMode();
                                                    }
                                                )
                                            );
                                        }}>
                                        {privateMode.Ativado
                                            ? t(Labels.DASHBOARD_VEHICLE_PRIVATE_MODE_INACTIVE_BUTTON)
                                            : t(Labels.DASHBOARD_VEHICLE_PRIVATE_MODE_ACTIVE_BUTTON)}
                                    </Button>
                                </>
                            )}
                        </Box>
                        <Box className="privateModelRefresh">
                            <IconButton
                                onClick={() => {
                                    getPrivateMode();
                                }}>
                                <RefreshIcon fontSize="large" viewBox="0 0 22 19" />
                            </IconButton>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default PrivateMode;
