import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Container, Grid } from "@material-ui/core";

import fleetAction from "~/actions/fleetAction";
import driverAction from "~/actions/driverAction";
import vehicleSearchAction from "~/actions/vehicleSearchAction";

import Page from "~/components/common/page/Page";
import ReportSpeeding from "~/components/reporting/speeding/ReportSpeeding";

import speedingReportAction from "~/actions/reporting/speedingReportAction";
import OptionExportButton from "~/components/common/exportButton/OptionExportButton";

import _ from "lodash";

const ReportSpeedingView = () => {
    const dispatch = useDispatch();

    const { value } = useSelector((state) => state.reportTab);
    const { loading } = useSelector((state) => state.app);

    const {
        perObject: { filters },
    } = useSelector((state) => state.speedingReport);

    useEffect(() => {
        let filter = {
            type: "gps",
            functionality: "3",
        };
        dispatch(fleetAction.fleetData(filter));
        dispatch(driverAction.driverData({ amount: -1 }));
        dispatch(vehicleSearchAction.vehicleData(filter));
    }, [dispatch]);

    return (
        <>
            <Page
                topButtons={
                    value === "TAB_OBJECT" ? (
                        <div id="export-container">
                            <OptionExportButton
                                loading={loading.requestObjectDoc}
                                onClick={(type) => {
                                    if (_.isEmpty(filters.period)) {
                                        return;
                                    }

                                    const format = type.value.toLowerCase();
                                    dispatch(
                                        speedingReportAction.getRequestPerObject(
                                            {
                                                ...filters,
                                                format: format,
                                            },
                                            () => {},
                                            "requestObjectDoc"
                                        )
                                    );
                                }}
                            />
                        </div>
                    ) : null
                }>
                <Container maxWidth="lg" className="container-map-view">
                    <Grid container>
                        <Grid item xs={12}>
                            <ReportSpeeding />
                        </Grid>
                    </Grid>
                </Container>
            </Page>
        </>
    );
};

export default ReportSpeedingView;
