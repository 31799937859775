import Constants from "~/helpers/enums/Constants";

const initialState = {
    filter: {
        id: null,
    },
    data: [],
    dataBase: [],
    privateMode: null,
};

function dashboardDataVehicleReducer(state = initialState, action = null) {
    switch (action.type) {
        case Constants.GET_DATA_VEHICLE_INDIVIDUAL:
            return {
                ...state,
                data: action.payload.data,
            };
        case Constants.GET_DATA_VEHICLE_MODE_PRIVATE:
            return {
                ...state,
                privateMode: action.payload.data,
            };
        case Constants.DASHBOARD_BASE_COMPARATIVE_KM_RUN:
            return {
                ...state,
                dataBase: action.payload.data,
            };
        default:
            return state;
    }
}

export default dashboardDataVehicleReducer;
