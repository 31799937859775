//! transparent linear-gradient(302deg, #6EC8AF 0%, #6EC8B1 46%, #64C8FF 100%)
const baseColorSolid = "#6EC8AF";
export const baseColors = {
	type: "linear",
	x: 0,
	y: 0,
	x2: 0,
	y2: 1,
	colorStops: [
		{
			offset: 0,
			color: baseColorSolid, // color at 0% position
		},
		{
			offset: 0.46,
			color: "#6EC8B1", // color at 0% position
		},
		{
			offset: 1,
			color: "#64C8FF", // color at 100% position
		},
	],
	global: false,
};

//! transparent linear-gradient(209deg, #FFDA5D 0%, #FF9464 100%)
const veiculoColorSolid = "#FFDA5D";
export const veiculoColors = {
	type: "linear",
	x: 0,
	y: 0,
	x2: 0,
	y2: 1,
	colorStops: [
		{
			offset: 0,
			color: veiculoColorSolid, // color at 0% position
		},
		{
			offset: 1,
			color: "#FF9464", // color at 100% position
		},
	],
	global: false,
};

const formatDay = (Dia, Mes, Ano) =>{
	return `${Dia}/${Mes}/${Ano}`
}

const customChart = ({ data }) => {
	const { Base, Veiculo } = data;

	const payload = Base.map((time, index) => ({
		day: formatDay(time.Dia, time.Mes, time.Ano),
		Base: time.Distancia,
		Veículo: Veiculo[index].Distancia,
	}));

	
	return payload;
};

const extractData = ({ data, ...rest }) => {
	const { Base, Veiculo } = data;

	const payload = {
		Base,
		Veículo: Veiculo,
	};
	return payload;
};

const emptyChart = () => {
	return [{day: " ",
	Base: 0,
	Veículo: 0}];
}

export default { extractData, customChart, emptyChart };
