import React from "react";
import { Field } from "formik";
import { Box } from "@material-ui/core";
import { Checkbox } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	checkbox: {
		padding: "8px 8px 8px 0",

	},
	checkboxLabel: {
		color: "#3D5564",
		fontSize: 14,
		font: "Trebuchet MS",
		marginRight: 7,
	},
}));

const CheckboxPGF = (props) => {
	const classes = useStyles();

	return (
		<Box className="import-checkbox">
			<Field
				id={props.id}
				name={props.name}
				component={Checkbox}
				variant="outlined"
				color="primary"
				aria-describedby="outlined-weight-helper-text"
				type={"checkbox"}
				className={classes.checkbox}
			/>
			<label className={classes.checkboxLabel} htmlFor={props.name}>
				{props.label}
			</label>
		</Box>
	);
};

export default CheckboxPGF;
