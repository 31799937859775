import React, { Fragment } from "react";
import { Grid, Box, Button, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { UserIcon, CancelIcon, ConfirmIcon } from "~/components/common/icons/Icons";
import Labels from "~/helpers/enums/Labels";
import RegisterInputText from "~/components/common/registerInputs/RegisterInputText";
import RegisterAutocomplete from "~/components/common/registerInputs/RegisterAutocomplete";
import RegisterImageInput from "~/components/common/registerInputs/RegisterImageInput";
import "./Profile.scss";

const PersonalInformationForm = (props) => {
    const { t } = useTranslation();
    const { fusos } = useSelector((state) => state.profile);

    const { submitForm } = props.form;

    const passwordRules = t(Labels.PROFILE_PERSONAL_INFORMATION_NEW_PASSWORD_RULES, { returnObjects: true }) || {};

    return (
        <Box className="profile-panel">
            <Grid container direction="row" alignItems="stretch" spacing={0}>
                <Grid item xs={12}>
                    <Grid container direction="row" alignItems="stretch" spacing={0}>
                        <Grid className="profile-no-padding-left" item xs={12} md={3}>
                            <RegisterImageInput
                                id="profile-photo"
                                name="ImagemUsuarioFile"
                                placholderIcon={<UserIcon />}
                                accept=".jpg, .png, .jpeg"
                                withButton
                                width={"140px"}
                                height={"140px"}
                            />
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container direction="row" alignItems="stretch" spacing={0}>
                                <Grid item xs={12} md={4}>
                                    <RegisterInputText
                                        label={t(Labels.PROFILE_PERSONAL_INFORMATION_LOGIN)}
                                        name="Login"
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <RegisterInputText
                                        label={t(Labels.PROFILE_PERSONAL_INFORMATION_NAME)}
                                        name="Nome"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RegisterInputText
                                        label={t(Labels.PROFILE_PERSONAL_INFORMATION_EMAIL)}
                                        name="Email"
                                    />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <RegisterAutocomplete
                                        getOptionLabel={(option) => option.Text || ""}
                                        getOptionSelected={(option, value) => option.Value === value.Value}
                                        options={fusos || []}
                                        label={t(Labels.PROFILE_PERSONAL_INFORMATION_TIMEZONE)}
                                        name="TzId"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid className="profile-no-padding-left" item xs={12} md={4}>
                    <RegisterInputText
                        label={t(Labels.PROFILE_PERSONAL_INFORMATION_NEW_PASSWORD)}
                        type="password"
                        name="Senha"
                        inputProps={{
                            autoComplete: "nope",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <RegisterInputText
                        label={t(Labels.PROFILE_PERSONAL_INFORMATION_REPEAT_PASSWORD)}
                        type="password"
                        name="RepetirSenha"
                        inputProps={{
                            autoComplete: "nope",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4} style={{ display: "flex" }}>
                    <Grid container direction="row" justify="flex-end" spacing={0}>
                        <Grid item xs={12} md={5}>
                            <Box className="button-form button-form-refactor">
                                <Button
                                    id="profile-cancel-button"
                                    className="cancel-button cancel-button-refactor"
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    fullWidth
                                    onClick={() => {
                                        props.comeBack();
                                    }}
                                    disabled={props.loading}>
                                    <CancelIcon />

                                    {t(Labels.PROFILE_BUTTON_CANCEL)}
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Box className="button-form button-form-refactor">
                                <Button
                                    id="profile-submit-button"
                                    variant="contained"
                                    className="save-button save-button-refactor"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    disableElevation
                                    onClick={submitForm}
                                    disabled={props.loading}
                                    startIcon={props.loading ? <CircularProgress size={18} /> : <ConfirmIcon />}>
                                    {t(Labels.PROFILE_BUTTON_CONFIRM)}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box className="rules-box rules-password">
                        <span className="rules-title">{passwordRules["title"]}</span>
                        {passwordRules["hints"].map((hint, i) => (
                            <Fragment key={i}>
                                <br />
                                <span className="hint"> - {hint}</span>{" "}
                            </Fragment>
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PersonalInformationForm;
