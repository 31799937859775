import React, { useEffect, useRef } from "react";
import { Button, Grid } from "@material-ui/core";

import Labels from "~/helpers/enums/Labels";
import { SaveReportIcon } from "~/components/common/icons/Icons";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import Utils from "~/helpers/Utils";

import ReportInput from "~/components/reporting/common/input/Input";
import ReportPeriodicityField from "~/components/reporting/common/scheduling/ReportPeriodicityField";
import ReportFileTypeField from "~/components/reporting/common/scheduling/ReportFileTypeField";
import VehicleAutocomplete from "~/components/reporting/common/vehicle/VehicleAutocomplete";
import ReportDateField from "~/components/reporting/common/scheduling/ReportDateField";

const SchedulingFilter = ({ edition, onFormRefUpdate, onSubmit }) => {
    const { t } = useTranslation();
    const scheduledForm = useRef();

    useEffect(() => {
        onFormRefUpdate && onFormRefUpdate(scheduledForm);
    }, [onFormRefUpdate, scheduledForm]);

    useEffect(() => {
        if (!!edition.Id) {
            Utils.updateFormikValues(scheduledForm.current, {
                ...edition,
                ObjetoRastreavel: {
                    id: edition.ObjetoRastreavelId,
                    text: edition.ObjetoGrupoNome || "null",
                },
            });
        }
    }, [edition]);

    return (
        <Formik
            innerRef={scheduledForm}
            initialValues={{
                PeriodicidadeDia: "",
                Formato: "",
                DataInicio: new Date(),
                ObjetoRastreavel: {},
                Email: "",
            }}
            validate={(values) => {
                const errors = {};

                if (!values.PeriodicidadeDia) {
                    errors.PeriodicidadeDia = t(Labels.REPORT_SHEDULED_FREQUENCY_REQUIRED);
                }

                if (!values.Formato) {
                    errors.Formato = t(Labels.REPORT_SHEDULED_FILE_TYPE_REQUIRED);
                }

                if (!values.ObjetoRastreavel || !values.ObjetoRastreavel.id) {
                    errors.ObjetoRastreavel = t(Labels.REPORT_SHEDULED_VEHICLE_REQUIRED);
                }

                if (!values.Email) {
                    errors.Email = t(Labels.REPORT_SHEDULED_EMAIL_REQUIRED);
                } else if (!Utils.isValidEmail(values.Email)) {
                    errors.Email = t(Labels.REPORT_SHEDULED_EMAIL_INVALID);
                }

                return errors;
            }}
            onSubmit={onSubmit}
            render={({ submitForm }) => {
                return (
                    <Form>
                        <Grid container justify="right" alignItems="flex-end" spacing={1}>
                            <Grid item xs={2}>
                                <ReportPeriodicityField
                                    name="PeriodicidadeDia"
                                    label={t(Labels.REPORT_SHEDULED_FREQUENCY)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <ReportFileTypeField name="Formato" label={t(Labels.REPORT_SHEDULED_FILE_TYPE)} />
                            </Grid>
                            <Grid item xs={2}>
                                <ReportDateField
                                    name="DataInicio"
                                    label={t(Labels.REPORT_SHEDULED_DATE)}
                                    minDate={new Date()}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <VehicleAutocomplete
                                    name="ObjetoRastreavel"
                                    label={t(Labels.REPORT_SHEDULED_VEHICLE)}
                                    placeholder={t(Labels.REPORT_EVENT_VEHICLE_PLACEHOLDER)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <ReportInput
                                    name="Email"
                                    placeholder={t(Labels.REPORT_EVENT_EMAIL_PLACEHOLDER)}
                                    label={t(Labels.REPORT_SHEDULED_EMAIL)}
                                />
                            </Grid>

                            <Grid item xs={2}>
                                <Button
                                    id="event-filter-scheduled-save-button"
                                    className="report-save-button report-save-button-refactor"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SaveReportIcon />}
                                    disableElevation
                                    onClick={submitForm}>
                                    {t(Labels.REPORT_EVENT_SAVE_BUTTON)}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        />
    );
};

export default SchedulingFilter;
