import Constants from "~/helpers/enums/Constants";

const initialState = {
	groups: [],
	deleteGroup: [],
	pointsLinked: [],
	availablePoints: [],
};

function groupPointsReducer(state = initialState, action = null) {
	const { payload } = action;
	switch (action.type) {
		case Constants.DELETE_GROUP_POINTS_BY_ID:
			return {
				...state,
				deleteGroup: payload,
			};
		case Constants.GET_GROUPS_POINTS:
			return {
				...state,
				groups: payload,
			};
		case Constants.GET_POINTS_LINKED_GROUP:
			return {
				...state,
				pointsLinked: payload,
			};
		case Constants.GET_AVAILABLE_POINTS_FOR_GROUP:
			return {
				...state,
				availablePoints: payload,
			};
		case Constants.CLEAN_POINTS_LINKED_GROUP:
			return {
				...state,
				pointsLinked: initialState.pointsLinked,
			};
		case Constants.CLEAN_AVAILABLE_POINTS_FOR_GROUP:
			return {
				...state,
				availablePoints: initialState.availablePoints,
			};
		default:
			return state;
	}
}

export default groupPointsReducer;
