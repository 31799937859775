import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Button, Grid, Box, Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import Page from "~/components/common/page/Page";
import Labels from "~/helpers/enums/Labels";
import { ComeBackIcon } from "~/components/common/icons/Icons";
import { useTranslation } from "react-i18next";
import PointsInterestForm from "~/components/pointsInterest/register/PointsInterestForm";
import MapCard from "~/components/map/MapCard";
import Card from "~/components/common/expansionCard/Card";
import { RegisterTitleIcon, EditIcon } from "~/components/common/icons/Icons";
import pointsInterestAction from "~/actions/pointsInterestAction";
import "./RegisterPointsInterest.scss";

const RegisterPointsInterest = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { pointInterest } = useSelector((state) => state.pointsInterest);

	const { id } = props.match.params;

	useEffect(() => {
		dispatch(pointsInterestAction.cleanPointInterest());
		if (id) dispatch(pointsInterestAction.getPointInterestById(parseInt(id)));

		return () => {
			dispatch(pointsInterestAction.cleanPointInterest());
		};
	}, [dispatch, id]);

	const comeBack = () => {
		props.history.push("/register/pointsInterest/list");
	};

	return (
		<Page
			topButtons={
				<Button
					className="comeback-button"
					id="extractReport"
					variant="contained"
					size="small"
					disableElevation
					startIcon={<ComeBackIcon />}
					onClick={() => {
						comeBack();
					}}>
					{t(Labels.REGISTER_POINT_INTEREST_COMEBACK)}
				</Button>
			}>
			<main>
				<Container id="register-points-interest-container" maxWidth="lg" className="container-map-view">
					<Grid container id="register-routes-container" direction="row" alignItems="stretch" spacing={3}>
						<Grid item xs={12} md={12}>
							<Box className="register-title">
								<Typography>
									{!!id ? (
										<>
											<EditIcon />
											{t(Labels.REGISTER_POINT_INTEREST_FORM_EDIT_TITLE)}
										</>
									) : (
										<>
											<RegisterTitleIcon />
											{t(Labels.REGISTER_POINT_INTEREST_FORM_ADD_TITLE)}
										</>
									)}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} md={4}>
							{((id && pointInterest) || !id) && (
								<PointsInterestForm pointInterest={pointInterest} comeback={comeBack} />
							)}
						</Grid>

						<Grid item xs={12} md={8}>
							<Card
								title="Mapa"
								notPadded
								className="form-card map-card card-group-refactor"
								>
								<MapCard className="map-card-refactor" />
							</Card>
						</Grid>
					</Grid>
				</Container>
			</main>
		</Page>
	);
};

export default withRouter(RegisterPointsInterest);
