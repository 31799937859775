import Constants from "~/helpers/enums/Constants";

const initialState = {
	filter: {
		id: 0,
		description: "",
	},
	data: [],
};

function vehicleReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.FLEET_GET_DATA_FILTER:
			return {
				...state,
				data: action.payload.data,
			};
		case Constants.FLEET_DATA_FILTER:
			return {
				...state,
				filter: {
					...state.filter,
					...action.payload.filter,
				},
			};
		default:
			return state;
	}
}

export default vehicleReducer;
