import { Colors, ColorSolid, getFullValue, getName } from "~/components/common/dashboard/PeriodTripsFormatterUtils";

const customChart = ({ data: { Veiculo } }) => {
	const {
		TempoMadrugadaPercentual: TempoMadrugadaPorcentagem,
		TempoMadrugada,
		TempoManhaPercentual: TempoManhaPorcentagem,
		TempoManha,
		TempoTardePercentual: TempoTardePorcentagem,
		TempoTarde,
		TempoNoitePercentual: TempoNoitePorcentagem,
		TempoNoite,
	} = Veiculo;

	const payload = Object.entries({
		TempoMadrugadaPorcentagem,
		TempoManhaPorcentagem,
		TempoTardePorcentagem,
		TempoNoitePorcentagem,
	}).map((time) => ({
		name: getName(time[0]),
		value: time[1],
		fullValue: getFullValue(time[0], {
			TempoMadrugada,
			TempoManha,
			TempoTarde,
			TempoNoite,
		}),
		itemStyle: {
			color: Colors(time[0]),
		},
		label: {
			color: ColorSolid(time[0]),
		},
	}));

	return payload;
};

const emptyChart = () => {
	return [{
				value: 0,
				name: 0,
				fullValue: 0,
				itemStyle: {
					color: {
						type: "linear",
						x: 0,
						y: 0,
						x2: 0,
						y2: 1,
						colorStops: [
							{
								offset: 0,
								color: "#6EC8AF",
							},
							{
								offset: 1,
								color: "#eef2f5",
							},
						],
						global: true
					}
				}
			}
		]
}

export default { customChart, emptyChart };
