import React, { useEffect } from "react";

import { useDispatch } from "react-redux";

import { Box, Typography } from "@material-ui/core";

import Card from "~/components/card/Card";
import Table from "~/components/table/Table";

const TableCard = (props) => {
    const { data } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        props.updateAction && data && dispatch(props.updateAction(data));
    }, [dispatch, data, props]);

    useEffect(() => {
        return () => {
            props.cleanAction && dispatch(props.cleanAction());
        };
    }, [dispatch, props]);

    return (
        <Box elevation={0} style={{ padding: "10px 0" }} className={props.className} >
            {props.mode.orientation === "horizontal" ? (
                props.data?.map((item, i) => (
                    <Card
                        id={props.id}
                        key={i}
                        columns={props.filters}
                        data={item}
                        order={props.order}
                        selectedLabel={props.selectedLabel}
                        onHeaderClick={props.onHeaderClick}
                        onItemClick={props.onItemClick}
                    />
                ))
            ) : (
                <Table
                    id={props.id}
                    column={props.filters}
                    data={props.data}
                    order={props.order}
                    selectedLabel={props.selectedLabel}
                    onHeaderClick={props.onHeaderClick}
                    onItemClick={props.onItemClick}
                />
            )}
            {props.data.length <= 0 ? (
                <Typography noWrap style={{ fontSize: "12px" }}>
                    Nenhum registro encontrado.
                </Typography>
            ) : null}
        </Box>
    );
};

export default React.memo(TableCard);
