import React from "react";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { Box, Typography } from "@material-ui/core";
import { Field } from "formik";
import FormikAutocomplete from "~/components/common/autocomplete/FormikAutocomplete";
import "~/components/reporting/Reporting.scss";
import "~/components/reporting/common/ReportingCommon.scss";

const AutocompleteFleet = (props) => {
	const { t } = useTranslation();
    const [ data ] = React.useState([{ text:"Concluídos", value: 2 }, { text:"Abertos", value: 1 }, { text:"Todos", value: -1 }]);
	return (
		<Box className="report-field">
			<Typography variant="subtitle1" className="report-field-label">
				{props.label}
			</Typography>
			<Field
				id={props.id}
				name={props.name}
				getOptionLabel={(option) => option.text || ""}
				getOptionSelected={(option, value) => option.value === value.value}
				component={FormikAutocomplete}
				onChange={props.onChange}
				options={data}
				noOptionsText={t(Labels.NO_DATA)}
				popupIcon={<ExpandMoreRoundedIcon viewBox="0 0 25 25" />}
				loading={false}
				textFieldProps={{
					variant: "outlined",
					fullWidth: true,
					margin: "dense",
					InputProps: {
						placeholder: props.placeholder,
					},
				}}
			/>
		</Box>
	);
};
export default AutocompleteFleet;
