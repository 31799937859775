import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
	tutorial: [],
	tutorialFilter: [],
};

function tutorialReducer(state = INITIAL_STATE, action = null) {
	switch (action.type) {
		case Constants.TUTORIAL_GET_DATA:
			return {
				...state,
				tutorial: action.payload,
				tutorialFilter: action.payload
			};
		case Constants.TUTORIAL_FILTER_DATA:
			return {
				...state,
				tutorialFilter: action.payload,
			};

		default:
			return state;
	}
}

export default tutorialReducer;
