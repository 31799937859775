import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";

const updateMapContext = (map) => (dispatch) => {
	dispatch({ type: Constants.UPDATE_MAPS_CONTEXT, payload: { map } });
};

const updateObjects = (objects) => (dispatch) => {
	dispatch({ type: Constants.UPDATE_MAPS_OBJECTS, payload: { objects } });
};

const updateGroupPoints = (groupPoints) => (dispatch) => {
	dispatch({ type: Constants.UPDATE_MAPS_GROUP_POINTS, payload: { groupPoints } });
};

const updatePoints = (points) => (dispatch) => {
	console.log(points);
	dispatch({ type: Constants.UPDATE_MAPS_POINTS, payload: { points } });
};

const updateRoutes = (routes) => (dispatch) => {
	dispatch({ type: Constants.UPDATE_MAPS_ROUTES, payload: { routes } });
};

const updateDirections = (directions) => (dispatch) => {
	dispatch({ type: Constants.UPDATE_MAPS_DIRECTIONS, payload: { directions } });
};

const updateEvents = (events, ident = "Identificacao") => (dispatch) => {
	dispatch({
		type: Constants.UPDATE_MAPS_EVENTS,
		payload: { events: Object.values(Utils.groupBy(events, ident)) },
	});
};

const centralizePoints = (points) => (dispatch) => {
	dispatch({ type: Constants.CENTRALIZE_MAPS, payload: { points } });
};

const centralizeDirections = (directions) => (dispatch) => {
	const points = directions
		.map((d) =>
			d.Trajeto
				? d.Trajeto.map((e) => {
						return {
							lat: e.latitude,
							lng: e.longitude,
						};
				  })
				: []
		)
		.reduce((a, b) => [...a, ...b]);

	dispatch({ type: Constants.CENTRALIZE_MAPS, payload: { points } });
};

const addEventOnMap = (key, event, func) => (dispatch) => {
	dispatch({
		type: Constants.ADD_EVENT_ON_MAP,
		payload: {
			key,
			event,
			func,
		},
	});
};

const addSyncFunc = (key, syncFunc) => (dispatch) => {
	dispatch({
		type: Constants.ADD_SYNC_FUNC,
		payload: {
			key,
			syncFunc,
		},
	});
};

const cleanObjects = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_MAPS_OBJECTS });
};

const cleanGroupPoints = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_MAPS_GROUP_POINTS });
};

const cleanPoints = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_MAPS_POINTS });
};

const cleanRoutes = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_MAPS_ROUTES });
};

const cleanDirections = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_MAPS_DIRECTIONS });
};

const cleanEvents = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_MAPS_EVENTS });
};

const cleanEventsOnMap = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_EVENTS_ON_MAP });
};

const cleanSyncFuncs = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_SYNC_FUNC });
};

const cleanAll = () => (dispatch) => {
	dispatch(cleanObjects());
	dispatch(cleanGroupPoints());
	dispatch(cleanPoints());
	dispatch(cleanRoutes());
	dispatch(cleanDirections());
	dispatch(cleanEvents());
	dispatch(cleanEventsOnMap());
	dispatch(cleanSyncFuncs());
};

export default {
	updateMapContext,
	updateObjects,
	updateGroupPoints,
	updatePoints,
	updateRoutes,
	updateDirections,
	centralizePoints,
	centralizeDirections,
	cleanObjects,
	cleanGroupPoints,
	cleanPoints,
	cleanRoutes,
	cleanDirections,
	cleanEvents,
	cleanAll,
	updateEvents,
	addEventOnMap,
	cleanEventsOnMap,
	addSyncFunc,
	cleanSyncFuncs,
};
