import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Tabs, Tab, Button } from "@material-ui/core";
import { CloseIcon } from "~/components/common/icons/Icons.jsx";
import reportTabAction from "~/actions/reportTabAction";
import Constants from "~/helpers/enums/Constants";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import "./ReportComponents.scss";

const ReportTab = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { value } = useSelector((state) => state.reportTab);
	const { initTab } = props;

	useEffect(() => {
		dispatch(reportTabAction.changeTabValue(initTab || Constants.TAB_OBJECT));
	}, [dispatch, initTab]);

	return (
		<Box className="report-tab">
			<Tabs
				indicatorColor="primary"
				value={value}
				onChange={(_, newValue) => {
					dispatch(reportTabAction.changeTabValue(newValue));
				}}>
				{props.hasObject && <Tab value={Constants.TAB_OBJECT} label={t(Labels.REPORT_TAB_OBJECT)} />}
				{props.hasPeriod && <Tab value={Constants.TAB_PERIOD} label={t(Labels.REPORT_TAB_PERIOD)} />}
				{props.hasScheduled && <Tab value={Constants.TAB_SCHEDULED} label={t(Labels.REPORT_TAB_SCHEDULED)} />}
				{props.tabs}
			</Tabs>
			<Box className="container-clean-button">
				<Button
					id={"report-tab-clean"}
					startIcon={<CloseIcon />}
					onClick={() => {
						props.onCleans && props.onCleans[value] && props.onCleans[value]();
					}}>
					{t(Labels.REPORT_CLEAN_BUTTON)}
				</Button>
			</Box>
		</Box>
	);
};

export default ReportTab;
