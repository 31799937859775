import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "~/components/common/expansionCard/Card";
import { Grid, Box, Button, CircularProgress } from "@material-ui/core";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { CancelIcon, ConfirmIcon } from "~/components/common/icons/Icons";
import Labels from "~/helpers/enums/Labels";
import Utils from "~/helpers/Utils";
import Constants from "~/helpers/enums/Constants";
import RegisterInputText from "~/components/common/registerInputs/RegisterInputText";
import RegisterPlacesAutocomplete from "~/components/common/registerInputs/RegisterPlacesAutocomplete";
import routeAction from "~/actions/routeAction";
import RouteSyncMap from "./RouteSyncMap";
import "./RouteForm.scss";

const RouteForm = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { loading } = useSelector((state) => state.app);

	const hasRoute = !!props.route;

	let route = {
		Nome: "",
		Origem: {},
		Destino: {},
		Raio: 30,
		Coordenadas: "",
		WayPoints: "",
		Cerca: "",
		Distancia: "",
	};

	if (hasRoute) {
		route = {
			...route,
			...props.route,
			Raio: props.route.Raio || "",
			Origem: {
				sugestion: {
					description: props.route.Origem || "",
				},
			},
			Destino: {
				sugestion: {
					description: props.route.Destino || "",
				},
			},
		};
	}

	return (
		<Card className={"form-card"}>
			<Formik
				initialValues={{
					...route,
				}}
				validate={(values) => {
					const errors = {};

					if (!values.Nome) {
						errors.Nome = t(Labels.ROUTE_FORM_NAME_REQUIRED);
					}

					if (!values.Origem.sugestion) {
						errors.Origem = t(Labels.ROUTE_FORM_ADDRESS_DEPARTURE_REQUIRED);
					}

					if (!values.Destino.sugestion) {
						errors.Destino = t(Labels.ROUTE_FORM_ADDRESS_ARRIVAL_REQUIRED);
					}

					if (!values.Raio) {
						errors.Raio = t(Labels.ROUTE_FORM_RATIO_REQUIRED);
					}

					if (!values.Distancia) {
						errors.Distancia = t(Labels.ROUTE_FORM_DISTANCE_REQUIRED);
					}

					return errors;
				}}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(false);

					let { Origem, Destino, ...newRoute } = values;

					newRoute = {
						...newRoute,
						Origem: (Origem.sugestion && Origem.sugestion.description) || null,
						Destino: (Destino.sugestion && Destino.sugestion.description) || null,
					};

					dispatch(
						routeAction.saveRoute(newRoute, (error) => {
							setSubmitting(false);
							if (error) {
								Utils.showError(error);
								return;
							}

							Utils.showTranslatedToast({
								type: Constants.SUCCESS,
								description: hasRoute ? Labels.ROUTE_FORM_MSG_EDIT_SUCCESS : Labels.ROUTE_FORM_MSG_SAVE_SUCCESS,
							});

							props.comeBack();
						})
					);
				}}>
				{({ values, submitForm, setFieldValue, errors, touched }) => (
					<Form className="ceabs-register-form ceabs-register-point-interest">
						<RouteSyncMap values={values} setFieldValue={setFieldValue} name="points" />

						<Grid container direction="row" alignItems="stretch" spacing={0}>
							<Grid item xs={12}>
								<RegisterInputText label={t(Labels.ROUTE_FORM_NAME)} name="Nome" />
							</Grid>

							<Grid item xs={12}>
								<RegisterPlacesAutocomplete
									margin="dense"
									label={t(Labels.ROUTE_FORM_ADDRESS)}
									placeholder={t(Labels.ROUTE_FORM_ADDRESS_DEPARTURE_PLACEHOLDER)}
									name="Origem"
								/>
							</Grid>

							<Grid item xs={12}>
								<RegisterPlacesAutocomplete
									margin="dense"
									placeholder={t(Labels.ROUTE_FORM_ADDRESS_ARRIVAL_PLACEHOLDER)}
									name="Destino"
								/>
							</Grid>

							<Grid item xs={12}>
								<RegisterInputText
									type="number"
									onKeyPress={(ev) => {
										if (["e", "-"].includes(ev.key)) ev.preventDefault();
									}}
									label={t(Labels.ROUTE_FORM_RATIO)}
									name="Raio"
									inputProps={{
										min: 0,
									}}
								/>
							</Grid>

							<Grid item xs={12}>
								<RegisterInputText disabled label={t(Labels.ROUTE_FORM_LATLNG)} name="Coordenadas" />
							</Grid>

							<Grid item xs={12}>
								<RegisterInputText disabled label={t(Labels.ROUTE_FORM_WAY_POINTS)} name="WayPoints" />
							</Grid>

							<Grid item xs={12}>
								<RegisterInputText disabled label={t(Labels.ROUTE_FORM_FENCE)} name="Cerca" />
							</Grid>

							<Grid item xs={12}>
								<RegisterInputText disabled label={t(Labels.ROUTE_FORM_DISTANCE)} name="Distancia" />
							</Grid>

							<Grid item xs={12}>
								<Grid container direction="row" justify="flex-end" spacing={0}>
									<Grid item xs={4}>
										<Box className="button-form button-form-refactor">
											<Button
												data-id="point-interest-cancel-button"
												className="cancel-button cancel-button-refactor"
												variant="contained"
												size="large"
												disableElevation
												fullWidth
												startIcon={<CancelIcon />}
												onClick={() => {
													props.comeBack && props.comeBack();
												}}
												disabled={loading.route}>
												{t(Labels.REGISTER_POINT_INTEREST_FORM_BUTTON_CANCEL)}
											</Button>
										</Box>
									</Grid>
									<Grid item xs={8}>
										<Box className="button-form button-form-refactor">
											<Button
												id="point-interest-submit-button"
												variant="contained"
												className="save-button save-button-refactor"
												color="primary"
												size="large"
												fullWidth
												disableElevation
												onClick={submitForm}
												disabled={loading.route}
												startIcon={loading.route ? <CircularProgress size={18} /> : <ConfirmIcon />}>
												{t(Labels.REGISTER_POINT_INTEREST_FORM_BUTTON_CONFIRM)}
											</Button>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Card>
	);
};

export default RouteForm;
