import Constants from "~/helpers/enums/Constants";
import DashVehicleGraphEnum from "~/components/dashboardVehicle/common/DashVehicleGraphEnum";

const mountState = (state, checked = false) => {
    return { [state?.name]: { ...state, checked } };
};

const INITIAL_STATES = {
    currentFilters: {
        ...mountState(DashVehicleGraphEnum.BASE_COMPARATIVE, true),
        ...mountState(DashVehicleGraphEnum.BASE_COMPARATIVE_ITEMS, true),
        ...mountState(DashVehicleGraphEnum.KM_RUN, true),
        ...mountState(DashVehicleGraphEnum.KM_RUN_PERIOD, true),
        ...mountState(DashVehicleGraphEnum.ROAD_OFFSET, true),
        ...mountState(DashVehicleGraphEnum.PERIOD_TRIPS, true),
        ...mountState(DashVehicleGraphEnum.FLEET_EVOLUTION_PERCENT, true),
        ...mountState(DashVehicleGraphEnum.DRIVABILITY_SCORE),
        ...mountState(DashVehicleGraphEnum.RISK_EXPOSURE_SCORE),
        ...mountState(DashVehicleGraphEnum.KM_RUN_PERIOD_DAY, true),
        ...mountState(DashVehicleGraphEnum.HEATMAP),
    },
    filterCache: undefined,
    enableVehicleDashboard: true,
};

const HIDDEN_STATE = {
    ...mountState(DashVehicleGraphEnum.BASE_COMPARATIVE),
    ...mountState(DashVehicleGraphEnum.BASE_COMPARATIVE_ITEMS),
    ...mountState(DashVehicleGraphEnum.KM_RUN),
    ...mountState(DashVehicleGraphEnum.KM_RUN_PERIOD),
    ...mountState(DashVehicleGraphEnum.ROAD_OFFSET),
    ...mountState(DashVehicleGraphEnum.PERIOD_TRIPS),
    ...mountState(DashVehicleGraphEnum.FLEET_EVOLUTION_PERCENT),
    ...mountState(DashVehicleGraphEnum.DRIVABILITY_SCORE),
    ...mountState(DashVehicleGraphEnum.RISK_EXPOSURE_SCORE),
    ...mountState(DashVehicleGraphEnum.KM_RUN_PERIOD_DAY),
    ...mountState(DashVehicleGraphEnum.HEATMAP),
};

function appReducer(state = INITIAL_STATES, action = null) {
    switch (action.type) {
        case Constants.DASHBOARD_VEHICLE_TOGGLE_MARKED_FILTER:
            return {
                ...state,
                currentFilters: {
                    ...state.currentFilters,
                    [action.name]: {
                        ...state.currentFilters[action.name],
                        checked: action.checked,
                    },
                },
            };
        case Constants.DASHBOARD_VEHICLE_HIDE_ALL_FILTERS:
            return {
                currentFilters: HIDDEN_STATE,
                filterCache: state.currentFilters,
                enableVehicleDashboard: false,
            };
        case Constants.DASHBOARD_VEHICLE_SHOW_CURRENT_FILTERS:
            return {
                currentFilters: state.filterCache || INITIAL_STATES.currentFilters,
                enableVehicleDashboard: true,
            };
        default:
            return state;
    }
}

export default appReducer;
