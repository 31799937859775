import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		width: "100%",
		height: "100%",
	},
	paper: {
		width: "100%",
		height: "100%",
		background: "#fff",
		borderRadius: "none",
	},
}));

const Toolbar = (props) => {
	const { pageToolbar } = useSelector((state) => state.app);

	const classes = useStyles();

	return (
		<div className={classes.container}>
			<Fade in={pageToolbar} style={{ transitionDelay: pageToolbar ? "200ms" : "0ms" }}>
				{props.children}
			</Fade>
		</div>
	);
};

export default Toolbar;
