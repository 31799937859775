import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CardAlert, { AlertCardItem } from "./CardAlert";
import { TextField } from "formik-material-ui";
import { Field } from "formik";
import {
    AlertIcon,
    ArchiveIcon,
    CalendarDarkIcon,
    CheckCircleIcon,
    CloseCircleIcon,
    ForwardNoFillIcon,
    SpeedometerIcon,
    ConfirmIcon,
} from "~/components/common/icons/Icons";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import alertsActions from "~/actions/alertsActions";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import useFuso from "~/components/common/fuso/useFuso";
import ContentAccordion from "~/components/common/accordion/ContentAccordion";
import Utils from "~/helpers/Utils";
import Constants from "~/helpers/enums/Constants";

import "./AlertDetails.scss";

import googleMapsDataAction from "~/actions/googleMapsDataAction";

const useStyles = makeStyles((theme) => ({
    dividerBox: {
        display: "flex",
        paddingLeft: 30,
    },
    leftDivider: {
        flex: "1 1 auto",
        paddingLeft: 5,
        paddingRight: 3,
        borderLeft: "1px solid #AFC3D2",
    },
    middle: {
        flex: "1",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
    },
}));

const AlertDetails = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { fromUtc } = useFuso();

    const { loading } = useSelector((state) => state.app);
    const { alertDetailsHistory } = useSelector((state) => state.alert);
    const { alertDetails, alertIdSelected } = useSelector((state) => state.alertsViolated);

    useEffect(() => {
        if (!alertDetails || !alertDetails.ObjetoRastreavel) return;
        dispatch(
            googleMapsDataAction.updateObjects([
                {
                    ...alertDetails.ObjetoRastreavel,
                    Latitude: alertDetails.Violacao.Evento.Gps.Latitude,
                    Longitude: alertDetails.Violacao.Evento.Gps.Longitude,
                    TipoEventoTxt: alertDetails.Violacao.Evento.EventoTipo,
                    VelocidadeTxt: alertDetails.Violacao.Evento.Gps.Velocidade,
                    MotoristaNome: alertDetails.Violacao.Evento.MotoristaNome,
                    Localizacao: alertDetails.Violacao.Evento.RevGeo.Localizacao,
                    Ignicao: alertDetails.Violacao.Evento.Ignicao,
                    DataHoraEventoLocal: alertDetails.Violacao.Evento.DataHoraEvento,
                },
            ])
        );

        dispatch(
            googleMapsDataAction.centralizePoints([
                {
                    lat: alertDetails.Violacao.Evento.Gps.Latitude,
                    lng: alertDetails.Violacao.Evento.Gps.Longitude,
                },
            ])
        );

        return () => {
            dispatch(googleMapsDataAction.cleanObjects());
        };
    }, [dispatch, alertDetails]);

    return (
        <div className="alertDetails">
            {alertDetails?.Id ? (
                <Grid container>
                    <Grid item xs={4}>
                        <Grid
                            container
                            justify="space-between"
                            alignItems="stretch"
                            style={{
                                height: "100%",
                            }}>
                            <Grid item xs={12}>
                                <CardAlert icon={<AlertIcon />} title={t(Labels.ALERT_VIOLATED_DETAILS_ALERT_TITLE)}>
                                    <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_ALERT_NAME)}>
                                        {alertDetails.Violacao.AlertaNome}
                                    </AlertCardItem>
                                    <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_ALERT_CRITERY)}>
                                        {alertDetails.Violacao.TodasCondicoesVerdadeiras
                                            ? "Todas verdadeiras"
                                            : "Contém Falsa"}
                                    </AlertCardItem>
                                    <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_ALERT_ACTIVE_DAYS)}>
                                        {Utils.translateDayOfWeek(alertDetails.Violacao.DiasSemana)}
                                    </AlertCardItem>
                                    <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_ALERT_HOUR)}>
                                        {alertDetails.Violacao.HorarioValidacaoFinal}
                                    </AlertCardItem>
                                    <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_ALERT_TREATMENT)}>
                                        {alertDetails.Violacao.DataHoraTratamento &&
                                            fromUtc(alertDetails.Violacao.DataHoraTratamento).format(
                                                "DD/MM/YYYY | HH:mm"
                                            )}
                                    </AlertCardItem>
                                    <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_ALERT_USER_TREATMENT)}>
                                        {alertDetails.Violacao.UsuarioTratamento ||
                                            alertDetails.Violacao.UsuarioConclusao}
                                    </AlertCardItem>
                                </CardAlert>
                            </Grid>
                            <Grid item xs={12}>
                                <CardAlert
                                    icon={<SpeedometerIcon />}
                                    title={t(Labels.ALERT_VIOLATED_DETAILS_CONDITIONS)}>
                                    {alertDetails.Violacao.Violacoes.map((v) => (
                                        <AlertCardItem>
                                            <div className={classes.dividerBox}>
                                                <span className={classes.leftDivider}>{v.DescricaoViolacao}</span>
                                                <span className={classes.leftDivider}>{v.CondicaoViolacao}</span>
                                                <span className={classes.leftDivider}>{v.ValorViolacao}</span>
                                            </div>
                                        </AlertCardItem>
                                    ))}
                                </CardAlert>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                }}>
                                <CardAlert
                                    icon={<ForwardNoFillIcon />}
                                    title={t(Labels.ALERT_VIOLATED_DETAILS_ACTIONS)}>
                                    {alertDetails.Violacao.Acoes.map((a) => (
                                        <AlertCardItem>{a.AcaoNome}</AlertCardItem>
                                    ))}
                                </CardAlert>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid
                            container
                            style={{
                                height: "100%",
                            }}>
                            <Grid item xs={6}>
                                <CardAlert
                                    icon={<CalendarDarkIcon />}
                                    fullHeight
                                    title={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_TITLE)}>
                                    <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_TYPE)}>
                                        {alertDetails.Violacao.Evento.EventoTipo}
                                    </AlertCardItem>
                                    <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_DATE)}>
                                        {alertDetails.Violacao.Evento.DataHoraEvento &&
                                            fromUtc(alertDetails.Violacao.Evento.DataHoraEvento).format(
                                                "DD/MM/YYYY | HH:mm"
                                            )}
                                    </AlertCardItem>
                                    <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_PLATE)}>
                                        {alertDetails.ObjetoRastreavel.Placa}
                                    </AlertCardItem>
                                    <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_LOCALIZATION)}>
                                        {Utils.formatCoordinates(alertDetails.Violacao.Evento.Gps.Latitude)},{" "}
                                        {Utils.formatCoordinates(alertDetails.Violacao.Evento.Gps.Longitude)}
                                    </AlertCardItem>
                                    <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_ADDRESS)}>
                                        {alertDetails.Violacao.Evento.RevGeo.Localizacao}
                                    </AlertCardItem>
                                    <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_DRIVER)}>
                                        {alertDetails.Violacao.Evento.MotoristaNome}
                                    </AlertCardItem>
                                    <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_MARK)}>
                                        {alertDetails.ObjetoRastreavel.Marca}
                                    </AlertCardItem>
                                    <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_MODEL)}>
                                        {alertDetails.ObjetoRastreavel.Modelo}
                                    </AlertCardItem>
                                    <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_VELOCITY)}>
                                        {alertDetails.Violacao.Evento.Gps.Velocidade || 0} Km/h
                                    </AlertCardItem>
                                    <AlertCardItem legendWidth={"auto"}>
                                        <div className={classes.dividerBox} style={{ padding: 0 }}>
                                            <span className={classes.middle}>
                                                <span className="legend">
                                                    {t(Labels.ALERT_VIOLATED_DETAILS_IGNITION)}
                                                </span>
                                                <span
                                                    className={`ignition ${
                                                        alertDetails.Violacao.Evento.Ignicao === 1
                                                            ? "ignitionOn"
                                                            : "ignitionOff"
                                                    }`}></span>
                                            </span>
                                            <span className={`${classes.leftDivider} ${classes.middle}`}>
                                                <span className="legend">{t(Labels.ALERT_VIOLATED_DETAILS_GPS)}</span>
                                                <span className="gps">
                                                    {alertDetails.Violacao.Evento.Gps.Fixado === 1 ? (
                                                        <CheckCircleIcon />
                                                    ) : (
                                                        <CloseCircleIcon />
                                                    )}
                                                </span>
                                            </span>
                                        </div>
                                    </AlertCardItem>
                                </CardAlert>
                            </Grid>
                            <Grid item xs={6}>
                                <CardAlert
                                    icon={<ArchiveIcon style={{ width: 14 }} />}
                                    fullHeight
                                    title={t(Labels.ALERT_VIOLATED_DETAILS_HISTORIC)}>
                                    <AlertCardItem style={{ padding: 0 }}>
                                        <Formik
                                            initialValues={{
                                                descricao: "",
                                            }}
                                            validate={(values) => {
                                                const errors = {};

                                                if (!values.descricao) {
                                                    errors.descricao = t(
                                                        Labels.ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED
                                                    );
                                                }

                                                return errors;
                                            }}
                                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                                const req = {
                                                    descricao: values.descricao,
                                                    alertaId: alertIdSelected,
                                                    violacaoId: alertDetails.Id,
                                                };

                                                dispatch(
                                                    alertsActions.saveHistory(req, (res) => {
                                                        resetForm();
                                                        setSubmitting(false);
                                                        dispatch(
                                                            alertsActions.getHistory({
                                                                violacaoId: alertDetails.Id,
                                                            })
                                                        );
                                                    })
                                                );
                                            }}>
                                            {({ submitForm }) => (
                                                <Form className="ceabs-register-form ceabs-register">
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        alignItems="stretch"
                                                        justify="space-between">
                                                        <Field
                                                            component={TextField}
                                                            type="text"
                                                            margin="dense"
                                                            multiline
                                                            rowsMax={4}
                                                            inputProps={{
                                                                width: "100%",
                                                                height: "calc(100% - 30px)",
                                                                fontSize: 12,
                                                            }}
                                                            name="descricao"
                                                            className="boxTextArea"
                                                        />

                                                        <Button
                                                            id="done-alert-submit-button"
                                                            variant="contained"
                                                            className="doneAlert"
                                                            color="primary"
                                                            size="large"
                                                            disableElevation
                                                            onClick={submitForm}
                                                            disabled={loading.saveAlertHistory}
                                                            startIcon={
                                                                loading.saveAlertHistory ? (
                                                                    <CircularProgress size={18} />
                                                                ) : (
                                                                    <ConfirmIcon />
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Form>
                                            )}
                                        </Formik>
                                    </AlertCardItem>
                                    <AlertCardItem style={{ padding: 0 }}>
                                        <ContentAccordion
                                            title={t(Labels.ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED_CADASTRED_TITLE)}
                                            defaultExpanded>
                                            <div
                                                style={{
                                                    overflowY: "auto",
                                                    maxHeight: "300px",
                                                    width: "100%",
                                                }}>
                                                {alertDetailsHistory?.map((register) => (
                                                    <div className="historic-item-hover">
                                                        <AlertCardItem legendWidth={"auto"}>
                                                            <div className={"alert-divider-box"} style={{ padding: 0 }}>
                                                                <span>
                                                                    <span className="divider-legend">
                                                                        {t(
                                                                            Labels.ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED_CADASTRED_USER
                                                                        )}
                                                                    </span>{" "}
                                                                    {`${register.UsuarioNome}`}
                                                                </span>
                                                                <span>
                                                                    <span className="divider-legend">
                                                                        {t(
                                                                            Labels.ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED_CADASTRED_DATE
                                                                        )}
                                                                    </span>{" "}
                                                                    {register.DataHoraCadastro &&
                                                                        fromUtc(register.DataHoraCadastro).format(
                                                                            "DD/MM/YYYY"
                                                                        )}
                                                                </span>
                                                            </div>
                                                        </AlertCardItem>
                                                        <AlertCardItem
                                                            legend={t(
                                                                Labels.ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED_CADASTRED_TREATMENT
                                                            )}>
                                                            {register.Descricao}
                                                        </AlertCardItem>
                                                        <div />
                                                    </div>
                                                ))}
                                            </div>
                                        </ContentAccordion>
                                    </AlertCardItem>
                                </CardAlert>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    display: "flex",
                                }}>
                                <Button
                                    id="alert-details-submit-button"
                                    variant="contained"
                                    className="save-button alert-conclude-button"
                                    color="primary"
                                    size="large"
                                    style={{
                                        margin: "0.5rem",
                                    }}
                                    fullWidth
                                    disableElevation
                                    onClick={() => {
                                        dispatch(
                                            alertsActions.markBoardDoneAlertsViolated(
                                                {
                                                    idAlert: alertDetails.params.alertaId,
                                                    board: alertDetails.params.placa,
                                                    violacaoId: alertDetails.params.idViolacao,
                                                },
                                                (error) => {
                                                    if (error) {
                                                        Utils.showAllErrors(error);
                                                        return;
                                                    }

                                                    Utils.showTranslatedToast({
                                                        type: Constants.SUCCESS,
                                                        description: Labels.ALERT_VIOLATED_MSG_SUCESSS,
                                                    });

                                                    dispatch(alertsActions.cleanAlertDetails());
                                                    dispatch(alertsActions.getAlertsViolated());
                                                }
                                            )
                                        );
                                    }}
                                    disabled={loading.pointInterest}
                                    startIcon={
                                        loading.pointInterest ? <CircularProgress size={18} /> : <ConfirmIcon />
                                    }>
                                    {t(Labels.ALERT_VIOLATED_DETAILS_CONCLUDE)}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Typography style={{ color: "#3D5564", fontSize: "16px", fontWeight: "bold" }}>
                    {typeof alertDetails === "string" ? alertDetails : t(Labels.ALERT_NONE_SELECTED)}
                </Typography>
            )}
        </div>
    );
};

export default AlertDetails;
