import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Box,
    Typography,
    Select,
    MenuItem,
    TextField,
    Grid,
    Button,
    IconButton,
    FormHelperText,
    InputAdornment,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { resolveValue } from "./conditionshelper";
import { Autocomplete } from "@material-ui/lab";
import MomentUtils from "@date-io/moment";
import mapAction from "~/actions/mapAction";
import { Field, FieldArray } from "formik";
import { CloseIcon, RegisterTitleIcon, CalendarIcon } from "~/components/common/icons/Icons";
import "./AlertForm.scss";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

const GenericSelect = ({ options, defaultValue, onChange, value, ...selectProps }) => {
    return (
        <Select fullWidth variant="outlined" margin="dense" onChange={onChange} {...selectProps} value={value || ""}>
            {options.map((opt, i) => (
                <MenuItem key={i} value={opt.value}>
                    {opt.label}
                </MenuItem>
            ))}
        </Select>
    );
};

const GenericBooleanSelect = ({ trueLabel, falseLabel, ...props }) => {
    return (
        <GenericSelect
            {...props}
            defaultValue="1"
            options={[
                { value: "0", label: falseLabel },
                { value: "1", label: trueLabel },
            ]}
            value={props.value ? "1" : "0"}
            onChange={(e, val) => {
                props.onChange(e, !!parseInt(e.target.value));
            }}
        />
    );
};

const GenericAutocomplete = ({ error, placeholder, name, identifier, ...props }) => {
    const [isLoading, setLoading] = useState(false);
    const { t } = useTranslation();

    return (
        <Autocomplete
            {...props}
            value={(props.value && props.value[identifier] && props.value) || ""}
            loading={isLoading}
            getOptionLabel={(opt) => (opt && opt[name]) || ""}
            getOptionSelected={(opt, val) => opt[identifier] === val[identifier]}
            onInputChange={(event, val, callBack) => {
                if (!val) {
                    return;
                }
                setLoading(true);
                props.onInputChange(event, val, () => setLoading(false));
            }}
            onChange={(e, val) => props.onChange(e, val || {})}
            closeText={t(Labels.AUTOCOMPLETE_CLOSE)}
            loadingText={t(Labels.AUTOCOMPLETE_LOADING)}
            noOptionsText={t(Labels.AUTOCOMPLETE_NOT_FOUND)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    InputProps={{
                        ...params.InputProps,
                        placeholder,
                    }}
                    error={error}
                />
            )}
        />
    );
};

const AlertConditions = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { form, label, addLabel, placeholder, options, text, identifier, loading, bottomSelect, compact } = props;
    const { pointInterestGroup, pointInterest, routes } = useSelector((state) => state.map);

    const resolveSubfieldCase1 = useCallback(
        (name, value, error, disabled) => {
            const { AlertaRegraId } = value;

            const DeafultGenericSelect = (trueLabel, falseLabel) => {
                return (
                    <Grid item xs={compact ? 4 : 6}>
                        <GenericBooleanSelect
                            value={value.Ativado}
                            error={error}
                            trueLabel={trueLabel}
                            falseLabel={falseLabel}
                            disabled={disabled}
                            onChange={(e, val) => {
                                form.setFieldValue(name, { ...value, Ativado: val });
                            }}
                        />
                    </Grid>
                );
            };

            switch (AlertaRegraId) {
                case 4:
                    return DeafultGenericSelect(
                        t(Labels.ALERT_FORM_CONDITONS_OPTION_PLATFORM_ACTIVE),
                        t(Labels.ALERT_FORM_CONDITONS_OPTION_PLATFORM_INACTIVE)
                    );
                case 5:
                    return DeafultGenericSelect(
                        t(Labels.ALERT_FORM_CONDITONS_OPTION_ENGAGE),
                        t(Labels.ALERT_FORM_CONDITONS_OPTION_DISENGAGED)
                    );
                case 44:
                case 45:
                    return DeafultGenericSelect(
                        t(Labels.ALERT_FORM_CONDITONS_OPTION_IN),
                        t(Labels.ALERT_FORM_CONDITONS_OPTION_OUT)
                    );
                case 18:
                case 50:
                case 52:
                case 53:
                case 54:
                    return null;
                case 2:
                case 6:
                case 7:
                case 10:
                case 17:
                case 29:
                case 37:
                case 38:
                case 42:
                case 43:
                case 57:
                default:
                    return DeafultGenericSelect(
                        t(Labels.ALERT_FORM_CONDITONS_OPTION_ACTIVE),
                        t(Labels.ALERT_FORM_CONDITONS_OPTION_INACTIVE)
                    );
            }
        },
        [form, compact, t]
    );

    const resolveSubfieldCase2 = useCallback(
        (name, value, error, disabled) => {
            const { AlertaRegraId } = value;

            const moreThanLessThan = [
                { value: ">", label: t(Labels.ALERT_FORM_CONDITONS_OPTION_MORE_THAN) },
                { value: "<", label: t(Labels.ALERT_FORM_CONDITONS_OPTION_LESS_THAN) },
            ];

            const numericInput = (endLabel) => (
                <TextField
                    fullWidth
                    value={value.Valor || ""}
                    variant="outlined"
                    margin="dense"
                    error={error}
                    disabled={disabled}
                    type="number"
                    onKeyPress={(ev) => {
                        if (["e", "-"].includes(ev.key)) ev.preventDefault();
                    }}
                    onChange={(e, val) => {
                        const number = parseInt(e.target.value) || 0;
                        if (number >= 0 && number < 2147483647) {
                            // Max int c#
                            form.setFieldValue(name, { ...value, Valor: e.target.value });
                        }
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{endLabel}</InputAdornment>,
                    }}
                />
            );

            const dateInput = () => (
                <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
                    <DatePicker
                        inputVariant="outlined"
                        fullWidth
                        margin="dense"
                        variant="inline"
                        minDate={new Date()}
                        format="DD/MM/yyyy"
                        value={value.DataLimite}
                        minDateMessage={t(Labels.LESS_MINIMAL)}
                        disabled={disabled}
                        onChange={(val) => {
                            form.setFieldValue(name, { ...value, DataLimite: val });
                        }}
                        InputProps={{
                            endadornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <CalendarIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </MuiPickersUtilsProvider>
            );

            const pfPjInput = () => (
                <GenericSelect
                    value={value.Valor}
                    error={error}
                    disabled={disabled}
                    options={[
                        { value: "pf", label: t(Labels.ALERT_FORM_CONDITONS_OPTION_PF) },
                        { value: "pj", label: t(Labels.ALERT_FORM_CONDITONS_OPTION_PJ) },
                    ]}
                    onChange={(e, val) => {
                        form.setFieldValue(name, { ...value, Valor: e.target.value });
                    }}
                />
            );

            const DefaultGenericField = (
                _options,
                getInput,
                endLabel = "",
                getValue = (value) => {
                    return { Operacao: value };
                }
            ) => {
                return (
                    <>
                        <Grid item xs={4}>
                            <GenericSelect
                                value={value.Operacao}
                                error={error}
                                options={_options}
                                disabled={disabled}
                                onChange={(e, val) => {
                                    form.setFieldValue(name, { ...value, ...getValue(e.target.value) });
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {getInput(endLabel)}
                        </Grid>
                    </>
                );
            };

            switch (AlertaRegraId) {
                case 11:
                    return DefaultGenericField(
                        moreThanLessThan,
                        numericInput,
                        t(Labels.ALERT_FORM_CONDITONS_LABEL_KMH),
                        (value) => {
                            return {
                                Operacao: value,
                                VelocidadeAcima: value === moreThanLessThan[0].value
                            }
                        }
                    );
                case 35:
                    return DefaultGenericField(
                        moreThanLessThan,
                        numericInput,
                        t(Labels.ALERT_FORM_CONDITONS_LABEL_MINUTES)
                    );
                case 36:
                    return DefaultGenericField(
                        moreThanLessThan,
                        numericInput,
                        t(Labels.ALERT_FORM_CONDITONS_LABEL_METERS)
                    );
                case 40:
                    return DefaultGenericField(moreThanLessThan, dateInput);
                case 48:
                    return DefaultGenericField(
                        [{ value: "=", label: t(Labels.ALERT_FORM_CONDITONS_OPTION_EQUAL) }],
                        pfPjInput
                    );
                case 46:
                    return DefaultGenericField(
                        moreThanLessThan,
                        numericInput,
                        t(Labels.ALERT_FORM_CONDITONS_LABEL_DAYS)
                    );
                case 49:
                default:
                    return DefaultGenericField(moreThanLessThan, numericInput);
            }
        },
        [form, t]
    );

    const resolveSubfield = useCallback(
        (name, value, error, disabled) => {
            const { AlertaRegraTipoId } = value;
            switch (AlertaRegraTipoId) {
                case 1:
                    return resolveSubfieldCase1(name, value, error, disabled);
                case 2:
                    return resolveSubfieldCase2(name, value, error, disabled);
                case 4:
                    return (
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                error={error}
                                value={value.Valor || ""}
                                variant="outlined"
                                margin="dense"
                                disabled={disabled}
                                onChange={(e, val) => {
                                    form.setFieldValue(name, { ...value, Valor: e.target.value });
                                }}
                                inputProps={{
                                    maxLength: 100,
                                }}
                            />
                        </Grid>
                    );
                case 5:
                    return (
                        <>
                            <Grid item xs={4}>
                                <GenericBooleanSelect
                                    value={value.DentroRota}
                                    trueLabel={t(Labels.ALERT_FORM_CONDITONS_OPTION_IN)}
                                    falseLabel={t(Labels.ALERT_FORM_CONDITONS_OPTION_OUT)}
                                    error={error}
                                    disabled={disabled}
                                    onChange={(e, val) => {
                                        form.setFieldValue(name, { ...value, DentroRota: val });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <GenericAutocomplete
                                    value={value.Rota || { title: value.CercaNome, name: value.RotaId }}
                                    name="title"
                                    identifier="name"
                                    error={error}
                                    options={routes}
                                    placeholder={t(Labels.ALERT_FORM_CONDITONS_PLACEHOLDER_SEARCH_ROUTE)}
                                    disabled={disabled}
                                    onChange={(e, val) => {
                                        form.setFieldValue(name, { ...value, RotaId: val.name, Rota: val });
                                    }}
                                    onInputChange={(event, val, callBack) => {
                                        dispatch(mapAction.getRoutes({ descricao: val }, () => callBack()));
                                    }}
                                />
                            </Grid>
                        </>
                    );
                case 10:
                    return (
                        <>
                            <Grid item xs={4}>
                                <GenericBooleanSelect
                                    value={value.DentroPontoInteresse}
                                    trueLabel={t(Labels.ALERT_FORM_CONDITONS_OPTION_IN)}
                                    falseLabel={t(Labels.ALERT_FORM_CONDITONS_OPTION_OUT)}
                                    error={error}
                                    disabled={disabled}
                                    onChange={(e, val) => {
                                        form.setFieldValue(name, { ...value, DentroPontoInteresse: val });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <GenericAutocomplete
                                    value={
                                        value.PontoInteresse || { title: value.CercaNome, name: value.PontoInteresseId }
                                    }
                                    name="title"
                                    identifier="name"
                                    error={error}
                                    options={pointInterest}
                                    disabled={disabled}
                                    placeholder={t(Labels.ALERT_FORM_CONDITONS_PLACEHOLDER_SEARCH_INTEREST_POINT)}
                                    onChange={(e, val) => {
                                        form.setFieldValue(name, {
                                            ...value,
                                            PontoInteresseId: val.name,
                                            PontoInteresse: val,
                                        });
                                    }}
                                    onInputChange={(event, val, callBack) => {
                                        dispatch(mapAction.getPointInterest({ descricao: val }, () => callBack()));
                                    }}
                                />
                            </Grid>
                        </>
                    );
                case 11:
                    return (
                        <>
                            <Grid item xs={4}>
                                <GenericBooleanSelect
                                    value={value.DentroGrupoInteresse}
                                    trueLabel={t(Labels.ALERT_FORM_CONDITONS_OPTION_IN)}
                                    falseLabel={t(Labels.ALERT_FORM_CONDITONS_OPTION_OUT)}
                                    error={error}
                                    disabled={disabled}
                                    onChange={(e, val) => {
                                        form.setFieldValue(name, { ...value, DentroGrupoInteresse: val });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <GenericAutocomplete
                                    value={
                                        value.GrupoInteresse || { text: value.CercaNome, id: value.GrupoInteresseId }
                                    }
                                    name="text"
                                    error={error}
                                    identifier="id"
                                    placeholder={t(Labels.ALERT_FORM_CONDITONS_PLACEHOLDER_SERACH_GROUP_INTEREST_POINT)}
                                    options={pointInterestGroup}
                                    disabled={disabled}
                                    onChange={(e, val) => {
                                        form.setFieldValue(name, {
                                            ...value,
                                            GrupoInteresseId: val.id,
                                            GrupoInteresse: val,
                                        });
                                    }}
                                    onInputChange={(event, val, callBack) => {
                                        dispatch(mapAction.getPointInterestGroup({ descricao: val }, () => callBack()));
                                    }}
                                />
                            </Grid>
                        </>
                    );
                case 12:
                    return (
                        <>
                            <Grid item xs={compact ? 4 : 6}>
                                <TextField
                                    fullWidth
                                    value={value.Valor || ""}
                                    variant="outlined"
                                    margin="dense"
                                    error={error}
                                    disabled={disabled}
                                    type="number"
                                    onKeyPress={(ev) => {
                                        if (["e", "-"].includes(ev.key)) ev.preventDefault();
                                    }}
                                    onChange={(e, val) => {
                                        const number = parseInt(e.target.value) || 0;
                                        if (number >= 0 && number < 2147483647) {
                                            // Max int c#
                                            form.setFieldValue(name, { ...value, Valor: e.target.value });
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {t(Labels.ALERT_FORM_CONDITONS_LABEL_MINUTES)}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </>
                    );
                case 13:
                    return (
                        <>
                            <Grid item xs={8}>
                                <GenericSelect
                                    value={value.Valor}
                                    error={error}
                                    disabled={disabled}
                                    options={[
                                        { value: "1", label: t(Labels.ALERT_FORM_CONDITONS_OPTION_ONLY_THIS_ALERT) },
                                        { value: "2", label: t(Labels.ALERT_FORM_CONDITONS_OPTION_ALL_VEHICLES) },
                                        { value: "3", label: t(Labels.ALERT_FORM_CONDITONS_OPTION_ALL_ALERTS) },
                                    ]}
                                    onChange={(e, val) => {
                                        form.setFieldValue(name, { ...value, Valor: e.target.value });
                                    }}
                                />
                            </Grid>
                        </>
                    );
                case 14:
                    return (
                        <>
                            <Grid item xs={4}>
                                <GenericBooleanSelect
                                    value={value.DentroPavimento}
                                    trueLabel={t(Labels.ALERT_FORM_CONDITONS_OPTION_IN)}
                                    falseLabel={t(Labels.ALERT_FORM_CONDITONS_OPTION_OUT)}
                                    error={error}
                                    disabled={disabled}
                                    onChange={(e, val) => {
                                        form.setFieldValue(name, { ...value, DentroPavimento: val });
                                    }}
                                />
                            </Grid>
                            {value.DentroPavimento && (
                                <Grid item xs={4}>
                                    <GenericSelect
                                        value={value.Valor}
                                        error={error}
                                        disabled={disabled}
                                        options={[
                                            { value: "0", label: t(Labels.ALERT_FORM_CONDITONS_OPTION_URBAN) },
                                            { value: "1", label: t(Labels.ALERT_FORM_CONDITONS_OPTION_ROAD) },
                                        ]}
                                        onChange={(e, val) => {
                                            form.setFieldValue(name, { ...value, Valor: e.target.value });
                                        }}
                                    />
                                </Grid>
                            )}
                        </>
                    );
                default:
                    return null;
            }
        },
        [
            dispatch,
            pointInterest,
            pointInterestGroup,
            routes,
            form,
            compact,
            resolveSubfieldCase1,
            resolveSubfieldCase2,
            t,
        ]
    );

    return (
        <Box className="register-field regsiter-array-field">
            <Typography variant="subtitle1" className="register-field-label">
                {label}
            </Typography>
            <FieldArray
                name={props.name}
                render={(arrayHelpers) => {
                    return (
                        <>
                            {form.values[props.name].map((fieldValue, index) => {
                                const fieldname = `${props.name}.${index}`;
                                return (
                                    <Box className="field-condition" key={index}>
                                        <Field name={fieldname}>
                                            {({ field, form: { touched, errors, setFieldValue }, meta }) => {
                                                const error = errors[fieldname];
                                                const isTouched = touched[props.name] && !!touched[props.name][index];

                                                const { value } = field;

                                                return (
                                                    <Grid container direction="row" alignItems="stretch" spacing={1}>
                                                        <Grid item xs={compact ? 8 : 12}>
                                                            <Autocomplete
                                                                noOptionsText={t(Labels.AUTOCOMPLETE_NOT_FOUND)}
                                                                loadingText={t(Labels.AUTOCOMPLETE_LOADING)}
                                                                closeText={t(Labels.AUTOCOMPLETE_CLOSE)}
                                                                disabled={props.disabled}
                                                                value={value}
                                                                onChange={(_, val) => {
                                                                    if (!val || !val.Codigo) {
                                                                        setFieldValue(fieldname, {});
                                                                        return;
                                                                    }

                                                                    setFieldValue(
                                                                        fieldname,
                                                                        resolveValue(val, "Codigo")
                                                                    );
                                                                }}
                                                                onBlur={(e) => field.onBlur(e)}
                                                                getOptionLabel={(opt) => opt[text] || ""}
                                                                getOptionSelected={(opt, val) =>
                                                                    opt.Codigo === val.Codigo
                                                                }
                                                                options={[
                                                                    { [text]: placeholder },
                                                                    ...options.map((o) => {
                                                                        return {
                                                                            Codigo: o[identifier],
                                                                            [text]: o[text],
                                                                        };
                                                                    }),
                                                                ]}
                                                                loading={loading}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        margin="dense"
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            placeholder,
                                                                            endAdornment: props.disabled ? null : (
                                                                                <IconButton
                                                                                    className="cancel-adorment"
                                                                                    onClick={() => {
                                                                                        arrayHelpers.remove(index);
                                                                                    }}>
                                                                                    <CloseIcon width={10} />
                                                                                </IconButton>
                                                                            ),
                                                                        }}
                                                                        error={isTouched && !!error}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        {value &&
                                                            resolveSubfield(
                                                                fieldname,
                                                                value,
                                                                isTouched && !!error,
                                                                props.disabled
                                                            )}
                                                        <FormHelperText
                                                            error
                                                            margin="dense"
                                                            variant="outlined"
                                                            className="error-condition">
                                                            {isTouched && error}
                                                        </FormHelperText>
                                                    </Grid>
                                                );
                                            }}
                                        </Field>
                                    </Box>
                                );
                            })}
                            {bottomSelect && <Box className="field-condition">{bottomSelect}</Box>}
                            <FormHelperText
                                error
                                margin="dense"
                                variant="outlined"
                                style={{
                                    position: "unset",
                                }}>
                                {form.values[props.name].length < 1 && form.errors[`${props.name}.0`]}
                            </FormHelperText>
                            {!props.disabled && (
                                <Button
                                    size="large"
                                    disableElevation
                                    fullWidth
                                    className={`add-condition-button ${!!form.errors[props.name] ? "error" : ""}`}
                                    startIcon={<RegisterTitleIcon width={18} />}
                                    onClick={() => {
                                        arrayHelpers.insert(form.values[props.name].length, {});
                                    }}>
                                    {addLabel}
                                </Button>
                            )}
                        </>
                    );
                }}
            />
        </Box>
    );
};

export default AlertConditions;
