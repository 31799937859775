import React from "react";
import ReactEcharts from "echarts-for-react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import "./KmRunPeriodWeek.scss";
import { baseColors, veiculoColors } from "./KmRunPeriodWeekFormatter";
import Utils from "~/helpers/Utils";

export default function KmRunPeriodDayBar() {
    const { waitingTravel } = useSelector((state) => state.app.loading);
    const { kmRunWeek } = useSelector((state) => state.dashboardCharts);

    const getOption = () => {
        return {
            legend: {
                orient: "horizontal",
                icon: "circle",
                bottom: 0,
                align: "left",
                itemGap: 20,
                textStyle: {
                    fontSize: 15,
                },
            },
            tooltip: {
                show: true,
                formatter: function (params) {
                    const circleColor = params.color.colorStops[0].color;
                    const circle =
                        '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
                        circleColor +
                        '"></span>';
                    const header = "<span>" + circle + " " + params.name + "</span>";

                    const value = params.componentIndex === 1 ? Utils.formatKilometers(params.data.Rodoviário) : Utils.formatKilometers(params.data.Urbano);
                    const body = `</br> <span> Valor: ${value}`;
                    return header + body;
                },
            },
            dataset: {
                source: kmRunWeek,
            },
            xAxis: { type: "category" },
            yAxis: {
                axisLabel: {
                    formatter: "{value}",
                },
            },
            // Declare several bar series, each will be mapped
            // to a column of dataset.source by default.
            series: [
                {
                    type: "bar",
                    itemStyle: {
                        color: baseColors,
                    },
                },
                {
                    type: "bar",
                    itemStyle: {
                        color: veiculoColors,
                    },
                },
            ],
        };
    };
    return (
        <Box className="chart">
            <Box className="x-axis">
                <Typography>Km Rodados</Typography>
            </Box>
            <Box className="echart">
                <ReactEcharts
                    option={getOption()}
                    showLoading={waitingTravel}
                    loadingOption={{ text: "Carregando" }}
                    style={{ height: "100%", width: "100%" }}
                />
            </Box>
        </Box>
    );
}
