import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";

import userAction from "~/actions/userAction";

import CloseIcon from "@material-ui/icons/Close";
import { Box, Button, Typography, IconButton, Grid } from "@material-ui/core";

import Card from "~/components/common/expansionCard/Card";
import CardGroup from "~/components/common/card/CardGroup";
import MenuRange from "~/components/common/menuRange/MenuRange";
import { AddIcon } from "~/components/common/icons/Icons";
import FormRegisterUser from "./FormRegisterUser";
import TabsEditionUser from "./edition/TabsEditionUser";

import "./formRegisterUser.scss";

const RegisterUser = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { users, searchTerm } = useSelector((state) => state.user);
    const loadingReq = useSelector((state) => state.app.loading);

    const [display, setDisplayUser] = useState(false);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(25);
    const [addUser, setAddUser] = React.useState(false);

    const USER_COLUMNS = [
        {
            name: "Id",
            label: "Código",
        },
        {
            name: "Nome",
            label: "Nome",
        },
        {
            name: "Email",
            label: t(Labels.REPORT_SHEDULED_EMAIL),
        },
        {
            name: "Login",
            label: "Login",
        },
        {
            name: "Ativo",
            label: "Status",
            render: (active) => <span>{active === "Sim" ? "Ativo" : "Inativo"}</span>,
        },
    ];

    useEffect(() => {
        const query = {
            index: 0,
            length: limit,
            search: searchTerm,
        };

        dispatch(userAction.getUsers({ ...query }));
        // eslint-disable-next-line
    }, [dispatch]);

    const rangeLimitUsers = (value) => {
        setLimit(value);
        const query = {
            index: 0,
            length: value,
            search: searchTerm,
        };
        dispatch(userAction.getUsers({ ...query }));
    };

    const selectUser = (data) => {
        setTimeout(() => {
            setDisplayUser(true);
            dispatch(userAction.getUserById(data.Id));
        }, 0);
    };

    const setDisplayUserOnly = () => {
        setDisplayUser(false);
    };

    const handlePageUsers = (event, value) => {
        setPage(value);

        const query = {
            index: value * 1 - 1,
            length: limit,
            search: searchTerm,
        };

        dispatch(userAction.getUsers({ ...query }));
    };

    const searchFn = (term) => {
        setPage(1);
        dispatch(userAction.setUserSearch(term));
        const query = {
            index: 0,
            length: limit,
            search: term,
        };

        dispatch(
            userAction.getUsers({
                ...query,
            })
        );
    };

    const handlerToogleNewUser = (visible) => {
        setAddUser(visible);
    };

    return (
        <Box className="box-items-group boxCardUserAdd">
            {addUser && (
                <Grid container className="box-group boxFormAddUser">
                    <Grid item xs={4}>
                        <Box className="container-aditional-group-card">
                            <Card
                                topButtons={
                                    <IconButton onClick={() => handlerToogleNewUser(false)}>
                                        <CloseIcon style={{width: 18, height: 18}} />
                                    </IconButton>
                                }>
                                <FormRegisterUser onFinish={handlerToogleNewUser} />
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            )}
            <Box className="box-group">
                {!addUser && (
                    <Box className="container-aditional-group-card">
                        <Button
                            data-id={String("Adicionar-Usuario-id").replace(/ de /g, "").replace(/\s/g, "")}
                            className="btn-add-group-items btn-add-group-items-smaller"
                            variant="contained"
                            color="primary"
                            disableElevation
                            style={{
                                margin: "0 0 20px 0",
                            }}
                            onClick={() => {
                                handlerToogleNewUser(true);
                            }}>
                            <Typography className="import-font">
                                <AddIcon />
                                Adicionar Usuário
                            </Typography>
                        </Button>
                    </Box>
                )}

                <Box className="container-group-card">
                    <Box className="container-group-card-item">
                        <CardGroup
                            classTitle="boxTitle-refactor"
                            data-id={String(t(Labels.GROUP_POINTS_LIST_LABEL) + "-id")
                                .replace(/ de /g, "")
                                .replace(/\s/g, "")}
                            title={"Usuário" /*t(Labels.GROUP_POINTS_LIST_LABEL)*/}
                            classHeader={"search-group-item"}
                            loading={loadingReq.getUsers}
                            columns={USER_COLUMNS}
                            source={users.data}
                            select={selectUser}
                            page={page}
                            totalItems={users.recordsTotal}
                            itemsPerPage={limit}
                            handlePage={handlePageUsers}
                            searchFn={searchFn}
                            searchValue={searchTerm}
                            arrowCheck
                            headerComp={
                                <div className="boxRangeAlerts">
                                    <MenuRange
                                        classNameRefactor="menu-label-range-refactor"
                                        label={t(Labels.MENU_RANGE_SHOW)}
                                        value={limit}
                                        onChange={(event) => {
                                            rangeLimitUsers(event.target.value);
                                        }}
                                        options={[10, 25, 50, 100, 250, 500]}
                                    />
                                </div>
                            }
                        />
                    </Box>
                    <Box className="container-group-card-item">
                        {display && (
                            <CardGroup
                                data-id={"cadastrar-usuario-tabs-id"}
                                title={""}
                                className="group-items cardUserTab"
                                classHeader={"search-group-item"}
                                loading={
                                    loadingReq.getUserById || loadingReq.userActivationById || loadingReq.editUser
                                }>
                                <TabsEditionUser onDisplay={setDisplayUserOnly} />
                            </CardGroup>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default RegisterUser;
