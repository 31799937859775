import React from 'react';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default function SwitchLabels(props) {
  const [checked, setState] = React.useState(false);

  const handleChange = (event) => {
    setState(event.target.checked);
    props.onChanged(event.target.checked)
  };

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Switch
            {...props}
            checked={checked}
            onChange={handleChange}
            name={props.name}
            id={props.id}
            data-id={"checked-"+props.label}
            color="primary"
          />
        }
        label={props.label}
      />
    </FormGroup>
  );
}
