import React from "react";
import { fade, makeStyles } from "@material-ui/core";
import TabMain from "~/components/common/tab/TabMain";
import EditAlerts from "~/components/alert/listing/tabs/editAlert/EditAlert";
import HistoricAlerts from "~/components/alert/listing/tabs/historicAlerts/HistoricAlerts";
import ImportCars from "~/components/alert/listing/tabs/importCars/ImportCars";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTabs-root": {
            background: fade(theme.palette.primary.main, 0.15),
            padding: "20px 0px 0 22px",
        },
    },
}));

const TabsAlerts = ({ closeTab }) => {
    const { t } = useTranslation();


    const TABS = [
        {
            id: 0,
            label: t(Labels.ALERT_TAB_EDIT),
            children: <EditAlerts closeTab={closeTab} />
        },
        {
            id: 1,
            label: t(Labels.ALERT_TAB_IMPORT_CARS),
            children: <ImportCars />,
        },
        {
            id: 2,
            label: t(Labels.ALERT_TAB_HISTORIC),
            children: <HistoricAlerts />,
        },
    ];

    const classes = useStyles();
    return (
        <TabMain id="TabAlerts" tabs={TABS} className={classes.root} classNameLabelRefactor="tab-label-refactor" />
    );
};

export default TabsAlerts;
