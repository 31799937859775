import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import Utils from "~/helpers/Utils";

import importDriverAction from "~/actions/drivers/importDriverAction";
import importDriverService from "~/services/driver/importDriverService";

import { Form, Formik } from "formik";
import { Button, CircularProgress, Grid, Typography, Box } from "@material-ui/core";

import Autocomplete from "~/components/common/autocomplete/AutoComplete";
import Card from "~/components/common/expansionCard/Card";
import FileInput from "~/components/common/inputFile/FileInput";
import Constants from "~/helpers/enums/Constants";

import { BackIcon, CancelIcon, ConfirmIcon } from "~/components/common/icons/Icons";

import "~/components/drivers/import/ImportDriver.scss";

const ImportDriver = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { loading } = useSelector((state) => state.app);
    const { data } = useSelector((state) => state.importDriver);

    const dwFile = async () => {
        const link = await importDriverService.downloadFile();
        window.open(link.data);
    };

    useEffect(() => {
        dispatch(importDriverAction.listGroupDriver());
    }, [dispatch]);

    const fnCallback = (msg, type) => {
        Utils.showTranslatedToast({
            type: type,
            description: msg,
        });
    };

    const convertFile = async (file) => {
        if (file === undefined) {
            return;
        }

        const newFile64 = await Utils.fileToBase64(file);

        let fileBase64 = Utils.stringBase64ToObjectBase64(newFile64);

        const file64 = {
            fileName: file.name,
            size: file.size,
            fileData: fileBase64.data,
        };
        return file64;
    };

    return (
        <>
            <Card
                title={
                    <span className="import-title">
                        <span>
                            <BackIcon style={{ width: 24, height: 24, border: "2px solid", borderRadius: "20px" }} />
                        </span>
                        {t(Labels.LABEL_IMPORT_DRIVER)}
                    </span>
                }
                className="card-import card-group-refactor">
                <Formik
                    validateOnBlur={false}
                    validateOnChange={false}
                    initialValues={{
                        file: null,
                        groupDriverID: null,
                    }}
                    validate={(values) => {}}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);

                        if (!values.file) {
                            fnCallback(t(Labels.ERROR_IMPORT_CHOICE), "error");
                            return;
                        }
                        if (!Utils.isFile(values.file.fileName, [".xls", ".xlsx"])) {
                            fnCallback(t(Labels.ERROR_IMPORT_TYPE_FILE), "error");
                            return;
                        }

                        dispatch(
                            importDriverAction.importFile(values, (msg) => {
                                Utils.showTranslatedToast({
                                    type: msg.includes && msg.includes("sucesso") ? Constants.SUCCESS : Constants.ERROR,
                                    description: msg,
                                });
                            })
                        );
                    }}
                    render={({ submitForm, setFieldValue, values, resetForm }) => {
                        return (
                            <Form className="ceabs-register-form">
                                <Grid
                                    container
                                    direction="column"
                                    justify="flex-end"
                                    alignItems="flex-start"
                                    spacing={2}
                                    className="content">
                                    <Grid direction="column">
                                        <Grid className="grid-item">
                                            <FileInput
                                                forID="file-input"
                                                label={t(Labels.FILE_LABEL_IMPORT_DRIVER)}
                                                placeHolderInput={t(Labels.PLACEHOLDER_IMPORT_DRIVER)}
                                                labelButton={t(Labels.CHOICE_LABEL)}
                                                value={values.file?.fileName}
                                                backIconStyle={{ border: "2px solid", borderRadius: "20px" }}>
                                                <input
                                                    id="file-input"
                                                    data-id={t(Labels.FILE_LABEL_IMPORT_DRIVER)
                                                        .replace(/ de /g, "")
                                                        .replace(/\s/g, "")}
                                                    name="file"
                                                    type="file"
                                                    accept=".xlsx, .xls"
                                                    onChange={async (event) => {
                                                        const file64 = await convertFile(event.currentTarget.files[0]);
                                                        setFieldValue("file", file64);
                                                    }}
                                                    className="form-control"
                                                />
                                            </FileInput>
                                            <span
                                                className="link-import"
                                                data-id={t(Labels.LINK_LABEL_IMPORT_DRIVER)
                                                    .replace(/ de /g, "")
                                                    .replace(/\s/g, "")}
                                                onClick={dwFile}>
                                                <Typography className="import-font">
                                                    {t(Labels.LINK_LABEL_IMPORT_DRIVER)}
                                                </Typography>
                                            </span>
                                        </Grid>
                                        <Grid className="grid-item">
                                            <Autocomplete
												name="groupDriverID"
												isRegisterForm
                                                label={t(Labels.GROUP_DRIVER_LABEL_IMPORT_DRIVER)}
                                                placeholder={t(Labels.SEARCH_FILE_IMPORT_DRIVER)}
                                                dataSource={data ? data : []}
                                                onChange={() => {}}
                                                loading={loading.listGroupDriver}
                                                inputChange={(_, value) => {
                                                    dispatch(
                                                        importDriverAction.listGroupDriver({ description: value })
                                                    );
                                                }}
                                            />
                                            <span className="link-import">
                                                <Typography className="import-font">
                                                    {t(Labels.INFO_LABEL_GROUP_IMPORT_DRIVER)}
                                                </Typography>
                                            </span>
                                        </Grid>
                                        <Grid container xs={12} md={12}>
											<Grid item xs={4} md={4}>
                                                <Box id="points-import-cancel-button" className="button-form button-form-refactor">
                                                    <Button
                                                        data-id="import-cancel"
                                                        className="cancel-button cancel-button-refactor"
                                                        variant="contained"
                                                        disableElevation
                                                        onClick={() => {
                                                            resetForm();
                                                        }}>
                                                        <CancelIcon />
                                                        {t(Labels.BTN_CANCEL_IMPORT_DRIVER)}
                                                    </Button>
                                                </Box>
                                            </Grid>
											<Grid item xs={8} md={8}>
                                                <Box id="points-import-save-button" className="button-form button-form-refactor">
                                                    <Button
                                                        data-id="import-button"
                                                        className="save-button save-button-refactor"
                                                        variant="contained"
                                                        color="primary"
                                                        disableElevation
                                                        disabled={loading.importDriver}
                                                        onClick={submitForm}
                                                        startIcon={
                                                            loading.importDriver ? (
                                                                <CircularProgress size={18} />
                                                            ) : (
                                                                <ConfirmIcon />
                                                            )
                                                        }>
                                                        {t(Labels.BTN_CONFIRM_IMPORT_DRIVER)}
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                />
            </Card>
        </>
    );
};

export default ImportDriver;
