import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import TableCardReUse from "~/components/tableCard/TableCardReUse";
import Loading from "~/components/common/loading/LoadingPanel";

const KmRunBase = () => {
    const [load, setLoad] = useState(true);
    const { dataBase } = useSelector((state) => state.dashboardDataVehicle);
    const { loading } = useSelector((state) => state.app);

    useEffect(() => {
        setTimeout(() => {
            setLoad(false);
        }, 2000);
    });

    const COLUMNS = [
        {
            name: "INFO",
            label: "Informação",
            render: (info) => <span>{info}</span>,
        },
        {
            name: "TOTAL",
            label: "Total do Veículo",
            render: (total) => <span>{total ? total.toLocaleString("pt-br") : 0}</span>,
        },
        {
            name: "MEDIA_VEHICLE",
            label: "Média Diária do Veículo",
            render: (mVehicle) => <span>{mVehicle ? mVehicle.toLocaleString("pt-br", { maximumFractionDigits: 1 }) : 0}</span>,
        },
        {
            name: "MEDIA_BASE",
            label: "Média Diária da Base",
            render: (mBase) => <span>{mBase ? mBase.toLocaleString("pt-br", { maximumFractionDigits: 1 }) : 0}</span>,
        },
    ];

    const DATA = [
        {
            INFO: "Viagens",
            TOTAL: dataBase.Veiculo?.QtdViagem,
            MEDIA_VEHICLE: dataBase.Veiculo?.MediaQtdViagem,
            MEDIA_BASE: dataBase.Base?.MediaQtdViagem,
        },
        {
            INFO: "Total Percorrido",
            TOTAL: dataBase.Veiculo?.Distancia,
            MEDIA_VEHICLE: dataBase.Veiculo?.MediaDistancia,
            MEDIA_BASE: dataBase.Base?.MediaDistancia,
        },
        {
            INFO: "Total Percorrido Urbano",
            TOTAL: dataBase.Veiculo?.DistanciaUrbano,
            MEDIA_VEHICLE: dataBase.Veiculo?.MediaDistanciaUrbano,
            MEDIA_BASE: dataBase.Base?.MediaDistanciaUrbano,
        },
        {
            INFO: "Total Percorrido Rodoviário",
            TOTAL: dataBase.Veiculo?.DistanciaRodoviario,
            MEDIA_VEHICLE: dataBase.Veiculo?.MediaDistanciaRodoviario,
            MEDIA_BASE: dataBase.Base?.MediaDistanciaRodoviario,
        },
        {
            INFO: "Total Percorrido HC",
            TOTAL: dataBase.Veiculo?.DistanciaComercial,
            MEDIA_VEHICLE: dataBase.Veiculo?.MediaDistanciaComercial,
            MEDIA_BASE: dataBase.Base?.MediaDistanciaComercial,
        },
        {
            INFO: "Total Percorrido FHC",
            TOTAL: dataBase.Veiculo?.DistanciaNaoComercial,
            MEDIA_VEHICLE: dataBase.Veiculo?.MediaDistanciaNaoComercial,
            MEDIA_BASE: dataBase.Base?.MediaDistanciaNaoComercial,
        },
        {
            INFO: "Total Percorrido FDS",
            TOTAL: dataBase.Veiculo?.DistanciaFimSemana,
            MEDIA_VEHICLE: dataBase.Veiculo?.MediaDistanciaFimSemana,
            MEDIA_BASE: dataBase.Base?.MediaDistanciaFimSemana,
        },
    ];

    const isLoading = loading.waitingTravel || load;

    return (
        <div className="cardBaseKmRunTable">
            {isLoading && (
                <div className="loadingGridData">
                    <Loading size={40} />
                </div>
            )}
            <TableCardReUse columns={COLUMNS} dataSource={DATA} />
        </div>
    );
};

export default KmRunBase;
