import React from "react";
import { Switch } from "formik-material-ui";
import { withStyles } from "@material-ui/core/styles";
import { Box, FormControlLabel } from "@material-ui/core";
import { Field } from "formik";
import "./RegisterComponents.scss";

const RegisterSwitch = (props) => {
	const { label, placement, color, ...fieldProps } = props;

	return (
		<Box className="register-field register-switch">
			<FormControlLabel
				labelPlacement={placement || "start"}
				control={
					<Field
						component={withStyles({
							switchBase: {
								color: color,
								"&$checked": {
									color: color,
								},
								"&$checked + $track": {
									backgroundColor: color,
								},
							},
							checked: {},
							track: {},
						})(Switch)}
						type="checkbox"
						{...fieldProps}
					/>
				}
				label={label}
			/>
		</Box>
	);
};

export default RegisterSwitch;
