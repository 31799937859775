const extractData = ({ data }) => {
	const [item] = data;

	const {
		QtdAceleracaoBrusca,
		QtdCurvaAgressiva,
		QtdFreadaBrusca,
		QtdLimiteVelocidadeVia,
		QtdViagemNaoComercial,
		QtdViagemFimSemana,
	} = item;

	const payload = {
		QtdAceleracaoBrusca,
		QtdCurvaAgressiva,
		QtdFreadaBrusca,
		QtdLimiteVelocidadeVia,
		QtdViagemNaoComercial,
		QtdViagemFimSemana,
	};

	return payload;
};

export default { extractData };
