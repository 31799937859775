import http from "~/config/httpRequest/http";

const CONTROLLER_NAME = "login/Entrar";

export const login = (credentials) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/${CONTROLLER_NAME}`, credentials)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const logout = () => {
	return new Promise((resolve, reject) => {
		http
			.get(`/login/sair`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
}

export default { login, logout };
