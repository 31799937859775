import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, Paper, Box, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import GoogleScriptLoader from "~/components/common/googleScriptLoader/GoogleScriptLoader";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import "./PlacesAutocomplete.scss";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

const poweredPaperComponent = (props) => (
	<Paper {...props}>
		{props.children}
		<Box className="powered-by-google-box">
			<img
				src="https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png"
				alt="Powered by Google"
			/>
		</Box>
	</Paper>
);

const PlacesAutocompleteComponent = ({ inputValue, ...props }) => {
	const {
		value,
		suggestions: { loading, data },
		setValue,
		clearSuggestions,
	} = usePlacesAutocomplete();

	useEffect(() => {
		setValue(inputValue, false);
	}, [setValue, inputValue]);

	const { t } = useTranslation();

	return (
		<Autocomplete
			inputValue={value || ""}
			options={data}
			loading={loading}
			popupIcon={null}
			noOptionsText={"Sem opções"}
			loadingText={t(Labels.AUTOCOMPLETE_LOADING)}
			onBlur={props.onBlur}
			onChange={(event, sugestion) => {
				if (!sugestion) {
					props.onChange && props.onChange({ latlng: {}, sugestion: sugestion });
					return;
				}

				setValue(sugestion.description, false);
				clearSuggestions();

				getGeocode({ address: sugestion.description })
					.then((results) => getLatLng(results[0]))
					.then((latlng) => {
						props.onChange && props.onChange({ latlng: latlng, sugestion: sugestion });
					})
					.catch((error) => {
						props.onError && props.onError(error);
					});
			}}
			PaperComponent={poweredPaperComponent}
			getOptionLabel={(option) => option.description || ""}
			getOptionSelected={(option, valuer) => option.description === valuer.description}
			renderOption={({ id, structured_formatting: { main_text, secondary_text } }, { selected }) => (
				<div key={id} className="places-options">
					<Typography variant="body1">
						<strong>{main_text}</strong> {secondary_text}
					</Typography>
				</div>
			)}
			renderInput={(textFieldProps) => {
				return (
					<TextField
						{...textFieldProps}
						onChange={(e) => {
							setValue(e.target.value);
						}}
						margin={props.margin}
						placeholder={props.placeholder}
						variant="outlined"
						helperText={props.helperText}
						error={props.error}
						className={`text-field-places ${props.borded && "borded"}`}
						InputProps={{
							...textFieldProps.InputProps,
							endAdornment: props.endAdornment || textFieldProps.InputProps.endAdornment,
						}}
					/>
				);
			}}
		/>
	);
};

const PlacesAutocomplete = (props) => {
	return (
		<GoogleScriptLoader>
			<PlacesAutocompleteComponent {...props} />
		</GoogleScriptLoader>
	);
};

PlacesAutocomplete.propTypes = {
	onSelect: PropTypes.func,
	onError: PropTypes.func,
	placeholder: PropTypes.string,
};

export default PlacesAutocomplete;
