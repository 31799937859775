import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import profileService from "~/services/profileService";

const getProfile = (id, callback = () => {}, LOADING_IDENTIFICATOR = "profile") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

    return profileService
        .getProfile(id)
        .then((response) => {
            const profile = response.data;
            dispatch({ type: Constants.GET_PROFILE, payload: profile });
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const saveProfile = (profile, callback = () => {}, LOADING_IDENTIFICATOR = "profile") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

    return profileService
        .saveProfile(profile)
        .then((response) => {
            callback(false);
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getFusosByCountry = (countryId, language, callback = () => {}, LOADING_IDENTIFICATOR = "profile") => (
    dispatch
) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return profileService
        .getFusosByCountry(countryId, language)
        .then(({ data }) => {
            dispatch({ type: Constants.GET_FUSOS_BY_COUNTRY, payload: data });
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const cleanProfile = () => (dispatch) => {
    dispatch({ type: Constants.CLEAN_PROFILE });
};

export default {
    getProfile,
    saveProfile,
    cleanProfile,
    getFusosByCountry,
};
