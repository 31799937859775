const extractData = ({ data: { Veiculo }, ...rest }) => {
	const {
		DistanciaEvolucaoPercentual,
		DistanciaFimSemanaEvolucaoPercentual,
		DistanciaComercialEvolucaoPercentual,
		DistanciaNaoComercialEvolucaoPercentual,
		QtdAceleracaoBruscaPercentualEvolucao,
		QtdCurvaAgressivaPercentualEvolucao,
		QtdFreadaBruscaPercentualEvolucao,
		QtdLimiteVelocidadeViaPercentualEvolucao,
	} = Veiculo;
	const payload = {
		DistanciaEvolucaoPercentual,
		DistanciaFimSemanaEvolucaoPercentual,
		DistanciaComercialEvolucaoPercentual,
		DistanciaNaoComercialEvolucaoPercentual,
		QtdAceleracaoBruscaPercentualEvolucao,
		QtdCurvaAgressivaPercentualEvolucao,
		QtdFreadaBruscaPercentualEvolucao,
		QtdLimiteVelocidadeViaPercentualEvolucao,
	};
	return payload;
};

export default { extractData };
