import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
    pointInterestList: {
        pagination: {
            page: 0,
            limit: 25,
            search: "",
        },
        data: [],
        locationObjectsMaps: [],
    },
    pointsInterest: [],
    pointInterest: null,
    pointInterestTypes: [],
    savedPointInterest: {},
};

function pointsInterestReducer(state = INITIAL_STATE, action = null) {
    switch (action.type) {
        case Constants.GET_LIST_POINT_INTEREST:
            return {
                ...state,
                pointInterestList: {
                    ...state.pointInterestList,
                    data: action.payload.data,
                    pagination: action.payload.pagination,
                },
            };
        case Constants.GET_OBJECTS_POINT_INTEREST:
            return {
                ...state,
                pointInterestList: {
                    ...state.pointInterestList,
                    locationObjectsMaps: action.payload,
                },
            };
        case Constants.GET_POINT_INTEREST_TYPES:
            return {
                ...state,
                pointInterestTypes: action.payload.pointInterestTypes,
            };
        case Constants.GET_POINT_INTEREST_BY_ID:
            return {
                ...state,
                pointInterest: action.payload.pointInterest,
            };
        case Constants.UPDATE_PAGINATION_POINT_INTEREST:
            return {
                ...state,
                pointInterestList: {
                    ...state.pointInterestList,
                    pagination: action.payload.pagination,
                },
            };
        case Constants.SAVE_POINT_INTEREST:
            return {
                ...state,
                savedPointInterest: action.payload,
            };
        case Constants.CLEAN_POINT_INTEREST:
            return {
                ...state,
                pointInterest: INITIAL_STATE.pointInterest,
            };
        default:
            return state;
    }
}

export default pointsInterestReducer;
