import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import vehicleAction from "~/actions/vehicleAction";
import { Box } from "@material-ui/core";
import CardGroup from "~/components/common/card/CardGroup";
import MenuRange from "~/components/common/menuRange/MenuRange";
import TabsVehicles from "~/components/vehicle/listing/tabs/TabsVehicles";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

import "./ListingVehicles.scss";

const ListingVehicles = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { vehicles, vehicleFilter } = useSelector((state) => state.vehicle);
    const { loading } = useSelector((state) => state.app);
    const [displayView, setDisplayView] = useState(false);

    useEffect(() => {
        dispatch(vehicleAction.getListVehicles({ ...vehicleFilter }));
    }, [dispatch, vehicleFilter]);

    useEffect(() => {
        return () => {
            dispatch(vehicleAction.cleanVehicleList());
        };
    }, [dispatch]);

    const COLUMNS_VEHICLES = [
        {
            name: "Placa",
            label: t(Labels.COLUMN_PLATE),
            render: (name) => <span>{name}</span>,
        },
        {
            name: "Identificacao",
            label: t(Labels.COLUMN_IDENTIFICATION),
            render: (id) => <span>{id}</span>,
        },
        {
            name: "MarcaModelo",
            label: t(Labels.COLUMN_BRAND),
            render: (marcaModelo) => <span>{marcaModelo}</span>,
        },
        {
            name: "AnoFabricacao",
            label: t(Labels.COLUMN_Y_FABRICATION),
            render: (anoFabricacao) => <span>{anoFabricacao}</span>,
        },

        {
            name: "AnoModelo",
            label: t(Labels.COLUMN_Y_MODEL),
            render: (anoModelo) => <span>{anoModelo}</span>,
        },
        {
            name: "Chassi",
            label: t(Labels.COLUMN_CHASSI),
            render: (chassi) => <span>{chassi}</span>,
        },
    ];

    return (
        <Box className="container-group-card">
            <Box className="container-group-card-item">
                <CardGroup
                    data-id={"listar-veiculos-id"}
                    title={t(Labels.VEHICLE_LIST_TITLE)}
                    classHeader={"search-group-item"}
                    className="card-group-refactor"
                    loading={loading.getListVehicles}
                    columns={COLUMNS_VEHICLES}
                    source={vehicles.data}
                    searchFn={(value) => {
                        dispatch(
                            vehicleAction.updateVehicleFilter({
                                search: value,
                                page: 0,
                            })
                        );
                    }}
                    headerComp={
                        <div className="boxRangeVehicles">
                            <MenuRange
                                classNameRefactor="menu-label-range-refactor"
                                label={t(Labels.MENU_RANGE_SHOW)}
                                value={vehicleFilter.limit}
                                onChange={(event) => {
                                    dispatch(
                                        vehicleAction.updateVehicleFilter({
                                            limit: event.target.value,
                                            page: 0,
                                        })
                                    );
                                }}
                                options={[10, 25, 50, 100, 250, 500]}
                            />
                        </div>
                    }
                    select={(_item) => {
                        dispatch(vehicleAction.getVehicleById(_item.Id));
                        dispatch(vehicleAction.getOdometerById(_item.Id));
                        setDisplayView(true);
                    }}
                    arrowCheck
                    page={vehicleFilter.page + 1}
                    itemsPerPage={vehicleFilter.limit}
                    totalItems={vehicles.recordsTotal}
                    handlePage={(event, value) => {
                        dispatch(
                            vehicleAction.updateVehicleFilter({
                                page: value - 1,
                            })
                        );
                    }}
                />
            </Box>
            <Box className="container-group-card-item">
                {displayView && (
                    <CardGroup
                        data-id={"cadastrar-alertas-tabs-id"}
                        className="no-padding card-vehicles-list-tabs"
                        classHeader={"search-group-item"}>
                        <TabsVehicles
                            closeTab={() => {
                                setDisplayView(false);
                            }}
                        />
                    </CardGroup>
                )}
            </Box>
        </Box>
    );
};

export default ListingVehicles;
