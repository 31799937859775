import Constants from "~/helpers/enums/Constants";
import ReportTypeEnum from "~/helpers/enums/ReportTypeEnum";
import reportingService from "~/services/reportingService";
import Utils from "~/helpers/Utils";

const EVENTS_REPORT_ID = ReportTypeEnum.EVENTS.id;

const getEventsReportScheduling = (params) => (dispatch) => {
    return reportingService
        .getReportSchedulingByType(params, EVENTS_REPORT_ID)
        .then((response) => {
            const { data } = response.data;
            dispatch({ type: Constants.GET_EVENTS_REPORT_SCHEDULING, payload: { data } });
        })
        .catch((error) => {
            console.log(error);
        });
};

const getEventsReportSent = (params) => (dispatch) => {
    return reportingService
        .getReportSentByType(params, EVENTS_REPORT_ID)
        .then((response) => {
            const { index, recordsTotal, recordsFiltered, data } = response;
            //TODO Apply pagination
            const pagination = {
                index,
                recordsTotal,
                recordsFiltered,
            };
            dispatch({ type: Constants.GET_EVENTS_REPORT_SENT, payload: { data: data.data, pagination } });
        })
        .catch((error) => {
            console.log(error);
        });
};

const saveEventsReportScheduling = (report, callback = () => {}) => (dispatch) => {
    return reportingService
        .saveDistanceReportScheduling(report, EVENTS_REPORT_ID)
        .then((response) => {
            callback(false, response);
        })
        .catch((error) => {
            callback(error);
            console.error(error);
        });
};

const finishEditionEventsReportScheduling = () => (dispatch) => {
    dispatch({ type: Constants.EDIT_EVENTS_REPORT_SCHEDULING, payload: {} });
};

const getEventsReportSchedulingById = (id, callback = () => {}) => (dispatch) => {
    return reportingService
        .getDistanceReportSchedulingById(id)
        .then(({ data }) => {
            dispatch({ type: Constants.EDIT_EVENTS_REPORT_SCHEDULING, payload: data.data });
            callback();
        })
        .catch((error) => {
            callback(error);
        });
};

const requestPerObject = (params, callback = () => {}, LOADING_IDENTIFICATOR = "requestObject") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return reportingService
        .requestReportingPerObject(params)
        .then(({ data }) => {
            callback();

            if (data.data) {
                dispatch({ type: Constants.REQUEST_EVENT_PER_OBJECT, payload: data });
            } else if (data.Message) {
                if (!data.Success) {
                    Utils.showError(data.Message);
                    return;
                }
                Utils.downloadToLink(data.Message);
            }
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const updateFilterEventObject = (filter) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FILTER_EVENT_OBJECT, payload: { filter } });
};

const updateFilterEventPeriod = (filter) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FILTER_EVENT_PERIOD, payload: { filter } });
};

const updatePeriodForm = (form) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FORM_EVENT_PERIOD, payload: { form } });
};

const updateScheduleForm = (form) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FORM_EVENT_SCHEDULED, payload: { form } });
};

const updateObjectForm = (form) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FORM_EVENT_OBJECT, payload: { form } });
};

const updateFilterEventScheduled = (filter) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FILTER_EVENT_SCHEDULED, payload: { filter } });
};

const cleanFilterEventObject = () => (dispatch) => {
    dispatch({ type: Constants.CLEAN_FILTER_EVENT_OBJECT });
};

const cleanFilterEventPeriod = () => (dispatch) => {
    dispatch({ type: Constants.CLEAN_FILTER_EVENT_PERIOD });
};

const cleanFilterEventScheduled = () => (dispatch) => {
    dispatch({ type: Constants.CLEAN_FILTER_EVENT_SCHEDULED });
};
export default {
    getEventsReportScheduling,
    getEventsReportSent,
    getEventsReportSchedulingById,
    saveEventsReportScheduling,
    finishEditionEventsReportScheduling,
    updateFilterEventObject,
    updateFilterEventPeriod,
    updatePeriodForm,
    updateScheduleForm,
    updateFilterEventScheduled,
    cleanFilterEventObject,
    cleanFilterEventPeriod,
    cleanFilterEventScheduled,
    updateObjectForm,
    requestPerObject,
};
