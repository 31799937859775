import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import Labels from "~/helpers/enums/Labels";
import Utils from "~/helpers/Utils";

import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import { Grid, Button, CircularProgress, Tabs, Tab, Typography } from "@material-ui/core";
import { ConfirmIcon, CancelIcon } from "~/components/common/icons/Icons";
import RegisterInputText from "~/components/common/registerInputs/RegisterInputText";
import RegisterSwitch from "~/components/common/registerInputs/RegisterSwitch";
import userAction from "~/actions/userAction";

import AccordionAlert from "~/components/common/accordion/ContentAccordion";
import RegisterCheckbox from "~/components/common/registerInputs/RegisterCheckbox";

import TableCard from "~/components/tableCard/TableCardReUse";
import CardGroupUserFleets from "~/components/common/card/CardGroup";

const regexName = RegExp(/^(?=[a-z-A-Z]*[a-z-A-Z])([a-z-A-Z_\\1]*[a-z-A-Z])([a-z-A-Z{.\\1}]*[a-z-A-Z\\1])$/);
//RegExp(/^[a-z-A-Z_.0-9]{1,16}$/);
//;;

const FormRegisterUser = (props) => {
    const { onFinish } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { loading } = useSelector((state) => state.app);
    const { fleets, permissions } = useSelector((state) => state.user);

    const [tabActive, setTabActive] = React.useState(0);
    const [checkedObject, setCheckedObject] = React.useState({});
    const [functionalities, setFunctionalities] = React.useState([]);
    const [initialValues, setInitialValues] = React.useState("");
    const [disabledTab, setDisabledTab] = React.useState(true);
    const [fleetsSelected, setFleetsSelected] = React.useState([]);
    const [fleetsObj, setFleetsObj] = React.useState([]);
    const [fleetsCompleted, setFleetCompleted] = React.useState(false);
    const [displayFleets, setDisplayFleets] = React.useState(true);

    useEffect(() => {
        dispatch(userAction.getFleetByUserId(0));
        dispatch(userAction.getPermissionsByUserId(0));
    }, [dispatch]);

    const handlerToogleNewUser = () => {
        setTimeout(() => {
            onFinish(false);
        }, 1500);
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    const handleChangeTab = async (event, newValue) => {
        setTabActive(newValue);
    };

    const handleChangeChecked = (_item, _data) => {
        if (_item.id === parseInt(_data)) {
            setCheckedObject({
                ...checkedObject,
                [_item.id]: !checkedObject[_item.id],
            });

            let funcs = functionalities.filter(function (e) {
                return e !== _item.id;
            });

            let itemFunc = functionalities.filter(function (e) {
                return e === _item.id;
            });

            if (_.isEmpty(itemFunc)) {
                funcs.push(_item.id);
            }

            setFunctionalities(funcs);
        }
    };

    const handleChangeFleets = (newSelected, data, selected, name) => {
        let fleetCheck;

        fleetCheck = fleetsSelected.filter(function (e) {
            return e !== name;
        });

        let fleetCheckAdd = fleetsSelected.filter(function (e) {
            return e === name;
        });

        if (_.isEmpty(fleetCheckAdd)) {
            fleetCheck.push(name);
        }

        if (name === "all" && selected) {
            fleetCheck = [];
        }

        if (name === "all" && !selected) {
            fleetCheck = [];
            data.forEach((item) => {
                fleetCheck.push(item.Value);
            });
        }

        setFleetsSelected(fleetCheck);
    };

    useEffect(() => {
        let arrayObj = {};
        let func = [];
        permissions.forEach((folder) => {
            folder.additionalParameters.children.forEach((item, j) => {
                func.push(item.id);
                arrayObj = { ...arrayObj, [item.id]: item.selected };
                setCheckedObject(arrayObj);
            });
        });

        if (permissions) {
            setInitialValues({
                Nome: "",
                Email: "",
                Login: "",
                Senha: "",
                TodosVeiculos: false,
                Funcionalidades: func,
                FrotasId: [],
            });
            setFunctionalities(func);
        }
    }, [permissions]);

    useEffect(() => {
        let fleetArray = [];
        if (!fleetsCompleted && !_.isEmpty(fleets)) {
            fleets.forEach((item) => {
                fleetArray.push(item.Value);
                setFleetsSelected(fleetArray);
            });

            setFleetCompleted(true);
        }
        if (fleets) {
            setFleetsObj(fleets);
        } else {
            setFleetsObj([]);
        }
    }, [fleetsCompleted, fleets]);

    const filterDataArray = (search) => {
        if (fleets) {
            let result = Utils.filterData(fleets, search);
            setFleetsObj(result);
        } else {
            setFleetsObj([]);
        }
    };

    const CARS_COLUMNS = [
        {
            name: "Text",
            label: "Todos os Veículos",
        },
    ];

    const verifyLogin = (_login, search) => {
        let count = (_login.match(new RegExp(search, "g")) || []).length;
        return count;
    };

    const getResponseMsg = (_res) => {
        if (typeof _res === "object") {
            Utils.showTranslatedToast({
                type: "error",
                description: "Não foi possível criar o usuário. Favor entrar em contato com a central.",
            });
        } else {
            Utils.showTranslatedToast({
                type: "success",
                description: _res,
            });
            handlerToogleNewUser();
        }
    };

    return (
        <Formik
            enableReinitialize
            validateOnBlur={true}
            validateOnChange={true}
            initialValues={initialValues}
            validate={(values) => {
                const errors = {};

                if (!values.Nome || values.Nome === "") {
                    errors.Nome = t(Labels.GROUP_POINTS_REGISTER_REQUIRED);
                }
                if (values.Nome.length > 50) {
                    errors.Nome = "Nome com muitos caracteres.";
                }
                if (!values.Email || values.Email === "") {
                    errors.Email = "Informe o E-mail";
                }

                if (!values.Email || values.Email === "") {
                    errors.Email = t(Labels.PROFILE_PERSONAL_INFORMATION_EMAIL_REQUIRED);
                } else if (!Utils.isValidEmail(values.Email)) {
                    errors.Email = t(Labels.PROFILE_PERSONAL_INFORMATION_EMAIL_INVALID);
                }

                if (!values.Login || values.Login === "") {
                    errors.Login = "Informe o Login";
                }

                if (!regexName.test(values.Login)) {
                    errors.Login = "Login utiliza padrão inválido";
                }

                if (verifyLogin(values.Login, "_") >= 2) {
                    errors.Login = "Login utiliza padrão inválido";
                }

                if (!values.Senha || values.Senha === "") {
                    if (!Utils.isValidPassword(values.Senha)) {
                        errors.Senha = t(Labels.PROFILE_PERSONAL_INFORMATION_NEW_PASSWORD_INVALID);
                    }
                }

                if (values.Senha) {
                    if (!values.RepetirSenha) {
                        errors.RepetirSenha = t(Labels.PROFILE_PERSONAL_INFORMATION_REPEAT_PASSWORD_REQUIRED);
                    }
                    if (values.Senha !== values.RepetirSenha) {
                        errors.RepetirSenha = t(Labels.PROFILE_PERSONAL_INFORMATION_REPEAT_PASSWORD_NOT_EQUAL);
                    }
                }

                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                if (_.isEmpty(fleets)) {
                    Utils.showTranslatedToast({
                        type: "error",
                        description: "Por favor, selecione ao menos uma frota!",
                    });
                    return;
                }

                let arrayFuncs = ["66", ...functionalities];

                let userReq = {
                    Nome: values.Nome,
                    Email: values.Email,
                    Login: values.Login,
                    Senha: values.Senha,
                    RepetirSenha: values.RepetirSenha,
                    TodosVeiculos: values.TodosVeiculos,
                    FrotasId: fleetsSelected,
                    Funcionalidades: arrayFuncs,
                };
                dispatch(userAction.createUser(userReq, getResponseMsg));
            }}
            render={({ submitForm, setTouched, validateForm, touched, errors, isValid }) => {
                return (
                    <Form id="user-register-form" className="ceabs-register-form">
                        <Tabs
                            variant="scrollable"
                            color="primary"
                            aria-label="Cadastro de usuário"
                            value={tabActive}
                            onChange={handleChangeTab}
                            indicatorColor="primary"
                            style={{ marginBottom: 10 }}>
                            <Tab
                                onClick={() => {
                                    setDisabledTab(true);
                                }}
                                style={{ paddingRight: "2rem" }}
                                label={<Typography className="register-user-tab-title">Adicionar Usuário</Typography>}
                                {...a11yProps(0)}
                            />
                            <Tab
                                disabled={disabledTab}
                                style={{ paddingRight: "2rem" }}
                                label={<Typography className="register-user-tab-title">Permissões</Typography>}
                                {...a11yProps(1)}
                            />
                        </Tabs>
                        <div style={{ display: tabActive === 0 ? "block" : "none" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <RegisterInputText name="Nome" label={"Nome"} />
                                </Grid>
                                <Grid item xs={12}>
                                    <RegisterInputText name="Email" label={t(Labels.REPORT_SHEDULED_EMAIL)} />
                                </Grid>
                                <Grid item xs={12}>
                                    <RegisterInputText name="Login" label={"Login"} />
                                </Grid>
                                <Grid item xs={6}>
                                    <RegisterInputText type="password" name="Senha" label={"Senha"} />
                                </Grid>
                                <Grid item xs={6}>
                                    <RegisterInputText type="password" name="RepetirSenha" label={"Repetir Senha"} />
                                </Grid>

                                <Grid item xs={12} className="itemViewVehicles">
                                    <Typography>Visualizar todos os Veículos</Typography>
                                    <RegisterSwitch
                                        name="TodosVeiculos"
                                        color="#71C154"
                                        onClick={() => {
                                            setDisplayFleets(!displayFleets);
                                        }}
                                    />
                                </Grid>

                                <Grid className="button-form button-form-refactor" item xs={4}>
                                    <Button
                                        id="cancelButton"
                                        className="cancel-button cancel-button-refactor"
                                        fullWidth
                                        variant="contained"
                                        disableElevation
                                        onClick={handlerToogleNewUser}>
                                        <CancelIcon />
                                        {t(Labels.VEHICLE_FORM_BUTTON_CANCEL)}
                                    </Button>
                                </Grid>
                                <Grid className="button-form button-form-refactor" item xs={8}>
                                    <Button
                                        id="nextButton"
                                        className="save-button save-button-refactor"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        disabled={loading.createUser}
                                        onClick={async () => {
                                            const res = await validateForm();

                                            if (_.isEmpty(res)) {
                                                setDisabledTab(false);
                                                setTabActive(1);
                                                return;
                                            }
                                            
                                            setTouched({ ...res, ...touched });
                                        }}>
                                        {loading.createUser ? (
                                            <CircularProgress
                                                style={{ height: 14, width: 14, marginRight: 8 }}
                                                color={"#fff"}
                                            />
                                        ) : (
                                            <ConfirmIcon />
                                        )}
                                        Avançar
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ display: tabActive === 1 ? "block" : "none" }}>
                            <Grid container spacing={2}>
                                <Grid
                                    id="register-user-permission-accordion"
                                    item
                                    xs={12}
                                    className={`userPermissions`}>
                                    {loading.getPermissionsByUserId ? (
                                        <CircularProgress
                                            style={{ height: 32, width: 32, marginRight: 8 }}
                                            color="primary"
                                        />
                                    ) : null}
                                    {permissions.length > 0 &&
                                        permissions.map((folder) => {
                                            return (
                                                <AccordionAlert title={folder.name} titleColor>
                                                    <div className="itemAlerts">
                                                        <div
                                                            className="conditionsList"
                                                            role="group"
                                                            aria-labelledby="checkbox-group">
                                                            {folder.additionalParameters.children.map((item, j) => (
                                                                <span key={item.id}>
                                                                    <RegisterCheckbox
                                                                        id={item.id}
                                                                        value={item.id}
                                                                        name={item.name}
                                                                        size="medium"
                                                                        color="primary"
                                                                        onChange={(event) => {
                                                                            handleChangeChecked(item, event.target.id);
                                                                        }}
                                                                        labelLegend={item.name}
                                                                        checked={
                                                                            checkedObject[item.id]
                                                                                ? checkedObject[item.id]
                                                                                : false
                                                                        }
                                                                    />
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </AccordionAlert>
                                            );
                                        })}
                                </Grid>
                                <Grid
                                    id="register-user-permission-cancel-button"
                                    className="button-form button-form-refactor"
                                    item
                                    xs={4}>
                                    <Button
                                        id="speeding-filter-period-save-button"
                                        className="cancel-button cancel-button-refactor"
                                        fullWidth
                                        variant="contained"
                                        disableElevation
                                        onClick={handlerToogleNewUser}>
                                        <CancelIcon />
                                        {t(Labels.VEHICLE_FORM_BUTTON_CANCEL)}
                                    </Button>
                                </Grid>
                                <Grid
                                    id="register-user-permission-save-button"
                                    className="button-form button-form-refactor"
                                    item
                                    xs={8}>
                                    <Button
                                        id="speeding-filter-period-save-buttont"
                                        className="save-button save-button-refactor"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        disabled={loading.createUser}
                                        onClick={submitForm}>
                                        {loading.createUser ? (
                                            <CircularProgress
                                                style={{ height: 14, width: 14, marginRight: 8 }}
                                                color={"#fff"}
                                            />
                                        ) : (
                                            <ConfirmIcon />
                                        )}
                                        Salvar
                                    </Button>
                                </Grid>
                                <Grid xs={12} id="register-user-permission-list">
                                    {displayFleets && (
                                        <CardGroupUserFleets
                                            data-id={"cadastrar-fleets-users-id"}
                                            title={"Frotas"}
                                            className="group-items card-fleets-list-tabs"
                                            classHeader={"search-group-item"}
                                            loading={loading.getFleetByUserId}
                                            searchFn={filterDataArray}>
                                            <TableCard
                                                columns={CARS_COLUMNS}
                                                checkBox={true}
                                                rowSelection={{
                                                    refColName: "Value",
                                                    onChange: (newSelected, data, selected, name) => {
                                                        handleChangeFleets(newSelected, data, selected, name);
                                                    },
                                                }}
                                                dataSource={fleetsObj}
                                                isChecked
                                                fleetsSelecteds={fleetsSelected}
                                            />
                                        </CardGroupUserFleets>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    </Form>
                );
            }}
        />
    );
};

export default FormRegisterUser;
