import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
	periodTrips: [],
	roadOffset: [],
	kmRun: [],
	kmRunPeriod:[],
	isVisibleDetailsKmRun: false,
	distancia: 0,
	evolutionFleet: [],
	distanciaMedia: 0,
	distanciaComercial: 0,
	distanciaComercialMedia: 0,
	distanciaFimSemana: 0,
	distanciaFimSemanaMedia: 0,
	distanciaNaoComercial: 0,
	distanciaNaoComercialMedia: 0,
	qtdAceleracaoBrusca: 0,
	qtdCurvaAgressiva: 0,
	qtdFreadaBrusca: 0,
	qtdExcessoVelocidade: 0,
	qtdViagemNaoComercial: 0,
	qtdViagemFimSemana: 0,
};

function appReducer(state = INITIAL_STATE, action = null) {
	switch (action.type) {
		case Constants.DASHBOARD_VEHICLE_GET_PERIOD_TRIPS:
			return {
				...state,
				periodTrips: action.payload,
			};
		case Constants.DASHBOARD_VEHICLE_GET_ROAD_OFFSET:
			return {
				...state,
				roadOffset: action.payload,
			};
		case Constants.DASHBOARD_VEHICLE_EVOLUTION_FLEET:
			return{
				...state,
				evolutionFleet: action.payload
			}
		case Constants.DASHBOARD_VEHICLE_GET_KM_RUN:
			return {
				...state,
				kmRun: action.payload.kmRun,
				distancia: action.payload.Distancia,
				distanciaMedia: action.payload.MediaDistancia,
			};
		case Constants.DASHBOARD_VEHICLE_TOGGLE_DETAILS_KM_RUN:
			return {
				...state,
				isVisibleDetailsKmRun: action.payload,
			};
		case Constants.DASHBOARD_VEHICLE_GET_KM_RUN_INDICATOR:
			return {
				...state,
				distanciaComercial: action.payload.DistanciaComercial,
				distanciaComercialMedia: action.payload.DistanciaComercialMedia,
				distanciaFimSemana: action.payload.DistanciaFimSemana,
				distanciaFimSemanaMedia: action.payload.DistanciaFimSemanaMedia,
				distanciaNaoComercial: action.payload.DistanciaNaoComercial,
				distanciaNaoComercialMedia: action.payload.DistanciaNaoComercialMedia,
			};
		case Constants.DASHBOARD_VEHICLE_GET_KM_RUN_PERIOD:
			return {
				...state,
				kmRunPeriod: action.payload.kmRunPeriod
			};
		case Constants.DASHBOARD_VEHICLE_GET_ANALYSIS_ITEMS:
			return {
				...state,
				qtdAceleracaoBrusca: action.payload.QtdAceleracaoBrusca,
				qtdCurvaAgressiva: action.payload.QtdCurvaAgressiva,
				qtdFreadaBrusca: action.payload.QtdFreadaBrusca,
				qtdExcessoVelocidade: action.payload.QtdExcessoVelocidade,
				qtdViagemNaoComercial: action.payload.QtdViagemNaoComercial,
				qtdViagemFimSemana: action.payload.QtdViagemFimSemana,
			};
		default:
			return state;
	}
}

export default appReducer;
