import React from 'react';

import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

import { fade, makeStyles } from '@material-ui/core';

import TabMain from '~/components/common/tab/TabMain'
import EditFleet from '~/components/fleets/finder/tab/EditFleet'
import ImportCars from '~/components/fleets/finder/tab/ImportCars'

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTabs-root":{
      background: fade(theme.palette.primary.main, 0.15),
      padding: '20px 0px 0 22px'
    }
  },
}));

const TabsFleets = (props) => {
  const { t } = useTranslation();

  const TABS = [
    {
      id: 0,
      label: t(Labels.FLEET_LABEL_EDIT),
      children: (<EditFleet closeTab={props.closeTab} />)
    },
    {
      id: 1,
      label: t(Labels.FLEET_LABEL_IMPORT),
      children: (<ImportCars closeTab={props.closeTab} />)
    }
  ]

  const classes = useStyles();
  return (
    <div className="">
      <TabMain
        id="TabAlerts"
        tabs={TABS}
        className={classes.root}
        classNameLabelRefactor="tab-label-refactor"
      />
    </div>
  );
}

export default TabsFleets;
