import moment from "moment-timezone";
import { useCallback } from "react";
import { getUserInfo } from "~/helpers/Utils";

export default () => {
    const userInfo = getUserInfo();
    const timeZone = (userInfo && userInfo["Timezone"]) || "America/Sao_Paulo";

    const toUtc = useCallback(
        (date) => {
            return moment.tz(date, timeZone).utc();
        },
        [timeZone]
    );

    const fromUtc = useCallback(
        (date, format) => {
            return moment.utc(date, format).tz(timeZone);
        },
        [timeZone]
    );

    return { toUtc, fromUtc, timeZone };
};
