import { Box, Typography } from "@material-ui/core";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import "~/components/reporting/common/ReportingCommon.scss";
import "~/components/reporting/Reporting.scss";

const Input = ({ label, placeholder, ...props }) => {
    return (
        <Box className="report-field">
            <Typography variant="subtitle1" className="report-field-label">
                {label}
            </Typography>
            <Field
                component={TextField}
                variant="outlined"
                fullWidth
                onKeyPress={(ev) => {
                    if (props.type === "number" && ["e"].includes(ev.key)) ev.preventDefault();
                }}
                InputProps={{
                    style: props.style,
                    fullWidth: true,
                    margin: "dense",
                    placeholder: placeholder,
                }}
                {...props}
            />
        </Box>
    );
};

export default Input;
