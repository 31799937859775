import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Card from "~/components/common/expansionCard/Card";
import dashboardFiltersAction from "~/actions/dashboard/dashboardFiltersAction";
import { Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const CardGrid = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dashboardToolbar = useSelector((state) => state.dashboardToolbar);
    const [filter, setFilter] = useState({});

    const handlerHideCard = (card) => {
        dispatch(dashboardFiltersAction.toggleMarkedFilter(card.name, false));
    };

    useEffect(() => {
        setFilter(dashboardToolbar[props.name]);
    }, [dashboardToolbar, props.name]);

    return filter && !!filter.checked ? (
        <>
            <Grid {...props}>
                <Card
                    title={props.noTitle ? null : t(filter.label)}
                    className="card-refactor"
                    topButtons={
                        <IconButton onClick={() => handlerHideCard(filter)}>
                            <CloseIcon />
                        </IconButton>
                    }>
                    {props.children}
                </Card>
            </Grid>
        </>
    ) : null;
};

export default CardGrid;
