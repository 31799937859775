import React, { useEffect, useState } from 'react';

import { useSelector } from "react-redux";

import TableItems from "~/components/tableCard/TableCardReUse";
import Loading from '~/components/common/loading/LoadingPanel'

const ItemsBase = () => {
  const [load, setLoad] = useState(true);
  const { dataBase } = useSelector((state) => state.dashboardDataVehicle);
  const { loading } = useSelector((state) => state.app);


  useEffect(() =>{
    setTimeout(()=>{
      setLoad(false)
    }, 2000)
  })

  const COLUMNS = [
    {
      name: "INFO",
      label: "Informação",
      render: (info) => <span>{info}</span>,
    },
    {
      name: "TOTAL",
      label: "Total do Veículo",
      render: (total) => <span>{(total ? total.toLocaleString('pt-br') : 0) }</span>,
    },
    {
      name: "MEDIA_VEHICLE",
      label: "Média Diária do Veículo",
      render: (mVehicle) => <span>{(mVehicle ? mVehicle.toLocaleString('pt-br', { maximumFractionDigits: 1 }) : 0) }</span>,
    },
    {
      name: "MEDIA_BASE",
      label: "Média Diária da Base",
      render: (mBase) => <span>{(mBase ? mBase.toLocaleString('pt-br', { maximumFractionDigits: 1 }) : 0) }</span>,
    },
  ];

  const DATA = [
    {
      INFO: "Excesso de Velocidade na Via",
      TOTAL: dataBase.Veiculo?.QtdExcessoVelocidade,
      MEDIA_VEHICLE: dataBase.Veiculo?.MediaQtdExcessoVelocidade,
      MEDIA_BASE: dataBase.Base?.MediaQtdExcessoVelocidade
    },
    {
      INFO: "Freada Brusca",
      TOTAL: dataBase.Veiculo?.QtdFreadaBrusca,
      MEDIA_VEHICLE: dataBase.Veiculo?.MediaQtdFreadaBrusca,
      MEDIA_BASE: dataBase.Base?.MediaQtdFreadaBrusca
    },
    {
      INFO: "Aceleração Brusca",
      TOTAL: dataBase.Veiculo?.QtdAceleracaoBrusca,
      MEDIA_VEHICLE: dataBase.Veiculo?.MediaQtdAceleracaoBrusca,
      MEDIA_BASE: dataBase.Base?.MediaQtdAceleracaoBrusca
    },
    {
      INFO: "Curva Brusca",
      TOTAL: dataBase.Veiculo?.QtdCurvaAgressiva,
      MEDIA_VEHICLE: dataBase.Veiculo?.MediaQtdCurvaAgressiva,
      MEDIA_BASE: dataBase.Base?.MediaQtdCurvaAgressiva
    },
  ]

  const isLoading = (loading.waitingTravel || load);

  return(
    <div className="cardBaseKmRunTable">
      {isLoading && <div className="loadingGridData"><Loading size={40} /></div>}
      <TableItems columns={COLUMNS} dataSource={DATA} />
    </div>
  );
}

export default ItemsBase;
