import React from "react";
import "./expansionCard.scss";
import ExpansionCardEnum from "./ExpansionCardEnum";

const GridExpansionCard = (props) => {

	const getClassName = (alignment) => {

		switch (alignment.id) {
			case 1:
				return ExpansionCardEnum.HORIZONTAL.classname;
			case 2:
				return ExpansionCardEnum.VERTICAL.classname;
			case 3:
				return ExpansionCardEnum.HORIZONTAL_INVERSE.classname;
			case 4:
				return ExpansionCardEnum.VERTICAL_INVERSE.classname;
			default:
				return ExpansionCardEnum.HORIZONTAL.classname;
		}
	}

	return (
		<div className={`expansionGrid ${getClassName(props.alignment)}`}>
			{props.children}
		</div>
	);
};

export default GridExpansionCard;
