import { createStyles, Grid, IconButton, MenuItem, Select, WithStyles, withStyles } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { getHours, getMonth, getYear, setMonth, setHours, setYear, getMinutes, setMinutes } from "date-fns";
import React from "react";

interface HeaderProps extends WithStyles<typeof styles> {
	date: Date;
	setDate: (date: Date) => void;
	nextDisabled: boolean;
	prevDisabled: boolean;
	onClickNext: () => void;
	onClickPrevious: () => void;
	hours: boolean;
}

const styles = createStyles({
	iconContainer: {
		padding: 5,
	},
	icon: {
		padding: 10,
		"&:hover": {
			background: "none",
		},
	},
	boxDate: {
		display: "flex",
		width: "100%",
		justifyContent: "space-between",
		alignItems: "center",
	},
});

const MONTHS = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];

const generateHoursMin = (_min: number, _max: number) => {
	let number = [];
	for (let i = _min; i < _max; i++) {
		number.push(i);
	}
	return number;
};

const generateYears = (relativeTo: Date, count: number) => {
	const half = Math.floor(count / 2);
	return Array(count)
		.fill(0)
		.map((y, i) => relativeTo.getFullYear() - half + i); // TODO: make part of the state
};

const Header: React.FunctionComponent<HeaderProps> = ({
	date,
	classes,
	setDate,
	nextDisabled,
	prevDisabled,
	onClickNext,
	onClickPrevious,
	hours,
}) => {
	return (
		<Grid container justify="space-between" alignItems="center" direction="column">
			{hours && (
				<Grid item>
					<Select
						value={getHours(date)}
						onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
							setDate(setHours(date, parseInt(event.target.value as string)));
						}}
						MenuProps={{ disablePortal: true }}>
						{generateHoursMin(0, 24).map((hours, idx) => (
							<MenuItem key={hours} value={idx}>
								{hours}
							</MenuItem>
						))}
					</Select>
					<> : </>
					<Select
						value={getMinutes(date)}
						onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
							setDate(setMinutes(date, parseInt(event.target.value as string)));
						}}
						MenuProps={{ disablePortal: true }}>
						{generateHoursMin(0, 60).map((min, idx) => (
							<MenuItem key={min} value={idx}>
								{min}
							</MenuItem>
						))}
					</Select>
				</Grid>
			)}
			<div className={classes.boxDate}>
				<Grid item className={classes.iconContainer}>
					<IconButton className={classes.icon} disabled={prevDisabled} onClick={onClickPrevious}>
						<ChevronLeft color={prevDisabled ? "disabled" : "action"} />
					</IconButton>
				</Grid>
				<Grid item>
					<Select
						value={getMonth(date)}
						onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
							setDate(setMonth(date, parseInt(event.target.value as string)));
						}}
						MenuProps={{ disablePortal: true }}>
						{MONTHS.map((month, idx) => (
							<MenuItem key={month} value={idx}>
								{month}
							</MenuItem>
						))}
					</Select>
				</Grid>
				<Grid item>
					<Select
						value={getYear(date)}
						onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
							setDate(setYear(date, parseInt(event.target.value as string)));
						}}
						MenuProps={{ disablePortal: true }}>
						{generateYears(date, 15).map((year) => (
							<MenuItem key={year} value={year}>
								{year}
							</MenuItem>
						))}
					</Select>

					{/* <Typography>{format(date, "MMMM YYYY")}</Typography> */}
				</Grid>
				<Grid item className={classes.iconContainer}>
					<IconButton className={classes.icon} disabled={nextDisabled} onClick={onClickNext}>
						<ChevronRight color={nextDisabled ? "disabled" : "action"} />
					</IconButton>
				</Grid>
			</div>
		</Grid>
	);
};

export default withStyles(styles)(Header);
