import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Labels from "~/helpers/enums/Labels";
import { Grid, Button } from "@material-ui/core";
import { Form, Formik } from "formik";
import { SaveReportIcon } from "~/components/common/icons/Icons";
import Utils from "~/helpers/Utils";
import alertReportAction from "~/actions/reporting/alertReportAction";
import AutoCompleteAlert from "~/components/reporting/common/alert/AutoCompleteAlert";
import AutoCompleteFleet from "~/components/reporting/common/fleet/AutoCompleteFleet";
import AutoCompleteStatus from "~/components/reporting/common/status/AutoCompleteStatus";
import VehicleAutocomplete from "~/components/reporting/common/vehicle/VehicleAutocomplete";
import ReportPeriodField from "~/components/reporting/common/ReportPeriodField";
import "~/components/reporting/Reporting.scss";

const AlertFilter = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const periodForm = useRef();
    const {
        perObject: { filters },
    } = useSelector((state) => state.alertReport);

    useEffect(() => {
        if (!filters.period) {
            return;
        }

        const values = {
            AlertaId: filters.AlertaId?.Id,
            Inicio: filters.period.startDate,
            Final: filters.period.endDate,
            ObjetoRastreavelId: filters.vehicle ? parseInt(filters.vehicle.id) : null,
            GrupoId: filters.fleet ? parseInt(filters.fleet.id) : null,
            Opcao: filters.status.value,
            GridRequest: {
                ...filters.GridRequest,
            },
        };

        dispatch(
            alertReportAction.importAlertReportGet(values, (error) => {
                if (!error) return;
                Utils.showAllErrors(error);
            })
        );

        dispatch(
            alertReportAction.saveAlertExport({
                DataInicio: values.Inicio?.format(),
                DataFim: values.Final?.format(),
                Alerta: values.AlertaId,
                Serial: values.ObjetoRastreavelId,
                Opcao: values.Opcao,
                GrupoId: values.GrupoId,
            })
        );
    }, [dispatch, filters]);

    useEffect(() => {
        dispatch(alertReportAction.updateAlertFilterForm(periodForm.current));
    }, [dispatch, periodForm]);

    return (
        <Formik
            innerRef={periodForm}
            initialValues={{
                AlertaId: null,
                fleet: null,
                traced: 0,
                period: Utils.getTodayPeriod(),
                status: {},
                vehicle: null,
            }}
            validate={(values) => {
                const errors = {};

                if (values.period && Utils.diffInDays(new Date(), values.period.startDate) > 90) {
                    errors.period = t(Labels.REPORT_VALIDATION_MESSAGE_PERIOD);
                }

                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                dispatch(
                    alertReportAction.updateFilterAlert({
                        ...values,
                        period: {
                            startDate: moment(values.period.startDate),
                            endDate: moment(values.period.endDate),
                        },
                    })
                );

                dispatch(
                    alertReportAction.updateFilterAlertPagination({
                        ...filters.GridRequest,
                        Index: 0,
                    })
                );
            }}
            render={({ submitForm, setFieldValue }) => {
                return (
                    <Form>
                        <Grid container justify="right" alignItems="flex-end" spacing={2}>
                            <Grid item xs={2}>
                                <AutoCompleteAlert
                                    name="AlertaId"
                                    label={t(Labels.REPORT_LABEL_ALERT)}
                                    placeholder={t(Labels.REPORT_LABEL_ALERT)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <AutoCompleteFleet
                                    name="fleet"
                                    label={t(Labels.REPORT_LABEL_FLEET_ALERT_VIOLATED)}
                                    placeholder={t(Labels.REPORT_LABEL_PLACEHOLDER)}
                                    onChange={() => {
                                        setFieldValue("vehicle", null);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={2}>
                                <VehicleAutocomplete
                                    id="vehicle-Combo"
                                    name="vehicle"
                                    label={t(Labels.REPORT_LABEL_TRACE)}
                                    placeholder={t(Labels.SEARCH_FILE_IMPORT_DRIVER)}
                                    onChange={() => {
                                        setFieldValue("fleet", null);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <ReportPeriodField
                                    id="id-period-date"
                                    label={t(Labels.REPORT_EVENT_PERIOD_LABEL)}
                                    name="period"
                                    hours
                                />
                            </Grid>

                            <Grid item xs={1}>
                                <AutoCompleteStatus
                                    name="status"
                                    label={t(Labels.REPORT_LABEL_STATUS)}
                                    placeholder={t(Labels.REPORT_LABEL_PLACEHOLDER)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    id="event-filter-period-save-button"
                                    className="report-save-button report-save-button-refactor"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    startIcon={<SaveReportIcon />}
                                    onClick={submitForm}>
                                    {t(Labels.REPORT_EVENT_SAVE_BUTTON)}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        />
    );
};
export default AlertFilter;
