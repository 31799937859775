import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import _ from "lodash";

import DashboardFilter from "~/components/dashboard/common/DashboardFilter";
import CardGrid from "~/components/dashboard/common/CardGrid";
import KmRunChartPie from "~/components/dashboard/kmRun/KmRunChartPie";
import KmRunIndicator from "~/components/dashboard/kmRun/KmRunIndicator";
import AnalysisItems from "~/components/dashboard/analysisItems/AnalysisItems";
import dashboardChartsAction from "~/actions/dashboard/dashboardChartsAction";
import TabDriverVehicle from "~/components/dashboard/topTen/TabDriverVehicle";
import TabAnalyzeVehicleDriver from "~/components/dashboard/topTen/TabAnalyzeVehicleDriver";
import KmRunPeriodWeek from "~/components/dashboard/kmRunWeek/KmRunPeriodWeek";
import RoadOffsetChartPie from "~/components/dashboard/chartPie/RoadOffsetChartPie";
import PeriodTripsChartPie from "~/components/dashboard/chartPie/PeriodTripsChartPie";
import ScoreChartGauge from "./chartGauge/ScoreChartGauge";
import FleetEvolution from "~/components/dashboard/fleetEvolution/FleetEvolution";
import TopTenMaxVelocityVehicle from "~/components/dashboard/topTenMaxVelocity/TopTenMaxVelocityVehicle";
import TopTenMaxVelocityDriver from "~/components/dashboard/topTenMaxVelocity/TopTenMaxVelocityDriver";
import AccidentProbabilityChartFunnel from "./chartFunnel/AccidentProbabilityChartFunnel";
import DashGraphEnum from "./common/DashGraphEnum";

import "~/helpers/reactToPrint.scss"

const Dashboard = React.forwardRef((props, ref) => {
	const dispatch = useDispatch();
	const [filterState, setFilterState] = useState(null);

	const { fleetSelected, subFleetSelected, rangeDate } = useSelector((state) => state.dashboardFilter);
	const { isVisibleDetailsKmRun } = useSelector((state) => state.dashboardTravels);
	const dashboardToolbar = useSelector((state) => state.dashboardToolbar);
	
	useEffect(() => {
		//! Verificar se um dos 12 itens foi selecionado para realmente fazer a chamada
		//! Se tiver mais de um item selecionado não deve fazer a chamada
		//! Verificar se os dados estão nos reducers

		if (!rangeDate.AnoInicio || !rangeDate.AnoFim) {
			return;
		}

		const filter = !_.isEmpty(subFleetSelected)
			? { grupoId: subFleetSelected.id, ...rangeDate }
			: { grupoId: fleetSelected.id, ...rangeDate };

		updateFilter(filter);


		dispatch(dashboardChartsAction.getTravel({ ...filter }, "waitingTravel"));
		dispatch(dashboardChartsAction.getScores({ ...filter }, "waitingScore"));
		dispatch(dashboardChartsAction.getKmRunWeek({ ...filter }, "waitingKmRunWeek"));
	
	}, [dispatch, fleetSelected, subFleetSelected, rangeDate]);

	const updateFilter = (_filter) => {
		const filterKm = {
			GroupID: _filter.grupoId, //null
			startMonth: _filter.MesInicio,
			endMonth: _filter.MesFim,
			startYear: _filter.AnoInicio,
			endYear: _filter.AnoFim,
		};

		setFilterState({ ...filterKm });
	};

	return (
		<span style={{ width: "100%" }} ref={ref}>
			<Grid container>
				<DashboardFilter />
			</Grid>

			<Grid container>
				<CardGrid item xs={4} name={DashGraphEnum.KM_RUN.name}>
					<KmRunChartPie />
				</CardGrid>
				<CardGrid item xs={4} name={DashGraphEnum.PERIOD_TRIPS.name}>
					<PeriodTripsChartPie />
				</CardGrid>
				<CardGrid item xs={4} name={DashGraphEnum.ROAD_OFFSET.name}>
					<RoadOffsetChartPie />
				</CardGrid>
			</Grid>

			{dashboardToolbar[DashGraphEnum.KM_RUN.name].checked && isVisibleDetailsKmRun && (
				<Grid container>
					<Grid item xs={4}>
						<KmRunIndicator title="Km rodados Horário Comercial" action="distanciaComercial" />
					</Grid>
					<Grid item xs={4}>
						<KmRunIndicator title="Km rodados Fora do Horário" action="distanciaNaoComercial" />
					</Grid>
					<Grid item xs={4}>
						<KmRunIndicator title="Km rodados Final de Semana" action="distanciaFimSemana" />
					</Grid>
				</Grid>
			)}

			<CardGrid item xs={12} name={DashGraphEnum.TOP_TEN_DRIVER_VEHICLE.name} noTitle>
				<TabDriverVehicle filter={filterState} />
			</CardGrid>

			<Grid container>
				<CardGrid item xs={6} name={DashGraphEnum.TOP_TEN_MAX_VELOCITY_VEHICLE.name}>
					<TopTenMaxVelocityVehicle />
				</CardGrid>
				<CardGrid item xs={6} name={DashGraphEnum.TOP_TEN_MAX_VELOCITY_DRIVER.name}>
					<TopTenMaxVelocityDriver />
				</CardGrid>
			</Grid>

			<CardGrid item xs={12} name={DashGraphEnum.ANALYSIS_ITEMS_TITLE.name} noTitle>
				<AnalysisItems />
			</CardGrid>

			<CardGrid item xs={12} name={DashGraphEnum.TOP_TEN_ANALYZE_DRIVER_VEHICLE.name} noTitle>
				<TabAnalyzeVehicleDriver filter={filterState} />
			</CardGrid>

			<Grid container>
				<CardGrid item xs={4} name={DashGraphEnum.DRIVABILITY_SCORE.name}>
					<ScoreChartGauge nameProperty="ScoreDirecao" />
				</CardGrid>
				<CardGrid item xs={4} name={DashGraphEnum.RISK_EXPOSURE_SCORE.name}>
					<ScoreChartGauge nameProperty="ScoreExposicaoRisco" color="secondary" />
				</CardGrid>
			</Grid>

			<CardGrid item xs={12} name={DashGraphEnum.KM_RUN_PERIOD_WEEK.name}>
				<KmRunPeriodWeek />
			</CardGrid>

			<CardGrid item xs={12} name={DashGraphEnum.FLEET_EVOLUTION.name}>
				<FleetEvolution />
			</CardGrid>
			<CardGrid item xs={7} name={DashGraphEnum.ACCIDENT_PROBABILITY.name}>
				<AccidentProbabilityChartFunnel />
			</CardGrid>
		</span>
	);
})

export default Dashboard;
