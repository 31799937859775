import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Grid, IconButton } from "@material-ui/core";
import { CloseIcon } from "~/components/common/icons/Icons";
import Card from "~/components/common/expansionCard/Card";
import dashboardChartsAction from "~/actions/dashboard/dashboardChartsAction";
import LoadingPanel from "~/components/common/loading/LoadingPanel";
import MapCard from "~/components/map/MapCard";
import useFuso from "~/components/common/fuso/useFuso";
import googleMapsDataAction from "~/actions/googleMapsDataAction";
import "./MaxVelocityModal.scss";
import TableCard from "~/components/tableCard/TableCardReUse";

const MaxVelocityModal = (props) => {
    // const { t } = useTranslation();
    const { vehicle, driver } = props;
    const loadingId = useMemo(() => "modalTenMaxVelocity", []);
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.app.loading);

    const { rangeDate } = useSelector((state) => state.dashboardFilter);
    const { travelMaxVelocity, travelMaxVelocityPositions } = useSelector((state) => state.dashboardCharts);

    const { fromUtc } = useFuso();

    useEffect(() => {
        const filter = {
            MotoristaId: driver?.MotoristaId,
            ObjetoRastreavelId: vehicle?.ObjetoRastreavelId, // 1089673,
            dataInicio: moment(new Date(rangeDate.AnoInicio, rangeDate.MesInicio - 1, 1)).format(), // 01/MM/YYYY,
            dataFim: moment(new Date(rangeDate.AnoFim, rangeDate.MesFim - 1, new Date().getDate())).format(), // MM/DD/YYYY, com DD de hoje,
            velocidadeMaxima: vehicle?.VelocidadeMaxima || driver?.VelocidadeMaxima, // 133,
        };

        dispatch(dashboardChartsAction.getTravelMaxVelocity(filter, loadingId));
    }, [dispatch, rangeDate, vehicle, driver, loadingId]);

    useEffect(() => {
        if (!travelMaxVelocity.ObjetoRastreavelId) return;

        dispatch(
            dashboardChartsAction.getTravelMaxVelocityPositions(
                {
                    DataInicio: fromUtc(travelMaxVelocity.Inicio).format(),
                    DataFim: fromUtc(travelMaxVelocity.Termino).format(),
                    ObjetoRastreavelId: travelMaxVelocity.ObjetoRastreavelId, // 1217567,
                },
                loadingId
            )
        );
    }, [dispatch, travelMaxVelocity, loadingId, fromUtc]);

    useEffect(() => {
        if (!travelMaxVelocity.ObjetoRastreavelId || travelMaxVelocityPositions.length <= 0) return;

        dispatch(
            googleMapsDataAction.updateDirections([
                {
                    Trajeto: travelMaxVelocityPositions.map((p) => {
                        return {
                            latitude: parseFloat(p.Latitude),
                            longitude: parseFloat(p.Longitude),
                            ignition: p.Ignicao === "True",
                            direction: p.Direcao,
                            big: parseInt(p.Velocidade) === travelMaxVelocity.VelocidadeMaxima,
                        };
                    }),
                },
            ])
        );

        return () => {
            dispatch(googleMapsDataAction.cleanDirections());
        };
    }, [dispatch, travelMaxVelocityPositions, travelMaxVelocity]);

    const renderDate = (date) => {
        return (
            <span>
                {fromUtc(date).format("DD/MM/YYYY")}
                <span style={{ color: "#AFC3D2", margin: "auto 5px" }}>|</span>
                {fromUtc(date).format("HH:mm:ss")}
            </span>
        );
    };

    const COLUMN = [
        {
            name: "Placa",
            label: "Placa",
            render: (name) => <strong>{name}</strong>,
        },
        {
            name: "Inicio",
            label: "Início",
            render: (date) => renderDate(date),
        },
        {
            name: "Termino",
            label: "Fim",
            render: (date) => renderDate(date),
        },
        {
            name: "Distancia",
            label: "Percorrido",
            render: (value) => <span>{value} Km</span>,
        },
        {
            name: "Motorista",
            label: "Motorista",
        },
        {
            name: "MotoristaIndentificacao",
            label: "Proprietário",
        },
        {
            name: "LocalizacaoInicio",
            label: "Localização Inicial",
        },
        {
            name: "LocalizacaoTermino",
            label: "Localização Final",
        },
    ];

    return (
        <div className="ceabs-layout ">
            <Card
                title={"Detalhes"}
                className={"max-velocity-modal card-refactor"}
                topButtons={
                    <IconButton
                        onClick={() => {
                            props.onClose && props.onClose();
                        }}>
                        <CloseIcon />
                    </IconButton>
                }>
                {loading[loadingId] && <LoadingPanel size={45} />}
                <Grid container direction="row" alignItems="stretch">
                    <Grid item xs={4}>
                        <Card title="Resultados " className="card-refactor">
                            <TableCard mode="horizontal" columns={COLUMN} dataSource={[travelMaxVelocity]} />
                        </Card>
                    </Grid>
                    <Grid item xs={8}>
                        <Card title="Mapa" notPadded className={"max-velocity-modal-map card-refactor"}>
                            <MapCard />
                        </Card>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
};

export default MaxVelocityModal;
