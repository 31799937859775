import Constants from "~/helpers/enums/Constants";

const initialState = {
	clear: false,
	nameClear: "",
	clearAll: false,
	perObject: {
		filters: [
			{
				name: "Placa",
				label: "Placa",
				checked: true,
			},
			{
				name: "Data",
				label: "Data",
				checked: true,
			},
			{
				name: "TempoViagem",
				label: "Tempo viagem",
				checked: true,
			},
			{
				name: "Marca",
				label: "Marca",
				checked: true,
			},
			{
				name: "Modelo",
				label: "Modelo",
				checked: true,
			},
			{
				name: "KmInicial",
				label: "Km inicial",
				checked: true,
			},
			{
				name: "KmFinal",
				label: "Km final",
				checked: true,
			},
			{
				name: "DistanciaHorarioComercial",
				label: "Distância horário comercial",
				checked: true,
			},
			{
				name: "DistanciaForaHorarioComercial",
				label: "Distância fora horário comercial",
				checked: true,
			},
			{
				name: "DistanciaFimSemana",
				label: "Distância fim de semana",
				checked: true,
			},
			{
				name: "DistanciaPercorrida",
				label: "Percorrida",
				checked: true,
			},
		],
		data: {
			data: [],
		},
	},
	perPeriod: {
		pagination: {
			Start: 0,
			Index: 0,
			Length: 25,
		},
		page: {
			recordsTotal: 0,
			recordsFiltered: 1,
		},
		data: [],
	},
	scheduling: {
		edition: {},
		data: [],
	},
	sent: {
		pagination: {},
		data: [],
	},
};

function reportingReducer(state = initialState, action = null) {
	const { payload } = action;
	switch (action.type) {
		case Constants.REQUEST_REPORTING_PER_OBJECT:
			return {
				...state,
				perObject: {
					...state.perObject,
					data: action.payload.data,
				},
			};

		case Constants.CLEAR_AUTO_COMPLETE:
			return {
				...state,
				clear: payload.clearFlag,
				nameClear: payload.nameField,
			};

		case Constants.CLEAR_ALL:
			return {
				...state,
				clearAll: payload.clear,
			};

		case Constants.GET_REPORTING_PER_PERIOD:
			return {
				...state,
				perPeriod: {
					...state.perPeriod,
					data: payload.data,
					page: payload.page,
				},
			};
		case Constants.UPDATE_PAGINATION_PER_PERIOD:
			return {
				...state,
				perPeriod: {
					...state.perPeriod,
					pagination: payload.pagination,
				},
			};
		case Constants.GET_DISTANCE_REPORT_SCHEDULING:
			return {
				...state,
				scheduling: {
					...state.scheduling,
					data: payload.data,
					pagination: payload.pagination,
				},
			};

		case Constants.GET_DISTANCE_REPORT_SENT:
			return {
				...state,
				sent: {
					data: payload.data,
					pagination: payload.pagination,
				},
			};
		case Constants.EDIT_DISTANCE_REPORT_SCHEDULING:
			return {
				...state,
				scheduling: {
					...state.scheduling,
					edition: payload,
				},
			};

		case Constants.GET_REPORTING_PER_OBJECT:
			// const { Data } = payload;
			return {
				...state,
				perObject: {
					...state.perObject,
					data: payload.data,
				},
				/*return {
        ...state,
        perObject: {
          data: Data,
        },*/
			};

		default:
			return state;
	}
}

export default reportingReducer;
