import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import Pagination from "~/components/common/pagination/Pagination";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

import fleetsActions from "~/actions/fleets/fleetsActions";

import { Box } from "@material-ui/core";

import CardGroup from "~/components/common/card/CardGroup";
import MenuRange from "~/components/common/menuRange/MenuRange";
import TabsFleets from "~/components/fleets/finder/tab/TabsFleets";
import AccordionBox from "~/components/fleets/common/AccordionBox";
import "./FinderFleets.scss";

const FinderFleets = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { fleetsData, filter } = useSelector((state) => state.fleetsRegister);
    const { loading } = useSelector((state) => state.app);
    const [displayView, setDisplayView] = useState(false);

    useEffect(() => {
        dispatch(fleetsActions.getDataFleetsList({ ...filter }));
    }, [dispatch, filter]);

    useEffect(() => {
        return () => {
            dispatch(
                fleetsActions.updateFilterFleet({
                    limit: 10,
                    page: 0,
                    status: -1,
                    search: "",
                })
            );
        };
    }, [dispatch]);

    const COLUMNS_FLEETS = {
        FrotaPai: {
            id: "Id",
            name: "Nome",
            status: "Ativo",
        },
        SubFrotas: {
            id: "Id",
            name: "Nome",
            status: "Ativo",
        },
    };

    return (
        <Box className="container-group-card">
            <Box className="container-group-card-item">
                <CardGroup
                    data-id={"cadastrar-frotas-id"}
                    title={t(Labels.FLEET_LABEL)}
                    className="card-group-refactor"
                    classHeader=""
                    loading={loading.getDataFleetsList}
                    searchValue={filter.search}
                    searchFn={(value) => {
                        dispatch(
                            fleetsActions.updateFilterFleet({
                                ...filter,
                                page: 0,
                                search: value,
                            })
                        );
                    }}
                    headerComp={
                        <>
                            <MenuRange
                                classNameRefactor="menu-label-range-refactor"
                                label={t(Labels.MENU_RANGE_SHOW)}
                                value={filter.limit}
                                onChange={(event) => {
                                    dispatch(
                                        fleetsActions.updateFilterFleet({
                                            ...filter,
                                            page: 0,
                                            limit: event.target.value,
                                        })
                                    );
                                }}
                                options={[10, 25, 50, 100, 250, 500]}
                            />
                            <MenuRange
                                classNameRefactor="menu-label-range-refactor"
                                label={t(Labels.MENU_RANGE_STATUS)}
                                value={filter.status}
                                onChange={(event) => {
                                    dispatch(
                                        fleetsActions.updateFilterFleet({
                                            ...filter,
                                            status: event.target.value,
                                        })
                                    );
                                }}
                                options={[
                                    {
                                        label: t(Labels.MENU_ACTIVE),
                                        value: 1,
                                    },
                                    {
                                        label: t(Labels.MENU_INACTIVE),
                                        value: 0,
                                    },
                                    {
                                        label: t(Labels.MENU_ALL),
                                        value: -1,
                                    },
                                ]}
                            />
                        </>
                    }>
                    <Box className="finder-box-acordion">
                        <AccordionBox
                            keyColumn={{
                                parent: "FrotaPai",
                                children: "SubFrotas",
                            }}
                            columns={COLUMNS_FLEETS}
                            data={fleetsData.data}
                            select={(_item) => {
                                dispatch(fleetsActions.selectFleets(_item));
                                setDisplayView(true);
                            }}
                        />
                    </Box>
                    <Pagination
                        onChange={(event, value) => {
                            dispatch(
                                fleetsActions.updateFilterFleet({
                                    ...filter,
                                    page: value - 1,
                                })
                            );
                        }}
                        page={filter.page + 1}
                        count={Math.ceil(fleetsData.recordsTotal / filter.limit)}
                    />
                </CardGroup>
            </Box>
            <Box className="container-group-card-item">
                {displayView && (
                    <CardGroup
                        data-id={"cadastrar-frotas-tabs-id"}
                        className={"no-padding"}
                        loading={loading.selectFleets || loading.saveFleets || loading.importFileFleet}>
                        <TabsFleets
                            closeTab={() => {
                                setDisplayView(false);
                            }}
                        />
                    </CardGroup>
                )}
            </Box>
        </Box>
    );
};

export default FinderFleets;
