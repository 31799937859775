import React, { useState, useEffect } from "react";
import TableCard from "~/components/tableCard/TableCardReUse";
import { useSelector, useDispatch } from "react-redux";
import Loading from "~/components/common/loading/LoadingPanel";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import AlertForm from "~/components/alert/register/AlertForm";

import alertsActions from "~/actions/alertActions";
// import { Container } from './styles';
import useFuso from "~/components/common/fuso/useFuso";

import { Typography, Box, Grid, Button } from "@material-ui/core";
import "./historicAlert.scss";

const HistoricAlerts = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { alertDetailsHistory, alertDetailsHistoryId } = useSelector((state) => state.alert);
    const { loading } = useSelector((state) => state.app);
    const { acoes } = useSelector((state) => state.alert.actions);
    const { conditions } = useSelector((state) => state.alert);
    const { alert } = useSelector((state) => state.alert);
    const [showForm, setShowForm] = useState(false);

    const { fromUtc } = useFuso();

    useEffect(() => {
        setShowForm(false);
    }, [alert.Alerta.Id]);

    const COLUMNS_ALERTS = [
        {
            name: "Nome",
            label: t(Labels.ALERT_VIOLATED_DETAILS_COLUMNS_NAME),
            render: (name) => <span>{name}</span>,
        },
        {
            name: "Id",
            label: t(Labels.ALERT_VIOLATED_DETAILS_COLUMNS_ID),
            render: (id) => <span>{id}</span>,
        },
        {
            name: "StatusDescricao",
            label: t(Labels.ALERT_VIOLATED_DETAILS_COLUMNS_STATUS),
            render: (status) => <span>{status}</span>,
        },
        {
            name: "DataHoraCadastro",
            label: t(Labels.ALERT_VIOLATED_DETAILS_COLUMNS_REGISTER_DATE),
            render: (date) => (
                <span>
                    {fromUtc(date, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")}
                    <span style={{ color: "#AFC3D2", margin: "auto 5px" }}>|</span>
                    {fromUtc(date, "YYYY-MM-DDTHH:mm:ss").format("HH:mm:ss")}
                </span>
            ),
        },
        {
            name: "UsuarioCadastro",
            label: t(Labels.ALERT_VIOLATED_DETAILS_COLUMNS_REGISTER_USER),
            render: (status) => <span>{status}</span>,
        },
        {
            name: "UsuarioInativacao",
            label: t(Labels.ALERT_VIOLATED_DETAILS_COLUMNS_EDIT_USER),
            render: (status) => <span>{status}</span>,
        },
    ];

    const getAlertById = (_item) => {
        dispatch(alertsActions.getAlertAlterHistoryId(_item.Id));
        setShowForm(true);
    };

    const cleanAlert = () => {
        setShowForm(false);
        dispatch(alertsActions.cleanAlertAlterHistory());
    };

    return (
        <Box style={{ padding: "1rem" }}>
            {(loading.getAlertAlterHistory || loading.getAlertAlterHistoryId) && (
                <div className="loadingGridData loadingGroupCard">
                    <Loading size={40} />
                </div>
            )}
            {showForm &&
            alertDetailsHistoryId.Alerta &&
            (alertDetailsHistoryId.AlertasCondicoes || alertDetailsHistoryId.AlertasAcoes) ? (
                <>
                    <AlertForm
                        compact
                        disabled
                        enableReinitialize
                        initialValues={{
                            Nome: alertDetailsHistoryId.Alerta.Nome,
                            DiasSemana: alertDetailsHistoryId.Alerta.DiasSemana,
                            AlertaCondicaoTipoId: alertDetailsHistoryId.Alerta.AlertaCondicaoTipoId,
                            HorarioValidacaoInicial: alertDetailsHistoryId.Alerta.HorarioValidacaoInicial,
                            HorarioValidacaoFinal: alertDetailsHistoryId.Alerta.HorarioValidacaoFinal,
                            DataValidade: alertDetailsHistoryId.Alerta.DataValidade,
                            AlertasAcoes: alertDetailsHistoryId.AlertasAcoes.map((ac) => {
                                const acao = acoes.find((c) => c.Id === ac.Codigo) || {};
                                return {
                                    ...ac,
                                    Nome: acao.Nome,
                                };
                            }),
                            AlertasCondicoes: alertDetailsHistoryId.AlertasCondicoes.map((ac) => {
                                const cond = conditions.find((c) => c.Id === ac.Codigo) || {};
                                return {
                                    ...ac,
                                    Nome: cond.Nome,
                                };
                            }),
                        }}
                    />
                    <Grid container direction="row">
                        <Grid item xs={5} md={5}>
                            <Box className="button-form button-form-refactor">
                                <Button
                                    id="profile-cancel-button"
                                    className="cancel-button cancel-button-refactor"
                                    variant="contained"
                                    size="medium"
                                    disableElevation
                                    fullWidth
                                    onClick={cleanAlert}>
                                    {t(Labels.LABEL_HISTORY_ID_BACKLIST)}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <div className="boxTableAlerts">
                    <Typography id="label-history-alert">{t(Labels.LABEL_HISTORY_ALERT)}</Typography>
                    <TableCard
                        title={"Histórico das ultimas atualizações"}
                        columns={COLUMNS_ALERTS}
                        dataSource={alertDetailsHistory}
                        onSelect={getAlertById}
                    />
                </div>
            )}
        </Box>
    );
};

export default HistoricAlerts;
