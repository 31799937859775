import Constants from "~/helpers/enums/Constants";

const initialState = {
	periodForm: null,
	objectForm: null,
	scheduleForm: null,
	lastRecord: 0,
	perObject: {
		filters: {
			vehicle: "",
			fleet: "",
			speed: 0,
			period: {},
			index: 0,
			limit: 25,
			search: "",
			SearchColumns: [
				{
					Data: "DataHoraEventoLocal",
					Name: "dataHoraEventoUtc",
					OrderTable: true,
					OrderDirection: "desc",
				},
			],
		},
		data: {
			data: [],
		},
	},
	perPeriod: {
		vehicle: "",
		fleet: "",
		speed: 0,
		period: {},
		email: "",
	},
	scheduling: {
		edition: {},
		data: [],
	},
	sent: {
		pagination: {},
		data: [],
	},
};

function eventReportReducer(state = initialState, action = null) {
	const { payload } = action;
	switch (action.type) {
		case Constants.GET_EVENTS_REPORT_SCHEDULING:
			return {
				...state,
				scheduling: {
					...state.scheduling,
					data: payload.data,
					pagination: payload.pagination,
				},
			};

		case Constants.GET_EVENTS_REPORT_SENT:
			return {
				...state,
				sent: {
					data: payload.data,
					pagination: payload.pagination,
				},
			};
		case Constants.EDIT_EVENTS_REPORT_SCHEDULING:
			return {
				...state,
				scheduling: {
					...state.scheduling,
					edition: payload,
				},
			};
		case Constants.REQUEST_EVENT_PER_OBJECT:
			return {
				...state,
				perObject: {
					...state.perObject,
					data: action.payload,
				},
			};
		case Constants.UPDATE_FILTER_EVENT_OBJECT:
			return {
				...state,
				perObject: {
					...state.perObject,
					filters: {
						...state.perObject.filters,
						...action.payload.filter,
					},
				},
			};
		case Constants.UPDATE_FILTER_EVENT_PERIOD:
			return {
				...state,
				perPeriod: action.payload.filter,
			};
		case Constants.UPDATE_FORM_EVENT_PERIOD:
			return {
				...state,
				periodForm: action.payload.form,
			};
		case Constants.UPDATE_FORM_EVENT_OBJECT:
			return {
				...state,
				objectForm: action.payload.form,
			};
		case Constants.UPDATE_FORM_EVENT_SCHEDULED:
			return {
				...state,
				scheduleForm: action.payload.form,
			};
		case Constants.UPDATE_FILTER_EVENT_SCHEDULED:
			return {
				...state,
				scheduled: action.payload.filter,
			};
		case Constants.CLEAN_FILTER_EVENT_OBJECT:
			return {
				...state,
				perObject: initialState.perObject,
			};
		case Constants.CLEAN_FILTER_EVENT_PERIOD:
			return {
				...state,
				perPeriod: initialState.perPeriod,
			};
		case Constants.CLEAN_FILTER_EVENT_SCHEDULED:
			return {
				...state,
				scheduled: initialState.scheduled,
			};
		case Constants.UPDATE_FILTER_EVENT_LAST_RECORD:
			return {
				...state,
				lastRecord: payload,
			};
		default:
			return state;
	}
}

export default eventReportReducer;
