import React, { useState, useRef } from "react";

import { useSelector } from "react-redux";

import _ from "lodash";

import ExpansionCard from "~/components/common/expansionCard/ExpansionCard";
import ExpansionGrid from "~/components/common/expansionCard/ExpansionGrid";
import ExpansionCardEnum from "~/components/common/expansionCard/ExpansionCardEnum";
import GridResultsObject from "~/components/reporting/speeding/perObject/GridResultsObject";
import { ObjectPanel } from "~/components/common/reportTab/ReportPanel";
import Loading from "~/components/common/loading/LoadingPanel";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import ReactEcharts from "echarts-for-react";

import "./Graph.scss";

const circle = (circleColor) => {
    if (circleColor === "rgba(0,0,0,0)") {
        circleColor = "#7CB5EC"; // por algum bug da lib, o valor da primeira cor do tooltip sempre está vindo transparente
    }
    return `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;
  background-color:${circleColor}"></span>`;
};

const SpeedingObject = (props) => {
    const {
        chartData,
        perObject: { filters },
    } = useSelector((state) => state.speedingReport);
    const { loading } = useSelector((state) => state.app);

    const [alignment, setAlignment] = useState(ExpansionCardEnum.HORIZONTAL);
    const refSerachDiv = useRef(null);

    const hasDetails = filters.details && !_.isEmpty(chartData.details);
    const { t } = useTranslation();

    return (
        <div>
            <ObjectPanel>
                <ExpansionGrid alignment={alignment} fnClick={setAlignment}>
                    {hasDetails && (
                        <ExpansionCard
                            className="speeding-graph card-refactor"
                            card={"relatorioVelocidade"}
                            title={"Registros"}
                            classTitle={"cardTitle"}
                            flex={"0 1 30%"}
                            key={"1"}
                            alignment={alignment}
                            fnClick={setAlignment}
                            refTitleDiv={refSerachDiv}>
                            <GridResultsObject mode={alignment} refSerachDiv={refSerachDiv} />
                        </ExpansionCard>
                    )}
                    <ExpansionCard
                        key={"2"}
                        flex={`0 1 ${hasDetails ? "70%" : "100%"}`}
                        title={t(Labels.RESULTS)}
                        alignment={alignment}
                        fnClick={setAlignment}
                        className="speeding-graph card-refactor">
                        <div
                            className="graphCard"
                            style={{
                                width:
                                    alignment.orientation === "vertical" ? "100%" : `${hasDetails ? "60vw" : "100%"}`,
                            }}>
                            {loading.requestObjectSpeeding && (
                                <div className="loadingGridData loadingGroupCard">
                                    <Loading size={40} />
                                </div>
                            )}
                            {_.isEmpty(chartData) || _.isEmpty(chartData?.speedSeries) ? (
                                <>Nenhum resultado encontrado.</>
                            ) : (
                                <ReactEcharts
                                    loadingOption={{ text: "Carregando..." }}
                                    option={{
                                        tooltip: {
                                            trigger: "axis",
                                            axisPointer: {
                                                type: "cross",
                                            },
                                            formatter: function (params) {
                                                const title = `<span>${params[0].axisValueLabel}</span>`;
                                                const body = params.reduce(
                                                    (a, item) =>
                                                        a +
                                                        `</br><span>${circle(item.color)} ${item.seriesName}: ${
                                                            item.value ? item.value : "-"
                                                        }</span>`,
                                                    ""
                                                );
                                                return title + body;
                                            },
                                        },

                                        grid: {
                                            bottom: 100,
                                        },

                                        toolbox: {
                                            show: true,
                                            feature: {
                                                saveAsImage: {
                                                    title: "Salvar imagem",
                                                },
                                            },
                                        },

                                        legend: {
                                            show: true,
                                            bottom: "0%",
                                        },

                                        xAxis: {
                                            type: "category",
                                            boundaryGap: false,
                                            data: chartData?.xAxis,
                                        },

                                        yAxis: {
                                            type: "value",
                                            axisLabel: {
                                                formatter: "{value} Km/h",
                                            },
                                            axisPointer: {
                                                snap: true,
                                            },
                                            name: "Velocidade x Velocidade da Via",
                                            nameLocation: "middle",
                                            nameTextStyle: {
                                                fontSize: 18,
                                            },
                                            nameGap: 80,
                                        },

                                        dataZoom: [
                                            {
                                                type: "inside",
                                                start: 0,
                                                end: 100,
                                            },

                                            {
                                                type: "slider",
                                                show: true,
                                                yAxisIndex: 0,
                                                filterMode: "none",
                                            },

                                            {
                                                type: "slider",
                                                show: true,
                                                xAxisIndex: 0,
                                                filterMode: "filter",
                                                right: 50,
                                                left: 50,
                                                bottom: 35,
                                            },
                                        ],

                                        visualMap: {
                                            show: false,
                                            type: "piecewise",
                                            dimension: 0,
                                            seriesIndex: 0,
                                        },

                                        series: [
                                            {
                                                name: "Velocidade",
                                                type: "line",
                                                smooth: true,
                                                lineStyle: {
                                                    color: "#7CB5EC",
                                                    width: 1,
                                                },
                                                itemStyle: {
                                                    color: "#7CB5EC",
                                                },
                                                data: chartData?.speedSeries,
                                            },
                                            {
                                                name: "Velocidade da via",
                                                type: "line",
                                                smooth: true,
                                                lineStyle: {
                                                    color: "#000000",
                                                    width: 1,
                                                },
                                                itemStyle: {
                                                    color: "#000000",
                                                },
                                                data: chartData?.speedLimitSeries,
                                            },
                                            {
                                                name: "Velocidade Excedida",
                                                type: "line",
                                                smooth: true,
                                                lineStyle: {
                                                    color: "#EF0707",
                                                    width: 1,
                                                },
                                                itemStyle: {
                                                    color: "#EF0707",
                                                },
                                                data: chartData?.speedSeriesAbove,
                                            },
                                        ],
                                    }}
                                />
                            )}
                        </div>
                    </ExpansionCard>
                </ExpansionGrid>
            </ObjectPanel>
        </div>
    );
};

export default SpeedingObject;
