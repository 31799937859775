import Utils from "~/helpers/Utils";
import userService from "~/services/userService";
import Constants from "~/helpers/enums/Constants";

const validateAccess = (identifier, callback = () => {}, LOADING_IDENTIFICATOR = "validateAccess") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return userService
        .validateAccess(identifier)
        .then((res) => {
            if (typeof res.data !== "object" && res.data.includes("não localizado")) {
                callback(false, { msg: res.data, error: true });
                return;
            }
            callback(false, res.data);
        })
        .catch((error) => {
            callback(true, error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const setDisplayFleet = (display, callback = () => {}, LOADING_IDENTIFICATOR = "setDisplayFleet") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    dispatch({ type: Constants.DISPLAY_FLEET_USER, payload: display });
    setTimeout(() => {
        dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    }, 500);
};

const createAccess = (access, callback = () => {}, LOADING_IDENTIFICATOR = "createAccess") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return userService
        .createAccess(access)
        .then((res) => {
            callback(false, res);
        })
        .catch(({ response: { data } }) => {
            callback(true, data.Message);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const createUser = (user, callback = () => {}, LOADING_IDENTIFICATOR = "createUser") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return userService
        .createUser(user)
        .then((res) => {
            callback(res.data);
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const userActivationById = (filter, LOADING_IDENTIFICATOR = "userActivationById") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return userService
        .userActivationById(filter)
        .then((res) => {
            Utils.showTranslatedToast({
                type: "info",
                description: res.data,
            });
            dispatch({ type: Constants.USER_ACTIVATE_ID, payload: res.data });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const editUser = (user, callback = () => {}, LOADING_IDENTIFICATOR = "editUser") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return userService
        .editUser(user)
        .then((res) => {
            callback(res.data);
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const saveLinkTraceable = (userId, traceables, LOADING_IDENTIFICATOR = "saveLinkTraceable") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return userService
        .saveLinkTraceable(userId, traceables)
        .then((res) => {
            console.log(res);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const saveUnLinkTraceable = (userId, traceables, LOADING_IDENTIFICATOR = "saveUnLinkTraceable") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return userService
        .saveUnLinkTraceable(userId, traceables)
        .then((res) => {
            console.log(res);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getUserById = (id, LOADING_IDENTIFICATOR = "getUserById") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return userService
        .getUserById(id)
        .then((res) => {
            dispatch({ type: Constants.GET_USER_BY_ID, payload: res });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getUsers = (filter, LOADING_IDENTIFICATOR = "getUsers") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return userService
        .getUsers(filter)
        .then((res) => {
            dispatch({ type: Constants.GET_USERS, payload: res });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const setUserSearch = (search) => (dispatch) => {
    dispatch({ type: Constants.SET_USER_SEARCH, payload: search });
}

const getTraceableLinked = (userId, search, pagination, LOADING_IDENTIFICATOR = "getTraceableLinked") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return userService
        .getTraceableLinked(userId, search, pagination)
        .then((res) => {
            dispatch({ type: Constants.GET_CARS_LINKED_FOR_USER, payload: res.data });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getAvailableTraceable = (userId, filter, LOADING_IDENTIFICATOR = "getAvailableTraceable") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return userService
        .getAvailableTraceable(userId, filter)
        .then((res) => {
            dispatch({ type: Constants.GET_AVAILABLE_CARS_FOR_USER, payload: res.data });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const cleanCarsLinked = () => (dispatch) => {
    dispatch({ type: Constants.CLEAN_CARS_LINKED_FOR_USER });
};

const cleanAvailableCars = () => (dispatch) => {
    dispatch({ type: Constants.CLEAN_AVAILABLE_CARS_FOR_USER });
};

const getFunctionality = (search = "", pagination = {}, LOADING_IDENTIFICATOR = "getFunctionality") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return userService
        .getFunctionality({ length: 100 })
        .then((res) => {
            dispatch({ type: Constants.GET_FUNCTIONALITY_FOR_USER, payload: res });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getFleetByUserId = (userId, LOADING_IDENTIFICATOR = "getFleetByUserId") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return userService
        .getFleetByUserId(userId)
        .then((res) => {
            dispatch({ type: Constants.GET_FLEET_BY_USER, payload: res });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getPermissionsByUserId = (userId, LOADING_IDENTIFICATOR = "getPermissionsByUserId") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return userService
        .getPermissionsByUserId(0, userId)
        .then((res) => {
            return Promise.all(
                res.map(async (i) => {
                    i.additionalParameters.children = await userService.getPermissionsByUserId(
                        i.additionalParameters.id,
                        userId
                    );
                    return i;
                })
            );
        })
        .then((res) => {
            dispatch({ type: Constants.GET_PERMISSIONS_BY_USER, payload: res });
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

export default {
    validateAccess,
    createAccess,
    userActivationById,
    getTraceableLinked,
    getAvailableTraceable,
    cleanCarsLinked,
    cleanAvailableCars,
    saveLinkTraceable,
    saveUnLinkTraceable,
    createUser,
    setDisplayFleet,
    editUser,
    getFunctionality,
    getFleetByUserId,
    getPermissionsByUserId,
    getUsers,
    getUserById,
    setUserSearch
};
