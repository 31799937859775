import React, { Fragment, useState } from "react";

import PropTypes from "prop-types";

import { withoutSpace } from "~/helpers/Utils";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Typography, Box } from "@material-ui/core";

import "./TabMain.scss";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tab-pgf-${index}`}
            aria-labelledby={`tab-pgf-${index}`}
            {...other}>
            {value === index && <Box className="childrenPanelTab">{children}</Box>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(label, index) {
    return {
        id: `tab-${index}-${withoutSpace(label)}`,
        "aria-controls": `tab-${withoutSpace(label)}-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,

        "& .MuiTabs-root": {
            borderBottom: "1px solid #AFC3D2",
            backgroundColor: "#fff",
        },

        "& .MuiTabs-root .MuiTab-root": {
            minWidth: "15%",
        },
    },
}));

const TabMain = ({ tabs, className, header, component, ...props }) => {
    const classes = useStyles();
    const [index, setIndex] = useState(0);

    const handleChange = (event, newIndex) => {
        setIndex(newIndex);
    };

    const ComponentTab = component || Box;

    return (
        <div {...props} className={`${classes.root} ${className}`}>
            <ComponentTab>
                {header}
                <Tabs
                    variant="scrollable"
                    color="primary"
                    value={index}
                    component={Box}
                    onChange={handleChange}
                    indicatorColor="primary">
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.id}
                            style={{
                                paddingRight: "2rem",
                            }}
                            label={
                                <Typography
                                    className={props.classNameLabelRefactor}
                                    id={tab.label}
                                    style={{ textTransform: "none" }}>
                                    {tab.label}
                                </Typography>
                            }
                            className={tab.hasError ? "has-error" : ""}
                            {...a11yProps(tab.label, tab.id)}
                        />
                    ))}
                </Tabs>
            </ComponentTab>
            {tabs.map((panel) => (
                <Fragment key={panel.id}>
                    <TabPanel value={index} index={panel.id}>
                        {panel.children}
                    </TabPanel>
                </Fragment>
            ))}
        </div>
    );
};

export default TabMain;
