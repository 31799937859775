import Constants from "~/helpers/enums/Constants";

const updateFilterDistanceObject = (filters) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FILTER_DISTANCE_OBJECT, payload: { filters } });
};

const callExportDocumentReport = (callDoc) => (dispatch) => {
    dispatch({ type: Constants.CALL_EXPORT_DOCUMENT_DISTANCE, callDoc });
};

const cleanExportDocumentReport = () => (dispatch) => {
    dispatch({ type: Constants.CLEAN_CALL_EXPORT_DOCUMENT_DISTANCE });
};

const updateObjectForm = (form) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FORM_DISTANCE_OBJECT, payload: { form } });
};

const updatePeriodForm = (form) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FORM_DISTANCE_PERIOD, payload: { form } });
};

const updateScheduleForm = (form) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FORM_DISTANCE_SHEDULED, payload: { form } });
};

export default {
    updateFilterDistanceObject,
    callExportDocumentReport,
    cleanExportDocumentReport,
    updateObjectForm,
    updatePeriodForm,
    updateScheduleForm,
};
