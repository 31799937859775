import React from "react";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { Box, Typography, FormControl, FormHelperText, MenuItem, ListItemIcon } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import FormikSelectPopper from "~/components/common/popperComponents/FormikSelectPopper";
import FileTypeEnum from "~/helpers/enums/FileTypeEnum";
import { PdfIcon, ExcelIcon } from "~/components/common/icons/Icons";

import "~/components/reporting/Reporting.scss";
import "~/components/reporting/common/ReportingCommon.scss";

const ReportFileTypeField = (props) => {
    const { touched, errors } = useFormikContext();

    const error = errors && errors[props.name];
    const isTouched = touched && touched[props.name];

    const mountIcon = (value) => {
        switch (value) {
            case FileTypeEnum.PDF.value:
                return <PdfIcon style={{ marginRight: 7 }} />;
            case FileTypeEnum.XLS.value:
                return <ExcelIcon style={{ marginRight: 7 }} />;
            default:
                return <PdfIcon />;
        }
    };

    return (
        <Box className="report-field">
            <FormControl fullWidth margin="dense">
                <Typography variant="subtitle1" className="report-field-label">
                    {props.label}
                </Typography>
                <Field
                    id={props.id}
                    name={props.name}
                    component={FormikSelectPopper}
                    onChange={props.onChange}
                    IconComponent={ExpandMoreRoundedIcon}
                    error={isTouched && !!error}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    renderValue={(value) => FileTypeEnum.LIST.find((f) => f.value === value).description}>
                    {FileTypeEnum.LIST.map((f) => (
                        <MenuItem data-id={"arquivo-id-" + f.description} key={f.description} value={f.value}>
                            <ListItemIcon> {mountIcon(f.value)} </ListItemIcon>
                            {f.description}
                        </MenuItem>
                    ))}
                </Field>

                <FormHelperText error margin="dense" variant="outlined">
                    {isTouched && error}
                </FormHelperText>
            </FormControl>
        </Box>
    );
};
export default ReportFileTypeField;
