import React from "react";

import { Typography } from "@material-ui/core";

import "./View.scss";

const ViewItemData = ({ column, data, render }) => {
    return (
        <div className="viewItemdata">
            <span className="column">
                <Typography>{column.label}</Typography>
            </span>
            <span className="data">
                {render ? render(data[column.name]) : <Typography>{data[column.name]}</Typography>}
            </span>
        </div>
    );
};

export default ViewItemData;
