import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";

function AlertModal(props) {
	const handleClose = () => {
		props.onClose();
	};

	return (
		<Snackbar
			anchorOrigin={props.anchorOrigin || { vertical: "center", horizontal: "center" }}
			open={props.open}
			onClose={handleClose}
			key={props.keyValue || "topcenter"}>
			<Alert id={props?.idAlert} onClose={handleClose} severity={props.severity} closeText="Fechar">
				{props.message}
			</Alert>
		</Snackbar>
	);
}

export default AlertModal;
