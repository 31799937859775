import http from "~/config/httpRequest/http";

const fleets = () => {
    return new Promise((resolve, reject) => {
        http.get(`/pesquisa/ConsultarFrota`)
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};
const subFleets = (filter) => {
    return new Promise((resolve, reject) => {
        http.get(`/pesquisa/BuscarSubFrota`, { params: { grupoId: filter } })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const periodTrips = (filter) => {
    return new Promise((resolve, reject) => {
        http.get(`/dashboard/BuscarViagens`, { params: { ...filter } })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const travel = (filter) => {
    delete filter.ValueInicio;
    delete filter.ValueFim;

    return new Promise((resolve, reject) => {
        http.get(`/dashboardVeiculo/BuscarComparativoComBase`, { params: { ...filter } })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const daytravel = (filter) => {
    return new Promise((resolve, reject) => {
        http.get(`/dashboardVeiculo/buscarkmrodadopordia`, { params: { ...filter } })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const scores = (filter) => {
    return new Promise((resolve, reject) => {
        http.get(`/Dashboard/BuscarScore`, { params: { ...filter } })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const fleetEvolution = (filter) => {
    return new Promise((resolve, reject) => {
        http.get(`/dashboard/BuscarEvolucaoFrota`, { params: { ...filter } })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const monthlyFleetEvolution = (filter) => {
    return new Promise((resolve, reject) => {
        http.get(`/dashboard/BuscarEvolucaoMensal`, { params: { ...filter } })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const topTenMaxVelocityVehicle = (filter) => {
    return new Promise((resolve, reject) => {
        http.get(`/dashboard/BuscarTop10VeiculoVelocidadeMaxima`, {
            params: {
                ...filter,
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const topTenMaxVelocityDriver = (filter) => {
    return new Promise((resolve, reject) => {
        http.get(`/dashboard/BuscarTop10MotoristasVelocidadeMaxima`, {
            params: {
                ...filter,
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const travelMaxVelocityPositions = (filter) => {
    return new Promise((resolve, reject) => {
        http.get(`/dashboardVeiculo/BuscarUltimasPosicoesSemDataTable`, {
            params: {
                ...filter,
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const vehicleHeatMap = (filter) => {
    return new Promise((resolve, reject) => {
        http.get(`/dashboardVeiculo/BuscarCoordenadasMapaCalor`, {
            params: {
                ...filter,
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const findTravelsVehicle = (filter) => {
    return new Promise((resolve, reject) => {
        http.post(`/dashboardVeiculo/BuscarViagensVeiculo`, { ...filter })
            .then((res) => resolve(res.data))
            .catch((error) => reject(error));
    });
};

const findLastPositionsWithoutDataTable = (filter) => {
    return new Promise((resolve, reject) => {
        http.get(`/dashboardVeiculo/BuscarUltimasPosicoesSemDataTable`, {
            params: {
                ...filter,
            },
        })
            .then((res) => resolve(res.data))
            .catch((error) => reject(error));
    });
};

const findLastPositions = (filter) => {
    return new Promise((resolve, reject) => {
        http.post(`/dashboardVeiculo/BuscarUltimasPosicoes`, {
            ...filter,
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const getPrivateMode = (chassi) => {
    return new Promise((resolve, reject) => {
        http.get(`/dashboardVeiculo/BuscarModoPrivado`, {
            params: {
                chassi,
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const switchPrivateMode = (chassi, ativo) => {
    return new Promise((resolve, reject) => {
        http.post(`/dashboardVeiculo/AtivarDesativarModoPrivado`, { 
            chassi,
            ativo
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

export default {
    daytravel,
    fleets,
    subFleets,
    periodTrips,
    scores,
    travel,
    fleetEvolution,
    monthlyFleetEvolution,
    topTenMaxVelocityVehicle,
    topTenMaxVelocityDriver,
    travelMaxVelocityPositions,
    vehicleHeatMap,
    findTravelsVehicle,
    findLastPositionsWithoutDataTable,
    findLastPositions,
    getPrivateMode,
    switchPrivateMode,
};
