import React, { useState } from "react";

import { makeStyles, Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./ContentAccordion.scss";

export default function ContentAccordion({ notIcon, iconTitle, title, ...props }) {
    const [background, setBackground] = useState(false);

    const useStyles = makeStyles((theme) => ({
        titleColor: {
            background: `${theme.palette.primary.main} !important`,

            "& .titleStyles": {
                color: "white",
            },

            "& .MuiIconButton-label": {
                color: "white",
            },
        },
    }));

    const classes = useStyles();

    return (
        <div className="main-accordion">
            <Accordion
                {...props}
                className={props.className || ""}
                data-id={String("accordion-id-" + title).replace(/\s/g, "")}>
                <AccordionSummary
                    data-id={String("accordion-summary-id-" + title).replace(/\s/g, "")}
                    expandIcon={notIcon ? <span /> : <ExpandMoreIcon />}
                    aria-controls={title + "-id"}
                    id={title + "-id"}
                    className={
                        (props.bgColor
                            ? props.titleColor && classes.titleColor
                            : !!props.titleColor && background && classes.titleColor) || ""
                    }
                    onClick={() => {
                        if (props.onClickItem) {
                            props.onClickItem();
                        }
                        setBackground(!background);
                    }}>
                    <span className="fieldTitle">
                        {iconTitle}
                        <Typography className="titleStyles">{title}</Typography>
                    </span>
                </AccordionSummary>
                <AccordionDetails>{props.children}</AccordionDetails>
            </Accordion>
        </div>
    );
}
