import React from "react";
import { prefixWithZeros } from "~/helpers/Utils";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from "@material-ui/core";
import "./TopTenTable.scss";

const TopTenTable = (props) => {
    return (
        <TableContainer className="top-ten-table">
            <Table>
                <colgroup>
                    <col width="3%" />
                    {props.columns && props.columns.map((column, i) => <col width={column.width} key={i} />)}
                </colgroup>
                <TableHead>
                    <TableRow>
                        <TableCell width="3%" className="numerate-column" hidden={props.hidddenNumbers} />
                        {props.columns &&
                            props.columns.map(({ title, ...column }, i) => (
                                <TableCell key={i} className={`${column.borded && "borded"}`}>
                                    {title}
                                </TableCell>
                            ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rows &&
                        props.rows.map(({ key, value, ...row }, i) => {
                            const rowKey = key || i;
                            return (
                                <TableRow
                                    key={rowKey}
                                    onClick={(ev) => {
                                        props.onItemClick && props.onItemClick(ev, key, value, row);
                                    }}
                                    hover
                                    style={{
                                        cursor: props.onItemClick ? "pointer" : "normal",
                                    }}>
                                    <TableCell className="numerate-column" hidden={props.hidddenNumbers}>
                                        <span className="number">
                                            {prefixWithZeros(i + 1, props.prefixNumber || 2)}
                                        </span>
                                    </TableCell>
                                    {props.columns &&
                                        props.columns.map((column, j) => (
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                key={j}
                                                className={`${column.borded && "borded"}`}>
                                                {column.render
                                                    ? column.render(row[column.key], row, i)
                                                    : row[column.key]}
                                            </TableCell>
                                        ))}
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TopTenTable;
