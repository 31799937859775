import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import DashboardVehicleFilter from "~/components/dashboardVehicle/common/DashboardVehicleFilter";
import dashboardChartsAction from "~/actions/dashboardVehicle/dashboardChartsAction";
import CardGrid from "~/components/dashboardVehicle/common/CardGrid";
import ExpansionCardGrid from "~/components/dashboardVehicle/common/ExpansionCardGrid";

import DashVehicleGraphEnum from "../common/DashVehicleGraphEnum";

import PeriodTripsChartPie from "~/components/dashboardVehicle/chartPie/PeriodTripsChartPie";
import RoadOffsetChartPie from "~/components/dashboardVehicle/chartPie/RoadOffsetChartPie";
import KmRunChartPie from "~/components/dashboardVehicle/kmRun/KmRunChartPie";
import KmRunIndicator from "~/components/dashboardVehicle/kmRun/KmRunIndicator";
import KmRunPeriod from "~/components/dashboardVehicle/kmRunPeriod/KmRunPeriod";
import EvolutionFleet from "~/components/dashboardVehicle/evolutionFleet/EvolutionFleet";
import KmRunPeriodDay from "~/components/dashboardVehicle/kmRunPeriodDay/KmRunPeriodDay";

import ScoreChartGauge from "~/components/dashboardVehicle/chartGauge/ScoreChartGauge";
import DataVehicle from "~/components/dashboardVehicle/dataVehicle/DataVehicle";
import MapVehicle from "~/components/dashboardVehicle/mapVehicle/MapVehicle";
import BaseComparative from "~/components/dashboardVehicle/baseComparative/BaseComparative";
import HeatMap from "~/components/dashboardVehicle/heatmap/HeatMap";

import "./TabsDash.scss";

const Informations = () => {
    const dispatch = useDispatch();

    const { rangeDate, vehicle } = useSelector((state) => state.dashboardVehicleFilter);
    const { isVisibleDetailsKmRun } = useSelector((state) => state.dashboardVehicleTravels);
    const { currentFilters } = useSelector((state) => state.dashboardVehicleToolbar);

    useEffect(() => {
        if (!rangeDate.AnoInicio || !rangeDate.AnoFim) {
            return;
        }

        dispatch(
            dashboardChartsAction.getTravel(
                { ObjetoRastreavelId: vehicle?.ObjetoRastreavelId, ...rangeDate },
                "waitingTravel"
            )
        );
        dispatch(
            dashboardChartsAction.getScores(
                { ObjetoRastreavelId: vehicle?.ObjetoRastreavelId, ...rangeDate },
                "waitingScore"
            )
        );
    }, [dispatch, rangeDate, vehicle]);

    return (
        <>
            <Grid container>
                <Grid item xs={12} className="itemsDash">
                    <DashboardVehicleFilter vehicle={vehicle} />
                </Grid>
                <Grid item xs={12} className="itemsDash">
                    <DataVehicle vehicle={vehicle} />
                </Grid>
                <Grid item xs={12} className="itemsDash">
                    <MapVehicle />
                </Grid>
                <Grid item xs={12} className="itemsDash">
                    <BaseComparative />
                </Grid>
            </Grid>

            <Grid className="itemsDash" container>
                <CardGrid item xs={4} name={DashVehicleGraphEnum.KM_RUN.name}>
                    <KmRunChartPie />
                </CardGrid>
                <CardGrid item xs={4} name={DashVehicleGraphEnum.PERIOD_TRIPS.name}>
                    <PeriodTripsChartPie />
                </CardGrid>
                <CardGrid item xs={4} name={DashVehicleGraphEnum.ROAD_OFFSET.name}>
                    <RoadOffsetChartPie />
                </CardGrid>
            </Grid>

            {currentFilters[DashVehicleGraphEnum.KM_RUN.name]?.checked && isVisibleDetailsKmRun && (
                <Grid className="itemsDash" container>
                    <Grid item xs={4}>
                        <KmRunIndicator title="Km rodados Horário Comercial" action="distanciaComercial" />
                    </Grid>
                    <Grid item xs={4}>
                        <KmRunIndicator title="Km rodados Fora do Horário" action="distanciaNaoComercial" />
                    </Grid>
                    <Grid item xs={4}>
                        <KmRunIndicator title="Km rodados Final de Semana" action="distanciaFimSemana" />
                    </Grid>
                </Grid>
            )}

            <Grid className="itemsDash" container>
                <CardGrid item xs={8} name={DashVehicleGraphEnum.KM_RUN_PERIOD.name}>
                    <KmRunPeriod />
                </CardGrid>
                <CardGrid className="evolutionDash" item xs={4} name={DashVehicleGraphEnum.FLEET_EVOLUTION_PERCENT.name}>
                    <EvolutionFleet />
                </CardGrid>
            </Grid>

            <Grid container>
                <CardGrid className="itemsDash" item xs={4} name={DashVehicleGraphEnum.DRIVABILITY_SCORE.name}>
                    <ScoreChartGauge nameProperty="ScoreDirecao" />
                </CardGrid>
                <CardGrid className="itemsDash" item xs={8} name={DashVehicleGraphEnum.RISK_EXPOSURE_SCORE.name}>
                    <ScoreChartGauge nameProperty="ScoreExposicaoRisco" color="secondary" />
                </CardGrid>
            </Grid>

            <Grid className="itemsDash" container>
                <CardGrid item xs={12} name={DashVehicleGraphEnum.KM_RUN_PERIOD_DAY.name}>
                    <KmRunPeriodDay />
                </CardGrid>
            </Grid>

            <Grid container>
                <ExpansionCardGrid
                    className="itemsDash"
                    item
                    xs={12}
                    name={DashVehicleGraphEnum.HEATMAP.name}
                    hideAlignments={true}
                    notPadded>
                    <HeatMap vehicle={vehicle} />
                </ExpansionCardGrid>
            </Grid>
        </>
    );
};

export default Informations;
