import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import alertsActions from "~/actions/alertsActions";

import { lighten } from "polished";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, ListItem, Box } from "@material-ui/core";

import Loading from "~/components/common/loading/Loading";
import ContentAccordion from "~/components/common/accordion/ContentAccordion";
import TableCardReUse from "~/components/tableCard/TableCardReUse";

import { AlertIcon, IconView } from "~/components/common/icons/Icons";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

import "./GridAlert.scss";

const useStyles = makeStyles((theme) => ({
    itemBtnCustom: {
        color: `${lighten(0.25, theme.palette.primary.main)} !important`,
        border: `1px solid ${lighten(0.25, theme.palette.primary.main)} !important`,
        padding: "0.5rem 0",
    },
    itemBtn: {
        color: `${lighten(0.25, theme.palette.primary.main)} !important`,
    },
    concludeButton: {
        justifyContent: "flex-start",
    },
}));

const GridAlertViolated = (props) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState({});
    const { alerts } = useSelector((state) => state.alertsViolated);
    const { loading } = useSelector((state) => state.app);
    const { t } = useTranslation();

    const handlerViewDetails = (id, row, item) => {
        setSelectedItem({ idViolacao: item.AlertaViolacaoId, row });
        dispatch(alertsActions.setAlertIdSelected(item.AlertaViolacaoId));

        const PARAMS_ALERT = {
            alertaId: item.AlertaId,
            placa: row,
            dataHoraViolacao: item.DataHoraViolacao,
            idViolacao: item.AlertaViolacaoId,
        };

        dispatch(
            alertsActions.getAlertDetails(PARAMS_ALERT, (res) => {
                dispatch(alertsActions.getHistory({ violacaoId: res.Id }));
            })
        );
    };

    const handlerMakeAll = (alert) => {
        const PARAMS_ALERT = {
            idAlert: alert.AlertaId,
            board: alert.Placa,
            violacaoId : alert.AlertaViolacaoId,
        };
        dispatch(
            alertsActions.markBoardDoneAlertsViolated(PARAMS_ALERT, () => {
                dispatch(alertsActions.getAlertsViolated());        
                dispatch(alertsActions.cleanAlertDetails());
            })
        );
    };

    const ALERTS_COLUMNS = [
        {
            label: "Placa",
            render: (row, item) => <strong>{item}</strong>,
        },
        {
            name: "Id",
            labelRender: (alert) => (
                <ListItem
                    button
                    className={`${"styleBtnTable"} ${classes.itemBtnCustom}`}
                    disabled={loading.markBoardDoneAlertsViolated}
                    onClick={() => {
                        handlerMakeAll(alert);
                    }}>
                    <Typography color="primary" className={classes.concludeButton}>
                        {t(Labels.ALERT_VIOLATED_BUTTON_CONCLUDE)}
                    </Typography>
                </ListItem>
            ),
            render: (Id, row, iCol, dataMain) => (
                <span className="boxBtnItem">
                    <ListItem
                        className={` ${"itemBtnAccordion"} ${classes.itemBtn}`}
                        button
                        onClick={() => {
                            handlerViewDetails(Id, row, dataMain);
                        }}>
                        <IconView style={{ marginRight: 5 }} />
                        <Typography component="span" color="primary">
                            {t(Labels.ALERT_VIOLATED_BUTTON_VIEW)}
                        </Typography>
                    </ListItem>
                </span>
            ),
        },
    ];
    const formatarDataHora = (dataHoraString) => {
        const options = {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        };
      
        const dataHora = new Date(dataHoraString);
        return dataHora.toLocaleDateString('pt-BR', options);
      };
    useEffect(() => {
        dispatch(alertsActions.getAlertsViolated());
    }, [dispatch]);

    return (
        <Box className={" alertCardList"}>
            {loading.getAlertsViolated ? (
                <div id="loading-object">
                    <Loading size={30} />
                </div>
            ) : (
                alerts?.map((item, i) => (
                    <ContentAccordion iconTitle={<AlertIcon />} title={<>{item.Alerta}<br />{formatarDataHora(item.DataHoraViolacao)}</>} key={i}>
                        <div className="itemAlerts">
                            <div className="conditionsList">
                                {item.ListCondicoes?.map((name, j) => (
                                    <span key={j}>
                                        <Typography>{name}</Typography>
                                    </span>
                                ))}
                            </div>
                            <TableCardReUse
                                paddingBox="0"
                                align="left"
                                columns={ALERTS_COLUMNS}
                                dataSource={[item.Placa]}
                                dataMain={item}
                                rowID={selectedItem.idViolacao === item.AlertaViolacaoId && selectedItem.row}
                            />

                        </div>
                    </ContentAccordion>
                ))
            )}
            {alerts.length <= 0 && <ContentAccordion title={t(Labels.ALERT_NONE_FOUND)} notIcon />}
        </Box>
    );
};

export default GridAlertViolated;
