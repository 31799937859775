import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import CardGroup from "~/components/common/card/CardGroup";
import MenuRange from "~/components/common/menuRange/MenuRange";
import TabsAlerts from "~/components/alert/listing/tabs/TabsAlerts";
import alertActions from "~/actions/alertActions";
import registerAlertAction from "~/actions/registerAlertAction";
import useFuso from "~/components/common/fuso/useFuso";
import "./Listing.scss";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

const ListingAlerts = () => {
    const dispatch = useDispatch();
    const { alerts, alertFilter, openEditForm } = useSelector((state) => state.alert);
    const { loading } = useSelector((state) => state.app);
    const { fromUtc } = useFuso();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(alertActions.getListAlerts({ ...alertFilter, status: alertFilter.status.toLocaleLowerCase() }));
    }, [dispatch, alertFilter]);

    useEffect(() => {
        return () => {
            dispatch(registerAlertAction.updateAlertFilter({ search: "", page: 0 }));
        }
    }, [dispatch]);

    const COLUMNS_ALERTS = [
        {
            name: "Nome",
            label: t(Labels.ALERT_VIOLATED_DETAILS_COLUMNS_NAME),
            render: (name) => <span>{name}</span>,
        },
        {
            name: "Id",
            label: t(Labels.ALERT_VIOLATED_DETAILS_COLUMNS_ID),
            render: (id) => <span>{id}</span>,
        },
        {
            name: "DataHoraCadastro",
            label: t(Labels.ALERT_VIOLATED_DETAILS_COLUMNS_REGISTER_DATE),
            render: (date) => (
                <span>
                    {fromUtc(date, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")}
                    <span style={{ color: "#AFC3D2", margin: "auto 5px" }}>|</span>
                    {fromUtc(date, "YYYY-MM-DDTHH:mm:ss").format("HH:mm:ss")}
                </span>
            ),
        },
        {
            name: "StatusDescricao",
            label: t(Labels.ALERT_VIOLATED_DETAILS_COLUMNS_STATUS),
            render: (status) => <span>{status}</span>,
            noSort: true,
        },
    ];

    return (
        <Box className="container-group-card">
            <Box className="container-group-card-item">
                <CardGroup
                    data-id={"cadastrar-alertas-id"}
                    title={"Alertas"}
                    className="card-group-refactor header-transform-none"
                    loading={loading.getListAlerts}
                    columns={COLUMNS_ALERTS}
                    source={alerts.data}
                    searchFn={(search) => {
                        dispatch(registerAlertAction.updateAlertFilter({ search, page: 0 }));
                    }}
                    headerComp={
                        <>
                            <MenuRange
                                classNameRefactor="menu-label-range-refactor"
                                label={t(Labels.MENU_RANGE_SHOW)}
                                value={alertFilter.limit}
                                onChange={(event) => {
                                    dispatch(
                                        registerAlertAction.updateAlertFilter({
                                            limit: event.target.value,
                                            page: 0,
                                        })
                                    );
                                }}
                                options={[10, 25, 50, 100, 250, 500]}
                            />
                            <MenuRange
                                classNameRefactor="menu-label-range-refactor"
                                label={t(Labels.MENU_RANGE_STATUS)}
                                value={alertFilter.status}
                                onChange={(event) => {
                                    dispatch(
                                        registerAlertAction.updateAlertFilter({
                                            status: event.target.value,
                                            page: 0,
                                        })
                                    );
                                }}
                                options={[
                                    { label: t(Labels.MENU_ACTIVE), value: "Ativo" },
                                    { label: t(Labels.MENU_INACTIVE), value: "Pendente" },
                                ]}
                            />
                        </>
                    }
                    select={(_item) => {
                        dispatch(alertActions.getListAlertsByID(_item.Id));
                        dispatch(alertActions.getAlertAlterHistory(_item.Id));

                        dispatch(registerAlertAction.setOpenInsertForm(false));
                        dispatch(registerAlertAction.setOpenEditForm(true));
                    }}
                    page={alertFilter.page + 1}
                    itemsPerPage={alertFilter.limit}
                    totalItems={alerts.recordsTotal}
                    handlePage={(event, value) => {
                        dispatch(registerAlertAction.updateAlertFilter({ page: value - 1 }));
                    }}
                    arrowCheck={openEditForm}
                    onSort={(orderObject, col, data) => {
                        dispatch(
                            registerAlertAction.updateAlertFilter({
                                SearchColumns: [
                                    {
                                        Name: orderObject.orderBy,
                                        Data: orderObject.orderBy,
                                        OrderTable: true,
                                        OrderDirection: orderObject.order,
                                    },
                                ],
                            })
                        );
                    }}
                    orderObject={{
                        order: alertFilter.SearchColumns[0].OrderDirection,
                        orderBy: alertFilter.SearchColumns[0].Name,
                    }}
                />
            </Box>
            <Box className="container-group-card-item">
                {openEditForm && (
                    <CardGroup data-id={"cadastrar-alertas-tabs-id"} className={"no-padding"}>
                        <TabsAlerts
                            closeTab={() => {
                                dispatch(registerAlertAction.setOpenEditForm(false));
                            }}
                        />
                    </CardGroup>
                )}
            </Box>
        </Box>
    );
};

export default ListingAlerts;
