import React, { useState, useEffect } from "react";
import userService from "~/services/userService";

const PDFView = ({ pdfLink }) => {
  const [showButton, setShowButton] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  useEffect(() => {
    if (acceptTerms) {
      try {
        localStorage.setItem("AceiteTermo", "True");
        userService.acceptTerms();
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }
  }, [acceptTerms]);

  const handleMouseEnter = () => {
    setShowButton(true);
  };

  const handleMouseLeave = () => {
    setShowButton(false);
  };

  const handleAcceptTerms = () => {
    setAcceptTerms(true);
  };

  return (
    <div
      style={{ width: '100%', height: '100vh' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={{ position: 'relative' }}>
        <iframe
          title="Visualizador de PDF"
          src={pdfLink}
          style={{ width: '100%', height: '500px', border: 'none' }}
        />

      </div>
    </div>
  );
};

export default PDFView;
