import React, { useEffect } from "react";
import { Paper, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TabLogin from "./tab/TabLogin";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import loginTabAction from "~/actions/loginTabAction";
import Constants from "~/helpers/enums/Constants";
import "./Login.scss";
import localImage from "~/assets/images/fleet_white.png"; 
import texture from '~/assets/images/texture.svg';

const useStyles = makeStyles((theme) => ({
    image: {
        backgroundImage: `url(${localImage}) !important`,
        backgroundRepeat: "no-repeat !important",
        backgroundColor: "#243782 !important",
        backgroundSize: "70% 10% !important", 
        backgroundPosition: "center !important",
		position: 'relative' 

    },
	svgContainer: {
	  position: 'absolute',
	  top: '70%',
	  left: '70%',
	  transform: 'translate(-50%, -50%)',
	  zIndex: 1 
	},
	svg: {
	  width: '390px !important', 
	  height: '390px !important'
	}
}));

const LoginMain = () => {
	const classes = useStyles();

	const history = useHistory();
	const { location } = history;
	const dispatch = useDispatch();

	useEffect(() => {
		const splitUrl = location.pathname.split("/Login/CadastrarUsuario/");
		if (splitUrl.length > 1) {
			const document = splitUrl[1];
			dispatch(loginTabAction.setLoginTab(Constants.REGISTER_TAB));
			dispatch(loginTabAction.updateDocument(document));
		}
	}, [dispatch, location, location.pathname]);

	return (
		<Grid container component="main" className="login-main">
			<Grid item xs={false} sm={4} md={6} >
				<Box className={`${classes.image} login-img`}>
				<Box className={classes.svgContainer}>
					<img src={texture} alt="texture" className={classes.svg}/>
				</Box>
				</Box>
			</Grid>
			<Grid item xs={12} sm={8} md={6} component={Paper} elevation={0} className="login-paper">
				<Box className="login-tab">
					<TabLogin />
				</Box>
			</Grid>
		</Grid>
	);
};

export default LoginMain;
