import React from "react";
import ReactEcharts from "echarts-for-react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import "./KmRunPeriod.scss";
import { UrbanColors, RodoviarioColors } from "./KmRunPeriodFormatter";

import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";

export default function KmRunPeriodBar() {
    const { t } = useTranslation();

    const { waitingTravel } = useSelector((state) => state.app.loading);
    const { kmRunPeriod } = useSelector((state) => state.dashboardVehicleTravels);
    const getOption = () => {
        return {
            legend: {
                orient: "horizontal",
                icon: "circle",
                bottom: 0,
                align: "left",
                itemGap: 20,
                textStyle: {
                    fontSize: 15,
                },
            },
            tooltip: {},
            dataset: {
                source: kmRunPeriod,
            },
            xAxis: { type: "category" },
            yAxis: {
                axisLabel: {
                    formatter: "{value} K",
                },
            },
            // Declare several bar series, each will be mapped
            // to a column of dataset.source by default.
            series: [
                {
                    type: "bar",
                    itemStyle: {
                        color: UrbanColors,
                    },
                },
                {
                    type: "bar",
                    itemStyle: {
                        color: RodoviarioColors,
                    },
                },
            ],
        };
    };
    return (
        <Box className="chart" style={{ minHeight: "475px" }}>
            <Box className="x-axis">
                <Typography variant="h4">{t(Labels.CHART_COMMON_KMRUN)}</Typography>
            </Box>
            <Box className="echart">
                <ReactEcharts
                    option={getOption()}
                    showLoading={waitingTravel}
                    loadingOption={{ text: "Carregando" }}
                    style={{ height: "100%", width: "100%" }}
                />
            </Box>
        </Box>
    );
}
