import React, { useEffect, useMemo } from "react";
import { MeasureArrowIcon } from "~/components/common/icons/Icons";
import { CenterIcon } from "~/components/common/icons/Icons";
import { ZoomIcon } from "~/components/common/icons/Icons";
import { Paper, MenuList, MenuItem } from "@material-ui/core";
import { createPortal } from "react-dom";
import { MAP } from "react-google-maps/lib/constants";
import PropTypes from "prop-types";
import "../GoogleMaps.scss";


const MapCustomControl = (props, context) => {
	const { controls } = context[MAP];
	const controlDiv = useMemo(() => document.createElement('div'), [])

	const _handleCenterAction = () => {
		if ("handleCenterAction" in props) {
			props.handleCenterAction();
		}
	};

	const _handleZoomAction = () => {
		if ("handleZoomAction" in props) {
			props.handleZoomAction();
		}
	};

	const _handleMeasureAction = () => {
		if ("handleMeasureAction" in props) {
			props.handleMeasureAction();
		}
	};

	useEffect(() => {
		const rightControls = controls[window.google.maps.ControlPosition.TOP_RIGHT];
		const index = rightControls.length;
		rightControls.push(controlDiv);

		return () => {
			rightControls.removeAt(index);
		};
	}, [controlDiv, controls]);

	return createPortal(
		<Paper className="control-box">
			<MenuList>
				<MenuItem onClick={_handleCenterAction}>
					<CenterIcon />
				</MenuItem>
				<MenuItem onClick={_handleZoomAction}>
					<ZoomIcon />
				</MenuItem>
				<MenuItem onClick={_handleMeasureAction}>
					<MeasureArrowIcon />
				</MenuItem>
			</MenuList>
		</Paper>,
		controlDiv
	);
};

MapCustomControl.contextTypes = {
	[MAP]: PropTypes.object,
};

export default MapCustomControl;
