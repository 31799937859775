import { composeWithDevTools } from "redux-devtools-extension";
import { applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";

let exportFunc = null;

if (process.env.NODE_ENV !== "development") {
	exportFunc = composeWithDevTools(applyMiddleware(thunk));
} else {
	exportFunc = composeWithDevTools(applyMiddleware(thunk, logger));
}

export default exportFunc;
