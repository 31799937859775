//! transparent linear-gradient(302deg, #6EC8AF 0%, #6EC8B1 46%, #64C8FF 100%)
const UrbanColorSolid = "#6EC8AF";
export const UrbanColors = {
	type: "linear",
	x: 0,
	y: 0,
	x2: 0,
	y2: 1,
	colorStops: [
		{
			offset: 0,
			color: UrbanColorSolid, // color at 0% position
		},
		{
			offset: 0.46,
			color: "#6EC8B1", // color at 0% position
		},
		{
			offset: 1,
			color: "#64C8FF", // color at 100% position
		},
	],
	global: false,
};

//! transparent linear-gradient(209deg, #FFDA5D 0%, #FF9464 100%)
const RodoviarioColorSolid = "#FFDA5D";
export const RodoviarioColors = {
	type: "linear",
	x: 0,
	y: 0,
	x2: 0,
	y2: 1,
	colorStops: [
		{
			offset: 0,
			color: RodoviarioColorSolid, // color at 0% position
		},
		{
			offset: 1,
			color: "#FF9464", // color at 100% position
		},
	],
	global: false,
};

const formatValue = (value) => (value/1000)

const customChart = ({ data: { Veiculo } }) => {
	const {
		DistanciaRodoviarioMadrugada,
		DistanciaRodoviarioManha,
		DistanciaRodoviarioNoite,
		DistanciaRodoviarioTarde,
		DistanciaUrbanoMadrugada,
		DistanciaUrbanoManha,
		DistanciaUrbanoNoite,
		DistanciaUrbanoTarde,
	} = Veiculo;

	const kmRunPeriod = [
			{ turno: "Madrugada", Rodoviário: formatValue(DistanciaRodoviarioMadrugada), Urbano: formatValue(DistanciaUrbanoMadrugada) },
			{ turno: "Manhã", Rodoviário: formatValue(DistanciaRodoviarioManha), Urbano: formatValue(DistanciaUrbanoManha) },
			{ turno: "Tarde", Rodoviário: formatValue(DistanciaRodoviarioTarde), Urbano: formatValue(DistanciaUrbanoTarde) },
			{ turno: "Noite", Rodoviário: formatValue(DistanciaRodoviarioNoite), Urbano: formatValue(DistanciaUrbanoNoite) },
	];

	const payload = {
		kmRunPeriod,
	};
	return payload;
};

const extractData = ({ data: { Veiculo }, ...rest }) => {
	const {
		DistanciaRodoviarioMadrugada,
		DistanciaRodoviarioManha,
		DistanciaRodoviarioNoite,
		DistanciaRodoviarioTarde,
		DistanciaUrbanoMadrugada,
		DistanciaUrbanoManha,
		DistanciaUrbanoNoite,
		DistanciaUrbanoTarde,
	} = Veiculo;

	const payload = {
		DistanciaRodoviarioMadrugada,
		DistanciaRodoviarioManha,
		DistanciaRodoviarioNoite,
		DistanciaRodoviarioTarde,
		DistanciaUrbanoMadrugada,
		DistanciaUrbanoManha,
		DistanciaUrbanoNoite,
		DistanciaUrbanoTarde,
	};
	return payload;
};

const emptyChart = () => {
	const kmRunPeriod = [
		{ turno: "Madrugada", Rodoviário: 0, Urbano: 0 },
		{ turno: "Manhã", Rodoviário: 0, Urbano: 0 },
		{ turno: "Tarde", Rodoviário: 0, Urbano: 0 },
		{ turno: "Noite", Rodoviário: 0, Urbano: 0 },
	];
	return { kmRunPeriod };
}

export default { extractData, customChart, emptyChart };
