import React, { forwardRef } from "react";

import { useSelector } from "react-redux";
import { makeStyles, Box } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";

import TabMain from "~/components/common/tab/TabMain";
import Travels from "./tabs/Travels";
import LastPositions from "./tabs/LastPositions";
import Informations from "./tabs/Informations";
import Card from "~/components/common/expansionCard/Card";

import "~/helpers/reactToPrint.scss";

const useStyles = makeStyles((theme) => ({
    root: {
        background: fade(theme.palette.primary.main, 0.15),
    },
}));

const DashboardVehicle = forwardRef((props, ref) => {
    const { vehicle } = useSelector((state) => state.dashboardVehicleFilter);

    const TABS = [
        {
            id: 0,
            label: "Informações",
            children: <Informations />,
        },
        {
            id: 1,
            label: "Viagens",
            children: <Travels />,
        },
        {
            id: 2,
            label: "Últimas posições",
            children: <LastPositions />,
        },
    ];

    const classes = useStyles();

    const ComponentTab = (props) => <Card notPadded {...props} />;

    return (
        <Box ref={ref} style={{ width: "100%" }}>
            <TabMain
                id="TopTabDashboardVehicle"
                tabs={TABS}
                component={ComponentTab}
                header={
                    <div
                        className={classes.root}
                        style={{
                            font: "bold 20px Trebuchet MS",
                            color: "#3D5564",
                            width: "100%",
                            padding: "2rem 0px 2rem 2rem",
                            boxSizing: "border-box",
                            marginBottom: "2rem",
                        }}>
                        {vehicle.Placa} - CEABS SERVIÇOS S.A.
                    </div>
                }
                style={{ backgroundColor: "transparent" }}
                className={"classMainTabDash"}
            />
        </Box>
    );
});

export default DashboardVehicle;
