import React from "react";

import _ from "lodash";

import { Link } from "react-router-dom";

import { Zoom, ListItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import "./SubMenu.scss";

const SubMenu = (props) => {
    const { t } = useTranslation();
    const { checked, data, fnClick, permission } = props;

    const verifyIdMenu = (_id) => {
        let p = "";
        if (_id) {
            p = permission.filter((f) => parseInt(f) === _id);
            if (_.isEmpty(p)) {
                return "notPermission";
            }
        }
        return;
    };

    return (
        <Zoom in={checked} style={{ transitionDelay: checked ? "300ms" : "800ms" }}>
            <div className="SubMenu">
                {data.subPaths?.map((item, i) => (
                    <Link
                        id={item.label.replace(/ de /g, "").replace(/\s/g, "")}
                        data-id={item.label.replace(/ de /g, "").replace(/\s/g, "")}
                        key={i}
                        to={item.path}
                        className={`link ${verifyIdMenu(item.id)}`}>
                        <ListItem
                            data-id={`${data.title}${item.label.replace(/ de /g, "").replace(/\s/g, "")}`}
                            id={item.label.replace(/ de /g, "").replace(/\s/g, "")}
                            button
                            className={`menuItem subMenuItem`}
                            onClick={() => {
                                fnClick(false);
                            }}>
                            <span>{t(item.label)}</span>
                        </ListItem>
                    </Link>
                ))}
            </div>
        </Zoom>
    );
};

export default SubMenu;
