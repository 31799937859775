import http from "~/config/httpRequest/http";

const getAlertsViolated = () => {
    return new Promise((resolve, reject) => {
        http.get(`/PainelAlertas/BuscarAlertas`)
            .then((res) => resolve(res.data))
            .catch((error) => reject(error));
    });
};

const getAlertDetails = (req) => {
    return new Promise((resolve, reject) => {
        http.get(`/PainelAlertas/DetalharAlerta`, {
            params: {
                alertaId: req.alertaId,
                placa: req.placa,
                dataHoraViolacao: req.dataHoraViolacao,
                idViolacao: req.idViolacao
            },
        })
            .then((res) => resolve(res.data))
            .catch((error) => reject(error));
    });
};

const getHistory = (req) => {
    return new Promise((resolve, reject) => {
        http.get(`/PainelAlertas/BuscarHistorico`, {
            params: {
                violacaoId: req.violacaoId,
                quantidade: 25,
            },
        })
            .then((res) => resolve(res.data))
            .catch((error) => reject(error));
    });
};

const saveHistory = (req) => {
    return new Promise((resolve, reject) => {
        const body = {
            alertaId: req.alertaId,
            violacaoId: req.violacaoId,
            descricao: req.descricao,
        };
        http.post(`/PainelAlertas/SalvarHistorico`, body)
            .then((res) => resolve(res.data))
            .catch((error) => reject(error));
    });
};

const markAllDoneAlertsViolated = (idAlert) => {
    return new Promise((resolve, reject) => {
        http.post(`/PainelAlertas/MarcarAlertaConcluido`, {
            alertaId: idAlert,
        })
            .then((res) => resolve(res.data))
            .catch((error) => reject(error));
    });
};

const markBoardDoneAlertsViolated = (req) => {
    return new Promise((resolve, reject) => {
        http.post(`/PainelAlertas/MarcarAlertaConcluidoPorPlaca`, {
            alertaId: req.idAlert,
            placa: req.board,
            violacaoId: req.violacaoId
        })
            .then((res) => resolve(res.data))
            .catch((error) => reject(error));
    });
};

export default {
    getAlertDetails,
    getHistory,
    saveHistory,
    getAlertsViolated,
    markAllDoneAlertsViolated,
    markBoardDoneAlertsViolated,
};
