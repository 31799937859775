import Constants from "~/helpers/enums/Constants";

const initialState = {
    alertDetails: null,
    alertIdSelected: null,
    alerts: [],
    alertDetailsHistory: [],
};

function alertsReducer(state = initialState, action = null) {
    const { payload } = action;
    switch (action.type) {
        case Constants.GET_ALERTS_VIOLATED:
            return {
                ...state,
                alerts: payload,
            };
        case Constants.GET_ALERTS_DETAILS:
            return {
                ...state,
                alertDetails: payload,
            };
        case Constants.CLEAN_ALERTS_DETAILS:
            return {
                ...state,
                alertDetails: initialState.alertDetails,
            };

        case Constants.SET_ALERT_ID_SELECTED:
            return {
                ...state,
                alertIdSelected: payload,
            };
        default:
            return state;
    }
}

export default alertsReducer;
