import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import dashboardFiltersAction from "~/actions/dashboard/dashboardFiltersAction";
import { useSelector, useDispatch } from "react-redux";
// import _ from "lodash";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
	},
	paper: {
		//margin: theme.spacing(1),
	},
	svg: {
		width: 100,
		height: 100,
	},
	polygon: {
		fill: theme.palette.common.white,
		stroke: theme.palette.divider,
		strokeWidth: 1,
	},
	root: {
		display: "flex",
	},
	formControl: {
		margin: theme.spacing(3),
	},
}));

const DashboardToolbar = () => {
	const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
	const dashboardToolbar = useSelector(
		(state) => state.dashboardToolbar
	);

	const handleChange = (filter) => {
		dispatch(dashboardFiltersAction.toggleMarkedFilter(filter.name, !filter.checked));
  };

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<FormControl component="fieldset" className={classes.formControl}>
					<FormGroup>
						{Object.values({
							...dashboardToolbar
						}).map((filter) => (
							<FormControlLabel
								key={filter.name}
								control={
									<Checkbox
										color="primary"
										checked={filter.checked}
										onClick={() => {
											handleChange(filter);
										}}
										name={filter.name}
									/>
								}
								label={t(filter.label)}
							/>
              ))}
					</FormGroup>
				</FormControl>
			</div>
		</div>
	);
};

export default DashboardToolbar;
