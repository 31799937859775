import Constants from "~/helpers/enums/Constants";

const formInitialState = {
    document: "",
    user: "",
    pass: "",
    confirmPass: "",
    mail: ""
}

const initialState = {
    tabActive: Constants.LOGIN_TAB,
    form: formInitialState
};

function loginTabReducer(state = initialState, action = null) {
    switch (action.type) {
        case Constants.TAB_LOGIN_FILTERS:
            return {
                ...state,
                tabActive: action.payload.tabActive,
            };
        case Constants.TAB_LOGIN_FORM_UPDATE:
            return {
                ...state,
                form: action.payload,
            };
        case Constants.TAB_LOGIN_RESET:
            return {
                ...state,
                form: formInitialState
            };
        case Constants.TAB_LOGIN_FORM_UPDATE_DOCUMENT:
            return {
                ...state,
                form: {
                    ...state.form,
                    document: action.payload
                }
            };
        default:
            return state;
    }
}

export default loginTabReducer;
