import React from "react"
import { ArrowDownIcon } from "~/components/common/icons/Icons";
import "./ArrowSortOrder.scss";

const ArrowSortOrder = (props) => (
    <>
    {props.show && <span className={`arrow-down-icon ${props.order === "desc" ? "" : "reverse"}`}><ArrowDownIcon /></span>}
    </>
)

export default ArrowSortOrder