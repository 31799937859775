import React from "react";
import { Typography, Box } from "@material-ui/core";
import Labels from "~/helpers/enums/Labels";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Utils from "~/helpers/Utils";
import "../GoogleMaps.scss";
import { useHistory } from "react-router-dom";
import dashboardActionsTopTen from "~/actions/dashboard/dashboarTopTenAction";
import dashboardFiltersAction from "~/actions/dashboardVehicle/dashboardFiltersAction";
import useFuso from "~/components/common/fuso/useFuso";

const GoogleMapInfoView = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const elementr = props.item;

    const { fromUtc } = useFuso();

    return (
        <Box className="info-view">
            <Box
                onClick={() => {
                    history.push("/dashboardVehicle");
                    elementr.Placa = elementr.Identificacao;
                    dispatch(dashboardFiltersAction.resetRangeDate());
                    dispatch(dashboardActionsTopTen.setVehicle(elementr));
                }}
                className={`header ${elementr.Ignicao ? "on" : "off"}`}>
                <Typography variant="h6"> {elementr.Identificacao} </Typography>
                <div className="icon">
                    <img src={Utils.iconResolver(elementr.Tipo, elementr.Ignicao)} alt={`${elementr.Tipo} icon`} />
                </div>
            </Box>
            <Box className="body">
                <div className="line">
                    <div className="label">
                        <Typography variant="body2"> {t(Labels.MAP_INFO_VIEW_DATE)} </Typography>
                    </div>
                    <div className="value">
                        <Typography variant="body2">
                            {" "}
                            {elementr.DataHoraEventoLocal &&
                                fromUtc(elementr.DataHoraEventoLocal, "YYYY-MM-DDTHH:mm:ss").format(
                                    "DD/MM/YYYY | HH:mm:ss"
                                )}{" "}
                        </Typography>
                    </div>
                    <span className="border" />
                </div>
                <div className="line">
                    <div className="label">
                        <Typography variant="body2"> {t(Labels.MAP_INFO_VIEW_EVENT)} </Typography>
                    </div>
                    <div className="value">
                        <Typography variant="body2"> {elementr.TipoEventoTxt} </Typography>
                    </div>
                    <span className="border" />
                </div>

                <div className="line">
                    <div className="label">
                        <Typography variant="body2"> {t(Labels.MAP_INFO_VIEW_IGNITION)} </Typography>
                    </div>
                    <div className="value">
                        <Typography variant="body2">
                            <img
                                src={Utils.iconResolver(elementr.Tipo, elementr.Ignicao)}
                                alt={`${elementr.Tipo} icon`}
                            />
                        </Typography>
                    </div>
                    <span className="border" />
                </div>

                <div className="line">
                    <div className="label">
                        <Typography variant="body2"> {t(Labels.MAP_INFO_VIEW_VELOCITY)} </Typography>
                    </div>
                    <div className="value">
                        <Typography variant="body2"> {elementr.VelocidadeTxt} </Typography>
                    </div>
                    <span className="border" />
                </div>

                <div className="line">
                    <div className="label">
                        <Typography variant="body2"> {t(Labels.MAP_INFO_VIEW_DRIVER)} </Typography>
                    </div>
                    <div className="value">
                        <Typography variant="body2"> {elementr.MotoristaNome} </Typography>
                    </div>
                    <span className="border" />
                </div>

                <div className="line">
                    <div className="label">
                        <Typography variant="body2"> {t(Labels.MAP_INFO_VIEW_LOCALIZATION)} </Typography>
                    </div>
                    <div className="value">
                        <Typography variant="body2"> {elementr.Localizacao} </Typography>
                    </div>
                </div>
            </Box>
        </Box>
    );
};

export default GoogleMapInfoView;
