import React from "react";

import { Container, Grid } from "@material-ui/core";

import Page from "~/components/common/page/Page";
import AlertPanelMain from "~/components/alerts/AlertPanelMain";

const AlertView = () => {
    return (
        <>
            <Page>
                <main>
                    <Container maxWidth="lg" className="container-map-view">
                        <Grid container>
                            <Grid item xs={12}>
                                <AlertPanelMain />
                            </Grid>
                        </Grid>
                    </Container>
                </main>
            </Page>
        </>
    );
};

export default AlertView;
