import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import alertService from "~/services/alertService";

const saveAlert = (alert, callback = () => {}, LOADING_IDENTIFICATOR = "alert") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return alertService
		.saveAlert(alert)
		.then(() => {
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getAlertsConditons = (callback = () => {}, LOADING_IDENTIFICATOR = "alert") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return alertService
		.getAlertsConditons()
		.then(({ data }) => {
			dispatch({ type: Constants.LIST_ALERTS_CONDITIONS, payload: data });
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getAlertsActions = (callback = () => {}, LOADING_IDENTIFICATOR = "alert") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return alertService
		.getAlertsActions()
		.then(({ data }) => {
			dispatch({ type: Constants.LIST_ALERTS_ACTIONS, payload: data });
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getAlertById = (id, callback = () => {}, LOADING_IDENTIFICATOR = "alert") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	return alertService
		.getAlertById(id)
		.then((response) => {
			const alert = response.data;
			dispatch({ type: Constants.GET_ALERT_BY_ID, payload: alert });
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const searchAlertList = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "searchAlertList") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return alertService
		.searchAlertList(filter)
		.then((response) => {
			let { index, recordsTotal, recordsFiltered, data } = response.data;
			const page = {
				index,
				recordsTotal,
				recordsFiltered,
			};
			dispatch({ type: Constants.LIST_ALERTS, payload: { data: data, page } });
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const deactivateAlert = (_id, callback = () => {}, LOADING_IDENTIFICATOR = "deactivateAlert") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return alertService
		.deactivateAlert(_id)
		.then(() => {
			callback();
			dispatch(updateAlertFilter({
				limit: 10,
				status: "Ativo",
				search: "",
			}));
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const exportAlert = (filter, LOADING_IDENTIFICATOR = "exportAlert") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return alertService
		.exportAlert(filter)
		.then(({ data }) => {
			if (data.Success) {
				Utils.downloadToLink(data.Message);
			} else {
				Utils.showError(data.Message);
			}
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const updateInserForm = (context) => (dispatch) => {
	dispatch({ type: Constants.UPDATE_INSERT_FORM, payload: context });
};

const updateEditForm = (context) => (dispatch) => {
	dispatch({ type: Constants.UPDATE_EDIT_FORM, payload: context });
};

const setOpenInsertForm = (open) => (dispatch) => {
	dispatch({ type: Constants.SET_OPEN_INSERT_FORM, payload: open });
};

const setOpenEditForm = (open) => (dispatch) => {
    dispatch({ type: Constants.SET_OPEN_EDIT_FORM, payload: open });
};

const updateAlertFilter = (filter) => (dispatch) => {
	dispatch({ type: Constants.UPDATE_ALERT_FILTER, payload: filter });
};

const cleanAlert = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_ALERT });
};

export default {
	saveAlert,
	getAlertsConditons,
	getAlertsActions,
	getAlertById,
	searchAlertList,
	cleanAlert,
	updateInserForm,
	updateEditForm,
	setOpenInsertForm,
	setOpenEditForm,
	deactivateAlert,
	updateAlertFilter,
	exportAlert,
};
