import {
	addMonths,
	addYears,
	isAfter,
	isBefore,
	isSameDay,
	isSameMonth,
	isWithinRange,
	getHours,
	max,
	min,
	setHours,
	setMinutes,
	setSeconds,
	getMinutes,
	endOfDay
} from "date-fns";
import React, { useState, useCallback } from "react";
import Menu from "./components/Menu";
import { defaultRanges } from "./defaults";
import { DateRange, DefinedRange, NavigationAction } from "./types";
import { parseOptionalDate } from "./utils";

type Marker = symbol;

export const MARKERS: { [key: string]: Marker } = {
	FIRST_MONTH: Symbol("firstMonth"),
	SECOND_MONTH: Symbol("secondMonth"),
};

const getValidatedMonths = (range: DateRange, minDate: Date, maxDate: Date) => {
	let { startDate, endDate } = range;
	if (startDate && endDate) {
		const newStart = max(startDate, minDate);
		const newEnd = min(endDate, maxDate);

		return [newStart, isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd];
	} else {
		return [startDate, endDate];
	}
};

interface DateRangePickerProps {
	open: boolean;
	closeButton: Node;
	hours: boolean;
	value: DateRange;
	initialDateRange?: DateRange;
	definedRanges?: DefinedRange[];
	minDate?: Date | string;
	maxDate?: Date | string;
	dontShowMonthRanges?: boolean
	onChange: (dateRange: DateRange) => void;
}

const RangeDatePicker: React.FunctionComponent<DateRangePickerProps> = (props) => {

	const startRangeDate = new Date();
	const endRangeDate = new Date();

	startRangeDate.setHours(0, 0, 0);
	endRangeDate.setHours(23, 59, 59);

	const {
		closeButton,
		open,
		hours,
		onChange,
		initialDateRange,
		minDate,
		maxDate,
		dontShowMonthRanges,
	} = props;

	const definedRanges = dontShowMonthRanges ? defaultRanges.filter(item => !item.label.includes("mês")) : defaultRanges;

	const minDateValid = parseOptionalDate(minDate, addYears(startRangeDate, -10));
	const maxDateValid = endOfDay(parseOptionalDate(maxDate, addYears(endRangeDate, 10)));
	const [intialFirstMonth, initialSecondMonth] = getValidatedMonths(initialDateRange || {}, minDateValid, maxDateValid);

	const [dateRange, setDateRange] = useState<DateRange>({ ...initialDateRange });
	const [hoverDay, setHoverDay] = useState<Date>();
	const [firstMonth, setFirstMonth] = useState<Date>(intialFirstMonth || startRangeDate);
	const [secondMonth, setSecondMonth] = useState<Date>(initialSecondMonth || addMonths(endRangeDate, 1));

	const { startDate, endDate } = dateRange;


	// handlers
	const setFirstMonthValidated = (date: Date) => {
		if (isBefore(date, secondMonth)) {
			setFirstMonth(date);
		}
	};

	const setSecondMonthValidated = (date: Date) => {
		if (isAfter(date, firstMonth)) {
			setSecondMonth(date);
		}
	};

	const setDateRangeValidated = useCallback((range: DateRange) => {
		let { startDate: newStart, endDate: newEnd } = range;
		if (newStart && newEnd) {
			range.startDate = newStart = max(newStart, minDateValid);
			range.endDate = newEnd = min(newEnd, maxDateValid);
			setDateRange(range);
			onChange(range);
			setFirstMonth(newStart);
			setSecondMonth(isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd);
		}
	}, [minDateValid, maxDateValid, onChange]);

	const onDayClick = (day: Date) => {
		if (startDate && !endDate && !isBefore(day, startDate)) {
			const newRange = { startDate, endDate: day };

			const rangeNewDate = {
				startDate: setSeconds(
					setMinutes(setHours(newRange.startDate, getHours(firstMonth)), getMinutes(firstMonth)), 0),
				endDate: setSeconds(
					setMinutes(setHours(newRange.endDate, getHours(secondMonth)), getMinutes(secondMonth)), 59),
			};

			onChange(rangeNewDate);
			setDateRange(rangeNewDate);
		} else {
			setDateRange({ startDate: setSeconds(day, 0), endDate: undefined });
		}
		setHoverDay(day);
	};

	const onMonthNavigate = (marker: Marker, action: NavigationAction) => {
		if (marker === MARKERS.FIRST_MONTH) {
			const firstNew = addMonths(firstMonth, action);
			if (isBefore(firstNew, secondMonth)) setFirstMonth(firstNew);
		} else {
			const secondNew = addMonths(secondMonth, action);
			if (isBefore(firstMonth, secondNew)) setSecondMonth(secondNew);
		}
	};

	const onDayHover = (date: Date) => {
		if (startDate && !endDate) {
			if (!hoverDay || !isSameDay(date, hoverDay)) {
				setHoverDay(date);
			}
		}
	};

	// helpers
	const inHoverRange = (day: Date) => {
		return (startDate &&
			!endDate &&
			hoverDay &&
			isAfter(hoverDay, startDate) &&
			isWithinRange(day, startDate, hoverDay)) as boolean;
	};

	const helpers = {
		inHoverRange,
	};

	const handlers = {
		onDayClick,
		onDayHover,
		onMonthNavigate,
	};

	return open ? (
		<Menu
			id="rangeDatePickerMenu"
			closeButton={closeButton}
			dateRange={dateRange}
			minDate={minDateValid}
			maxDate={maxDateValid}
			ranges={definedRanges}
			firstMonth={firstMonth}
			secondMonth={secondMonth}
			setFirstMonth={setFirstMonthValidated}
			setSecondMonth={setSecondMonthValidated}
			setDateRange={setDateRangeValidated}
			helpers={helpers}
			handlers={handlers}
			hours={hours}
		/>
	) : null;
};

export default RangeDatePicker;
