import React, { Fragment } from "react";
import { Grid, Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
    ChooseImageIcon,
    CancelIcon,
    ConfirmIcon,
    WhiteTrashIcon,
    ArrowDownIcon,
} from "~/components/common/icons/Icons";
import Labels from "~/helpers/enums/Labels";
import RegisterImageInput from "~/components/common/registerInputs/RegisterImageInput";
import RegisterSwitch from "~/components/common/registerInputs/RegisterSwitch";
import RegisterColorPicker from "~/components/common/registerInputs/RegisterColorPicker";
import "./Profile.scss";

const CustomizationForm = (props) => {
    const { t } = useTranslation();
    const { submitForm, setFieldValue } = props.form;

    const logoRules = t(Labels.PROFILE_CUSTOMIZATION_LOGO_RULES, { returnObjects: true }) || {};

    return (
        <Box className="profile-panel">
            <Grid container direction="row" alignItems="stretch" spacing={0}>
                <Grid item xs={12}>
                    <Grid container direction="row" alignItems="stretch" spacing={0}>
                        <Grid className="profile-no-padding-left" item xs={12} md={9}>
                            <RegisterImageInput
                                id="profile-logo"
                                name="LogotipoFile"
                                objectFit="contain"
                                placholderIcon={<ChooseImageIcon viewBox={null} />}
                                placholderText={t(Labels.PROFILE_CUSTOMIZATION_LOGO)}
                                accept=".jpg, .png, .jpeg"
                                height={"150px"}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box className="rules-box rules-logo">
                                <span className="rules-title">{logoRules["title"]}</span>
                                {logoRules["hints"].map((hint, i) => (
                                    <Fragment key={i}>
                                        <br />
                                        <span className="hint"> - {hint}</span>
                                    </Fragment>
                                ))}
                            </Box>
                        </Grid>
                        <Grid className="profile-no-padding-left profile-report-switch" item xs={12} md={6}>
                            <RegisterSwitch
                                name="MostrarLogoRelatorio"
                                // color="#71C154"
								disabled // Foi solicitado a desabilitação do campo
                                label={t(Labels.PROFILE_CUSTOMIZATION_LOGO_SHOW_ON_REPORT)}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box className="button-form">
                                <Button
                                    className="remove-logo"
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    disableElevation
                                    startIcon={<WhiteTrashIcon />}
                                    onClick={() => {
                                        setFieldValue("LogotipoFile", {});
                                    }}>
                                    {t(Labels.PROFILE_CUSTOMIZATION_LOGO_REMOVE)}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Typography variant="h6" className="configuration-title">
                        {t(Labels.PROFILE_CUSTOMIZATION_COLOR_CONFIGURATION)}
                    </Typography>
                </Grid>

                <Grid className="profile-no-padding-left" item xs={12} md={6}>
                    <RegisterColorPicker
                        label={t(Labels.PROFILE_CUSTOMIZATION_COLOR_CONFIGURATION_PRIMARY)}
                        name="CorBarraTopo"
                        InputProps={{
                            endAdornment: <ArrowDownIcon />,
                        }}
                    />
                </Grid>

                <Grid className="profile-no-padding-right" item xs={12} md={6}>
                    <RegisterColorPicker
                        label={t(Labels.PROFILE_CUSTOMIZATION_COLOR_CONFIGURATION_SECONDARY)}
                        name="CorPaineis"
                        InputProps={{
                            endAdornment: <ArrowDownIcon />,
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={12} style={{ display: "flex" }}>
                    <Grid container direction="row" justify="flex-end" spacing={0}>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={4}>
                            <Box className="button-form button-form-refactor">
                                <Button
                                    id="profile-cancel-button"
                                    className="cancel-button cancel-button-refactor"
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    fullWidth
                                    onClick={() => {
                                        props.comeBack();
                                    }}
                                    disabled={props.loading}>
                                    <CancelIcon />

                                    {t(Labels.PROFILE_BUTTON_CANCEL)}
                                </Button>
                            </Box>
                        </Grid>
                        <Grid className="profile-no-padding-right" item xs={12} md={5}>
                            <Box className="button-form button-form-refactor">
                                <Button
                                    id="profile-submit-button"
                                    variant="contained"
                                    className="save-button save-button-refactor"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    disableElevation
                                    onClick={submitForm}
                                    disabled={props.loading}
                                    startIcon={props.loading ? <CircularProgress size={18} /> : <ConfirmIcon />}>
                                    {t(Labels.PROFILE_BUTTON_CONFIRM)}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CustomizationForm;
