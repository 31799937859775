import http from "~/config/httpRequest/http";

export const listRoutes = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/RotaApi/Buscar`, {
				...filter,
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

export const getObjectsPerRoutes = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/RotaApi/BuscarVeiculosProximos`, {
				params: {
					...filter,
				},
			})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const deleteRoute = (id) => {
	return new Promise((resolve, reject) => {
		http
			.delete(`/RotaApi/DeletaRota`, {
				params: {
					rotaId: id,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => {
				reject(error);
			});
	});
};

export const saveRoute = (Routes) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/RotaApi/Salvar`, {
				...Routes,
			})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const getRoutesById = (id) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/RotaApi/BuscarPorId`, {
				params: {
					rotaId: id,
				},
			})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const exportRoutes = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/RotaApi/ExportarRotas`, {
				params: {
					...filter,
				},
			})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export default {
	listRoutes,
	getObjectsPerRoutes,
	deleteRoute,
	saveRoute,
	getRoutesById,
	exportRoutes,
};
