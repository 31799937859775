import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Utils from "~/helpers/Utils";
import speedingReportAction from "~/actions/reporting/speedingReportAction";
import { Button, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import { Field } from "formik";
import { SaveReportIcon } from "~/components/common/icons/Icons";
import DriverAutoComplete from "~/components/reporting/common/driver/AutoCompleteDriver";
import ReportPeriodField from "~/components/reporting/common/ReportPeriodField";
import VehicleAutocomplete from "~/components/reporting/common/vehicle/VehicleAutocomplete";
import Toogle from "~/components/common/toogle/Toogle";
import Labels from "~/helpers/enums/Labels";
import "~/components/reporting/common/ReportingCommon.scss";
import "./Object.scss";

const FiltersObject = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { requestObjectSpeeding } = useSelector((state) => state.app.loading);
    const {
        perObject: { filters },
    } = useSelector((state) => state.speedingReport);

    const objectForm = useRef();

    useEffect(() => {
        dispatch(speedingReportAction.updateObjectForm(objectForm.current));
    }, [dispatch, objectForm]);

    useEffect(() => {
        if (filters.period === "" || filters.vehicle === null) {
            return;
        }
        dispatch(speedingReportAction.getRequestPerObject(filters));
    }, [dispatch, filters]);

    return (
        <Formik
            innerRef={objectForm}
            initialValues={{
                period: Utils.getTodayPeriod(),
                vehicle: null,
                driver: "",
                details: false,
                limit: 25,
                index: 0,
                typeReport: 76,
            }}
            validate={(values) => {
                const errors = {};

                if (!values.vehicle && !values.fleet) {
                    errors.vehicle = t(Labels.REPORT_EVENT_VEHICLE_MSG_OBLIGATED_WITHOUT_OR);
                    errors.fleet = t(Labels.REPORT_EVENT_FLEET_MSG_OBLIGATED);
                }

                if (!values.period) {
                    errors.period = t(Labels.REPORT_EVENT_PERIOD_MSG_OBLIGATED);
                }

                if (values.period && Utils.diffInDays(values.period.endDate, values.period.startDate) > 7) {
                    errors.period = t(Labels.REPORT_EVENT_PERIOD_MSG_PERIOD_WEEK);
                }

                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                dispatch(
                    speedingReportAction.updateFilterSpeedingObject({
                        ...values,
                        period: {
                            startDate: Utils.utcToLocal(values.period.startDate),
                            endDate: Utils.utcToLocal(values.period.endDate),
                        },
                    })
                );

                setSubmitting(false);
            }}>
            {({ submitForm, setFieldValue, handleChange }) => (
                <Form>
                    <Grid container justify="right" alignItems="flex-end" spacing={1}>
                        <Grid item xs={3}>
                            <VehicleAutocomplete
                                id="vehicle-Combo"
                                name="vehicle"
                                label={t(Labels.REPORT_EVENT_VEHICLE_LABEL)}
                                placeholder={t(Labels.REPORT_EVENT_VEHICLE_PLACEHOLDER)}
                                onChange={() => {
                                    setFieldValue("driver", "");
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ReportPeriodField
                                dontShowMonthRanges={true}
                                id="id-period-date"
                                label={t(Labels.REPORT_EVENT_PERIOD_LABEL)}
                                name="period"
                                hours
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DriverAutoComplete
                                id="driver-combo"
                                name="driver"
                                label={t(Labels.COLUMN_DRIVER)}
                                placeholder={t(Labels.REPORT_LABEL_PLACEHOLDER)}
                                onChange={() => {
                                    setFieldValue("vehicle", "");
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Field
                                id="details"
                                name="details"
                                label={t(Labels.REPORT_SPEEDING_DETAILED)}
                                component={Toogle}
                                onChanged={(event) => {
                                    setFieldValue("details", event);
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                id="travel-filter-object-save-button"
                                className="report-save-button report-save-button-refactor"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disableElevation
                                disabled={requestObjectSpeeding}
                                startIcon={<SaveReportIcon />}
                                onClick={submitForm}>
                                {t(Labels.REPORT_EVENT_SAVE_BUTTON)}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default FiltersObject;
