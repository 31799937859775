import _ from "lodash";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import Constants from "~/helpers/enums/Constants";
import i18next from "i18next";
import Profiles from "./enums/Profiles";
import Cookies from "js-cookie";
import moment from "moment";

import CarOff from "~/assets/icons/card/car-off.png";
import CarOn from "~/assets/icons/card/car-on.png";
import BikeOff from "~/assets/icons/card/bike-off.png";
import BikeOn from "~/assets/icons/card/bike-on.png";
import TruckOff from "~/assets/icons/card/truck-off.png";
import TruckOn from "~/assets/icons/card/truck-on.png";

export const not = (obj) => !obj;

export const isEmpty = (obj) => _.isEmpty(obj);

export const isNotEmpty = (obj) => !isEmpty(obj);

export const isNumber = (v) => typeof v === "number" && Number.isFinite(v);

export const isObject = (v) => typeof v === "object" && v !== null;

export const isEmptyOrNullOrZero = (obj) => {
    return isEmpty(obj) || obj === 0;
};

export const isNullOrBlank = (str) => {
    return !str || str.match(/^\s*$/) !== null;
};

export const getAllCookies = () =>
    document.cookie.split(";").reduce((a, b) => {
        const cookie = b.trim();
        const sepIndex = cookie.indexOf("=");
        const key = cookie.substr(0, sepIndex);
        const value = cookie.substr(sepIndex + 1);
        a[key] = value;
        return a;
    }, {});

export const getAppInfo = () => {
    const env = process.env;
    return {
        version: env.REACT_APP_VERSION,
        name: env.REACT_APP_NAME,
        environment: env.NODE_ENV,
        branch: env.REACT_APP_BRANCH_NAME,
        build: env.REACT_APP_BUILD_NUMBER,
        date: env.REACT_APP_BUILD_DATE,
    };
};

export const setToken = (token) => {
    localStorage.setItem(Constants.TOKEN, token);
};

export const withoutSpace = (getString) => {
    const value = String(getString).replace(/ de /g, "").replace(/\s/g, "");

    return value;
};

export const getToken = () => {
    return Cookies.get("token");
};

export const getUserInfo = () => {
    const userInfo = Cookies.get("UserInfo");

    if (userInfo) {
        return JSON.parse(userInfo);
    }

    return null;
};

export const getPermissions = () => {
    let userInfo = getUserInfo();

    if (userInfo) {
        return userInfo.Funcionalidades?.split("|");
    }
    return [];
};

export const convertMToKm = (value) => {
    //1000 = value to convert m for km
    return Math.ceil(value / 1000).toLocaleString("pt-br");
};

export const convertMToKmPrecision = (value) => {
    return (value / 1000).toFixed(0).toString().replace(".", ",");
};

export const hasTokenValid = () => {
    // TODO Validate Token
    return isNotEmpty(getToken());
};

export const removeToken = () => {
    Cookies.remove("token");
    return 1;
    // return localStorage.removeItem(Constants.TOKEN);
};

export const startLoading = (identifier) => ({
    type: Constants.UPDATE_LOADING,
    loading: { [identifier]: true },
});

export const endLoading = (identifier) => ({
    type: Constants.UPDATE_LOADING,
    loading: { [identifier]: false },
});

export const showTranslatedToast = ({ description, type }, props = () => {}) => {
    if (!description) return;
    try {
        const Translated = i18next.t(description);
        description = Translated;
    } catch (error) {
    } finally {
        toast(description, {
            type,
            position: toast.POSITION.TOP_CENTER,
            ...props,
        });
    }
};

export const showComponentToast = ({ content, type }, props = () => {}) => {
    toast(content, {
        type: type,
        position: toast.POSITION.TOP_CENTER,
        ...props,
    });
};

export const showError = (error) => {
    showTranslatedToast({
        type: Constants.ERROR,
        description: error,
    });
};

export const showAllErrors = (error) => {
    const show = (_data) => {
        if (!_data) return;

        if (typeof _data === "string") {
            showError(_data);
        } else if (typeof _data.Message === "string") {
            showError(_data.Message);
        }
    };

    const data = error?.response?.data;

    if (Array.isArray(data)) {
        data.forEach((e) => show(e));
    } else {
        show(data);
    }
};

export const downloadToLink = (href) => {
    const element = document.createElement("a");

    element.setAttribute("href", href);
    element.setAttribute("target", "_blank");
    element.setAttribute("download", true);
    element.style.display = "none";

    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
};

export const getDataToken = () => {
    const token = getToken();
    if (token) {
        return jwtDecode(token);
    }
    return null;
};

export const userHasAdmin = () => {
    const { profile } = getDataToken() || {};
    return profile === Profiles.ADMINISTRATOR;
};

const iconResolver = (type, ignition) => {
    ignition = ignition === "OFF" ? false : ignition === "ON" ? true : ignition;
    switch (type) {
        case "Carreta":
            return ignition ? TruckOn : TruckOff;
        case "Moto":
            return ignition ? BikeOn : BikeOff;
        case "Veículo":
        default:
            return ignition ? CarOn : CarOff;
    }
};

const filterData = (items, val) => {
    let searchedItems = items.filter((item) => {
        return Object.keys(item).some((key) => {
            return String(item[key]).toLowerCase().indexOf(val.toLowerCase()) > -1;
        });
    });

    return searchedItems;
};

const TextView = (data, maximoChar) => {
    const limite = maximoChar;
    const dados = data;

    if (data === null || data === undefined) return "";

    return dados.toString().substring(0, limite) + (dados.toString().length > limite ? "..." : "");
};

export const getLatLngPathsFromString = (latlngsString) => {
    return latlngsString
        .split(";")
        .filter((x) => x)
        .map((x) => {
            const latlng = x.split(",");

            return {
                lat: parseFloat(latlng[0]),
                lng: parseFloat(latlng[1]),
            };
        });
};

export const isValidLogin = (login) => {
    const loginRegex = RegExp(/^([a-z]+([._]{1}[a-z]+|[a-z]*)*)+$/);
    return loginRegex.test(login);
};

export const isValidEmail = (email) => {
    const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    return emailRegex.test(email);
};

export const isValidPassword = (password) => {
    const passwordRegex = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
    return passwordRegex.test(password);
};

export const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const uniqueBy = (a, key) => {
    var seen = {};
    return a.filter(function (item) {
        var k = key(item);
        return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
};

export const fileToBase64 = (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result);
        reader.readAsDataURL(file);
    });
};

export const stringBase64ToObjectBase64 = (base64String) => {
    return {
        data: base64String.split(",")[1],
        contentType: base64String.split(",")[0].split(";")[0].split(":")[1],
    };
};

const getDaysArrayFromNumber = (number) => {
    const diasDaSemana = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
    if (number) {
        const stringNumberArray = [...number.toString()].map((item) => Number.parseInt(item) - 1);
        const diasPresentes = diasDaSemana.filter((item, index) => stringNumberArray.includes(index));
        return diasPresentes;
    } else {
        return number;
    }
};

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        byteArrays.push(new Uint8Array(byteNumbers));
    }
    return new Blob(byteArrays, { type: contentType });
};

function isFile(file, types) {
    return types.some((el) => file.endsWith(el));
}

export const downloadBase64 = (data, type, filename) => {
    const element = document.createElement("a");
    const href = URL.createObjectURL(b64toBlob(data, type));
    element.setAttribute("href", href);
    element.setAttribute("target", "_blank");
    element.setAttribute("download", true);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

const getTodayPeriod = () => {
    const startDate = new Date();
    const endDate = new Date();

    startDate.setHours(0, 0, 0);
    endDate.setHours(23, 59, 59);

    return {
        startDate,
        endDate,
    };
};

const diffInDays = (firstDate, secondDate) => {
    const endDate = moment(firstDate);
    const startDate = moment(secondDate);
    return endDate.diff(startDate, "days");
};

export const nFormatter = (num, digits) => {
    var si = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const prefixWithZeros = (number, length) => String(number).padStart(length, "0");

export const updateFormikValues = (form, values) => {
    if (!form) return;
    form.resetForm();

    Object.entries(values).forEach(([key, value]) => {
        form.setFieldValue(key, value);
    });
};

export const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const translateDayOfWeek = (nums) => {
    const strNums = String(nums);
    const days = moment.weekdaysShort().map(capitalize);

    return strNums
        .split("")
        .map((n) => days[parseInt(n) - 1])
        .join(", ");
};

export const formatSpeed = (speed) => {
    return !isNaN(speed) ? `${Math.trunc(speed)} km/h` : speed;
};

export const formatKilometers = (km, digits) => {
    return !isNaN(km) ? km.toFixed(digits) : km;
};

export const formatCoordinates = (location) => {
    return !isNaN(location) ? location.toFixed(3) : location;
};

export const formatVoltage = (voltage) => {
    return !isNaN(voltage) ? Math.trunc(voltage) : voltage;
};

export const getCenterPolygon = (points) => {
    const centroid = points.reduce(
        (a, b) => {
            return { lat: a.lat + b.lat, lng: a.lng + b.lng };
        },
        { lat: 0, lng: 0 }
    );
    return { lat: centroid.lat / points.length, lng: centroid.lng / points.length };
};

export const utcToLocal = (date) => {
    return moment(date).local();
};

export const brazilianStringNumberToFloat = (numStr) => {
    if (!numStr) return;
    return parseFloat(numStr.replace(",", "."));
};

export default {
    not,
    isEmpty,
    isNotEmpty,
    isNumber,
    isObject,
    endLoading,
    startLoading,
    hasTokenValid,
    setToken,
    removeToken,
    getPermissions,
    getToken,
    showTranslatedToast,
    isEmptyOrNullOrZero,
    showError,
    showAllErrors,
    iconResolver,
    filterData,
    TextView,
    convertMToKm,
    downloadToLink,
    getLatLngPathsFromString,
    getAppInfo,
    getAllCookies,
    isValidEmail,
    groupBy,
    getTodayPeriod,
    fileToBase64,
    downloadBase64,
    stringBase64ToObjectBase64,
    isFile,
    isNullOrBlank,
    nFormatter,
    prefixWithZeros,
    isValidPassword,
    showComponentToast,
    uniqueBy,
    isValidLogin,
    diffInDays,
    updateFormikValues,
    getDaysArrayFromNumber,
    translateDayOfWeek,
    convertMToKmPrecision,
    formatSpeed,
    formatKilometers,
    formatCoordinates,
    formatVoltage,
    getCenterPolygon,
    utcToLocal,
    brazilianStringNumberToFloat,
};
