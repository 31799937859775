import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import Utils from "~/helpers/Utils";

import alertsActions from "~/actions/alertActions";
import alertService from "~/services/alertService";

import { Form, Formik } from "formik";
import { Button, Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import FileInput from "~/components/common/inputFile/FileInput";

import { ImportGreenIcon, CloseLightGrayIcon, IconDwGreenIcon } from "~/components/common/icons/Icons";

import "./ImportCarAlert.scss";

const ImportCars = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.app);
    const { alert } = useSelector((state) => state.alert);

    const dwFile = async () => {
        const link = await alertService.downloadFile();
        window.open(link.data);
    };

    const fnCallback = (msg, type) => {
        if (typeof msg === "object") {
            msg = t(Labels.ERROR_IMPORT_FILE);
            type = "error";
        } else if (type === "info") {
            type = "success";
        }
        Utils.showTranslatedToast({
            type: type,
            description: msg,
        });
    };

    const convertFile = async (file) => {
        if (file === undefined) {
            return;
        }

        const newFile64 = await Utils.fileToBase64(file);

        let fileBase64 = Utils.stringBase64ToObjectBase64(newFile64);

        const file64 = {
            fileName: file.name,
            fileData: fileBase64.data,
            size: file.size,
        };
        return file64;
    };

    return (
        <Formik
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={{
                file: null,
                alert: null,
                removeObj: false,
            }}
            validate={(values) => {}}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                if (!values.file) {
                    fnCallback(t(Labels.ERROR_IMPORT_CHOICE), "error");
                    return;
                }

                if (Utils.isFile(values.file.fileName, [".xls", ".xlsx"])) {
                    dispatch(alertsActions.importFileAlert(values, fnCallback));
                } else {
                    fnCallback(t(Labels.REGISTER_ALERT_ERROR_TYPE_XLS), "error");
                    return;
                }
            }}
            render={({ submitForm, setFieldValue, values, resetForm }) => {
                return (
                    <Box className="importCarAlert">
                        <Form>
                            <Grid container justify="flex-start">
                                <Grid item xs={9}>
                                    <FileInput
                                        forID="file-input"
                                        placeHolderInput={t(Labels.PLACEHOLDER_IMPORT_DRIVER)}
                                        labelButton={t(Labels.CHOICE_LABEL)}
                                        value={values.file?.fileName}
                                        backIconStyle={{ border: "2px solid", borderRadius: "20px" }}>
                                        <input
                                            id="file-input"
                                            data-id={t(Labels.FILE_LABEL_IMPORT_DRIVER)
                                                .replace(/ de /g, "")
                                                .replace(/\s/g, "")}
                                            name="file"
                                            type="file"
                                            accept=".xlsx, .xls"
                                            onChange={async (event) => {
                                                const file64 = await convertFile(event.currentTarget.files[0]);
                                                setFieldValue("file", file64);
                                            }}
                                            className="form-control"
                                        />
                                    </FileInput>
                                    <span
                                        className="link-import"
                                        data-id={t(Labels.LINK_LABEL_IMPORT_DRIVER)
                                            .replace(/ de /g, "")
                                            .replace(/\s/g, "")}
                                        onClick={dwFile}>
                                        <IconDwGreenIcon color="primary" className="iconImportDw" />
                                        <Typography color="primary" className="import-font">
                                            {t(Labels.LINK_LABEL_IMPORT_DRIVER)}
                                        </Typography>
                                    </span>
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <FormControlLabel
                                        key={"form"}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                className="import-checkbox"
                                                checked={values.removeObj}
                                                onClick={() => {
                                                    setFieldValue("removeObj", !values.removeObj);
                                                }}
                                                name={"Checkbox"}
                                            />
                                        }
                                        label={
                                            <Typography className="fontCheckBox">
                                                {t(Labels.ALERT_LABEL_CHECKBOX)}
                                            </Typography>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container direction="row" className="grid-item grid-buttons">
                                        <Grid item xs={3}>
                                            <Button
                                                data-id="import-cancel"
                                                className="import-cancel-button"
                                                variant="outlined"
                                                disableElevation
                                                onClick={() => {
                                                    resetForm();
                                                }}>
                                                <CloseLightGrayIcon className="close-light-gray-icon" />
                                                <Typography className="import-font">
                                                    {t(Labels.BTN_CANCEL_IMPORT_DRIVER)}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button
                                                data-id="import-button"
                                                className="import-confirm-button"
                                                variant="outlined"
                                                color="primary"
                                                disableElevation
                                                disabled={loading.importFileAlert}
                                                fullWidth
                                                onClick={() => {
                                                    submitForm();
                                                    setFieldValue("alert", { id: alert.Alerta && alert.Alerta.Id });
                                                }}>
                                                {loading.importFileAlert ? (
                                                    <CircularProgress
                                                        style={{ height: 14, width: 14, marginRight: 8 }}
                                                        color="secondary"
                                                    />
                                                ) : (
                                                    <span className="btnConfirmAlert">
                                                        <ImportGreenIcon
                                                            className="import-green-icon"
                                                            color="primary"
                                                        />
                                                        <Typography>{t(Labels.ALERT_LABEL_BTN_IMPORT)}</Typography>
                                                    </span>
                                                )}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    </Box>
                );
            }}
        />
    );
};

export default ImportCars;
