import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import MapCard from "~/components/map/MapCard";
import ExpansionCard from "~/components/common/expansionCard/ExpansionCard";
import ExpansionGrid from "~/components/common/expansionCard/ExpansionGrid";
import ExpansionCardEnum from "~/components/common/expansionCard/ExpansionCardEnum";
import DatePickerFilter from "~/components/common/datepicker/DatePickerFilter";
import MenuRange from "~/components/common/menuRange/MenuRange";
import useFuso from "~/components/common/fuso/useFuso";

import TableCardReUse from "~/components/tableCard/TableCardReUse";
import Utils from "~/helpers/Utils";
import Loading from "~/components/common/loading/Loading";

import googleMapsDataAction from "~/actions/googleMapsDataAction";
import dashboardTabTravelsActions from "~/actions/dashboardVehicle/dashboardTabTravelsActions";
import moment from "moment";
import "./Travels.scss";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

const ReportResult = ({ mode }) => {
    const dispatch = useDispatch();
    const { findLastPositions } = useSelector((state) => state.app.loading);
    const { lastPositions } = useSelector((state) => state.dashboardVehicleTabTravels);
    const { vehicle } = useSelector((state) => state.dashboardVehicleFilter);
    const { t } = useTranslation();
    const { fromUtc } = useFuso();

    const COLUMNS = [
        {
            name: "DataHoraEvento",
            label: "Data/Hora",
            render: (date) => (
                <span>
                    <div> {fromUtc(date, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY | HH:mm:ss")} </div>
                </span>
            ),
        },
        {
            name: "TipoEvento",
            label: "Evento",
        },
        {
            name: "Ignicao",
            label: "Ignição",
            render: (ignicao) => (
                <img src={Utils.iconResolver("Veículo", ignicao)} style={{ width: 25 }} alt={`${ignicao} icon`} />
            ),
        },
        {
            name: "Velocidade",
            label: "Velocidade",
            render: (velocidade) => <span>{velocidade} km/h</span>,
        },
        {
            name: "TensaoBateria",
            label: "Bateria",
        },
        {
            name: "Localizacao",
            label: "Localização",
        },
        {
            name: "PontoMaisProximo",
            label: "Ponto mais Próximo",
        },
        {
            name: "PavimentoTipo",
            label: "Pavimento",
        },
    ];

    const [filter, setFilter] = useState({
        DataInicio: moment(new Date().setHours(0, 0, 0)).format("DD-MM-YYYY HH:mm:ss"),
        DataFim: moment(new Date().setHours(23, 59, 59)).format("DD-MM-YYYY HH:mm:ss"),
        GridRequest: {
            Index: 0,
            Length: 25,
        },
    });

    useEffect(() => {
        dispatch(
            dashboardTabTravelsActions.findLastPositions({
                ObjetoRastreavelId: vehicle.ObjetoRastreavelId,
                ...filter,
            })
        );
    }, [dispatch, filter, vehicle]);

    useEffect(() => {
        if (!lastPositions) return;

        dispatch(
            googleMapsDataAction.updateDirections([
                {
                    Trajeto: lastPositions.map((p) => {
                        return {
                            latitude: parseFloat(p.Latitude),
                            longitude: parseFloat(p.Longitude),
                            ignition: p.Ignicao === "True",
                            direction: p.Direcao,
                        };
                    }),
                },
            ])
        );

        return () => {
            dispatch(googleMapsDataAction.cleanDirections());
        };
    }, [dispatch, lastPositions]);

    return (
        <div className="contentCard">
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                <DatePickerFilter
                    handleChangeAuto={(nome, event) => {
                        setFilter({
                            ...filter,
                            DataInicio: event.DataInicial,
                            DataFim: event.DataFinal,
                        });
                    }}
                    style={{ width: "100%", marginRight: "5px" }}
                />
                <MenuRange
                    label={t(Labels.MENU_RANGE_SHOW)}
                    className="menu-range-refactor"
                    value={filter.GridRequest.Length}
                    onChange={(event) => {
                        setFilter({
                            ...filter,
                            GridRequest: {
                                ...filter.GridRequest,
                                Length: event.target.value,
                            },
                        });
                    }}
                    options={[10, 25, 50, 100, 250, 500]}
                />
            </div>

            {findLastPositions ? (
                <div id="loading-object">
                    <Loading />
                </div>
            ) : (
                <TableCardReUse
                    mode={mode.orientation}
                    columns={COLUMNS}
                    dataSource={lastPositions.map((item) => {
                        item.Id = item.NumeroSequencial;
                        return item;
                    })}
                    onSelect={(element) => {
                        dispatch(
                            googleMapsDataAction.centralizePoints([
                                {
                                    lat: parseFloat(element.Latitude),
                                    lng: parseFloat(element.Longitude),
                                },
                            ])
                        );
                    }}
                />
            )}
        </div>
    );
};

const LastPositions = () => {
    const [alignment, setAlignment] = useState(ExpansionCardEnum.HORIZONTAL);
    const { t } = useTranslation();

    return (
        <Grid container>
            <ExpansionGrid alignment={alignment} fnClick={setAlignment}>
                <ExpansionCard
                    card={"veículos"}
                    flex={"0 1 30%"}
                    key={"1"}
                    title={t(Labels.RESULTS)}
                    alignment={alignment}
                    className="card-refactor"
                    fnClick={setAlignment}>
                    <ReportResult mode={alignment} />
                </ExpansionCard>
                <ExpansionCard
                    notPadded
                    title={t(Labels.ROUTE_MAP)}
                    card={"mapa"}
                    flex={"0 1 70%"}
                    key={"2"}
                    alignment={alignment}
                    fnClick={setAlignment}
                    className="dashboardVehicleSecondaryTabMap card-refactor">
                    <div className="mapScreen">
                        <MapCard className="map-card-refactor" />
                    </div>
                </ExpansionCard>
            </ExpansionGrid>
        </Grid>
    );
};

export default LastPositions;
