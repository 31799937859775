import React from "react";
import { Form, Formik } from "formik";
import { Button, CircularProgress, Grid, Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import importPointsAction from "~/actions/pointsInterest/importPointsAction";
import Card from "~/components/common/expansionCard/Card";
import { BackIcon, CancelIcon, ConfirmIcon } from "~/components/common/icons/Icons";
import FileInput from "~/components/common/inputFile/FileInput";
import Labels from "~/helpers/enums/Labels";
import Utils from "~/helpers/Utils";
import importPointsService from "~/services/pointOfInterest/importPointService";
import Constants from "~/helpers/enums/Constants";
import GridInfo from "./GridInfo";
import "./Info.scss";

const PointsImport = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { loading } = useSelector((state) => state.app);

    const dwFile = async () => {
        const link = await importPointsService.downloadFile();
        window.open(link.data);
    };

    const fnCallback = (msg, type) => {
        Utils.showTranslatedToast({
            type: type,
            description: msg,
        });
    };

    return (
        <>
            <Card
                title={
                    <span className="import-title">
                        <span>
                            <BackIcon style={{ width: 24, height: 24, border: "2px solid", borderRadius: "20px" }} />
                        </span>
                        {t(Labels.POINTS_LABEL_IMPORT)}
                    </span>
                }
                className="card-import card-group-refactor">
                <Formik
                    validateOnBlur={false}
                    validateOnChange={false}
                    initialValues={{
                        file: null,
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);

                        if (!values.file) {
                            fnCallback(t(Labels.ERROR_IMPORT_CHOICE), "error");
                            return;
                        }

                        if (!Utils.isFile(values.file.name, [".xls", ".xlsx"])) {
                            fnCallback(t(Labels.ERROR_IMPORT_TYPE_FILE), "error");
                            return;
                        }

                        dispatch(
                            importPointsAction.importFile(values, (msg) => {
                                Utils.showTranslatedToast({
                                    type: msg && msg.includes("sucesso") ? Constants.SUCCESS : Constants.ERROR,
                                    description: msg,
                                });
                            })
                        );
                    }}
                    render={({ submitForm, setFieldValue, values, resetForm }) => {
                        return (
                            <Form className="ceabs-register-form">
                                <Grid
                                    container
                                    direction="column"
                                    justify="flex-end"
                                    alignItems="flex-start"
                                    spacing={2}
                                    className="content">
                                    <Grid direction="column">
                                        <Grid className="grid-item">
                                            <FileInput
                                                forID="file-input"
                                                label={t(Labels.FILE_LABEL_IMPORT_DRIVER)}
                                                placeHolderInput={t(Labels.PLACEHOLDER_IMPORT_DRIVER)}
                                                labelButton={t(Labels.CHOICE_LABEL)}
                                                value={values.file?.name}
                                                backIconStyle={{ border: "2px solid", borderRadius: "20px" }}>
                                                <input
                                                    id="file-input"
                                                    data-id={t(Labels.FILE_LABEL_IMPORT_DRIVER)
                                                        .replace(/ de /g, "")
                                                        .replace(/\s/g, "")}
                                                    name="file"
                                                    type="file"
                                                    accept=".xlsx, .xls"
                                                    onChange={(event) => {
                                                        setFieldValue("file", event.currentTarget.files[0]);
                                                    }}
                                                    className="form-control"
                                                />
                                            </FileInput>
                                            <span
                                                className="link-import"
                                                data-id={t(Labels.LINK_LABEL_IMPORT_DRIVER)
                                                    .replace(/ de /g, "")
                                                    .replace(/\s/g, "")}
                                                onClick={dwFile}>
                                                <Typography className="import-font">
                                                    {t(Labels.LINK_LABEL_IMPORT_DRIVER)}
                                                </Typography>
                                            </span>
                                        </Grid>
                                        <Grid className="grid-item">
                                            <GridInfo />
                                        </Grid>
                                        <Grid container xs={12} md={12}>
                                            <Grid item xs={4} md={4}>
                                                <Box id="points-import-cancel-button" className="button-form button-form-refactor">
                                                    <Button
                                                        data-id="import-cancel"
                                                        className="cancel-button cancel-button-refactor"
                                                        variant="contained"
                                                        disableElevation
                                                        onClick={() => {
                                                            resetForm();
                                                        }}>
                                                        <CancelIcon />
                                                        {t(Labels.BTN_CANCEL_IMPORT_DRIVER)}
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={8} md={8}>
                                                <Box id="points-import-save-button" className="button-form button-form-refactor">
                                                    <Button
                                                        data-id="import-button"
                                                        className="save-button save-button-refactor"
                                                        variant="contained"
                                                        color="primary"
                                                        disableElevation
                                                        disabled={loading.importPoints}
                                                        onClick={submitForm}
                                                        startIcon={
                                                            loading.importPoints ? (
                                                                <CircularProgress size={18} />
                                                            ) : (
                                                                <ConfirmIcon />
                                                            )
                                                        }>
                                                        {t(Labels.BTN_CONFIRM_IMPORT_DRIVER)}
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                />
            </Card>
        </>
    );
};

export default PointsImport;
