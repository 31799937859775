import { names, colors, colorSolid, getFullValue } from "~/components/common/dashboard/KmRunFormatterUtils";

const customChart = ({ data }) => {
    const [item] = data;
    const {
        DistanciaComercialPorcentagem,
        DistanciaComercial,
        DistanciaNaoComercialPorcentagem,
        DistanciaNaoComercial,
        DistanciaFimSemanaPorcentagem,
        DistanciaFimSemana,
        Distancia,
        DistanciaMedia,
    } = item;

    const kmRun = Object.entries({
        DistanciaComercialPorcentagem,
        DistanciaNaoComercialPorcentagem,
        DistanciaFimSemanaPorcentagem,
    }).map((time) => ({
        name: names(time[0].substring(9, time[0].length - 11)),
        value: time[1],
        fullValue: getFullValue(time[0].substring(9, time[0].length - 11), {
            DistanciaComercial,
            DistanciaNaoComercial,
            DistanciaFimSemana,
        }),
        itemStyle: {
            color: colors(time[0].substring(9, time[0].length - 11)),
        },
        label: {
            color: colorSolid(time[0].substring(9, time[0].length - 11)),
        },
    }));

    return {
        kmRun,
        Distancia,
        DistanciaMedia,
    };
};

const extractData = ({ data }) => {
    const [item] = data;

    const {
        DistanciaComercial,
        DistanciaComercialMedia,
        DistanciaFimSemana,
        DistanciaFimSemanaMedia,
        DistanciaNaoComercial,
        DistanciaNaoComercialMedia,
    } = item;

    const payload = {
        DistanciaComercial,
        DistanciaComercialMedia,
        DistanciaFimSemana,
        DistanciaFimSemanaMedia,
        DistanciaNaoComercial,
        DistanciaNaoComercialMedia,
    };

    return payload;
};

export default { customChart, extractData };
