import Constants from "~/helpers/enums/Constants";

const initialState = {
	driver: null,
	dataDriver: [],
	dataDriverAll: [],
	searchKey: "",
	status: -1,
	dataDriverSearch: {
		pagination: {
			Start: 0,
			Index: 0,
			Length: 25,
		},
		page: {
			recordsTotal: 0,
			recordsFiltered: 1,
		},
		data: [],
	},
};

function driverReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.GET_DATA_DRIVER:
			return {
				...state,
				dataDriver: action.payload.data,
			};
		case Constants.GET_DATA_DRIVER_ALL:
			return {
				...state,
				dataDriverAll: action.payload.data,
			};
		case Constants.SET_SEARCH_KEY:
			return {
				...state,
				searchKey: action.payload,
			};
		case Constants.SET_STATUS:
			return {
				...state,
				status: action.payload,
			};
		case Constants.GET_DATA_DRIVER_SEARCH:
			return {
				...state,
				searchKey: action.payload.searchKey,
				status: action.payload.status,
				dataDriverSearch: {
					...state.dataDriverSearch,
					data: action.payload.data,
					page: action.payload.page,
				},
			};
		// case Constants.GET_REPORTING_PER_PERIOD:
		// 	return {
		// 		...state,
		// 		perPeriod: {
		// 			...state.perPeriod,
		// 			data: payload.data,
		// 			page: payload.page,
		// 		},
		// 	};
		case Constants.UPDATE_PAGINATION_REGISTER_DRIVER:
			return {
				...state,
				dataDriverSearch: {
					...state.dataDriverSearch,
					pagination: action.payload.pagination,
				},
			};

		case Constants.GET_DRIVER_BY_ID:
			return {
				...state,
				driver: action.payload.driver,
			};
		case Constants.CLEAN_DRIVER:
			return {
				...state,
				driver: initialState.driver,
			};
		default:
			return state;
	}
}

export default driverReducer;
