import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import userService from "~/services/userService";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import authAction from "~/actions/authAction";
import TermsPDFView from "./TermsPDFView";

const modalContainerStyles = {
    position: "fixed",
    bottom: "1.25rem",
    border: "1px solid black",
    left: "50%",
    transform: "translateX(-50%)",
    width: "90%",
    maxWidth: "22.5rem",
    backgroundColor: "white",
    padding: "1.25rem", 
    borderRadius: "0.125rem",  
    boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.1)", 
     zIndex: 9999,
  };
  const backdropStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0)', 
    zIndex: 9999, 
  };
  const viewButtonStyles = {
    marginLeft: '4px',
    backgroundColor: "transparent",
    color: "#007bff",
    border: "none",
    padding: 0,
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: "0.90rem",
  };
  
  const modalContentStyles = {
    maxHeight: "18.75rem",
    overflowY: "auto",
  };
  
  const scrollableContentStyles = {
    maxHeight: "12.7rem", 
    overflowY: "auto",
  };
  
  const acceptButtonStyles = {
    color: "red",
    fontWeight: "bold",
    cursor: "pointer",
  };

const AcceptTermsView = () => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [rejectTerms, setRejectTerms] = useState(false);
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [acceptTermsButton, setAcceptTermsShowButton] = useState(true);
    const [showBackdrop, setShowBackdrop] = useState(true);
    const [acceptTerms, setAcceptTerms] = useState(true); // Inicializa como true
    const [userHasInteracted, setUserHasInteracted] = useState(false); // Novo estado
    
    useEffect(() => {
        setShowModal(true);
    }, []);

    useEffect(() => {
        if (showModal || showPdfModal) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [showModal, showPdfModal]);
    
    const handleShowPdfModal = () => {
        setShowPdfModal(true);
        setShowBackdrop(false);
       //setShowModal(false);

    };

    const handleAcceptTerms = () => {
        setAcceptTerms(true); 
        setRejectTerms(false); 
        if (!userHasInteracted) { 
          setUserHasInteracted(true); 
        }
        
        if (acceptTermsButton && showModal) {
          try {
            localStorage.setItem("AceiteTermo", "True");
            userService.acceptTerms();
            window.location.reload();
          } catch (error) {
            console.log(error);
          }
        }
        
        setAcceptTerms(true);
        setAcceptTermsShowButton(true);
      };

    const handleRejectTerms = () => {
        setRejectTerms(true); 
        setUserHasInteracted(true); // O usuário interagiu, então atualiza este estado
        setAcceptTerms(false); // Configura para false
        authAction.logout();
      };
    
    return (
        <>
            {showBackdrop  && <div style={backdropStyle}></div>} 
            {showModal && (
                <div style={modalContainerStyles}>
                    <div style={modalContentStyles}>
                        <div style={{ display: "flex", alignItems: "flex-start" }}>
                            <div style={{ marginRight: "10px", marginTop: "4px" }}>
                                <InsertDriveFileIcon style={{ fontSize: 32 }} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <p style={{ marginTop: "5px" }}>
                                    Veja os
                                    <button style={viewButtonStyles} onClick={handleShowPdfModal}>
                                         Termos e Condições
                                    </button>
                                    <br />e Leia para ativar.
                                </p>
                                <div style={scrollableContentStyles}></div>
                                {acceptTerms || rejectTerms ? (
                                    <p style={acceptButtonStyles} onClick={acceptTermsButton ? handleAcceptTerms : handleRejectTerms}>
                                        {acceptTerms ? "Aceitar T&C" : "Recusar T&C"}
                                    </p>
                                ) : null}
                            </div>
                            <div style={{ marginLeft: "20px" }}>
                                <div style={{ marginBottom: "10px" }}>
                                    <label>
                                        <input type="radio" checked={acceptTerms} onChange={handleAcceptTerms} />
                                        <strong>Eu Li</strong>
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type="radio" checked={!acceptTerms  && userHasInteracted} onChange={handleRejectTerms} />
                                        <strong>Eu Recuso</strong>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            )}
            {showPdfModal && (
                <TermsPDFView isOpen={showPdfModal} onRequestClose={() => setShowPdfModal(false)} />

      )}

        </>
    );
};

export default AcceptTermsView;
