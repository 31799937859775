import { nFormatter } from "~/helpers/Utils";

const extractData = ({ data }) => {
	const [item] = data;

	const { ComportamentoRisco, QuaseAcidente, SemDanosMateriais, ComDanosMateriais, AcidentesGraves } = item;

	const payload = [
		{
			value: 20,
			name: "Acidentes Graves",
			formatedValue: nFormatter(AcidentesGraves, 2),
			fullValue: AcidentesGraves,
			className: "purple",
		},
		{
			value: 40,
			name: "Acidentes com Danos Materiais",
			formatedValue: nFormatter(ComDanosMateriais, 2),
			fullValue: ComDanosMateriais,
			className: "blue",
		},
		{
			value: 60,
			name: "Acidentes sem Danos Materiais",
			formatedValue: nFormatter(SemDanosMateriais, 2),
			fullValue: SemDanosMateriais,
			className: "green",
		},
		{
			value: 80,
			name: "Quase Acidente",
			formatedValue: nFormatter(QuaseAcidente, 2),
			fullValue: QuaseAcidente,
			className: "orange",
		},
		{
			value: 100,
			name: "Comportamento de Risco",
			formatedValue: nFormatter(ComportamentoRisco, 1),
			fullValue: ComportamentoRisco,
			className: "red",
		},
	];
	return payload;
};

export default { extractData };
