import Constants from "~/helpers/enums/Constants";

const initialState = {
	filter: {
		index: 0,
		limit: 10,
		term: "",
		selectedLabel: "",
		SearchColumns: [{
			OrderDirection: "desc"
		}]
	},
	data: { Data: [] },
};

function mapFilterReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.DATA_FILTER_MAP:
			return {
				...state,
				data: action.payload.data,
			};
		case Constants.UPDATE_DATA_FILTER:
			return {
				...state,
				filter: {
					...state.filter,
					...action.payload.filter,
				},
			};
		case Constants.CLEAN_MAP_FILTER:
			return {
				...state,
				filter: initialState.filter
			};
		default:
			return state;
	}
}

export default mapFilterReducer;
