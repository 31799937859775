import Constants from "~/helpers/enums/Constants";

const mountState = (name, label, searchData, checked = false, searchName = null) => {
    return {
        name,
        label,
        checked,
        searchData,
        searchName: searchName || searchData,
    };
};

const INITIAL_STATE = {
    filters: [
        {
            ...mountState("Identificacao", "Placa", "placa", true),
        },
        {
            ...mountState(
                "DataHoraEventoLocal",
                "Data/Hora do evento",
                "DataHoraEventoLocal",
                true,
                "dataHoraEventoUtc"
            ),
        },
        {
            ...mountState("TipoEventoTxt", "Evento", "tipoEvento", true),
        },
        {
            ...mountState("Ignicao", "Ignição", "tipoEvento", true),
        },
        {
            ...mountState("Velocidade", "Velocidade", "gpsVelocidade", true),
        },
        {
            ...mountState("MotoristaNome", "Motorista", "motoristaNome", true),
        },
        {
            ...mountState("Localizacao", "Localização", "Localizacao", true, "localizacao"),
        },
        {
            ...mountState("Chassi", "Chassi", "Chassi", true, "Chassi"),
        },
        {
            ...mountState("Tipo", "Tipo", "Tipo", false, "tipoObjeto"),
        },
        {
            ...mountState("Marca", "Marca", "Marca", false, "marca"),
        },
        {
            ...mountState("Frota", "Frota", "Frota", false, "frota"),
        },
        {
            ...mountState("NumeroSerie", "Número Série", "NumeroSerie", false, "numeroSerie"),
        },
        {
            ...mountState("PontoReferencia", "Ponto mais próximo", "poiDistance"),
        },
        {
            ...mountState("PavimentoTipo", "Pavimento", "revGeoPavimentoTipo"),
        },
        {
            ...mountState("ProprietarioNome", "Proprietário", "ProprietarioNome", false, "proprietarioNome"),
        },
    ],
};

function appReducer(state = INITIAL_STATE, action = null) {
    switch (action.type) {
        case Constants.TOOGLE_MARKED_MAP_FILTER:
            return {
                ...state,
                filters: state.filters.map((filter) =>
                    filter.name === action.name ? { ...filter, checked: action.checked } : filter
                ),
            };
        default:
            return state;
    }
}

export default appReducer;
