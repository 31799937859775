import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import Utils from "~/helpers/Utils";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";

import groupDriversAction from "~/actions/groupDriversAction";

import { useHistory } from "react-router-dom";

import { lighten } from "polished";

import { Box, Button, makeStyles, Typography } from "@material-ui/core";

import CardGroup from "~/components/common/card/CardGroup";

import { ColoredEditIcon, IconDelete, AddIcon, EditIconWhite } from "~/components/common/icons/Icons";

import "./GroupDriverFinder.scss";

const useStyles = makeStyles((theme) => ({
    root: {
        background: lighten(0.3, theme.palette.primary.main),
        cursor: "pointer",
        width: "90%",
    },
}));

const GroupDriverFinder = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { groups, driversLinked } = useSelector((state) => state.groupDriversReducer);
    const loadingReq = useSelector((state) => state.app.loading);
    const { user } = useSelector((state) => state.auth);

    let history = useHistory();

    const [display, setDisplayGroup] = useState(false);
    const [page, setPage] = React.useState(1);
    const [searchTerm, setTerm] = React.useState("");
    const [groupData, setGroupData] = React.useState("");

    const handlerEdit = (id, row) => {
        history.push(
            {
                pathname: `/register/groupOfDrivers/${id}`,
            },
            row
        );
    };

    const refreshList = () => {
        setDisplayGroup(false);
        dispatch(groupDriversAction.getGroupsDrivers());
    };

    const handlerDelete = (id, row) => {
        dispatch(groupDriversAction.deleteGroupOfDrivers(id, refreshList));
    };

    const LINKED_GROUP_DRIVERS_COLUMNS = [
        {
            name: "Nome",
            label: t(Labels.GROUP_DRIVERS_REGISTER_NAME),
            render: (name) => <strong title={name}>{Utils.TextView(name, 20)}</strong>,
        },
        {
            name: "Cpf",
            label: t(Labels.GROUP_DRIVERS_REGISTER_CPF),
            render: (cpf) => <span>{cpf}</span>,
        },
        {
            name: "NumeroCNH",
            label: t(Labels.GROUP_DRIVERS_REGISTER_CNH),
            render: (cnh) => <span>{cnh}</span>,
        },
        {
            name: "CategoriaCNH",
            label: t(Labels.GROUP_DRIVERS_REGISTER_CATEGORIA),
            render: (cat) => <span>{cat}</span>,
        },
        {
            name: "Identificador",
            label: t(Labels.GROUP_DRIVERS_REGISTER_IDENTIFICADOR),
            render: (id) => <span>{id}</span>,
        },
    ];

    const GROUP_DRIVERS_COLUMNS = [
        {
            name: "Id",
            label: t(Labels.GROUP_POINTS_COLUMN_ACTIONS),
            render: (Id, row) => (
                <span id="drivers-group-actions-column">
                    <span
                        style={{ color: user.colors.topBarColor, marginRight: 10 }}
                        onClick={() => {
                            handlerEdit(Id, row);
                        }}>
                        <ColoredEditIcon style={{ marginRight: 3 }} />
                        {t(Labels.GROUP_DRIVERS_LIST_BTN_EDIT)}
                    </span>
                    <span
                        style={{ borderLeft: "1px solid #AFC3D2", paddingLeft: 10, color: user.colors.topBarColor }}
                        onClick={() => {
                            handlerDelete(Id, row);
                        }}>
                        <IconDelete style={{ height: 14, width: "auto" }} />
                        {t(Labels.ALERT_EDIT_BUTTON_DEACTIVE)}
                    </span>
                </span>
            ),
        },
        {
            name: "Nome",
            label: t(Labels.GROUP_DRIVERS_REGISTER_NAME),
            render: (name) => <strong title={name}>{Utils.TextView(name, 32)}</strong>,
        },
        {
            name: "Id",
            label: t(Labels.GROUP_DRIVERS_REGISTER_CATEGORIA),
            render: (code) => <span>{code}</span>,
        },
        {
            name: "Ativo",
            label: t(Labels.GROUP_DRIVERS_REGISTER_ACTIVE),
            render: (active) => <span>{active ? t(Labels.YES) : t(Labels.NO)}</span>,
        },
    ];

    useEffect(() => {
        dispatch(groupDriversAction.getGroupsDrivers());
    }, [dispatch]);

    const selectGroupDriver = (data) => {
        setTimeout(() => {
            setDisplayGroup(true);
            dispatch(groupDriversAction.getDriversLinkedGroupById(data.Id, { page: 0, length: 10 }));
            setGroupData(data);
            setPage(1);
        }, 500);
    };

    const handlePageDriversLinked = (event, value) => {
        setPage(value);

        const query = {
            page: value * 1 - 1,
            length: 10,
            search: searchTerm,
        };

        dispatch(
            groupDriversAction.getDriversLinkedGroupById(groupData.Id, {
                ...query,
            })
        );
    };

    const searchFn = (term) => {
        setTerm(term);
        const query = {
            page: 0,
            length: 10,
            search: term,
        };

        dispatch(
            groupDriversAction.getDriversLinkedGroupById(groupData.Id, {
                ...query,
            })
        );
    };

    const classes = useStyles();
    return (
        <Box>
            <Box className="box-group">
                <Button
                    data-id={t(Labels.GROUP_DRIVERS_LIST_BTNADD) + "-id"}
                    className="btn-add-group-items btn-add-group-items-smaller"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => {
                        history.push("/register/groupOfDrivers/new");
                    }}>
                    <Typography className="import-font">
                        <AddIcon />
                        {t(Labels.GROUP_DRIVERS_LIST_BTNADD)}
                    </Typography>
                </Button>
                <div className="box-group-card">
                    <div className="box-card-group-item">
                        <CardGroup
                            data-id={t(Labels.GROUP_DRIVERS_LIST_LABEL) + "-id"}
                            title={t(Labels.GROUP_DRIVERS_LIST_LABEL)}
                            className="group-items"
                            classTitle="boxTitle-refactor"
                            classHeader={"search-group-item"}
                            loading={loadingReq.getDriversGroup}
                            columns={GROUP_DRIVERS_COLUMNS}
                            source={groups.data}
                            select={selectGroupDriver}
                        />
                    </div>
                    <div id="group-driver-finder" className="box-card-group-item">
                        {display && (
                            <CardGroup
                                data-id={t(Labels.GROUP_DRIVERS_LINKED_LABEL) + "-id"}
								title={t(Labels.GROUP_DRIVERS_LINKED_LABEL)}
                                className={`group-items ${classes.root}`}
                                classTitle="boxTitle-refactor"
                                classHeader={"search-group-item"}
                                headerComp={
                                    <Button
                                        data-id={"edit-linked-id"}
                                        className="btn-edit-linked"
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        onClick={() => {
                                            handlerEdit(groupData.Id, groupData);
                                        }}>
                                        <Typography className="import-font">
                                            <EditIconWhite />
                                            {t(Labels.GROUP_DRIVERS_LIST_BTN_EDIT)}
                                        </Typography>
                                    </Button>
                                }
                                loading={loadingReq.getDriversLinked}
                                columns={LINKED_GROUP_DRIVERS_COLUMNS}
                                source={driversLinked.data}
                                backgroundChanged
                                page={page}
                                itemsPerPage={25}
                                totalItems={driversLinked.recordsTotal}
                                handlePage={handlePageDriversLinked}
                                searchFn={searchFn}
                            />
                        )}
                    </div>
                </div>
            </Box>
        </Box>
    );
};

export default GroupDriverFinder;
