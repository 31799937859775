import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";

import dashboardService from "~/services/dashboard/dashboardService";
import dashboardServiceVehicle from "~/services/dashboardVehicle/dashboardService";

const getDataVehicle = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "getDataVehicle") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return dashboardService
        .getDataVehicles(filter)
        .then((response) => {
            let data = response.data;
            let payload = {
                data: data,
            };
            dispatch({ type: Constants.GET_DATA_VEHICLE_INDIVIDUAL, payload });
            callback(false);
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const updateDataVehicle = (callback = () => {}, LOADING_IDENTIFICATOR = "getDataVehicle") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    let payload = {
        data: [],
    };

    dispatch({ type: Constants.GET_DATA_VEHICLE_INDIVIDUAL, payload });

    setTimeout(function () {
        dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    }, 1000);
};

const getPrivateMode = (chassi, callback = () => {}, LOADING_IDENTIFICATOR = "getPrivateMode") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return dashboardServiceVehicle
        .getPrivateMode(chassi)
        .then((response) => {
            let data = response.data;
            callback(false, data);
            dispatch({
                type: Constants.GET_DATA_VEHICLE_MODE_PRIVATE,
                payload: {
                    data,
                },
            });
        })
        .catch((error) => {
            callback(error);
            dispatch({
                type: Constants.GET_DATA_VEHICLE_MODE_PRIVATE,
                payload: {
                    data: null
                },
            });
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const switchPrivateMode = ({ chassi, status }, callback = () => {}, LOADING_IDENTIFICATOR = "switchPrivateMode") => (
    dispatch
) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return dashboardServiceVehicle
        .switchPrivateMode(chassi, status)
        .then((response) => {
            let data = response.data;
            callback(false, data);
        })
        .catch((error) => {
            callback(error);
        })
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

export default {
    getDataVehicle,
    updateDataVehicle,
    getPrivateMode,
    switchPrivateMode,
};
