import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import "./styles.scss";
const VehicleSelection = ({ selectedVehicles, handleVehicleSelection, selectAll, allSelected, vehicles }) => (
    <div>
        <p>Selecione os veículos que deseja contratar:</p>
        <div className="table-container">
            <table className="vehicle-table">
                <thead>
                    <tr>
                        <th>Placa</th>
                        <th>Chassi</th>
                        <th>
                            <FormControlLabel
                                control={<Checkbox checked={allSelected} onChange={selectAll} color="primary" />}
                                label="Selecionar Todos"
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {vehicles.map((vehicle, index) => (
                        <tr key={index}>
                            <td>{vehicle.Placa}</td>
                            <td>{vehicle.Chassi}</td>
                            <td>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedVehicles.some((v) => v.Chassi === vehicle.Chassi)}
                                            onChange={() => handleVehicleSelection(vehicle)}
                                            color="primary"
                                        />
                                    }
                                    label=""
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
);

export default VehicleSelection;
