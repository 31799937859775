import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 20,
        borderRadius: 0,
        width: "100%",
        marginRight: "100px",
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 300 : 700],
    },
    bar: {
        borderRadius: 0,
        backgroundColor: theme.palette.primary,
    },
}))(LinearProgress);

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        height: "fit-content"
    },
});

export default function CustomizedProgressBars({ value }) {
    const classes = useStyles();

    return (
        <div className={`${classes.root} progressBox`}>
            <BorderLinearProgress color="primary" variant="determinate" value={value ? value : 0} />
        </div>
    );
}
