import React, { useState } from "react";
import { useDispatch } from "react-redux";
import recoverAction from "~/actions/recover/recoverActions";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import AlertMessage from "~/components/common/alertModal/AlertModal";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";

const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

const FormRecover = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [msg, setMsg] = useState("");
    const [touched, setTouched] = useState(false);
    const { t } = useTranslation();

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    const returnMsg = (msg) => {
        setMsg(msg);
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
        setTouched(false);
        if (msg.includes("enviada")) {
            backToLogin();
        }
    };

    const sendInfo = () => {
        setTouched(true);
        if (!emailRegex.test(email)) {
            return;
        }
        dispatch(recoverAction.recoverLogin(email, returnMsg));
    };

    const hasError = (field) => {
        if (field === "email") {
            return (!email && touched) || (!!email && !emailRegex.test(email)) ? true : false;
        }
    };

    const errorMessage = () => {
        if (touched && !email) {
            return t(Labels.RECOVER_PASSWORD_EMAIL_REQUIRED);
        } else if (!!email && !emailRegex.test(email)) {
            return t(Labels.RECOVER_PASSWORD_EMAIL_INVALID);
        } else {
            return "";
        }
    };

    const backToLogin = () => {
        dispatch(recoverAction.visibleFormRecover(false));
    };

    return (
        <Box className="boxFormRecover">
            <AlertMessage open={openModal} onClose={handleClose} message={msg} severity="info" />
            <span className="textInfo">
                <Typography>{t(Labels.RECOVER_PASSWORD_INFO)}</Typography>
            </span>
            <Box className="text-form">
                <TextField
                    fullWidth
                    type="email"
                    className="margin-form"
                    label={t(Labels.PROFILE_PERSONAL_INFORMATION_EMAIL)}
                    id="email-recover"
                    onChange={handleChangeEmail}
                    inputProps={{ maxLength: 150 }}
                    error={hasError("email")}
                    helperText={errorMessage()}
                    placeholder={t(Labels.REPORT_SHEDULED_EMAIL)}
                />
                <Button
                    color="primary"
                    className="btnSendForm"
                    fullWidth
                    variant="contained"
                    onClick={() => {
                        sendInfo();
                    }}>
                    {t(Labels.RECOVER_PASSWORD_RECOVER)}
                </Button>
            </Box>
        </Box>
    );
};

export default FormRecover;
