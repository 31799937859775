import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import "./TableTab.scss";

const useStyles = makeStyles((theme) => ({
	active: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		borderColor: theme.palette.primary.main,
	},
}));

const TableTab = (props) => {
	const classes = useStyles();

	return (
		<Table className="table-tab">
			<TableHead>
				<TableRow>
					{props.columns &&
						props.columns.map(({ title, ...column }, i) => (
							<TableCell key={i} {...column}>
								{title}
							</TableCell>
						))}
				</TableRow>
			</TableHead>
			<TableBody>
				{props.rows &&
					props.rows.map(({ key, ...row }, i) => {
						const rowKey = key || i;
						const isActive = props.value === rowKey;
						return (
							<TableRow
								key={rowKey}
								hover={!isActive}
								className={`table-tab-row ${isActive ? `active ${classes.active}` : "inactive"} `}
								onClick={() => {
									props.onItemClick && props.onItemClick(rowKey);
								}}>
								{props.columns &&
									props.columns.map((column, j) => (
										<TableCell component="th" scope="row" key={j}>
											{row[column.key]}
										</TableCell>
									))}
								{isActive && (
									<th className="arrow">
										<span />
									</th>
								)}
							</TableRow>
						);
					})}
			</TableBody>
		</Table>
	);
};

export default TableTab;
