import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { CancelIcon, ConfirmIcon } from "~/components/common/icons/Icons";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import alertAction from "~/actions/registerAlertAction";
import AlertConditions from "./AlertConditions";
import RegisterInputText from "~/components/common/registerInputs/RegisterInputText";
import RegisterSelect from "~/components/common/registerInputs/RegisterSelect";
import RegisterDayOfWeek from "~/components/common/registerInputs/RegisterDayOfWeek";
import RegisterDatePicker from "~/components/common/registerInputs/RegisterDatePicker";
import "./AlertForm.scss";

const AlertForm = (props) => {
    const { t } = useTranslation();
    const { updateAction, comeBack, initialValues } = props;
    const dispatch = useDispatch();
    const refAlertForm = useRef();

    const {
        conditions,
        actions: { acoes, condicaoTipos },
    } = useSelector((state) => state.alert);
    const { loading } = useSelector((state) => state.app);

    useEffect(() => {
        dispatch(alertAction.getAlertsConditons());
        dispatch(alertAction.getAlertsActions());
    }, [dispatch]);

    useEffect(() => {
        if (refAlertForm.current) updateAction && dispatch(updateAction(refAlertForm));
    }, [dispatch, updateAction]);

    return (
        <Formik
            innerRef={refAlertForm}
            initialValues={{
                Nome: "",
                DiasSemana: 1234567,
                AlertaCondicaoTipoId: "1",
                HorarioValidacaoInicial: "",
                HorarioValidacaoFinal: "",
                DataValidade: null,
                AlertasAcoes: [{}],
                AlertasCondicoes: [{}],
                ...initialValues,
            }}
            validate={(values) => {
                const errors = {};

                if (!values.Nome) {
                    errors.Nome = t(Labels.ALERT_FORM_NAME_REQUIRED);
                }

                if (!values.DiasSemana) {
                    errors.DiasSemana = t(Labels.ALERT_FORM_DAY_WEEK_REQUIRED);
                }

                if (values.HorarioValidacaoInicial || values.HorarioValidacaoFinal) {
                    if (!values.HorarioValidacaoInicial) {
                        errors.HorarioValidacaoInicial = t(Labels.ALERT_FORM_HOUR_WEEK_REQUIRED_START);
                    }

                    if (!values.HorarioValidacaoFinal) {
                        errors.HorarioValidacaoFinal = t(Labels.ALERT_FORM_HOUR_WEEK_REQUIRED_END);
                    }
                }

                if (values.AlertasCondicoes.filter((ac) => ac.Codigo).length < 1) {
                    errors[`AlertasCondicoes.0`] = t(Labels.ALERT_FORM_CONDITONS_REQUIRED);
                }

                values.AlertasCondicoes.forEach((ac, i) => {
                    const error = ac.validate && ac.validate(ac);
                    if (error) errors[`AlertasCondicoes.${i}`] = error;
                });

                values.AlertasAcoes.forEach((aa, i) => {
                    const error = aa.validate && aa.validate(aa);
                    if (error) errors[`AlertasAcoes.${i}`] = error;

                    if (aa.AlertaRegraId === 21 && aa.AlertaRegraTipoId === 4 && !Utils.isValidEmail(aa.Valor))
                        errors[`AlertasAcoes.${i}`] = t(Labels.ALERT_FORM_EMAIL_REQUIRED);
                });

                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                dispatch(
                    alertAction.saveAlert(
                        {
                            Alerta: {
                                ...values,
                                AlertasAcoes: undefined,
                                AlertasCondicoes: undefined,
                            },
                            AlertasAcoes: values.AlertasAcoes.filter((aa) => aa.Codigo),
                            AlertasCondicoes: values.AlertasCondicoes.filter((ac) => ac.Codigo),
                        },
                        (error) => {
                            setSubmitting(false);
                            if (error) {
                                Utils.showError(error.response.data.Message);
                                return;
                            }

                            Utils.showTranslatedToast({
                                type: Constants.SUCCESS,
                                description: Labels.ALERT_FORM_MSG_SAVE_SUCCESS,
                            });

                            dispatch(alertAction.updateAlertFilter());

                            props.comeBack();
                        }
                    )
                );
            }}>
            {(form) => {
                return (
                    <Form className={`ceabs-register-form alert-form ${props.compact ? "alert-form-compact" : ""}`}>
                        <Grid className="alert-form-grid" container direction="row">
                            <Grid item xs={12} md={props.compact ? 12 : 5}>
                                <RegisterInputText
                                    label={t(Labels.ALERT_FORM_NAME)}
                                    name="Nome"
                                    disabled={props.disabled}
                                />
                            </Grid>
                            <Grid item xs={4} md={props.compact ? 4 : 2}>
                                <RegisterDayOfWeek
                                    label={t(Labels.ALERT_FORM_DAY_WEEK)}
                                    name="DiasSemana"
                                    disabled={props.disabled}
                                />
                            </Grid>
                            <Grid item xs={4} md={props.compact ? 4 : 2}>
                                <Box className="register-field hour-conditions">
                                    <Typography variant="subtitle1" className="register-field-label">
                                        {t(Labels.ALERT_FORM_HOUR_WEEK)}
                                    </Typography>
                                    <Grid container direction="row" spacing={0}>
                                        <Grid item xs={6}>
                                            <RegisterInputText
                                                className="alert-time-start"
                                                type="time"
                                                noPadding
                                                name="HorarioValidacaoInicial"
                                                disabled={props.disabled}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <RegisterInputText
                                                className="alert-time-end"
                                                type="time"
                                                noPadding
                                                name="HorarioValidacaoFinal"
                                                disabled={props.disabled}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid item xs={4} md={props.compact ? 4 : 2}>
                                <RegisterDatePicker
                                    label={t(Labels.ALERT_FORM_EXPIRATION)}
                                    name="DataValidade"
                                    disabled={props.disabled}
                                />
                            </Grid>

                            <Grid item xs={12} md={props.compact ? 12 : 4}>
                                <AlertConditions
                                    name="AlertasCondicoes"
                                    placeholder={t(Labels.ALERT_FORM_CONDITONS_PLACEHOLDER)}
                                    label={t(Labels.ALERT_FORM_CONDITONS)}
                                    addLabel={t(Labels.ALERT_FORM_CONDITONS_ADD)}
                                    text="Nome"
                                    identifier="Id"
                                    options={conditions}
                                    loading={loading.alert}
                                    form={form}
                                    compact={props.compact}
                                    disabled={props.disabled}
                                    bottomSelect={
                                        form.values.AlertasCondicoes.length > 1 ? (
                                            <RegisterSelect
                                                noPadding
                                                name="AlertaCondicaoTipoId"
                                                options={condicaoTipos}
                                                disabled={props.disabled}
                                                text="Nome"
                                                identifier="Id"
                                            />
                                        ) : null
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} md={props.compact ? 12 : 4}>
                                <AlertConditions
                                    name="AlertasAcoes"
                                    placeholder={t(Labels.ALERT_FORM_ACTIONS_PLACEHOLDER)}
                                    label={t(Labels.ALERT_FORM_ACTIONS)}
                                    addLabel={t(Labels.ALERT_FORM_ACTIONS_ADD)}
                                    text="Nome"
                                    identifier="Id"
                                    options={acoes}
                                    loading={loading.alert}
                                    compact={props.compact}
                                    form={form}
                                    disabled={props.disabled}
                                />
                            </Grid>
							{!props.disabled && (

                            <Grid item xs={4} md={props.compact ? 6 : 3}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    style={{ height: "100%" }}
                                    spacing={0}>
                                    <Grid item xs={5} md={5}>
                                        <Box className="button-form button-form-refactor">
                                            <Button
                                                id="profile-cancel-button"
                                                className="cancel-button cancel-button-refactor"
                                                variant="contained"
                                                size="medium"
                                                disableElevation
                                                fullWidth
                                                onClick={() => {
                                                    form.resetForm();
                                                    comeBack && comeBack();
                                                }}
                                                disabled={props.loading}>
                                                <CancelIcon />
                                                {t(Labels.ALERT_FORM_BUTTON_CANCEL)}
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <Box className="button-form button-form-refactor">
                                                <Button
                                                    id="profile-submit-button"
                                                    variant="contained"
                                                    className="save-button save-button-refactor"
                                                    color="primary"
                                                    size="medium"
                                                    fullWidth
                                                    disableElevation
                                                    onClick={form.submitForm}
                                                    disabled={props.loading}
                                                    startIcon={
                                                        props.loading ? <CircularProgress size={18} /> : <ConfirmIcon />
                                                    }>
                                                    {t(Labels.ALERT_FORM_BUTTON_CONFIRM)}
                                                </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
							)}
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default AlertForm;
