// REGISTER

import Constants from "~/helpers/enums/Constants";

const initialState = {
    openIsertForm: false,
    openEditForm: false,
    insertform: {},
    editForm: {},
    alert: {},
    alertFilter: {
        page: 0,
        limit: 10,
        status: "Ativo",
        search: "",
        SearchColumns: [
            {
                OrderTable: true,
            },
        ],
    },
    alertDetailsHistory: [],
    alertDetailsHistoryId: {},

    conditions: [],
    actions: {
        acoes: [],
        condicaoTipos: [],
    },
    alerts: [],
    alertLinkedTrue: [],
    alertLinkedFalse: [],
    alertVeiculos: [],
    msg: "",
};

function alertReducer(state = initialState, action = null) {
    switch (action.type) {
        case Constants.SET_OPEN_INSERT_FORM:
            return {
                ...state,
                openIsertForm: action.payload,
            };
        case Constants.SET_OPEN_EDIT_FORM:
            return {
                ...state,
                openEditForm: action.payload,
            };
        case Constants.UPDATE_INSERT_FORM:
            return {
                ...state,
                insertform: action.payload,
            };
        case Constants.UPDATE_EDIT_FORM:
            return {
                ...state,
                editForm: action.payload,
            };
        case Constants.GET_ALERT_BY_ID:
            return {
                ...state,
                alert: action.payload,
            };
        case Constants.GET_ALERTS_HISTORY:
            return {
                ...state,
                alertDetailsHistory: action.payload,
            };
        case Constants.LIST_ALERTS:
            return {
                ...state,
                alerts: action.payload.data,
            };
        case Constants.IMPORT_FILE_ALERT:
            return {
                ...state,
                msg: action.payload.data,
            };
        case Constants.LIST_ALERTS_LINKED_TRUE:
            return {
                ...state,
                alertLinkedTrue: action.payload.data,
            };
        case Constants.LIST_ALERTS_LINKED_FALSE:
            return {
                ...state,
                alertLinkedFalse: action.payload.data,
            };

        case Constants.LIST_ALERTS_CONDITIONS:
            return {
                ...state,
                conditions: action.payload.sort((a, b) => {
                    if (a.name > b.name) {
                        return 1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    return 0;
                }),
            };
        case Constants.LIST_ALERTS_ACTIONS:
            return {
                ...state,
                actions: action.payload,
            };
        case Constants.CLEAN_ALERT:
            return {
                ...state,
                alert: initialState.alert,
            };

        case Constants.GET_ALERTS_HISTORY_ID:
            return {
                ...state,
                alertDetailsHistoryId: action.payload,
            };
        case Constants.CLEAN_ALERTS_HISTORY:
            return {
                ...state,
                alertDetailsHistoryId: {},
            };
        case Constants.UPDATE_ALERT_FILTER:
            return {
                ...state,
                alertFilter: {
                    ...state.alertFilter,
                    ...action.payload,
                },
            };

        default:
            return state;
    }
}

export default alertReducer;
