import React from "react";

import { useDispatch } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import { lighten } from "polished";
import ArrowSortOrder from "~/components/common/arrowSortOrder/ArrowSortOrder";

import Utils from "~/helpers/Utils";

import "./Table.scss";
import { useEffect } from "react";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: lighten(0.2, "#AFC3D2"),
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    "&:nth-of-type(2n+0)": {
      backgroundColor: lighten(0.2, "#AFC3D2"),
    },
    "&:hover": {
      backgroundColor: `${lighten(0.3, theme.palette.primary.main)} !important`,
      cursor: "pointer",
    },
  },
}));

const TableComponent = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      props.cleanAction && dispatch(props.cleanAction());
    };
  }, [dispatch, props]);

  function onClickRow(data) {
    props.onItemClick && props.onItemClick(data);
  }

  const classes = useStyles();
  return (
    <>
      <div className="table-result">
        <TableContainer component={Paper} style={{ borderRadius: 0, boxShadow: "none" }} className="custom-table">
          <Table id={"table-" + props.id} size="small">
            <TableHead id={"table-head-" + props.id}>
              <TableRow className={classes.tableRow}>
                {props.column
                  .filter((col) => col.checked)
                  .map((col, i) => (
                    <StyledTableCell onClick={() => {props.onHeaderClick && props.onHeaderClick(col.label)}} id={"table-cell-head" + props.id + " - " + i} key={i} align="center" rowSpan={0}>
                      <Typography
                        id={"table-cell-font" + props.id + " - " + i}
                        style={{ fontSize: "16px", color: "#AFC3D2", cursor: props.onHeaderClick ? "pointer" : "unset" }}>
                        {col.label}
                        <ArrowSortOrder show={col.label === props.selectedLabel} order={props.order} />
                      </Typography>
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((row, i) => (
                <TableRow
                  id={"table-value-row" + props.id + " - " + i}
                  className={classes.root}
                  hover
                  key={i}
                  onClick={() => {
                    onClickRow(row);
                  }}
                  style={{ height: 25 }}
                  align="right">
                  {props.column
                    .filter((col) => col.checked)
                    .map((col, j) => (
                      <StyledTableCell id={"table-value-cell" + props.id + " - " + j} key={j}>
                        {col.name === "Ignicao" ? (
                          <img
                            src={Utils.iconResolver(props.data["Tipo"], row[col.name])}
                            alt={""}
                            height="16"
                            width="16"
                          />
                        ) : (
                            <Typography
                              id={"table-value-cell-font" + props.id + " - " + j}
                              style={{ fontSize: "12px", color: "#3D5564" }}>
                              {col.name === "Distancia" ? row[col.name] + " km" : row[col.name]}
                            </Typography>
                          )}
                      </StyledTableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
export default TableComponent;
