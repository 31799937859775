import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import { useHistory } from "react-router-dom";

import { Grid } from "@material-ui/core";

import DualList from "~/components/common/dualList/DualList";
import fleetActions from "~/actions/fleets/fleetsActions";

const FleetsLinkCars = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let history = useHistory();

    const { loading } = useSelector((state) => state.app);
    const auth = useSelector((state) => state.auth);
    const { fleet, availableCarsFleets, notAvailableCarsFleets } = useSelector((state) => state.fleetsRegister);

    useEffect(() => {
        dispatch(fleetActions.getObjectsFleetAvailable(fleet.Id));
        dispatch(fleetActions.getObjectsFleetNotAvailable(fleet.Id));
    }, [dispatch, fleet]);

    useEffect(() => {
        dispatch(fleetActions.cleanCarsLinked());
        dispatch(fleetActions.cleanAvailableCars());
    }, [dispatch]);

    const CARS_COLUMNS = [
        {
            name: "Placa",
            label: t(Labels.COLUMN_PLATE),
            render: (fleet) => <strong>{fleet}</strong>,
        },
        {
            name: "Identificacao",
            label: t(Labels.COLUMN_IDENTIFICATION),
        },
        {
            name: "Marca",
            label: t(Labels.COLUMN_BRAND),
        },
        {
            name: "Modelo",
            label: t(Labels.COLUMN_MODEL),
        },
    ];

    const getIdOfselectedRows = (rows) => {
        return rows.map((r) => r.Id);
    };

    const handleLinkCars = (selectedRows) => {
        const selectedRowsId = getIdOfselectedRows(selectedRows);
        dispatch(fleetActions.saveFleetLinkedObject(fleet.Id, selectedRowsId));
    };

    const handleUnbindCars = (selectedRows) => {
        const selectedRowsId = getIdOfselectedRows(selectedRows);
        dispatch(fleetActions.saveFleetUnlinkedObject(fleet.Id, selectedRowsId));
    };

    const handlerSearchPagingAvailable = (search, pagination) => {
        const filter = {
            limit: pagination.length,
            page: pagination.current - 1,
            search: search,
        };
        dispatch(fleetActions.getObjectsFleetAvailable(fleet.Id, filter));
    };

    const handlerSearchPagingLinked = (search, pagination) => {
        const filter = {
            length: pagination.length,
            page: pagination.current - 1,
            search: search,
        };
        dispatch(fleetActions.getObjectsFleetNotAvailable(fleet.Id, filter));
    };

    useEffect(() => {
        if (!fleet.Id) {
            history.push(`/register/fleet`);
        }
    }, [fleet, history]);

    return (
        <div>
            <Grid container justify="flex-start" alignItems="flex-end" spacing={1}>
                <Grid item xs={12}>
                    <DualList
                        actionAdd={handleLinkCars}
                        actionRemove={handleUnbindCars}
                        tableAvailable={{
                            key: "Available",
                            title: t(Labels.FLEET_LINK_CARS_AVAILABLE),
                            columns: CARS_COLUMNS,
                            dataSource: availableCarsFleets.data,
                            rowSelection: {
                                refColName: "Placa",
                                onChange: (selectedRows) => {
                                    console.log("selectedRows", selectedRows);
                                },
                            },
                            pagination: {
                                current: availableCarsFleets.index,
                                itemsPerPage: availableCarsFleets.recordsFiltered,
                                totalItems: availableCarsFleets.recordsTotal,
                            },
                            onChange: handlerSearchPagingAvailable,
                            loading: loading.getObjectsFleetAvailable,
                        }}
                        tableLinked={{
                            key: "Linked",
                            title: (
                                <span>
                                    {t(Labels.FLEET_LINK_CARS_VINCULATED)}
                                    <span style={{ display: "block", fontWeight: 200, fontSize: 12 }}>
									{t(Labels.FLEET_LINK_CARS_FLEET)}{fleet.Nome}
                                    </span>
                                </span>
                            ),
                            columns: CARS_COLUMNS,
                            dataSource: notAvailableCarsFleets.data,
                            rowSelection: {
                                refColName: "Placa",
                                onChange: (selectedRows) => {
                                    console.log("selectedRows", selectedRows);
                                },
                            },
                            loading: loading.getObjectsFleetNotAvailable,
                            search: true,
                            pagination: {
                                current: notAvailableCarsFleets.index,
                                itemsPerPage: notAvailableCarsFleets.recordsFiltered,
                                totalItems: notAvailableCarsFleets.recordsTotal,
                            },
                            onChange: handlerSearchPagingLinked,
                            backgroundColor: auth.user.colors.topBarColor,
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default FleetsLinkCars;
