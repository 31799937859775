const initialState = {
    canRenew: false,
    items: [],
    contact: {},
};

const SET_CAN_RENEW = "SET_CAN_RENEW";
const ADD_ITEMS = "ADD_ITEMS";
const SET_CONTACT = "SET_CONTACT";

const renewalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CAN_RENEW:
            return {
                ...state,
                canRenew: action.payload,
            };

        case ADD_ITEMS:
            return {
                ...state,
                items: [...state.items, action.payload],
            };
        case SET_CONTACT:
            return {
                ...state,
                contact: action.payload,
            };

        default:
            return state;
    }
};

export default renewalReducer;
