import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import recoverAction from "~/actions/recover/recoverActions";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Box, Button, TextField, Typography, IconButton, InputAdornment } from "@material-ui/core";
import AlertMessage from "~/components/common/alertModal/AlertModal";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Utils from "~/helpers/Utils";
import Constants from "~/helpers/enums/Constants";
import recoverActions from "~/actions/recover/recoverActions";

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

const FormRecover = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false); // New state for showing/hiding password
    const [openModal, setOpenModal] = useState(false);
    const [msg, setMsg] = useState("");
    const [touched, setTouched] = useState(false);
    const { t } = useTranslation();
    const [confirmPassword, setConfirmPassword] = useState("");
    const [touchedConfirm, setTouchedConfirm] = useState(false);
    const isPasswordValid = () => passwordRegex.test(password);
    const isConfirmationValid = () => confirmPassword === password;

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const hasErrorConfirm = () => {
        return confirmPassword !== password;
    };
    const handleChangeConfirmPassword = (event) => {
        setConfirmPassword(event.target.value);
    };
    const errorMessageConfirm = () => {
        if ( confirmPassword !== password) {
            return "Senhas não conferem";
        }
    };

    const handleClose = () => {
        setOpenModal(false);
        setTouched(false);
    };

    const sendInfo = async () => {
        setTouched(true);
    
        if (!isPasswordValid() || !isConfirmationValid()) {
            return;
        }
    
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get("code");
        const username = searchParams.get("username");
    
        try {
            const response = await dispatch(
                recoverActions.confirmRecoverPassword(password, code, username)
            );
                Utils.showTranslatedToast({
                    type: Constants.SUCCESS,
                    description: "Senha alterada com sucesso!",
                });
    
                setTimeout(() => {
                    history.push("/login");
                }, 4000);
        } catch (error) {
            console.error("Error:", error);
            Utils.showError("Erro ao confirmar a recuperação de senha. Por favor, tente novamente.");
        }
    };

    const hasError = (field) => {
        if (field === "password") {
            return (!password && touched) || (!!password && !passwordRegex.test(password)) ? true : false;
        }
    };

    const errorMessage = () => {
        if (touched && !password) {
            return t(Labels.RECOVER_PASSWORD_REQUIRED);
        } else if (!!password && !passwordRegex.test(password)) {
            return t(Labels.RECOVER_PASSWORD_INVALID);
        } else {
            return "";
        }
    };
    const passwordRules = t(Labels.PROFILE_PERSONAL_INFORMATION_NEW_PASSWORD_RULES, { returnObjects: true }) || {};

    return (
        <Box className="boxFormRecover">
            <AlertMessage open={openModal} onClose={handleClose} message={msg} severity="info" />
            <Box className="text-form">
                <TextField
                    fullWidth
                    type={showPassword ? "text" : "password"} // Conditional rendering based on showPassword state
                    name="Senha"
                    className="margin-form"
                    label={t(Labels.PROFILE_PERSONAL_INFORMATION_NEW_PASSWORD)}
                    id="password"
                    onChange={handleChangePassword}
                    inputProps={{ maxLength: 150 }}
                    error={hasError("password")}
                    helperText={errorMessage()}
                    placeholder={t(Labels.PROFILE_PERSONAL_INFORMATION_NEW_PASSWORD)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleTogglePasswordVisibility}>
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            <TextField
                fullWidth
                type={showPassword ? "text" : "password"}
                className="margin-form"
                label={t(Labels.PROFILE_PERSONAL_INFORMATION_REPEAT_PASSWORD)}
                id="confirmPassword"
                onChange={handleChangeConfirmPassword}
                inputProps={{ maxLength: 150 }}
                error={hasErrorConfirm()}
                helperText={errorMessageConfirm()}
                placeholder={t(Labels.PROFILE_PERSONAL_INFORMATION_REPEAT_PASSWORD)}
            />
                <Button
                    color="primary"
                    className="btnSendForm"
                    fullWidth
                    variant="contained"
                    onClick={() => {
                        sendInfo();
                    }}
                    disabled={!isPasswordValid() || !isConfirmationValid()}>
                    {t(Labels.SAVE_LABEL)}
                </Button>
                <Box className="rules-box rules-password" style={{marginTop: '10px'}}>
                        <span className="rules-title">{passwordRules["title"]}</span>
                        {passwordRules["hints"].map((hint, i) => (
                            <Fragment key={i}>
                                <br />
                                <span className="hint"> - {hint}</span>{" "}
                            </Fragment>
                        ))}
                    </Box>
            </Box>
        </Box>
    );
};

export default FormRecover;
