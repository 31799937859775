import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import TableTab from "~/components/common/tableTab/TableTab";
import FleetEvolutionChart from "./FleetEvolutionChart";
import dashboardChartsAction from "~/actions/dashboard/dashboardChartsAction";
import LoadingPanel from "~/components/common/loading/LoadingPanel";
import "./FleetEvolution.scss";

const FleetEvolution = (props) => {
    const { t } = useTranslation();
    const loadingFleetEvolutionId = useMemo(() => "fleetEvolution", []);
    const loadingFleetEvolutionMonthlyId = useMemo(() => "fleetEvolutionMonthly", []);

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.app.loading);

    const { fleetSelected, subFleetSelected, rangeDate } = useSelector((state) => state.dashboardFilter);
    const { fleetEvolution, monthlyFleetEvolution } = useSelector((state) => state.dashboardCharts);
    const [activeLine, setActiveLine] = useState("Distancia");

    useEffect(() => {
        const filter = {
            grupoId: subFleetSelected?.id || fleetSelected?.id,
            ...rangeDate,
        };

        dispatch(dashboardChartsAction.getFleetEvolution(filter, loadingFleetEvolutionId));
        dispatch(dashboardChartsAction.getMonthlyFleetEvolution(filter, loadingFleetEvolutionMonthlyId));
    }, [dispatch, fleetSelected, subFleetSelected, rangeDate, loadingFleetEvolutionId, loadingFleetEvolutionMonthlyId]);

    const fleetEvolutionLabels = t(Labels.DASHBOARD_FLEET_EVOLUTION_CHARTS, { returnObjects: true });

    const values = useMemo(() => {
        return [
            "Distancia",
            "DistanciaComercial",
            "DistanciaNaoComercial",
            "DistanciaFimSemana",
            "QtdAceleracaoBrusca",
            "QtdCurvaAgressiva",
            "QtdFreadaBrusca",
            "QtdLimiteVelocidadeVia",
        ].map((key) => {
            const percent = fleetEvolution[key];
            return {
                key,
                metric: fleetEvolutionLabels[key]?.label,
                xlabel: fleetEvolutionLabels[key]?.xlabel,
                percent: (
                    <span className="percent-label">
                        {percent ? `${percent > 0 ? "↑" : "↓"} ${Math.abs(percent)}%` : "-"}
                    </span>
                ),
            };
        });
    }, [fleetEvolution, fleetEvolutionLabels]);

    const activeValue = values.find((x) => x.key === activeLine);

    return (
        <div>
            {loading[loadingFleetEvolutionId] && <LoadingPanel size={45} />}
            <Box className="fleet-evolution-box">
                <Box className="fleet-evolution-tab">
                    <TableTab
                        value={activeLine}
                        onItemClick={(key) => {
                            setActiveLine(key);
                        }}
                        columns={[
                            {
                                title: t(Labels.DASHBOARD_FLEET_EVOLUTION_TAB_METRIC),
                                key: "metric",
                            },
                            {
                                title: t(Labels.DASHBOARD_FLEET_EVOLUTION_TAB_PERCENT),
                                key: "percent",
                            },
                        ]}
                        rows={values}
                    />
                </Box>

                <Box className="fleet-evolution-chart">
                    <Box className="fleet-evolution-title">
                        <Typography variant="h4">{t(Labels.DASHBOARD_FLEET_EVOLUTION_TAB_MONTHLY)}</Typography>
                    </Box>
                    <FleetEvolutionChart
                        title={activeValue.metric}
                        xlabel={activeValue.xlabel}
                        loading={loading[loadingFleetEvolutionMonthlyId]}
                        data={monthlyFleetEvolution.map((m) => {
                            return {
                                label: `${m.Mes}/${m.Ano}`,
                                value: m[activeLine],
                            };
                        })}
                    />
                </Box>
            </Box>
        </div>
    );
};

export default FleetEvolution;
