import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { lighten } from "polished";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";

import Utils from "~/helpers/Utils";

import TableCard from "~/components/tableCard/TableCard";
import Loading from "~/components/common/loading/Loading";
import TableReUse from "~/components/table/TableReUse";

import fleetVehicleAction from "~/actions/fleetVehicleAction";
import googleMapsDataAction from "~/actions/googleMapsDataAction";
import useFuso from "~/components/common/fuso/useFuso";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

import "./CardFleet.scss";

const useStyles = makeStyles((theme) => ({
    cardItemGroup: {
        "&:hover": {
            background: lighten(0.5, theme.palette.primary.main),
        },
    },
    buttonBack: {
        margin: "0px 0 10px 0px",
        borderRadius: "2px",
        cursor: "pointer",
        outline: "none",
        border: `1px solid ${theme.palette.primary.main}`,
        background: "none",
        fontSize: "14px",
        color: theme.palette.primary.main,
        padding: "5px",
        "&:hover": {
            background: theme.palette.primary.main,
            color: "white",
        },
    },
}));

const sortWithOrder = (data, orderColumn, order) => {
    const sortedResult = data.sort((a, b) => {
        const comparison = typeof a[orderColumn] === "string" ? a[orderColumn].localeCompare(b[orderColumn]) : a[orderColumn] - b[orderColumn];
        return order === "asc" ? comparison : -comparison;
    });

    return sortedResult;
};

function CardFleet(props) {
    const dispatch = useDispatch();
    const fleet = useSelector((state) => state.fleet);
    const { filter, data } = useSelector((state) => state.fleetVehicle);
    const loading = useSelector((state) => state.app.loading);

    const [display, setDisplay] = useState(false);
    const [dataFleetFilter, setDataFleetFilter] = useState([]);
    const [dataFleetVehicleFilter, setDataVehicleFleetFilter] = useState([]);
    const [sort, setSort] = useState({field: undefined, order: undefined})
    const [orderObject, setOrderObject] = useState({
        order: "desc",
        orderBy: "text",
    });
    const { t } = useTranslation();

    const { fromUtc } = useFuso();

    const classes = useStyles();

    const getVehicleFleetID = (id) => {
        const query = {
            ...filter,
            id: id,
        };
        dispatch(fleetVehicleAction.fleetData({ ...query }));
        setDisplay(true);
    };

    const updateHeaderSortOrder = (event) => {
        setSort({
            field: event,
            order: event === sort.field ? sort.order === "desc" ? "asc" : "desc" : "desc"
        })
    }

    useEffect(() => {
        dataFleetVehicleFilter && dispatch(googleMapsDataAction.updateObjects(dataFleetVehicleFilter));
        return () => {
            dispatch(googleMapsDataAction.cleanObjects());
        };
    }, [dispatch, dataFleetVehicleFilter]);

    useEffect(() => {
        if (!display) {
            let result = Utils.filterData(props.data, fleet.filter.description);
            const sortedResult = sortWithOrder(result, orderObject.orderBy, orderObject.order);
            setDataFleetFilter(sortedResult);
        } else {
            let result = Utils.filterData(data, fleet.filter.description);
            const selectedHeader = props.columns.find((item) => item.label === sort.field)?.name;
            const sortedResult = sortWithOrder(result, selectedHeader, sort.order);
            setDataVehicleFleetFilter(sortedResult);
        }
    }, [fleet.filter.description, display, props.data, data, orderObject, sort, props.columns]);

    return display ? (
        <List id={"list-fleet"}>
            <button
                id={"button-fleet-back"}
                className={classes.buttonBack}
                onClick={() => {
                    setDisplay(false);
                }}>
                Voltar
            </button>
            {loading.fleetData ? (
                <Loading />
            ) : (
                <TableCard
                    id={"card-item-fleet"}
                    mode={props.mode}
                    filters={props.columns}
                    data={dataFleetVehicleFilter.map((obj) => {
                        return {
                            ...obj,
                            DataHoraEventoLocal: obj.DataHoraEventoLocal
                                ? fromUtc(obj.DataHoraEventoLocal, "YYYY-MM-DDTHH:mm:ss").format(
                                      "DD/MM/YYYY | HH:mm:ss"
                                  )
                                : "-",
                            Velocidade: Utils.formatSpeed(obj.Velocidade)
                        }
                    })}
                    updateAction={googleMapsDataAction.updateObjects}
                    cleanAction={googleMapsDataAction.cleanObjects}
                    onItemClick={(element) => {
                        dispatch(
                            googleMapsDataAction.centralizePoints([
                                {
                                    lat: element.Latitude,
                                    lng: element.Longitude,
                                },
                            ])
                        );
                    }}
                    onHeaderClick={updateHeaderSortOrder}
                    selectedLabel={sort.field}
                    order={sort.order}
                />
            )}
        </List>
    ) : (
        <>
            <TableReUse
                rowSelection={{}}
                className={"table-map-fleet"}
                columns={[
                    {
                        label: t(Labels.MAP_TAB_FLEETS_TABLE_CODE),
                        name: "id",
                    },
                    {
                        label: t(Labels.MAP_TAB_FLEETS_TABLE_NAME),
                        name: "text",
                    },
                ]}
                onSelect={(item) => {
                    getVehicleFleetID(item.id);
                }}
                dataSource={dataFleetFilter}
                onSort={(orderObject, col, data) => {
                    setOrderObject(orderObject);
                }}
                orderObject={orderObject}
            />
        </>
    );
}

export default CardFleet;
