import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import dashboardChartsAction from "~/actions/dashboard/dashboardChartsAction";
import TopTenTable from "~/components/common/topTenTable/TopTenTable";
import LoadingPanel from "~/components/common/loading/LoadingPanel";
import Avatar from "@material-ui/core/Avatar";

const TopTenMaxVelocityDriver = (props) => {
    const { t } = useTranslation();
    const loadingId = useMemo(() => "topTenMaxVelocityDriver", []);
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.app.loading);

    const { fleetSelected, subFleetSelected, rangeDate } = useSelector((state) => state.dashboardFilter);
    const { topTenMaxVelocityDriver } = useSelector((state) => state.dashboardCharts);

    useEffect(() => {
        const filter = {
            grupoId: subFleetSelected?.id || fleetSelected?.id,
            ...rangeDate,
        };

        dispatch(dashboardChartsAction.getTopTenMaxVelocityDriver(filter, loadingId));
    }, [dispatch, fleetSelected, subFleetSelected, rangeDate, loadingId]);

    return (
        <>
            {loading[loadingId] && <LoadingPanel size={45} />}
            <TopTenTable
                columns={[
                    {
                        title: "",
                        key: "avatar",
                        width: "7%",
                    },
                    {
                        title: t(Labels.DASHBOARD_TOP_TEN_MAX_VELOCITY_DRIVER_DRIVER),
                        key: "driver",
                        width: "40%",
                    },
                    {
                        title: t(Labels.DASHBOARD_TOP_TEN_MAX_VELOCITY_DRIVER_MAX_VELOCITY),
                        key: "max_velocity",
                        width: "50%",
                        borded: true,
                    },
                ]}
                rows={topTenMaxVelocityDriver.map((driver) => {
                    const url = driver.UrlImagem ? driver.UrlImagem.trim() : null;
                    return {
                        driver: driver.MotoristaNome,
                        max_velocity: `${driver.VelocidadeMaxima} Km/h`,
                        avatar: <Avatar src={url} />,
                    };
                })}
            />
        </>
    );
};

export default TopTenMaxVelocityDriver;
