import http from "~/config/httpRequest/http";

//http://apicps-solutis.ceabsservicos.com.br/PortalGestaoFrotas/api/Pesquisa/BuscarFrota?quantidade=10&descricao=

// funcionalidade=&acessorioInstaladoNome=&quantidade=5&tipo=&retorno=&descricao=
const list = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Pesquisa/BuscarObjetosRastreaveis`, {
				params: {
					funcionalidadeId: filter?.functionality,
					acessorioInstaladoNome: filter?.acessory,
					quantidade: filter?.limit,
					tipo: filter?.type,
					descricao: filter?.description,
					retorno: filter?.return,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

export default { list };
