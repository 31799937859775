import React from "react";
import communicateActions from "~/actions/communicateActions";
import { Box, Divider } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import "./CommunicatedList.scss";
const CommunicatedList = () => {
	const {communicated} = useSelector((state) => state.communicate);
	const dispatch = useDispatch();
	const [detail, setDetail] = React.useState("");
	const [flagOpenDetail, setFlagOpenDetail] = React.useState(false);
	const [css, setChangeCss] = React.useState(0);

	const renderHtml = (htmlRe) => {
		return { __html: htmlRe };
	};

	const saveDetails = (detail, id) => {
		dispatch(communicateActions.checkNotification(detail.Id));
		setDetail(detail);
		setFlagOpenDetail(true);

		document.getElementById(`paperCommunicated-${css}`).className = "paperlistActive";
		document.getElementById(`paperCommunicated-${id}`).className = "paperlist";
		setChangeCss(id);
	};
	return (
		<>
			<Box style={{ display: "flex" }}>
				<Box style={{ display: "block !important", width: "45%", cursor: "pointer" }}>
					{communicated?.data?.map((data, i) => (
						<div
							id={`paperCommunicated-${i}`}
							className="paperlistActive"
							onClick={(e) => saveDetails(data, i)}
							key={i}>
							<div className="titleCommunicate">{data.Nome}</div>
							<Divider></Divider>
						</div>
					))}
				</Box>
				{flagOpenDetail ? (
					<Box style={{ marginLeft: "5px", width: "75%" }}>
						<Box className="paperlistDetail">
							<Box className="titleCommunicate">{detail.Nome}</Box>
							<Divider></Divider>
							<Box className="textDetail" dangerouslySetInnerHTML={renderHtml(detail.Descricao)} />
						</Box>
					</Box>
				) : null}
			</Box>
		</>
	);
};

export default CommunicatedList;
