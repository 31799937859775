import React from "react";
import { Grid } from "@material-ui/core";
import ListingVehicles from "~/components/vehicle/listing/ListingVehicles";

const VehicleMain = () => {
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<ListingVehicles />
			</Grid>
		</Grid>
	);
};

export default VehicleMain;
