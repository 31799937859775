import React from "react";

import TabMain from "~/components/common/tab/TabMain";
import TopDriver from "~/components/dashboard/topTen/topDriver/TopDriver";
import TopVehicles from "~/components/dashboard/topTen/topVehicles/TopVehicles";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";

import "./TabDriverVehicle.scss";

const TabDriverVechicle = ({ filter }) => {
    const { t } = useTranslation();

    const TABS = [
        {
            id: 0,
            label: t(Labels.TAB_VEHICLE),
            children: <TopVehicles filter={filter} />,
        },
        {
            id: 1,
            label: t(Labels.TAB_DRIVER),
            children: <TopDriver filter={filter} />,
        },
    ];

    return (
        <div className="cardTabTopTen">
            <TabMain id="TopTabDriverVehicle" tabs={TABS} className={"classMainTab"} />
        </div>
    );
};

export default TabDriverVechicle;
