import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import infoAction from "~/actions/infoAction";

const GoogleScriptLoader = (props) => {
	const dispatch = useDispatch();

	const { apiKey } = useSelector((state) => state.info);
	const [isScriptLoad, setScriptLoad] = useState(false);

	useEffect(() => {
		if (!apiKey) {
			dispatch(infoAction.getGoogleApiKey());
			return;
		}

		const componetId = "google-apis-script";
		let script = document.getElementById(componetId);

		if (!script) {
			script = document.createElement("script");
			script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=geometry,drawing,places,visualization`;
			script.id = componetId;

			document.body.appendChild(script);
		}

		script.addEventListener("load", () => {
			setScriptLoad(true);
		});

		if (!!window.google) {
			setScriptLoad(true);
		}
	}, [dispatch, apiKey]);

	return isScriptLoad ? <>{props.children}</> : null;
};

export default GoogleScriptLoader;
