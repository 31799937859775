import http from "~/config/httpRequest/http";

const downloadFile = () => {
	return new Promise((resolve, reject) => {
		http
			.get(`/PontoInteresseApi/ObterPlanilhaPontoInteresse`)
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const importFilePoints = (filter) => {
	return new Promise((resolve, reject) => {
		const formData = new FormData();
		formData.append("file", filter.file);

		http
			.post(`/PontoInteresseApi/ImportarPontoInteresse`, formData, {
				headers: {
					"Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

export default { downloadFile, importFilePoints };
