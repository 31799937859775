import Constants from "~/helpers/enums/Constants";

import fleetService from "~/services/fleetService";

const fleetData = (filter) => (dispatch) => {
	return fleetService
		.list(filter)
		.then((response) => {
			let data = response.data;

			let payload = {
				filter: filter,
				data: data,
			};

			dispatch({ type: Constants.FLEET_GET_DATA_FILTER, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {});
};

const fleetFilterData = (filter) => (dispatch) => {
	dispatch({ type: Constants.FLEET_DATA_FILTER, payload: { filter } });
};

export default {
	fleetData,
	fleetFilterData,
};
