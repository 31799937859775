import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import vehicleService from "~/services/vehicleService";


const getListVehicles = (filter, LOADING_IDENTIFICATOR = "getListVehicles") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return vehicleService
		.searchVehicleList(filter)
		.then((res) => {
			let payload = res;
			dispatch({ type: Constants.LIST_VEHICLES, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getVehicleById = (id, LOADING_IDENTIFICATOR = "getVehicleByID") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return vehicleService
		.getVehicleById(id)
		.then((res) => {
			let payload = res.data;
			dispatch({ type: Constants.GET_VEHICLE_BY_ID, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getOdometerById = (id, LOADING_IDENTIFICATOR= "getOdometerByID") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return vehicleService
		.getOdometerById(id)
		.then((res) => {
			let payload = res.data;
			dispatch({ type: Constants.GET_ODOMETER_BY_ID, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const updateVehicleFilter = (filter) => (dispatch) => {
	dispatch({ type: Constants.UPDATE_VEHICLE_FILTER, payload: filter });
};

const cleanVehicleList = () => (dispatch) => {
	dispatch ({ type: Constants.CLEAN_VEHICLE_LIST });
}

const updateEditForm = (context) => (dispatch) => {
	dispatch({ type: Constants.UPDATE_VEHICLE_EDIT_FORM, payload: context });
};

const updateOdometerForm = (context) => (dispatch) => {
	dispatch({ type: Constants.UPDATE_VEHICLE_ODOMETER_FORM, payload: context });
};

const saveVehicle = (vehicle, callback = () => {}, LOADING_IDENTIFICATOR = "vehicle") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return vehicleService
		.saveVehicle(vehicle)
		.then(() => {
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const saveOdometer = (odometer, callback = () => {}, LOADING_IDENTIFICATOR = "odometer") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return vehicleService
		.saveOdometer(odometer)
		.then(() => {
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

export default { getListVehicles,
	updateVehicleFilter,
	cleanVehicleList,
	getVehicleById,
	saveVehicle,
	updateEditForm,
	updateOdometerForm,
	getOdometerById,
	saveOdometer };