import Labels from "~/helpers/enums/Labels";

const KM_RUN = {
	name: "kmRun",
	label: Labels.DASHBOARD_KM_RUN_TITLE,
};

const KM_RUN_PERIOD = {
	name: "kmRunPeriod",
	label: Labels.DASHBOARD_KM_RUN_PERIOD_TITLE
};


const KM_RUN_PERIOD_DAY = {
	name: "kmRunPeriodDay",
	label: Labels.DASHBOARD_KM_RUN_PERIOD_DAY_TITLE
};

const PERIOD_TRIPS = {
	name: "periodTrips",
	label: Labels.DASHBOARD_PERIOD_TRIPS_TITLE,
};

const ROAD_OFFSET = {
	name: "roadOffset",
	label: Labels.DASHBOARD_ROAD_URBAN_OFFSET_TITLE,
};

const TOP_TEN_DRIVER_VEHICLE = {
	name: "topTenDriverVehicle",
	label: Labels.DASHBOARD_TOP_TEN_DRIVER_VEHICLE_TITLE,
};

const TOP_TEN_MAX_VELOCITY_VEHICLE = {
	name: "topTenMaxVelocityVehicle",
	label: Labels.DASHBOARD_TOP_TEN_MAX_VELOCITY_VEHICLE_CARD_TITLE,
};

const TOP_TEN_MAX_VELOCITY_DRIVER = {
	name: "topTenMaxVelocityDriver",
	label: Labels.DASHBOARD_TOP_TEN_MAX_VELOCITY_DRIVER_CARD_TITLE,
};

const ANALYSIS_ITEMS_TITLE = {
	name: "analysisItems",
	label: Labels.DASHBOARD_ANALYSIS_ITEMS_TITLE,
};

const TOP_TEN_ANALYZE_DRIVER_VEHICLE = {
	name: "topTenAnalyzeDriverVehicle",
	label: Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TITLE,
};

const DRIVABILITY_SCORE = {
	name: "drivabilityScore",
	label: Labels.DASHBOARD_DRIVABILITY_SCORE_TITLE,
};

const RISK_EXPOSURE_SCORE = {
	name: "riskExposureScore",
	label: Labels.DASHBOARD_RISK_EXPOSURE_SCORE_TITLE,
	extraLabel: Labels.DASHBOARD_COMPOSITION_TITLE
};

const FLEET_EVOLUTION = {
	name: "fleetEvolution",
	label: Labels.DASHBOARD_FLEET_EVOLUTION_TITLE,
};

const FLEET_EVOLUTION_PERCENT = {
	name: "fleetEvolutionPercent",
	label: Labels.DASHBOARD_FLEET_EVOLUTION_PERCENT_VEHICLE_TITLE,
};

const ACCIDENT_PROBABILITY = {
	name: "accidentProbability",
	label: Labels.DASHBOARD_ACCIDENT_PROBABILITY_TITLE,
};

const HEATMAP = {
	name: "heatmap",
	label: Labels.DASHBOARD_VEHICLE_HEATMAP,
};

const BASE_COMPARATIVE_ITEMS = {
	name: "baseComparativeItems",
	label: Labels.DASHBOARD_BASE_COMPARATIVE_ITEMS
};

const BASE_COMPARATIVE = {
	name: "baseComparative",
	label: Labels.DASHBOARD_BASE_COMPARATIVE_KM_RUN
}


export default {
	KM_RUN,
	PERIOD_TRIPS,
	FLEET_EVOLUTION_PERCENT,
	ROAD_OFFSET,
	KM_RUN_PERIOD,
	KM_RUN_PERIOD_DAY,
	TOP_TEN_DRIVER_VEHICLE,
	TOP_TEN_MAX_VELOCITY_VEHICLE,
	TOP_TEN_MAX_VELOCITY_DRIVER,
	ANALYSIS_ITEMS_TITLE,
	TOP_TEN_ANALYZE_DRIVER_VEHICLE,
	DRIVABILITY_SCORE,
	RISK_EXPOSURE_SCORE,
	FLEET_EVOLUTION,
	ACCIDENT_PROBABILITY,
	HEATMAP,
	BASE_COMPARATIVE,
	BASE_COMPARATIVE_ITEMS
};
