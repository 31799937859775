import React from "react";

import { withRouter } from "react-router";
import { Button, Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ComeBackIcon } from "~/components/common/icons/Icons";
import Labels from "~/helpers/enums/Labels";

import Page from "~/components/common/page/Page";
import AlertBond from "~/components/alert/alertBond/AlertBond";

const AlertBondView = () => {
	const { t } = useTranslation();
	let history = useHistory();
	const comeBack = () => {
		history.push("/register/alerts");
	};
	return (
		<Page
			bottomStyle={{ justifyContent: "flex-end" }}
			bottomButtons={
				<Button
					className="comeback-button"
					id="extractReport"
					variant="contained"
					size="small"
					disableElevation
					startIcon={<ComeBackIcon />}
					onClick={() => {
						comeBack();
					}}>
					{t(Labels.GROUP_DRIVER_COMEBACK)}
				</Button>
			}>
			<main>
				<Container maxWidth="lg" className="container-map-view">
					<AlertBond />
				</Container>
			</main>
		</Page>
	);
};

export default withRouter(AlertBondView);
