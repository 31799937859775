import React from "react";

import { Grid, Box } from "@material-ui/core";

import RegisterFleets from "~/components/fleets/register/RegisterFleets";
import FinderFleets from "~/components/fleets/finder/FinderFleets";

const FleetsMain = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box className="card-container">
                    <RegisterFleets />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box className="card-container">
                    <FinderFleets />
                </Box>
            </Grid>
        </Grid>
    );
};

export default FleetsMain;
