import Constants from "~/helpers/enums/Constants";

const initialState = {
	menu: [
		{
			name: "Identificacao",
			label: "Placa",
			checked: true,
		},
		{
			name: "Localizacao",
			label: "Localização",
			checked: true,
		},
		{
			name: "PavimentoTipo",
			label: "Pavimento",
			checked: true,
		},
		{
			name: "Distancia",
			label: "Distância",
			checked: true,
		},
		{
			name: "MotoristaNome",
			label: "Motorista",
			checked: true,
		},
	],
	filter: {
		index: 0,
		limit: 10,
		term: "",
		longitude: 0,
		latitude: 0,
	},
	data: { Data: [] },
};

function addressReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.ADDRESS_GET_DATA:
			return {
				...state,
				data: action.payload.data,
			};
		case Constants.ADDRESS_DATA_FILTER:
			return {
				...state,
				filter: {
					...state.filter,
					...action.payload.filter,
				},
			};
		default:
			return state;
	}
}

export default addressReducer;
