import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
    dataDriversTop: [],
    dataVehiclesTop: [],
    dataAnalyzeVehiclesTop: [],
    dataAnalyzeDriversTop: [],
};

function dashboardTopTenReducer(state = INITIAL_STATE, action = null) {
    switch (action.type) {
        case Constants.GET_KM_RUN_VEHICLE:
        case Constants.GET_KM_RUN_COMMERCIAL_VEHICLE:
        case Constants.GET_KM_RUN_NOT_COMMERCIAL_VEHICLE:
        case Constants.GET_KM_RUN_WEEKEND_VEHICLE:
            return {
                ...state,
                dataVehiclesTop: action.payload,
            };
        /* driver */
        case Constants.GET_KM_RUN_DRIVER:
        case Constants.GET_KM_RUN_COMMERCIAL_DRIVER:
        case Constants.GET_KM_RUN_NOT_COMMERCIAL_DRIVER:
        case Constants.GET_KM_RUN_WEEKEND_DRIVER:
            return {
                ...state,
                dataDriversTop: action.payload,
            };
        /* analyze vehicles */
        case Constants.GET_KM_RUN_ANALYZE_SPEEDING_VEHICLE:
        case Constants.GET_KM_RUN_ANALYZE_BRUSQUE_ACCELARATION_VEHICLE:
        case Constants.GET_KM_RUN_ANALYZE_DISPLACEMENT_NOT_COMMERCIAL_VEHICLE:
        case Constants.GET_KM_RUN_ANALYZE_DISPLACEMENT_WEEKEND_VEHICLE:
        case Constants.GET_KM_RUN_ANALYZE_BRUSQUE_CURVE_VEHICLE:
        case Constants.GET_KM_RUN_ANALYZE_BRUSQUE_BRAKED_VEHICLE:
            return {
                ...state,
                dataAnalyzeVehiclesTop: action.payload,
            };
        /* analyze drivers */
        case Constants.GET_KM_RUN_ANALYZE_SPEEDING_DRIVER:
        case Constants.GET_KM_RUN_ANALYZE_BRUSQUE_ACCELARATION_DRIVER:
        case Constants.GET_KM_RUN_ANALYZE_DISPLACEMENT_NOT_COMMERCIAL_DRIVER:
        case Constants.GET_KM_RUN_ANALYZE_DISPLACEMENT_WEEKEND_DRIVER:
        case Constants.GET_KM_RUN_ANALYZE_BRUSQUE_CURVE_DRIVER:
        case Constants.GET_KM_RUN_ANALYZE_BRUSQUE_BRAKED_DRIVER:
            return {
                ...state,
                dataAnalyzeDriversTop: action.payload,
            };
        default:
            return state;
    }
}

export default dashboardTopTenReducer;
