import React from "react"
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import { Typography } from "@material-ui/core";


const AlertPopup = ({nomeAlerta, condicao, placa}) => {
    const { t } = useTranslation();

    return (<>
        <Typography variant="h5">{t(Labels.ALERT_POPUP_TITLE)}</Typography>
        <Typography>{placa} - {nomeAlerta}</Typography>
        <Typography>{condicao}</Typography>
    </>)
}

export default AlertPopup