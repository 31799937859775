import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

import exportDocumentAction from "~/actions/exportDocumentAction";

import { Container, Button, Grid, Box, Typography } from "@material-ui/core";

import Page from "~/components/common/page/Page";
import FinderPoints from "~/components/pointsInterest/finder/FinderPoints";
import { AddIcon } from "~/components/common/icons/Icons";

import OptionExportButton from "~/components/common/exportButton/OptionExportButton";

import "./FinderPointsView.scss";

const FinderPointsView = (props) => {
    const dispatch = useDispatch();

    let history = useHistory();
    const { t } = useTranslation();

    const loading = useSelector((state) => state.app.loading);
    const { pagination } = useSelector((state) => state.pointsInterest.pointInterestList);

    const openForm = () => {
        history.push("/register/pointsInterest/");
    };

    const openFormImport = () => {
        history.push("/register/pointsInterest/pointsImport");
    };

    return (
        <Page
            topButtons={
                <OptionExportButton
                    loading={loading.requestObjectDoc}
                    onClick={(type) => {
                        dispatch(
                            exportDocumentAction.extractDocumentPoints({
                                format: type.value.toLowerCase(),
                                search: pagination.search,
                            })
                        );
                    }}
                />
            }>
            <main>
                <Container maxWidth="lg" className="container-map-view">
                    <Grid container>
                        <Grid item xs={12}>
                            <div id="finder-points-view-buttons">
                                <Box>
                                    <Button
                                        id="add-button-driver"
                                        className="btn-add-group-items"
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        style={{ textTransform: "none !important" }}
                                        onClick={openForm}>
                                        <AddIcon />
                                        <Typography>
                                            {t(Labels.REGISTER_POINTS_INTEREST_BUTTON_ADD)}
                                        </Typography>
                                    </Button>
                                </Box>
                                <Box style={{ marginLeft: "30px" }}>
                                    <Button
                                        id="button-import"
                                        className="btn-add-group-items"
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        style={{ textTransform: "none !important" }}
                                        onClick={openFormImport}>
                                        <AddIcon />
                                        <Typography >
                                            {t(Labels.REGISTER_POINTS_INTEREST_BUTTON_IMPORT)}
                                        </Typography>
                                    </Button>
                                </Box>
                            </div>
                            <FinderPoints />
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </Page>
    );
};

export default FinderPointsView;
