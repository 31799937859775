export const DIARIO = {
	value: 1,
	description: "Diário",
};

export const SEMANAL = {
	value: 7,
	description: "Semanal",
};

export const QUINZENAL = {
	value: 15,
	description: "Quinzenal",
};

export const MENSAL = {
	value: 30,
	description: "Mensal",
};

export const LIST = [DIARIO, SEMANAL, QUINZENAL, MENSAL];

export default { DIARIO, SEMANAL, QUINZENAL, MENSAL, LIST };
