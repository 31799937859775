import React from "react";
import MapFilter from "./MapFilter";
import GoogleMaps from "./GoogleMaps";
import "./GoogleMaps.scss";

const MapCard = (props) => {
	return (
		<div className={`google-maps-card ${props.className}`}>
			<MapFilter />
			<GoogleMaps />
		</div>
	);
};

export default MapCard;
