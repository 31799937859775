import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import { Table, TableHead, TableCell, TableRow, TableBody } from "@material-ui/core";
import Loading from "~/components/common/loading/LoadingPanel";
import "./EvolutionFleet.scss";

const EvolutionFleet = () => {
    const { t } = useTranslation();
    const { evolutionFleet } = useSelector((state) => state.dashboardVehicleTravels);
    const { waitingTravel } = useSelector((state) => state.app.loading);

    const fleetEvolutionLabels = t(Labels.DASHBOARD_FLEET_EVOLUTION_PERCENT_CHARTS, { returnObjects: true });

    const values = useMemo(() => {
        return [
            "DistanciaEvolucaoPercentual",
            "DistanciaFimSemanaEvolucaoPercentual",
            "DistanciaComercialEvolucaoPercentual",
            "DistanciaNaoComercialEvolucaoPercentual",
            "QtdAceleracaoBruscaPercentualEvolucao",
            "QtdCurvaAgressivaPercentualEvolucao",
            "QtdFreadaBruscaPercentualEvolucao",
            "QtdLimiteVelocidadeViaPercentualEvolucao",
        ].map((key) => {
            const percent = evolutionFleet[key];
            return {
                key,
                metric: fleetEvolutionLabels[key]?.label,
                xlabel: fleetEvolutionLabels[key]?.xlabel,
                percent: (
                    <span className="percent-label">
                        {percent ? `${percent > 0 ? "+" : "-"} ${Math.abs(percent)}%` : "-"}
                    </span>
                ),
            };
        });
    }, [evolutionFleet, fleetEvolutionLabels]);

    return (
        <>
            {waitingTravel && (
                <div className="loadingGridData">
                    <Loading size={40} />
                </div>
            )}
            {(waitingTravel || (!waitingTravel && evolutionFleet)) && (
                <Table aria-label="simple table" className="evoltuion-fleet-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t(Labels.DASHBOARD_FLEET_EVOLUTION_PERCENT_TAB_METRIC)}</TableCell>
                            <TableCell align="center">
                                {t(Labels.DASHBOARD_FLEET_EVOLUTION_PERCENT_TAB_PERCENT)}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {values.map((row) => (
                            <TableRow key={row.metric}>
                                <TableCell scope="row">
                                    {row.metric}
                                </TableCell>
                                <TableCell align="center" style={{ borderLeft: "1px solid #e6e6e6" }}>
                                    <span style={{ color: "green", fontWeight: "bold" }}>{row.percent}</span>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </>
    );
};

export default EvolutionFleet;
