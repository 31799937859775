import React, { useEffect, useRef } from "react";
import { Form, Formik } from "formik";
import { Button, Grid, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import reportingAction from "~/actions/reportingAction";
import { SaveReportIcon } from "~/components/common/icons/Icons";
import AutoCompleteFleet from "~/components/reporting/common/fleet/AutoCompleteFleet";
import ReportPeriodField from "~/components/reporting/common/ReportPeriodField";
import VehicleAutocomplete from "~/components/reporting/common/vehicle/VehicleAutocomplete";
import distanceReportAction from "~/actions/reporting/distanceReportAction";
import RegisterDayOfWeek from "~/components/common/registerInputs/RegisterDayOfWeek";
import Labels from "~/helpers/enums/Labels";
import Utils from "~/helpers/Utils";
import Input from "~/components/reporting/common/input/Input";
import "./FilterPeriod.scss";

const fixDaysWeek = (diasDaSemana) => {
    /* TODO: ajustar a lógica de todos os componentes que passam dia das semanas para considerar 0123456 ao invés de 1234567,
    caso seja confirmado que essa é a forma correta de passar os dias em todas as telas do sistema. Ao menos para esse relatório,
    essa função foi criada com o objetivo de subtrair 1 de todos os valores de dia que forem passados do número construído a partir
    dos dias, pois foi confirmado que para ele é necessário passar o ENUM dessa forma.
    */
    const arrayDiasNumber = !Number.isNaN(diasDaSemana) ? diasDaSemana.toString().split("").reduce(((a, item) => a + (Number.parseInt(item) - 1).toString()), "") : diasDaSemana;
    return arrayDiasNumber;
}

const FilterPeriod = () => {
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const periodForm = useRef();
    const { requestPeriod } = useSelector((state) => state.app.loading);

    useEffect(() => {
        dispatch(distanceReportAction.updatePeriodForm(periodForm.current));
    }, [dispatch, periodForm]);

    return (
        <Formik
            innerRef={periodForm}
            initialValues={{
                fleet: null,
                period: Utils.getTodayPeriod(),
                vehicle: null,
                DiasSemana: 1234567,
                email: "",
                limit: 25,
                index: 0,
                typeReport: 30,
            }}
            validate={(values) => {
                const errors = {};

                if (!values.vehicle && !values.fleet) {
                    errors.vehicle = t(Labels.REPORT_EVENT_VEHICLE_MSG_OBLIGATED_WITHOUT_OR);
                    errors.fleet = t(Labels.REPORT_EVENT_FLEET_MSG_OBLIGATED);
                }

                if (!values.period) {
                    errors.period = t(Labels.REPORT_EVENT_PERIOD_MSG_OBLIGATED);
                }

                if (!values.email) {
                    errors.email = t(Labels.REPORT_EVENT_EMAIL_MSG_OBLIGATED);
                } else if (!Utils.isValidEmail(values.email)) {
                    errors.email = t(Labels.REPORT_EVENT_EMAIL_MSG_NOT_VALID);
                }

                fixDaysWeek(values.DiasSemana);
                return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                const val = {
                    GrupoId: values?.fleet?.id,
                    DataFinal: Utils.utcToLocal(values.period.endDate),
                    DataInicial: Utils.utcToLocal(values.period.startDate),
                    DiasSemana: fixDaysWeek(values.DiasSemana).toString(),
                    ObjetoRastreavelId: values?.vehicle?.id,
                    Email: values.email,
                };
                setSubmitting(true);

                dispatch(
                    reportingAction.generateReportingPerPeriod(val, (res) => {
                        setSubmitting(false);
                        resetForm();
                        dispatch(
                            reportingAction.getReportingPerPeriod({
                                relatorioId: 3,
                                Start: 0,
                                Index: 0,
                                Length: 10,
                            })
                        );
                    })
                );
            }}>
            {({ submitForm, setFieldValue }) => (
                <Form>
                    <Grid container justify="right" alignItems="flex-end" spacing={1}>
                        <Grid item xs={2}>
                            <VehicleAutocomplete
                                id="vehicle-Combo"
                                name="vehicle"
                                label={t(Labels.REPORT_EVENT_VEHICLE_LABEL)}
                                placeholder={t(Labels.REPORT_EVENT_VEHICLE_PLACEHOLDER)}
                                onChange={() => {
                                    setFieldValue("fleet", null);
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <AutoCompleteFleet
                                id="fleet-Combo"
                                name="fleet"
                                label={t(Labels.REPORT_LABEL_FLEET)}
                                placeholder={t(Labels.REPORT_LABEL_PLACEHOLDER)}
                                onChange={() => {
                                    setFieldValue("vehicle", null);
                                }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <ReportPeriodField
                                dontShowMonthRanges={true}
                                id="id-period-date"
                                label={t(Labels.REPORT_EVENT_PERIOD_LABEL)}
                                name="period"
                                hours
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <RegisterDayOfWeek
                                formType="report"
                                label={t(Labels.REPORT_DISTANCE_DAYS_OF_WEEKS)}
                                name="DiasSemana"
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <Input
                                name="email"
                                placeholder={t(Labels.REPORT_EVENT_EMAIL_PLACEHOLDER)}
                                label={t(Labels.REPORT_EVENT_EMAIL_LABEL)}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <Button
                                id="travel-filter-object-save-button"
                                className="report-save-button report-save-button-refactor"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disableElevation
                                disabled={requestPeriod}
                                startIcon={
                                    requestPeriod ? (
                                        <CircularProgress
                                            style={{ height: 14, width: 14, marginRight: 8 }}
                                            color={"#fff"}
                                        />
                                    ) : (
                                        <SaveReportIcon />
                                    )
                                }
                                onClick={submitForm}>
                                {t(Labels.REPORT_EVENT_SAVE_BUTTON)}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default FilterPeriod;
