import React from "react";
import Page from "~/components/common/page/Page";
import ExampleToolbar from "~/components/example/ExampleToolbar"
import Example from "~/components/example/Example"


const ExampleView = () => {
	return (
		<>
			<Page bar={<ExampleToolbar />}>
				<Example />
			</Page>
		</>
	);
};

export default ExampleView;
