import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import FormikAutocomplete from "~/components/common/autocomplete/FormikAutocomplete";
import vehicleSearchAction from "~/actions/vehicleSearchAction";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { Box, Typography } from "@material-ui/core";
import { Field } from "formik";
import Labels from "~/helpers/enums/Labels";
import "~/components/reporting/Reporting.scss";
import "~/components/reporting/common/ReportingCommon.scss";

const VehicleAutocomplete = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const data = useSelector((state) => state.vehicleSearch);
	const { loading } = useSelector((state) => state.app);

	return (
		<Box className="report-field">
			<Typography variant="subtitle1" className="report-field-label">
				{props.label}
			</Typography>
			<Field
				id={props.id}
				name={props.name}
				getOptionLabel={(option) => option.text || ""}
				getOptionSelected={(option, value) => option.text === value.text}
				component={FormikAutocomplete}
				onChange={props.onChange}
				onInputChange={(_, value) => {
					dispatch(vehicleSearchAction.vehicleData({ description: value }));
				}}
				options={data ? data.dataVehicle : []}
				noOptionsText={t(Labels.NO_DATA)}
				loading={loading.vehicleDataSearch}
				popupIcon={<ExpandMoreRoundedIcon viewBox="0 0 25 25" />}
				textFieldProps={{
					variant: "outlined",
					fullWidth: true,
					margin: "dense",
					InputProps: {
						placeholder: props.placeholder,
					},
				}}
			/>
		</Box>
	);
};

export default VehicleAutocomplete;
