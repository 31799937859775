import React from "react";

import fleetsActions from "~/actions/fleets/fleetsActions";

import { useSelector, useDispatch } from "react-redux";

import { Container, Grid } from "@material-ui/core";

import Page from "~/components/common/page/Page";
import FleetsMain from "~/components/fleets/FleetsMain";
import OptionExportButton from "~/components/common/exportButton/OptionExportButton";

const FleetsView = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.app.loading);
    const { filter } = useSelector((state) => state.fleetsRegister);

    return (
        <>
            <Page
                topButtons={
                    <div id="export-container">
                        <OptionExportButton
                            loading={loading.requestObjectDoc}
                            onClick={(type) => {
                                dispatch(
                                    fleetsActions.exportFleet({
                                        status: filter.status,
                                        search: filter.search,
                                        format: type.value.toLowerCase(),
                                    })
                                );
                            }}
                        />
                    </div>
                }>
                <main>
                    <Container maxWidth="lg" className=" container-map-view">
                        <Grid container>
                            <Grid item xs={12}>
                                <FleetsMain />
                            </Grid>
                        </Grid>
                    </Container>
                </main>
            </Page>
        </>
    );
};

export default FleetsView;
