import Constants from "~/helpers/enums/Constants";

const initialState = {
  email: '',
  visibleRecover: false
};

function authReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.RECOVER_LOGIN:
			return {
				...state,
				email: action.payload.email,
      };
      case Constants.VISIBLE_RECOVER_LOGIN:
        return {
          ...state,
          visibleRecover: action.payload.visible,
        };
		default:
			return state;
	}
}

export default authReducer;
