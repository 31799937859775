import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Button, Grid, Box, Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import Page from "~/components/common/page/Page";
import Labels from "~/helpers/enums/Labels";
import { ComeBackIcon } from "~/components/common/icons/Icons";
import { useTranslation } from "react-i18next";
import RouteForm from "~/components/route/register/RouteForm";
import MapCard from "~/components/map/MapCard";
import Card from "~/components/common/expansionCard/Card";
import { RegisterTitleIcon, EditIcon } from "~/components/common/icons/Icons";
import routeAction from "~/actions/routeAction";

import "./RegisterRoutesView.scss";

const RegisterRoutesView = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { route } = useSelector((state) => state.route);
	const { id } = props.match.params;

	useEffect(() => {
		dispatch(routeAction.cleanRoute());
		if (id) dispatch(routeAction.getRoutesById(parseInt(id)));

		return () => {
			dispatch(routeAction.cleanRoute());
		};
	}, [dispatch, id]);

	const comeBack = () => {
		props.history.push("/register/routes");
	};

	return (
		<Page
			topButtons={
				<Button
					className="comeback-button"
					id="extractReport"
					variant="contained"
					size="small"
					disableElevation
					startIcon={<ComeBackIcon />}
					onClick={() => {
						comeBack();
					}}>
					{t(Labels.ROUTE_FORM_COMEBACK)}
				</Button>
			}>
			<main>
				<Container className="container-map-view">
					<Grid container id="register-routes-container" direction="row" alignItems="stretch" spacing={3}>
						<Grid item xs={12} md={12}>
							<Box className="register-title">
								<Typography>
									{!!id ? (
										<>
											<EditIcon />
											{t(Labels.ROUTE_FORM_EDIT_TITLE)}
										</>
									) : (
										<>
											<RegisterTitleIcon />
											{t(Labels.ROUTE_FORM_ADD_TITLE)}
										</>
									)}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} md={4}>
							{((id && route) || !id) && <RouteForm route={route} comeBack={comeBack} />}
						</Grid>

						<Grid item xs={12} md={8}>
							<Card title={t(Labels.ROUTE_MAP)} notPadded className="form-card map-card card-group-refactor">
								<MapCard className="map-card-refactor" />
							</Card>
						</Grid>
					</Grid>
				</Container>
			</main>
		</Page>
	);
};

export default withRouter(RegisterRoutesView);
