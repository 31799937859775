import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { FormGroup, FormControl, Grid, Slider, Typography, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardArrowDown as KeyboardArrowDownIcon, Close as CloseIcon } from "@material-ui/icons";
import Card from "~/components/common/expansionCard/Card";
import dashboardFiltersAction from "~/actions/dashboard/dashboardFiltersAction";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import "moment/locale/pt-br";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        margin: "0 15px",
    },
    svg: {
        width: 100,
        height: 100,
    },
    polygon: {
        fill: theme.palette.common.white,
        stroke: theme.palette.divider,
        strokeWidth: 1,
    },
    root: {
        display: "flex",
    },
    formControlCombo: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "end",
    },
    formControl: {
        width: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    combo: {
        width: "70%",
    },
}));

const CustomSlider = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        height: 10,
        width: "95%",
    },
    thumb: {
        height: 20,
        width: 20,
    },
    mark: {
        width: 6,
        height: "inherit",
        opacity: 0.5,
    },
    markActive: {
        backgroundColor: "inherit",
    },
    markLabel: {
        marginTop: 10,
        textTransform: "capitalize",
        fontSize: "14px",
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)",
        marginTop: 10,
    },
    track: {
        height: 10,
    },
    rail: {
        height: 10,
    },
}))(Slider);

const CustomAutoComplete = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        fontWeight: "bold",
        color: "white",
        borderRadius: 3,
    },
    inputRoot: {
        color: "white",
        fontSize: "14px",
    },
}))(Autocomplete);

const CustomArrow = withStyles({
    root: {
        color: "white",
        fontSize: 23,
    },
})(KeyboardArrowDownIcon);

const marks = [];

const values = [6, 5, 4, 3, 2, 1];

const initialValue = { id: "", text: "Todos os Veículos", extraValue: null };

for (let i = 5; i >= 0; i--) {
    const date = moment().subtract(i, "month");
    marks.push({ value: values[i], label: date.format("MMM|YY"), mes: date.month() + 1, ano: date.year() });
}

const DashboardFilter = (props) => {
    const { language } = useSelector((state) => state.language);
    const classes = useStyles();
    const dispatch = useDispatch();

    moment.locale(language);

    const { fleetList, subFleetList, fleetSelected, subFleetSelected, rangeDate } = useSelector(
        (state) => state.dashboardFilter
    );
    const { waitingFleets, waitingSubFleets } = useSelector((state) => state.app.loading);

    const [months, setMonths] = useState([5, 6]);

    useEffect(() => {
        if (_.isEmpty(rangeDate)) {
            const date = {
                ValueInicio: marks[4].value,
                MesInicio: marks[4].mes,
                AnoInicio: marks[4].ano,
                ValueFim: marks[5].value,
                MesFim: marks[5].mes,
                AnoFim: marks[5].ano,
            };
            dispatch(dashboardFiltersAction.setRangeDate(date));
        } else {
            setMonths([rangeDate.ValueInicio, rangeDate.ValueFim]);
        }

        if (_.isEmpty(fleetSelected)) {
            dispatch(dashboardFiltersAction.setFleet(initialValue));
        } else {
            dispatch(dashboardFiltersAction.setFleet(fleetSelected));
            dispatch(dashboardFiltersAction.getSubFleets(fleetSelected?.id, "waitingSubFleets"));
            if (!_.isEmpty(subFleetSelected)) {
                dispatch(dashboardFiltersAction.setSubFleet(subFleetSelected));
            }
        }
    }, [dispatch, rangeDate, fleetSelected, subFleetSelected]);

    useEffect(() => {
        dispatch(dashboardFiltersAction.getFleets("waitingFleets"));

        dispatch(dashboardFiltersAction.setFleet(initialValue));
    }, [dispatch]);

    useEffect(() => {
        const _newDate = new Date();

        const date = {
            ValueInicio: marks[5].value,
            MesInicio: _newDate.getMonth() + 1,
            AnoInicio: _newDate.getFullYear(),
            ValueFim: marks[5].value,
            MesFim: _newDate.getMonth() + 1,
            AnoFim: _newDate.getFullYear(),
        };

        dispatch(dashboardFiltersAction.setRangeDate(date));
    }, [dispatch]);

    return (
        <div className={classes.container}>
            <Typography
                style={{ fontSize: "20px", padding: 0, marginBottom: "-10px" }}
                variant="subtitle1"
                className="report-field-label">
                Filtros
            </Typography>

            <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                    <Card style={{ margin: "15px 0" }}>
                        <Grid item xs={12} style={{ display: "flex" }}>
                            <Grid item xs={5}>
                                <FormControl variant="outlined" className={classes.formControlCombo}>
                                    <Typography
                                        style={{ width: 75, fontSize: "16px" }}
                                        variant="subtitle1"
                                        className="report-field-label">
                                        Frota:
                                    </Typography>
                                    <CustomAutoComplete
                                        id="combo-box-demo"
                                        className={classes.combo}
                                        options={fleetList || []}
                                        openText="Abrir"
                                        clearText="Limpar"
                                        closeText="Fechar"
                                        loadingText="Carregando..."
                                        noOptionsText="Nenhum Registro"
                                        loading={waitingFleets}
                                        onChange={(event, newValue, reason) => {
                                            if (reason === "clear") {
                                                dispatch(dashboardFiltersAction.clearSubFleets());
                                            }
                                    
                                            dispatch(dashboardFiltersAction.setFleet(newValue || {}));
                                            dispatch(dashboardFiltersAction.clearSelectedSubFleet());
                                            newValue && dispatch(dashboardFiltersAction.getSubFleets(newValue.id, "waitingSubFleets"));
                                        }}
                                        closeIcon={<CloseIcon fontSize="small" style={{ color: "white" }} />}
                                        popupIcon={<CustomArrow />}
                                        value={_.isEmpty(fleetSelected) ? initialValue : fleetSelected}
                                        getOptionLabel={(option) => option.text}
                                        renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={6}>
                                <CustomSlider
                                    aria-label="pretto slider"
                                    step={1}
                                    marks={marks}
                                    value={months}
                                    onChange={(event, newValue) => {
                                        if (_.isEqual(newValue, months)) return;

                                        const date = {
                                            ValueInicio: marks[newValue[0] - 1].value,
                                            MesInicio: marks[newValue[0] - 1].mes,
                                            AnoInicio: marks[newValue[0] - 1].ano,
                                            ValueFim: marks[newValue[1] - 1].value,
                                            MesFim: marks[newValue[1] - 1].mes,
                                            AnoFim: marks[newValue[1] - 1].ano,
                                        };

                                        dispatch(dashboardFiltersAction.setRangeDate(date));
                                        setMonths(newValue);
                                    }}
                                    aria-labelledby="range-slider"
                                    min={1}
                                    max={6}
                                />
                            </Grid>
                        </Grid>
                        {subFleetList?.length > 0 && (
                            <Grid item xs={12} style={{ marginTop: 15 }}>
                                <Grid item xs={5}>
                                    <FormControl variant="outlined" className={classes.formControlCombo}>
                                        <Typography
                                            style={{ width: 75 }}
                                            variant="subtitle1"
                                            className="report-field-label">
                                            Sub frota:
                                        </Typography>
                                        <CustomAutoComplete
                                            id="combo-box-demo"
                                            className={classes.combo}
                                            options={subFleetList}
                                            clearText="Limpar"
                                            loading={waitingSubFleets}
                                            loadingText="Carregando..."
                                            onChange={(event, newValue, reason) => {
                                                if (reason === "clear") {
                                                    dispatch(dashboardFiltersAction.clearSelectedSubFleet());
                                                }
                                        
                                                if (newValue) {
                                                    dispatch(dashboardFiltersAction.setSubFleet(newValue));
                                                }
                                            }}
                                            closeIcon={<CloseIcon fontSize="small" style={{ color: "white" }} />}
                                            popupIcon={<CustomArrow />}
                                            getOptionLabel={(option) => option.text}
                                            value={_.isEmpty(subFleetSelected) ? "" : subFleetSelected}
                                            noOptionsText="Sem opções"
                                            renderInput={(params) => (
                                                <TextField {...params} label="" variant="outlined" />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )}
                    </Card>
                </FormGroup>
            </FormControl>
        </div>
    );
};

export default DashboardFilter;
