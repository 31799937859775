import { createStore } from "redux";

import { persistStore, persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";

import reducers from "~/reducers";
import middlewares from "./middlewares";

const persistConfig = {
	key: "PGF",
	storage,
	whitelist: ["auth", "mapToolbar", "mapFilterData", "dashboardFilter", "dashboardToolbar", "dashboardVehicleFilter", "dashboardVehicleToolbar"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer, middlewares);
const persistor = persistStore(store);

export { store, persistor };
