import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { Box, Button, ButtonGroup, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import GridData from "~/components/common/gridData/GridData";

import "./boxTab.scss";

const useStyles = makeStyles((theme) => ({
    btnSelected: {
        background: theme.palette.primary.main,

        "&:hover": {
            backgroundColor: `${theme.palette.primary.main} !important`,
            opacity: 0.9,
        },

        "& .btnFont": {
            color: "#FFF !important",
        },
    },
    arrowSelected: {
        position: "absolute",
        left: -20,
        width: 0,
        height: 0,
        border: `10px solid ${theme.palette.primary.main}`,
        borderTopColor: "transparent",
        borderLeftColor: "transparent",
        borderBottomColor: "transparent",
    },
}));

const BoxTab = ({ children, listButton, data, columns, loading, defaultColumn, className }) => {
    const { t } = useTranslation();

    const [typeColumn, setColumnType] = useState(defaultColumn);

    const setBackgroundRow = (row, style) => {
        if (typeColumn === row) {
            return style.btnSelected;
        }
    };

    const classes = useStyles();
    return (
        <Box className={`boxTab ${className}`}>
            <Grid container spacing={4}>
                <Grid className="gridChildrenBoxTab" item xs={7}>
                    {children ? (
                        children
                    ) : (
                        <GridData data={data} columns={columns} typeColumn={typeColumn} loading={loading} />
                    )}
                </Grid>
                <Grid item xs={5}>
                    <ButtonGroup orientation="vertical" color="primary" variant="text" fullWidth>
                        {listButton.map((btn, i) => (
                            <Button
                                key={i}
                                className={`btnKm ${setBackgroundRow(btn.column, classes)}`}
                                onClick={() => {
                                    setColumnType(btn.column);
                                    btn.onClick();
                                }}>
                                <span className={typeColumn === btn.column ? classes.arrowSelected : ""} />
                                <Typography className="btnFont">{t(btn.label)}</Typography>
                            </Button>
                        ))}
                    </ButtonGroup>
                </Grid>
            </Grid>
        </Box>
    );
};

export default BoxTab;
