import { Button, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import reportingAction from "~/actions/reportingAction";
import { SaveReportIcon } from "~/components/common/icons/Icons";
import AutoCompleteFleet from "~/components/reporting/common/fleet/AutoCompleteFleet";
import ReportPeriodField from "~/components/reporting/common/ReportPeriodField";
import VehicleAutocomplete from "~/components/reporting/common/vehicle/VehicleAutocomplete";
import distanceReportAction from "~/actions/reporting/distanceReportAction";
import RegisterDayOfWeek from "~/components/common/registerInputs/RegisterDayOfWeek";
import Labels from "~/helpers/enums/Labels";
import Utils from "~/helpers/Utils";
import "./FilterObject.scss";

const FilterObject = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        callDoc,
        perObject: { filters },
    } = useSelector((state) => state.distanceReport);

    const objectForm = useRef();

    useEffect(() => {
        dispatch(distanceReportAction.updateObjectForm(objectForm.current));
    }, [dispatch, objectForm]);

    useEffect(() => {
        const val = {
            ...objectForm?.current.values,
            DiasSemana: Utils.getDaysArrayFromNumber(objectForm?.current?.values?.DiasSemana),
            format: callDoc.format === "xlsx" ? "xls" : callDoc.format,
            period: {
                endDate: Utils.utcToLocal(objectForm?.current.values.period?.endDate),
                startDate: Utils.utcToLocal(objectForm?.current.values.period?.startDate),
            },
        };
        dispatch(reportingAction.getReportingPerObject(val));
    }, [dispatch, callDoc]);

    useEffect(() => {        
        dispatch(reportingAction.getReportingPerObject(filters));
    }, [filters, dispatch]);

    return (
        <Formik
            innerRef={objectForm}
            initialValues={{
                fleet: null,
                period: Utils.getTodayPeriod(),
                vehicle: null,
                DiasSemana: 1234567,
                limit: 25,
                index: 0,
                typeReport: 30,
            }}
            validate={(values) => {
                const errors = {};

                if (!values.vehicle && !values.fleet) {
                    errors.vehicle = t(Labels.REPORT_EVENT_VEHICLE_MSG_OBLIGATED_WITHOUT_OR);
                    errors.fleet = t(Labels.REPORT_EVENT_FLEET_MSG_OBLIGATED);
                }

                if (!values.period) {
                    errors.period = t(Labels.REPORT_EVENT_PERIOD_MSG_OBLIGATED);
                }

                if (values.period && Utils.diffInDays(values.period.endDate, values.period.startDate) > 7) {
                    errors.period = t(Labels.REPORT_EVENT_PERIOD_MSG_PERIOD_WEEK);
                }

                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                dispatch(
                    distanceReportAction.updateFilterDistanceObject({
                        ...values,
                        period: {
                            endDate: Utils.utcToLocal(values.period.endDate),
                            startDate: Utils.utcToLocal(values.period.startDate),
                        },
                        DiasSemana: Utils.getDaysArrayFromNumber(values.DiasSemana),
                        index: 0,
                    })
                );

                setSubmitting(false);
            }}>
            {({ submitForm, setFieldValue }) => (
                <Form>
                    <Grid container justify="right" alignItems="flex-end" spacing={1}>
                        <Grid item xs={3}>
                            <VehicleAutocomplete
                                id="vehicle-Combo"
                                name="vehicle"
                                label={t(Labels.REPORT_EVENT_VEHICLE_LABEL)}
                                placeholder={t(Labels.REPORT_EVENT_VEHICLE_PLACEHOLDER)}
                                onChange={() => {
                                    setFieldValue("fleet", null);
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <AutoCompleteFleet
                                id="fleet-Combo"
                                name="fleet"
                                label={t(Labels.REPORT_LABEL_FLEET)}
                                placeholder={t(Labels.REPORT_LABEL_PLACEHOLDER)}
                                onChange={() => {
                                    setFieldValue("vehicle", null);
                                }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <ReportPeriodField
                                dontShowMonthRanges={true}
                                id="id-period-date"
                                label={t(Labels.REPORT_EVENT_PERIOD_LABEL)}
                                name="period"
                                hours
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <RegisterDayOfWeek
                                formType="report"
                                label={t(Labels.REPORT_DISTANCE_DAYS_OF_WEEKS)}
                                name="DiasSemana"
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <Button
                                id="travel-filter-object-save-button"
                                className="report-save-button report-save-button-refactor"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disableElevation
                                startIcon={<SaveReportIcon />}
                                onClick={submitForm}>
                                {t(Labels.REPORT_EVENT_SAVE_BUTTON)}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default FilterObject;
