import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Utils from "~/helpers/Utils";
import SchedulingFilter from "~/components/common/schedulingReport/SchedulingFilter";
import speedingReportAction from "~/actions/reporting/speedingReportAction";
import "~/components/reporting/Reporting.scss";

const SpeedingFilterScheduled = (props) => {
    const dispatch = useDispatch();

    const { edition } = useSelector((state) => state.speedingReport.scheduling);

    const onFormRefUpdate = useCallback(
        (_scheduledForm) => {
            dispatch(speedingReportAction.updateScheduleForm(_scheduledForm.current));
        },
        [dispatch]
    );

    return (
        <SchedulingFilter
            edition={edition}
            onFormRefUpdate={onFormRefUpdate}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                dispatch(
                    speedingReportAction.saveSpeedingReportScheduling(
                        {
                            Id: values.Id,
                            PeriodicidadeDia: values.PeriodicidadeDia,
                            Formato: values.Formato,
                            Email: values.Email,
                            DataInicio: moment(values.DataInicio).format("DD/MM/YYYY"),
                            ObjetoRastreavelId: parseInt(values.ObjetoRastreavel.id),
                        },
                        (error, res) => {
                            setSubmitting(false);

                            if (error) {
                                Utils.showAllErrors(error);
                                return;
                            }

                            resetForm();

                            dispatch(speedingReportAction.getSpeedingReportScheduling({}));
                            dispatch(speedingReportAction.getSpeedingReportSent({}));
                            dispatch(speedingReportAction.finishEditionSpeedingReportScheduling());
                        }
                    )
                );
            }}
        />
    );
};

export default SpeedingFilterScheduled;
