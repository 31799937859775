import Constants from "~/helpers/enums/Constants";

const toggleMarkedFilter = (name, checked) => (dispatch) => {
	dispatch({ type: Constants.DASHBOARD_VEHICLE_TOGGLE_MARKED_FILTER, name, checked });
};

const hideAllFilters = () => (dispatch) => {
	dispatch({ type: Constants.DASHBOARD_VEHICLE_HIDE_ALL_FILTERS });
}

const showCurrentFilters = () => (dispatch) => {
	dispatch({ type: Constants.DASHBOARD_VEHICLE_SHOW_CURRENT_FILTERS });
}

const setRangeDateAndId = (rangeDate, ObjetoRastreavelId) => (dispatch) => {
	dispatch({
		type: Constants.SET_RANGE_DATE_AND_ID,
		payload: {
			rangeDate,
			ObjetoRastreavelId
		},
	});
};

const resetRangeDate = () => (dispatch) => {
	dispatch({ type: Constants.RESET_DASHBOARD_VEHICLE_RANGE_DATE });
}

export default {
	toggleMarkedFilter,
	setRangeDateAndId,
	resetRangeDate,
	hideAllFilters,
	showCurrentFilters
};
