import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "~/components/common/expansionCard/Card";
import { Grid, Box, Button, CircularProgress } from "@material-ui/core";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { CancelIcon, ConfirmIcon } from "~/components/common/icons/Icons";
import Labels from "~/helpers/enums/Labels";
import Utils from "~/helpers/Utils";
import Constants from "~/helpers/enums/Constants";
import pointsInterestAction from "~/actions/pointsInterestAction";
import RegisterInputText from "~/components/common/registerInputs/RegisterInputText";
import RegisterSelect from "~/components/common/registerInputs/RegisterSelect";
import RegisterRadio from "~/components/common/registerInputs/RegisterRadio";
import RegisterPlacesAutocomplete from "~/components/common/registerInputs/RegisterPlacesAutocomplete";
import PointsInterestSyncMap from "./PointsInterestSyncMap";
import "./PointsInterestForm.scss";

const PointsInterestForm = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { loading } = useSelector((state) => state.app);
	const { pointInterestTypes } = useSelector((state) => state.pointsInterest);
	const { syncFuncs } = useSelector((state) => state.googleMapsData);

	const hasPointInterest = !!props.pointInterest;

	let pointInterest = {
		TipoPonto: "Ponto",
		Nome: "",
		PontoInteresseTipoId: "",
		Endereco: {},
		Latitude: "",
		Longitude: "",
		Raio: 0,
		Coordenadas: "",
	};

	if (hasPointInterest) {
		pointInterest = {
			...pointInterest,
			...props.pointInterest,
			Raio: props.pointInterest.Raio || "",
			Endereco: {
				sugestion: {
					description: props.pointInterest.Endereco || "",
				},
			},
		};
	}

	useEffect(() => {
		dispatch(pointsInterestAction.getPointInterestTypes());
	}, [dispatch]);

	return (
		<Card className={"form-card"}>
			<Formik
				initialValues={{
					...pointInterest,
				}}
				validate={(values) => {
					const errors = {};

					if (!values.Nome) {
						errors.Nome = t(Labels.REGISTER_POINT_INTEREST_FORM_NAME_MSG_REQUIRED);
					}

					if (!values.PontoInteresseTipoId) {
						errors.PontoInteresseTipoId = t(Labels.REGISTER_POINT_INTEREST_FORM_TYPE_MSG_REQUIRED);
					}

					if (values.TipoPonto === "Ponto") {
						if (!values.Latitude) {
							errors.Latitude = t(Labels.REGISTER_POINT_INTEREST_FORM_LATITUDE_MSG_REQUIRED);
						}

						if (!values.Longitude) {
							errors.Longitude = t(Labels.REGISTER_POINT_INTEREST_FORM_LONGITUDE_MSG_REQUIRED);
						}

						if (!values.Raio) {
							errors.Raio = t(Labels.REGISTER_POINT_INTEREST_FORM_RADIUS_MSG_REQUIRED);
						}
					} else {
						if (!values.Coordenadas) {
							errors.Coordenadas = t(Labels.REGISTER_POINT_INTEREST_FORM_COORDINATES_MSG_REQUIRED);
						}
					}

					return errors;
				}}
				onSubmit={(values, { setSubmitting }) => {
					let { Endereco, ...newInterestPoint } = values;

					newInterestPoint = {
						...newInterestPoint,
						Endereco: (Endereco.sugestion && Endereco.sugestion.description) || null,
						PontoInteresseTipoId: newInterestPoint.PontoInteresseTipoId || null,
					};

					if (newInterestPoint.TipoPonto === "Ponto") {
						newInterestPoint = {
							...newInterestPoint,
							Coordenadas: null,
						};
					} else if (newInterestPoint.TipoPonto === "Poligono") {
						newInterestPoint = {
							...newInterestPoint,
							Latitude: null,
							Longitude: null,
							Raio: null,
						};
					}

					dispatch(
						pointsInterestAction.savePointInterest(newInterestPoint, (error) => {
							setSubmitting(false);
							if (error) {
								Utils.showError(error);
								return;
							}

							Utils.showTranslatedToast({
								type: Constants.SUCCESS,
								description: Labels.REGISTER_POINT_INTEREST_FORM_MSG_SUCCESS,
							});

							props.comeback();
						})
					);
				}}>
				{({ values, submitForm, setFieldValue, errors, touched }) => (
					<Form className="ceabs-register-form ceabs-register-point-interest">
						<PointsInterestSyncMap values={values} setFieldValue={setFieldValue} name="points" />

						<Grid container direction="row" alignItems="stretch" spacing={0}>
							<Grid item xs={12}>
								<RegisterRadio
									label={t(Labels.REGISTER_POINT_INTEREST_FORM_FORM_TYPE)}
									name="TipoPonto"
									options={[
										{
											value: "Ponto",
											label: t(Labels.REGISTER_POINT_INTEREST_FORM_FORM_TYPE_POINT),
										},
										{
											value: "Poligono",
											label: t(Labels.REGISTER_POINT_INTEREST_FORM_FORM_TYPE_FENCE),
										},
									]}
								/>
							</Grid>

							<Grid item xs={12}>
								<RegisterInputText label={t(Labels.REGISTER_POINT_INTEREST_FORM_NAME)} name="Nome" />
							</Grid>

							<Grid id="point-interest-autocomplete" item xs={12}>
								<RegisterSelect
									errors={errors}
									touched={touched}
									label={t(Labels.REGISTER_POINT_INTEREST_FORM_TYPE)}
									name="PontoInteresseTipoId"
									loading={loading.pointInterest}
									options={pointInterestTypes.map((pit) => {
										return {
											value: pit.Id,
											label: pit.Nome,
										};
									})}
								/>
							</Grid>

							<Grid item xs={12}>
								<RegisterPlacesAutocomplete
									margin="dense"
									label={t(Labels.REGISTER_POINT_INTEREST_FORM_ADDRESS)}
									name="Endereco"
									onChange={({ latlng: { lat, lng }, sugestion }) => {
										if (values["TipoPonto"] === "Ponto") {
											setFieldValue("Latitude", lat || "");
											setFieldValue("Longitude", lng || "");
										}

										syncFuncs["points"] && syncFuncs["points"]({ latlng: { lat, lng }, sugestion });
									}}
								/>
							</Grid>

							{values["TipoPonto"] === "Ponto" ? (
								<>
									<Grid item xs={12}>
										<RegisterInputText
											disabled
											label={t(Labels.REGISTER_POINT_INTEREST_FORM_LATITUDE)}
											name="Latitude"
										/>
									</Grid>
									<Grid item xs={12}>
										<RegisterInputText
											disabled
											label={t(Labels.REGISTER_POINT_INTEREST_FORM_LONGITUDE)}
											name="Longitude"
										/>
									</Grid>
									<Grid item xs={12}>
										<RegisterInputText
											type="number"
											label={t(Labels.REGISTER_POINT_INTEREST_FORM_RADIUS)}
											name="Raio"
										/>
									</Grid>
								</>
							) : (
								<Grid item xs={12}>
									<RegisterInputText
										disabled
										label={t(Labels.REGISTER_POINT_INTEREST_FORM_COORDINATES)}
										name="Coordenadas"
									/>
								</Grid>
							)}

							<Grid item xs={12}>
								<Grid container direction="row" justify="flex-end" spacing={0}>
									<Grid item xs={4}>
										<Box className="button-form button-form-refactor">
											<Button
												data-id="point-interest-cancel-button"
												className="cancel-button cancel-button-refactor"
												variant="contained"
												size="large"
												disableElevation
												fullWidth
												onClick={() => {
													props.comeback();
												}}
												disabled={loading.pointInterest}>
												<CancelIcon />

												{t(Labels.REGISTER_POINT_INTEREST_FORM_BUTTON_CANCEL)}
											</Button>
										</Box>
									</Grid>
									<Grid item xs={8}>
										<Box className="button-form button-form-refactor">
											<Button
												id="point-interest-submit-button"
												variant="contained"
												className="save-button save-button-refactor"
												color="primary"
												size="large"
												fullWidth
												disableElevation
												onClick={submitForm}
												disabled={loading.pointInterest}
												startIcon={loading.pointInterest ? <CircularProgress size={18} /> : <ConfirmIcon />}>
												{t(Labels.REGISTER_POINT_INTEREST_FORM_BUTTON_CONFIRM)}
											</Button>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Card>
	);
};

export default PointsInterestForm;
