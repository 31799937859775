import http from "~/config/httpRequest/http";

const getKmRunVehicles = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10VeiculoKmRodados`, {
        params: {
          GrupoId: filter.GroupID,
          Operation: "SUM",
          OrderBy: "DESC",
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
          IsRfg: false,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

const getKmRunCommercialVehicles = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10VeiculoKmRodadosComercial`, {
        params: {
          GrupoId: filter.GroupID,
          Operation: "SUM",
          OrderBy: "DESC",
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
          IsRfg: false,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

const getKmRunNotCommercialVehicles = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10VeiculoKmRodadosNaoComercial`, {
        params: {
          GrupoId: filter.GroupID,
          Operation: "SUM",
          OrderBy: "DESC",
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
          IsRfg: false,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

const getKmRunWeekendVehicles = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10VeiculoKmRodadosFimSemana`, {
        params: {
          GrupoId: filter.GroupID,
          Operation: "SUM",
          OrderBy: "DESC",
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
          IsRfg: false,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};
/* DRIVERS */
const getKmRunDrivers = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10MotoristasKmRodados`, {
        params: {
          GrupoId: filter.GroupID,
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

const getKmRunCommercialDrivers = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10MotoristasKmRodadosHC`, {
        params: {
          GrupoId: filter.GroupID,
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

const getKmRunNotCommercialDrivers = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10MotoristasKmRodadosFHC`, {
        params: {
          GrupoId: filter.GroupID,
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
          IsRfg: false,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

const getKmRunWeekendDrivers = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10MotoristasKmRodadosFDS`, {
        params: {
          GrupoId: filter.GroupID,
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

/* analyze Vehicles */
const getKmRunAnalyzeExcessSpeedingVehicles = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10VeiculoExcessoVelocidade?`, {
        params: {
          GrupoId: filter.GroupID,
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

const getKmRunAnalyzeBrusqueAccelerationVehicles = (filter) => {

  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10VeiculoAceleracaoBrusca`, {
        params: {
          GrupoId: filter.GroupID,
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

const getKmRunAnalyzeBrusqueCurveVehicles = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10VeiculoCurvaBrusca`, {
        params: {
          GrupoId: filter.GroupID,
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

const getKmRunAnalyzeBrusqueBrakedVehicles = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10VeiculoFreadaBrusca`, {
        params: {
          GrupoId: filter.GroupID,
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

const getKmRunAnalyzeDisplacementNotCommercialVehicles = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10VeiculoDeslocamentoFHC`, {
        params: {
          GrupoId: filter.GroupID,
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

const getKmRunAnalyzeDisplacementWeekendVehicles = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10VeiculoDeslocamentoFDS`, {
        params: {
          GrupoId: filter.GroupID,
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

/* analyze Drivers */
const getKmRunAnalyzeExcessSpeedingDrivers = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10MotoristaExcessoVelocidade?`, {
        params: {
          GrupoId: filter.GroupID,
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

const getKmRunAnalyzeBrusqueAccelerationDrivers = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10MotoristaAceleracaoBrusca`, {
        params: {
          GrupoId: filter.GroupID,
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

const getKmRunAnalyzeBrusqueCurveDrivers = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10MotoristaCurvaBrusca`, {
        params: {
          GrupoId: filter.GroupID,
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

const getKmRunAnalyzeBrusqueBrakedDrivers = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10MotoristaFreadaBrusca`, {
        params: {
          GrupoId: filter.GroupID,
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

const getKmRunAnalyzeDisplacementNotCommercialDrivers = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10MotoristaDeslocamentoFHC`, {
        params: {
          GrupoId: filter.GroupID,
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

const getKmRunAnalyzeDisplacementWeekendDrivers = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/dashboard/BuscarTop10MotoristaDeslocamentoFDS`, {
        params: {
          GrupoId: filter.GroupID,
          MesInicio: filter.startMonth,
          MesFim: filter.endMonth,
          AnoInicio: filter.startYear,
          AnoFim: filter.endYear,
        },
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
};

export default {
  getKmRunVehicles,
  getKmRunCommercialVehicles,
  getKmRunNotCommercialVehicles,
  getKmRunWeekendVehicles,
  getKmRunDrivers,
  getKmRunCommercialDrivers,
  getKmRunNotCommercialDrivers,
  getKmRunWeekendDrivers,

  /* analyze vehicles */
  getKmRunAnalyzeExcessSpeedingVehicles,
  getKmRunAnalyzeBrusqueAccelerationVehicles,
  getKmRunAnalyzeBrusqueBrakedVehicles,
  getKmRunAnalyzeBrusqueCurveVehicles,
  getKmRunAnalyzeDisplacementNotCommercialVehicles,
  getKmRunAnalyzeDisplacementWeekendVehicles,

  /* analyze drivers */
  getKmRunAnalyzeExcessSpeedingDrivers,
  getKmRunAnalyzeBrusqueAccelerationDrivers,
  getKmRunAnalyzeBrusqueBrakedDrivers,
  getKmRunAnalyzeBrusqueCurveDrivers,
  getKmRunAnalyzeDisplacementNotCommercialDrivers,
  getKmRunAnalyzeDisplacementWeekendDrivers
};
