import http from "~/config/httpRequest/http";

const downloadFile = () => {
	return new Promise((resolve, reject) => {
		http
			.get(`/motoristaapi/ObterPlanilhaImportarMotorista`)
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const importFileDriver = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/motoristaapi/ImportarMotorista`, {
				GrupoMotoristaId: filter?.groupDriverID?.id,
				Documento: {
					LocalFilePath: null,
					FileName: filter?.file?.fileName,
					FileLength: filter?.file?.size,
					File: filter?.file?.fileData,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const listGroupDriver = (filter) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/Pesquisa/BuscarGrupoMotorista`, {
				params: {
					descricao: filter?.description,
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

export default { downloadFile, importFileDriver, listGroupDriver };
