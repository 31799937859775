import React from "react";
import { Typography } from '@material-ui/core';
import Utils from '~/helpers/Utils';
import "./buildTag.scss";
import BranchIcon from '~/assets/icons/branch.svg'

const BuildTag = () => {
	const info = Utils.getAppInfo();
	const fullVersion = `${info.version}${info.build ? `.${info.build}` : ''}`;
	const branch = info.branch ? <> <img src={BranchIcon} alt={info.branch} className={'app-info-badge-icon'} /> {info.branch} </> : '';

	return (
		<Typography variant='caption' component='span' className={'app-info-badge'}>
			<div className="app-info-badge-tooltip">
				v: {fullVersion}{branch}
				<span className="tooltiptext">
					{Object.keys(info).map((i, key) => <span key={key}>{i}: {info[i]} </span>)}
				</span>
			</div> 
		</Typography>);
};

export default BuildTag;
