import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router";
import { Grid } from "@material-ui/core";
import DualList from "~/components/common/dualList/DualList";
import alertActions from "~/actions/alertActions";

import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";

import "./AlertBond.scss";

const AlertBond = (props) => {

	const { t } = useTranslation();

	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { alertLinkedTrue, alertLinkedFalse, alert } = useSelector((state) => state.alert);
	const { id } = props.match.params;

	const GROUP_POINTS_COLUMNS = [
		{
			name: "Placa",
			label: t(Labels.COLUMN_PLATE),
			render: (nome) => <strong>{nome}</strong>,
		},
		{
			name: "Identificacao",
			label: t(Labels.COLUMN_IDENTIFICATION),
			render: (nome) => <strong>{nome}</strong>,
		},
		{
			name: "Marca",
			label: t(Labels.COLUMN_BRAND),
			render: (nome) => <strong>{nome}</strong>,
		},

		{
			name: "Modelo",
			label: t(Labels.COLUMN_MODEL),
			render: (nome) => <strong>{nome}</strong>,
		},
		{
			name: "DataHoraCadastro",
			label: t(Labels.COLUMN_DATE_REGISTER),
			render: (nome) => <strong>{nome != null ? moment(nome).format("DD/MM/YYYY | HH:mm:ss") : ""}</strong>,
		},
	];
	const GROUP_POINTS_COLUMNS_LINKED = [
		{
			name: "Placa",
			label: t(Labels.COLUMN_PLATE),
			render: (nome) => <strong>{nome}</strong>,
		},
		{
			name: "Identificacao",
			label: t(Labels.COLUMN_IDENTIFICATION),
			render: (nome) => <strong>{nome}</strong>,
		},
		{
			name: "Marca",
			label: t(Labels.COLUMN_BRAND),
			render: (nome) => <strong>{nome}</strong>,
		},

		{
			name: "Modelo",
			label: t(Labels.COLUMN_MODEL),
			render: (nome) => <strong>{nome}</strong>,
		},
	];
	const getIdOfselectedRows = (rows) => {
		return rows.map((r) => r.Id);
	};

	useEffect(() => {
		dispatch(
			alertActions.getAlertsLinkedTrue({
				grupoId: null,
				AlertaId: id,
				Vinculado: true,
				GridRequest: {
					Index: 0,
					Length: 10,
					Search: "",
				},
			})
		);
		dispatch(
			alertActions.getAlertsLinkedFalse({
				grupoId: null,
				AlertaId: id,
				Vinculado: false,
				GridRequest: {
					Index: 0,
					Length: 10,
					Search: "",
				},
			})
		);
		dispatch(alertActions.getListAlertsByID(id));
	}, [dispatch, id]);
	return (
		<div className="alert-bond">
			<Grid container justify="right" alignItems="flex-end" spacing={1}>
				<Grid item xs={12}>
					<DualList
						actionAdd={(selectedRows) => {
							const selectedRowsId = getIdOfselectedRows(selectedRows);
							dispatch(
								alertActions.postAlertsLinked({
									AlertaId: id,
									Vinculado: false,
									Selecionados: selectedRowsId,
								})
							);
						}}
						actionRemove={(selectedRows) => {
							const selectedRowsId = getIdOfselectedRows(selectedRows);
							dispatch(
								alertActions.postAlertsLinked({
									AlertaId: id,
									Vinculado: true,
									Selecionados: selectedRowsId,
								})
							);
						}}
						tableAvailable={{
							key: "Available",
							title: t(Labels.ALERT_LIST_AVAILABLE),
							columns: GROUP_POINTS_COLUMNS,
							dataSource: alertLinkedFalse.data,
							rowSelection: {
								refColName: "Id",
								onChange: (selectedRows) => {
									console.log("selectedRows", selectedRows);
								},
							},
							pagination: {
								current: alertLinkedFalse.index,
								totalItems:  alertLinkedFalse.recordsTotal,
							},
							onChange: (search, pagination) => {
								dispatch(
									alertActions.getAlertsLinkedFalse({
										grupoId: null,
										AlertaId: id,
										Vinculado: false,
										GridRequest: {
											Index: pagination.current - 1,
											Length: pagination.length,
											Search: search,
										},
									})
								);
							},
						}}
						tableLinked={{
							key: "Linked",
							title: `${t(Labels.ALERT_LIST_ALERT)}${alert?.Alerta ? `: ${alert.Alerta.Nome}` : ""}`,
							columns: GROUP_POINTS_COLUMNS_LINKED,
							dataSource: alertLinkedTrue.data,
							rowSelection: {
								refColName: "Id",
								onChange: (selectedRows) => {
									console.log("selectedRows", selectedRows);
								},
							},
							pagination: {
								current: alertLinkedTrue.index,
								totalItems:  alertLinkedTrue.recordsTotal,
							},
							onChange: (search, pagination) => {
								dispatch(
									alertActions.getAlertsLinkedTrue({
										grupoId: null,
										AlertaId: id,
										Vinculado: true,
										GridRequest: {
											Index: pagination.current - 1,
											Length: pagination.length,
											Search: search,
										},
									})
								);
							},
							backgroundColor: user.colors.topBarColor,
						}}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default withRouter(AlertBond);
