!(function (t, e) {
	"object" == typeof exports && "object" == typeof module
		? (module.exports = e())
		: "function" == typeof define && define.amd
		? define("MeasureTool", [], e)
		: "object" == typeof exports
		? (exports.MeasureTool = e())
		: (t.MeasureTool = e());
})(this, function () {
	return (function (t) {
		function e(i) {
			if (n[i]) return n[i].exports;
			var o = (n[i] = { i: i, l: !1, exports: {} });
			return t[i].call(o.exports, o, o.exports, e), (o.l = !0), o.exports;
		}
		var n = {};
		return (
			(e.m = t),
			(e.c = n),
			(e.i = function (t) {
				return t;
			}),
			(e.d = function (t, n, i) {
				e.o(t, n) || Object.defineProperty(t, n, { configurable: !1, enumerable: !0, get: i });
			}),
			(e.n = function (t) {
				var n =
					t && t.__esModule
						? function () {
								return t.default;
						  }
						: function () {
								return t;
						  };
				return e.d(n, "a", n), n;
			}),
			(e.o = function (t, e) {
				return Object.prototype.hasOwnProperty.call(t, e);
			}),
			(e.p = ""),
			e((e.s = 33))
		);
	})([
		function (t, e, n) {
			"use strict";
			function i(t, e) {
				(this._groups = t), (this._parents = e);
			}
			function o() {
				return new i([[document.documentElement]], N);
			}
			n.d(e, "c", function () {
				return N;
			}),
				(e.b = i);
			var r = n(70),
				a = n(71),
				s = n(58),
				u = n(52),
				c = n(18),
				l = n(57),
				h = n(61),
				f = n(63),
				p = n(66),
				d = n(73),
				g = n(49),
				v = n(65),
				_ = n(64),
				m = n(72),
				y = n(56),
				x = n(55),
				A = n(48),
				b = n(20),
				w = n(67),
				C = n(50),
				T = n(74),
				L = n(59),
				E = n(68),
				k = n(62),
				B = n(47),
				P = n(60),
				M = n(69),
				j = n(51),
				S = n(53),
				O = n(8),
				U = n(54),
				N = [null];
			(i.prototype = o.prototype = {
				constructor: i,
				select: r.a,
				selectAll: a.a,
				filter: s.a,
				data: u.a,
				enter: c.a,
				exit: l.a,
				join: h.a,
				merge: f.a,
				order: p.a,
				sort: d.a,
				call: g.a,
				nodes: v.a,
				node: _.a,
				size: m.a,
				empty: y.a,
				each: x.a,
				attr: A.a,
				style: b.b,
				property: w.a,
				classed: C.a,
				text: T.a,
				html: L.a,
				raise: E.a,
				lower: k.a,
				append: B.a,
				insert: P.a,
				remove: M.a,
				clone: j.a,
				datum: S.a,
				on: O.c,
				dispatch: U.a,
			}),
				(e.a = o);
		},
		function (t, e, n) {
			"use strict";
			Object.defineProperty(e, "__esModule", { value: !0 });
			var i = n(43);
			n.d(e, "create", function () {
				return i.a;
			});
			var o = n(2);
			n.d(e, "creator", function () {
				return o.a;
			});
			var r = n(44);
			n.d(e, "local", function () {
				return r.a;
			});
			var a = n(16);
			n.d(e, "matcher", function () {
				return a.a;
			});
			var s = n(45);
			n.d(e, "mouse", function () {
				return s.a;
			});
			var u = n(6);
			n.d(e, "namespace", function () {
				return u.a;
			});
			var c = n(7);
			n.d(e, "namespaces", function () {
				return c.a;
			});
			var l = n(3);
			n.d(e, "clientPoint", function () {
				return l.a;
			});
			var h = n(17);
			n.d(e, "select", function () {
				return h.a;
			});
			var f = n(46);
			n.d(e, "selectAll", function () {
				return f.a;
			});
			var p = n(0);
			n.d(e, "selection", function () {
				return p.a;
			});
			var d = n(9);
			n.d(e, "selector", function () {
				return d.a;
			});
			var g = n(21);
			n.d(e, "selectorAll", function () {
				return g.a;
			});
			var v = n(20);
			n.d(e, "style", function () {
				return v.a;
			});
			var _ = n(75);
			n.d(e, "touch", function () {
				return _.a;
			});
			var m = n(76);
			n.d(e, "touches", function () {
				return m.a;
			});
			var y = n(11);
			n.d(e, "window", function () {
				return y.a;
			});
			var x = n(8);
			n.d(e, "event", function () {
				return x.a;
			}),
				n.d(e, "customEvent", function () {
					return x.b;
				});
		},
		function (t, e, n) {
			"use strict";
			function i(t) {
				return function () {
					var e = this.ownerDocument,
						n = this.namespaceURI;
					return n === a.b && e.documentElement.namespaceURI === a.b ? e.createElement(t) : e.createElementNS(n, t);
				};
			}
			function o(t) {
				return function () {
					return this.ownerDocument.createElementNS(t.space, t.local);
				};
			}
			var r = n(6),
				a = n(7);
			e.a = function (t) {
				var e = n.i(r.a)(t);
				return (e.local ? o : i)(e);
			};
		},
		function (t, e, n) {
			"use strict";
			e.a = function (t, e) {
				var n = t.ownerSVGElement || t;
				if (n.createSVGPoint) {
					var i = n.createSVGPoint();
					return (i.x = e.clientX), (i.y = e.clientY), (i = i.matrixTransform(t.getScreenCTM().inverse())), [i.x, i.y];
				}
				var o = t.getBoundingClientRect();
				return [e.clientX - o.left - t.clientLeft, e.clientY - o.top - t.clientTop];
			};
		},
		function (t, e, n) {
			"use strict";
			Object.defineProperty(e, "__esModule", { value: !0 });
			e.Config = {
				prefix: "measure-tool",
				tooltipText1: "Drag to change, click to remove",
				tooltipText2: "Drag to change",
			};
		},
		function (t, e) {
			t.exports = function () {
				var t = [];
				return (
					(t.toString = function () {
						for (var t = [], e = 0; e < this.length; e++) {
							var n = this[e];
							n[2] ? t.push("@media " + n[2] + "{" + n[1] + "}") : t.push(n[1]);
						}
						return t.join("");
					}),
					(t.i = function (e, n) {
						"string" == typeof e && (e = [[null, e, ""]]);
						for (var i = {}, o = 0; o < this.length; o++) {
							var r = this[o][0];
							"number" == typeof r && (i[r] = !0);
						}
						for (o = 0; o < e.length; o++) {
							var a = e[o];
							("number" == typeof a[0] && i[a[0]]) ||
								(n && !a[2] ? (a[2] = n) : n && (a[2] = "(" + a[2] + ") and (" + n + ")"), t.push(a));
						}
					}),
					t
				);
			};
		},
		function (t, e, n) {
			"use strict";
			var i = n(7);
			e.a = function (t) {
				var e = (t += ""),
					n = e.indexOf(":");
				return (
					n >= 0 && "xmlns" !== (e = t.slice(0, n)) && (t = t.slice(n + 1)),
					i.a.hasOwnProperty(e) ? { space: i.a[e], local: t } : t
				);
			};
		},
		function (t, e, n) {
			"use strict";
			n.d(e, "b", function () {
				return i;
			});
			var i = "http://www.w3.org/1999/xhtml";
			e.a = {
				svg: "http://www.w3.org/2000/svg",
				xhtml: i,
				xlink: "http://www.w3.org/1999/xlink",
				xml: "http://www.w3.org/XML/1998/namespace",
				xmlns: "http://www.w3.org/2000/xmlns/",
			};
		},
		function (t, e, n) {
			"use strict";
			function i(t, e, n) {
				return (
					(t = o(t, e, n)),
					function (e) {
						var n = e.relatedTarget;
						(n && (n === this || 8 & n.compareDocumentPosition(this))) || t.call(this, e);
					}
				);
			}
			function o(t, e, n) {
				return function (i) {
					var o = l;
					l = i;
					try {
						t.call(this, this.__data__, e, n);
					} finally {
						l = o;
					}
				};
			}
			function r(t) {
				return t
					.trim()
					.split(/^|\s+/)
					.map(function (t) {
						var e = "",
							n = t.indexOf(".");
						return n >= 0 && ((e = t.slice(n + 1)), (t = t.slice(0, n))), { type: t, name: e };
					});
			}
			function a(t) {
				return function () {
					var e = this.__on;
					if (e) {
						for (var n, i = 0, o = -1, r = e.length; i < r; ++i)
							(n = e[i]),
								(t.type && n.type !== t.type) || n.name !== t.name
									? (e[++o] = n)
									: this.removeEventListener(n.type, n.listener, n.capture);
						++o ? (e.length = o) : delete this.__on;
					}
				};
			}
			function s(t, e, n) {
				var r = c.hasOwnProperty(t.type) ? i : o;
				return function (i, o, a) {
					var s,
						u = this.__on,
						c = r(e, o, a);
					if (u)
						for (var l = 0, h = u.length; l < h; ++l)
							if ((s = u[l]).type === t.type && s.name === t.name)
								return (
									this.removeEventListener(s.type, s.listener, s.capture),
									this.addEventListener(s.type, (s.listener = c), (s.capture = n)),
									void (s.value = e)
								);
					this.addEventListener(t.type, c, n),
						(s = { type: t.type, name: t.name, value: e, listener: c, capture: n }),
						u ? u.push(s) : (this.__on = [s]);
				};
			}
			function u(t, e, n, i) {
				var o = l;
				(t.sourceEvent = l), (l = t);
				try {
					return e.apply(n, i);
				} finally {
					l = o;
				}
			}
			n.d(e, "a", function () {
				return l;
			}),
				(e.b = u);
			var c = {},
				l = null;
			if ("undefined" != typeof document) {
				"onmouseenter" in document.documentElement || (c = { mouseenter: "mouseover", mouseleave: "mouseout" });
			}
			e.c = function (t, e, n) {
				var i,
					o,
					u = r(t + ""),
					c = u.length;
				{
					if (!(arguments.length < 2)) {
						for (l = e ? s : a, null == n && (n = !1), i = 0; i < c; ++i) this.each(l(u[i], e, n));
						return this;
					}
					var l = this.node().__on;
					if (l)
						for (var h, f = 0, p = l.length; f < p; ++f)
							for (i = 0, h = l[f]; i < c; ++i) if ((o = u[i]).type === h.type && o.name === h.name) return h.value;
				}
			};
		},
		function (t, e, n) {
			"use strict";
			function i() {}
			e.a = function (t) {
				return null == t
					? i
					: function () {
							return this.querySelector(t);
					  };
			};
		},
		function (t, e, n) {
			"use strict";
			var i = n(8);
			e.a = function () {
				for (var t, e = i.a; (t = e.sourceEvent); ) e = t;
				return e;
			};
		},
		function (t, e, n) {
			"use strict";
			e.a = function (t) {
				return (t.ownerDocument && t.ownerDocument.defaultView) || (t.document && t) || t.defaultView;
			};
		},
		function (t, e) {
			function n(t, e) {
				for (var n = 0; n < t.length; n++) {
					var i = t[n],
						o = f[i.id];
					if (o) {
						o.refs++;
						for (var r = 0; r < o.parts.length; r++) o.parts[r](i.parts[r]);
						for (; r < i.parts.length; r++) o.parts.push(u(i.parts[r], e));
					} else {
						for (var a = [], r = 0; r < i.parts.length; r++) a.push(u(i.parts[r], e));
						f[i.id] = { id: i.id, refs: 1, parts: a };
					}
				}
			}
			function i(t) {
				for (var e = [], n = {}, i = 0; i < t.length; i++) {
					var o = t[i],
						r = o[0],
						a = o[1],
						s = o[2],
						u = o[3],
						c = { css: a, media: s, sourceMap: u };
					n[r] ? n[r].parts.push(c) : e.push((n[r] = { id: r, parts: [c] }));
				}
				return e;
			}
			function o(t, e) {
				var n = g(),
					i = m[m.length - 1];
				if ("top" === t.insertAt)
					i ? (i.nextSibling ? n.insertBefore(e, i.nextSibling) : n.appendChild(e)) : n.insertBefore(e, n.firstChild),
						m.push(e);
				else {
					if ("bottom" !== t.insertAt)
						throw new Error("Invalid value for parameter 'insertAt'. Must be 'top' or 'bottom'.");
					n.appendChild(e);
				}
			}
			function r(t) {
				t.parentNode.removeChild(t);
				var e = m.indexOf(t);
				e >= 0 && m.splice(e, 1);
			}
			function a(t) {
				var e = document.createElement("style");
				return (e.type = "text/css"), o(t, e), e;
			}
			function s(t) {
				var e = document.createElement("link");
				return (e.rel = "stylesheet"), o(t, e), e;
			}
			function u(t, e) {
				var n, i, o;
				if (e.singleton) {
					var u = _++;
					(n = v || (v = a(e))), (i = c.bind(null, n, u, !1)), (o = c.bind(null, n, u, !0));
				} else
					t.sourceMap &&
					"function" == typeof URL &&
					"function" == typeof URL.createObjectURL &&
					"function" == typeof URL.revokeObjectURL &&
					"function" == typeof Blob &&
					"function" == typeof btoa
						? ((n = s(e)),
						  (i = h.bind(null, n)),
						  (o = function () {
								r(n), n.href && URL.revokeObjectURL(n.href);
						  }))
						: ((n = a(e)),
						  (i = l.bind(null, n)),
						  (o = function () {
								r(n);
						  }));
				return (
					i(t),
					function (e) {
						if (e) {
							if (e.css === t.css && e.media === t.media && e.sourceMap === t.sourceMap) return;
							i((t = e));
						} else o();
					}
				);
			}
			function c(t, e, n, i) {
				var o = n ? "" : i.css;
				if (t.styleSheet) t.styleSheet.cssText = y(e, o);
				else {
					var r = document.createTextNode(o),
						a = t.childNodes;
					a[e] && t.removeChild(a[e]), a.length ? t.insertBefore(r, a[e]) : t.appendChild(r);
				}
			}
			function l(t, e) {
				var n = e.css,
					i = e.media;
				if ((i && t.setAttribute("media", i), t.styleSheet)) t.styleSheet.cssText = n;
				else {
					for (; t.firstChild; ) t.removeChild(t.firstChild);
					t.appendChild(document.createTextNode(n));
				}
			}
			function h(t, e) {
				var n = e.css,
					i = e.sourceMap;
				i &&
					(n +=
						"\n/*# sourceMappingURL=data:application/json;base64," +
						btoa(unescape(encodeURIComponent(JSON.stringify(i)))) +
						" */");
				var o = new Blob([n], { type: "text/css" }),
					r = t.href;
				(t.href = URL.createObjectURL(o)), r && URL.revokeObjectURL(r);
			}
			var f = {},
				p = function (t) {
					var e;
					return function () {
						return void 0 === e && (e = t.apply(this, arguments)), e;
					};
				},
				d = p(function () {
					return /msie [6-9]\b/.test(self.navigator.userAgent.toLowerCase());
				}),
				g = p(function () {
					return document.head || document.getElementsByTagName("head")[0];
				}),
				v = null,
				_ = 0,
				m = [];
			t.exports = function (t, e) {
				if ("undefined" != typeof DEBUG && DEBUG && "object" != typeof document)
					throw new Error("The style-loader cannot be used in a non-browser environment");
				(e = e || {}), void 0 === e.singleton && (e.singleton = d()), void 0 === e.insertAt && (e.insertAt = "bottom");
				var o = i(t);
				return (
					n(o, e),
					function (t) {
						for (var r = [], a = 0; a < o.length; a++) {
							var s = o[a],
								u = f[s.id];
							u.refs--, r.push(u);
						}
						if (t) {
							n(i(t), e);
						}
						for (var a = 0; a < r.length; a++) {
							var u = r[a];
							if (0 === u.refs) {
								for (var c = 0; c < u.parts.length; c++) u.parts[c]();
								delete f[u.id];
							}
						}
					}
				);
			};
			var y = (function () {
				var t = [];
				return function (e, n) {
					return (t[e] = n), t.filter(Boolean).join("\n");
				};
			})();
		},
		function (t, e, n) {
			"use strict";
			Object.defineProperty(e, "__esModule", { value: !0 });
			e.UnitTypeId = { METRIC: "metric", IMPERIAL: "imperial", NAUTICAL: "nautical" };
		},
		function (t, e, n) {
			"use strict";
			function i(t, e) {
				var i = t.document.documentElement,
					a = n.i(o.select)(t).on("dragstart.drag", null);
				e &&
					(a.on("click.drag", r.a, !0),
					setTimeout(function () {
						a.on("click.drag", null);
					}, 0)),
					"onselectstart" in i
						? a.on("selectstart.drag", null)
						: ((i.style.MozUserSelect = i.__noselect), delete i.__noselect);
			}
			e.b = i;
			var o = n(1),
				r = n(15);
			e.a = function (t) {
				var e = t.document.documentElement,
					i = n.i(o.select)(t).on("dragstart.drag", r.a, !0);
				"onselectstart" in e
					? i.on("selectstart.drag", r.a, !0)
					: ((e.__noselect = e.style.MozUserSelect), (e.style.MozUserSelect = "none"));
			};
		},
		function (t, e, n) {
			"use strict";
			function i() {
				o.event.stopImmediatePropagation();
			}
			e.b = i;
			var o = n(1);
			e.a = function () {
				o.event.preventDefault(), o.event.stopImmediatePropagation();
			};
		},
		function (t, e, n) {
			"use strict";
			e.a = function (t) {
				return function () {
					return this.matches(t);
				};
			};
		},
		function (t, e, n) {
			"use strict";
			var i = n(0);
			e.a = function (t) {
				return "string" == typeof t
					? new i.b([[document.querySelector(t)]], [document.documentElement])
					: new i.b([[t]], i.c);
			};
		},
		function (t, e, n) {
			"use strict";
			function i(t, e) {
				(this.ownerDocument = t.ownerDocument),
					(this.namespaceURI = t.namespaceURI),
					(this._next = null),
					(this._parent = t),
					(this.__data__ = e);
			}
			e.b = i;
			var o = n(19),
				r = n(0);
			(e.a = function () {
				return new r.b(this._enter || this._groups.map(o.a), this._parents);
			}),
				(i.prototype = {
					constructor: i,
					appendChild: function (t) {
						return this._parent.insertBefore(t, this._next);
					},
					insertBefore: function (t, e) {
						return this._parent.insertBefore(t, e);
					},
					querySelector: function (t) {
						return this._parent.querySelector(t);
					},
					querySelectorAll: function (t) {
						return this._parent.querySelectorAll(t);
					},
				});
		},
		function (t, e, n) {
			"use strict";
			e.a = function (t) {
				return new Array(t.length);
			};
		},
		function (t, e, n) {
			"use strict";
			function i(t) {
				return function () {
					this.style.removeProperty(t);
				};
			}
			function o(t, e, n) {
				return function () {
					this.style.setProperty(t, e, n);
				};
			}
			function r(t, e, n) {
				return function () {
					var i = e.apply(this, arguments);
					null == i ? this.style.removeProperty(t) : this.style.setProperty(t, i, n);
				};
			}
			function a(t, e) {
				return t.style.getPropertyValue(e) || n.i(s.a)(t).getComputedStyle(t, null).getPropertyValue(e);
			}
			e.a = a;
			var s = n(11);
			e.b = function (t, e, n) {
				return arguments.length > 1
					? this.each((null == e ? i : "function" == typeof e ? r : o)(t, e, null == n ? "" : n))
					: a(this.node(), t);
			};
		},
		function (t, e, n) {
			"use strict";
			function i() {
				return [];
			}
			e.a = function (t) {
				return null == t
					? i
					: function () {
							return this.querySelectorAll(t);
					  };
			};
		},
		function (t, e, n) {
			"use strict";
			function i(t, e) {
				if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
			}
			Object.defineProperty(e, "__esModule", { value: !0 });
			var o = (function () {
					function t(t, e) {
						for (var n = 0; n < e.length; n++) {
							var i = e[n];
							(i.enumerable = i.enumerable || !1),
								(i.configurable = !0),
								"value" in i && (i.writable = !0),
								Object.defineProperty(t, i.key, i);
						}
					}
					return function (e, n, i) {
						return n && t(e.prototype, n), i && t(e, i), e;
					};
				})(),
				r = n(4),
				a = n(77),
				s = (function (t) {
					return t && t.__esModule ? t : { default: t };
				})(a),
				u = (function () {
					function t(e, n) {
						i(this, t),
							(this._defaultOptions = { width: 150 }),
							(this._options = Object.assign({}, this._defaultOptions, n || {})),
							(this._parentDiv = e),
							(this._containerDiv = document.createElement("div")),
							this._containerDiv.classList.add(r.Config.prefix + "-context-menu"),
							(this._containerDiv.stylesheet = s.default),
							(this._containerDiv.oncontextmenu = function (t) {
								return t.preventDefault();
							}),
							(this._list = document.createElement("ul")),
							this._containerDiv.appendChild(this._list),
							e.appendChild(this._containerDiv),
							(this._isVisible = !1);
					}
					return (
						o(t, [
							{
								key: "left",
								get: function () {
									return this._containerDiv.getBoundingClientRect().left;
								},
							},
							{
								key: "top",
								get: function () {
									return this._containerDiv.getBoundingClientRect().top;
								},
							},
							{
								key: "width",
								get: function () {
									return this._containerDiv.getBoundingClientRect().width;
								},
							},
							{
								key: "height",
								get: function () {
									return this._containerDiv.getBoundingClientRect().height;
								},
							},
						]),
						o(t, [
							{
								key: "addItem",
								value: function (t, e, n) {
									var i = this,
										o = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : this,
										r = document.createElement("li"),
										a = document.createTextNode(t);
									return (
										r.appendChild(a),
										(r.onclick = function (t) {
											t.preventDefault(), n.apply(o), i.hide();
										}),
										e ? this.showItem(r) : this.hideItem(r),
										this._list.appendChild(r),
										r
									);
								},
							},
							{
								key: "hideItem",
								value: function (t) {
									t.style.cssText = "display: none";
								},
							},
							{
								key: "showItem",
								value: function (t) {
									t.style.cssText = "display: block";
								},
							},
							{
								key: "toggleItems",
								value: function (t, e) {
									var n = this;
									t.forEach(function (t) {
										return n.showItem(t);
									}),
										e.forEach(function (t) {
											return n.hideItem(t);
										});
								},
							},
							{
								key: "show",
								value: function (t) {
									(this._isVisible = !0),
										(this._containerDiv.style.cssText =
											"\n      display: block;\n      visibility: hidden;\n      position: absolute;\n      width: " +
											this._options.width +
											"px; \n    ");
									var e = this._parentDiv.getBoundingClientRect().width <= t.x + this.width,
										n = this._parentDiv.getBoundingClientRect().height <= t.y + this.height;
									this._containerDiv.style.cssText +=
										"\n      " +
										(e ? "right: 0px;" : "left: " + t.x + "px;") +
										"\n      " +
										(n ? "bottom: 14px;" : "top: " + t.y + "px;") +
										"\n      visibility: visible;\n    ";
								},
							},
							{
								key: "hide",
								value: function () {
									(this._isVisible = !1), (this._containerDiv.style.cssText = "display: none");
								},
							},
							{
								key: "toggle",
								value: function (t) {
									this._isVisible ? this.hide() : this.show(t);
								},
							},
						]),
						t
					);
				})();
			(e.default = u), (t.exports = e.default);
		},
		function (t, e, n) {
			"use strict";
			Object.defineProperty(e, "__esModule", { value: !0 });
			(e.EVENT_START = "measure_start"), (e.EVENT_END = "measure_end"), (e.EVENT_CHANGE = "measure_change");
		},
		function (t, e, n) {
			"use strict";
			function i(t, e) {
				if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
			}
			Object.defineProperty(e, "__esModule", { value: !0 });
			var o = (function () {
				function t(t, e) {
					for (var n = 0; n < e.length; n++) {
						var i = e[n];
						(i.enumerable = i.enumerable || !1),
							(i.configurable = !0),
							"value" in i && (i.writable = !0),
							Object.defineProperty(t, i.key, i);
					}
				}
				return function (e, n, i) {
					return n && t(e.prototype, n), i && t(e, i), e;
				};
			})();
			e.Geometry = (function () {
				function t() {
					i(this, t), (this._nodes = []);
				}
				return (
					o(t, [
						{
							key: "nodes",
							get: function () {
								return this._nodes;
							},
						},
						{
							key: "lines",
							get: function () {
								var t = [];
								if (this._nodes.length > 1)
									for (var e = 1; e < this._nodes.length; e++) t.push([this._nodes[e - 1], this._nodes[e]]);
								return t;
							},
						},
					]),
					o(t, [
						{
							key: "addNode",
							value: function (t) {
								this._nodes.push(t);
							},
						},
						{
							key: "updateNode",
							value: function (t, e) {
								this._nodes[t] = e;
							},
						},
						{
							key: "removeNode",
							value: function (t) {
								this._nodes.splice(t, 1);
							},
						},
						{
							key: "insertNode",
							value: function (t, e) {
								this._nodes.splice(t, 0, e);
							},
						},
					]),
					t
				);
			})();
		},
		function (t, e, n) {
			"use strict";
			function i(t, e) {
				if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
			}
			Object.defineProperty(e, "__esModule", { value: !0 });
			var o = (function () {
					function t(t, e) {
						for (var n = 0; n < e.length; n++) {
							var i = e[n];
							(i.enumerable = i.enumerable || !1),
								(i.configurable = !0),
								"value" in i && (i.writable = !0),
								Object.defineProperty(t, i.key, i);
						}
					}
					return function (e, n, i) {
						return n && t(e.prototype, n), i && t(e, i), e;
					};
				})(),
				r = n(13),
				a = (function () {
					function t(e) {
						i(this, t), (this._options = { unit: r.UnitTypeId.METRIC }), Object.assign(this._options, e), this.init();
					}
					return (
						o(
							t,
							[
								{
									key: "init",
									value: function () {
										this.initUnits();
									},
								},
								{
									key: "initUnits",
									value: function () {
										switch (this._options.unit.toLowerCase()) {
											case r.UnitTypeId.METRIC:
												(this._lengthMultiplier = 1),
													(this.formatLength = this._formatLengthMetric),
													(this._areaMultiplier = 1),
													(this.formatArea = this._formatAreaMetric);
												break;
											case r.UnitTypeId.IMPERIAL:
												(this._lengthMultiplier = 3.28084),
													(this.formatLength = this._formatLengthImperial),
													(this._areaMultiplier = 10.7639),
													(this.formatArea = this._formatAreaImperial);
												break;
											case r.UnitTypeId.NAUTICAL:
												(this._lengthMultiplier = 1),
													(this.formatLength = this._formatLengthNautical),
													(this._areaMultiplier = 1),
													(this.formatArea = this._formatAreaMetric);
												break;
											default:
												(this._lengthMultiplier = 1),
													(this.formatLength = this._formatLengthMetric),
													(this._areaMultiplier = 1),
													(this.formatArea = this._formatAreaMetric);
										}
									},
								},
								{
									key: "setOption",
									value: function (t, e) {
										if (!this._options[t]) throw new Error(t + " is not a valid option on MeasureTool helper");
										(this._options[t] = e), this.initUnits();
									},
								},
								{
									key: "computeLengthBetween",
									value: function (t, e) {
										return (
											google.maps.geometry.spherical.computeDistanceBetween(
												new google.maps.LatLng(t[1], t[0]),
												new google.maps.LatLng(e[1], e[0])
											) * this._lengthMultiplier
										);
									},
								},
								{
									key: "computePathLength",
									value: function (t) {
										for (var e = 0, n = 1; n < t.length; n++)
											e += google.maps.geometry.spherical.computeDistanceBetween(
												new google.maps.LatLng(t[n - 1][1], t[n - 1][0]),
												new google.maps.LatLng(t[n][1], t[n][0])
											);
										return e * this._lengthMultiplier;
									},
								},
								{
									key: "computeArea",
									value: function (t) {
										return (
											google.maps.geometry.spherical.computeArea(
												t.map(function (t) {
													return new google.maps.LatLng(t[1], t[0]);
												})
											) * this._areaMultiplier
										);
									},
								},
								{
									key: "_formatLengthMetric",
									value: function (t) {
										var e = void 0;
										return (
											t / 1e3 >= 1 ? ((e = "km"), (t /= 1e3)) : (e = "m"),
											this._numberToLocale(this._roundUp(t, 2)) + " " + e
										);
									},
								},
								{
									key: "_formatLengthImperial",
									value: function (t) {
										var e = void 0;
										return (
											t / 5280 >= 1 ? ((e = "mi"), (t /= 5280)) : (e = "ft"),
											this._numberToLocale(this._roundUp(t, 2)) + " " + e
										);
									},
								},
								{
									key: "_formatLengthNautical",
									value: function (t) {
										return (t /= 1852), this._numberToLocale(this._roundUp(t, 2)) + " NM";
									},
								},
								{
									key: "_formatAreaMetric",
									value: function (t) {
										var e = void 0;
										return (
											t / 1e6 >= 1 ? ((e = "km²"), (t /= 1e6)) : (e = "m²"),
											this._numberToLocale(this._roundUp(t, 2)) + " " + e
										);
									},
								},
								{
									key: "_formatAreaImperial",
									value: function (t) {
										var e = void 0;
										return (
											3.587e-8 * t >= 1 ? ((e = "mi²"), (t *= 3.587e-8)) : (e = "ft²"),
											this._numberToLocale(this._roundUp(t, 2)) + " " + e
										);
									},
								},
								{
									key: "_roundUp",
									value: function (t, e) {
										return Number(Math.round(t + "e" + e) + "e-" + e).toFixed(e);
									},
								},
								{
									key: "_numberToLocale",
									value: function (t) {
										return new Intl.NumberFormat().format(t);
									},
								},
							],
							[
								{
									key: "findTouchPoint",
									value: function (t, e) {
										var n =
											((t[1][1] - t[0][1]) * (e[0] - t[0][0]) - (t[1][0] - t[0][0]) * (e[1] - t[0][1])) /
											(Math.pow(t[1][1] - t[0][1], 2) + Math.pow(t[1][0] - t[0][0], 2));
										return [e[0] - n * (t[1][1] - t[0][1]), e[1] + n * (t[1][0] - t[0][0])];
									},
								},
								{
									key: "findMidPoint",
									value: function (t) {
										return [(t[0][0] + t[1][0]) / 2, (t[0][1] + t[1][1]) / 2];
									},
								},
								{
									key: "transformText",
									value: function (e, n) {
										var i = t.findMidPoint([e, n]),
											o = void 0;
										return (
											(o =
												e[0] === n[0]
													? n[1] > e[1]
														? 90
														: n[1] < e[1]
														? 270
														: 0
													: (180 * Math.atan((n[1] - e[1]) / (n[0] - e[0]))) / Math.PI),
											"translate(" + i[0] + ", " + i[1] + ") rotate(" + o + ")"
										);
									},
								},
								{
									key: "makeId",
									value: function (t) {
										return (Math.random().toString(36) + "00000000000000000").slice(2, t + 2);
									},
								},
								{
									key: "_interpolate",
									value: function (t, e, n) {
										var i = google.maps.geometry.spherical.interpolate(
											new google.maps.LatLng(t[1], t[0]),
											new google.maps.LatLng(e[1], e[0]),
											n
										);
										return [i.lng(), i.lat()];
									},
								},
							]
						),
						t
					);
				})();
			(e.default = a), (t.exports = e.default);
		},
		function (t, e, n) {
			"use strict";
			Object.defineProperty(e, "__esModule", { value: !0 });
			e.ObjectAssign = function () {
				"function" != typeof Object.assign &&
					(Object.assign = function (t) {
						if (null === t) throw new TypeError("Cannot convert undefined or null to object");
						t = Object(t);
						for (var e = 1; e < arguments.length; e++) {
							var n = arguments[e];
							if (null !== n) for (var i in n) Object.prototype.hasOwnProperty.call(n, i) && (t[i] = n[i]);
						}
						return t;
					});
			};
		},
		function (t, e, n) {
			"use strict";
			function i(t, e) {
				if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
			}
			Object.defineProperty(e, "__esModule", { value: !0 });
			var o = (function () {
					function t(t, e) {
						for (var n = 0; n < e.length; n++) {
							var i = e[n];
							(i.enumerable = i.enumerable || !1),
								(i.configurable = !0),
								"value" in i && (i.writable = !0),
								Object.defineProperty(t, i.key, i);
						}
					}
					return function (e, n, i) {
						return n && t(e.prototype, n), i && t(e, i), e;
					};
				})(),
				r = (function () {
					function t(e, n, o) {
						i(this, t),
							(this._defaultOptions = { offsetRate: 8e3 }),
							(this._options = Object.assign({}, this._defaultOptions, o || {})),
							(this._container = e),
							(this._projection = n);
					}
					return (
						o(t, [
							{
								key: "latLngToSvgPoint",
								value: function (t) {
									var e = this._options.offsetRate / 2,
										n = new google.maps.LatLng(t[1], t[0]),
										i = this._projection.fromLatLngToDivPixel(n);
									return [i.x + e, i.y + e];
								},
							},
							{
								key: "svgPointToLatLng",
								value: function (t) {
									var e = this._options.offsetRate / 2,
										n = new google.maps.Point(t[0] - e, t[1] - e),
										i = this._projection.fromDivPixelToLatLng(n);
									return [i.lng(), i.lat()];
								},
							},
							{
								key: "svgPointToContainerPoint",
								value: function (t) {
									var e = this.svgPointToLatLng(t);
									return this._projection.fromLatLngToContainerPixel(new google.maps.LatLng(e[1], e[0]));
								},
							},
							{
								key: "latLngToContainerPoint",
								value: function (t) {
									return this._projection.fromLatLngToContainerPixel(new google.maps.LatLng(t[1], t[0]));
								},
							},
						]),
						t
					);
				})();
			(e.default = r), (t.exports = e.default);
		},
		function (t, e, n) {
			"use strict";
			function i(t, e) {
				if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
			}
			Object.defineProperty(e, "__esModule", { value: !0 });
			var o = (function () {
				function t(t, e) {
					for (var n = 0; n < e.length; n++) {
						var i = e[n];
						(i.enumerable = i.enumerable || !1),
							(i.configurable = !0),
							"value" in i && (i.writable = !0),
							Object.defineProperty(t, i.key, i);
					}
				}
				return function (e, n, i) {
					return n && t(e.prototype, n), i && t(e, i), e;
				};
			})();
			e.Segment = (function () {
				function t(e, n, o, r) {
					i(this, t), (this._start = e), (this._end = n), (this._length = o), (this._lengthText = r);
				}
				return (
					o(t, [
						{
							key: "toJSON",
							value: function () {
								return {
									start_location: { lat: this._start[1], lng: this._start[0] },
									end_location: { lat: this._end[1], lng: this._end[0] },
									length: { text: this._lengthText, value: this._length },
								};
							},
						},
					]),
					t
				);
			})();
		},
		function (t, e, n) {
			"use strict";
			function i(t, e) {
				if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
			}
			Object.defineProperty(e, "__esModule", { value: !0 });
			var o = (function () {
					function t(t, e) {
						for (var n = 0; n < e.length; n++) {
							var i = e[n];
							(i.enumerable = i.enumerable || !1),
								(i.configurable = !0),
								"value" in i && (i.writable = !0),
								Object.defineProperty(t, i.key, i);
						}
					}
					return function (e, n, i) {
						return n && t(e.prototype, n), i && t(e, i), e;
					};
				})(),
				r = n(4),
				a = n(78),
				s = (function (t) {
					return t && t.__esModule ? t : { default: t };
				})(a),
				u = (function () {
					function t(e) {
						i(this, t),
							(this._parentDiv = e),
							(this._containerDiv = document.createElement("div")),
							this._containerDiv.classList.add(r.Config.prefix + "-tooltip"),
							(this._containerDiv.stylesheet = s.default),
							e.appendChild(this._containerDiv);
					}
					return (
						o(t, [
							{
								key: "show",
								value: function (t, e) {
									(this._containerDiv.innerHTML = e),
										(this._containerDiv.style.cssText =
											"\n      display: block;\n      visibility: hidden;\n      position: absolute;\n    ");
									var n = this._parentDiv.getBoundingClientRect().width,
										i = this._parentDiv.getBoundingClientRect().height;
									(this._containerDiv.style.cssText +=
										t.x < n / 2 ? "left: " + t.x + "px;" : "right: " + (n - t.x) + "px;"),
										(this._containerDiv.style.cssText +=
											t.y < i / 2 ? "top: " + t.y + "px" : "bottom: " + (i - t.y) + "px;"),
										(this._containerDiv.style.cssText += "visibility: visible;");
								},
							},
							{
								key: "hide",
								value: function () {
									this._containerDiv.style.cssText = "display: none";
								},
							},
						]),
						t
					);
				})();
			(e.default = u), (t.exports = e.default);
		},
		function (t, e, n) {
			"use strict";
			Object.defineProperty(e, "__esModule", { value: !0 });
			var i =
				"function" == typeof Symbol && "symbol" == typeof Symbol.iterator
					? function (t) {
							return typeof t;
					  }
					: function (t) {
							return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype
								? "symbol"
								: typeof t;
					  };
			e.deepClone = function t(e) {
				if (!e) return e;
				var n = [Number, String, Boolean],
					o = void 0;
				if (
					(n.forEach(function (t) {
						e instanceof t && (o = t(e));
					}),
					void 0 === o)
				)
					if ("[object Array]" === Object.prototype.toString.call(e))
						(o = []),
							e.forEach(function (e, n, i) {
								o[n] = t(e);
							});
					else if ("object" === (void 0 === e ? "undefined" : i(e)))
						if (e.nodeType && "function" == typeof e.cloneNode) o = e.cloneNode(!0);
						else if (e.prototype) o = e;
						else if (e instanceof Date) o = new Date(e);
						else {
							o = {};
							for (var r in e) o[r] = t(e[r]);
						}
					else o = e;
				return o;
			};
		},
		function (t, e, n) {
			"use strict";
			Object.defineProperty(e, "__esModule", { value: !0 });
			var i = n(40);
			n.d(e, "drag", function () {
				return i.a;
			});
			var o = n(14);
			n.d(e, "dragDisable", function () {
				return o.a;
			}),
				n.d(e, "dragEnable", function () {
					return o.b;
				});
		},
		function (t, e, n) {
			var i = n(35);
			"string" == typeof i && (i = [[t.i, i, ""]]);
			n(12)(i, {});
			i.locals && (t.exports = i.locals);
		},
		function (t, e, n) {
			"use strict";
			function i(t) {
				return t && t.__esModule ? t : { default: t };
			}
			function o(t) {
				if (Array.isArray(t)) {
					for (var e = 0, n = Array(t.length); e < t.length; e++) n[e] = t[e];
					return n;
				}
				return Array.from(t);
			}
			function r(t, e) {
				if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
			}
			Object.defineProperty(e, "__esModule", { value: !0 });
			var a = (function () {
					function t(t, e) {
						for (var n = 0; n < e.length; n++) {
							var i = e[n];
							(i.enumerable = i.enumerable || !1),
								(i.configurable = !0),
								"value" in i && (i.writable = !0),
								Object.defineProperty(t, i.key, i);
						}
					}
					return function (e, n, i) {
						return n && t(e.prototype, n), i && t(e, i), e;
					};
				})(),
				s = n(32),
				u = (i(s), n(4)),
				c = n(22),
				l = i(c),
				h = n(29),
				f = i(h),
				p = n(1),
				d = n(27),
				g = i(d),
				v = n(24),
				_ = n(28),
				m = n(31),
				y = n(25),
				x = i(y),
				A = n(13),
				b = n(23),
				w = n(26),
				C = n(30),
				T = (function () {
					function t(e, n) {
						r(this, t),
							t._initPolyfills(),
							(this._options = {
								showSegmentLength: !0,
								showAccumulativeLength: !0,
								contextMenu: !0,
								tooltip: !0,
								unit: A.UnitTypeId.METRIC,
								initialSegments: [],
							}),
							Object.assign(this._options, n),
							(this._map = e),
							this._map.setClickableIcons(!1),
							(this._id = x.default.makeId(4)),
							(this._events = new Map()),
							this._init();
					}
					return (
						a(
							t,
							[
								{
									key: "lengthText",
									get: function () {
										return this._helper.formatLength(this._length || 0);
									},
								},
								{
									key: "areaText",
									get: function () {
										return this._helper.formatArea(this._area || 0);
									},
								},
								{
									key: "length",
									get: function () {
										return this._length || 0;
									},
								},
								{
									key: "area",
									get: function () {
										return this._area || 0;
									},
								},
								{
									key: "segments",
									get: function () {
										return (0, C.deepClone)(this._segments) || [];
									},
								},
								{
									key: "points",
									get: function () {
										return (
											(0, C.deepClone)(
												this._geometry.nodes.map(function (t) {
													return { lat: t[1], lng: t[0] };
												})
											) || []
										);
									},
								},
							],
							[
								{
									key: "UnitTypeId",
									get: function () {
										return A.UnitTypeId;
									},
								},
							]
						),
						a(
							t,
							[
								{
									key: "_init",
									value: function () {
										(this._containerDiv = this._map.getDiv().querySelector("div:first-child")),
											this._options.contextMenu &&
												((this._contextMenu = new l.default(this._containerDiv, { width: 160 })),
												(this._startElementNode = this._contextMenu.addItem("Measure distance", !0, this.start, this)),
												(this._endElementNode = this._contextMenu.addItem("Clear measurement", !1, this.end, this)),
												this._bindToggleContextMenu()),
											this._options.tooltip && (this._tooltip = new f.default(this._containerDiv)),
											(this._helper = new x.default({ unit: this._options.unit })),
											this._initOverlay();
									},
								},
								{
									key: "_bindToggleContextMenu",
									value: function () {
										var t = this;
										this._map.addListener("rightclick", function (e) {
											(t._firstClick = e), t._contextMenu.show(t._projection.fromLatLngToContainerPixel(e.latLng));
										}),
											document.addEventListener("keydown", function (e) {
												27 === e.which && t._contextMenu.hide();
											}),
											this._containerDiv.addEventListener("mousedown", function (e) {
												(e.clientX >= t._contextMenu.left &&
													e.clientX <= t._contextMenu.left + t._contextMenu.width &&
													e.clientY >= t._contextMenu.top &&
													e.clientY <= t._contextMenu.top + t._contextMenu.height) ||
													t._contextMenu.hide();
											});
									},
								},
								{
									key: "start",
									value: function (t) {
										var e = this;
										if (!this._started) {
											this._overlay.setMap(this._map), (this._geometry = new v.Geometry()), (this._segments = []);
											var n = t && t.length > 0;
											if (!this._options.contextMenu && n)
												for (var i = 0; i < t.length; i++) {
													var o = t[i];
													if ((this._geometry.addNode([o.lng, o.lat]), i > 0)) {
														var r = t[i - 1];
														this._updateSegment([r, o]);
													}
												}
											this._options.contextMenu &&
												this._firstClick &&
												(this._checkClick(this._firstClick),
												this._contextMenu.toggleItems([this._endElementNode], [this._startElementNode])),
												(this._mapClickEvent = this._map.addListener("click", function (t) {
													return e._checkClick(t);
												})),
												this._map.setOptions({ draggableCursor: "default" }),
												(this._started = !0),
												"function" == typeof this._events.get(b.EVENT_START) && this._events.get(b.EVENT_START)();
										}
									},
								},
								{
									key: "end",
									value: function () {
										this._started &&
											("function" == typeof this._events.get(b.EVENT_END) &&
												this._events.get(b.EVENT_END)(this._getResults()),
											this._options.contextMenu &&
												this._contextMenu.toggleItems([this._startElementNode], [this._endElementNode]),
											this._mapClickEvent.remove(),
											(this._geometry = new v.Geometry()),
											this._onRemoveOverlay(),
											this._setOverlay(),
											this._overlay.setMap(null),
											this._map.setOptions({ draggableCursor: null }),
											(this._started = !1));
									},
								},
								{
									key: "addListener",
									value: function (t, e) {
										this._events.set(t, e);
									},
								},
								{
									key: "removeListener",
									value: function (t) {
										this._events.delete(t);
									},
								},
								{
									key: "setOption",
									value: function (t, e) {
										if (!this._options[t]) throw new Error(t + " is not a valid option on MeasureTool");
										(this._options[t] = e),
											this._helper._options[t] && this._helper.setOption(t, e),
											this._overlay && this._nodeCircles && this._redrawOverlay();
									},
								},
								{
									key: "_initOverlay",
									value: function () {
										this._setOverlay(), (this._initComplete = !1);
									},
								},
								{
									key: "_setOverlay",
									value: function () {
										(this._overlay = new google.maps.OverlayView()),
											(this._overlay.onAdd = this._onAddOverlay.bind(this)),
											(this._overlay.draw = this._onDrawOverlay.bind(this)),
											(this._overlay.onRemove = this._onRemoveOverlay.bind(this)),
											this._overlay.setMap(this._map);
									},
								},
								{
									key: "_onAddOverlay",
									value: function () {
										this._initComplete || (this._initComplete = !0),
											(this._projection = this._overlay.getProjection()),
											(this._projectionUtility = new g.default(this._containerDiv, this._projection)),
											(this._svgOverlay = (0, p.select)(this._overlay.getPanes().overlayMouseTarget)
												.append("div")
												.attr("class", u.Config.prefix + "-measure-points-" + this._id)
												.append("svg")
												.attr("class", u.Config.prefix + "-svg-overlay")),
											(this._linesBase = this._svgOverlay.append("g").attr("class", "base")),
											this._linesBase.selectAll("line").data(this._geometry ? this._geometry.lines : []),
											(this._linesAux = this._svgOverlay.append("g").attr("class", "aux")),
											this._linesAux.selectAll("line").data(this._geometry ? this._geometry.lines : []),
											(this._nodeCircles = this._svgOverlay.append("g").attr("class", "node-circle")),
											this._nodeCircles.selectAll("circle").data(this._geometry ? this._geometry.nodes : []),
											this._options.showSegmentLength &&
												((this._segmentText = this._svgOverlay.append("g").attr("class", "segment-text")),
												this._segmentText.selectAll("text").data(this._geometry ? this._geometry.lines : [])),
											this._options.showAccumulativeLength &&
												((this._nodeText = this._svgOverlay.append("g").attr("class", "node-text")),
												this._nodeText.selectAll("text").data(this._geometry ? this._geometry.nodes : [])),
											(this._hoverCircle = this._svgOverlay.append("g").attr("class", "hover-circle")),
											this._hoverCircle.append("circle").attr("class", "grey-circle").attr("r", 5),
											this._initComplete && !this._started && this._overlay.setMap(null);
									},
								},
								{
									key: "_onDrawOverlay",
									value: function () {
										this._updateCircles(),
											this._updateLine(),
											this._options.showSegmentLength && this._updateSegmentText(),
											this._options.showAccumulativeLength && this._updateNodeText(),
											this._geometry &&
												this._updateArea(
													this._geometry.nodes.length - 1,
													this._geometry.nodes[this._geometry.nodes.length - 1]
												),
											this._dispatchMeasureEvent();
									},
								},
								{
									key: "_onRemoveOverlay",
									value: function () {
										(0, p.selectAll)("." + u.Config.prefix + "-measure-points-" + this._id).remove();
									},
								},
								{
									key: "_redrawOverlay",
									value: function () {
										this._onRemoveOverlay(), this._setOverlay(), this._overlay.draw();
									},
								},
								{
									key: "_checkClick",
									value: function (t) {
										if (
											!this._dragged &&
											0 == this._nodeCircles.selectAll('circle[r="6"]').size() &&
											!this._hoverCircle.select("circle").attr("cx")
										) {
											var e = [t.latLng.lng(), t.latLng.lat()];
											this._geometry.addNode(e), this._overlay.draw();
										}
										this._dragged = !1;
									},
								},
								{
									key: "_updateCircles",
									value: function () {
										var t = this,
											e = this;
										this._nodeCircles
											.selectAll("circle")
											.data(this._geometry ? this._geometry.nodes : [])
											.attr("class", function (t, e) {
												return 0 === e ? "cover-circle head-circle" : "cover-circle";
											})
											.attr("r", 5)
											.attr("cx", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e)[0];
											})
											.attr("cy", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e)[1];
											})
											.on("mouseover", function (t, n) {
												e._onOverCircle(t, n, this);
											})
											.on("mouseout", function (t) {
												e._onOutCircle(t, this);
											})
											.on("touchstart", function (t, n) {
												e._onOverCircle(t, n, this);
											})
											.on("touchleave", function (t) {
												e._onOutCircle(t, this);
											})
											.on("mousedown", function () {
												return t._hideTooltip();
											})
											.call(this._onDragCircle())
											.enter()
											.append("circle")
											.attr("class", "cover-circle")
											.attr("r", 5)
											.attr("cx", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e)[0];
											})
											.attr("cy", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e)[1];
											})
											.on("mouseover", function (t, n) {
												e._onOverCircle(t, n, this);
											})
											.on("mouseout", function (t) {
												e._onOutCircle(t, this);
											})
											.on("touchstart", function (t, n) {
												e._onOverCircle(t, n, this);
											})
											.on("touchleave", function (t) {
												e._onOutCircle(t, this);
											})
											.on("mousedown", function () {
												return t._hideTooltip();
											})
											.call(this._onDragCircle()),
											this._nodeCircles.selectAll(".removed-circle").remove();
									},
								},
								{
									key: "_updateLine",
									value: function () {
										var t = this;
										this._segments = [];
										var e = this._linesBase
											.selectAll("line")
											.data(this._geometry ? this._geometry.lines : [])
											.attr("class", "base-line")
											.attr("x1", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e[0])[0];
											})
											.attr("y1", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e[0])[1];
											})
											.attr("x2", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e[1])[0];
											})
											.attr("y2", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e[1])[1];
											})
											.each(function (e) {
												return t._updateSegment(e);
											});
										e
											.enter()
											.append("line")
											.attr("class", "base-line")
											.attr("x1", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e[0])[0];
											})
											.attr("y1", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e[0])[1];
											})
											.attr("x2", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e[1])[0];
											})
											.attr("y2", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e[1])[1];
											})
											.each(function (e) {
												return t._updateSegment(e);
											}),
											e.exit().remove();
										var n = this._linesAux
											.selectAll("line")
											.data(this._geometry ? this._geometry.lines : [])
											.attr("class", "aux-line")
											.attr("x1", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e[0])[0];
											})
											.attr("y1", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e[0])[1];
											})
											.attr("x2", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e[1])[0];
											})
											.attr("y2", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e[1])[1];
											})
											.on("mousemove", function (e) {
												var n = x.default.findTouchPoint(
													[t._projectionUtility.latLngToSvgPoint(e[0]), t._projectionUtility.latLngToSvgPoint(e[1])],
													[p.event.offsetX, p.event.offsetY]
												);
												t._updateHoverCirclePosition(n[0], n[1]);
											})
											.on("mouseout", function (e) {
												return t._hideHoverCircle();
											})
											.on("mousedown", function () {
												return t._hideTooltip();
											})
											.call(this._onDragLine());
										n
											.enter()
											.append("line")
											.attr("class", "aux-line")
											.attr("x1", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e[0])[0];
											})
											.attr("y1", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e[0])[1];
											})
											.attr("x2", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e[1])[0];
											})
											.attr("y2", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e[1])[1];
											})
											.on("mousemove", function (e) {
												var n = x.default.findTouchPoint(
													[t._projectionUtility.latLngToSvgPoint(e[0]), t._projectionUtility.latLngToSvgPoint(e[1])],
													[p.event.offsetX, p.event.offsetY]
												);
												t._updateHoverCirclePosition(n[0], n[1]);
											})
											.on("mouseout", function (e) {
												return t._hideHoverCircle();
											})
											.on("mousedown", function () {
												return t._hideTooltip();
											})
											.call(this._onDragLine()),
											n.exit().remove();
									},
								},
								{
									key: "_updateSegmentText",
									value: function () {
										var t = this,
											e = this._segmentText
												.selectAll("text")
												.data(this._geometry ? this._geometry.lines : [])
												.attr("class", "segment-measure-text")
												.attr("text-anchor", "middle")
												.attr("dominant-baseline", "text-before-edge")
												.attr("transform", function (e) {
													var n = t._projectionUtility.latLngToSvgPoint(e[0]),
														i = t._projectionUtility.latLngToSvgPoint(e[1]);
													return x.default.transformText(n, i);
												})
												.text(function (e, n) {
													return t._helper.formatLength(t._helper.computeLengthBetween(e[0], e[1]));
												});
										e
											.enter()
											.append("text")
											.attr("class", "segment-measure-text")
											.attr("text-anchor", "middle")
											.attr("dominant-baseline", "text-before-edge")
											.attr("transform", function (e) {
												var n = t._projectionUtility.latLngToSvgPoint(e[0]),
													i = t._projectionUtility.latLngToSvgPoint(e[1]);
												return x.default.transformText(n, i);
											})
											.text(function (e, n) {
												return t._helper.formatLength(t._helper.computeLengthBetween(e[0], e[1]));
											}),
											e.exit().remove();
									},
								},
								{
									key: "_updateNodeText",
									value: function () {
										var t = this,
											e = this._nodeText
												.selectAll("text")
												.data(this._geometry ? this._geometry.nodes : [])
												.attr("class", function (t, e) {
													return 0 === e ? "node-measure-text head-text" : "node-measure-text";
												})
												.attr("text-anchor", "middle")
												.attr("dominant-baseline", "text-after-edge")
												.attr("x", function (e) {
													return t._projectionUtility.latLngToSvgPoint(e)[0];
												})
												.attr("y", this._transformNodeTextY.bind(this))
												.text(function (e, n) {
													var i = t._helper.computePathLength(t._geometry.nodes.slice(0, n + 1));
													return n === t._geometry.nodes.length - 1 && (t._length = i), t._helper.formatLength(i);
												});
										e
											.enter()
											.append("text")
											.attr("class", function (t, e) {
												return 0 === e ? "node-measure-text head-text" : "node-measure-text";
											})
											.attr("text-anchor", "middle")
											.attr("dominant-baseline", "text-after-edge")
											.attr("x", function (e) {
												return t._projectionUtility.latLngToSvgPoint(e)[0];
											})
											.attr("y", this._transformNodeTextY.bind(this))
											.text(function (e, n) {
												var i = t._helper.computePathLength(t._geometry.nodes.slice(0, n + 1));
												return n === t._geometry.nodes.length - 1 && (t._length = i), t._helper.formatLength(i);
											}),
											e.exit().remove();
									},
								},
								{
									key: "_onOverCircle",
									value: function (t, e, n) {
										this._dragging ||
											((0, p.select)(n).attr("r", 6),
											this._options.tooltip &&
												this._tooltip.show(
													this._projectionUtility.latLngToContainerPoint(t),
													0 === e ? u.Config.tooltipText2 : u.Config.tooltipText1
												));
									},
								},
								{
									key: "_onOutCircle",
									value: function (t, e) {
										(0, p.select)(e).attr("r", 5), this._hideTooltip();
									},
								},
								{
									key: "_onDragCircle",
									value: function () {
										var t = this,
											e = !1,
											n = (0, m.drag)().on("drag", function (n, i) {
												(e = !0),
													(t._dragging = !0),
													(0, p.select)(this).attr("cx", p.event.x).attr("cy", p.event.y),
													t._updateLinePosition.call(t, t._linesBase, i),
													t._updateLinePosition.call(t, t._linesAux, i),
													t._options.showSegmentLength && t._updateSegmentTextPosition(i),
													t._options.showAccumulativeLength && t._updateNodeTextPosition(i),
													t._updateArea(i, t._projectionUtility.svgPointToLatLng([p.event.x, p.event.y]));
											});
										return (
											n.on("start", function (e) {
												p.event.sourceEvent.stopPropagation(),
													(0, p.select)(this).raise().attr("r", 6),
													t._disableMapScroll();
											}),
											n.on("end", function (n, i) {
												t._enableMapScroll(),
													e
														? (t._geometry.updateNode(i, t._projectionUtility.svgPointToLatLng([p.event.x, p.event.y])),
														  t._showTooltipOnEvent(0 === i ? u.Config.tooltipText2 : u.Config.tooltipText1))
														: i > 0
														? (t._geometry.removeNode(i), (0, p.select)(this).classed("removed-circle", !0))
														: (t._geometry.addNode(n), (t._dragged = !0)),
													(e = !1),
													(t._dragging = !1),
													t._overlay.draw();
											}),
											n
										);
									},
								},
								{
									key: "_onDragLine",
									value: function () {
										var t = this,
											e = !1,
											n = (0, m.drag)().on("drag", function (n, i) {
												(t._dragging = !0),
													e ||
														((e = !0),
														t._geometry.insertNode(
															i + 1,
															t._projectionUtility.svgPointToLatLng([p.event.x, p.event.y])
														),
														t._updateLine(),
														t._options.showSegmentLength && t._updateSegmentText(),
														t._options.showAccumulativeLength && t._updateNodeText()),
													t._updateHoverCirclePosition(p.event.x, p.event.y),
													t._updateLinePosition(t._linesBase, i + 1),
													t._updateLinePosition(t._linesAux, i + 1),
													t._options.showSegmentLength && t._updateSegmentTextPosition(i + 1),
													t._options.showAccumulativeLength && t._updateNodeTextPosition(i + 1),
													t._updateArea(i + 1, t._projectionUtility.svgPointToLatLng([p.event.x, p.event.y]));
											});
										return (
											n.on("start", function () {
												p.event.sourceEvent.stopPropagation(),
													t._hoverCircle.select("circle").attr("class", "cover-circle"),
													t._disableMapScroll();
											}),
											n.on("end", function (n, i) {
												t._enableMapScroll(),
													e &&
														(t._geometry.updateNode(
															i + 1,
															t._projectionUtility.svgPointToLatLng([p.event.x, p.event.y])
														),
														t._hideHoverCircle(),
														t._overlay.draw(),
														(e = !1),
														t._showTooltipOnEvent(u.Config.tooltipText1)),
													t._updateArea(i + 1, t._projectionUtility.svgPointToLatLng([p.event.x, p.event.y])),
													t._hoverCircle.select("circle").attr("class", "grey-circle"),
													(t._dragging = !1);
											}),
											n
										);
									},
								},
								{
									key: "_updateLinePosition",
									value: function (t, e) {
										e < this._geometry.lines.length &&
											t
												.select("line:nth-child(" + (e + 1) + ")")
												.attr("x1", p.event.x)
												.attr("y1", p.event.y),
											e > 0 &&
												t
													.select("line:nth-child(" + e + ")")
													.attr("x2", p.event.x)
													.attr("y2", p.event.y);
									},
								},
								{
									key: "_updateSegmentTextPosition",
									value: function (t) {
										var e = this;
										t < this._geometry.lines.length &&
											this._segmentText
												.select("text:nth-child(" + (t + 1) + ")")
												.attr("transform", function (t) {
													var n = [p.event.x, p.event.y],
														i = e._projectionUtility.latLngToSvgPoint(t[1]);
													return x.default.transformText(n, i);
												})
												.text(function (t) {
													return e._helper.formatLength(
														e._helper.computeLengthBetween(
															e._projectionUtility.svgPointToLatLng([p.event.x, p.event.y]),
															t[1]
														)
													);
												}),
											t > 0 &&
												this._segmentText
													.select("text:nth-child(" + t + ")")
													.attr("transform", function (t) {
														var n = e._projectionUtility.latLngToSvgPoint(t[0]),
															i = [p.event.x, p.event.y];
														return x.default.transformText(n, i);
													})
													.text(function (t) {
														return e._helper.formatLength(
															e._helper.computeLengthBetween(
																t[0],
																e._projectionUtility.svgPointToLatLng([p.event.x, p.event.y])
															)
														);
													});
									},
								},
								{
									key: "_updateNodeTextPosition",
									value: function (t) {
										var e = this;
										this._nodeText
											.select("text:nth-child(" + (t + 1) + ")")
											.attr("x", p.event.x)
											.attr("y", function () {
												var n = void 0;
												return (
													(n =
														t > 0 && e._projectionUtility.latLngToSvgPoint(e._geometry.nodes[t - 1])[1] < p.event.y
															? 23
															: -7),
													p.event.y + n
												);
											}),
											this._nodeText.select("text:nth-child(" + (t + 2) + ")").attr("y", function (n) {
												var i = void 0;
												return (
													(i = t + 1 > 0 && p.event.y < e._projectionUtility.latLngToSvgPoint(n)[1] ? 23 : -7),
													e._projectionUtility.latLngToSvgPoint(n)[1] + i
												);
											}),
											this._nodeText
												.selectAll("text")
												.filter(function (e, n) {
													return n >= t;
												})
												.text(function (n, i) {
													var r = e._helper.computePathLength(
														[].concat(
															o(e._geometry.nodes.slice(0, t)),
															[e._projectionUtility.svgPointToLatLng([p.event.x, p.event.y])],
															o(e._geometry.nodes.slice(t + 1, t + 1 + i))
														)
													);
													return t + i === e._geometry.nodes.length - 1 && (e._length = r), e._helper.formatLength(r);
												});
									},
								},
								{
									key: "_updateHoverCirclePosition",
									value: function (t, e) {
										this._hoverCircle.select("circle").attr("cx", t).attr("cy", e),
											this._dragging ||
												(this._options.tooltip &&
													this._tooltip.show(
														this._projectionUtility.svgPointToContainerPoint([t, e]),
														u.Config.tooltipText2
													));
									},
								},
								{
									key: "_hideHoverCircle",
									value: function () {
										this._hoverCircle.select("circle").attr("cx", null).attr("cy", null), this._hideTooltip();
									},
								},
								{
									key: "_disableMapScroll",
									value: function () {
										(this._zoomControl = !!document.querySelector("button[aria-label='Zoom in']")),
											this._map.setOptions({ scrollwheel: !1, gestureHandling: "none", zoomControl: !1 });
									},
								},
								{
									key: "_enableMapScroll",
									value: function () {
										this._map.setOptions({ scrollwheel: !0, gestureHandling: "auto", zoomControl: this._zoomControl });
									},
								},
								{
									key: "_transformNodeTextY",
									value: function (t, e) {
										var n = void 0;
										return (
											(n = e > 0 && this._geometry.nodes[e - 1][1] > t[1] ? 23 : -7),
											this._projectionUtility.latLngToSvgPoint(t)[1] + n
										);
									},
								},
								{
									key: "_updateArea",
									value: function (t, e) {
										if (this._geometry) {
											var n = this._geometry.nodes.length,
												i = (1 / 80) * this.length,
												r = void 0,
												a = 0;
											n > 2 &&
												(0 === t
													? ((r = this._helper.computeLengthBetween(this._geometry.nodes[n - 1], e)),
													  (a =
															r > i
																? 0
																: this._helper.computeArea([e].concat(o(this._geometry.nodes.slice(1, n - 1))))))
													: t === n - 1
													? ((r = this._helper.computeLengthBetween(e, this._geometry.nodes[0])),
													  (a = r > i ? 0 : this._helper.computeArea(this._geometry.nodes.slice(0, n - 1))))
													: t > 0 && t < n - 1
													? ((r = this._helper.computeLengthBetween(
															this._geometry.nodes[0],
															this._geometry.nodes[n - 1]
													  )),
													  (a =
															r > i
																? 0
																: this._helper.computeArea(
																		[].concat(
																			o(this._geometry.nodes.slice(0, t)),
																			[e],
																			o(this._geometry.nodes.slice(t + 1))
																		)
																  )))
													: ((r = this._helper.computeLengthBetween(
															this._geometry.nodes[0],
															this._geometry.nodes[n - 1]
													  )),
													  (a = r > i ? 0 : this._helper.computeArea(this._geometry.nodes)))),
												(this._area = a),
												a > 0 &&
													this._nodeText
														.select(":last-child")
														.text("Total distance: " + this.lengthText + "; Total area: " + this.areaText + ".");
										}
									},
								},
								{
									key: "_showTooltipOnEvent",
									value: function (t) {
										this._options.tooltip &&
											this._tooltip.show(this._projectionUtility.svgPointToContainerPoint([p.event.x, p.event.y]), t);
									},
								},
								{
									key: "_hideTooltip",
									value: function () {
										this._options.tooltip && this._tooltip.hide();
									},
								},
								{
									key: "_dispatchMeasureEvent",
									value: function () {
										if (this._started) {
											var t = this._getResults();
											(this._lastMeasure &&
												this._lastMeasure.result.lengthText === this.lengthText &&
												this._lastMeasure.result.areaText === this.areaText) ||
												("function" == typeof this._events.get(b.EVENT_CHANGE) &&
													this._events.get(b.EVENT_CHANGE)((this._lastMeasure = t)));
										}
									},
								},
								{
									key: "_updateSegment",
									value: function (t) {
										var e = this._helper.computeLengthBetween(t[0], t[1]),
											n = this._helper.formatLength(e);
										this._segments.push(new _.Segment(t[0], t[1], e, n).toJSON());
									},
								},
								{
									key: "_getResults",
									value: function () {
										return {
											result: {
												length: this.length,
												lengthText: this.lengthText,
												area: this.area,
												areaText: this.areaText,
												segments: this.segments,
												points: this.points,
											},
										};
									},
								},
							],
							[
								{
									key: "_initPolyfills",
									value: function () {
										(0, w.ObjectAssign)();
									},
								},
							]
						),
						t
					);
				})();
			(e.default = T), (t.exports = e.default);
		},
		function (t, e, n) {
			(e = t.exports = n(5)()),
				e.push([
					t.i,
					".measure-tool-context-menu {\n  font-family: Roboto, Arial, sans-serif;\n  border: 1px solid rgba(0, 0, 0, 0.2);\n  outline: none;\n  position: fixed;\n  display: none;\n  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);\n  transform: translate3d(0, 0, 0);\n  max-width: 265px;\n  z-index: 1;\n  outline-offset: -2px;\n  background: #fff;\n  padding: 6px 0;\n  white-space: nowrap;\n  cursor: default;\n  margin: 0; }\n  .measure-tool-context-menu ul {\n    padding: 0px;\n    margin: 0px;\n    background-color: white; }\n    .measure-tool-context-menu ul li {\n      cursor: pointer;\n      display: flex;\n      align-items: center;\n      border-color: transparent;\n      border-style: dotted;\n      border-width: 1px 0;\n      color: #333;\n      font-size: 13px;\n      font-weight: normal;\n      margin: 0;\n      padding: 4px 44px 4px 16px;\n      position: relative;\n      white-space: nowrap; }\n      .measure-tool-context-menu ul li:hover {\n        background-color: #f1f1f1;\n        border-color: #f1f1f1;\n        color: #222;\n        transition: background 0s; }\n",
					"",
					{
						version: 3,
						sources: [
							"/home/alex/ambiente/ceabs/frotas/frontend/external-components/MeasureTool-GoogleMaps-V3/src/context-menu.scss",
						],
						names: [],
						mappings:
							"AACA;EACE,uCAFqC;EAGrC,qCAAiC;EACjC,cAAa;EACb,gBAAe;EACf,cAAa;EAEb,yCAAqC;EACrC,gCAA6B;EAC7B,iBAAgB;EAChB,WAAU;EACV,qBAAoB;EAEpB,iBAAgB;EAChB,eAAc;EACd,oBAAmB;EACnB,gBAAe;EACf,UAAS,EA6BV;EA9CD;IAmBI,aAAY;IACZ,YAAW;IACX,wBAAuB,EAwBxB;IA7CH;MAwBM,gBAAe;MACf,cAAa;MACb,oBAAmB;MACnB,0BAAyB;MACzB,qBAAoB;MACpB,oBAAmB;MACnB,YAAW;MACX,gBAAe;MACf,oBAAmB;MACnB,UAAS;MACT,2BAA0B;MAC1B,mBAAkB;MAClB,oBAAmB,EAQpB;MA5CL;QAuCQ,0BAAyB;QACzB,sBAAqB;QACrB,YAAW;QACX,0BAAyB,EAC1B",
						file: "context-menu.scss",
						sourcesContent: [
							"$font-family: Roboto, Arial, sans-serif;\n.measure-tool-context-menu {\n  font-family: $font-family;\n  border: 1px solid rgba(0,0,0,0.2);\n  outline: none;\n  position: fixed;\n  display: none;\n\n  box-shadow: 0 2px 2px rgba(0,0,0,0.2);\n  transform: translate3d(0,0,0);\n  max-width: 265px;\n  z-index: 1;\n  outline-offset: -2px;\n\n  background: #fff;\n  padding: 6px 0;\n  white-space: nowrap;\n  cursor: default;\n  margin: 0;\n  ul {\n    padding: 0px;\n    margin: 0px;\n    background-color: white;\n\n    li {\n      cursor: pointer;\n      display: flex;\n      align-items: center;\n      border-color: transparent;\n      border-style: dotted;\n      border-width: 1px 0;\n      color: #333;\n      font-size: 13px;\n      font-weight: normal;\n      margin: 0;\n      padding: 4px 44px 4px 16px;\n      position: relative;\n      white-space: nowrap;\n\n      &:hover {\n        background-color: #f1f1f1;\n        border-color: #f1f1f1;\n        color: #222;\n        transition: background 0s;\n      }\n    }\n  }\n}",
						],
						sourceRoot: "",
					},
				]);
		},
		function (t, e, n) {
			(e = t.exports = n(5)()),
				e.push([
					t.i,
					".measure-tool-svg-overlay {\n  position: absolute;\n  top: -4000px;\n  left: -4000px;\n  width: 8000px;\n  height: 8000px; }\n\n.dragging-circle,\n.cover-circle {\n  fill: white;\n  stroke: black;\n  stroke-width: 2.5px; }\n  .dragging-circle:hover,\n  .cover-circle:hover {\n    cursor: pointer; }\n\n.grey-circle {\n  fill: #fcfcfc;\n  stroke: #646464;\n  stroke-width: 2.5px;\n  pointer-events: none; }\n\n.base-line {\n  fill: none;\n  stroke: black;\n  stroke-width: 2.5px; }\n\n.aux-line {\n  fill: none;\n  stroke: transparent;\n  stroke-width: 8px;\n  cursor: pointer; }\n\n.segment-measure-text {\n  stroke: black;\n  pointer-events: none; }\n\n.node-measure-text {\n  text-shadow: -1.4px -1.4px rgba(255, 255, 255, 0.4), -1.4px 1.4px rgba(255, 255, 255, 0.4), 1.4px 1.4px rgba(255, 255, 255, 0.4), 1.4px -1.4px rgba(255, 255, 255, 0.4), -1.4px 0 rgba(255, 255, 255, 0.4), 0 1.4px rgba(255, 255, 255, 0.4), 1.4px 0 rgba(255, 255, 255, 0.4), 0 -1.4px rgba(255, 255, 255, 0.4);\n  pointer-events: none; }\n  .node-measure-text.head-text {\n    visibility: hidden; }\n",
					"",
					{
						version: 3,
						sources: [
							"/home/alex/ambiente/ceabs/frotas/frontend/external-components/MeasureTool-GoogleMaps-V3/src/index.scss",
						],
						names: [],
						mappings:
							"AAEA;EACE,mBAAkB;EAClB,aAAY;EACZ,cAAa;EACb,cAAa;EACb,eAAc,EACf;;AAED;;EAEE,YAAW;EACX,cAAa;EACb,oBAAmB,EAIpB;EARD;;IAMI,gBAAe,EAChB;;AAGH;EACE,cAAwB;EACxB,gBAA0B;EAC1B,oBAAmB;EACnB,qBAAoB,EAIrB;;AAED;EACE,WAAU;EACV,cAAa;EACb,oBAAmB,EACpB;;AAED;EACE,WAAU;EACV,oBAAmB;EACnB,kBAAiB;EACjB,gBAAe,EAChB;;AAED;EACE,cAAa;EACb,qBAAoB,EACrB;;AAED;EACE,kTAjDqC;EAyDrC,qBAAoB,EAKrB;EAdD;IAYI,mBAAkB,EACnB",
						file: "index.scss",
						sourcesContent: [
							"$opaque-white: rgba(255, 255, 255, 0.4);\n\n.measure-tool-svg-overlay{\n  position: absolute;\n  top: -4000px;\n  left: -4000px;\n  width: 8000px;\n  height: 8000px;\n}\n\n.dragging-circle,\n.cover-circle {\n  fill: white;\n  stroke: black;\n  stroke-width: 2.5px;\n  &:hover {\n    cursor: pointer;\n  }\n}\n\n.grey-circle {\n  fill: rgb(252, 252, 252);\n  stroke: rgb(100, 100, 100);\n  stroke-width: 2.5px;\n  pointer-events: none;\n  //&:hover {\n  //  cursor: pointer;\n  //}\n}\n\n.base-line {\n  fill: none;\n  stroke: black;\n  stroke-width: 2.5px;\n}\n\n.aux-line {\n  fill: none;\n  stroke: transparent;\n  stroke-width: 8px;\n  cursor: pointer;\n}\n\n.segment-measure-text {\n  stroke: black;\n  pointer-events: none;\n}\n\n.node-measure-text {\n  text-shadow: -1.4px -1.4px $opaque-white,\n               -1.4px 1.4px $opaque-white,\n                1.4px 1.4px $opaque-white,\n                1.4px -1.4px $opaque-white,\n                -1.4px 0 $opaque-white,\n                0 1.4px $opaque-white,\n                1.4px 0 $opaque-white,\n                0 -1.4px $opaque-white;\n  pointer-events: none;\n\n  &.head-text {\n    visibility: hidden;\n  }\n}\n",
						],
						sourceRoot: "",
					},
				]);
		},
		function (t, e, n) {
			(e = t.exports = n(5)()),
				e.push([
					t.i,
					".measure-tool-tooltip {\n  display: none;\n  font-family: Roboto, Arial, sans-serif;\n  margin: 6px 15px;\n  background-color: #fff;\n  border-radius: 2px;\n  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);\n  padding: 10px;\n  overflow: hidden;\n  pointer-events: none;\n  font-size: 0.7rem;\n  z-index: 999; }\n",
					"",
					{
						version: 3,
						sources: [
							"/home/alex/ambiente/ceabs/frotas/frontend/external-components/MeasureTool-GoogleMaps-V3/src/tooltip.scss",
						],
						names: [],
						mappings:
							"AACA;EACE,cAAa;EACb,uCAHqC;EAIrC,iBAAgB;EAChB,uBAAsB;EACtB,mBAAkB;EAClB,yCAAqC;EACrC,cAAa;EACb,iBAAgB;EAChB,qBAAoB;EACpB,kBAAiB;EACjB,aAAY,EACb",
						file: "tooltip.scss",
						sourcesContent: [
							"$font-family: Roboto, Arial, sans-serif;\n.measure-tool-tooltip {\n  display: none;\n  font-family: $font-family;\n  margin: 6px 15px;\n  background-color: #fff;\n  border-radius: 2px;\n  box-shadow: 0 1px 4px rgba(0,0,0,0.3);\n  padding: 10px;\n  overflow: hidden;\n  pointer-events: none;\n  font-size: 0.7rem;\n  z-index: 999;\n}",
						],
						sourceRoot: "",
					},
				]);
		},
		function (t, e, n) {
			"use strict";
			function i() {
				for (var t, e = 0, n = arguments.length, i = {}; e < n; ++e) {
					if (!(t = arguments[e] + "") || t in i || /[\s.]/.test(t)) throw new Error("illegal type: " + t);
					i[t] = [];
				}
				return new o(i);
			}
			function o(t) {
				this._ = t;
			}
			function r(t, e) {
				return t
					.trim()
					.split(/^|\s+/)
					.map(function (t) {
						var n = "",
							i = t.indexOf(".");
						if ((i >= 0 && ((n = t.slice(i + 1)), (t = t.slice(0, i))), t && !e.hasOwnProperty(t)))
							throw new Error("unknown type: " + t);
						return { type: t, name: n };
					});
			}
			function a(t, e) {
				for (var n, i = 0, o = t.length; i < o; ++i) if ((n = t[i]).name === e) return n.value;
			}
			function s(t, e, n) {
				for (var i = 0, o = t.length; i < o; ++i)
					if (t[i].name === e) {
						(t[i] = u), (t = t.slice(0, i).concat(t.slice(i + 1)));
						break;
					}
				return null != n && t.push({ name: e, value: n }), t;
			}
			var u = { value: function () {} };
			(o.prototype = i.prototype = {
				constructor: o,
				on: function (t, e) {
					var n,
						i = this._,
						o = r(t + "", i),
						u = -1,
						c = o.length;
					{
						if (!(arguments.length < 2)) {
							if (null != e && "function" != typeof e) throw new Error("invalid callback: " + e);
							for (; ++u < c; )
								if ((n = (t = o[u]).type)) i[n] = s(i[n], t.name, e);
								else if (null == e) for (n in i) i[n] = s(i[n], t.name, null);
							return this;
						}
						for (; ++u < c; ) if ((n = (t = o[u]).type) && (n = a(i[n], t.name))) return n;
					}
				},
				copy: function () {
					var t = {},
						e = this._;
					for (var n in e) t[n] = e[n].slice();
					return new o(t);
				},
				call: function (t, e) {
					if ((n = arguments.length - 2) > 0)
						for (var n, i, o = new Array(n), r = 0; r < n; ++r) o[r] = arguments[r + 2];
					if (!this._.hasOwnProperty(t)) throw new Error("unknown type: " + t);
					for (i = this._[t], r = 0, n = i.length; r < n; ++r) i[r].value.apply(e, o);
				},
				apply: function (t, e, n) {
					if (!this._.hasOwnProperty(t)) throw new Error("unknown type: " + t);
					for (var i = this._[t], o = 0, r = i.length; o < r; ++o) i[o].value.apply(e, n);
				},
			}),
				(e.a = i);
		},
		function (t, e, n) {
			"use strict";
			var i = n(37);
			n.d(e, "a", function () {
				return i.a;
			});
		},
		function (t, e, n) {
			"use strict";
			e.a = function (t) {
				return function () {
					return t;
				};
			};
		},
		function (t, e, n) {
			"use strict";
			function i() {
				return !u.event.ctrlKey && !u.event.button;
			}
			function o() {
				return this.parentNode;
			}
			function r(t) {
				return null == t ? { x: u.event.x, y: u.event.y } : t;
			}
			function a() {
				return navigator.maxTouchPoints || "ontouchstart" in this;
			}
			var s = n(38),
				u = n(1),
				c = n(14),
				l = n(15),
				h = n(39),
				f = n(41);
			e.a = function () {
				function t(t) {
					t.on("mousedown.drag", e)
						.filter(L)
						.on("touchstart.drag", g)
						.on("touchmove.drag", v)
						.on("touchend.drag touchcancel.drag", _)
						.style("touch-action", "none")
						.style("-webkit-tap-highlight-color", "rgba(0,0,0,0)");
				}
				function e() {
					if (!b && w.apply(this, arguments)) {
						var t = m("mouse", C.apply(this, arguments), u.mouse, this, arguments);
						t &&
							(n.i(u.select)(u.event.view).on("mousemove.drag", p, !0).on("mouseup.drag", d, !0),
							n.i(c.a)(u.event.view),
							n.i(l.b)(),
							(A = !1),
							(y = u.event.clientX),
							(x = u.event.clientY),
							t("start"));
					}
				}
				function p() {
					if ((n.i(l.a)(), !A)) {
						var t = u.event.clientX - y,
							e = u.event.clientY - x;
						A = t * t + e * e > P;
					}
					E.mouse("drag");
				}
				function d() {
					n.i(u.select)(u.event.view).on("mousemove.drag mouseup.drag", null),
						n.i(c.b)(u.event.view, A),
						n.i(l.a)(),
						E.mouse("end");
				}
				function g() {
					if (w.apply(this, arguments)) {
						var t,
							e,
							i = u.event.changedTouches,
							o = C.apply(this, arguments),
							r = i.length;
						for (t = 0; t < r; ++t) (e = m(i[t].identifier, o, u.touch, this, arguments)) && (n.i(l.b)(), e("start"));
					}
				}
				function v() {
					var t,
						e,
						i = u.event.changedTouches,
						o = i.length;
					for (t = 0; t < o; ++t) (e = E[i[t].identifier]) && (n.i(l.a)(), e("drag"));
				}
				function _() {
					var t,
						e,
						i = u.event.changedTouches,
						o = i.length;
					for (
						b && clearTimeout(b),
							b = setTimeout(function () {
								b = null;
							}, 500),
							t = 0;
						t < o;
						++t
					)
						(e = E[i[t].identifier]) && (n.i(l.b)(), e("end"));
				}
				function m(e, i, o, r, a) {
					var s,
						c,
						l,
						h = o(i, e),
						p = k.copy();
					if (
						n.i(u.customEvent)(new f.a(t, "beforestart", s, e, B, h[0], h[1], 0, 0, p), function () {
							return (
								null != (u.event.subject = s = T.apply(r, a)) && ((c = s.x - h[0] || 0), (l = s.y - h[1] || 0), !0)
							);
						})
					)
						return function d(g) {
							var v,
								_ = h;
							switch (g) {
								case "start":
									(E[e] = d), (v = B++);
									break;
								case "end":
									delete E[e], --B;
								case "drag":
									(h = o(i, e)), (v = B);
							}
							n.i(u.customEvent)(new f.a(t, g, s, e, v, h[0] + c, h[1] + l, h[0] - _[0], h[1] - _[1], p), p.apply, p, [
								g,
								r,
								a,
							]);
						};
				}
				var y,
					x,
					A,
					b,
					w = i,
					C = o,
					T = r,
					L = a,
					E = {},
					k = n.i(s.a)("start", "drag", "end"),
					B = 0,
					P = 0;
				return (
					(t.filter = function (e) {
						return arguments.length ? ((w = "function" == typeof e ? e : n.i(h.a)(!!e)), t) : w;
					}),
					(t.container = function (e) {
						return arguments.length ? ((C = "function" == typeof e ? e : n.i(h.a)(e)), t) : C;
					}),
					(t.subject = function (e) {
						return arguments.length ? ((T = "function" == typeof e ? e : n.i(h.a)(e)), t) : T;
					}),
					(t.touchable = function (e) {
						return arguments.length ? ((L = "function" == typeof e ? e : n.i(h.a)(!!e)), t) : L;
					}),
					(t.on = function () {
						var e = k.on.apply(k, arguments);
						return e === k ? t : e;
					}),
					(t.clickDistance = function (e) {
						return arguments.length ? ((P = (e = +e) * e), t) : Math.sqrt(P);
					}),
					t
				);
			};
		},
		function (t, e, n) {
			"use strict";
			function i(t, e, n, i, o, r, a, s, u, c) {
				(this.target = t),
					(this.type = e),
					(this.subject = n),
					(this.identifier = i),
					(this.active = o),
					(this.x = r),
					(this.y = a),
					(this.dx = s),
					(this.dy = u),
					(this._ = c);
			}
			(e.a = i),
				(i.prototype.on = function () {
					var t = this._.on.apply(this._, arguments);
					return t === this._ ? this : t;
				});
		},
		function (t, e, n) {
			"use strict";
			e.a = function (t) {
				return function () {
					return t;
				};
			};
		},
		function (t, e, n) {
			"use strict";
			var i = n(2),
				o = n(17);
			e.a = function (t) {
				return n.i(o.a)(n.i(i.a)(t).call(document.documentElement));
			};
		},
		function (t, e, n) {
			"use strict";
			function i() {
				return new o();
			}
			function o() {
				this._ = "@" + (++r).toString(36);
			}
			e.a = i;
			var r = 0;
			o.prototype = i.prototype = {
				constructor: o,
				get: function (t) {
					for (var e = this._; !(e in t); ) if (!(t = t.parentNode)) return;
					return t[e];
				},
				set: function (t, e) {
					return (t[this._] = e);
				},
				remove: function (t) {
					return this._ in t && delete t[this._];
				},
				toString: function () {
					return this._;
				},
			};
		},
		function (t, e, n) {
			"use strict";
			var i = n(10),
				o = n(3);
			e.a = function (t) {
				var e = n.i(i.a)();
				return e.changedTouches && (e = e.changedTouches[0]), n.i(o.a)(t, e);
			};
		},
		function (t, e, n) {
			"use strict";
			var i = n(0);
			e.a = function (t) {
				return "string" == typeof t
					? new i.b([document.querySelectorAll(t)], [document.documentElement])
					: new i.b([null == t ? [] : t], i.c);
			};
		},
		function (t, e, n) {
			"use strict";
			var i = n(2);
			e.a = function (t) {
				var e = "function" == typeof t ? t : n.i(i.a)(t);
				return this.select(function () {
					return this.appendChild(e.apply(this, arguments));
				});
			};
		},
		function (t, e, n) {
			"use strict";
			function i(t) {
				return function () {
					this.removeAttribute(t);
				};
			}
			function o(t) {
				return function () {
					this.removeAttributeNS(t.space, t.local);
				};
			}
			function r(t, e) {
				return function () {
					this.setAttribute(t, e);
				};
			}
			function a(t, e) {
				return function () {
					this.setAttributeNS(t.space, t.local, e);
				};
			}
			function s(t, e) {
				return function () {
					var n = e.apply(this, arguments);
					null == n ? this.removeAttribute(t) : this.setAttribute(t, n);
				};
			}
			function u(t, e) {
				return function () {
					var n = e.apply(this, arguments);
					null == n ? this.removeAttributeNS(t.space, t.local) : this.setAttributeNS(t.space, t.local, n);
				};
			}
			var c = n(6);
			e.a = function (t, e) {
				var l = n.i(c.a)(t);
				if (arguments.length < 2) {
					var h = this.node();
					return l.local ? h.getAttributeNS(l.space, l.local) : h.getAttribute(l);
				}
				return this.each(
					(null == e ? (l.local ? o : i) : "function" == typeof e ? (l.local ? u : s) : l.local ? a : r)(l, e)
				);
			};
		},
		function (t, e, n) {
			"use strict";
			e.a = function () {
				var t = arguments[0];
				return (arguments[0] = this), t.apply(null, arguments), this;
			};
		},
		function (t, e, n) {
			"use strict";
			function i(t) {
				return t.trim().split(/^|\s+/);
			}
			function o(t) {
				return t.classList || new r(t);
			}
			function r(t) {
				(this._node = t), (this._names = i(t.getAttribute("class") || ""));
			}
			function a(t, e) {
				for (var n = o(t), i = -1, r = e.length; ++i < r; ) n.add(e[i]);
			}
			function s(t, e) {
				for (var n = o(t), i = -1, r = e.length; ++i < r; ) n.remove(e[i]);
			}
			function u(t) {
				return function () {
					a(this, t);
				};
			}
			function c(t) {
				return function () {
					s(this, t);
				};
			}
			function l(t, e) {
				return function () {
					(e.apply(this, arguments) ? a : s)(this, t);
				};
			}
			(r.prototype = {
				add: function (t) {
					this._names.indexOf(t) < 0 && (this._names.push(t), this._node.setAttribute("class", this._names.join(" ")));
				},
				remove: function (t) {
					var e = this._names.indexOf(t);
					e >= 0 && (this._names.splice(e, 1), this._node.setAttribute("class", this._names.join(" ")));
				},
				contains: function (t) {
					return this._names.indexOf(t) >= 0;
				},
			}),
				(e.a = function (t, e) {
					var n = i(t + "");
					if (arguments.length < 2) {
						for (var r = o(this.node()), a = -1, s = n.length; ++a < s; ) if (!r.contains(n[a])) return !1;
						return !0;
					}
					return this.each(("function" == typeof e ? l : e ? u : c)(n, e));
				});
		},
		function (t, e, n) {
			"use strict";
			function i() {
				var t = this.cloneNode(!1),
					e = this.parentNode;
				return e ? e.insertBefore(t, this.nextSibling) : t;
			}
			function o() {
				var t = this.cloneNode(!0),
					e = this.parentNode;
				return e ? e.insertBefore(t, this.nextSibling) : t;
			}
			e.a = function (t) {
				return this.select(t ? o : i);
			};
		},
		function (t, e, n) {
			"use strict";
			function i(t, e, n, i, o, r) {
				for (var s, u = 0, c = e.length, l = r.length; u < l; ++u)
					(s = e[u]) ? ((s.__data__ = r[u]), (i[u] = s)) : (n[u] = new a.b(t, r[u]));
				for (; u < c; ++u) (s = e[u]) && (o[u] = s);
			}
			function o(t, e, n, i, o, r, s) {
				var c,
					l,
					h,
					f = {},
					p = e.length,
					d = r.length,
					g = new Array(p);
				for (c = 0; c < p; ++c)
					(l = e[c]) && ((g[c] = h = u + s.call(l, l.__data__, c, e)), h in f ? (o[c] = l) : (f[h] = l));
				for (c = 0; c < d; ++c)
					(h = u + s.call(t, r[c], c, r)),
						(l = f[h]) ? ((i[c] = l), (l.__data__ = r[c]), (f[h] = null)) : (n[c] = new a.b(t, r[c]));
				for (c = 0; c < p; ++c) (l = e[c]) && f[g[c]] === l && (o[c] = l);
			}
			var r = n(0),
				a = n(18),
				s = n(42),
				u = "$";
			e.a = function (t, e) {
				if (!t)
					return (
						(m = new Array(this.size())),
						(d = -1),
						this.each(function (t) {
							m[++d] = t;
						}),
						m
					);
				var a = e ? o : i,
					u = this._parents,
					c = this._groups;
				"function" != typeof t && (t = n.i(s.a)(t));
				for (var l = c.length, h = new Array(l), f = new Array(l), p = new Array(l), d = 0; d < l; ++d) {
					var g = u[d],
						v = c[d],
						_ = v.length,
						m = t.call(g, g && g.__data__, d, u),
						y = m.length,
						x = (f[d] = new Array(y)),
						A = (h[d] = new Array(y));
					a(g, v, x, A, (p[d] = new Array(_)), m, e);
					for (var b, w, C = 0, T = 0; C < y; ++C)
						if ((b = x[C])) {
							for (C >= T && (T = C + 1); !(w = A[T]) && ++T < y; );
							b._next = w || null;
						}
				}
				return (h = new r.b(h, u)), (h._enter = f), (h._exit = p), h;
			};
		},
		function (t, e, n) {
			"use strict";
			e.a = function (t) {
				return arguments.length ? this.property("__data__", t) : this.node().__data__;
			};
		},
		function (t, e, n) {
			"use strict";
			function i(t, e, i) {
				var o = n.i(a.a)(t),
					r = o.CustomEvent;
				"function" == typeof r
					? (r = new r(e, i))
					: ((r = o.document.createEvent("Event")),
					  i ? (r.initEvent(e, i.bubbles, i.cancelable), (r.detail = i.detail)) : r.initEvent(e, !1, !1)),
					t.dispatchEvent(r);
			}
			function o(t, e) {
				return function () {
					return i(this, t, e);
				};
			}
			function r(t, e) {
				return function () {
					return i(this, t, e.apply(this, arguments));
				};
			}
			var a = n(11);
			e.a = function (t, e) {
				return this.each(("function" == typeof e ? r : o)(t, e));
			};
		},
		function (t, e, n) {
			"use strict";
			e.a = function (t) {
				for (var e = this._groups, n = 0, i = e.length; n < i; ++n)
					for (var o, r = e[n], a = 0, s = r.length; a < s; ++a) (o = r[a]) && t.call(o, o.__data__, a, r);
				return this;
			};
		},
		function (t, e, n) {
			"use strict";
			e.a = function () {
				return !this.node();
			};
		},
		function (t, e, n) {
			"use strict";
			var i = n(19),
				o = n(0);
			e.a = function () {
				return new o.b(this._exit || this._groups.map(i.a), this._parents);
			};
		},
		function (t, e, n) {
			"use strict";
			var i = n(0),
				o = n(16);
			e.a = function (t) {
				"function" != typeof t && (t = n.i(o.a)(t));
				for (var e = this._groups, r = e.length, a = new Array(r), s = 0; s < r; ++s)
					for (var u, c = e[s], l = c.length, h = (a[s] = []), f = 0; f < l; ++f)
						(u = c[f]) && t.call(u, u.__data__, f, c) && h.push(u);
				return new i.b(a, this._parents);
			};
		},
		function (t, e, n) {
			"use strict";
			function i() {
				this.innerHTML = "";
			}
			function o(t) {
				return function () {
					this.innerHTML = t;
				};
			}
			function r(t) {
				return function () {
					var e = t.apply(this, arguments);
					this.innerHTML = null == e ? "" : e;
				};
			}
			e.a = function (t) {
				return arguments.length
					? this.each(null == t ? i : ("function" == typeof t ? r : o)(t))
					: this.node().innerHTML;
			};
		},
		function (t, e, n) {
			"use strict";
			function i() {
				return null;
			}
			var o = n(2),
				r = n(9);
			e.a = function (t, e) {
				var a = "function" == typeof t ? t : n.i(o.a)(t),
					s = null == e ? i : "function" == typeof e ? e : n.i(r.a)(e);
				return this.select(function () {
					return this.insertBefore(a.apply(this, arguments), s.apply(this, arguments) || null);
				});
			};
		},
		function (t, e, n) {
			"use strict";
			e.a = function (t, e, n) {
				var i = this.enter(),
					o = this,
					r = this.exit();
				return (
					(i = "function" == typeof t ? t(i) : i.append(t + "")),
					null != e && (o = e(o)),
					null == n ? r.remove() : n(r),
					i && o ? i.merge(o).order() : o
				);
			};
		},
		function (t, e, n) {
			"use strict";
			function i() {
				this.previousSibling && this.parentNode.insertBefore(this, this.parentNode.firstChild);
			}
			e.a = function () {
				return this.each(i);
			};
		},
		function (t, e, n) {
			"use strict";
			var i = n(0);
			e.a = function (t) {
				for (
					var e = this._groups, n = t._groups, o = e.length, r = n.length, a = Math.min(o, r), s = new Array(o), u = 0;
					u < a;
					++u
				)
					for (var c, l = e[u], h = n[u], f = l.length, p = (s[u] = new Array(f)), d = 0; d < f; ++d)
						(c = l[d] || h[d]) && (p[d] = c);
				for (; u < o; ++u) s[u] = e[u];
				return new i.b(s, this._parents);
			};
		},
		function (t, e, n) {
			"use strict";
			e.a = function () {
				for (var t = this._groups, e = 0, n = t.length; e < n; ++e)
					for (var i = t[e], o = 0, r = i.length; o < r; ++o) {
						var a = i[o];
						if (a) return a;
					}
				return null;
			};
		},
		function (t, e, n) {
			"use strict";
			e.a = function () {
				var t = new Array(this.size()),
					e = -1;
				return (
					this.each(function () {
						t[++e] = this;
					}),
					t
				);
			};
		},
		function (t, e, n) {
			"use strict";
			e.a = function () {
				for (var t = this._groups, e = -1, n = t.length; ++e < n; )
					for (var i, o = t[e], r = o.length - 1, a = o[r]; --r >= 0; )
						(i = o[r]) && (a && 4 ^ i.compareDocumentPosition(a) && a.parentNode.insertBefore(i, a), (a = i));
				return this;
			};
		},
		function (t, e, n) {
			"use strict";
			function i(t) {
				return function () {
					delete this[t];
				};
			}
			function o(t, e) {
				return function () {
					this[t] = e;
				};
			}
			function r(t, e) {
				return function () {
					var n = e.apply(this, arguments);
					null == n ? delete this[t] : (this[t] = n);
				};
			}
			e.a = function (t, e) {
				return arguments.length > 1
					? this.each((null == e ? i : "function" == typeof e ? r : o)(t, e))
					: this.node()[t];
			};
		},
		function (t, e, n) {
			"use strict";
			function i() {
				this.nextSibling && this.parentNode.appendChild(this);
			}
			e.a = function () {
				return this.each(i);
			};
		},
		function (t, e, n) {
			"use strict";
			function i() {
				var t = this.parentNode;
				t && t.removeChild(this);
			}
			e.a = function () {
				return this.each(i);
			};
		},
		function (t, e, n) {
			"use strict";
			var i = n(0),
				o = n(9);
			e.a = function (t) {
				"function" != typeof t && (t = n.i(o.a)(t));
				for (var e = this._groups, r = e.length, a = new Array(r), s = 0; s < r; ++s)
					for (var u, c, l = e[s], h = l.length, f = (a[s] = new Array(h)), p = 0; p < h; ++p)
						(u = l[p]) &&
							(c = t.call(u, u.__data__, p, l)) &&
							("__data__" in u && (c.__data__ = u.__data__), (f[p] = c));
				return new i.b(a, this._parents);
			};
		},
		function (t, e, n) {
			"use strict";
			var i = n(0),
				o = n(21);
			e.a = function (t) {
				"function" != typeof t && (t = n.i(o.a)(t));
				for (var e = this._groups, r = e.length, a = [], s = [], u = 0; u < r; ++u)
					for (var c, l = e[u], h = l.length, f = 0; f < h; ++f)
						(c = l[f]) && (a.push(t.call(c, c.__data__, f, l)), s.push(c));
				return new i.b(a, s);
			};
		},
		function (t, e, n) {
			"use strict";
			e.a = function () {
				var t = 0;
				return (
					this.each(function () {
						++t;
					}),
					t
				);
			};
		},
		function (t, e, n) {
			"use strict";
			function i(t, e) {
				return t < e ? -1 : t > e ? 1 : t >= e ? 0 : NaN;
			}
			var o = n(0);
			e.a = function (t) {
				function e(e, n) {
					return e && n ? t(e.__data__, n.__data__) : !e - !n;
				}
				t || (t = i);
				for (var n = this._groups, r = n.length, a = new Array(r), s = 0; s < r; ++s) {
					for (var u, c = n[s], l = c.length, h = (a[s] = new Array(l)), f = 0; f < l; ++f) (u = c[f]) && (h[f] = u);
					h.sort(e);
				}
				return new o.b(a, this._parents).order();
			};
		},
		function (t, e, n) {
			"use strict";
			function i() {
				this.textContent = "";
			}
			function o(t) {
				return function () {
					this.textContent = t;
				};
			}
			function r(t) {
				return function () {
					var e = t.apply(this, arguments);
					this.textContent = null == e ? "" : e;
				};
			}
			e.a = function (t) {
				return arguments.length
					? this.each(null == t ? i : ("function" == typeof t ? r : o)(t))
					: this.node().textContent;
			};
		},
		function (t, e, n) {
			"use strict";
			var i = n(10),
				o = n(3);
			e.a = function (t, e, r) {
				arguments.length < 3 && ((r = e), (e = n.i(i.a)().changedTouches));
				for (var a, s = 0, u = e ? e.length : 0; s < u; ++s) if ((a = e[s]).identifier === r) return n.i(o.a)(t, a);
				return null;
			};
		},
		function (t, e, n) {
			"use strict";
			var i = n(10),
				o = n(3);
			e.a = function (t, e) {
				null == e && (e = n.i(i.a)().touches);
				for (var r = 0, a = e ? e.length : 0, s = new Array(a); r < a; ++r) s[r] = n.i(o.a)(t, e[r]);
				return s;
			};
		},
		function (t, e, n) {
			var i = n(34);
			"string" == typeof i && (i = [[t.i, i, ""]]);
			n(12)(i, {});
			i.locals && (t.exports = i.locals);
		},
		function (t, e, n) {
			var i = n(36);
			"string" == typeof i && (i = [[t.i, i, ""]]);
			n(12)(i, {});
			i.locals && (t.exports = i.locals);
		},
	]);
});
