const EVENTS_OBJECT_COLUMNS = [
	{
		name: "Ignicao",
		label: "Ignição",
		checked: true,
	},
	{
		name: "Placa",
		label: "Placa",
		checked: true,
	},
	{
		name: "ProprietarioNome",
		label: "Proprietário",
		checked: true,
	},
	{
		name: "Marca",
		label: "Marca",
		checked: true,
	},
	{
		name: "Frota",
		label: "Frota",
		checked: true,
	},
	{
		name: "Data",
		label: "Data",
		checked: true,
	},
	{
		name: "TipoEvento",
		label: "Evento",
		checked: true,
	},
	{
		name: "Velocidade",
		label: "Velocidade",
		checked: true,
	},
	{
		name: "Endereco",
		label: "Endereço",
		checked: true,
	},
	{
		name: "PontoMaisProximo",
		label: "Ponto mais próximo",
		checked: true,
	},
	{
		name: "Numero",
		label: "Número",
		checked: true,
	},
	{
		name: "CEP",
		label: "CEP",
		checked: true,
	},
	{
		name: "Cidade",
		label: "Cidade",
		checked: true,
	},
	{
		name: "UF",
		label: "UF",
		checked: true,
	},
	{
		name: "PavimentoTipo",
		label: "Pavimento",
		checked: true,
	},
	{
		name: "MotoristaNome",
		label: "Últ. Motorista",
		checked: true,
	},
];

export { EVENTS_OBJECT_COLUMNS };
