import axios from 'axios';
import { addInterceptors } from './interceptors';

const _instance = null;

const getInstance = () => {
  if (_instance !== null) return _instance;

  const config = {
    baseURL: process.env.REACT_APP_URL_API || '/api',
    timeout: process.env.TIMEOUT || 60000 // 30 segundos
  };

  let newInstance = axios.create(config);
  newInstance = addInterceptors(newInstance);

  return newInstance;
};

export default getInstance();
