import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Utils from "~/helpers/Utils";
import Labels from "~/helpers/enums/Labels";
import _ from "lodash";

import { useHistory } from "react-router-dom";

import fleetActions from "~/actions/fleets/fleetsActions";
import fleetAction from "~/actions/fleetAction";

import { Form, Formik } from "formik";
import { Button, CircularProgress, Grid, Typography, Box } from "@material-ui/core";

import RegisterInputText from "~/components/common/registerInputs/RegisterInputText";
import CheckboxPGF from "~/components/common/inputs/checkbox/CheckboxPGF";
import Autocomplete from "~/components/common/autocomplete/AutoComplete";
import Constants from "~/helpers/enums/Constants";

import { CancelIcon, ConfirmIcon, WhiteVehicleIcon } from "~/components/common/icons/Icons";
import "./TabsFleets.scss";

const EditFleet = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let history = useHistory();

    const { loading } = useSelector((state) => state.app);
    const { data } = useSelector((state) => state.fleet);
    const { fleet, filter } = useSelector((state) => state.fleetsRegister);

    const [initialValues, setInitialValues] = useState("");

    useEffect(() => {
        dispatch(fleetAction.fleetData());
    }, [dispatch]);

    useEffect(() => {
        if (!_.isEmpty(fleet) && !_.isEmpty(data)) {
            let fleetCheckAdd = {};
            data.forEach((item) => {
                if (parseInt(item.id) === fleet.GrupoPaiId) {
                    fleetCheckAdd = item;
                }
            });

            let initialValue = {
                Nome: fleet.Nome,
                FleetId: fleetCheckAdd,
                ActiveEdit: fleet.Ativo,
            };
            setInitialValues(initialValue);
        }
    }, [fleet, data]);

    return (
        <Box className="panel-edit-fleet">
            <Formik
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={initialValues}
                validate={(values) => {
                    const errors = {};

                    if (!values.Nome) {
                        errors.Nome = t(Labels.GROUP_DRIVERS_REGISTER_REQUIRED);
                    }

                    if (values.FleetId.id === fleet.Id) {
                        errors.FleetId = t(Labels.GROUP_DRIVERS_REGISTER_REQUIRED);
                    }

                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    dispatch(
                        fleetActions.saveFleets(
                            {
                                name: values.Nome,
                                active: values.ActiveEdit,
                                parentGroup: values.FleetId?.id,
                                Id: fleet.Id,
                                TipoDispositivo: fleet.TipoDispositivo,
                            },
                            (_res) => {
                                if (typeof _res === "object") {
                                    Utils.showTranslatedToast({
                                        type: Constants.ERROR,
                                        description:
                                            "Não foi possível salvar a frota. Favor entrar em contato com a central.",
                                    });
                                    return;
                                }

                                Utils.showTranslatedToast({
                                    type: Constants.SUCCESS,
                                    description: _res,
                                });

                                dispatch(
                                    fleetActions.updateFilterFleet({
                                        ...filter,
                                    })
                                );

                                dispatch(fleetAction.fleetData());

                                props.closeTab();
                            }
                        )
                    );
                }}
                render={({ submitForm, resetForm }) => {
                    return (
                        <Form id="edit-fleet-form" className="ceabs-register-form fleet-form">
                            <Grid container direction="column" spacing={3}>
                                <Grid item xs={4}>
                                    <Button
                                        startIcon={<WhiteVehicleIcon />}
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        disableElevation
                                        className="top-button"
                                        variant="contained"
                                        onClick={() => {
                                            history.push("/register/fleet/carsLink");
                                        }}>
                                        {t(Labels.ALERT_EDIT_BUTTON_BOND)}
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <RegisterInputText
                                        placeholder={t(Labels.GROUP_DRIVERS_REGISTER_NAME_PLACEHOLDER)}
                                        label={t(Labels.GROUP_DRIVERS_REGISTER_NAME)}
                                        name="Nome"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        name="FleetId"
                                        isRegisterForm
                                        disabled={!fleet.GrupoPaiId}
                                        label={t(Labels.REPORT_LABEL_FLEET)}
                                        placeholder={t(Labels.REPORT_LABEL_PLACEHOLDER)}
                                        dataSource={data ? data : []}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <span className="register-field">
                                        <Typography className="register-field-label">
                                            {t(Labels.STATUS_LABEL)}
                                        </Typography>
                                    </span>
                                    <CheckboxPGF
                                        color="primary"
                                        id="ActiveEdit"
                                        name="ActiveEdit"
                                        label={t(Labels.GROUP_DRIVERS_REGISTER_ACTIVE)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justify="flex-end" spacing={1}>
                                        <Grid className="button-form button-form-refactor" item xs={3}>
                                            <Button
                                                id="cancelButton"
                                                className="cancel-button cancel-button-refactor"
                                                fullWidth
                                                variant="contained"
                                                disableElevation
                                                onClick={props.closeTab}>
                                                <CancelIcon />
                                                {t(Labels.VEHICLE_FORM_BUTTON_CANCEL)}
                                            </Button>
                                        </Grid>
                                        <Grid className="button-form button-form-refactor" item xs={5}>
                                            <Button
                                                id="nextButton"
                                                className="save-button save-button-refactor"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                disableElevation
                                                disabled={loading.saveFleets}
                                                startIcon={
                                                    loading.saveFleets ? (
                                                        <CircularProgress size={18} />
                                                    ) : (
                                                        <ConfirmIcon />
                                                    )
                                                }
                                                onClick={submitForm}>
                                                {t(Labels.SAVE_LABEL)}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            />
        </Box>
    );
};

export default EditFleet;
