import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import vehicleSearchService from "~/services/vehicleSearchService";

const vehicleData = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "vehicleDataSearch") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return vehicleSearchService
		.list(filter)
		.then((response) => {
			let data = response.data.results;
			let payload = {
				data: data,
			};
			dispatch({ type: Constants.GET_DATA_VEHICLE, payload });
			callback(false);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

export default {
	vehicleData,
};
