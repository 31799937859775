import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import Utils from "~/helpers/Utils";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import groupPointsAction from "~/actions/groupPointsAction";

import { useHistory } from "react-router-dom";

import { lighten } from "polished";

import { Box, Button, makeStyles, Typography } from "@material-ui/core";

import CardGroup from "~/components/common/card/CardGroup";

import { ColoredEditIcon, AddIcon, EditIconWhite } from "~/components/common/icons/Icons";

import "./GroupPointsFinder.scss";

const useStyles = makeStyles((theme) => ({
    root: {
        background: lighten(0.3, theme.palette.primary.main),
        cursor: "pointer",
        width: "90%",
    },
}));

const GroupPointsFinder = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { groups, pointsLinked } = useSelector((state) => state.groupPoints);
    const loadingReq = useSelector((state) => state.app.loading);
    const { user } = useSelector((state) => state.auth);

    let history = useHistory();

    const [display, setDisplayGroup] = useState(false);
    const [page, setPage] = React.useState(1);
    const [searchTerm, setTerm] = React.useState("");
    const [groupData, setGroupData] = React.useState("");

    const handlerEdit = (id, row) => {
        history.push(
            {
                pathname: `/register/groupOfPointsInterest/${id}`,
            },
            row
        );
    };
    const LINKED_GROUP_POINTS_COLUMNS = [
        {
            name: "Nome",
            label: t(Labels.GROUP_POINTS_COLUMN_POINTS),
            render: (name) => <strong title={name}>{Utils.TextView(name, 32)}</strong>,
        },
        {
            name: "Ativo",
            label: t(Labels.GROUP_POINTS_COLUMN_ACTIVE),
            render: (active) => <span>{active ? "Sim" : "Não"}</span>,
        },
    ];
    const GROUP_POINTS_COLUMNS = [
        {
            name: "Id",
            label: t(Labels.GROUP_POINTS_COLUMN_ACTIONS),
            render: (Id, row) => (
                <span
					id="points-group-actions-column"
                    onClick={() => {
                        handlerEdit(Id, row);
                    }}>
                    <span style={{ color: user.colors.topBarColor, marginRight: 10, marginLeft: -6 }}>
                        <ColoredEditIcon style={{ marginRight: 3 }} />
                        {t(Labels.GROUP_DRIVERS_LIST_BTN_EDIT)}
                    </span>
                </span>
            ),
        },
        {
            name: "Nome",
            label: t(Labels.GROUP_POINTS_COLUMN_GROUP_POINTS),
            render: (name) => <strong title={name}>{Utils.TextView(name, 32)}</strong>,
        },
        {
            name: "Id",
            label: t(Labels.GROUP_POINTS_COLUMN_CODE),
            render: (code) => <span>{code}</span>,
        },
        {
            name: "Ativo",
            label: t(Labels.GROUP_POINTS_COLUMN_ACTIVE),
            render: (active) => <span>{active ? "Sim" : "Não"}</span>,
        },
    ];

    useEffect(() => {
        dispatch(groupPointsAction.getGroupsPoints());
    }, [dispatch]);

    const selectGroupDriver = (data) => {
        setTimeout(() => {
            setDisplayGroup(true);
            dispatch(groupPointsAction.getPointsLinkedGroupById(data.Id, { page: 0, length: 25 }));
            setGroupData(data);
            setPage(1);
        }, 500);
    };

    const handlePagePointsLinked = (event, value) => {
        setPage(value);

        const query = {
            page: value * 1 - 1,
            length: 25,
            search: searchTerm,
        };

        dispatch(
            groupPointsAction.getPointsLinkedGroupById(groupData.Id, {
                ...query,
            })
        );
    };

    const searchFn = (term) => {
        setPage(1);
        setTerm(term);
        const query = {
            page: 0,
            length: 25,
            search: term,
        };

        dispatch(
            groupPointsAction.getPointsLinkedGroupById(groupData.Id, {
                ...query,
            })
        );
    };

    const classes = useStyles();

    return (
        <Box className="box-items-group">
            <Box className="box-group">
                <Button
                    data-id={String(t(Labels.GROUP_POINTS_LIST_BTNADD) + "-id")
                        .replace(/ de /g, "")
                        .replace(/\s/g, "")}
                    className="btn-add-group-items btn-add-group-items-smaller"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => {
                        history.push("/register/groupOfPointsInterest/new");
                    }}>
                    <Typography className="import-font">
                        <AddIcon />
                        {t(Labels.GROUP_POINTS_LIST_BTNADD)}
                    </Typography>
                </Button>
                <div className="box-group-card">
                    <div className="box-card-group-item">
                        <CardGroup
                            data-id={String(t(Labels.GROUP_POINTS_LIST_LABEL) + "-id")
                                .replace(/ de /g, "")
                                .replace(/\s/g, "")}
                            title={t(Labels.GROUP_POINTS_LIST_LABEL)}
                            className="group-items"
                            classHeader={"search-group-item"}
                            loading={loadingReq.getPointsGroup}
                            columns={GROUP_POINTS_COLUMNS}
                            source={groups}
                            select={selectGroupDriver}
                        />
                    </div>
                    <div id="group-vinculated-points-finder" className="box-card-group-item">
                        {display && (
                            <CardGroup
                                data-id={String(t(Labels.GROUP_POINTS_LINKED_LABEL) + "-id")
                                    .replace(/ de /g, "")
                                    .replace(/\s/g, "")}
                                title={t(Labels.GROUP_POINTS_LINKED_LABEL)}
                                className={`group-items ${classes.root}`}
                                classHeader={"search-group-item"}
                                headerComp={
                                    <Button
                                        data-id={"edit-linked-id-points"}
                                        className="btn-edit-linked"
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        onClick={() => {
                                            handlerEdit(groupData.Id, groupData);
                                        }}>
                                        <Typography className="import-font">
                                            <EditIconWhite />
                                            {t(Labels.GROUP_POINTS_LIST_BTN_EDIT)}
                                        </Typography>
                                    </Button>
                                }
                                loading={loadingReq.getPointsLinked}
                                columns={LINKED_GROUP_POINTS_COLUMNS}
                                source={pointsLinked.data}
                                backgroundChanged
                                page={page}
                                totalItems={pointsLinked.recordsTotal}
                                itemsPerPage={25}
                                handlePage={handlePagePointsLinked}
                                searchFn={searchFn}
                            />
                        )}
                    </div>
                </div>
            </Box>
        </Box>
    );
};

export default GroupPointsFinder;
