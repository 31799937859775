import Constants from "~/helpers/enums/Constants";

const initialState = {
    periodForm: null,
	objectForm: null,
	scheduleForm: null,
    perObject: {
        filters: {
            vehicle: null,
            driver: "",
            details: false,
            index: 0,
            limit: 25,
            typeReport: 76,
            search: "",
        },
        data: {
            data: [],
        },
    },
    perPeriod: {
        vehicle: "",
        fleet: "",
        period: {},
        email: "",
    },
    scheduling: {
        edition: {},
        data: [],
    },
    sent: {
        pagination: {},
        data: [],
    },
    chartData: {
        xAxis: [],
        speedLimitSeries: [],
        speedSeries: [],
        colorSegments: [],
    },
};

function speedingReportReducer(state = initialState, action = null) {
    const { payload } = action;
    switch (action.type) {
        case Constants.GET_SPEEDING_REPORT_SCHEDULING:
            return {
                ...state,
                scheduling: {
                    ...state.scheduling,
                    data: payload.data,
                    pagination: payload.pagination,
                },
            };
        case Constants.UPDATE_FILTER_SPEEDING_OBJECT:
            return {
                ...state,
                perObject: {
                    ...state.perObject,
                    filters: {
                        ...state.perObject.filters,
                        ...action.payload.filter,
                    },
                },
            };
        case Constants.UPDATE_FORM_SPEEDING_PERIOD:
            return {
                ...state,
                periodForm: action.payload.form,
            };
        case Constants.UPDATE_FILTER_SPEEDING_PERIOD:
            return {
                ...state,
                perPeriod: action.payload.filter,
            };
        case Constants.GET_SPEEDING_REPORT_SENT:
            return {
                ...state,
                sent: {
                    data: payload.data,
                    pagination: payload.pagination,
                },
            };
        case Constants.EDIT_SPEEDING_REPORT_SCHEDULING:
            return {
                ...state,
                scheduling: {
                    ...state.scheduling,
                    edition: payload,
                },
            };

        case Constants.GET_SPEEDING_REPORT_PER_OBJECT:
            return {
                ...state,
                chartData: payload,
            };

        case Constants.UPDATE_FORM_SPEEDING_OBJECT:
            return {
                ...state,
                objectForm: action.payload.form,
            };
        case Constants.UPDATE_FORM_SPEEDING_SCHEDULED:
            return {
                ...state,
                scheduleForm: action.payload.form,
            };
        default:
            return state;
    }
}

export default speedingReportReducer;
