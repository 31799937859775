import React from "react";
import { Container, Grid } from "@material-ui/core";
import Page from "~/components/common/page/Page";
import RegisterUser from "~/components/user/register/RegisterUser";

const RegisterUserView = () => {
    return (
        <>
            <Page>
                <main>
                    <Container maxWidth="lg" className="containerRegisterUser container-map-view">
                        <Grid container>
                            <Grid item xs={12}>
                                <RegisterUser />
                            </Grid>
                        </Grid>
                    </Container>
                </main>
            </Page>
        </>
    );
};

export default RegisterUserView;
