import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";

import fleetService from "~/services/fleetService";

const getDataFleetsList = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "getDataFleetsList") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return fleetService
		.getDataFleetsList(filter)
		.then((response) => {
			let data = response.data;

			let payload = {
				data: data,
			};

			dispatch({ type: Constants.GET_DATA_FLEETS_LIST, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getGroupFleets = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "getGroupFleets") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return fleetService
		.getGroupFleets(filter)
		.then((response) => {
			let data = response.data;

			let payload = {
				data: data,
			};

			dispatch({ type: Constants.GET_DATA_GROUP_FLEETS, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const saveFleets = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "saveFleets") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return fleetService
		.saveFleets(filter)
		.then((response) => {
			let data = response.data;
			let payload = {
				data: data,
			};
			callback(data);
			dispatch({ type: Constants.SAVE_FLEETS_DATA, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const selectFleets = (fleet, callback = () => {}, LOADING_IDENTIFICATOR = "selectFleets") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	dispatch({ type: Constants.SELECT_FLEETS_DATA, fleet });

	setTimeout(() => {
		dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
	}, 1000);
};

const importFileFleet = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "importFileFleet") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return fleetService
		.importFileFleet(filter)
		.then((response) => {
			let data = response.data;
			let payload = {
				data: data,
			};
			callback(data, "info");
			dispatch({ type: Constants.IMPORT_FILE_FLEETS, payload });
		})
		.catch((error) => {
			console.log(error);
			callback(error, "error");
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const exportFleet = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "exportFleet") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return fleetService
		.exportFleet(filter)
		.then((response) => {
			let data = response.data;
			let payload = {
				data: data,
			};
			if (response.data.Success) {
				Utils.downloadToLink(response.data.Message);
			} else {
				Utils.showError(response.data.Message);
			}
			dispatch({ type: Constants.EXPORT_FILE_FLEET_DATA, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const saveFleetLinkedObject = (
	groupId,
	carsSelected,
	callback = () => {},
	LOADING_IDENTIFICATOR = "saveFleetLinkedObject"
) => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return fleetService
		.saveFleetLinkedObject(groupId, carsSelected)
		.then((response) => {
			let data = response.data;
			let payload = {
				data: data,
			};
			dispatch({ type: Constants.SAVE_LINKED_FLEET_OBJECT, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const saveFleetUnlinkedObject = (
	groupId,
	carsSelected,
	callback = () => {},
	LOADING_IDENTIFICATOR = "saveFleetUnlinkedObject"
) => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return fleetService
		.saveFleetUnlinkedObject(groupId, carsSelected)
		.then((response) => {
			let data = response.data;
			let payload = {
				data: data,
			};
			dispatch({ type: Constants.SAVE_UNLINKED_FLEET_OBJECT, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getObjectsFleetAvailable = (
	groupId,
	filter,
	callback = () => {},
	LOADING_IDENTIFICATOR = "getObjectsFleetAvailable"
) => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return fleetService
		.getObjectsFleetAvailable(groupId, filter)
		.then((response) => {
			let data = response.data;
			let payload = {
				data: data,
			};
			dispatch({ type: Constants.GET_DATA_FLEETS_OBJ_AVAILABLE, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getObjectsFleetNotAvailable = (
	groupId,
	filter,
	callback = () => {},
	LOADING_IDENTIFICATOR = "getObjectsFleetNotAvailable"
) => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return fleetService
		.getObjectsFleetNotAvailable(groupId, filter)
		.then((response) => {
			let data = response.data;
			let payload = {
				data: data,
			};
			dispatch({ type: Constants.GET_DATA_FLEETS_OBJ_NOT_AVAILABLE, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const cleanCarsLinked = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_CARS_LINKED_FOR_FLEETS });
};

const cleanAvailableCars = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_AVAILABLE_CARS_FOR_USER });
};


const updateFilterFleet = (filter) => (dispatch) => {
	dispatch({ type: Constants.UPDATE_REGISTER_FLEET_FILTER, payload: filter });
};

export default {
	getDataFleetsList,
	saveFleets,
	importFileFleet,
	exportFleet,
	selectFleets,
	saveFleetLinkedObject,
	saveFleetUnlinkedObject,
	cleanCarsLinked,
	cleanAvailableCars,
	getObjectsFleetAvailable,
	getObjectsFleetNotAvailable,
	getGroupFleets,
	updateFilterFleet,
};
