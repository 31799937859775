import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
	mapContext: null,
	objects: [],
	directions: [],
	groupPoints: [],
	points: [],
	routes: [],
	toCentralize: [],
	events: [],
	mapEvents: [],
	syncFuncs: {},
};

function googleMapsDataReducer(state = INITIAL_STATE, action = null) {
	switch (action.type) {
		case Constants.UPDATE_MAPS_CONTEXT:
			return {
				...state,
				mapContext: action.payload.map,
			};
		case Constants.UPDATE_MAPS_OBJECTS:
			return {
				...state,
				objects: action.payload.objects,
			};
		case Constants.UPDATE_MAPS_GROUP_POINTS:
			return {
				...state,
				groupPoints: action.payload.groupPoints,
			};
		case Constants.UPDATE_MAPS_POINTS:
			return {
				...state,
				points: action.payload.points,
			};
		case Constants.UPDATE_MAPS_ROUTES:
			return {
				...state,
				routes: action.payload.routes,
			};
		case Constants.UPDATE_MAPS_DIRECTIONS:
			return {
				...state,
				directions: action.payload.directions,
			};
		case Constants.UPDATE_MAPS_EVENTS:
			return {
				...state,
				events: action.payload.events,
			};
		case Constants.CENTRALIZE_MAPS:
			return {
				...state,
				toCentralize: action.payload.points,
			};
		case Constants.ADD_EVENT_ON_MAP:
			let mapEvent = state.mapEvents.find((x) => x.key === action.payload.key);

			if (mapEvent) {
				mapEvent["func"] = action.payload.func;
				return state;
			} else {
				mapEvent = {
					key: action.payload.key,
					event: action.payload.event,
					func: action.payload.func,
				};

				return {
					...state,
					mapEvents: [...state.mapEvents, mapEvent],
				};
			}
		case Constants.CLEAN_EVENTS_ON_MAP:
			return {
				...state,
				mapEvents: state.mapEvents.map((me) => {
					me.key = null;
					me.func = null;
					return me;
				}),
			};
		case Constants.ADD_SYNC_FUNC:
			const syncFuncs = state.syncFuncs;
			syncFuncs[action.payload.key] = action.payload.syncFunc;

			return {
				...state,
				syncFuncs: syncFuncs,
			};
		case Constants.CLEAN_MAPS_OBJECTS:
			return {
				...state,
				objects: INITIAL_STATE.objects,
			};
		case Constants.CLEAN_MAPS_GROUP_POINTS:
			return {
				...state,
				groupPoints: INITIAL_STATE.groupPoints,
			};
		case Constants.CLEAN_MAPS_POINTS:
			return {
				...state,
				points: INITIAL_STATE.points,
			};
		case Constants.CLEAN_MAPS_ROUTES:
			return {
				...state,
				routes: INITIAL_STATE.routes,
			};
		case Constants.CLEAN_MAPS_EVENTS:
			return {
				...state,
				events: INITIAL_STATE.events,
			};
		case Constants.CLEAN_MAPS_DIRECTIONS:
			return {
				...state,
				directions: INITIAL_STATE.directions,
			};
		case Constants.CLEAN_SYNC_FUNC:
			return {
				...state,
				syncFuncs: INITIAL_STATE.syncFuncs,
			};
		case Constants.CLEAN_MAPS_ALL:
			return INITIAL_STATE;
		default:
			return state;
	}
}

export default googleMapsDataReducer;
