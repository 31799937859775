import http from '~/config/httpRequest/http';


export const validateAccess = (identifier) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/Login/ValidarAcesso`, {
        params: {
          cpfCnpj: identifier
        }
      })
      .then((res) => resolve(res))
      .catch((error) => reject(error));
  });
}

export const createAccess = (access) => {
  return new Promise((resolve, reject) => {
    const body = {
      ClienteIdCps: access.ClienteIdCps,
      ClienteIdCso: access.ClienteIdCso,
      ProprietarioId: access.ProprietarioId,
      Login: access.Login,
      Senha: access.Senha,
      Email: access.Email
    }
    http
      .post(`/Login/SalvarUsuario`, body)
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
}

export const getUserById = (Id = "1374") => {
  return new Promise((resolve, reject) => {
    http
      .get(`/Usuario`, {
        params: { Id }
      })
      .then(res => resolve(res.data))
      .catch(error => reject(error));
  });
};

export const getUsers = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .post(`/Usuario/Buscar`, {
          search: filter.search,
          length: filter.length,
          index: filter.index,
          SearchColumns: [{
                Name: "Nome",
                Data: "Nome",
                OrderTable: true,
                OrderDirection: "asc",
          }],
      })
      .then(res => resolve(res.data))
      .catch(error => reject(error));
  });
};

export const createUser = (user) => {
  return new Promise((resolve, reject) => {
    http
      .post(`/Usuario/SalvarUsuario`, user)
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
};

export const editUser = (user) => {
  return new Promise((resolve, reject) => {
    http
      .put(`/Usuario/EditarUsuario`, user)
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
};

export const acceptTerms = () => {
  return new Promise((resolve, reject) => {
    http
      .put('/Usuario/AcceptTerms')
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
};
export const userActivationById = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .post(`/Usuario/AtivarInativarUsuario`, {
        id: filter.id,
        ativar: filter.activate
      })
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
};


export const saveLinkTraceable = (userId = 2481, traceables = []) => {
  return new Promise((resolve, reject) => {
    http
      .post(`/Usuario/SalvarObjetosUsuario`, {
        Adicionar: true,
        UsuarioId: userId,
        ObjetoRastreavelId: traceables
      })
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
};

export const saveUnLinkTraceable = (userId = 2481, traceables = []) => {
  return new Promise((resolve, reject) => {
    http
      .post(`/Usuario/SalvarObjetosUsuario`, {
        Adicionar: false,
        UsuarioId: userId,
        ObjetoRastreavelId: traceables
      })
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
};

export const getTraceableLinked = (userId, filter = { page: 0, length: 10, search: '' }) => {
  return new Promise((resolve, reject) => {
    http
      .post(`/Usuario/PesquisarObjetosUsuario`, {
        Vinculado: true,
        UsuarioId: userId,
        GrupoId: 0,
        GridRequest: {
          Length: filter.length,
          Index: filter.page,
          Search: filter.search,
        }
      })
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
};

export const getAvailableTraceable = (userId, filter = { page: 0, length: 10, search: '' }) => {
  return new Promise((resolve, reject) => {
    http
      .post(`/Usuario/PesquisarObjetosUsuario`, {
        Vinculado: false,
        UsuarioId: userId,
        GrupoId: 0,
        GridRequest: {
          Length: filter.length,
          Index: filter.page,
          Search: filter.search,
        }
      })
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
};

export const getFleetByUserId = (userId) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/Usuario/BuscarFrotas`, {
        params: { usuarioId: userId }
      })
      .then(res => resolve(res.data))
      .catch(error => reject(error));
  });
};

export const getPermissionsByUserId = (id = 0, userId = 0) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/Usuario`, {
        params: { id, usuarioId: userId }
      })
      .then(res => resolve(res.data))
      .catch(error => reject(error));
  });
};

export const getFunctionality = ({ search = "", length = 15, index = 0 }) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/Usuario/BuscarFuncionalidades`)
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
};


export default {
  validateAccess,
  createAccess,
  getUserById,
  getUsers,
  createUser,
  editUser,
  userActivationById,
  saveLinkTraceable,
  saveUnLinkTraceable,
  getTraceableLinked,
  getAvailableTraceable,
  getFleetByUserId,
  getPermissionsByUserId,
  getFunctionality,
  acceptTerms,
};
