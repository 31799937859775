import React from "react";
import FormikAutocomplete from "~/components/common/autocomplete/FormikAutocomplete";
import { Box, Typography } from "@material-ui/core";
import { Field } from "formik";
import "./RegisterComponents.scss";

const RegisterAutocomplete = (props) => {
	const { label, touched, errors, ...fieldProps } = props;

	return (
		<Box className="register-field">
			<Typography variant="subtitle1" className="register-field-label">
				{label}
			</Typography>
			<Field
				component={FormikAutocomplete}
				{...fieldProps}
				textFieldProps={{
					variant: "outlined",
					fullWidth: true,
					margin: "dense",
					InputProps: {
						placeholder: props.placeholder,
					},
				}}
			/>
		</Box>
	);
};

export default RegisterAutocomplete;
