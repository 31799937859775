import Labels from "~/helpers/enums/Labels";

const KM_RUN = {
	name: "kmRun",
	label: Labels.DASHBOARD_KM_RUN_TITLE,
};

const PERIOD_TRIPS = {
	name: "periodTrips",
	label: Labels.DASHBOARD_PERIOD_TRIPS_TITLE,
};

const KM_RUN_PERIOD_WEEK = {
	name: "kmRunPeriodWeek",
	label: Labels.DASHBOARD_KM_RUN_PERIOD_WEEK_TITLE,
}

const ROAD_OFFSET = {
	name: "roadOffset",
	label: Labels.DASHBOARD_ROAD_URBAN_OFFSET_TITLE,
};

// const KM_RUN_BUSINESS_HOURS = {
//   name: "kmRunBusinessHour",
//   label: "Km rodados Horário Comercial"
// }

// const KM_RUN_OUTSIDE_HOURS = {
//   name: "kmRunOutsideHours",
//   label: "Km rodados Fora do Horário"
// }

// const KM_RUN_WEEKEND = {
//   name: "kmRunWeekend",
//   label: "Km rodados Final de Semana"
// }

const TOP_TEN_DRIVER_VEHICLE = {
	name: "topTenDriverVehicle",
	label: Labels.DASHBOARD_TOP_TEN_DRIVER_VEHICLE_TITLE,
};

const TOP_TEN_MAX_VELOCITY_VEHICLE = {
	name: "topTenMaxVelocityVehicle",
	label: Labels.DASHBOARD_TOP_TEN_MAX_VELOCITY_VEHICLE_CARD_TITLE,
};

const TOP_TEN_MAX_VELOCITY_DRIVER = {
	name: "topTenMaxVelocityDriver",
	label: Labels.DASHBOARD_TOP_TEN_MAX_VELOCITY_DRIVER_CARD_TITLE,
};

const ANALYSIS_ITEMS_TITLE = {
	name: "analysisItems",
	label: Labels.DASHBOARD_ANALYSIS_ITEMS_TITLE,
};

const TOP_TEN_ANALYZE_DRIVER_VEHICLE = {
	name: "topTenAnalyzeDriverVehicle",
	label: Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TITLE,
};

const DRIVABILITY_SCORE = {
	name: "drivabilityScore",
	label: Labels.DASHBOARD_DRIVABILITY_SCORE_TITLE,
};

const RISK_EXPOSURE_SCORE = {
	name: "riskExposureScore",
	label: Labels.DASHBOARD_RISK_EXPOSURE_SCORE_TITLE,
};

const FLEET_EVOLUTION = {
	name: "fleetEvolution",
	label: Labels.DASHBOARD_FLEET_EVOLUTION_TITLE,
};

const ACCIDENT_PROBABILITY = {
	name: "accidentProbability",
	label: Labels.DASHBOARD_ACCIDENT_PROBABILITY_TITLE,
};

export default {
	KM_RUN,

	PERIOD_TRIPS,
	ROAD_OFFSET,
	KM_RUN_PERIOD_WEEK,
	// KM_RUN_BUSINESS_HOURS,
	// KM_RUN_OUTSIDE_HOURS,
	// KM_RUN_WEEKEND,
	TOP_TEN_DRIVER_VEHICLE,
	TOP_TEN_MAX_VELOCITY_VEHICLE,
	TOP_TEN_MAX_VELOCITY_DRIVER,
	ANALYSIS_ITEMS_TITLE,
	TOP_TEN_ANALYZE_DRIVER_VEHICLE,
	DRIVABILITY_SCORE,
	RISK_EXPOSURE_SCORE,
	FLEET_EVOLUTION,
	ACCIDENT_PROBABILITY,
};
