import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem } from "@material-ui/core";
import "./Dropdow.scss";

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.primary.main,
	},
}));

const DropdownItem = ({ icon, text, ...props }) => {
	const classes = useStyles();

	return (
		<MenuItem {...props} className={"dropdown-icon"}>
			<span className={`${classes.icon} icon`}>{icon}</span>
			<span className="text"> {text}</span>
		</MenuItem>
	);
};

export default DropdownItem;
