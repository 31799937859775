import React from "react";
import { makeStyles, Typography, List } from "@material-ui/core";
import CardItem from "./CardItem";
import ArrowSortOrder from "~/components/common/arrowSortOrder/ArrowSortOrder";
import { lighten } from "polished";
import "./Card.scss";

const useStyles = makeStyles((theme) => ({
    root: {
        "&:hover .cardItemValue": {
            background: lighten(0.3, theme.palette.primary.main),
            cursor: "pointer",
        },
    },
    rootSelectedPrint: {
        "& .cardItemValue": {
            backgroundColor: "#fed7d9",
        },
    },
}));

function CardDataReUse(props) {
    const { colorHover, printRow, onSelect } = props;

    const styleProps = { colorSecondary: colorHover || "#abcdef" };
    const classes = useStyles(styleProps);

    const setPrintRow = (row, style) => {
        if (printRow && row[printRow.key01] > row[printRow.key02]) {
            return style.rootSelectedPrint;
        }
    };

    const isSorted = props.onSort && props.orderObject;

    return (
        <div>
            {props?.dataSource?.map((row, iRow) => (
                <List
                    className={`${classes.root} ${setPrintRow(row, classes)}`}
                    key={iRow}
                    onClick={() => {
                        onSelect && onSelect(row);
                    }}>
                    {props.columns.map((col, iCol) => (
                        <div className="borderMain" key={iCol}>
                            <CardItem>
                                <div
                                    className={`${"cardItem"} ${"cardItemColumn"}`}
                                    style={{ paddingRight: "5px", fontSize: "14px" }}
                                    title={col.label}
                                    onClick={() => {
                                        if (!isSorted || col.noSort) {
                                            return;
                                        }

                                        const orderObject = {
                                            orderBy: col.name,
                                            order: props.orderObject.order === "asc" ? "desc" : "asc",
                                        };

                                        if (col.onSort) {
                                            col.onSort(orderObject, col, props.dataSource, props.onSort);
                                        } else if (props.onSort) {
                                            props.onSort(orderObject, col, props.dataSource);
                                        }
                                    }}>
                                    <Typography noWrap style={{ fontSize: "14px" }}>
                                        {col.label}
                                    </Typography>

                                    {isSorted && !col.noSort && (
                                        <ArrowSortOrder
                                            show={col.name === props.orderObject.orderBy}
                                            order={props.orderObject.order}
                                        />
                                    )}
                                </div>
                                <div className={`${"cardItem"} ${"cardItemValue"}`}>
                                    {col.render ? (
                                        col.render(row[col.name], row, iCol)
                                    ) : (
                                        <Typography
                                            style={{ paddingTop: "4px", fontSize: "14px" }}
                                            title={row[col.name] ? row[col.name] : "  "}>
                                            {
                                                //TODO Apply textOverflow ellipsis
                                                row[col.name] ? row[col.name] : "  "
                                            }
                                        </Typography>
                                    )}
                                </div>
                            </CardItem>
                            <div className="borderVehicle" />
                        </div>
                    ))}
                </List>
            ))}
        </div>
    );
}

export default CardDataReUse;
