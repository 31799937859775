import React from "react";

import { useSelector } from "react-redux";

import Loading from "~/components/common/loading/Loading";
import AlertDetails from "~/components/reporting/alerts/AlertViolated/AlertDetails/AlertDetails";

const AlertViolated = (props) => {
    const { getAlertDetails } = useSelector((state) => state.app.loading);

    return (
        <div className="contentCard">
            {getAlertDetails ? (
                <div id="loading-object">
                    <Loading />
                </div>
            ) : (
                <AlertDetails />
            )}
        </div>
    );
};

export default AlertViolated;
