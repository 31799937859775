import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withGoogleMap, GoogleMap } from "react-google-maps";
import { MAP } from "react-google-maps/lib/constants";
import { compose, withStateHandlers } from "recompose";
import dashboardChartsAction from "~/actions/dashboardVehicle/dashboardChartsAction";
import GoogleScriptLoader from "~/components/common/googleScriptLoader/GoogleScriptLoader";
import LoadingPanel from "~/components/common/loading/LoadingPanel";
import "./HeatMap.scss";

const GoogleMapsComponent = compose(
	withStateHandlers(
		() => ({
			toggleInfoWindows: {},
		}),
		{}
	),
	withGoogleMap
)((props) => {
	const { vehicle } = props;
	const dispatch = useDispatch();
	const [mapContext, setMapContext] = useState(null);
	const { rangeDate } = useSelector((state) => state.dashboardVehicleFilter);
	const { heatmapData } = useSelector((state) => state.dashboardVehicleCharts);

	const map = useRef();

	useEffect(() => {
		setMapContext(map.current.context[MAP]);
	}, [map]);

	useEffect(() => {
		dispatch(
			dashboardChartsAction.getVehicleHeatMap(
				{
					ObjetoRastreavelId: vehicle ? vehicle.ObjetoRastreavelId : null, // 1089673
					DataInicio: `${rangeDate.MesInicio}/${rangeDate.ValueInicio}/${rangeDate.AnoInicio}`, // "08/08/2020",
					DataFim: `${rangeDate.MesFim}/${rangeDate.ValueFim}/${rangeDate.AnoFim}`, //"08/09/2020"
				},
				"heatmap"
			)
		);
	}, [dispatch, rangeDate, vehicle]);

	useEffect(() => {
		if (!mapContext) return;
		let bounds = new window.google.maps.LatLngBounds();

		const points = heatmapData.map(
			(latlng) => new window.google.maps.LatLng(parseFloat(latlng.latitude), parseFloat(latlng.longitude))
		);
		points.forEach((point) => bounds.extend(point));

		const heatmap = new window.google.maps.visualization.HeatmapLayer({
			data: points,
			map: mapContext,
		});

		if (points.length > 0) mapContext.fitBounds(bounds);

		return () => {
			heatmap && heatmap.setMap(null);
		};
	}, [mapContext, heatmapData]);

	return (
		<GoogleMap
			defaultZoom={2}
			options={{
				fullscreenControl: false,
				streetViewControl: true,
				scaleControl: true,
			}}
			defaultCenter={{ lat: -15.35568, lng: -56.060555 }}
			ref={map}
			mapTypeId={window.google.maps.MapTypeId.HYBRID}
		/>
	);
});

const HeatMap = (props) => {
	const { heatmap } = useSelector((state) => state.app.loading);

	return (
		<>
			<GoogleScriptLoader>
				<GoogleMapsComponent
					loadingElement={<div style={{ height: `100%` }} />}
					containerElement={<div className="fixHeightMap heatMap" />}
					mapElement={<div style={{ height: `100%` }} />}
					{...props}
				/>
			</GoogleScriptLoader>
			{heatmap && <LoadingPanel size={45} />}

		</>
	);
};

export default HeatMap;
