import { Colors, ColorSolid, getFullValue } from "~/components/common/dashboard/RoadOffsetFormatterUtils";

const customChart = ({ data: { Veiculo } }) => {
	const { DistanciaUrbanoPercentual, DistanciaUrbano, DistanciaRodoviarioPercentual, DistanciaRodoviario } = Veiculo;

	const DistanciaRodoviárioPercentual = DistanciaRodoviarioPercentual;

	const payload = Object.entries({
		DistanciaUrbanoPercentual,
		DistanciaRodoviárioPercentual,
	}).map((time) => ({
		name: time[0].substring(9, time[0].length - 10),
		value: time[1],
		fullValue: getFullValue(time[0].substring(9, time[0].length - 10), { DistanciaUrbano, DistanciaRodoviario }),
		itemStyle: {
			color: Colors(time[0].substring(9, time[0].length - 10)),
		},
		label: {
			color: ColorSolid(time[0].substring(9, time[0].length - 10)),
		},
	}));

	return payload;
};

const emptyChart = () => {
	return [{
			value: 0,
			name: 0,
			fullValue: 0,
			itemStyle: {
				color: {
					type: "linear",
					x: 0,
					y: 0,
					x2: 0,
					y2: 1,
					colorStops: [
						{
							offset: 0,
							color: "#6EC8AF",
						},
						{
							offset: 1,
							color: "#eef2f5",
						},
					],
					global: true
				}
			}
		}
	]
}


export default { customChart, emptyChart };
