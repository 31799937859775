import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
	travels: [],
	positions: [],
	lastPositions: [],
};

function appReducer(state = INITIAL_STATE, action = null) {
	switch (action.type) {
		case Constants.FIND_TRAVELS_VEHICLE:
			return {
				...state,
				travels: action.payload,
			};
		case Constants.FIND_LAST_POSITIONS_WITHOUT_DATA_TABLE:
			return {
				...state,
				positions: action.payload,
			};
		case Constants.FIND_LAST_POSIIONS:
			return {
				...state,
				lastPositions: action.payload,
			};
		default:
			return state;
	}
}

export default appReducer;
