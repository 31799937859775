
import React from 'react';
import PDFView from './PDFView';

const TermsPDFView = () => {
  const pdfLink = 'https://ceabs-cdn.s3.sa-east-1.amazonaws.com/MyUconnectFleet/TermosECondicoes.pdf';

  return (
    <div>
      <h1>Visualizador de PDF</h1>
      <PDFView pdfLink={pdfLink} />
    </div>
  );
};

export default TermsPDFView;
