import React, { useState, useRef } from "react";
import ExpansionCard from "~/components/common/expansionCard/ExpansionCard";
import ExpansionGrid from "~/components/common/expansionCard/ExpansionGrid";
import ExpansionCardEnum from "~/components/common/expansionCard/ExpansionCardEnum";
import { ObjectPanel } from "~/components/common/reportTab/ReportPanel";
import MapCard from "~/components/map/MapCard";
import GridResultsObject from "~/components/reporting/travels/perObject/GridResultsObject";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

const TravelsObject = (props) => {
    const [alignment, setAlignment] = useState(ExpansionCardEnum.HORIZONTAL);
    const { t } = useTranslation();
    const refSerachDiv = useRef(null);

    return (
        <div>
            <ObjectPanel>
                <ExpansionGrid alignment={alignment} fnClick={setAlignment}>
                    <ExpansionCard
                        card={"relatorioViagens"}
                        flex={"0 1 30%"}
                        key={"1"}
                        title={t(Labels.RESULTS)}
                        alignment={alignment}
                        className={"card-refactor"}
                        fnClick={setAlignment}
                        refTitleDiv={refSerachDiv}>
                        <GridResultsObject mode={alignment} refSerachDiv={refSerachDiv} />
                    </ExpansionCard>
                    <ExpansionCard
                        card={"relatorioViagensMapa"}
                        notPadded
                        title={t(Labels.ROUTE_MAP)}
                        flex={"0 1 70%"}
                        key={"2"}
                        alignment={alignment}
                        fnClick={setAlignment}
                        className="travelsObjectMap card-refactor">
                        <div className="card-report-map">
                            <MapCard className="map-card-refactor" />
                        </div>
                    </ExpansionCard>
                </ExpansionGrid>
            </ObjectPanel>
        </div>
    );
};

export default TravelsObject;
