import React from "react";
import { Box, Button, IconButton, FormControl, FormHelperText } from "@material-ui/core";
import { CameraIcon, CloseIcon } from "~/components/common/icons/Icons";
import Utils from "~/helpers/Utils";
import { Field } from "formik";
import "./RegisterComponents.scss";

const RegisterImageInput = (props) => {
	const { id } = props;
	var _URL = window.URL || window.webkitURL;

	return (
		<Box className="register-field">
			<Field name={props.name}>
				{({ field, form: { touched, errors, setFieldValue }, meta }) => {
					const thumb = field.value.url || field.value.base64;
					const error = errors[props.name];

					return (
						<FormControl error={!!error}>
							<input
								hidden
								value={field.value.inpuValue || ""}
								name={props.name}
								accept={props.accept || "image/*"}
								multiple={false}
								id={`contained-button-file-${id}`}
								type="file"
								onChange={(event) => {
									let newValue = {
										inputValue: event.target.value,
										file: event.target.files[0],
									};

									if (newValue.file) {
										Utils.fileToBase64(newValue.file).then((string) => {
											newValue["base64"] = string;

											const img = new Image();

											img.onload = () => {
												newValue["img"] = img;
												setFieldValue(props.name, newValue);
											};

											img.onerror = () => {
												setFieldValue(props.name, newValue);
											};

											img.src = _URL.createObjectURL(newValue.file);
										});
									}
								}}
							/>
							<label htmlFor={!props.withButton ? `contained-button-file-${id}` : null}>
								<Box
									className={`field-file ${!!error ? "field-image-error" : " "} ${
										!props.withButton ? "no-button" : ""
									}`}
									style={{
										height: props.height,
										width: props.width,
									}}>
									{thumb && !error && <img className="field-cover" src={thumb} alt={"Cover"} style={{
										objectFit: props.objectFit || "cover"
									}}/>}
									{(!thumb || !!error) && (
										<Box className="field-placholder">
											{props.placholderIcon} <span className="text">{props.placholderText}</span>
										</Box>
									)}

									{thumb && (
										<IconButton
											className="close-button"
											onClick={(e) => {
												e.preventDefault();
												setFieldValue(props.name, {});
											}}>
											<CloseIcon />
										</IconButton>
									)}

									{props.withButton && (
										<label htmlFor={`contained-button-file-${id}`}>
											<Button
												disabled={field.disabled}
												variant="contained"
												color="primary"
												disableElevation
												component="span">
												<CameraIcon />
											</Button>
										</label>
									)}
								</Box>
							</label>

							<FormHelperText error margin={props.margin || "dense"}>
								{error}
							</FormHelperText>
						</FormControl>
					);
				}}
			</Field>
		</Box>
	);
};

export default RegisterImageInput;
