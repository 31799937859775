import http from "~/config/httpRequest/http";

export const getProfile = (id) => {
    return new Promise((resolve, reject) => {
        http.get(`/PerfilAcesso/CarregarPerfil`, {
            params: {
                profileId: id,
            },
        })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};
export const getProfileAcceptTerm = () => {
    return new Promise((resolve, reject) => {
        http.get(`/PerfilAcesso/CarregarPerfil`)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}; 
export const saveProfile = (profile) => {
    return new Promise((resolve, reject) => {
        http.post(`/PerfilAcesso/Salvar`, {
            ...profile,
        })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const getFusosByCountry = (country, language) => {
    return new Promise((resolve, reject) => {
        http.get(`/PerfilAcesso/CarregarFusoPais`, {
            params: {
                Pais: country,
                Idioma: language,
            },
        })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export default { getProfile, saveProfile, getFusosByCountry,getProfileAcceptTerm };
