import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import groupOfDriversService from "~/services/groupOfDriversService";

const saveGroupOfDrivers = (group, fnCallback = () => {}, LOADING_IDENTIFICATOR = "saveGroupOfDrivers") => (
	dispatch
) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return groupOfDriversService
		.saveGroup(group)
		.then((response) => {
			let payload = {
				Id: response.Id,
				Nome: group.Nome,
				Ativo: group.Ativo,
			};
			fnCallback(payload);
		})
		.catch((error) => {
			fnCallback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const deleteGroupOfDrivers = (groupId, fnCallback, LOADING_IDENTIFICATOR = "deleteGroupOfDrivers") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return groupOfDriversService
		.deleteGroupsDrivers(groupId)
		.then((response) => {
			let payload = response.data;
			fnCallback();
			Utils.showTranslatedToast({
				type: "info",
				description: response.data,
			});
			dispatch({ type: Constants.DELETE_GROUP_DRIVERS_BY_ID, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getGroupsDrivers = (LOADING_IDENTIFICATOR = "getDriversGroup") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return groupOfDriversService
		.getGroupsDrivers()
		.then((res) => {
			let payload = res.data;
			dispatch({ type: Constants.GET_GROUPS_DRIVERS, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getDriversLinkedGroupById = (groupId, pagination, LOADING_IDENTIFICATOR = "getDriversLinked") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return groupOfDriversService
		.getDriversLinkedGroupById(groupId, pagination)
		.then((res) => {
			let payload = res.data;
			dispatch({ type: Constants.GET_DRIVERS_LINKED_GROUP, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getAvailableDriversForGroupById = (groupId, pagination, LOADING_IDENTIFICATOR = "getAvailableDrivers") => (
	dispatch
) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return groupOfDriversService
		.getAvailableDriversForGroupById(groupId, pagination)
		.then((res) => {
			let payload = res.data;
			dispatch({ type: Constants.GET_AVAILABLE_DRIVERS_FOR_GROUP, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const saveLinkDriver = (groupId, driversSelected, LOADING_IDENTIFICATOR = "saveLinkDriver") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return groupOfDriversService
		.saveLinkDriver(groupId, driversSelected)
		.then((res) => {
			console.log(res);
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const saveUnbindDriver = (groupId, driversSelected, LOADING_IDENTIFICATOR = "saveUnbindDriver") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return groupOfDriversService
		.saveUnbindDriver(groupId, driversSelected)
		.then((res) => {
			console.log(res);
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const cleanDriversLinked = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_DRIVERS_LINKED_GROUP });
};

const cleanAvailableDrivers = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_AVAILABLE_DRIVERS_FOR_GROUP });
};

export default {
	saveGroupOfDrivers,
	getGroupsDrivers,
	getDriversLinkedGroupById,
	getAvailableDriversForGroupById,
	saveLinkDriver,
	saveUnbindDriver,
	deleteGroupOfDrivers,
	cleanDriversLinked,
	cleanAvailableDrivers,
};
