import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Utils from "~/helpers/Utils";
import SchedulingFilter from "~/components/common/schedulingReport/SchedulingFilter";
import eventReportAction from "~/actions/reporting/eventReportAction";
import "~/components/reporting/Reporting.scss";

const EventsFilterScheduled = (props) => {
    const dispatch = useDispatch();

    const { edition } = useSelector((state) => state.eventReport.scheduling);

    const onFormRefUpdate = useCallback(
        (_scheduledForm) => {
            dispatch(eventReportAction.updateScheduleForm(_scheduledForm.current));
        },
        [dispatch]
    );
    return (
        <SchedulingFilter
            edition={edition}
            onFormRefUpdate={onFormRefUpdate}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                dispatch(
                    eventReportAction.saveEventsReportScheduling(
                        {
                            Id: values.Id,
                            PeriodicidadeDia: values.PeriodicidadeDia,
                            Formato: values.Formato,
                            Email: values.Email,
                            DataInicio: moment(values.DataInicio).format("DD/MM/YYYY"),
                            ObjetoRastreavelId: parseInt(values.ObjetoRastreavel.id),
                        },
                        (error, res) => {
                            setSubmitting(false);

                            if (error) {
                                Utils.showAllErrors(error);
                                return;
                            }

                            resetForm();

                            dispatch(eventReportAction.getEventsReportScheduling({}));
                            dispatch(eventReportAction.getEventsReportSent({}));
                            dispatch(eventReportAction.finishEditionEventsReportScheduling());
                        }
                    )
                );
            }}
        />
    );
};

export default EventsFilterScheduled;
