import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import mapAction from "~/actions/mapAction";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
	},
	paper: {
		//margin: theme.spacing(1),
	},
	svg: {
		width: 100,
		height: 100,
	},
	polygon: {
		fill: theme.palette.common.white,
		stroke: theme.palette.divider,
		strokeWidth: 1,
	},
	root: {
		display: "flex",
	},
	formControl: {
		margin: theme.spacing(3),
	},
}));

const MapToolbar = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { filters } = useSelector((state) => state.mapToolbar);

	const handleChange = (event) => {
		dispatch(mapAction.toogleMarkedMapFilter(event.target.name, event.target.checked));
	};

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<FormControl component="fieldset" className={classes.formControl}>
					<FormGroup>
						{filters.map((filter) => (
							<FormControlLabel className="toolbar-checkbox" key={filter.name}
								control={<Checkbox color="primary" checked={filter.checked} onChange={handleChange} name={filter.name} />}
								label={filter.label}
							/>
						))}
					</FormGroup>
				</FormControl>
			</div>
		</div>
	);
};

export default MapToolbar;
