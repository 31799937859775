import Labels from "~/helpers/enums/Labels";

export const subRoutesReport = [
    {
        label: Labels.MENU_LABEL_REPORT_ALERT,
        path: "/report/alerts",
        id: 1016,
    },
    {
        label: Labels.MENU_LABEL_REPORT_DISTANCE,
        path: "/report/distance",
        id: 1012,
    },
    {
        label: Labels.MENU_LABEL_REPORT_EVENTS,
        path: "/report/events",
        id: 1013,
    },
    {
        label: Labels.MENU_LABEL_REPORT_TRAVELS,
        path: "/report/travels",
        id: 1014,
    },
    {
        label: Labels.MENU_LABEL_REPORT_SPEEDING,
        path: "/report/speeding",
        id: 1015,
    },
];

export const subRoutesRegister = [
    {
        label: Labels.MENU_LABEL_REGISTER_ALERT,
        path: "/register/alerts",
        id: 1003,
    },
    {
        label: Labels.MENU_LABEL_REGISTER_FLEET,
        path: "/register/fleet",
        id: 1004,
    },
    {
        label: Labels.MENU_LABEL_REGISTER_GROUP_DRIVER,
        path: "/register/groupOfDrivers/",
        id: 1005,
    },
    {
        label: Labels.MENU_LABEL_REGISTER_GROUP_POINT_INTEREST,
        path: "/register/groupOfPointsInterest",
        id: 1006,
    },
    {
        label: Labels.MENU_LABEL_REGISTER_POINT_INTEREST,
        path: "/register/pointsInterest/list",
        id: 1007,
    },
    {
        label: Labels.MENU_LABEL_REGISTER_DRIVER,
        path: "/register/drivers/list",
        id: 1008,
    },
    {
        label: Labels.MENU_LABEL_REGISTER_ROUTE,
        path: "/register/routes",
        id: 1009,
    },
    {
        label: Labels.MENU_LABEL_REGISTER_VEHICLE,
        path: "/register/vehicles",
        id: 1010,
    },
    {
        label: Labels.MENU_LABEL_REGISTER_USER,
        path: "/register/user",
        id: 1019,
    },
];
