import exportDocumentsService from "~/services/exportDocumentsService";
import Utils from "~/helpers/Utils";
import Constants from "~/helpers/enums/Constants";

const getRastreableObjectVehicle = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return exportDocumentsService
		.getRastreableObjectVehicle(filter)
		.then((response) => {
			if (response.data.Success) {
				Utils.downloadToLink(response.data.Message);
			} else {
				Utils.showError(response.data.Message);
			}

			callback(false, response);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getRastreableObjectFleets = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return exportDocumentsService
		.getRastreableObjectFleets(filter)
		.then((response) => {
			if (response.data.Success) {
				Utils.downloadToLink(response.data.Message);
			} else {
				Utils.showError(response.data.Message);
			}
			callback(false, response);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getRastreableObjectOneFleet = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return exportDocumentsService
		.getRastreableObjectOneFleet(filter)
		.then((response) => {
			if (response.data.Success) {
				Utils.downloadToLink(response.data.Message);
			} else {
				Utils.showError(response.data.Message);
			}
			callback(false, response);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const extractDocumentPoints = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "extractDocumentPoints") => (
	dispatch
) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return exportDocumentsService
		.extractDocumentPoints(filter)
		.then((response) => {
			if (response.data.Success) {
				Utils.downloadToLink(response.data.Message);
			} else {
				Utils.showError(response.data.Message);
			}
			callback(false, response);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const extractDocumentDrivers = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "extractDocumentDrivers") => (
	dispatch
) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return exportDocumentsService
		.extractDocumentDrivers(filter)
		.then((response) => {
			if (response.data.Success) {
				Utils.downloadToLink(response.data.Message);
			} else {
				Utils.showError(response.data.Message);
			}
			callback(false, response);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const changeExportDocumentFunction = (actionFunction, params) => (dispatch) => {
	dispatch({
		type: Constants.CHANGE_EXPORT_FUNCTION,
		payload: {
			actionFunction,
			params,
		},
	});
};

const cleanExportDocumentFunction = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_EXPORT_FUNCTION });
};

const extractDocumentVehicles = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "extractDocumentVehicles") => (
	dispatch
) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return exportDocumentsService
		.extractDocumentVehicles(filter)
		.then((response) => {
			if (response.data.Success) {
				Utils.downloadToLink(response.data.Message);
			} else {
				Utils.showError(response.data.Message);
			}
			callback(false, response);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

export default {
	getRastreableObjectVehicle,
	getRastreableObjectFleets,
	getRastreableObjectOneFleet,
	changeExportDocumentFunction,
	cleanExportDocumentFunction,
	extractDocumentPoints,
	extractDocumentDrivers,
	extractDocumentVehicles,
};
