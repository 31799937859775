import React from "react";
import Page from "~/components/common/page/Page";
import { Container, Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

//Import component
import Tutorial from "~/components/tutorial/Tutorial";
import "./Tutorial.scss";

const TutorialView = () => {
    const { user} = useSelector((state) => state.auth);
    return (
        <>
            <Page
                extraContent={
                    <div
                        className="banner-name"
                        style={{
                            background: `
							${user.colors.topBarColor}`,
                            borderLeft: "1px solid #fff",
                        }}>

                        <Typography variant="h4">Olá {user.name}</Typography>
                    </div>
                }>
                <main>
                    <Container maxWidth="lg" className="container-map-view">
                        <Grid container>
                            <Tutorial />
                        </Grid>
                    </Container>
                </main>
            </Page>
        </>
    );
};

export default TutorialView;
