import { Colors, ColorSolid, getFullValue, getName } from "~/components/common/dashboard/PeriodTripsFormatterUtils";

const customChart = ({ data }) => {
    const [item] = data;
    const {
        TempoMadrugadaPorcentagem,
        TempoMadrugada,
        TempoManhaPorcentagem,
        TempoManha,
        TempoTardePorcentagem,
        TempoTarde,
        TempoNoitePorcentagem,
        TempoNoite,
    } = item;

    const payload = Object.entries({
        TempoMadrugadaPorcentagem,
        TempoManhaPorcentagem,
        TempoTardePorcentagem,
        TempoNoitePorcentagem,
    }).map((time) => ({
        name: getName(time[0]),
        value: time[1],
        fullValue: getFullValue(time[0], {
            TempoMadrugada,
            TempoManha,
            TempoTarde,
            TempoNoite,
        }),
        itemStyle: {
            color: Colors(time[0]),
        },
        label: {
            color: ColorSolid(time[0]),
        },
    }));

    return payload;
};

export default { customChart };
