import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "~/components/common/expansionCard/Card";
import TableCard from "~/components/tableCard/TableCardReUse";
import reportingAction from "~/actions/reportingAction";
import { PeriodPanel } from "~/components/common/reportTab/ReportPanel";
import { ReactComponent as DownloadIcon } from "~/assets/icons/download.svg";
import ProgressButton from "~/components/common/progressButton/ProgressButton";
import useFuso from "~/components/common/fuso/useFuso";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

const ReportingPeriod = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.reporting.perPeriod);
    const { fromUtc } = useFuso();

    useEffect(() => {
        dispatch(
            reportingAction.getReportingPerPeriod({
                relatorioId: 3,
                Start: 0,
                Index: 0,
                Length: 10,
            })
        );
        const refreshLoop = setInterval(() => {
            dispatch(
                reportingAction.getReportingPerPeriod({
                    relatorioId: 3,
                    Start: 0,
                    Index: 0,
                    Length: 10,
                })
            );
        }, 59000);
        return () => {
            clearInterval(refreshLoop);
        };
    }, [dispatch]);

    const COLUMNS = [
        {
            name: "ObjetoGrupoNome",
            label: t(Labels.COLUMN_PLATE),
            render: (plate) => <span className="strong-plate">{plate}</span>,
        },
        {
            name: "DataInicial",
            label: t(Labels.REPORT_DISTANCE_INITIAL_DATE),
            render: (date) => (
                <span>
                    {fromUtc(date, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")}
                    <span style={{ color: "#AFC3D2", margin: "auto 5px" }}>|</span>
                    {fromUtc(date, "YYYY-MM-DDTHH:mm:ss").format("HH:mm:ss")}
                </span>
            ),
        },
        {
            name: "DataFinal",
            label: t(Labels.REPORT_DISTANCE_FINAL_DATE),
            render: (date) => (
                <span>
                    {fromUtc(date, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")}
                    <span style={{ color: "#AFC3D2", margin: "auto 5px" }}>|</span>
                    {fromUtc(date, "YYYY-MM-DDTHH:mm:ss").format("HH:mm:ss")}
                </span>
            ),
        },
        {
            name: "DataHoraCadastro",
            label: t(Labels.REPORT_DISTANCE_REPORT_REGISTER_DATE),
            render: (date) => (
                <span>
                    {fromUtc(date, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")}
                    <span style={{ color: "#AFC3D2", margin: "auto 5px" }}>|</span>
                    {fromUtc(date, "YYYY-MM-DDTHH:mm:ss").format("HH:mm:ss")}
                </span>
            ),
        },
        {
            name: "Quantidade",
            label: t(Labels.REPORT_DISTANCE_REPORT_AMOUNT_VEHICLES)
        },
        {
            name: "Processado",
            label: t(Labels.REPORT_DISTANCE_PROCESSING_PERCENT),
            render: (percent, row) => (
                <ProgressButton
                    data-id={"download-item-tabela-periodo-distancia"}
                    progress={row.Processado}
                    error={row.Restante}
                    onClick={() => {
                        handleDownloadReport(row.Arquivo);
                    }}>
                    <DownloadIcon style={{ height: "auto", width: 14 }} /> {t(Labels.DOWNLOAD)}
                </ProgressButton>
            ),
        },
    ];

    const handleDownloadReport = (url) => {
        window.open(url, "_blank");
    };

    return (
        <div>
            <PeriodPanel className="report-panel">
                <Card title={t(Labels.RESULTS)} className="card-refactor">
                    <TableCard columns={COLUMNS} dataSource={data} />
                </Card>
            </PeriodPanel>
        </div>
    );
};

export default ReportingPeriod;
