// General
const NO_DATA = "general.no_data";
const NO_SEARCH_YET = "general.no_search_yet";
const PAGINATION = "general.pagination";
const CURRENCY = "general.currency";
const SOON = "general.soon";
const AUTOCOMPLETE_LOADING = "general.autocomplete.loading";
const AUTOCOMPLETE_NOT_FOUND = "general.autocomplete.not_found";
const AUTOCOMPLETE_CLOSE = "general.autocomplete.close";
const AUTOCOMPLETE_OPEN = "general.autocomplete.open";
const AUTOCOMPLETE_CLEAR_TEXT = "general.autocomplete.clear_text";
const AUTOCOMPLETE_NO_OPTIONS = "general.autocomplete.no_options";
const AUTOCOMPLETE_BUTTON_NO_OPTIONS = "general.autocomplete.button.no_options";
const LESS_MINIMAL = "general.less_minimal";
const MORE_MAX = "general.more_max";
const GLOBAL_NAME = "general.name";
const COMEBACK = "general.comeback";
const FILTERS = "general.filters";
const DUAL_LIST_ADD_SELECTED = "general.dual_list.add_selected";
const DUAL_LIST_REMOVE_SELECTED = "general.dual_list.remove_selected";
const YES = "general.yes";
const NO = "general.no";
const REFRESH_INTERVAL = "general.refresh_interval";
const MENU_RANGE_SHOW = "general.menu_range_show";
const MENU_RANGE_STATUS = "general.menu_range_status";
const CONFIRM_EXCLUDE = "general.confirm_exclude";
const DOWNLOAD = "general.download";
const RESULTS = "general.results";
const ALL = "general.all";
const WEEKDAYS = "general.weekdays";
const MENU_ALL = "general.menu.all";
const MENU_ACTIVE = "general.menu.active";
const MENU_INACTIVE = "general.menu.inactive";

const TIME_ZONE = "time_zone.label";
const TIME_ZONE_PATTERN = "time_zone.pattern";
const TIME_ZONE_UTC = "time_zone.utc";

const VECHICLE_ICON = "general.icons.vechicle_icon";
const TRUCK_ICON = "general.icons.truck_icon";
const MOTORCYCLE_ICON = "general.icons.motorcycle_icon";

const EXTRACT_REPORT = "general.extract_report";

// titles
const TITLE_TUTORIAL = "titles.tutorial";
const TITLE_DASHBOARD_VIEW = "titles.dashboard_view";
const TITLE_DASHBOARD_VEHICLE_VIEW = "titles.dashboard_vehicle_view";
const TITLE_LOGIN = "titles.login";
const TITLE_ALERTS_VIEW = "titles.alerts_view";
const TITLE_MAP_VIEW = "titles.map_view";
const TITLE_ALERT_VIEW = "titles.alert_view";
const TITLE_ALERT_BOND_VIEW = "titles.alert_bond_view";
const TITLE_FLEETS_VIEW = "titles.fleets_view";
const TITLE_FLEETS_LINK_CARS_VIEW = "titles.fleets_link_cars_view";
const TITLE_GROUP_DRIVER_FINDER_VIEW = "titles.group_driver_finder_view";
const TITLE_REGISTER_GROUP_OF_DRIVERS_VIEW = "titles.register_group_of_drivers_view";
const TITLE_GROUP_POINTS_FINDER_VIEW = "titles.group_points_finder_view";
const TITLE_REGISTER_GROUP_OF_POINTS_VIEW = "titles.register_group_of_points_view";
const TITLE_REGISTER = "titles.register";
const TITLE_REGISTERED_DRIVERS = "titles.registered_drivers";
const TITLE_REGISTER_DRIVER = "titles.register_driver";
const TITLE_IMPORT_DRIVER_VIEW = "titles.import_driver_view";
const TITLE_POINTS_IMPORT_VIEW = "titles.points_import_view";
const TITLE_FINDER_POINTS_INTEREST = "titles.finder_points_interest";
const TITLE_COMMUNICATED = "titles.communicated";
const TITLE_REGISTER_POINTS_INTEREST = "titles.register_points_interest";
const TITLE_FINDER_ROUTES_VIEW = "titles.finder_routes_view";
const TITLE_REGISTER_ROUTES_VIEW = "titles.register_routes_view";
const TITLE_VEHICLE_VIEW = "titles.vehicle_view";
const TITLE_REGISTER_USER_VIEW = "titles.register_user_view";
const TITLE_USER_LINK_CARS_VIEW = "titles.user_link_cars_view";
const TITLE_REPORT_ALERTS_VIEW = "titles.report_alerts_view";
const TITLE_REPORT_DISTANCE = "titles.report_distance";
const TITLE_REPORT_EVENTS = "titles.report_events";
const TITLE_REPORT_TRAVELS = "titles.report_travels";
const TITLE_REPORT_SPEEDING_VIEW = "titles.report_speeding_view";
const TITLE_PROFILE_VIEW = "titles.profile_view";
const TITLE_UNAUTHORIZED = "titles.unauthorized";
const TITLE_EXAMPLE_VIEW = "titles.example_view";
const TITLE_NOT_FOUND = "titles.not_found";
const TITLE_RENEWAL = "titles.renewal_view"

// Menu Label
const MENU_LABEL_HOME = "menus.home";
const MENU_LABEL_RENEWAL = "menus.renewal_view";
const MENU_LABEL_DASHBOARD = "menus.dashboard";
const MENU_LABEL_ALERTS = "menus.alerts";
const MENU_LABEL_MAPS = "menus.maps";
const MENU_LABEL_COMMUNICATED = "menus.communicated";
const MENU_LABEL_REPORTS = "menus.reports";
const MENU_LABEL_REPORT_ALERT = "menus.report_alert";
const MENU_LABEL_REPORT_DISTANCE = "menus.report_distance";
const MENU_LABEL_REPORT_EVENTS = "menus.report_events";
const MENU_LABEL_REPORT_TRAVELS = "menus.report_travels";
const MENU_LABEL_REPORT_SPEEDING = "menus.report_speeding";
const MENU_LABEL_REGISTER = "menus.register";
const MENU_LABEL_REGISTER_ALERT = "menus.register_alert";
const MENU_LABEL_REGISTER_ALERT_CARS_LINK = "menus.register_alert_cars_link";
const MENU_LABEL_REGISTER_FLEET = "menus.register_fleet";
const MENU_LABEL_REGISTER_FLEET_CARS_LINK = "menus.register_fleet_cars_link";
const MENU_LABEL_REGISTER_GROUP_DRIVER = "menus.register_group_driver";
const MENU_LABEL_REGISTER_GROUP_POINT_INTEREST = "menus.register_group_point_interest";
const MENU_LABEL_REGISTER_POINT_INTEREST = "menus.register_point_interest";
const MENU_LABEL_REGISTER_POINT_INTEREST_IMPORT = "menus.register_point_interest_import";
const MENU_LABEL_REGISTER_POINT_INTEREST_ADD = "menus.register_point_interest_add";
const MENU_LABEL_REGISTER_POINT_INTEREST_EDIT = "menus.register_point_interest_edit";
const MENU_LABEL_REGISTER_DRIVER = "menus.register_driver";
const MENU_LABEL_REGISTER_DRIVER_ADD = "menus.register_drive_add";
const MENU_LABEL_REGISTER_DRIVER_EDIT = "menus.register_drive_edit";
const MENU_LABEL_REGISTER_DRIVER_IMPORT = "menus.register_drive_import";
const MENU_LABEL_REGISTER_ROUTE = "menus.register_route";
const MENU_LABEL_REGISTER_ROUTE_ADD = "menus.register_route_add";
const MENU_LABEL_REGISTER_ROUTE_EDIT = "menus.register_route_edit";
const MENU_LABEL_REGISTER_VEHICLE = "menus.register_vehicle";
const MENU_LABEL_REGISTER_USER = "menus.register_user";
const MENU_LABEL_REGISTER_USER_CARS_LINK = "menus.register_user_cars_link";

// alerts
const TITLE_ALERT_GRID = "alerts.title_grid";

// alerts popup

const ALERT_POPUP_TITLE = "alerts.popup.title";

// fleets register
const LABEL_CARD_FLEET = "fleetsRegister.title_label";
const STATUS_LABEL = "fleetsRegister.status_label";
const SAVE_LABEL = "fleetsRegister.save_label";
const FLEET_LABEL = "fleetsRegister.label_fleet";
const FLEET_LABEL_EDIT = "fleetsRegister.label_edit_fleet";
const FLEET_LABEL_IMPORT = "fleetsRegister.label_import_fleet";
const FLEET_LABEL_IMPORT_CHECKBOX = "fleetsRegister.label_import_checkbox";
const FLEET_LINK_CARS_AVAILABLE = "fleetsRegister.link_cars.available";
const FLEET_LINK_CARS_VINCULATED = "fleetsRegister.link_cars.vinculated";
const FLEET_LINK_CARS_FLEET = "fleetsRegister.link_cars.fleet";

// vehicle
const VEHICLE_LIST_TITLE = "vehicle.list_title";
const VEHICLE_EDIT_FORM_TITLE = "vehicle.form.edit.title";
const VEHICLE_ODOMETER_FORM_TITLE = "vehicle.form.odometer.title";
const VEHICLE_FORM_NICKNAME = "vehicle.form.nickname.title";
const VEHICLE_FORM_DEFAULT_DRIVER = "vehicle.form.default_driver.title";
const VEHICLE_FORM_NICKNAME_REQUIRED = "vehicle.form.nickname.required";
const VEHICLE_FORM_DEFAULT_DRIVER_REQUIRED = "vehicle.form.default_driver.required";
const VEHICLE_FORM_MSG_SAVE_SUCCESS = "vehicle.form.msgs.save_success";
const VEHICLE_FORM_MSG_ODOMETER_SAVE_SUCCESS = "vehicle.form.msgs.odometer_success";
const VEHICLE_FORM_BUTTON_CONFIRM = "vehicle.form.buttons.confirm";
const VEHICLE_FORM_BUTTON_CANCEL = "vehicle.form.buttons.cancel";
const VEHICLE_FORM_TRACKED_ODOMETER = "vehicle.form.tracked_odometer.title";
const VEHICLE_FORM_VEHICLE_ODOMETER = "vehicle.form.vehicle_odometer.title";
const VEHICLE_FORM_TRACKED_ODOMETER_SMALL_TITLE = "vehicle.form.tracked_odometer.small_title";
const VEHICLE_FORM_VEHICLE_ODOMETER_SMALL_TITLE = "vehicle.form.vehicle_odometer.small_title";
const VEHICLE_FORM_VEHICLE_ODOMETER_REQUIRED = "vehicle.form.vehicle_odometer.required";
const VEHICLE_FORM_VEHICLE_ODOMETER_HISTORY = "vehicle.form.vehicle_odometer.history.title";

// alerts import
const ALERT_LABEL_CHECKBOX = "alertImport.labelCheckBox";
const ALERT_LABEL_BTN_IMPORT = "alertImport.btnImport";

// Login
const LOGIN_TITLE = "login.title";
const LOGIN_USER = "login.user.label";
const LOGIN_USER_REQUIRED = "login.user.required";
const LOGIN_PASSWORD = "login.password.label";
const LOGIN_PASSWORD_REQUIRED = "login.password.required";
const LOGIN_REMEMBER = "login.remember";
const LOGIN_FORGORT_PASSWORD = "login.forgort_password";
const LOGIN_SUBMIT = "login.submit";
const LOGIN_ACCESS_DENIED = "login.access_denied";
// Columns for tables components
const COLUMN_PLATE = "common_columns_table.plate";
const COLUMN_IDENTIFICATION = "common_columns_table.identification";
const COLUMN_BRAND = "common_columns_table.brand";
const COLUMN_MODEL = "common_columns_table.model";
const COLUMN_DATE_REGISTER = "common_columns_table.date_register";
const COLUMN_Y_FABRICATION = "common_columns_table.y_fabrication";
const COLUMN_Y_MODEL = "common_columns_table.y_model";
const COLUMN_CHASSI = "common_columns_table.chassi";
const COLUMN_OWNER = "common_columns_table.owner";
const COLUMN_BRAND_OR_MODEL = "common_columns_table.brand_or_model";
const COLUMN_DRIVER = "common_columns_table.driver";
const COLUMN_ALERT = "common_columns_table.alert";
const COLUMN_EVENT_DATE = "common_columns_table.event_date";
const COLUMN_REGISTER_DATE = "common_columns_table.register_date";
const COLUMN_FINISHED_BY = "common_columns_table.finished_by";
const COLUMN_FINISH_DATE = "common_columns_table.finish_date";
const COLUMN_SPEED_ABBREVIATED = "common_columns_table.speed_abbreviated";
const COLUMN_LOCATION = "common_columns_table.location";
const COLUMN_STAY = "common_columns_table.stay";
const COLUMN_START = "common_columns_table.start";
const COLUMN_SPEED = "common_columns_table.speed";

// Recover Password

const RECOVER_PASSWORD_EMAIL_REQUIRED = "recoverPassword.email.required";
const RECOVER_PASSWORD_EMAIL_INVALID = "recoverPassword.email.invalid";
const RECOVER_PASSWORD_INFO = "recoverPassword.info";
const RECOVER_PASSWORD_RECOVER = "recoverPassword.recover";
const RECOVER_PASSWORD_TAB_LABEL = "recoverPassword.tab_label";

// Recover Password 2

const RECOVER_PASSWORD_REQUIRED = "recoverPassword2.password.required";
const RECOVER_PASSWORD_INVALID = "recoverPassword2.password.invalid";
const RECOVER_PASSWORD_INFO2 = "recoverPassword2.info";
const RECOVER_PASSWORD_RECOVER2 = "recoverPassword2.recover";
const RECOVER_PASSWORD_TAB_LABEL2 = "recoverPassword2.tab_label";

//CreateAccess
const ACCESS_TITLE = "access.title";
const ACCESS_DOCUMENT = "access.document.label";
const ACCESS_DOCUMENT_REQUIRED = "access.document.required";
const ACCESS_VALIDATE_ACCESS = "access.validateAccess";
const ACCESS_USER = "access.user.label";
const ACCESS_USER_REQUIRED = "access.user.required";
const ACCESS_USER_INVALID = "access.user.invalid";
const ACCESS_PASSWORD = "access.password.label";
const ACCESS_PASSWORD_REQUIRED = "access.password.required";
const ACCESS_PASSWORD_STRONG = "access.password.strong";
const ACCESS_CONFIRM_PASSWORD = "access.confirm_password.label";
const ACCESS_CONFIRM_PASSWORD_REQUIRED = "access.confirm_password.required";
const ACCESS_CONFIRM_PASSWORD_CONFIRM = "access.confirm_password.confirm";
const ACCESS_MAIL = "access.mail.label";
const ACCESS_MAIL_REQUIRED = "access.mail.required";
const ACCESS_MAIL_INVALID = "access.mail.invalid";
const ACCESS_CREATE_ACCESS = "access.buttons.createAccess";
const ACCESS_COMEBACK = "access.buttons.comeback";
const ACCESS_MSG_ERROR = "access.messages.error";
const ACCESS_MSG_SUCCESS = "access.messages.success";
const ACCESS_SUGESTIONS_DESCRIPTION = "access.sugestions.description";
const ACCESS_SUGESTIONS_1 = "access.sugestions.s1";
const ACCESS_SUGESTIONS_2 = "access.sugestions.s2";
const ACCESS_SUGESTIONS_3 = "access.sugestions.s3";
const ACCESS_SUGESTIONS_4 = "access.sugestions.s4";
const ACCESS_SUGESTIONS_5 = "access.sugestions.s5";

//TOP10
const TAB_ANALYZE_VEHICLE = "top_ten_tabs.topAnalyze.tabs.topTenVehicle";
const TAB_ANALYZE_DRIVER = "top_ten_tabs.topAnalyze.tabs.topTenDrivers";
const TAB_VEHICLE = "top_ten_tabs.top.tabs.topTenVehicle";
const TAB_DRIVER = "top_ten_tabs.top.tabs.topTenDrivers";

// Header
const HEADER_TITLE = "header.title";

// Driver Import
const LABEL_IMPORT_DRIVER = "driver_import.label";
const BTN_CONFIRM_IMPORT_DRIVER = "driver_import.confirm";
const BTN_CANCEL_IMPORT_DRIVER = "driver_import.cancel";
const FILE_LABEL_IMPORT_DRIVER = "driver_import.labelFile";
const PLACEHOLDER_IMPORT_DRIVER = "driver_import.placeholder";
const LINK_LABEL_IMPORT_DRIVER = "driver_import.linkLabel";
const GROUP_DRIVER_LABEL_IMPORT_DRIVER = "driver_import.groupDriverLabel";
const SEARCH_FILE_IMPORT_DRIVER = "driver_import.searchHolder";
const INFO_LABEL_GROUP_IMPORT_DRIVER = "driver_import.infoLabelGroupDriver";
const CHOICE_LABEL = "driver_import.choice";
const ERROR_IMPORT_FILE = "driver_import.ErrorImport";
const ERROR_IMPORT_TYPE_FILE = "driver_import.TypeErrorImport";
const ERROR_IMPORT_CHOICE = "driver_import.ChoiceErrorImport";
const ERROR_IMPORT_SUCCESS = "driver_import.success";

//History alert
const LABEL_HISTORY_ALERT = "register_alert.tabs.history.label";
const LABEL_HISTORY_ID = "register_alert.tabs.historyId.form.name";
const LABEL_HISTORY_ID_DAYOFWEEK = "register_alert.tabs.historyId.form.dayOfWeek";
const LABEL_HISTORY_ID_HOUR = "register_alert.tabs.historyId.form.hour";
const LABEL_HISTORY_ID_VALIDATE = "register_alert.tabs.historyId.form.validate";
const LABEL_HISTORY_ID_CONDITIONS = "register_alert.tabs.historyId.form.conditions";
const LABEL_HISTORY_ID_ACTIONS = "register_alert.tabs.historyId.form.actions";
const LABEL_HISTORY_ID_BACKLIST = "register_alert.tabs.historyId.backToList";
const REGISTER_ALERT_ERROR_TYPE_XLS = "register_alert.import.type_error";

// Points import
const POINTS_LABEL_IMPORT = "points_interest_import.label";
const IMPORT_INFO_TITLE = "points_interest_import.info-title";
const TITLE_COLUMN_IMPORT = "points_interest_import.title_column";
const TITLE_COLUMN_POINT_IMPORT = "points_interest_import.type-title-column-point";
const TITLE_COLUMN_FENCE_IMPORT = "points_interest_import.type-title-column-fence";
const LABEL_NAME_COLUMN_IMPORT = "points_interest_import.label-name-column";
const LABEL_TYPE_IMPORT = "points_interest_import.label-type";
const LABEL_TYPE_ELEMENT_IMPORT = "points_interest_import.label-type-element";
const LABEL_LAT_IMPORT = "points_interest_import.label-lat";
const LABEL_LONG_IMPORT = "points_interest_import.label-long";
const LABEL_COORDINATES_IMPORT = "points_interest_import.label-coordinates";

// Menu
const MENU_SYSTEM = "menu.system";
const MENU_FAILED = "menu.failed";
const MENU_LOGOUT = "menu.logout";
const MENU_PROFILE = "menu.profile";

//Map
const MAP_FILTER_GROUP = "map.filter.group";
const MAP_FILTER_POINT_INTEREST = "map.filter.pointInterest";
const MAP_FILTER_ROUTES = "map.filter.routes";
const MAP_FILTER_PLACEHOLDER = "map.filter.placeholder";
const MAP_TAB_SEARCH = "map.tab.search";
const MAP_TAB_RANGE_BUTTON = "map.tab.range_button";
const MAP_TAB_VEHICLES = "map.tab.vehicles.title";
const MAP_TAB_FLEETS = "map.tab.fleets.title";
const MAP_TAB_FLEETS_TABLE_CODE = "map.tab.fleets.table.code";
const MAP_TAB_FLEETS_TABLE_NAME = "map.tab.fleets.table.name";
const MAP_TAB_ADDRESSS = "map.tab.address.title";
const MAP_TAB_ADDRESSS_PLACES = "map.tab.address.places";

const MAP_INFO_VIEW_DATE = "map.info_view.date";
const MAP_INFO_VIEW_EVENT = "map.info_view.event";
const MAP_INFO_VIEW_IGNITION = "map.info_view.ignition";
const MAP_INFO_VIEW_VELOCITY = "map.info_view.velocity";
const MAP_INFO_VIEW_DRIVER = "map.info_view.driver";
const MAP_INFO_VIEW_LOCALIZATION = "map.info_view.localization";
const MAP_MARKER_INFO_VIEW_POINT_INTEREST = "map.marker_info_view.pointInterest";
const MAP_MARKER_INFO_VIEW_ROUTE_NAME = "map.marker_info_view.route.name";
const MAP_MARKER_INFO_VIEW_ROUTE_TITLE = "map.marker_info_view.route.title";

//Logs
const LOG_SEARCH_FILTER = "log.search.filter.title";
const LOG_SEARCH_FILTER_NAME = "log.search.filter.name.name";
const LOG_SEARCH_FILTER_NAME_3_DIGITS = "log.search.filter.name.3_digits";
const LOG_SEARCH_FILTER_BUTTON = "log.search.filter.button";
const LOG_SEARCH_FILTER_START_DATE = "log.search.filter.start_date.name";
const LOG_SEARCH_FILTER_END_DATE = "log.search.filter.end_date.name";
const LOG_SEARCH_TABLE_NAME = "log.search.table.name";
const LOG_SEARCH_TABLE_DATE = "log.search.table.date";

// Messages
const SUCCESSFUL_OPERATION = "messages.successful_operation";
const SUCCESSFULLY_LOGGED = "messages.successfully_logged";
const UNAVAILABLE_SERVICE = "messages.unavailable_service";
const NEED_SUPPORT = "messages.need_support";
const NOT_FOUND = "messages.not_found";
const BAD_REQUEST = "messages.bad_request";
const UNAUTHORIZED = "messages.unauthorized";
const FORBIDDEN = "messages.forbidden";
const INTERNAL_SERVER_ERROR = "messages.internal_server_error";
const ACCESS_DENIED = "messages.access_denied";
const NETWORK_ERROR = "messages.network_error";
const RESOURCE_NOT_FOUND = "messages.resource_not_found";
const METHOD_NOT_ALLOWED = "messages.method_not_allowed";
const SESSION_EXPIRED = "messages.session_expired";

//charts common

const CHART_COMMON_KMRUN = "charts_common.kmRun";

// Report tab
const REPORT_TAB_OBJECT = "report_tab.object";
const REPORT_TAB_PERIOD = "report_tab.period";
const REPORT_TAB_SCHEDULED = "report_tab.scheduled";
const REPORT_CLEAN_BUTTON = "report_tab.clean_button";

//Report
const REPORT_LABEL_FLEET = "report_fleet.label";
const REPORT_LABEL_PLACEHOLDER = "report_fleet.placeholder";

// Report alert

const REPORT_LABEL_ALERT = "alertViolated.alert.label";
const REPORT_LABEL_FLEET_ALERT_VIOLATED = "alertViolated.fleet.label";
const REPORT_LABEL_TRACE = "alertViolated.trace.label";
const REPORT_LABEL_PERIOD = "alertViolated.period.label";
const REPORT_LABEL_STATUS = "alertViolated.status.label";
const REPORT_VALIDATION_MESSAGE_PERIOD = "alertViolated.period.validation_message";
const ALERT_VIOLATED_DETAILS_ALERT_TITLE = "alertViolated.details.alert.title";
const ALERT_VIOLATED_DETAILS_ALERT_NAME = "alertViolated.details.alert.name";
const ALERT_VIOLATED_DETAILS_ALERT_CRITERY = "alertViolated.details.alert.critery";
const ALERT_VIOLATED_DETAILS_ALERT_ACTIVE_DAYS = "alertViolated.details.alert.active_days";
const ALERT_VIOLATED_DETAILS_ALERT_HOUR = "alertViolated.details.alert.hour";
const ALERT_VIOLATED_DETAILS_ALERT_TREATMENT = "alertViolated.details.alert.treatment";
const ALERT_VIOLATED_DETAILS_ALERT_USER_TREATMENT = "alertViolated.details.alert.user_treatment";
const ALERT_VIOLATED_DETAILS_CONDITIONS = "alertViolated.details.conditions";
const ALERT_VIOLATED_DETAILS_ACTIONS = "alertViolated.details.actions";
const ALERT_VIOLATED_DETAILS_EVENT_TITLE = "alertViolated.details.event.title";
const ALERT_VIOLATED_DETAILS_EVENT_TYPE = "alertViolated.details.event.type";
const ALERT_VIOLATED_DETAILS_EVENT_DATE = "alertViolated.details.event.date";
const ALERT_VIOLATED_DETAILS_EVENT_PLATE = "alertViolated.details.event.plate";
const ALERT_VIOLATED_DETAILS_EVENT_LOCALIZATION = "alertViolated.details.event.localization";
const ALERT_VIOLATED_DETAILS_EVENT_ADDRESS = "alertViolated.details.event.address";
const ALERT_VIOLATED_DETAILS_EVENT_DRIVER = "alertViolated.details.event.driver";
const ALERT_VIOLATED_DETAILS_EVENT_MARK = "alertViolated.details.event.mark";
const ALERT_VIOLATED_DETAILS_EVENT_MODEL = "alertViolated.details.event.model";
const ALERT_VIOLATED_DETAILS_EVENT_VELOCITY = "alertViolated.details.event.velocity";
const ALERT_VIOLATED_DETAILS_IGNITION = "alertViolated.details.ignition";
const ALERT_VIOLATED_DETAILS_GPS = "alertViolated.details.gps";
const ALERT_VIOLATED_DETAILS_HISTORIC = "alertViolated.details.historic.label";
const ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED = "alertViolated.details.historic.required";
const ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED_CADASTRED_TITLE = "alertViolated.details.historic.cadastred.title";
const ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED_CADASTRED_USER = "alertViolated.details.historic.cadastred.user";
const ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED_CADASTRED_DATE = "alertViolated.details.historic.cadastred.date";
const ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED_CADASTRED_TREATMENT =
    "alertViolated.details.historic.cadastred.treatment";
const ALERT_VIOLATED_MSG_SUCESSS = "alertViolated.details.messages.success";
const ALERT_VIOLATED_DETAILS_COLUMNS_NAME = "alertViolated.details.columns.name";
const ALERT_VIOLATED_DETAILS_COLUMNS_ID = "alertViolated.details.columns.id";
const ALERT_VIOLATED_DETAILS_COLUMNS_STATUS = "alertViolated.details.columns.status";
const ALERT_VIOLATED_DETAILS_COLUMNS_REGISTER_DATE = "alertViolated.details.columns.register_date";
const ALERT_VIOLATED_DETAILS_COLUMNS_REGISTER_USER = "alertViolated.details.columns.register_user";
const ALERT_VIOLATED_DETAILS_COLUMNS_EDIT_USER = "alertViolated.details.columns.edit_user";
const ALERT_VIOLATED_DETAILS_CONCLUDE = "alertViolated.details.conclude";
const ALERT_VIOLATED_BUTTON_CONCLUDE = "alertViolated.btnConclude";
const ALERT_VIOLATED_BUTTON_VIEW = "alertViolated.btnView";

//tutorial

const TUTORIAL_TIPS = "tutorial.tips";
const TUTORIAL_SHOWLESS = "tutorial.showLess";
const TUTORIAL_SHOWMORE = "tutorial.showMore";
const CARD_TUTORIAL_BUTTON = "tutorial.cardTutorial.goPage";

// Report Event
const REPORT_EVENT_VEHICLE_LABEL = "report.event.form.vehicle.label";
const REPORT_EVENT_VEHICLE_PLACEHOLDER = "report.event.form.vehicle.placeholder";
const REPORT_EVENT_VEHICLE_MSG_OBLIGATED = "report.event.form.vehicle.obligated";
const REPORT_EVENT_VEHICLE_MSG_OBLIGATED_WITHOUT_OR = "report.event.form.vehicle.obligated_without_or";
const REPORT_EVENT_FLEET_LABEL = "report.event.form.fleet.label";
const REPORT_EVENT_FLEET_PLACEHOLDER = "report.event.form.fleet.placeholder";
const REPORT_EVENT_FLEET_MSG_OBLIGATED = "report.event.form.fleet.obligated";
const REPORT_EVENT_SPEED_LABEL = "report.event.form.speed.label";
const REPORT_EVENT_SPEED_METRIC = "report.event.form.speed.metric";
const REPORT_EVENT_PERIOD_LABEL = "report.event.form.period.label";
const REPORT_EVENT_PERIOD_MSG_OBLIGATED = "report.event.form.period.obligated";
const REPORT_EVENT_PERIOD_MSG_NOT_VALID = "report.event.form.period.not_valid";
const REPORT_EVENT_PERIOD_MSG_PERIOD_WEEK = "report.event.form.period.period_week";
const REPORT_EVENT_EMAIL_LABEL = "report.event.form.email.label";
const REPORT_EVENT_EMAIL_PLACEHOLDER = "report.event.form.email.placeholder";
const REPORT_EVENT_EMAIL_MSG_OBLIGATED = "report.event.form.email.obligated";
const REPORT_EVENT_EMAIL_MSG_NOT_VALID = "report.event.form.email.not_valid";
const REPORT_EVENT_SAVE_BUTTON = "report.event.form.save_button";

//Report Distance

const REPORT_DISTANCE_DAYS_OF_WEEKS = "report.distance.form.days_of_week";
const REPORT_DISTANCE_INITIAL_DATE = "report.distance.initial_date";
const REPORT_DISTANCE_FINAL_DATE = "report.distance.final_date";
const REPORT_DISTANCE_REPORT_REGISTER_DATE = "report.distance.report_register_date";
const REPORT_DISTANCE_REPORT_AMOUNT_VEHICLES = "report.distance.amount_vehicles";
const REPORT_DISTANCE_PROCESSING_PERCENT = "report.distance.processing_percent";
const REPORT_DISTANCE_FILE_FORMAT = "report.distance.file_format";
const REPORT_DISTANCE_FILE_CREATION_DATE = "report.distance.file_creation_date";
const REPORT_DISTANCE_TITLES_REPORT_SCHEDULED = "report.distance.titles.report_scheduled";
const REPORT_DISTANCE_TITLES_REPORT_SENT = "report.distance.titles.report_sent";

const REPORT_SHEDULED_FREQUENCY = "report.sheduled.frequency.label";
const REPORT_SHEDULED_FREQUENCY_REQUIRED = "report.sheduled.frequency.required";
const REPORT_SHEDULED_FILE_TYPE = "report.sheduled.file_type.label";
const REPORT_SHEDULED_FILE_TYPE_REQUIRED = "report.sheduled.file_type.required";
const REPORT_SHEDULED_DATE = "report.sheduled.date.label";
const REPORT_SHEDULED_VEHICLE = "report.sheduled.vehicle.label";
const REPORT_SHEDULED_VEHICLE_REQUIRED = "report.sheduled.vehicle.required";
const REPORT_SHEDULED_EMAIL = "report.sheduled.email.label";
const REPORT_SHEDULED_EMAIL_REQUIRED = "report.sheduled.email.required";
const REPORT_SHEDULED_EMAIL_INVALID = "report.sheduled.email.invalid";

// Report Speeding
const REPORT_SPEEDING_DETAILED = "report_speeding.detailed";
const REPORT_SPEEDING_ROAD_SPEED = "report_speeding.road_speed";

//Group Drivers
const GROUP_DRIVER_COMEBACK = "groupDrivers.register.comeback";
const GROUP_DRIVERS_REGISTER_NAME = "groupDrivers.register.name.label";
const GROUP_DRIVERS_REGISTER_NAME_PLACEHOLDER = "groupDrivers.register.name.placeholder";
const GROUP_DRIVERS_REGISTER_CPF = "groupDrivers.register.cpf.label";
const GROUP_DRIVERS_REGISTER_CNH = "groupDrivers.register.cnh.label";
const GROUP_DRIVERS_REGISTER_CATEGORIA = "groupDrivers.register.categoria.label";
const GROUP_DRIVERS_REGISTER_IDENTIFICADOR = "groupDrivers.register.identificador.label";
const GROUP_DRIVERS_REGISTER_REQUIRED = "groupDrivers.register.name.required";
const GROUP_DRIVERS_REGISTER_ACTIVE = "groupDrivers.register.actived.label";
const GROUP_DRIVERS_REGISTER_BTNSAVE = "groupDrivers.register.btnSave.label";

const GROUP_DRIVERS_LIST_BTNADD = "groupDrivers.list.btnAddLabel";
const GROUP_DRIVERS_LIST_BTN_EDIT = "groupDrivers.list.btnEditLabel";
const GROUP_DRIVERS_LIST_LABEL = "groupDrivers.list.groupLabelList";
const GROUP_DRIVERS_LINKED_LABEL = "groupDrivers.list.groupDriversLinkedLabel";
const GROUP_DRIVERS_AVAILABLES_DRIVERS_LABEL = "groupDrivers.list.availablesDriversLabel";

//Group Points
const GROUP_POINTS_COMEBACK = "groupPoints.register.comeback";
const GROUP_POINTS_REGISTER_NAME = "groupPoints.register.name.label";
const GROUP_POINTS_REGISTER_NAME_PLACEHOLDER = "groupPoints.register.name.placeholder";
const GROUP_POINTS_REGISTER_REQUIRED = "groupPoints.register.name.required";
const GROUP_POINTS_REGISTER_ACTIVE = "groupPoints.register.actived.label";
const GROUP_POINTS_REGISTER_BTNSAVE = "groupPoints.register.btnSave.label";

const GROUP_POINTS_COLUMN_POINTS = "groupPoints.columns.points.label";
const GROUP_POINTS_COLUMN_ACTIVE = "groupPoints.columns.active.label";
const GROUP_POINTS_COLUMN_GROUP_POINTS = "groupPoints.columns.group_points.label";
const GROUP_POINTS_COLUMN_CODE = "groupPoints.columns.code.label";
const GROUP_POINTS_COLUMN_ACTIONS = "groupPoints.columns.actions.label";

const GROUP_POINTS_LIST_BTNADD = "groupPoints.list.btnAddLabel";
const GROUP_POINTS_LIST_BTN_EDIT = "groupPoints.list.btnEditLabel";
const GROUP_POINTS_LIST_LABEL = "groupPoints.list.groupLabelList";
const GROUP_POINTS_LINKED_LABEL = "groupPoints.list.groupPointsLinkedLabel";
const GROUP_POINTS_OF_INTEREST_LINKED_LABEL = "groupPoints.list.groupPointsOfInterestLinkedLabel";

//Driver register

const REGISTER_DRIVER_COMEBACK = "register.driver.comeback";
const REGISTER_DRIVER_FORM_ADD_TITLE = "register.driver.form.add_title";
const REGISTER_DRIVER_FORM_EDIT_TITLE = "register.driver.form.edit_title";
const REGISTER_DRIVER_FORM_IMAGE_MSG_INVALID_TYPE = "register.driver.form.image.invalid_type";
const REGISTER_DRIVER_FORM_NAME = "register.driver.form.name.label";
const REGISTER_DRIVER_FORM_NAME_MSG_REQUIRED = "register.driver.form.name.required";
const REGISTER_DRIVER_FORM_BIRTHDAY = "register.driver.form.birthday.label";
const REGISTER_DRIVER_FORM_BIRTHDAY_MINIMUM_AGE = "register.driver.form.birthday.invalid";
const REGISTER_DRIVER_FORM_PHONE = "register.driver.form.phone";
const REGISTER_DRIVER_FORM_CPF = "register.driver.form.cpf.label";
const REGISTER_DRIVER_FORM_CPF_MSG_REQUIRED = "register.driver.form.cpf.required";
const REGISTER_DRIVER_FORM_CPF_MSG_INVALID = "register.driver.form.cpf.invalid";
const REGISTER_DRIVER_FORM_CNPJ = "register.driver.form.cnpj.label";
const REGISTER_DRIVER_FORM_CNPJ_MSG_INVALID = "register.driver.form.cnpj.invalid";
const REGISTER_DRIVER_FORM_RG = "register.driver.form.rg";
const REGISTER_DRIVER_FORM_STATE_RG = "register.driver.form.state_rg";
const REGISTER_DRIVER_FORM_CNH_NUMBER = "register.driver.form.cnh_number";
const REGISTER_DRIVER_FORM_CATEGORY = "register.driver.form.category";
const REGISTER_DRIVER_FORM_CNH_RENOVATION_DAY = "register.driver.form.cnh_renovation_day";
const REGISTER_DRIVER_FORM_CNH_CITY = "register.driver.form.cnh_city";
const REGISTER_DRIVER_FORM_CARD = "register.driver.form.card";
const REGISTER_DRIVER_FORM_STATUS = "register.driver.form.status";
const REGISTER_DRIVER_FORM_STATUS_LABEL = "register.driver.form.status_label";
const REGISTER_DRIVER_FORM_COMENTARY = "register.driver.form.comentary";
const REGISTER_DRIVER_FORM_BUTTON_CANCEL = "register.driver.form.buttons.cancel";
const REGISTER_DRIVER_FORM_BUTTON_CONFIRM = "register.driver.form.buttons.confirm";
const REGISTER_DRIVER_FORM_MSG_SUCCESS = "register.driver.form.msgs.success";
const REGISTER_DRIVER_FORM_MSG_CPF_ALREADY_EXISTS = "register.driver.form.msgs.cpf_already_exists";

const REGISTER_POINT_INTEREST_COMEBACK = "register.points_interest.comeback";
const REGISTER_POINT_INTEREST_FORM_ADD_TITLE = "register.points_interest.form.add_title";
const REGISTER_POINT_INTEREST_FORM_EDIT_TITLE = "register.points_interest.form.edit_title";
const REGISTER_POINT_INTEREST_FORM_FORM_TYPE = "register.points_interest.form.form_type.label";
const REGISTER_POINT_INTEREST_FORM_FORM_TYPE_POINT = "register.points_interest.form.form_type.point";
const REGISTER_POINT_INTEREST_FORM_FORM_TYPE_FENCE = "register.points_interest.form.form_type.fence";
const REGISTER_POINT_INTEREST_FORM_NAME = "register.points_interest.form.name.label";
const REGISTER_POINT_INTEREST_FORM_NAME_MSG_REQUIRED = "register.points_interest.form.name.required";
const REGISTER_POINT_INTEREST_FORM_TYPE = "register.points_interest.form.type.label";
const REGISTER_POINT_INTEREST_FORM_TYPE_MSG_REQUIRED = "register.points_interest.form.type.required";
const REGISTER_POINT_INTEREST_FORM_ADDRESS = "register.points_interest.form.address.label";
const REGISTER_POINT_INTEREST_FORM_ADDRESS_MSG_REQUIRED = "register.points_interest.form.address.required";
const REGISTER_POINT_INTEREST_FORM_LATITUDE = "register.points_interest.form.latitude.label";
const REGISTER_POINT_INTEREST_FORM_LATITUDE_MSG_REQUIRED = "register.points_interest.form.latitude.required";
const REGISTER_POINT_INTEREST_FORM_LONGITUDE = "register.points_interest.form.longitude.label";
const REGISTER_POINT_INTEREST_FORM_LONGITUDE_MSG_REQUIRED = "register.points_interest.form.longitude.required";
const REGISTER_POINT_INTEREST_FORM_RADIUS = "register.points_interest.form.radius.label";
const REGISTER_POINT_INTEREST_FORM_RADIUS_MSG_REQUIRED = "register.points_interest.form.radius.required";
const REGISTER_POINT_INTEREST_FORM_COORDINATES = "register.points_interest.form.coordinates.label";
const REGISTER_POINT_INTEREST_FORM_COORDINATES_MSG_REQUIRED = "register.points_interest.form.coordinates.required";
const REGISTER_POINT_INTEREST_FORM_BUTTON_CANCEL = "register.points_interest.form.buttons.cancel";
const REGISTER_POINT_INTEREST_FORM_BUTTON_CONFIRM = "register.points_interest.form.buttons.confirm";
const REGISTER_POINT_INTEREST_FORM_MSG_SUCCESS = "register.points_interest.form.msgs.success";

//Driver List
const REGISTER_DRIVER_BUTTON_ADD_DRIVER = "register.driver.button.add.driver";
const REGISTER_DRIVER_BUTTON_IMPORT_DRIVER = "register.driver.button.import.driver";
const REGISTER_DRIVER_LIST = "register.driver.list";

//Point Interest
const REGISTER_POINTS_INTEREST_BUTTON_ADD = "register.driver.button.add.points";
const REGISTER_POINTS_INTEREST_BUTTON_IMPORT = "register.driver.button.import.points";

//Dashboard
const DASHBOARD_KM_RUN_TITLE = "dashboard.kmRun.title";
const DASHBOARD_KM_RUN_PERIOD_TITLE = "dashboard.kmRunPeriod.title";
const DASHBOARD_KM_RUN_PERIOD_DAY_TITLE = "dashboard.kmRunPeriodDay.title";
const DASHBOARD_KM_RUN_PERIOD_WEEK_TITLE = "dashboard.kmRunPeriodWeek.title";
const DASHBOARD_PERIOD_TRIPS_TITLE = "dashboard.periodTrips.title";
const DASHBOARD_PERIOD_TRIPS_CHARTS_DAYBREAK = "dashboard.periodTrips.charts.daybreak";
const DASHBOARD_PERIOD_TRIPS_CHARTS_MORNING = "dashboard.periodTrips.charts.morning";
const DASHBOARD_PERIOD_TRIPS_CHARTS_AFTERNOON = "dashboard.periodTrips.charts.afternoon";
const DASHBOARD_PERIOD_TRIPS_CHARTS_NIGHT = "dashboard.periodTrips.charts.night";
const DASHBOARD_BASE_COMPARATIVE_KM_RUN = "dashboard.baseComparative.kmRunBase.label";
const DASHBOARD_BASE_COMPARATIVE_ITEMS = "dashboard.baseComparative.analysisItemBase.label";
const DASHBOARD_ROAD_URBAN_OFFSET_TITLE = "dashboard.roadUrbanOffset.title";
const DASHBOARD_TOP_TEN_DRIVER_VEHICLE_TITLE = "dashboard.topTenDriverVehicle.title";
const DASHBOARD_FLEET_EVOLUTION_PERCENT_TITLE = "dashboard.fleet_evolution_percent.title";
const DASHBOARD_FLEET_EVOLUTION_PERCENT_VEHICLE_TITLE = "dashboard.fleet_evolution_percent.vehicle_title";
const DASHBOARD_FLEET_EVOLUTION_PERCENT_CHARTS = "dashboard.fleet_evolution_percent.charts";
const DASHBOARD_FLEET_EVOLUTION_PERCENT_TAB_METRIC = "dashboard.fleet_evolution_percent.tabs.metric";
const DASHBOARD_FLEET_EVOLUTION_PERCENT_TAB_PERCENT = "dashboard.fleet_evolution_percent.tabs.percent";
const DASHBOARD_FLEET_EVOLUTION_TITLE = "dashboard.fleet_evolution.title";
const DASHBOARD_FLEET_EVOLUTION_TAB_METRIC = "dashboard.fleet_evolution.tabs.metric";
const DASHBOARD_FLEET_EVOLUTION_TAB_PERCENT = "dashboard.fleet_evolution.tabs.percent";
const DASHBOARD_FLEET_EVOLUTION_TAB_MONTHLY = "dashboard.fleet_evolution.tabs.monthly";
const DASHBOARD_FLEET_EVOLUTION_CHARTS = "dashboard.fleet_evolution.charts";
const DASHBOARD_TOP_TEN_MAX_VELOCITY_VEHICLE_CARD_TITLE = "dashboard.top_ten_max_velocity_vehicle.card_title";
const DASHBOARD_TOP_TEN_MAX_VELOCITY_VEHICLE_PLATE = "dashboard.top_ten_max_velocity_vehicle.plate";
const DASHBOARD_TOP_TEN_MAX_VELOCITY_VEHICLE_MAX_VELOCITY = "dashboard.top_ten_max_velocity_vehicle.max_velocity";
const DASHBOARD_TOP_TEN_MAX_VELOCITY_DRIVER_CARD_TITLE = "dashboard.top_ten_max_velocity_driver.card_title";
const DASHBOARD_TOP_TEN_MAX_VELOCITY_DRIVER_DRIVER = "dashboard.top_ten_max_velocity_driver.driver";
const DASHBOARD_TOP_TEN_MAX_VELOCITY_DRIVER_MAX_VELOCITY = "dashboard.top_ten_max_velocity_driver.max_velocity";
const DASHBOARD_DRIVABILITY_SCORE_TITLE = "dashboard.drivabilityScore.title";
const DASHBOARD_RISK_EXPOSURE_SCORE_TITLE = "dashboard.riskExposureScore.title";
const DASHBOARD_COMPOSITION_TITLE = "dashboard.riskExposureScore.extraTitle";
const DASHBOARD_ACCIDENT_PROBABILITY_TITLE = "dashboard.accidentProbability.title";
const DASHBOARD_ANALYSIS_ITEMS_TITLE = "dashboard.analysisItems.title";
const DASHBOARD_ANALYSIS_ITEMS_QTD_ACELERACAO_BRUSCA = "dashboard.analysisItems.qtdAceleracaoBrusca";
const DASHBOARD_ANALYSIS_ITEMS_QTD_EXCESSO_VELOCIDADE = "dashboard.analysisItems.qtdExcessoVelocidade";
const DASHBOARD_ANALYSIS_ITEMS_QTD_CURVA_AGRESSIVA = "dashboard.analysisItems.qtdCurvaAgressiva";
const DASHBOARD_ANALYSIS_ITEMS_QTD_VIAGEM_NAO_COMERCIAL = "dashboard.analysisItems.qtdViagemNaoComercial";
const DASHBOARD_ANALYSIS_ITEMS_QTD_FREADA_BRUSCA = "dashboard.analysisItems.qtdFreadaBrusca";
const DASHBOARD_ANALYSIS_ITEMS_QTD_VIAGEM_FIM_SEMANA = "dashboard.analysisItems.qtdViagemFimSemana";
const DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TITLE = "dashboard.topTenAnalyzeDriverVehicle.title";
const DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TABLE_PLATE = "dashboard.topTenAnalyzeDriverVehicle.table.plate";
const DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TABLE_DRIVER = "dashboard.topTenAnalyzeDriverVehicle.table.driver";
const DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TABLE_VALUE = "dashboard.topTenAnalyzeDriverVehicle.table.value";
const DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_SPEEDING =
    "dashboard.topTenAnalyzeDriverVehicle.tabs.km_run_analyze_speeding";
const DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_BRUSQUE_ACCELERATION =
    "dashboard.topTenAnalyzeDriverVehicle.tabs.km_run_analyze_brusque_acceleration";
const DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_BRUSQUE_CURVE =
    "dashboard.topTenAnalyzeDriverVehicle.tabs.km_run_analyze_brusque_curve";
const DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_BRUSQUE_BRAKED =
    "dashboard.topTenAnalyzeDriverVehicle.tabs.km_run_analyze_brusque_braked";
const DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_DISPLACEMENT_NOT_COMMERCIAL =
    "dashboard.topTenAnalyzeDriverVehicle.tabs.km_run_analyze_displacement_not_commercial";
const DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_DISPLACEMENT_WEEKEND =
    "dashboard.topTenAnalyzeDriverVehicle.tabs.km_run_analyze_displacement_weekend";
const KM_RUN_LABEL = "dashboard.top_ten_drivers_vehicles.kmRunLabel";
const KM_RUN_COMMERCIAL_LABEL = "dashboard.top_ten_drivers_vehicles.kmRunCommercialLabel";
const KM_RUN_NOT_COMMERCIAL_LABEL = "dashboard.top_ten_drivers_vehicles.kmRunNotCommercialLabel";
const KM_RUN_WEEKEND_LABEL = "dashboard.top_ten_drivers_vehicles.kmRunWeekendLabel";
const DASHBOARD_CHARTS_FORMATTER_TOTAL = "dashboard.charts_formatters.total";
const DASHBOARD_CHARTS_FORMATTER_AVERAGE_PER_VEHICLE = "dashboard.charts_formatters.average_per_vehicle";

// Dashboard de veiculo
const DASHBOARD_VEHICLE_HEATMAP = "dashboard_vehicle.heatmap.title";
const NO_RESULTS = "dashboard_vehicle.noResults.title";

const DASHBOARD_VEHICLE_DATA_VEHICLE_TITLE = "dashboard_vehicle.data_vehicle.title";
const DASHBOARD_VEHICLE_DATA_VEHICLE_PLATE = "dashboard_vehicle.data_vehicle.plate";
const DASHBOARD_VEHICLE_DATA_VEHICLE_PRODUCER = "dashboard_vehicle.data_vehicle.producer";
const DASHBOARD_VEHICLE_DATA_VEHICLE_MODEL = "dashboard_vehicle.data_vehicle.model";
const DASHBOARD_VEHICLE_DATA_VEHICLE_CHASSI = "dashboard_vehicle.data_vehicle.chassi";
const DASHBOARD_VEHICLE_PRIVATE_MODE_TITLE = "dashboard_vehicle.private_mode.title";
const DASHBOARD_VEHICLE_PRIVATE_MODE_ACTIVE = "dashboard_vehicle.private_mode.active";
const DASHBOARD_VEHICLE_PRIVATE_MODE_INACTIVE = "dashboard_vehicle.private_mode.inactive";
const DASHBOARD_VEHICLE_PRIVATE_MODE_ACTIVE_BUTTON = "dashboard_vehicle.private_mode.active_button";
const DASHBOARD_VEHICLE_PRIVATE_MODE_NOT_INFO = "dashboard_vehicle.private_mode.not_info";
const DASHBOARD_VEHICLE_PRIVATE_MODE_INACTIVE_BUTTON = "dashboard_vehicle.private_mode.inactive_button";
const DASHBOARD_VEHICLE_PRIVATE_MODE_MSGS_ERROR_REFRESH = "dashboard_vehicle.private_mode.msgs.error_refresh";
const DASHBOARD_VEHICLE_PRIVATE_MODE_MSGS_ERROR_SAVE = "dashboard_vehicle.private_mode.msgs.error_save";
const DASHBOARD_VEHICLE_PRIVATE_MODE_MSGS_SUCESS_SAVE = "dashboard_vehicle.private_mode.msgs.sucess_save";

//Profile
const PROFILE_COMEBACK = "profile.comeback";
const PROFILE_PERSONAL_INFORMATION_TITLE = "profile.personal_information.title";
const PROFILE_PERSONAL_INFORMATION_IMAGE_INVALID = "profile.personal_information.image.invalid_type";
const PROFILE_PERSONAL_INFORMATION_LOGIN = "profile.personal_information.login.label";
const PROFILE_PERSONAL_INFORMATION_NAME = "profile.personal_information.name.label";
const PROFILE_PERSONAL_INFORMATION_NAME_REQUIRED = "profile.personal_information.name.required";
const PROFILE_PERSONAL_INFORMATION_EMAIL = "profile.personal_information.email.label";
const PROFILE_PERSONAL_INFORMATION_EMAIL_REQUIRED = "profile.personal_information.email.required";
const PROFILE_PERSONAL_INFORMATION_EMAIL_INVALID = "profile.personal_information.email.invalid";
const PROFILE_PERSONAL_INFORMATION_LANGUAGE = "profile.personal_information.language.label";
const PROFILE_PERSONAL_INFORMATION_LANGUAGE_REQUIRED = "profile.personal_information.language.required";
const PROFILE_PERSONAL_INFORMATION_COUNTRY = "profile.personal_information.country.label";
const PROFILE_PERSONAL_INFORMATION_COUNTRY_REQUIRED = "profile.personal_information.country.required";
const PROFILE_PERSONAL_INFORMATION_TIMEZONE = "profile.personal_information.timezone.label";
const PROFILE_PERSONAL_INFORMATION_TIMEZONE_REQUIRED = "profile.personal_information.timezone.required";
const PROFILE_PERSONAL_INFORMATION_NEW_PASSWORD = "profile.personal_information.new_password.label";
const PROFILE_PERSONAL_INFORMATION_NEW_PASSWORD_INVALID = "profile.personal_information.new_password.invalid";
const PROFILE_PERSONAL_INFORMATION_NEW_PASSWORD_RULES = "profile.personal_information.new_password.rules";
const PROFILE_PERSONAL_INFORMATION_REPEAT_PASSWORD = "profile.personal_information.repeat_password.label";
const PROFILE_PERSONAL_INFORMATION_REPEAT_PASSWORD_REQUIRED = "profile.personal_information.repeat_password.required";
const PROFILE_PERSONAL_INFORMATION_REPEAT_PASSWORD_NOT_EQUAL = "profile.personal_information.repeat_password.not_equal";
const PROFILE_CUSTOMIZATION_TITLE = "profile.customization.title";
const PROFILE_CUSTOMIZATION_LOGO = "profile.customization.logo.label";
const PROFILE_CUSTOMIZATION_LOGO_REMOVE = "profile.customization.logo.remove";
const PROFILE_CUSTOMIZATION_LOGO_SHOW_ON_REPORT = "profile.customization.logo.show_on_report";
const PROFILE_CUSTOMIZATION_LOGO_RULES = "profile.customization.logo.rules";
const PROFILE_CUSTOMIZATION_LOGO_INVALID_TYPE = "profile.customization.logo.invalid_type";
const PROFILE_CUSTOMIZATION_LOGO_INVALID_SIZE = "profile.customization.logo.invalid_size";
const PROFILE_CUSTOMIZATION_COLOR_CONFIGURATION = "profile.customization.color_configuration.title";
const PROFILE_CUSTOMIZATION_COLOR_CONFIGURATION_PRIMARY = "profile.customization.color_configuration.primary.label";
const PROFILE_CUSTOMIZATION_COLOR_CONFIGURATION_PRIMARY_REQUIRED =
    "profile.customization.color_configuration.primary.required";
const PROFILE_CUSTOMIZATION_COLOR_CONFIGURATION_SECONDARY = "profile.customization.color_configuration.secondary.label";
const PROFILE_CUSTOMIZATION_COLOR_CONFIGURATION_SECONDARY_REQUIRED =
    "profile.customization.color_configuration.secondary.required";
const PROFILE_BUTTON_CANCEL = "profile.buttons.cancel";
const PROFILE_BUTTON_CONFIRM = "profile.buttons.confirm";
const PROFILE_FORM_MSG_SUCCESS = "profile.msgs.success";

const ALERT_FORM_TITLE = "alert.form.title";
const ALERT_FORM_NAME = "alert.form.name.label";
const ALERT_FORM_NAME_REQUIRED = "alert.form.name.required";
const ALERT_FORM_DAY_WEEK = "alert.form.day_week.label";
const ALERT_FORM_DAY_WEEK_REQUIRED = "alert.form.day_week.required";
const ALERT_FORM_HOUR_WEEK = "alert.form.hour_week.label";
const ALERT_FORM_HOUR_WEEK_REQUIRED_START = "alert.form.hour_week.required_start";
const ALERT_FORM_HOUR_WEEK_REQUIRED_END = "alert.form.hour_week.required_end";
const ALERT_FORM_EXPIRATION = "alert.form.expiration.label";
const ALERT_FORM_CONDITONS = "alert.form.conditons.label";
const ALERT_FORM_CONDITONS_ADD = "alert.form.conditons.add";
const ALERT_FORM_CONDITONS_PLACEHOLDER = "alert.form.conditons.placeholder";
const ALERT_FORM_CONDITONS_REQUIRED = "alert.form.conditons.required";
const ALERT_FORM_CONDITONS_OPTION_PLATFORM_ACTIVE = "alert.form.conditons.options.platform_active";
const ALERT_FORM_CONDITONS_OPTION_PLATFORM_INACTIVE = "alert.form.conditons.options.platform_inactive";
const ALERT_FORM_CONDITONS_OPTION_ENGAGE = "alert.form.conditons.options.engage";
const ALERT_FORM_CONDITONS_OPTION_DISENGAGED = "alert.form.conditons.options.disengaged";
const ALERT_FORM_CONDITONS_OPTION_IN = "alert.form.conditons.options.in";
const ALERT_FORM_CONDITONS_OPTION_OUT = "alert.form.conditons.options.out";
const ALERT_FORM_CONDITONS_OPTION_ACTIVE = "alert.form.conditons.options.active";
const ALERT_FORM_CONDITONS_OPTION_INACTIVE = "alert.form.conditons.options.inactive";
const ALERT_FORM_CONDITONS_OPTION_MORE_THAN = "alert.form.conditons.options.more_than";
const ALERT_FORM_CONDITONS_OPTION_LESS_THAN = "alert.form.conditons.options.less_than";
const ALERT_FORM_CONDITONS_OPTION_PF = "alert.form.conditons.options.pf";
const ALERT_FORM_CONDITONS_OPTION_PJ = "alert.form.conditons.options.pj";
const ALERT_FORM_CONDITONS_OPTION_EQUAL = "alert.form.conditons.options.equal";
const ALERT_FORM_CONDITONS_OPTION_ONLY_THIS_ALERT = "alert.form.conditons.options.only_this_alert";
const ALERT_FORM_CONDITONS_OPTION_ALL_VEHICLES = "alert.form.conditons.options.all_vehicles";
const ALERT_FORM_CONDITONS_OPTION_ALL_ALERTS = "alert.form.conditons.options.all_alerts";
const ALERT_FORM_CONDITONS_OPTION_URBAN = "alert.form.conditons.options.urban";
const ALERT_FORM_CONDITONS_OPTION_ROAD = "alert.form.conditons.options.road";
const ALERT_FORM_CONDITONS_LABEL_KMH = "alert.form.conditons.labels.kmh";
const ALERT_FORM_CONDITONS_LABEL_MINUTES = "alert.form.conditons.labels.minutes";
const ALERT_FORM_CONDITONS_LABEL_METERS = "alert.form.conditons.labels.meters";
const ALERT_FORM_CONDITONS_LABEL_DAYS = "alert.form.conditons.labels.days";
const ALERT_FORM_CONDITONS_PLACEHOLDER_SEARCH_ROUTE = "alert.form.conditons.placeholder.search_route";
const ALERT_FORM_CONDITONS_PLACEHOLDER_SEARCH_INTEREST_POINT = "alert.form.conditons.placeholder.search_interest_point";
const ALERT_FORM_CONDITONS_PLACEHOLDER_SERACH_GROUP_INTEREST_POINT =
    "alert.form.conditons.placeholder.serach_group_interest_point";
const ALERT_FORM_CONDITONS_ERROR_REQUIRED_LIMIT_DATE = "alert.form.conditons.errors.required_limit_date";
const ALERT_FORM_CONDITONS_ERROR_REQUIRED_VALUE = "alert.form.conditons.errors.required_value";
const ALERT_FORM_CONDITONS_ERROR_REQUIRED_TEXT = "alert.form.conditons.errors.required_text";
const ALERT_FORM_CONDITONS_ERROR_REQUIRED_ROUTE = "alert.form.conditons.errors.required_route";
const ALERT_FORM_CONDITONS_ERROR_REQUIRED_INTEREST_POINT = "alert.form.conditons.errors.required_interest_point";
const ALERT_FORM_CONDITONS_ERROR_REQUIRED_GROUP_INTEREST_POINT =
    "alert.form.conditons.errors.required_group_interest_point";
const ALERT_FORM_CONDITONS_ERROR_VALID_MIN_MAX = "alert.form.conditons.errors.valid_min_max";
const ALERT_FORM_ACTIONS = "alert.form.actions.label";
const ALERT_FORM_ACTIONS_ADD = "alert.form.actions.add";
const ALERT_FORM_ACTIONS_PLACEHOLDER = "alert.form.actions.placeholder";
const ALERT_FORM_BUTTON_CONFIRM = "alert.form.buttons.confirm";
const ALERT_FORM_BUTTON_CANCEL = "alert.form.buttons.cancel";
const ALERT_CARD_INSERT_TITLE = "alert.cards.insert.title";
const ALERT_CARD_INSERT_TEXT = "alert.cards.insert.text";
const ALERT_CARD_WEEKEND_TITLE = "alert.cards.weekend.title";
const ALERT_CARD_WEEKEND_TEXT = "alert.cards.weekend.text";
const ALERT_CARD_OUT_HOURS_TITLE = "alert.cards.out_hours.title";
const ALERT_CARD_OUT_HOURS_TEXT = "alert.cards.out_hours.text";
const ALERT_CARD_VELOCITY_TITLE = "alert.cards.velocity.title";
const ALERT_CARD_VELOCITY_TEXT = "alert.cards.velocity.text";
const ALERT_CARD_ENTERING_RISK_TITLE = "alert.cards.entering_risk.title";
const ALERT_CARD_ENTERING_RISK_TEXT = "alert.cards.entering_risk.text";
const ALERT_CARD_LEAVING_RISK_TITLE = "alert.cards.leaving_risk.title";
const ALERT_CARD_LEAVING_RISK_TEXT = "alert.cards.leaving_risk.text";
const ALERT_CARD_NO_SIGNAL_TITLE = "alert.cards.no_signal.title";
const ALERT_CARD_NO_SIGNAL_TEXT = "alert.cards.no_signal.text";
const ALERT_EDIT_BUTTON_BOND = "alert.edit.bond";
const ALERT_EDIT_BUTTON_DEACTIVE = "alert.edit.deactive";
const ALERT_FORM_MSG_SAVE_SUCCESS = "alert.form.msgs.save_success";
const ALERT_FORM_MSG_EDIT_SUCCESS = "alert.form.msgs.edit_success";
const ALERT_LIST_AVAILABLE = "alert.alert_bond.double_list.title_available";
const ALERT_LIST_ALERT = "alert.alert_bond.double_list.title_alert";
const ALERT_TAB_EDIT = "alert.tabs.edit";
const ALERT_TAB_IMPORT_CARS = "alert.tabs.import_cars";
const ALERT_TAB_HISTORIC = "alert.tabs.historic";
const ALERT_FORM_EMAIL_REQUIRED = "alert.form.email.required";
const ALERT_NONE_SELECTED = "alert.none_selected";
const ALERT_GET_ERROR = "alert.get_error";
const ALERT_NONE_FOUND = "alert.none_found";

// Routes

const ROUTE_ADD_ROUTE = "route.add_route";
const ROUTE_MAP = "route.map";
const ROUTE_LIST_TITLE = "route.list.title";
const ROUTE_LIST_CODE = "route.list.code";
const ROUTE_LIST_NAME = "route.list.name";
const ROUTE_LIST_ACTIONS = "route.list.actions";
const ROUTE_FORM_COMEBACK = "route.form.comeback";
const ROUTE_FORM_ADD_TITLE = "route.form.add_title";
const ROUTE_FORM_EDIT_TITLE = "route.form.edit_title";
const ROUTE_FORM_NAME = "route.form.name.label";
const ROUTE_FORM_NAME_REQUIRED = "route.form.name.required";
const ROUTE_FORM_ADDRESS = "route.form.address.label";
const ROUTE_FORM_ADDRESS_DEPARTURE_PLACEHOLDER = "route.form.address.departure_placeholder";
const ROUTE_FORM_ADDRESS_ARRIVAL_PLACEHOLDER = "route.form.address.arrival_placeholder";
const ROUTE_FORM_ADDRESS_DEPARTURE_REQUIRED = "route.form.address.departure_required";
const ROUTE_FORM_ADDRESS_ARRIVAL_REQUIRED = "route.form.address.arrival_required";
const ROUTE_FORM_RATIO = "route.form.ratio.label";
const ROUTE_FORM_RATIO_REQUIRED = "route.form.ratio.required";
const ROUTE_FORM_LATLNG = "route.form.latlng.label";
const ROUTE_FORM_WAY_POINTS = "route.form.way_points.label";
const ROUTE_FORM_FENCE = "route.form.fence.label";
const ROUTE_FORM_DISTANCE = "route.form.distance.label";
const ROUTE_FORM_DISTANCE_REQUIRED = "route.form.distance.required";
const ROUTE_FORM_BUTTON_CONFIRM = "route.form.buttons.confirm";
const ROUTE_FORM_BUTTON_CANCEL = "route.form.buttons.cancel";
const ROUTE_FORM_MSG_EDIT_SUCCESS = "route.form.msgs.edit_success";
const ROUTE_FORM_MSG_SAVE_SUCCESS = "route.form.msgs.save_success";

export default {
    NO_DATA,
    ALERT_LABEL_CHECKBOX,
    ALERT_LABEL_BTN_IMPORT,
    NO_SEARCH_YET,
    REGISTER_DRIVER_LIST,
    SOON,
    LESS_MINIMAL,
    MORE_MAX,
    GLOBAL_NAME,
    COMEBACK,
    FILTERS,
    TIME_ZONE,
    TIME_ZONE_PATTERN,
    TIME_ZONE_UTC,
    AUTOCOMPLETE_LOADING,
    AUTOCOMPLETE_NOT_FOUND,
    AUTOCOMPLETE_CLOSE,
    REGISTER_POINTS_INTEREST_BUTTON_ADD,
    REGISTER_POINTS_INTEREST_BUTTON_IMPORT,
    PAGINATION,
    ALL,
    WEEKDAYS,
    MENU_ALL,
    MENU_ACTIVE,
    MENU_INACTIVE,
    CURRENCY,
    VECHICLE_ICON,
    TRUCK_ICON,
    MOTORCYCLE_ICON,
    EXTRACT_REPORT,
    LOGIN_TITLE,
    TITLE_TUTORIAL,
    TITLE_DASHBOARD_VIEW,
    TITLE_DASHBOARD_VEHICLE_VIEW,
    TITLE_LOGIN,
    TITLE_ALERTS_VIEW,
    TITLE_MAP_VIEW,
    TITLE_ALERT_VIEW,
    TITLE_ALERT_BOND_VIEW,
    TITLE_FLEETS_VIEW,
    TITLE_FLEETS_LINK_CARS_VIEW,
    TITLE_GROUP_DRIVER_FINDER_VIEW,
    TITLE_REGISTER_GROUP_OF_DRIVERS_VIEW,
    TITLE_GROUP_POINTS_FINDER_VIEW,
    TITLE_REGISTER_GROUP_OF_POINTS_VIEW,
    TITLE_REGISTER,
    TITLE_REGISTERED_DRIVERS,
    TITLE_REGISTER_DRIVER,
    TITLE_IMPORT_DRIVER_VIEW,
    TITLE_POINTS_IMPORT_VIEW,
    TITLE_FINDER_POINTS_INTEREST,
    TITLE_COMMUNICATED,
    TITLE_REGISTER_POINTS_INTEREST,
    TITLE_FINDER_ROUTES_VIEW,
    TITLE_REGISTER_ROUTES_VIEW,
    TITLE_VEHICLE_VIEW,
    TITLE_REGISTER_USER_VIEW,
    TITLE_USER_LINK_CARS_VIEW,
    TITLE_REPORT_ALERTS_VIEW,
    TITLE_REPORT_DISTANCE,
    TITLE_REPORT_EVENTS,
    TITLE_REPORT_TRAVELS,
    TITLE_REPORT_SPEEDING_VIEW,
    TITLE_PROFILE_VIEW,
    TITLE_UNAUTHORIZED,
    TITLE_EXAMPLE_VIEW,
    TITLE_RENEWAL,
    TITLE_NOT_FOUND,
    MENU_LABEL_HOME,
    MENU_LABEL_RENEWAL,
    MENU_LABEL_DASHBOARD,
    MENU_LABEL_ALERTS,
    MENU_LABEL_MAPS,
    MENU_LABEL_COMMUNICATED,
    MENU_LABEL_REPORTS,
    MENU_LABEL_REPORT_ALERT,
    MENU_LABEL_REPORT_DISTANCE,
    MENU_LABEL_REPORT_EVENTS,
    MENU_LABEL_REPORT_TRAVELS,
    MENU_LABEL_REPORT_SPEEDING,
    MENU_LABEL_REGISTER,
    MENU_LABEL_REGISTER_ALERT,
    MENU_LABEL_REGISTER_ALERT_CARS_LINK,
    MENU_LABEL_REGISTER_FLEET,
    MENU_LABEL_REGISTER_FLEET_CARS_LINK,
    MENU_LABEL_REGISTER_GROUP_DRIVER,
    MENU_LABEL_REGISTER_GROUP_POINT_INTEREST,
    MENU_LABEL_REGISTER_POINT_INTEREST,
    MENU_LABEL_REGISTER_POINT_INTEREST_IMPORT,
    MENU_LABEL_REGISTER_POINT_INTEREST_ADD,
    MENU_LABEL_REGISTER_POINT_INTEREST_EDIT,
    MENU_LABEL_REGISTER_DRIVER,
    MENU_LABEL_REGISTER_DRIVER_ADD,
    MENU_LABEL_REGISTER_DRIVER_EDIT,
    MENU_LABEL_REGISTER_DRIVER_IMPORT,
    MENU_LABEL_REGISTER_ROUTE,
    MENU_LABEL_REGISTER_ROUTE_ADD,
    MENU_LABEL_REGISTER_ROUTE_EDIT,
    MENU_LABEL_REGISTER_VEHICLE,
    MENU_LABEL_REGISTER_USER,
    MENU_LABEL_REGISTER_USER_CARS_LINK,
    REGISTER_DRIVER_BUTTON_IMPORT_DRIVER,
    REGISTER_DRIVER_BUTTON_ADD_DRIVER,
    LOGIN_USER,
    LOGIN_USER_REQUIRED,
    LOGIN_PASSWORD,
    LOGIN_PASSWORD_REQUIRED,
    LOGIN_REMEMBER,
    LOGIN_FORGORT_PASSWORD,
    LOGIN_SUBMIT,
    LOGIN_ACCESS_DENIED,
    ACCESS_TITLE,
    ACCESS_DOCUMENT,
    ACCESS_DOCUMENT_REQUIRED,
    ACCESS_VALIDATE_ACCESS,
    ACCESS_USER,
    ACCESS_USER_REQUIRED,
    ACCESS_USER_INVALID,
    ACCESS_PASSWORD,
    ACCESS_PASSWORD_REQUIRED,
    ACCESS_PASSWORD_STRONG,
    ACCESS_CONFIRM_PASSWORD,
    ACCESS_CONFIRM_PASSWORD_REQUIRED,
    ACCESS_CONFIRM_PASSWORD_CONFIRM,
    ACCESS_MAIL,
    ACCESS_MAIL_REQUIRED,
    ACCESS_MAIL_INVALID,
    ACCESS_CREATE_ACCESS,
    ACCESS_COMEBACK,
    ACCESS_MSG_ERROR,
    ACCESS_MSG_SUCCESS,
    ACCESS_SUGESTIONS_DESCRIPTION,
    ACCESS_SUGESTIONS_1,
    ACCESS_SUGESTIONS_2,
    ACCESS_SUGESTIONS_3,
    ACCESS_SUGESTIONS_4,
    ACCESS_SUGESTIONS_5,
    HEADER_TITLE,
    MENU_SYSTEM,
    MENU_FAILED,
    SUCCESSFUL_OPERATION,
    SUCCESSFULLY_LOGGED,
    UNAVAILABLE_SERVICE,
    NEED_SUPPORT,
    NOT_FOUND,
    BAD_REQUEST,
    UNAUTHORIZED,
    FORBIDDEN,
    INTERNAL_SERVER_ERROR,
    ACCESS_DENIED,
    NETWORK_ERROR,
    RESOURCE_NOT_FOUND,
    METHOD_NOT_ALLOWED,
    SESSION_EXPIRED,
    MAP_FILTER_GROUP,
    MAP_FILTER_POINT_INTEREST,
    MAP_FILTER_ROUTES,
    MAP_FILTER_PLACEHOLDER,
    MAP_TAB_SEARCH,
    MAP_TAB_RANGE_BUTTON,
    MAP_TAB_VEHICLES,
    MAP_TAB_FLEETS,
    MAP_TAB_FLEETS_TABLE_CODE,
    MAP_TAB_FLEETS_TABLE_NAME,
    MAP_TAB_ADDRESSS,
    MAP_TAB_ADDRESSS_PLACES,
    MAP_INFO_VIEW_DATE,
    MAP_INFO_VIEW_EVENT,
    MAP_INFO_VIEW_IGNITION,
    MAP_INFO_VIEW_VELOCITY,
    MAP_INFO_VIEW_DRIVER,
    MAP_INFO_VIEW_LOCALIZATION,
    MAP_MARKER_INFO_VIEW_POINT_INTEREST,
    LOG_SEARCH_FILTER,
    TAB_ANALYZE_VEHICLE,
    TAB_ANALYZE_DRIVER,
    TAB_VEHICLE,
    TAB_DRIVER,
    COLUMN_PLATE,
    TUTORIAL_TIPS,
    TUTORIAL_SHOWLESS,
    TUTORIAL_SHOWMORE,
    CARD_TUTORIAL_BUTTON,
    COLUMN_IDENTIFICATION,
    COLUMN_BRAND,
    COLUMN_MODEL,
    COLUMN_DATE_REGISTER,
    COLUMN_Y_FABRICATION,
    COLUMN_CHASSI,
    COLUMN_Y_MODEL,
    CHART_COMMON_KMRUN,
    LOG_SEARCH_FILTER_NAME,
    LABEL_CARD_FLEET,
    STATUS_LABEL,
    SAVE_LABEL,
    FLEET_LABEL,
    FLEET_LABEL_EDIT,
    FLEET_LABEL_IMPORT,
    FLEET_LABEL_IMPORT_CHECKBOX,
    LOG_SEARCH_FILTER_NAME_3_DIGITS,
    LOG_SEARCH_FILTER_BUTTON,
    LOG_SEARCH_FILTER_START_DATE,
    LOG_SEARCH_FILTER_END_DATE,
    LOG_SEARCH_TABLE_NAME,
    LOG_SEARCH_TABLE_DATE,
    REPORT_TAB_OBJECT,
    REPORT_TAB_PERIOD,
    REPORT_TAB_SCHEDULED,
    REPORT_CLEAN_BUTTON,
    REPORT_LABEL_ALERT,
    REPORT_LABEL_STATUS,
    REPORT_LABEL_FLEET_ALERT_VIOLATED,
    REPORT_LABEL_TRACE,
    REPORT_LABEL_PERIOD,
    REPORT_LABEL_FLEET,
    ALERT_VIOLATED_DETAILS_ALERT_TITLE,
    ALERT_VIOLATED_DETAILS_ALERT_NAME,
    ALERT_VIOLATED_DETAILS_ALERT_CRITERY,
    ALERT_VIOLATED_DETAILS_ALERT_ACTIVE_DAYS,
    ALERT_VIOLATED_DETAILS_ALERT_HOUR,
    ALERT_VIOLATED_DETAILS_ALERT_TREATMENT,
    ALERT_VIOLATED_DETAILS_ALERT_USER_TREATMENT,
    ALERT_VIOLATED_DETAILS_CONDITIONS,
    ALERT_VIOLATED_DETAILS_ACTIONS,
    ALERT_VIOLATED_DETAILS_EVENT_TITLE,
    ALERT_VIOLATED_DETAILS_EVENT_TYPE,
    ALERT_VIOLATED_DETAILS_EVENT_DATE,
    ALERT_VIOLATED_DETAILS_EVENT_PLATE,
    ALERT_VIOLATED_DETAILS_EVENT_LOCALIZATION,
    ALERT_VIOLATED_DETAILS_EVENT_ADDRESS,
    ALERT_VIOLATED_DETAILS_EVENT_DRIVER,
    ALERT_VIOLATED_DETAILS_EVENT_MARK,
    ALERT_VIOLATED_DETAILS_EVENT_MODEL,
    ALERT_VIOLATED_DETAILS_EVENT_VELOCITY,
    ALERT_VIOLATED_DETAILS_IGNITION,
    ALERT_VIOLATED_DETAILS_GPS,
    ALERT_VIOLATED_DETAILS_HISTORIC,
    ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED,
    ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED_CADASTRED_TITLE,
    ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED_CADASTRED_USER,
    ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED_CADASTRED_DATE,
    ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED_CADASTRED_TREATMENT,
    ALERT_VIOLATED_MSG_SUCESSS,
    ALERT_VIOLATED_DETAILS_CONCLUDE,
    REPORT_LABEL_PLACEHOLDER,
    REPORT_EVENT_VEHICLE_LABEL,
    REPORT_EVENT_VEHICLE_PLACEHOLDER,
    REPORT_EVENT_FLEET_LABEL,
    REPORT_EVENT_FLEET_PLACEHOLDER,
    REPORT_EVENT_SPEED_LABEL,
    REPORT_EVENT_SPEED_METRIC,
    REPORT_EVENT_PERIOD_LABEL,
    REPORT_EVENT_EMAIL_LABEL,
    REPORT_EVENT_EMAIL_PLACEHOLDER,
    REPORT_EVENT_SAVE_BUTTON,
    REPORT_SHEDULED_FREQUENCY,
    REPORT_SHEDULED_FREQUENCY_REQUIRED,
    REPORT_SHEDULED_FILE_TYPE,
    REPORT_SHEDULED_FILE_TYPE_REQUIRED,
    REPORT_SHEDULED_DATE,
    REPORT_SHEDULED_VEHICLE,
    REPORT_SHEDULED_VEHICLE_REQUIRED,
    REPORT_SHEDULED_EMAIL,
    REPORT_SHEDULED_EMAIL_REQUIRED,
    REPORT_SHEDULED_EMAIL_INVALID,
    REPORT_EVENT_VEHICLE_MSG_OBLIGATED,
    REPORT_EVENT_FLEET_MSG_OBLIGATED,
    REPORT_EVENT_PERIOD_MSG_OBLIGATED,
    REPORT_EVENT_PERIOD_MSG_NOT_VALID,
    REPORT_EVENT_EMAIL_MSG_OBLIGATED,
    REPORT_EVENT_EMAIL_MSG_NOT_VALID,
    REGISTER_DRIVER_COMEBACK,
    REGISTER_DRIVER_FORM_ADD_TITLE,
    REGISTER_DRIVER_FORM_EDIT_TITLE,
    REGISTER_DRIVER_FORM_NAME,
    REGISTER_DRIVER_FORM_BIRTHDAY,
    REGISTER_DRIVER_FORM_PHONE,
    REGISTER_DRIVER_FORM_CPF,
    REGISTER_DRIVER_FORM_CNPJ,
    REGISTER_DRIVER_FORM_RG,
    REGISTER_DRIVER_FORM_STATE_RG,
    REGISTER_DRIVER_FORM_CNH_NUMBER,
    REGISTER_DRIVER_FORM_CATEGORY,
    REGISTER_DRIVER_FORM_CNH_RENOVATION_DAY,
    REGISTER_DRIVER_FORM_CNH_CITY,
    REGISTER_DRIVER_FORM_CARD,
    REGISTER_DRIVER_FORM_STATUS,
    REGISTER_DRIVER_FORM_STATUS_LABEL,
    REGISTER_DRIVER_FORM_COMENTARY,
    REGISTER_DRIVER_FORM_BUTTON_CANCEL,
    REGISTER_DRIVER_FORM_BUTTON_CONFIRM,
    REGISTER_DRIVER_FORM_NAME_MSG_REQUIRED,
    REGISTER_DRIVER_FORM_CPF_MSG_REQUIRED,
    REGISTER_DRIVER_FORM_CPF_MSG_INVALID,
    REGISTER_DRIVER_FORM_CNPJ_MSG_INVALID,
    REGISTER_DRIVER_FORM_MSG_SUCCESS,
    REGISTER_DRIVER_FORM_MSG_CPF_ALREADY_EXISTS,
    REGISTER_DRIVER_FORM_IMAGE_MSG_INVALID_TYPE,
    ALERT_LIST_AVAILABLE,
    ALERT_LIST_ALERT,
    LABEL_HISTORY_ALERT,
    LABEL_HISTORY_ID,
    LABEL_HISTORY_ID_DAYOFWEEK,
    LABEL_HISTORY_ID_HOUR,
    LABEL_HISTORY_ID_VALIDATE,
    LABEL_HISTORY_ID_CONDITIONS,
    LABEL_HISTORY_ID_ACTIONS,
    LABEL_HISTORY_ID_BACKLIST,
    LABEL_IMPORT_DRIVER,
    FILE_LABEL_IMPORT_DRIVER,
    PLACEHOLDER_IMPORT_DRIVER,
    LINK_LABEL_IMPORT_DRIVER,
    GROUP_DRIVER_LABEL_IMPORT_DRIVER,
    SEARCH_FILE_IMPORT_DRIVER,
    INFO_LABEL_GROUP_IMPORT_DRIVER,
    BTN_CANCEL_IMPORT_DRIVER,
    BTN_CONFIRM_IMPORT_DRIVER,
    CHOICE_LABEL,
    GROUP_DRIVER_COMEBACK,
    GROUP_DRIVERS_REGISTER_NAME,
    GROUP_DRIVERS_REGISTER_NAME_PLACEHOLDER,
    GROUP_DRIVERS_REGISTER_CPF,
    GROUP_DRIVERS_REGISTER_CNH,
    GROUP_DRIVERS_REGISTER_CATEGORIA,
    GROUP_DRIVERS_REGISTER_IDENTIFICADOR,
    GROUP_DRIVERS_REGISTER_REQUIRED,
    GROUP_DRIVERS_REGISTER_ACTIVE,
    GROUP_DRIVERS_REGISTER_BTNSAVE,
    GROUP_DRIVERS_LIST_BTNADD,
    GROUP_DRIVERS_LIST_LABEL,
    GROUP_DRIVERS_LINKED_LABEL,
    GROUP_DRIVERS_LIST_BTN_EDIT,
    GROUP_DRIVERS_AVAILABLES_DRIVERS_LABEL,
    GROUP_POINTS_COMEBACK,
    GROUP_POINTS_REGISTER_NAME,
    GROUP_POINTS_REGISTER_NAME_PLACEHOLDER,
    GROUP_POINTS_REGISTER_REQUIRED,
    GROUP_POINTS_REGISTER_ACTIVE,
    GROUP_POINTS_REGISTER_BTNSAVE,
    GROUP_POINTS_LIST_BTNADD,
    GROUP_POINTS_LIST_LABEL,
    GROUP_POINTS_LINKED_LABEL,
    GROUP_POINTS_LIST_BTN_EDIT,
    GROUP_POINTS_COLUMN_POINTS,
    GROUP_POINTS_COLUMN_ACTIVE,
    GROUP_POINTS_COLUMN_GROUP_POINTS,
    GROUP_POINTS_COLUMN_CODE,
    GROUP_POINTS_COLUMN_ACTIONS,
    ERROR_IMPORT_FILE,
    ERROR_IMPORT_TYPE_FILE,
    ERROR_IMPORT_CHOICE,
    ERROR_IMPORT_SUCCESS,
    REGISTER_POINT_INTEREST_COMEBACK,
    REGISTER_POINT_INTEREST_FORM_ADD_TITLE,
    REGISTER_POINT_INTEREST_FORM_EDIT_TITLE,
    REGISTER_POINT_INTEREST_FORM_FORM_TYPE,
    REGISTER_POINT_INTEREST_FORM_FORM_TYPE_POINT,
    REGISTER_POINT_INTEREST_FORM_FORM_TYPE_FENCE,
    REGISTER_POINT_INTEREST_FORM_NAME,
    REGISTER_POINT_INTEREST_FORM_NAME_MSG_REQUIRED,
    REGISTER_POINT_INTEREST_FORM_TYPE,
    REGISTER_POINT_INTEREST_FORM_TYPE_MSG_REQUIRED,
    REGISTER_POINT_INTEREST_FORM_ADDRESS,
    REGISTER_POINT_INTEREST_FORM_ADDRESS_MSG_REQUIRED,
    REGISTER_POINT_INTEREST_FORM_LATITUDE,
    REGISTER_POINT_INTEREST_FORM_LATITUDE_MSG_REQUIRED,
    REGISTER_POINT_INTEREST_FORM_LONGITUDE,
    REGISTER_POINT_INTEREST_FORM_LONGITUDE_MSG_REQUIRED,
    REGISTER_POINT_INTEREST_FORM_RADIUS,
    REGISTER_POINT_INTEREST_FORM_RADIUS_MSG_REQUIRED,
    REGISTER_POINT_INTEREST_FORM_COORDINATES,
    REGISTER_POINT_INTEREST_FORM_COORDINATES_MSG_REQUIRED,
    REGISTER_POINT_INTEREST_FORM_BUTTON_CANCEL,
    REGISTER_POINT_INTEREST_FORM_BUTTON_CONFIRM,
    REGISTER_POINT_INTEREST_FORM_MSG_SUCCESS,
    POINTS_LABEL_IMPORT,
    TITLE_COLUMN_IMPORT,
    TITLE_COLUMN_POINT_IMPORT,
    TITLE_COLUMN_FENCE_IMPORT,
    LABEL_NAME_COLUMN_IMPORT,
    LABEL_TYPE_IMPORT,
    LABEL_TYPE_ELEMENT_IMPORT,
    LABEL_LAT_IMPORT,
    LABEL_LONG_IMPORT,
    LABEL_COORDINATES_IMPORT,
    IMPORT_INFO_TITLE,
    TITLE_ALERT_GRID,
    DASHBOARD_BASE_COMPARATIVE_KM_RUN,
    DASHBOARD_BASE_COMPARATIVE_ITEMS,
    DASHBOARD_FLEET_EVOLUTION_PERCENT_TITLE,
    DASHBOARD_FLEET_EVOLUTION_PERCENT_VEHICLE_TITLE,
    DASHBOARD_FLEET_EVOLUTION_PERCENT_CHARTS,
    DASHBOARD_FLEET_EVOLUTION_PERCENT_TAB_METRIC,
    DASHBOARD_FLEET_EVOLUTION_PERCENT_TAB_PERCENT,
    DASHBOARD_KM_RUN_TITLE,
    DASHBOARD_KM_RUN_PERIOD_TITLE,
    DASHBOARD_KM_RUN_PERIOD_DAY_TITLE,
    DASHBOARD_KM_RUN_PERIOD_WEEK_TITLE,
    DASHBOARD_PERIOD_TRIPS_TITLE,
    DASHBOARD_ROAD_URBAN_OFFSET_TITLE,
    DASHBOARD_TOP_TEN_DRIVER_VEHICLE_TITLE,
    DASHBOARD_FLEET_EVOLUTION_TITLE,
    DASHBOARD_FLEET_EVOLUTION_TAB_METRIC,
    DASHBOARD_FLEET_EVOLUTION_TAB_PERCENT,
    DASHBOARD_FLEET_EVOLUTION_TAB_MONTHLY,
    DASHBOARD_FLEET_EVOLUTION_CHARTS,
    DASHBOARD_TOP_TEN_MAX_VELOCITY_VEHICLE_CARD_TITLE,
    DASHBOARD_TOP_TEN_MAX_VELOCITY_VEHICLE_PLATE,
    DASHBOARD_TOP_TEN_MAX_VELOCITY_VEHICLE_MAX_VELOCITY,
    DASHBOARD_TOP_TEN_MAX_VELOCITY_DRIVER_CARD_TITLE,
    DASHBOARD_TOP_TEN_MAX_VELOCITY_DRIVER_DRIVER,
    DASHBOARD_TOP_TEN_MAX_VELOCITY_DRIVER_MAX_VELOCITY,
    DASHBOARD_DRIVABILITY_SCORE_TITLE,
    DASHBOARD_RISK_EXPOSURE_SCORE_TITLE,
    DASHBOARD_COMPOSITION_TITLE,
    DASHBOARD_ACCIDENT_PROBABILITY_TITLE,
    DASHBOARD_ANALYSIS_ITEMS_TITLE,
    DASHBOARD_ANALYSIS_ITEMS_QTD_ACELERACAO_BRUSCA,
    DASHBOARD_ANALYSIS_ITEMS_QTD_EXCESSO_VELOCIDADE,
    DASHBOARD_ANALYSIS_ITEMS_QTD_CURVA_AGRESSIVA,
    DASHBOARD_ANALYSIS_ITEMS_QTD_VIAGEM_NAO_COMERCIAL,
    DASHBOARD_ANALYSIS_ITEMS_QTD_FREADA_BRUSCA,
    DASHBOARD_ANALYSIS_ITEMS_QTD_VIAGEM_FIM_SEMANA,
    DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TITLE,
    DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TABLE_PLATE,
    DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TABLE_DRIVER,
    DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TABLE_VALUE,
    DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_SPEEDING,
    DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_BRUSQUE_ACCELERATION,
    DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_BRUSQUE_CURVE,
    DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_BRUSQUE_BRAKED,
    DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_DISPLACEMENT_NOT_COMMERCIAL,
    DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_DISPLACEMENT_WEEKEND,
    KM_RUN_LABEL,
    KM_RUN_COMMERCIAL_LABEL,
    KM_RUN_NOT_COMMERCIAL_LABEL,
    KM_RUN_WEEKEND_LABEL,
    DASHBOARD_VEHICLE_HEATMAP,
    NO_RESULTS,
    DASHBOARD_VEHICLE_DATA_VEHICLE_TITLE,
    DASHBOARD_VEHICLE_DATA_VEHICLE_PLATE,
    DASHBOARD_VEHICLE_DATA_VEHICLE_PRODUCER,
    DASHBOARD_VEHICLE_DATA_VEHICLE_MODEL,
    DASHBOARD_VEHICLE_DATA_VEHICLE_CHASSI,
    DASHBOARD_VEHICLE_PRIVATE_MODE_TITLE,
    DASHBOARD_VEHICLE_PRIVATE_MODE_ACTIVE,
    DASHBOARD_VEHICLE_PRIVATE_MODE_INACTIVE,
    DASHBOARD_VEHICLE_PRIVATE_MODE_ACTIVE_BUTTON,
    DASHBOARD_VEHICLE_PRIVATE_MODE_NOT_INFO,
    DASHBOARD_VEHICLE_PRIVATE_MODE_INACTIVE_BUTTON,
    DASHBOARD_VEHICLE_PRIVATE_MODE_MSGS_ERROR_REFRESH,
    DASHBOARD_VEHICLE_PRIVATE_MODE_MSGS_ERROR_SAVE,
    DASHBOARD_VEHICLE_PRIVATE_MODE_MSGS_SUCESS_SAVE,
    PROFILE_COMEBACK,
    PROFILE_PERSONAL_INFORMATION_TITLE,
    PROFILE_PERSONAL_INFORMATION_IMAGE_INVALID,
    PROFILE_PERSONAL_INFORMATION_LOGIN,
    PROFILE_PERSONAL_INFORMATION_NAME,
    PROFILE_PERSONAL_INFORMATION_NAME_REQUIRED,
    PROFILE_PERSONAL_INFORMATION_EMAIL,
    PROFILE_PERSONAL_INFORMATION_EMAIL_REQUIRED,
    PROFILE_PERSONAL_INFORMATION_LANGUAGE,
    PROFILE_PERSONAL_INFORMATION_LANGUAGE_REQUIRED,
    PROFILE_PERSONAL_INFORMATION_COUNTRY,
    PROFILE_PERSONAL_INFORMATION_COUNTRY_REQUIRED,
    PROFILE_PERSONAL_INFORMATION_TIMEZONE,
    PROFILE_PERSONAL_INFORMATION_TIMEZONE_REQUIRED,
    PROFILE_PERSONAL_INFORMATION_NEW_PASSWORD,
    PROFILE_PERSONAL_INFORMATION_NEW_PASSWORD_RULES,
    PROFILE_PERSONAL_INFORMATION_REPEAT_PASSWORD,
    PROFILE_CUSTOMIZATION_TITLE,
    PROFILE_CUSTOMIZATION_LOGO,
    PROFILE_CUSTOMIZATION_LOGO_REMOVE,
    PROFILE_CUSTOMIZATION_LOGO_SHOW_ON_REPORT,
    PROFILE_CUSTOMIZATION_LOGO_RULES,
    PROFILE_CUSTOMIZATION_LOGO_INVALID_TYPE,
    PROFILE_CUSTOMIZATION_LOGO_INVALID_SIZE,
    PROFILE_CUSTOMIZATION_COLOR_CONFIGURATION,
    PROFILE_CUSTOMIZATION_COLOR_CONFIGURATION_PRIMARY,
    PROFILE_CUSTOMIZATION_COLOR_CONFIGURATION_PRIMARY_REQUIRED,
    PROFILE_CUSTOMIZATION_COLOR_CONFIGURATION_SECONDARY,
    PROFILE_CUSTOMIZATION_COLOR_CONFIGURATION_SECONDARY_REQUIRED,
    PROFILE_BUTTON_CANCEL,
    PROFILE_BUTTON_CONFIRM,
    PROFILE_FORM_MSG_SUCCESS,
    PROFILE_PERSONAL_INFORMATION_EMAIL_INVALID,
    PROFILE_PERSONAL_INFORMATION_NEW_PASSWORD_INVALID,
    PROFILE_PERSONAL_INFORMATION_REPEAT_PASSWORD_REQUIRED,
    PROFILE_PERSONAL_INFORMATION_REPEAT_PASSWORD_NOT_EQUAL,
    ALERT_FORM_TITLE,
    ALERT_FORM_NAME,
    ALERT_FORM_NAME_REQUIRED,
    ALERT_FORM_DAY_WEEK,
    ALERT_FORM_HOUR_WEEK,
    ALERT_FORM_HOUR_WEEK_REQUIRED_START,
    ALERT_FORM_HOUR_WEEK_REQUIRED_END,
    ALERT_FORM_EXPIRATION,
    ALERT_FORM_CONDITONS,
    ALERT_FORM_CONDITONS_ADD,
    ALERT_FORM_CONDITONS_OPTION_PLATFORM_ACTIVE,
    ALERT_FORM_CONDITONS_OPTION_PLATFORM_INACTIVE,
    ALERT_FORM_CONDITONS_OPTION_ENGAGE,
    ALERT_FORM_CONDITONS_OPTION_DISENGAGED,
    ALERT_FORM_CONDITONS_OPTION_IN,
    ALERT_FORM_CONDITONS_OPTION_OUT,
    ALERT_FORM_CONDITONS_OPTION_ACTIVE,
    ALERT_FORM_CONDITONS_OPTION_INACTIVE,
    ALERT_FORM_CONDITONS_OPTION_MORE_THAN,
    ALERT_FORM_CONDITONS_OPTION_LESS_THAN,
    ALERT_FORM_CONDITONS_OPTION_PF,
    ALERT_FORM_CONDITONS_OPTION_PJ,
    ALERT_FORM_CONDITONS_OPTION_EQUAL,
    ALERT_FORM_CONDITONS_OPTION_ONLY_THIS_ALERT,
    ALERT_FORM_CONDITONS_OPTION_ALL_VEHICLES,
    ALERT_FORM_CONDITONS_OPTION_ALL_ALERTS,
    ALERT_FORM_CONDITONS_OPTION_URBAN,
    ALERT_FORM_CONDITONS_OPTION_ROAD,
    ALERT_FORM_CONDITONS_LABEL_KMH,
    ALERT_FORM_CONDITONS_LABEL_MINUTES,
    ALERT_FORM_CONDITONS_LABEL_METERS,
    ALERT_FORM_CONDITONS_LABEL_DAYS,
    ALERT_FORM_CONDITONS_PLACEHOLDER_SEARCH_ROUTE,
    ALERT_FORM_CONDITONS_PLACEHOLDER_SEARCH_INTEREST_POINT,
    ALERT_FORM_CONDITONS_PLACEHOLDER_SERACH_GROUP_INTEREST_POINT,
    ALERT_FORM_CONDITONS_ERROR_REQUIRED_LIMIT_DATE,
    ALERT_FORM_CONDITONS_ERROR_REQUIRED_VALUE,
    ALERT_FORM_CONDITONS_ERROR_REQUIRED_TEXT,
    ALERT_FORM_CONDITONS_ERROR_REQUIRED_ROUTE,
    ALERT_FORM_CONDITONS_ERROR_REQUIRED_INTEREST_POINT,
    ALERT_FORM_CONDITONS_ERROR_REQUIRED_GROUP_INTEREST_POINT,
    ALERT_FORM_CONDITONS_ERROR_VALID_MIN_MAX,
    ALERT_FORM_ACTIONS,
    ALERT_FORM_ACTIONS_ADD,
    ALERT_FORM_BUTTON_CONFIRM,
    ALERT_FORM_BUTTON_CANCEL,
    ALERT_FORM_CONDITONS_PLACEHOLDER,
    ALERT_FORM_ACTIONS_PLACEHOLDER,
    ALERT_CARD_INSERT_TITLE,
    ALERT_CARD_INSERT_TEXT,
    ALERT_CARD_WEEKEND_TITLE,
    ALERT_CARD_WEEKEND_TEXT,
    ALERT_CARD_OUT_HOURS_TITLE,
    ALERT_CARD_OUT_HOURS_TEXT,
    ALERT_CARD_VELOCITY_TITLE,
    ALERT_CARD_VELOCITY_TEXT,
    ALERT_CARD_ENTERING_RISK_TITLE,
    ALERT_CARD_ENTERING_RISK_TEXT,
    ALERT_CARD_LEAVING_RISK_TITLE,
    ALERT_CARD_LEAVING_RISK_TEXT,
    ALERT_CARD_NO_SIGNAL_TITLE,
    ALERT_CARD_NO_SIGNAL_TEXT,
    ALERT_EDIT_BUTTON_BOND,
    ALERT_EDIT_BUTTON_DEACTIVE,
    ALERT_FORM_MSG_SAVE_SUCCESS,
    ALERT_FORM_MSG_EDIT_SUCCESS,
    ROUTE_ADD_ROUTE,
    ROUTE_MAP,
    ROUTE_LIST_TITLE,
    ROUTE_LIST_CODE,
    ROUTE_LIST_NAME,
    ROUTE_LIST_ACTIONS,
    ROUTE_FORM_COMEBACK,
    ROUTE_FORM_ADD_TITLE,
    ROUTE_FORM_EDIT_TITLE,
    ROUTE_FORM_NAME,
    ROUTE_FORM_NAME_REQUIRED,
    ROUTE_FORM_ADDRESS,
    ROUTE_FORM_ADDRESS_DEPARTURE_PLACEHOLDER,
    ROUTE_FORM_ADDRESS_ARRIVAL_PLACEHOLDER,
    ROUTE_FORM_ADDRESS_DEPARTURE_REQUIRED,
    ROUTE_FORM_ADDRESS_ARRIVAL_REQUIRED,
    ROUTE_FORM_RATIO,
    ROUTE_FORM_RATIO_REQUIRED,
    ROUTE_FORM_LATLNG,
    ROUTE_FORM_WAY_POINTS,
    ROUTE_FORM_FENCE,
    ROUTE_FORM_DISTANCE,
    ROUTE_FORM_DISTANCE_REQUIRED,
    ROUTE_FORM_BUTTON_CONFIRM,
    ROUTE_FORM_BUTTON_CANCEL,
    ROUTE_FORM_MSG_EDIT_SUCCESS,
    ROUTE_FORM_MSG_SAVE_SUCCESS,
    ALERT_FORM_DAY_WEEK_REQUIRED,
    ALERT_FORM_CONDITONS_REQUIRED,
    ALERT_POPUP_TITLE,
    VEHICLE_LIST_TITLE,
    VEHICLE_EDIT_FORM_TITLE,
    VEHICLE_ODOMETER_FORM_TITLE,
    VEHICLE_FORM_NICKNAME,
    VEHICLE_FORM_DEFAULT_DRIVER,
    VEHICLE_FORM_NICKNAME_REQUIRED,
    VEHICLE_FORM_DEFAULT_DRIVER_REQUIRED,
    VEHICLE_FORM_MSG_SAVE_SUCCESS,
    VEHICLE_FORM_BUTTON_CONFIRM,
    VEHICLE_FORM_BUTTON_CANCEL,
    VEHICLE_FORM_MSG_ODOMETER_SAVE_SUCCESS,
    VEHICLE_FORM_TRACKED_ODOMETER,
    VEHICLE_FORM_VEHICLE_ODOMETER,
    VEHICLE_FORM_TRACKED_ODOMETER_SMALL_TITLE,
    VEHICLE_FORM_VEHICLE_ODOMETER_SMALL_TITLE,
    VEHICLE_FORM_VEHICLE_ODOMETER_REQUIRED,
    VEHICLE_FORM_VEHICLE_ODOMETER_HISTORY,
    MAP_MARKER_INFO_VIEW_ROUTE_TITLE,
    MAP_MARKER_INFO_VIEW_ROUTE_NAME,
    REGISTER_ALERT_ERROR_TYPE_XLS,
    RECOVER_PASSWORD_EMAIL_REQUIRED,
    RECOVER_PASSWORD_EMAIL_INVALID,
    DASHBOARD_CHARTS_FORMATTER_TOTAL,
    DASHBOARD_CHARTS_FORMATTER_AVERAGE_PER_VEHICLE,
    MENU_LOGOUT,
    MENU_PROFILE,
    COLUMN_OWNER,
    COLUMN_BRAND_OR_MODEL,
    COLUMN_DRIVER,
    COLUMN_ALERT,
    COLUMN_EVENT_DATE,
    COLUMN_REGISTER_DATE,
    COLUMN_FINISHED_BY,
    COLUMN_FINISH_DATE,
    COLUMN_SPEED_ABBREVIATED,
    COLUMN_LOCATION,
    COLUMN_STAY,
    DASHBOARD_PERIOD_TRIPS_CHARTS_DAYBREAK,
    DASHBOARD_PERIOD_TRIPS_CHARTS_MORNING,
    DASHBOARD_PERIOD_TRIPS_CHARTS_AFTERNOON,
    DASHBOARD_PERIOD_TRIPS_CHARTS_NIGHT,
    REPORT_EVENT_VEHICLE_MSG_OBLIGATED_WITHOUT_OR,
    REPORT_EVENT_PERIOD_MSG_PERIOD_WEEK,
    REPORT_VALIDATION_MESSAGE_PERIOD,
    REPORT_DISTANCE_DAYS_OF_WEEKS,
    ALERT_VIOLATED_DETAILS_COLUMNS_NAME,
    ALERT_VIOLATED_DETAILS_COLUMNS_ID,
    ALERT_VIOLATED_DETAILS_COLUMNS_STATUS,
    ALERT_VIOLATED_DETAILS_COLUMNS_REGISTER_DATE,
    ALERT_VIOLATED_DETAILS_COLUMNS_REGISTER_USER,
    ALERT_VIOLATED_DETAILS_COLUMNS_EDIT_USER,
    ALERT_TAB_EDIT,
    ALERT_TAB_IMPORT_CARS,
    ALERT_TAB_HISTORIC,
    ALERT_FORM_EMAIL_REQUIRED,
    ALERT_NONE_SELECTED,
    ALERT_GET_ERROR,
    ALERT_VIOLATED_BUTTON_VIEW,
    ALERT_VIOLATED_BUTTON_CONCLUDE,
    ALERT_NONE_FOUND,
    AUTOCOMPLETE_OPEN,
    AUTOCOMPLETE_CLEAR_TEXT,
    AUTOCOMPLETE_NO_OPTIONS,
    DUAL_LIST_ADD_SELECTED,
    DUAL_LIST_REMOVE_SELECTED,
    YES,
    NO,
    REFRESH_INTERVAL,
    AUTOCOMPLETE_BUTTON_NO_OPTIONS,
    MENU_RANGE_SHOW,
    FLEET_LINK_CARS_AVAILABLE,
    FLEET_LINK_CARS_VINCULATED,
    FLEET_LINK_CARS_FLEET,
    MENU_RANGE_STATUS,
    GROUP_POINTS_OF_INTEREST_LINKED_LABEL,
    RECOVER_PASSWORD_INFO,
    RECOVER_PASSWORD_RECOVER,
    RECOVER_PASSWORD_TAB_LABEL,
    CONFIRM_EXCLUDE,
    DOWNLOAD,
    RESULTS,
    REPORT_DISTANCE_INITIAL_DATE,
    REGISTER_DRIVER_FORM_BIRTHDAY_MINIMUM_AGE,
    REPORT_DISTANCE_FINAL_DATE,
    REPORT_DISTANCE_REPORT_REGISTER_DATE,
    REPORT_DISTANCE_REPORT_AMOUNT_VEHICLES,
    REPORT_DISTANCE_PROCESSING_PERCENT,
    COLUMN_START,
    REPORT_DISTANCE_FILE_FORMAT,
    REPORT_DISTANCE_FILE_CREATION_DATE,
    REPORT_DISTANCE_TITLES_REPORT_SCHEDULED,
    REPORT_DISTANCE_TITLES_REPORT_SENT,
    REPORT_SPEEDING_DETAILED,
    REPORT_SPEEDING_ROAD_SPEED,
    COLUMN_SPEED,
    RECOVER_PASSWORD_REQUIRED,       
    RECOVER_PASSWORD_INVALID,
    RECOVER_PASSWORD_INFO2,
    RECOVER_PASSWORD_RECOVER2,
    RECOVER_PASSWORD_TAB_LABEL2
};
