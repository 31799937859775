import React from "react";

import { ListItem } from "@material-ui/core";


const OrderAlert = (props) => {
	return (
		<ListItem>
			<span style={{ fontSize: "12px", color: "#AFC3D2", marginRight: "10px" }}>
				{props.value}
			</span>
		</ListItem>
	);
};
export default OrderAlert;
