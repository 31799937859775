import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableSortLabel,
    TableHead,
    TableRow,
    Box,
    Typography,
} from "@material-ui/core";
import Pagination from "~/components/common/pagination/Pagination";
import { lighten } from "polished";
import "./Table.scss";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: lighten(0.2, "#AFC3D2"),
        paddingLeft: "21px",

        "& span input": {
            color: `${theme.palette.primary.main} !important`,
        },

        "& .MuiCheckbox-root": {
            color: `${theme.palette.primary.main} !important`,
        },
    },
    body: {
        fontSize: 14,

        "& span input": {
            color: `${theme.palette.primary.main} !important`,
        },

        "& .MuiCheckbox-root": {
            color: `${theme.palette.primary.main} !important`,
        },
    },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        "&:nth-of-type(2n+0)": {
            backgroundColor: (props) =>
                props.background ? lighten(0.3, theme.palette.primary.main) : lighten(0.2, "#AFC3D2"),
        },
        "&:hover": {
            backgroundColor: `${lighten(0.2, theme.palette.primary.main)} !important`,
            cursor: "pointer",

            "& td div strong": {
                color: "#FFF !important",
            },
            "& td span": {
                color: "#FFF !important",
            },
            "& td span p": {
                color: "#FFF !important",
            },
            "& td div": {
                color: "#FFF !important",
            },
            "& td div p": {
                color: "#FFF !important",
            },
        },
        "& td div strong": {
            color: "#3D5564",
        },
        // "& td span": {
        //   color: "#3D5564",
        // },
        "& td span p": {
            color: "#3D5564",
        },
        "& td div": {
            color: "#3D5564",
        },
        "& td div p": {
            color: "#3D5564",
        },
    },
    rootSelected: {
        backgroundColor: (props) => `${lighten(0.1, theme.palette.primary.main)} !important`,

        "& td div strong": {
            color: "#FFF !important",
        },
        "& td span": {
            color: "#FFF !important",
        },
        "& td span p": {
            color: "#FFF !important",
        },
        "& td div": {
            color: "#FFF !important",
        },
        "& td div p": {
            color: "#FFF !important",
        },
    },
    rootSelectedPrint: {
        backgroundColor: "#fed7d9",
    },
    arrowCheckSelected: {
        backgroundColor: (props) => `${theme.palette.primary.main} !important`,
    },
}));

const TableReUse = (props) => {
    const { printRow } = props;
    const styleProps = { background: props.backgroundChanged };
    const classes = useStyles(styleProps);
    const [selected, setSelected] = useState([]);
    const [selectedRow, setSelectedRow] = useState("");
    const { refColName = "", onChange = () => {} } = props.rowSelection;

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const setBackgroundRow = (row, style) => {
        if (!row.Id) return;
        if ((selectedRow === row.Id && props.onSelect) || props.rowID === row) {
            return style.rootSelected;
        }
    };

    const setPrintRow = (row, style) => {
        if (printRow && row[printRow.key01] > row[printRow.key02]) {
            return style.rootSelectedPrint;
        }
    };

    useEffect(() => {
        if (!!props.dataSource) setSelected([]);
    }, [props.dataSource]);

    useEffect(() => {
        if (props.isChecked) {
            setSelected(props.fleetsSelecteds);
        }
    }, [props.fleetsSelecteds, props.isChecked]);

    const dataSource = props.dataSource || [];
    const isSorted = props.onSort && props.orderObject;

    const ContentHeaderCell = ({ col, data }) => {
        const withSorted = (_content) => {
            const isActive = col.name === props.orderObject.orderBy;

            return (
                <TableSortLabel
                    active={isActive}
                    direction={props.orderObject.order}
                    onClick={() => {
                        const orderObject = {
                            orderBy: col.name,
                            order: props.orderObject.order === "asc" ? "desc" : "asc",
                            isActive: isActive,
                        };

                        if (col.onSort) {
                            col.onSort(orderObject, col, data, props.onSort);
                        } else if (props.onSort) {
                            props.onSort(orderObject, col, data);
                        }
                    }}>
                    {_content}
                </TableSortLabel>
            );
        };

        let content = col.labelRender ? (
            col.labelRender(data)
        ) : (
            <Typography variant="body1" style={{ color: "#AFC3D2" }}>
                {col.label}
            </Typography>
        );

        content = isSorted && !col.noSort ? withSorted(content) : content;

        return content;
    };

    return (
        <>
            <Box className={`table-result ${props.classNameRefactor} ${props.className}`}>
                <TableContainer component={Box} className="custom-table fixed-height">
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow style={{ height: 25 }}>
                                {props.checkBox && props.rowSelection && (
                                    <StyledTableCell key={"check"} align={props.align ? props.align : "center"}>
                                        <Checkbox
                                            color="primary"
                                            indeterminate={selected.length > 0 && selected.length < dataSource.length}
                                            checked={dataSource.length > 0 && selected.length === dataSource.length}
                                            onChange={(event) => {
                                                let newSelecteds = [];
                                                if (event.target.checked) {
                                                    newSelecteds = dataSource.map((n) => n[refColName]);
                                                }
                                                setSelected(newSelecteds);
                                                onChange(
                                                    newSelecteds,
                                                    dataSource,
                                                    dataSource.length > 0 && selected.length === dataSource.length,
                                                    "all"
                                                );
                                            }}
                                            inputProps={{ "aria-label": "Selecionar todos" }}
                                        />
                                    </StyledTableCell>
                                )}

                                {props.columns.map((col, i) => (
                                    <StyledTableCell
                                        className={col.columnClass ? col.columnClass : ""}
                                        key={i}
                                        align={props.align ? props.align : "center"}>
                                        <ContentHeaderCell col={col} data={props.dataMain} />
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataSource.map((row, iRow) => (
                                <TableRow
                                    className={`
										${classes.root}
										${setBackgroundRow(row, classes)}
										${setPrintRow(row, classes)}
									`}
                                    hover
                                    key={iRow}
                                    onClick={() => {
                                        props.onSelect && props.onSelect(row);
                                        setSelectedRow(row.Id);
                                    }}
                                    align="right">
                                    {props.checkBox && props.rowSelection && (
                                        <StyledTableCell key={"checkbox"} align="center">
                                            <Checkbox
                                                color="primary"
                                                checked={isSelected(row[refColName])}
                                                onClick={(event) => {
                                                    const name = row[refColName];
                                                    const itemData = row;
                                                    const selectedIndex = selected.indexOf(name);

                                                    let newSelected = [];

                                                    if (selectedIndex === -1) {
                                                        newSelected = newSelected.concat(selected, name);
                                                    } else if (selectedIndex === 0) {
                                                        newSelected = newSelected.concat(selected.slice(1));
                                                    } else if (selectedIndex === selected.length - 1) {
                                                        newSelected = newSelected.concat(selected.slice(0, -1));
                                                    } else if (selectedIndex > 0) {
                                                        newSelected = newSelected.concat(
                                                            selected.slice(0, selectedIndex),
                                                            selected.slice(selectedIndex + 1)
                                                        );
                                                    }

                                                    setSelected(newSelected);
                                                    onChange(newSelected, itemData, isSelected(name), name);
                                                }}
                                            />
                                        </StyledTableCell>
                                    )}
                                    {props.columns.map((col, iCol) => (
                                        <StyledTableCell key={iCol}>
                                            {col.render ? (
                                                <div>{col.render(row[col.name], row, iCol, props.dataMain)}</div>
                                            ) : (
                                                <Typography style={{ color: "#3D5564" }}>{row[col.name]}</Typography>
                                            )}
                                        </StyledTableCell>
                                    ))}
                                    {props.arrowCheck && selectedRow === row.Id && (
                                        <Box color="primary.main" component="span" className={`arrowCheck`}>
                                            {">"}
                                        </Box>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box className="pagination">
                {props.page >= 0 ? (
                    <Pagination
                        onChange={props.handlePage}
                        page={props.page}
                        count={Math.ceil(props.totalItems / props.itemsPerPage)}
                    />
                ) : null}
            </Box>
        </>
    );
};

export default TableReUse;
