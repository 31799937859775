import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";

import importPointService from "~/services/pointOfInterest/importPointService";

const importFile = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "importPoints") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return importPointService
		.importFilePoints(filter)
		.then((response) => {
			let data = response.data;
			let payload = {
				data: data,
			};
			dispatch({ type: Constants.IMPORT_FILE_POINTS, payload });
			callback(data, Constants.SUCCESS);
		})
		.catch(() => {
			callback();
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

export default {
	importFile,
};
