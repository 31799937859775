import React from "react";
import { Container, Grid, Button } from "@material-ui/core";
import { ComeBackIcon } from "~/components/common/icons/Icons";
import Page from "~/components/common/page/Page";
import RegisterGroupOfPoints from "~/components/groupOfPoints/register/RegisterGroupOfPoints";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import { useHistory } from "react-router-dom";

const RegisterGroupOfPointsView = () => {
    const { t } = useTranslation();
    let history = useHistory();

    const comeBack = () => {
        history.push("/register/groupOfPointsInterest");
    };

    return (
        <>
            <Page
                topButtons={
                    <Button
                        className="comeback-button comeback-top"
                        id="extractReportPoints"
                        variant="contained"
                        size="small"
                        disableElevation
                        startIcon={<ComeBackIcon />}
                        onClick={() => {
                            comeBack();
                        }}>
                        {t(Labels.GROUP_POINTS_COMEBACK)}
                    </Button>
				}
				bottomStyle={{ justifyContent: "flex-end" }}
                bottomButtons={
                    <Button
                        className="comeback-button"
                        id="extractReportPoints"
                        variant="contained"
                        size="small"
                        disableElevation
                        startIcon={<ComeBackIcon />}
                        onClick={() => {
                            comeBack();
                        }}>
                        {t(Labels.GROUP_POINTS_COMEBACK)}
                    </Button>
                }>
                <main>
                    <Container maxWidth="lg" className="container-map-view">
                        <Grid container>
                            <Grid item xs={12}>
                                <RegisterGroupOfPoints />
                            </Grid>
                        </Grid>
                    </Container>
                </main>
            </Page>
        </>
    );
};

export default RegisterGroupOfPointsView;
