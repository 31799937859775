import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
	fleetList: [],
	subFleetList: [],
	rangeDate: {},
	fleetSelected: {},
	subFleetSelected: {},
};

function dashboardFilterReducer(state = INITIAL_STATE, action = null) {
	switch (action.type) {
		case Constants.SET_FLEET_LIST:
			return {
				...state,
				fleetList: action.payload.fleetList,
			};
		case Constants.SET_SUB_FLEET_LIST:
			return {
				...state,
				subFleetList: action.payload.subFleetList,
			};
		case Constants.SET_RANGE_DATE:
			return {
				...state,
				rangeDate: action.payload.rangeDate,
			};
		case Constants.SELECT_FLEET:
			return {
				...state,
				fleetSelected: action.payload.fleetSelected,
			};
		case Constants.SELECT_SUB_FLEET:
			return {
				...state,
				subFleetSelected: action.payload.subFleetSelected,
			};
		default:
			return state;
	}
}

export default dashboardFilterReducer;
