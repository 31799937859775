import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
	rangeDate: { ValueInicio: 5, ValueFim: 6 },
	ObjetoRastreavelId: 0,
	vehicle: {}
};

function appReducer(state = INITIAL_STATE, action = null) {
	switch (action.type) {
		case Constants.SET_RANGE_DATE_AND_ID:
			return {
				...state,
				rangeDate: action.payload.rangeDate,
				ObjetoRastreavelId: action.payload.id,
			};
		case Constants.SET_VEHICLE:
			return {
				...state,
				vehicle: action.payload
			};
		case Constants.RESET_DASHBOARD_VEHICLE_RANGE_DATE:
			return {
				...state,
				rangeDate: INITIAL_STATE.rangeDate
			}
		default:
			return state;
	}
}

export default appReducer;
