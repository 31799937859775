import React from "react";

import Utils from "~/helpers/Utils";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

import { BackIcon } from "~/components/common/icons/Icons";

import "./FileInput.scss";

const useStyles = makeStyles((theme) => ({
	spanButton: {
		background: theme.palette.primary.main,
	},
}));

function InputFile({ field, form, ...props }) {
	const classes = useStyles();

	return (
		<Box className="report-field">
			{props.label && <Typography className="import-font">{props.label}</Typography>}
			<label className="label-input-file" htmlFor={props.forID}>
				<span className="back-icon">
					<BackIcon style={props.backIconStyle} />
				</span>
				<Typography id="file-input-text" className="import-font" title={props.value ? props.value : props.placeHolderInput}>
					{Utils.TextView(props.value ? props.value : props.placeHolderInput, 40)}
				</Typography>
				<span className={`${classes.spanButton} ${"btn-file"}`} style={{ width: "auto", padding: "5px 4rem" }}>
					<Typography id="file-input-pick-button" className="import-font" style={{ marginLeft: 0 }}>
						{props.labelButton}
					</Typography>
				</span>
			</label>
			{props.children}
		</Box>
	);
}

export default InputFile;
