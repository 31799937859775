import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { Box, Typography } from "@material-ui/core";
import { Field } from "formik";
import alertActions from "~/actions/alertActions";
import FormikAutocomplete from "~/components/common/autocomplete/FormikAutocomplete";
import "~/components/reporting/Reporting.scss";
import "~/components/reporting/common/ReportingCommon.scss";

const AutocompleteFleet = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.alert.alerts);

    return (
        <Box className="report-field">
            <Typography variant="subtitle1" className="report-field-label">
                {props.label}
            </Typography>
            <Field
                id={props.id}
                name={props.name}
                getOptionLabel={(option) => option.Nome || ""}
                getOptionSelected={(option, value) => option.Id === value.Id}
                component={FormikAutocomplete}
                onChange={props.onChange}
                onInputChange={(_, value) => {
                    dispatch(
                        alertActions.getListAlerts({
                            status: "Ativo",
                            page: 0,
                            limit: 10,
                            search: value,
                        })
                    );
                }}
                options={data || []}
                noOptionsText={t(Labels.NO_DATA)}
                popupIcon={<ExpandMoreRoundedIcon viewBox="0 0 25 25" />}
                loading={false}
                textFieldProps={{
                    variant: "outlined",
                    fullWidth: true,
                    margin: "dense",
                    InputProps: {
                        placeholder: props.placeholder,
                    },
                }}
            />
        </Box>
    );
};
export default AutocompleteFleet;
