import React, { useState, useImperativeHandle, forwardRef } from "react";
import { TextField, IconButton } from "@material-ui/core";
import InputMask from "react-input-mask";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import { fetchAddressByCEP } from "~/services/fetchAddressByCEP";
import "./styles.scss";

const AddressContact = forwardRef(({ contactInfo, setContactInfo }, ref) => {
    const [isEditing, setIsEditing] = useState({
        celular: false,
        email: false,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const validateField = (name, value) => {
        if (value.trim() === "" && name !== "complemento") { // "complemento" não é obrigatório
            return "Campo obrigatório";
        }
        if (name === "email") {
            return validateEmail(value) ? "" : "E-mail inválido";
        }
        return "";
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedContactInfo = { ...contactInfo, [name]: value };
        setContactInfo(updatedContactInfo);

        const error = validateField(name, value);
        setErrors({ ...errors, [name]: error });
    };

    const handleEditToggle = (field) => {
        setIsEditing((prevEditing) => ({
            ...prevEditing,
            [field]: !prevEditing[field] // Alterna o estado de edição
        }));
    };

    const handleSubmit = () => {
        let hasErrors = false;
        const newErrors = {};

        Object.keys(contactInfo).forEach((field) => {
            const error = validateField(field, contactInfo[field]);
            if (error) {
                hasErrors = true;
                newErrors[field] = error;
            }
        });

        setErrors(newErrors);

        if (hasErrors) {
            return false;
        }

        return true;
    };

    useImperativeHandle(ref, () => ({
        getContactInfo: () => contactInfo,
        submitForm: handleSubmit,
    }));

    const handleCepBlur = async () => {
        const { cep } = contactInfo;

        if (errors.cep) return;

        if (cep.replace(/[^a-zA-Z0-9 ]/g, '') && cep.replace(/[^a-zA-Z0-9 ]/g, '').length === 8 ) {
            setIsLoading(true);
            try {
                const addressData = await fetchAddressByCEP(cep);

                handleFieldChange("rua", addressData.Logradouro || "");
                handleFieldChange("bairro", addressData.Bairro || "");
                handleFieldChange("cidade", addressData.Cidade || "");
                handleFieldChange("estado", addressData.UF || "");
                handleFieldChange("numero", addressData.Numero || "");
            } catch (error) {
                console.error("Erro ao buscar o endereço:", error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleFieldChange = (name, value) => {
        setContactInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
        const error = validateField(name, value);
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    };

    const formatPhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return "";

        const cleaned = ("" + phoneNumber).replace(/\D/g, "");

        const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return phoneNumber;
    };

    return (
        <div className="address-contact-container">
            <div className="contact-section">
                <h3>Contatos</h3>

                <div className="contact-item">
                    <>
                        <InputMask mask="(99)99999-9999" value={contactInfo.celular} onChange={handleInputChange} disabled={!isEditing.celular}>
                            {(inputProps) => (
                                <TextField
                                    {...inputProps}
                                    name="celular"
                                    variant="outlined"
                                    size="small"
                                    error={!!errors.celular}
                                    helperText={errors.celular}
                                    disabled={!isEditing.celular}
                                    InputProps={{
                                        style: { fontSize: 16 },
                                        classes: { disabled: 'input-disabled' }
                                    }}
                                />
                            )}
                        </InputMask>
                        <IconButton size="small" onClick={() => handleEditToggle("celular")}>
                            {isEditing.celular ? <CheckIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                        </IconButton>
                    </>
                </div>

                <div className="contact-item">
                    <>
                        <TextField
                            name="email"
                            value={contactInfo.email}
                            onChange={handleInputChange}
                            variant="outlined"
                            size="small"
                            error={!!errors.email}
                            helperText={errors.email}
                            disabled={!isEditing.email}
                            InputProps={{
                                style: { fontSize: 16 },
                                classes: { disabled: 'input-disabled' }
                            }}
                        />
                        <IconButton size="small" onClick={() => handleEditToggle("email")}>
                            {isEditing.email ? <CheckIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                        </IconButton>
                    </>
                </div>
            </div>

            <div className="address-section">
                <h3>Endereço</h3>
                <form className="address-form">
                    <InputMask
                        mask="99999-999"
                        value={contactInfo.cep}
                        onChange={handleInputChange}
                        onBlur={handleCepBlur}
                        disabled={isLoading} 
                    >
                        {(inputProps) => (
                            <TextField
                                {...inputProps}
                                label="CEP"
                                name="cep"
                                required
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                error={!!errors.cep}
                                helperText={errors.cep}
                                InputProps={{
                                    style: { fontSize: 16 },
                                    classes: { disabled: 'input-disabled' }
                                }}
                            />
                        )}
                    </InputMask>
                    <TextField
                        label="Rua"
                        name="rua"
                        required
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        value={contactInfo.rua}
                        onChange={handleInputChange}
                        disabled={isLoading} 
                        error={!!errors.rua}
                        helperText={errors.rua}
                        InputProps={{
                            style: { fontSize: 16 },
                            classes: { disabled: 'input-disabled' }
                        }}
                    />
                    <TextField
                        label="Número"
                        name="numero"
                        required
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        value={contactInfo.numero}
                        onChange={handleInputChange}
                        disabled={isLoading} 
                        error={!!errors.numero}
                        helperText={errors.numero}
                        InputProps={{
                            style: { fontSize: 16 },
                            classes: { disabled: 'input-disabled' }
                        }}
                    />
                    <TextField
                        label="Complemento"
                        name="complemento"
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        value={contactInfo.complemento}
                        onChange={handleInputChange}
                        disabled={isLoading}
                        InputProps={{
                            style: { fontSize: 16 },
                            classes: { disabled: 'input-disabled' }
                        }}
                    />
                    <TextField
                        label="Bairro"
                        name="bairro"
                        required
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        value={contactInfo.bairro}
                        onChange={handleInputChange}
                        disabled={isLoading} 
                        error={!!errors.bairro}
                        helperText={errors.bairro}
                        InputProps={{
                            style: { fontSize: 16 },
                            classes: { disabled: 'input-disabled' }
                        }}
                    />
                    <TextField
                        label="Cidade"
                        name="cidade"
                        required
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        value={contactInfo.cidade}
                        onChange={handleInputChange}
                        disabled={isLoading} 
                        error={!!errors.cidade}
                        helperText={errors.cidade}
                        InputProps={{
                            style: { fontSize: 16 },
                            classes: { disabled: 'input-disabled' }
                        }}
                    />
                    <TextField
                        label="Estado"
                        name="estado"
                        required
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        value={contactInfo.estado}
                        onChange={handleInputChange}
                        disabled={isLoading} 
                        error={!!errors.estado}
                        helperText={errors.estado}
                        InputProps={{
                            style: { fontSize: 16 },
                            classes: { disabled: 'input-disabled' }
                        }}
                    />
                </form>
            </div>
        </div>
    );
});

export default AddressContact;
