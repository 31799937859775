import React from "react";

const ExampleToolbar = (props) => {

	return (
		<div>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
			<p>sdafa dsfad sfsad</p>
		</div>
	);
};

export default ExampleToolbar;
