import React, { useEffect, useState } from "react";
import MenuRange from "~/components/common/menuRange/MenuRange";
import { Grid } from "@material-ui/core";
import CardGroup from "~/components/common/card/CardGroup";
import driverAction from "../../../actions/driverAction";
import { useSelector, useDispatch } from "react-redux";
import "./GridDriver.scss";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

const INITIAL_FLEETS_LIMIT = 25;

const GridRegister = (props) => {
    const dispatch = useDispatch();
    const { dataDriverSearch } = useSelector((state) => state.driver);
    const { loading } = useSelector((state) => state.app);
    const [limit, setLimit] = useState(INITIAL_FLEETS_LIMIT);
    const [status, setStatus] = useState({ label: "Todos", id: -1 });
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        setLimit(INITIAL_FLEETS_LIMIT);
        setSearch("");
        setPage(1);
        setStatus({ label: "Todos", id: -1 });
        const query = {
            status: -1,
            Index: 0,
            Length: 25,
            Search: "",
        };
        dispatch(driverAction.searchDriverList({ ...query }));
    }, [dispatch]);

    const handlerSearchFleet = (value) => {
        setPage(1);
        setSearch(value);
        //Se o valor da pesquisa for maior que 0 ele pesquisa em todos, se for menor não
        const query = {
            status: status.id,
            Index: 0,
            Length: limit,
            Search: value || null,
        };

        dispatch(
            driverAction.searchDriverList({
                ...query,
            })
        );
    };

    const handlerLimitFleet = (event, value) => {
        setPage(1);
        setLimit(value);
        const query = {
            Index: 0,
            Length: value,
            Search: search,
        };

        dispatch(
            driverAction.updatePaginationRegisterDriver(
                {
                    ...query,
                },
                status.id
            )
        );
    };

    const handlerStatusFleet = (event, value) => {
        let stsValue = value === "Ativos" ? 1 : value === "Inativos" ? 0 : -1;
        const objSts = {
            label: value,
            id: stsValue,
        };
        setStatus(objSts);

        const query = {
            Index: 0,
            Length: limit,
            Search: search,
        };

        dispatch(
            driverAction.updatePaginationRegisterDriver(
                {
                    ...query,
                },
                stsValue
            )
        );
    };

    const handlerPageFleet = (event, value) => {
        setPage(value);

        const query = {
            Index: value * 1 - 1,
            Length: limit,
            Search: search,
        };

        dispatch(
            driverAction.updatePaginationRegisterDriver(
                {
                    ...query,
                },
                status.id
            )
        );
    };

    return (
        <div>
            {props.cards?.map((card, i) => (
                <Grid container className="cardFleets cardsAlertsLists flexPagination">
                    <Grid item xs={12}>
                        <CardGroup
                            data-id={"cadastrar-frotas-id"}
                            title={card.title}
                            className="group-items card-group-refactor"
                            classHeader={"search-group-item"}
                            loading={loading.requestGridRegisterDriver}
                            columns={card.columns}
                            source={dataDriverSearch.data}
                            searchFn={(value) => {
                                handlerSearchFleet(value);
                            }}
                            headerComp={
                                <div className="boxRangeAlerts">
                                    <MenuRange
                                        classNameRefactor="menu-label-range-refactor"
                                        label={t(Labels.MENU_RANGE_SHOW)}
                                        value={limit}
                                        onChange={(event) => {
                                            handlerLimitFleet(event, event.target.value);
                                        }}
                                        options={[10, 25, 50, 100, 250, 500]}
                                    />
                                    <MenuRange
                                        classNameRefactor="menu-label-range-refactor"
                                        label={t(Labels.MENU_RANGE_STATUS)}
                                        value={status.label}
                                        onChange={(event) => {
                                            handlerStatusFleet(event, event.target.value);
                                        }}
                                        options={[
                                            { label: t(Labels.MENU_ALL), value: "Todos" },
                                            { label: t(Labels.MENU_ACTIVE), value: "Ativo" },
                                            { label: t(Labels.MENU_INACTIVE), value: "Inativos" },
                                        ]}
                                    />
                                </div>
                            }
                            page={page}
                            totalItems={dataDriverSearch.page.recordsTotal}
                            itemsPerPage={limit}
                            handlePage={handlerPageFleet}
                        />
                    </Grid>
                </Grid>
            ))}
        </div>
    );
};

export default GridRegister;
