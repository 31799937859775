import Constants from "~/helpers/enums/Constants";
import dashboardService from "~/services/dashboardVehicle/dashboardService";
import Utils from "~/helpers/Utils";
import PeriodTripsFormatter from "~/components/dashboardVehicle/chartPie/PeriodTripsFormatter";
import RoadOffsetFormatter from "~/components/dashboardVehicle/chartPie/RoadOffsetFormatter";
import KmRunFormatter from "~/components/dashboardVehicle/kmRun/KmRunFormatter";
import ScoreChartFormatter from "~/components/dashboardVehicle/chartGauge/ScoreChartFormatter";
import KmRunPeriodFormatter from "~/components/dashboardVehicle/kmRunPeriod/KmRunPeriodFormatter";
import KmRunPeriodDayFormatter from "~/components/dashboardVehicle/kmRunPeriodDay/KmRunPeriodDayFormatter";
import _ from "lodash";
import evolutionFleetFormat from "~/components/dashboardVehicle/evolutionFleet/evolutionFleetFormat";

const getTravel = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return dashboardService
		.travel(filter)
		.then((response) => {
			let data = response.data;
			let payload = {
				data: data,
			};
			dispatch({ type: Constants.DASHBOARD_VEHICLE_GET_KM_RUN, payload: KmRunFormatter.customChart(response) });
			dispatch({
				type: Constants.DASHBOARD_VEHICLE_GET_PERIOD_TRIPS,
				payload: PeriodTripsFormatter.customChart(response),
			});
			dispatch({ type: Constants.DASHBOARD_BASE_COMPARATIVE_KM_RUN, payload });
			dispatch({ type: Constants.DASHBOARD_VEHICLE_EVOLUTION_FLEET, payload: evolutionFleetFormat.extractData(response)});
			dispatch({
				type: Constants.DASHBOARD_VEHICLE_GET_KM_RUN_PERIOD,
				payload: KmRunPeriodFormatter.customChart(response),
			});
			dispatch({
				type: Constants.DASHBOARD_VEHICLE_GET_KM_RUN_INDICATOR,
				payload: KmRunFormatter.extractData(response),
			});
			dispatch({
				type: Constants.DASHBOARD_VEHICLE_GET_ROAD_OFFSET,
				payload: RoadOffsetFormatter.customChart(response),
			});
		})
		.catch((error) => {
			console.log(error);
			dispatch({ type: Constants.DASHBOARD_VEHICLE_GET_KM_RUN, payload: KmRunFormatter.emptyChart() });
			dispatch({ type: Constants.DASHBOARD_VEHICLE_GET_PERIOD_TRIPS, payload: PeriodTripsFormatter.emptyChart() });
			dispatch({ type: Constants.DASHBOARD_VEHICLE_GET_ROAD_OFFSET, payload: RoadOffsetFormatter.emptyChart() });
			dispatch({ type: Constants.DASHBOARD_VEHICLE_GET_KM_RUN_PERIOD, payload: KmRunPeriodFormatter.emptyChart() });
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getKmRunDay = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	return dashboardService
		.daytravel(filter)
		.then((response)=> {
			dispatch({type: Constants.DASHBOARD_VEHICLE_GET_KM_RUN_DAY_PERIOD, payload: KmRunPeriodDayFormatter.customChart(response)})
		})
		.catch((error) => {
			dispatch({type: Constants.DASHBOARD_VEHICLE_GET_KM_RUN_DAY_PERIOD, payload: KmRunPeriodDayFormatter.emptyChart()})
			console.log(error)
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};


const getFleetEvolution = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return dashboardService
		.fleetEvolution(filter)
		.then(({ data }) => {
			let payload = _.isString(data) ? {} : data;
			dispatch({ type: Constants.GET_FLEET_EVOLUTION, payload });
		})
		.catch((error) => console.log(error))
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getMonthlyFleetEvolution = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return dashboardService
		.monthlyFleetEvolution(filter)
		.then(({ data }) => {
			let payload = _.isString(data) ? [] : data;
			dispatch({ type: Constants.GET_MONTHLY_FLEET_EVOLUTION, payload });
		})
		.catch((error) => console.log(error))
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getScores = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	return dashboardService
		.scores(filter)
		.then((response) => {
			dispatch({ type: Constants.DASHBOARD_VEHICLE_GET_SCORE, payload: response.data || {} });
			dispatch({ type: Constants.DASHBOARD_VEHICLE_COMPOSITION_RADAR, payload: ScoreChartFormatter.formatRadar(response) });
		})
		.catch((error) => console.log(error))
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getTopTenMaxVelocityVehicle = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	return dashboardService
		.topTenMaxVelocityVehicle(filter)
		.then(({ data }) => {
			dispatch({ type: Constants.GET_TOP_TEN_MAX_VELOCITY_VEHICLE, payload: data });
		})
		.catch((error) => console.log(error))
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getTopTenMaxVelocityDriver = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	return dashboardService
		.topTenMaxVelocityDriver(filter)
		.then(({ data }) => {
			dispatch({ type: Constants.GET_TOP_TEN_MAX_VELOCITY_DRIVER, payload: data });
		})
		.catch((error) => console.log(error))
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const toggleDetailsKmRun = (isVisible) => (dispatch) => {
	dispatch({ type: Constants.DASHBOARD_VEHICLE_TOGGLE_DETAILS_KM_RUN, payload: isVisible });
};

const getVehicleHeatMap = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	return dashboardService
		.vehicleHeatMap(filter)
		.then(({ data }) => {
			dispatch({ type: Constants.DASHBOARD_VEHICLE_GET_VEHICLE_HEATMAP, payload: data });
		})
		.catch((error) => dispatch({ type: Constants.DASHBOARD_VEHICLE_GET_VEHICLE_HEATMAP, payload: [] }))
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

export default {
	// getPeriodTrips,
	getFleetEvolution,
	getMonthlyFleetEvolution,
	// getKmRun,
	getKmRunDay,
	getTravel,
	getScores,
	getTopTenMaxVelocityVehicle,
	getTopTenMaxVelocityDriver,
	toggleDetailsKmRun,
	getVehicleHeatMap,
};
