import React, { useMemo } from "react";
import { Box, Typography, FormControl, FormHelperText, MenuItem } from "@material-ui/core";
import SelectPopper from "~/components/common/popperComponents/SelectPopper";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import { Field } from "formik";
import "./RegisterComponents.scss";

const RegisterDayOfWeek = (props) => {
    const { label } = props;
    const { t } = useTranslation();

    const days = useMemo(() => {
        const weekdays = t(Labels.WEEKDAYS, { returnObjects: true });

        return [
            {
                value: 0,
                label: t(Labels.ALL),
            },
            {
                value: 1,
                label: weekdays[0],
            },
            {
                value: 2,
                label: weekdays[1],
            },
            {
                value: 3,
                label: weekdays[2],
            },
            {
                value: 4,
                label: weekdays[3],
            },
            {
                value: 5,
                label: weekdays[4],
            },
            {
                value: 6,
                label: weekdays[5],
            },
            {
                value: 7,
                label: weekdays[6],
            },
        ];
    }, [t]);

    return (
        <Box className={`${props.formType === "report" ? "report-field" : "register-field"}`}>
            <Typography
                variant="subtitle1"
                className={`${props.formType === "report" ? "report-field-label" : "register-field-label"}`}>
                {label}
            </Typography>
            <FormControl fullWidth margin="dense">
                <Field name={props.name}>
                    {({ field, form: { touched, errors, setFieldValue }, meta }) => {
                        const error = errors[props.name];
                        const isTouched = touched[props.name];

                        return (
                            <>
                                <SelectPopper
                                    multiple
                                    error={isTouched && !!error}
                                    variant="outlined"
                                    margin="dense"
                                    disabled={props.disabled}
                                    value={Array.from(field.value.toString()).map((v) => parseInt(v))}
                                    onChange={(e) => {
                                        field.onBlur(e);

                                        if (e.target.value.includes(0)) {
                                            setFieldValue(props.name, "1234567");
                                            return;
                                        }

                                        setFieldValue(props.name, parseInt(e.target.value.sort().join("")));
                                    }}
                                    renderValue={(selected) => {
                                        if (selected.length >= 7) return days.find((d) => d.value === 0).label;

                                        return days
                                            .filter((d) => selected.find((s) => parseInt(s) === d.value))
                                            .map((d) => d.label)
                                            .join(", ");
                                    }}>
                                    {days.map((d, i) => (
                                        <MenuItem key={i} value={d.value}>
                                            {d.label}
                                        </MenuItem>
                                    ))}
                                </SelectPopper>
                                <FormHelperText error margin="dense" variant="outlined">
                                    {isTouched && error}
                                </FormHelperText>
                            </>
                        );
                    }}
                </Field>
            </FormControl>
        </Box>
    );
};

export default RegisterDayOfWeek;
