import http from "~/config/httpRequest/http";

const listVehicleRenewal = (usePopup = true) => {
    return new Promise((resolve, reject) => {
        http.get(`/RenovacaoContratual/BuscarVeiculosElegiveisRenovacao?popUp=${usePopup}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const getContact = () => {
    return new Promise((resolve, reject) => {
        http.get(`/RenovacaoContratual/BuscarContato`)
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const getContract = () => {
    return new Promise((resolve, reject) => {
        http.get(`/RenovacaoContratual/BuscarProdutoContrato`)
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const saveRenewal = (params) => {
    return new Promise((resolve, reject) => {
        http.post(`/RenovacaoContratual/SalvarRenovacao`, params)
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const setNotShowPopup = (show) => {
    return new Promise((resolve, reject) => {
        http.post(`/RenovacaoContratual/UpdateVisualizarPopUpRenovacaoContratual?visualizar=${show}`)
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

export default { listVehicleRenewal, saveRenewal, getContract, getContact, setNotShowPopup };
