import Constants from "~/helpers/enums/Constants";

const initialState = {
    periodForm: null,
    objectForm: null,
    scheduleForm: null,
    perObject: {
        filters: {
            fleet: null,
            speed: 0,
            period: "",
            vehicle: null,
            driver: "",
            index: 0,
            limit: 25,
            typeReport: 30,
        },
        data: {
            data: [],
        },
    },
    perPeriod: {
        vehicle: "",
        fleet: "",
        speed: 0,
        period: new Date(),
        email: "",
    },
    scheduling: {
        edition: {},
        data: [],
    },
    sent: {
        pagination: {},
        data: [],
    },
};

function travelsReportReducer(state = initialState, action = null) {
    const { payload } = action;
    switch (action.type) {
        case Constants.GET_TRAVELS_REPORT_SCHEDULING:
            return {
                ...state,
                scheduling: {
                    ...state.scheduling,
                    data: payload.data,
                    pagination: payload.pagination,
                },
            };

        case Constants.GET_TRAVELS_REPORT_SENT:
            return {
                ...state,
                sent: {
                    data: payload.data,
                    pagination: payload.pagination,
                },
            };
        case Constants.EDIT_TRAVELS_REPORT_SCHEDULING:
            return {
                ...state,
                scheduling: {
                    ...state.scheduling,
                    edition: payload,
                },
            };
        case Constants.REQUEST_TRAVELS_PER_OBJECT:
            return {
                ...state,
                perObject: {
                    ...state.perObject,
                    data: action.payload,
                },
            };
        case Constants.UPDATE_FILTER_TRAVELS_OBJECT:
            return {
                ...state,
                perObject: {
                    ...state.perObject,
                    filters: {
                        ...state.perObject.filters,
                        ...action.payload.filter,
                    },
                },
            };
        case Constants.UPDATE_FILTER_TRAVELS_PERIOD:
            return {
                ...state,
                perPeriod: action.payload.filter,
            };
        case Constants.UPDATE_FORM_TRAVELS_PERIOD:
            return {
                ...state,
                periodForm: action.payload.form,
            };
        case Constants.UPDATE_FORM_TRAVELS_OBJECT:
            return {
                ...state,
                objectForm: action.payload.form,
            };
        case Constants.UPDATE_FORM_TRAVELS_SCHEDULED:
            return {
                ...state,
                scheduleForm: action.payload.form,
            };
        case Constants.UPDATE_FILTER_TRAVELS_SCHEDULED:
            return {
                ...state,
                scheduled: action.payload.filter,
            };
        case Constants.CLEAN_FILTER_TRAVELS_OBJECT:
            return {
                ...state,
                perObject: initialState.perObject,
            };
        case Constants.CLEAN_FILTER_TRAVELS_PERIOD:
            return {
                ...state,
                perPeriod: initialState.perPeriod,
            };
        case Constants.CLEAN_FILTER_TRAVELS_SCHEDULED:
            return {
                ...state,
                scheduled: initialState.scheduled,
            };
        default:
            return state;
    }
}

export default travelsReportReducer;
