import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import speedingReportAction from "~/actions/reporting/speedingReportAction";
import reportingAction from "~/actions/reportingAction";
import PeriodFilter from "~/components/common/periodReport/PeriodFilter";
import Utils from "~/helpers/Utils";
import "~/components/reporting/Reporting.scss";

const SpeedingFilterPeriod = () => {
    const dispatch = useDispatch();

    const { requestPeriod } = useSelector((state) => state.app.loading);

    const onFormRefUpdate = useCallback(
        (_periodForm) => {
            dispatch(speedingReportAction.updatePeriodForm(_periodForm.current));
        },
        [dispatch]
    );

    return (
        <PeriodFilter
            onFormRefUpdate={onFormRefUpdate}
            loading={requestPeriod}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                dispatch(
                    reportingAction.generateReportingPerPeriod(
                        {
                            RelatorioTipoId: 76,
                            DataInicial: Utils.utcToLocal(values.period.startDate),
                            DataFinal: Utils.utcToLocal(values.period.endDate),
                            Velocidade: values.speed > 0 ? values.speed : null,
                            Email: values.email,
                            ObjetoRastreavelId: values.vehicle ? parseInt(values.vehicle.id) : null,
                            GrupoId: values.fleet ? parseInt(values.fleet.id) : null,
                        },
                        (res) => {
                            setSubmitting(false);
                            resetForm();
                            dispatch(
                                reportingAction.updatePaginationPerPeriod({
                                    Start: 0,
                                    Index: 0,
                                    Length: 25,
                                })
                            );
                        }
                    )
                );
            }}
        />
    );
};
export default SpeedingFilterPeriod;
