import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Grid, Box, Button, CircularProgress } from "@material-ui/core";
import { CancelIcon, ConfirmIcon } from "~/components/common/icons/Icons";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import vehicleAction from "~/actions/vehicleAction";
import RegisterInputText from "~/components/common/registerInputs/RegisterInputText";
import OdometerHistory from "~/components/vehicle/listing/tabs/odometer/OdometerHistory.jsx";
import "./Form.scss";

const LabelWithSubLabel = (props) => {
    const { t } = useTranslation();
    const { firstLabel, secondLabel } = props;
    return (
        <span className="label-with-sublabel">
            <span>{t(firstLabel)}</span>
            <span>{t(secondLabel)}</span>
        </span>
    );
};

const OdometerForm = (props) => {
    const { t } = useTranslation();
    const { comeBack } = props;
    const dispatch = useDispatch();
    const refVehicleForm = useRef();
    const updateAction = vehicleAction.updateOdometerForm;

    useEffect(() => {
        if (refVehicleForm.current) dispatch(updateAction(refVehicleForm));
    }, [dispatch, updateAction]);

    return (
        <Formik
            innerRef={refVehicleForm}
            initialValues={{
                Id: null,
                DispositivoId: null,
                ObjetoRastreavelId: null,
                VeiculoOdometro: "",
                DispositivoOdometro: "",
            }}
            validate={(values) => {
                const errors = {};

                if (!values.VeiculoOdometro) {
                    errors.VeiculoOdometro = t(Labels.VEHICLE_FORM_VEHICLE_ODOMETER_REQUIRED);
                }

                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                dispatch(
                    vehicleAction.saveOdometer(
                        {
                            Id: values.Id,
                            VeiculoOdometro: parseInt(values.VeiculoOdometro),
                            DispositivoOdometro: values.DispositivoOdometro,
                            ObjetoRastreavelId: values.ObjetoRastreavelId,
                            DispositivoId: values.DispositivoId,
                        },
                        (error) => {
                            setSubmitting(false);
                            if (error) {
                                Utils.showError(error.response.data.Message);
                                return;
                            }

                            Utils.showTranslatedToast({
                                type: Constants.SUCCESS,
                                description: Labels.VEHICLE_FORM_MSG_ODOMETER_SAVE_SUCCESS,
                            });

                            props.comeBack();
                        }
                    )
                );
            }}>
            {(form) => {
                return (
                    <Form className="ceabs-register-form odometer-form">
                        <Grid container direction="row">
                            <Grid item xs={4} md={props.compact ? 5 : 3}>
                                <RegisterInputText
                                    disabled
                                    label={
                                        <LabelWithSubLabel
                                            firstLabel={Labels.VEHICLE_FORM_TRACKED_ODOMETER}
                                            secondLabel={Labels.VEHICLE_FORM_TRACKED_ODOMETER_SMALL_TITLE}
                                        />
                                    }
                                    name="DispositivoOdometro"
                                />
                            </Grid>

                            <Grid item xs={4} md={props.compact ? 5 : 3}>
                                <RegisterInputText
                                    label={
                                        <LabelWithSubLabel
                                            firstLabel={Labels.VEHICLE_FORM_VEHICLE_ODOMETER}
                                            secondLabel={Labels.VEHICLE_FORM_VEHICLE_ODOMETER_SMALL_TITLE}
                                        />
                                    }
                                    name="VeiculoOdometro"
                                />
                            </Grid>

                            <Grid className="odometer-history-list" item xs={12} md={props.compact ? 12 : 6}>
                                <OdometerHistory />
                            </Grid>

                            <Grid item xs={12} md={props.compact ? 12 : 3}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    style={{ height: "100%" }}
                                    spacing={0}>
                                    <Grid item xs={3} md={3}>
                                        <Box className="button-form button-form-refactor">
                                            <Button
                                                id="odemeter-cancel-button"
                                                className="cancel-button cancel-button-refactor"
                                                variant="contained"
                                                size="large"
                                                disableElevation
                                                fullWidth
                                                onClick={() => {
                                                    form.resetForm();
                                                    comeBack && comeBack();
                                                }}
                                                disabled={props.loading}
                                                startIcon={<CancelIcon />}>
                                                {t(Labels.VEHICLE_FORM_BUTTON_CANCEL)}
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Box className="button-form button-form-refactor">
                                            <Button
                                                id="odemeter-submit-button"
                                                variant="contained"
                                                className="save-button save-button-refactor"
                                                color="primary"
                                                size="large"
                                                fullWidth
                                                disableElevation
                                                onClick={form.submitForm}
                                                disabled={props.loading}
                                                startIcon={
                                                    props.loading ? <CircularProgress size={18} /> : <ConfirmIcon />
                                                }>
                                                {t(Labels.VEHICLE_FORM_BUTTON_CONFIRM)}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default OdometerForm;
