import React from "react";
import AlertsIcon from "~/assets/icons/menuSide/alerts.svg";
import Dashboard from "~/assets/icons/menuSide/dashboard.svg";
import MapIcon from "~/assets/icons/menuSide/map.svg";
import ReportIcon from "~/assets/icons/menuSide/report.svg";
import SignUpIcon from "~/assets/icons/menuSide/signUp.svg";
import RenewIcon from "~/assets/icons/menuSide/renovacao_icon.svg";
import Labels from "~/helpers/enums/Labels";
import AlertsView from "~/views/alerts/AlertsView";
import Communicated from "~/views/communicated/Communicated/";
import DashboardView from "~/views/dashboard/DashboardView";
import DashboardVehicleView from "~/views/dashboardVehicle/DashboardView";
import RegisteredDrivers from "~/views/drivers/finder/RegisteredDrivers";
import ImportDriverView from "~/views/drivers/import/ImportDriver";
import { default as Register, default as RegisterDriver } from "~/views/drivers/register/RegisterDriver";
import ExampleView from "~/views/example/ExampleView";
import GroupDriverFinderView from "~/views/groupOfDrivers/finder/GroupDriverFinderView";
import RegisterGroupOfDriversView from "~/views/groupOfDrivers/register/RegisterGroupOfDriversView";
import GroupPointsFinderView from "~/views/groupOfPoints/finder/GroupPointsFinderView";
import RegisterGroupOfPointsView from "~/views/groupOfPoints/register/RegisterGroupOfPointsView";
import Login from "~/views/login/Login";
import RecoverPassword from "~/views/recoverPassword/RecoverPassword";
import Renew from "~/views/Renew/Renew";
import MapView from "~/views/map/MapView";
// Views
import NotFound from "~/views/notFound/NotFound";
import FinderPointsInterest from "~/views/pointsInterest/finder/FinderPointsView";
import RegisterPointsInterest from "~/views/pointsInterest/register/RegisterPointsInterest";
import PointsImportView from "~/views/pointsInterest/import/PointsImportView";
import RegisterUserView from "~/views/user/register/RegisterUserView";
import UserLinkCarsView from "~/views/user/linkCars/UserLinkCarsView";
import ReportDistance from "~/views/reporting/distance/ReportDistance";
import ReportAlertsView from "~/views/reporting/alerts/ReportAlerts";
import TermsPDFView from "~/views/acceptTerms/TermsPDFView";

import ReportEvents from "~/views/reporting/events/ReportEvents";
import ReportSpeedingView from "~/views/reporting/speeding/ReportSpeedingView";
import ReportTravels from "~/views/reporting/travels/ReportTravels";
import ProfileView from "~/views/profile/ProfileView";
import FleetsView from "~/views/fleets/FleetsView";
import FleetsLinkCarsView from "~/views/fleets/LinkCarsView";
import AlertView from "~/views/alert/AlertView";
import AlertBondView from "~/views/alertBond/AlertBondView";
import VehicleView from "~/views/vehicle/VehicleView";
import FinderRoutesView from "~/views/route/FinderRoutesView";
import RegisterRoutesView from "~/views/route/RegisterRoutesView";
import Unauthorized from "~/views/unauthorized/Unauthorized";
import Tutorial from "~/views/tutorial/Tutorial";
import { subRoutesRegister, subRoutesReport } from "./subRoutes";

export const routes = [
    {
        path: "/",
        exact: true,
        title: () => Labels.TITLE_TUTORIAL,
        view: () => <Tutorial />,
        onlyAuthorized: true,
        showOnMenu: false,
    },
    {
        path: "/vehicleRenewal",
        exact: true,
        title: () => Labels.TITLE_RENEWAL,
        view: () => <Renew />,
        menu: () => Labels.MENU_LABEL_RENEWAL,
        icon: RenewIcon,
        onlyAuthorized: true,
        showOnMenu: true,
        id: 1020,
    },
    {
        path: "/dashboard",
        exact: true,
        title: () => Labels.TITLE_DASHBOARD_VIEW,
        view: () => <DashboardView />,
        menu: () => Labels.MENU_LABEL_DASHBOARD,
        icon: Dashboard,
        onlyAuthorized: true,
        showOnMenu: true,
        id: 1000,
    },
    {
        path: "/dashboardVehicle/",
        exact: true,
        title: () => Labels.TITLE_DASHBOARD_VEHICLE_VIEW,
        view: () => <DashboardVehicleView />,
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1017,
    },
    {
        path: "/login",
        exact: true,
        title: () => Labels.TITLE_LOGIN,
        view: () => <Login />,
        onlyAuthorized: false,
        showOnMenu: false,
    },
    {
        path: "/Cadastrar",
        exact: true,
        title: () => Labels.TITLE_REGISTER,
        view: () => <Login />,
        onlyAuthorized: false,
        showOnMenu: false,
    },
    {
        path: "/RecoverPassword",
        exact: true,
        title: () => Labels.TITLE_REGISTER,
        view: () => <RecoverPassword />,
        onlyAuthorized: false,
        showOnMenu: false,
    },
    {
        path: "/Login/CadastrarUsuario/:document",
        exact: true,
        title: () => Labels.TITLE_LOGIN,
        view: () => <Login />,
        onlyAuthorized: false,
        showOnMenu: false,
    },
    {
        path: "/alerts",
        exact: true,
        title: () => Labels.TITLE_ALERTS_VIEW,
        view: () => <AlertsView />,
        menu: () => Labels.MENU_LABEL_ALERTS,
        breadcrumbs: [{ label: Labels.MENU_LABEL_ALERTS }],
        icon: AlertsIcon,
        onlyAuthorized: true,
        notify: true,
        showOnMenu: true,
        id: 1001,
    },
    {
        path: "/map",
        exact: true,
        title: () => Labels.TITLE_MAP_VIEW,
        view: () => <MapView />,
        menu: () => Labels.MENU_LABEL_MAPS,
        breadcrumbs: [{ label: Labels.MENU_LABEL_MAPS }],
        icon: MapIcon,
        onlyAuthorized: true,
        showOnMenu: true,
        id: 1002,
    },
    {
        pathMenu: "/register",
        exact: true,
        menu: () => Labels.MENU_LABEL_REGISTER,
        icon: SignUpIcon,
        showOnMenu: true,
        subMenu: true,
        subRoutes: subRoutesRegister,
    },
    {
        path: "/register/alerts",
        exact: true,
        title: () => Labels.TITLE_ALERT_VIEW,
        view: () => <AlertView />,
        breadcrumbs: [{ label: Labels.MENU_LABEL_REGISTER }, { label: Labels.MENU_LABEL_REGISTER_ALERT }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1003,
    },
    {
        path: "/register/alerts/bond/:id",
        exact: true,
        title: () => Labels.TITLE_ALERT_BOND_VIEW,
        view: () => <AlertBondView />,
        breadcrumbs: [
            { label: Labels.MENU_LABEL_REGISTER },
            { label: Labels.MENU_LABEL_REGISTER_ALERT },
            { label: Labels.MENU_LABEL_REGISTER_ALERT_CARS_LINK },
        ],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1003,
    },
    {
        path: "/register/fleet",
        exact: true,
        title: () => Labels.TITLE_FLEETS_VIEW,
        view: () => <FleetsView />,
        breadcrumbs: [{ label: Labels.MENU_LABEL_REGISTER }, { label: Labels.MENU_LABEL_REGISTER_FLEET }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1004,
    },
    {
        path: "/register/fleet/carsLink",
        exact: true,
        title: () => Labels.TITLE_FLEETS_LINK_CARS_VIEW,
        view: () => <FleetsLinkCarsView />,
        breadcrumbs: [
            { label: Labels.MENU_LABEL_REGISTER },
            { label: Labels.MENU_LABEL_REGISTER_FLEET },
            { label: Labels.MENU_LABEL_REGISTER_FLEET_CARS_LINK },
        ],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1004,
    },
    {
        path: "/register/groupOfDrivers",
        exact: true,
        title: () => Labels.TITLE_GROUP_DRIVER_FINDER_VIEW,
        view: () => <GroupDriverFinderView />,
        breadcrumbs: [{ label: Labels.MENU_LABEL_REGISTER }, { label: Labels.MENU_LABEL_REGISTER_GROUP_DRIVER }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1005,
    },
    {
        path: "/register/groupOfDrivers/:Id",
        exact: true,
        title: () => Labels.TITLE_REGISTER_GROUP_OF_DRIVERS_VIEW,
        view: () => <RegisterGroupOfDriversView />,
        breadcrumbs: [{ label: Labels.MENU_LABEL_REGISTER }, { label: Labels.MENU_LABEL_REGISTER_GROUP_DRIVER }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1005,
    },
    {
        path: "/register/groupOfDrivers/new",
        exact: true,
        title: () => Labels.TITLE_REGISTER_GROUP_OF_DRIVERS_VIEW,
        view: () => <RegisterGroupOfDriversView />,
        breadcrumbs: [{ label: Labels.MENU_LABEL_REGISTER }, { label: Labels.MENU_LABEL_REGISTER_GROUP_DRIVER }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1005,
    },
    {
        path: "/register/groupOfPointsInterest",
        exact: true,
        title: () => Labels.TITLE_GROUP_POINTS_FINDER_VIEW,
        view: () => <GroupPointsFinderView />,
        breadcrumbs: [
            { label: Labels.MENU_LABEL_REGISTER },
            { label: Labels.MENU_LABEL_REGISTER_GROUP_POINT_INTEREST },
        ],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1006,
    },
    {
        path: "/register/groupOfPointsInterest/new",
        exact: true,
        title: () => Labels.TITLE_REGISTER_GROUP_OF_POINTS_VIEW,
        view: () => <RegisterGroupOfPointsView />,
        breadcrumbs: [
            { label: Labels.MENU_LABEL_REGISTER },
            { label: Labels.MENU_LABEL_REGISTER_GROUP_POINT_INTEREST },
        ],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1006,
    },
    {
        path: "/register/groupOfPointsInterest/:Id",
        exact: true,
        title: () => Labels.TITLE_REGISTER_GROUP_OF_POINTS_VIEW,
        view: () => <RegisterGroupOfPointsView />,
        breadcrumbs: [
            { label: Labels.MENU_LABEL_REGISTER },
            { label: Labels.MENU_LABEL_REGISTER_GROUP_POINT_INTEREST },
        ],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1006,
    },
    {
        path: "/register/drivers",
        exact: true,
        title: () => Labels.TITLE_REGISTER,
        view: () => <Register />,
        breadcrumbs: [{ label: Labels.MENU_LABEL_REGISTER }, { label: Labels.MENU_LABEL_REGISTER_DRIVER }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1008,
    },
    {
        path: "/register/drivers/list",
        exact: true,
        title: () => Labels.TITLE_REGISTERED_DRIVERS,
        view: () => <RegisteredDrivers />,
        breadcrumbs: [{ label: Labels.MENU_LABEL_REGISTER }, { label: Labels.MENU_LABEL_REGISTER_DRIVER }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1008,
    },
    {
        path: "/register/driver",
        exact: true,
        title: () => Labels.TITLE_REGISTER_DRIVER,
        view: () => <RegisterDriver />,
        breadcrumbs: [
            { label: Labels.MENU_LABEL_REGISTER },
            { label: Labels.MENU_LABEL_REGISTER_DRIVER },
            { label: Labels.MENU_LABEL_REGISTER_DRIVER_ADD },
        ],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1008,
    },
    {
        path: "/register/driver/:id",
        exact: true,
        title: () => Labels.TITLE_REGISTER_DRIVER,
        view: () => <RegisterDriver />,
        breadcrumbs: [
            { label: Labels.MENU_LABEL_REGISTER },
            { label: Labels.MENU_LABEL_REGISTER_DRIVER },
            { label: Labels.MENU_LABEL_REGISTER_DRIVER_EDIT },
        ],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1008,
    },
    {
        path: "/register/drivers/import",
        exact: true,
        title: () => Labels.TITLE_IMPORT_DRIVER_VIEW,
        view: () => <ImportDriverView />,
        breadcrumbs: [
            { label: Labels.MENU_LABEL_REGISTER },
            { label: Labels.MENU_LABEL_REGISTER_DRIVER },
            { label: Labels.MENU_LABEL_REGISTER_DRIVER_IMPORT },
        ],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1008,
    },
    {
        path: "/register/pointsInterest/pointsImport",
        exact: true,
        title: () => Labels.TITLE_POINTS_IMPORT_VIEW,
        view: () => <PointsImportView />,
        breadcrumbs: [
            { label: Labels.MENU_LABEL_REGISTER },
            { label: Labels.MENU_LABEL_REGISTER_POINT_INTEREST },
            { label: Labels.MENU_LABEL_REGISTER_POINT_INTEREST_IMPORT },
        ],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1007,
    },
    {
        path: "/register/pointsInterest/list",
        exact: true,
        title: () => Labels.TITLE_FINDER_POINTS_INTEREST,
        view: () => <FinderPointsInterest />,
        breadcrumbs: [{ label: Labels.MENU_LABEL_REGISTER }, { label: Labels.MENU_LABEL_REGISTER_POINT_INTEREST }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1007,
    },
    {
        path: "/communicated/",
        exact: true,
        title: () => Labels.TITLE_COMMUNICATED,
        view: () => <Communicated />,
        breadcrumbs: [{ label: "Comunicados" }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1011,
    },
    {
        path: "/register/pointsInterest",
        exact: true,
        title: () => Labels.TITLE_REGISTER_POINTS_INTEREST,
        view: () => <RegisterPointsInterest />,
        breadcrumbs: [
            { label: Labels.MENU_LABEL_REGISTER },
            { label: Labels.MENU_LABEL_REGISTER_POINT_INTEREST },
            { label: Labels.MENU_LABEL_REGISTER_POINT_INTEREST_ADD },
        ],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1007,
    },
    {
        path: "/register/pointsInterest/:id",
        exact: true,
        title: () => Labels.TITLE_REGISTER_POINTS_INTEREST,
        view: () => <RegisterPointsInterest />,
        breadcrumbs: [
            { label: Labels.MENU_LABEL_REGISTER },
            { label: Labels.MENU_LABEL_REGISTER_POINT_INTEREST },
            { label: Labels.MENU_LABEL_REGISTER_POINT_INTEREST_EDIT },
        ],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1007,
    },
    {
        path: "/register/routes",
        exact: true,
        title: () => Labels.TITLE_FINDER_ROUTES_VIEW,
        view: () => <FinderRoutesView />,
        breadcrumbs: [{ label: Labels.MENU_LABEL_REGISTER }, { label: Labels.MENU_LABEL_REGISTER_ROUTE }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1009,
    },
    {
        path: "/register/route/",
        exact: true,
        title: () => Labels.TITLE_REGISTER_ROUTES_VIEW,
        view: () => <RegisterRoutesView />,
        breadcrumbs: [
            { label: Labels.MENU_LABEL_REGISTER },
            { label: Labels.MENU_LABEL_REGISTER_ROUTE },
            { label: Labels.MENU_LABEL_REGISTER_ROUTE_ADD },
        ],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1009,
    },
    {
        path: "/register/route/:id",
        exact: true,
        title: () => Labels.TITLE_REGISTER_ROUTES_VIEW,
        view: () => <RegisterRoutesView />,
        breadcrumbs: [
            { label: Labels.MENU_LABEL_REGISTER },
            { label: Labels.MENU_LABEL_REGISTER_ROUTE },
            { label: Labels.MENU_LABEL_REGISTER_ROUTE_EDIT },
        ],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1009,
    },
    {
        path: "/register/vehicles",
        exact: true,
        title: () => Labels.TITLE_VEHICLE_VIEW,
        view: () => <VehicleView />,
        breadcrumbs: [{ label: Labels.MENU_LABEL_REGISTER }, { label: Labels.MENU_LABEL_REGISTER_VEHICLE }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1010,
    },
    {
        path: "/register/user",
        exact: true,
        title: () => Labels.TITLE_REGISTER_USER_VIEW,
        view: () => <RegisterUserView />,
        breadcrumbs: [{ label: Labels.MENU_LABEL_REGISTER }, { label: Labels.MENU_LABEL_REGISTER_USER }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1019,
    },
    {
        path: "/register/user/linkCars",
        exact: true,
        title: () => Labels.TITLE_USER_LINK_CARS_VIEW,
        view: () => <UserLinkCarsView />,
        breadcrumbs: [
            { label: Labels.MENU_LABEL_REGISTER },
            { label: Labels.MENU_LABEL_REGISTER_USER },
            { label: Labels.MENU_LABEL_REGISTER_USER_CARS_LINK },
        ],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1019,
    },
    {
        pathMenu: "/report",
        menu: () => Labels.MENU_LABEL_REPORTS,
        icon: ReportIcon,
        showOnMenu: true,
        subMenu: true,
        subRoutes: subRoutesReport,
    },
    {
        path: "/terms",
        exact: true,
        title: () => "Termos",
        view: () => <TermsPDFView />,
        menu: () => "Termos",
        icon: ReportIcon,
        onlyAuthorized: true,
        showOnMenu: true,
        id: 1,
    },
    {
        path: "/report/alerts",
        exact: true,
        title: () => Labels.TITLE_REPORT_ALERTS_VIEW,
        view: () => <ReportAlertsView />,
        breadcrumbs: [{ label: Labels.MENU_LABEL_REPORTS }, { label: Labels.MENU_LABEL_REPORT_ALERT }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1016,
    },
    {
        path: "/report/distance",
        exact: true,
        title: () => Labels.TITLE_REPORT_DISTANCE,
        view: () => <ReportDistance />,
        breadcrumbs: [{ label: Labels.MENU_LABEL_REPORTS }, { label: Labels.MENU_LABEL_REPORT_DISTANCE }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1012,
    },
    {
        path: "/report/events",
        exact: true,
        title: () => Labels.TITLE_REPORT_EVENTS,
        view: () => <ReportEvents />,
        breadcrumbs: [{ label: Labels.MENU_LABEL_REPORTS }, { label: Labels.MENU_LABEL_REPORT_EVENTS }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1013,
    },
    {
        path: "/report/travels",
        exact: true,
        title: () => Labels.TITLE_REPORT_TRAVELS,
        view: () => <ReportTravels />,
        breadcrumbs: [{ label: Labels.MENU_LABEL_REPORTS }, { label: Labels.MENU_LABEL_REPORT_TRAVELS }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1014,
    },
    {
        path: "/report/speeding",
        exact: true,
        title: () => Labels.TITLE_REPORT_SPEEDING_VIEW,
        view: () => <ReportSpeedingView />,
        breadcrumbs: [{ label: Labels.MENU_LABEL_REPORTS }, { label: Labels.MENU_LABEL_REPORT_SPEEDING }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1015,
    },
    {
        path: "/profile",
        exact: true,
        title: () => Labels.TITLE_PROFILE_VIEW,
        view: () => <ProfileView />,
        breadcrumbs: [{ label: Labels.TITLE_PROFILE_VIEW }],
        onlyAuthorized: true,
        showOnMenu: false,
        id: 1018,
    },
    {
        path: "/not-authorized",
        exact: true,
        title: () => Labels.TITLE_UNAUTHORIZED,
        view: () => <Unauthorized />,
        onlyAuthorized: "both",
        showOnMenu: false,
    },
    {
        path: "/example",
        exact: true,
        title: () => Labels.TITLE_EXAMPLE_VIEW,
        view: () => <ExampleView />,
        onlyAuthorized: true,
    },
    {
        path: "/not-found",
        exact: true,
        title: () => Labels.TITLE_NOT_FOUND,
        view: () => <NotFound />,
        onlyAuthorized: true,
        showOnMenu: false,
    },
];

export default routes;
