import React, { useRef, Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withGoogleMap, GoogleMap, Marker, InfoWindow, Polygon, Polyline, Circle } from "react-google-maps";
import InterestPointMarker from "~/assets/icons/interestPointMarker.svg";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import { MAP } from "react-google-maps/lib/constants";
import { compose, withStateHandlers } from "recompose";
import MeasureTool from "measuretool-googlemaps-v3";
import ZoomTool from "zoomtool-googlemaps-v3";
import Utils from "~/helpers/Utils";
import GoogleMapInfoView from "./googleMaps/GoogleMapInfoView";
import GoogleMapMarkerInfoView from "./googleMaps/GoogleMapMarkerInfoView";
import MapCustomControl from "./googleMaps/MapCustomControl";
import GoogleScriptLoader from "~/components/common/googleScriptLoader/GoogleScriptLoader";
import googleMapsDataAction from "~/actions/googleMapsDataAction";
import { arrowResolver } from "./ArrowResolver";
import Constants from "~/helpers/enums/Constants";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import "./GoogleMaps.scss";

const GoogleMapsComponent = compose(
    withStateHandlers(
        () => ({
            toggleInfoWindows: {},
        }),
        {
            onToggleOpen: (state) => (id) => {
                const { isOpen, toggleInfoWindows } = state;

                toggleInfoWindows[id] = !toggleInfoWindows[id];
                return {
                    ...state,
                    isOpen: !isOpen,
                    toggleInfoWindows: toggleInfoWindows,
                };
            },
            closeAllInfo: (state) => () => {
                return {
                    ...state,
                    toggleInfoWindows: {},
                };
            },
        }
    ),
    withGoogleMap
)((props) => {
    const dispatch = useDispatch();
    const [zoomTool, setZoomTool] = useState(null);
    const [measureTool, setMeasureTool] = useState(null);
    const [isMeasureTool, setIsMeasureTool] = useState(false);
    const [isAutoCenter, setAutoCenter] = useState(true);
    const { t } = useTranslation();

    const map = useRef();

    const { objects, groupPoints, points, routes, directions, events, toCentralize, mapEvents } = useSelector(
        (state) => state.googleMapsData
    );
    const allPoints = [...groupPoints, ...points];
    const { closeAllInfo } = props;

    const fillBoundsObjects = (bounds, objectsToBounds) => {
        objectsToBounds.forEach((element) => {
            bounds.extend({
                lat: element.Latitude,
                lng: element.Longitude,
            });
        });

        return bounds;
    };

    const fillBoundsDirections = (bounds, directionsToBound) => {
        directionsToBound.forEach((d) => {
            d.Trajeto &&
                d.Trajeto.forEach((t) => {
                    bounds.extend({
                        lat: t.latitude,
                        lng: t.longitude,
                    });
                });
        });

        return bounds;
    };

    const fillBoundsEvents = (bounds, eventsToBound) => {
        eventsToBound.forEach((eventGroup) => {
            eventGroup.forEach((e) => {
                bounds.extend({
                    lat: parseFloat(e.Latitude.replace(",", ".")),
                    lng: parseFloat(e.Longitude.replace(",", ".")),
                });
            });
        });

        return bounds;
    };

    const fillBoundsRoutes = (bounds, routesToBound) => {
        routesToBound.forEach((element) => {
            Utils.getLatLngPathsFromString(element.value).forEach((point) => {
                bounds.extend(point);
            });
        });

        return bounds;
    };

    const fillBoundsPoints = (bounds, pointsToInsert) => {
        pointsToInsert
            .filter((p) => p.type === Constants.POLYGON)
            .forEach((element) => {
                Utils.getLatLngPathsFromString(element.value).forEach((point) => {
                    bounds.extend(point);
                });
            });

        pointsToInsert
            .filter((p) => p.type === Constants.POINT)
            .forEach((element) => {
                bounds.extend({
                    lat: element.value.latitude,
                    lng: element.value.longitude,
                });
            });

        return bounds;
    };

    useEffect(() => {
        dispatch(googleMapsDataAction.updateMapContext(map.current.context[MAP]));
    }, [dispatch, map]);

    useEffect(() => {
        let bounds = new window.google.maps.LatLngBounds();
        closeAllInfo();

        if (isAutoCenter && objects.length > 0) {
            bounds = fillBoundsObjects(bounds, objects);

            map.current.fitBounds(bounds);
        }
    }, [isAutoCenter, objects, closeAllInfo]);

    useEffect(() => {
        let bounds = new window.google.maps.LatLngBounds();
        if (directions.length > 0) {
            bounds = fillBoundsDirections(bounds, directions);
            map.current.fitBounds(bounds);
        }
    }, [directions]);

    useEffect(() => {
        let bounds = new window.google.maps.LatLngBounds();
        if (events.length > 0) {
            bounds = fillBoundsEvents(bounds, events);
            map.current.fitBounds(bounds);
        }
    }, [events]);

    useEffect(() => {
        let bounds = new window.google.maps.LatLngBounds();

        if (points.length > 0) {
            bounds = fillBoundsPoints(bounds, points);
            map.current.fitBounds(bounds);
        }
    }, [points]);

    useEffect(() => {
        let bounds = new window.google.maps.LatLngBounds();

        if (groupPoints.length > 0) {
            bounds = fillBoundsPoints(bounds, groupPoints);
            map.current.fitBounds(bounds);
        }
    }, [groupPoints]);

    useEffect(() => {
        let bounds = new window.google.maps.LatLngBounds();

        if (routes.length > 0) {
            bounds = fillBoundsRoutes(bounds, routes);
            map.current.fitBounds(bounds);
        }
    }, [routes]);

    useEffect(() => {
        let bounds = new window.google.maps.LatLngBounds();

        if (toCentralize.length > 0) {
            toCentralize.forEach((p) => bounds.extend(p));
            map.current.fitBounds(bounds);
        }
    }, [toCentralize]);

    objects &&
        objects.forEach((e, idx) => {
            e["googleInfoViewId"] = idx;
        });

    const centralize = () => {
        let bounds = new window.google.maps.LatLngBounds();
        bounds = fillBoundsObjects(bounds, objects);
        bounds = fillBoundsDirections(bounds, directions);
        bounds = fillBoundsEvents(bounds, events);
        bounds = fillBoundsPoints(bounds, allPoints);
        bounds = fillBoundsRoutes(bounds, routes);
        map.current.fitBounds(bounds);
    };

    const handleZoomChange = () => {
        setAutoCenter(false);
    };

    useEffect(() => {
        const currentMapContext = map.current.context[MAP];

        mapEvents.forEach((mapEvent) => {
            if (!mapEvent.key || !mapEvent.func) return;
            if (mapEvent.handler) {
                window.google.maps.event.removeListener(mapEvent.handler);
            }

            const handler = window.google.maps.event.addListener(currentMapContext, mapEvent.event, function (
                mouseEvent
            ) {
                mapEvent.func(mouseEvent, currentMapContext);
            });

            mapEvent["handler"] = handler;
        });
    }, [dispatch, mapEvents]);

    useEffect(() => {
        mapEvents.forEach((mapEvent) => {
            if (mapEvent.key) return;
            window.google.maps.event.removeListener(mapEvent.handler);
        });
    }, [mapEvents]);

    useEffect(() => {
        const currentMapContext = map.current.context[MAP];
        setMeasureTool(
            new MeasureTool(currentMapContext, {
                contextMenu: false,
                showSegmentLength: true,
                unit: MeasureTool.UnitTypeId.METRIC,
            })
        );

        setZoomTool(new ZoomTool(currentMapContext));
    }, []);

    const filterPoints = (point, lat = "lat", lng = "lng") => {
        if (!point) {
            console.error("Google maps component - Undefined Objects");
            return false;
        }
        return true;
    };

    return (
        <GoogleMap
            defaultZoom={2}
            onZoomChanged={handleZoomChange}
            options={{
                fullscreenControl: false,
                streetViewControl: true,
                scaleControl: true,
                //	zoomControlOptions: {
                //		position: window.google.maps.ControlPosition.LEFT_BOTTOM
                //	},
                //	streetViewControlOptions: {
                //		position: window.google.maps.ControlPosition.LEFT_CENTER
                //	}
            }}
            defaultCenter={{ lat: -15.35568, lng: -56.060555 }}
            ref={map}>
            <MapCustomControl
                handleZoomAction={() => {
                    zoomTool.startZoomTool();
                }}
                handleCenterAction={() => {
                    centralize();
                }}
                handleMeasureAction={() => {
                    if (isMeasureTool) {
                        measureTool.end();
                        measureTool._redrawOverlay();
                    } else {
                        measureTool.start();
                    }
                    setIsMeasureTool(!isMeasureTool);
                }}
            />

            <MarkerClusterer>
                {objects &&
                    objects
                        .filter((obj) => filterPoints(obj, "Latitude", "Longitude"))
                        .map((p, i) => (
                            <Marker
                                key={i}
                                position={{ lat: p.Latitude, lng: p.Longitude }}
                                zIndex={10}
                                icon={{
                                    url: Utils.iconResolver(p.Tipo, p.Ignicao),
                                    scaledSize: new window.google.maps.Size(40, 40), // scaled size
                                }}
                                onClick={() => {
                                    props.onToggleOpen(p.Tipo + p.googleInfoViewId);
                                }}>
                                {props.toggleInfoWindows[p.Tipo + p.googleInfoViewId] && (
                                    <InfoWindow
                                        onCloseClick={() => {
                                            props.onToggleOpen(p.Tipo + p.googleInfoViewId);
                                        }}>
                                        <GoogleMapInfoView item={p} />
                                    </InfoWindow>
                                )}
                            </Marker>
                        ))}
            </MarkerClusterer>

            <MarkerClusterer>
                {routes &&
                    routes.map((p, i) => {
                        const item = Utils.getLatLngPathsFromString(p.value);

                        const markerInfoProps = {
                            title: t(Labels.MAP_MARKER_INFO_VIEW_ROUTE_TITLE),
                            value: p.title,
                            description: t(Labels.MAP_MARKER_INFO_VIEW_ROUTE_NAME),
                        };

                        const markerChildren = (
                            <InfoWindow>
                                <GoogleMapMarkerInfoView item={markerInfoProps} />
                            </InfoWindow>
                        );

                        const markerAId = p.type + p.name + "A";
                        const markerBId = p.type + p.name + "B";

                        return (
                            <Fragment key={i}>
                                <Marker
                                    position={{ lat: item[0].lat, lng: item[0].lng }}
                                    zIndex={10}
                                    label={{ text: "A", color: "white", fontSize: "16px" }}
                                    onMouseOver={() => {
                                        props.onToggleOpen(markerAId);
                                    }}
                                    onMouseOut={() => {
                                        props.onToggleOpen(markerAId);
                                    }}>
                                    {props.toggleInfoWindows[markerAId] && markerChildren}
                                </Marker>
                                <Marker
                                    position={{ lat: item[item.length - 1].lat, lng: item[item.length - 1].lng }}
                                    zIndex={10}
                                    label={{ text: "B", color: "white", fontSize: "16px" }}
                                    onMouseOver={() => {
                                        props.onToggleOpen(markerBId);
                                    }}
                                    onMouseOut={() => {
                                        props.onToggleOpen(markerBId);
                                    }}>
                                    {props.toggleInfoWindows[markerBId] && markerChildren}
                                </Marker>
                            </Fragment>
                        );
                    })}
            </MarkerClusterer>

            {allPoints &&
                allPoints
                    .filter((p) => p.type === Constants.POLYGON)
                    .map((p, i) => {
                        const paths = Utils.getLatLngPathsFromString(p.value);
                        const center = Utils.getCenterPolygon(paths);

                        return (
                            <Fragment key={i}>
                                <Polygon
                                    key={i}
                                    paths={paths}
                                    options={{
                                        fillColor: "#6EC8AF",
                                    }}
                                    onClick={() => {
                                        props.onToggleOpen(p.type + p.name);
                                    }}
                                />
                                <Marker visible={false} position={center}>
                                    {props.toggleInfoWindows[p.type + p.name] && (
                                        <InfoWindow
                                            onCloseClick={() => {
                                                props.onToggleOpen(p.type + p.name);
                                            }}>
                                            <GoogleMapMarkerInfoView
                                                item={{
                                                    title: p.groupName,
                                                    value: p.title,
                                                    description: t(Labels.MAP_MARKER_INFO_VIEW_POINT_INTEREST),
                                                }}
                                            />
                                        </InfoWindow>
                                    )}
                                </Marker>
                            </Fragment>
                        );
                    })}

            {allPoints &&
                allPoints
                    .filter((p) => p.type === Constants.POINT)
                    .filter((obj) => filterPoints(obj, "latitude", "longitude"))
                    .map((p, i) => {
                        const markerInfoProps = {
                            title: p.groupName,
                            value: p.title,
                            description: t(Labels.MAP_MARKER_INFO_VIEW_POINT_INTEREST),
                        };
                        return (
                            <Fragment key={i}>
                                <Circle
                                    center={{
                                        lat: p.value.latitude,
                                        lng: p.value.longitude,
                                    }}
                                    visible={true}
                                    radius={p.value.radius === 0 ? 1 : p.value.radius}
                                    options={{
                                        strokeOpacity: 0,
                                        fillColor: "#64C8FF",
                                        fillOpacity: 0.17,
                                    }}
                                />
                                <Marker
                                    icon={{
                                        url: InterestPointMarker,
                                        scale: 13,
                                    }}
                                    position={{
                                        lat: p.value.latitude,
                                        lng: p.value.longitude,
                                    }}
                                    onClick={() => {
                                        props.onToggleOpen(p.type + p.name);
                                    }}>
                                    {props.toggleInfoWindows[p.type + p.name] && (
                                        <InfoWindow
                                            onCloseClick={() => {
                                                props.onToggleOpen(p.type + p.name);
                                            }}>
                                            <GoogleMapMarkerInfoView item={markerInfoProps} />
                                        </InfoWindow>
                                    )}
                                </Marker>
                            </Fragment>
                        );
                    })}

            {routes &&
                routes.map((p, idx) => (
                    <Polyline
                        key={idx}
                        options={{
                            strokeColor: "#64C8FF",
                            strokeWeight: 4,
                            strokeOpacity: 0.8,
                        }}
                        path={Utils.getLatLngPathsFromString(p.value)}
                    />
                ))}

            {directions &&
                directions.map((d, i) => {
                    const routeDirections = d.Trajeto
                        ? d.Trajeto.map((e) => {
                              return {
                                  position: {
                                      lat: e.latitude,
                                      lng: e.longitude,
                                  },
                                  ignition: e.ignition,
                                  direction: e.direction,
                                  big: e.big,
                              };
                          })
                        : [];

                    return (
                        <Fragment key={i}>
                            <Polyline
                                options={{
                                    strokeColor: "#CC4848",
                                    strokeWeight: 3,
                                }}
                                zIndex={0}
                                path={routeDirections.map((r) => r.position)}
                            />
                            {routeDirections.map((r, j) => (
                                <Marker
                                    key={j}
                                    position={r.position}
                                    zIndex={r.big ? 4 : 1}
                                    icon={{
                                        anchor: { x: 10, y: 10 },
                                        url: arrowResolver(r.ignition, r.direction),
                                        scaledSize: r.big
                                            ? new window.google.maps.Size(40, 40)
                                            : new window.google.maps.Size(25, 25),
                                    }}
                                />
                            ))}
                        </Fragment>
                    );
                })}

            {events &&
                events.map((eventGorup, i) => {
                    const eventGroupDirections = eventGorup.map((e) => {
                        return {
                            position: {
                                lat: parseFloat(e.Latitude.replace(",", ".")),
                                lng: parseFloat(e.Longitude.replace(",", ".")),
                            },
                            ignition: e.Ignicao === "ON",
                            direction: e.direction,
                        };
                    });

                    return (
                        <Fragment key={i}>
                            <Polyline
                                options={{
                                    strokeColor: "#CC4848",
                                    strokeWeight: 2,
                                }}
                                zIndex={0}
                                path={eventGroupDirections.map((r) => r.position)}
                            />
                            {eventGroupDirections.map((r, j) => (
                                <Marker
                                    key={j}
                                    position={r.position}
                                    zIndex={1}
                                    icon={{
                                        path: window.google.maps.SymbolPath.CIRCLE,
                                        scale: 8,
                                        fillColor: r.ignition ? "#71C154" : "#CC4848",
                                        fillOpacity: 0.7,
                                        strokeOpacity: 0,
                                        strokeColor: "#fff",
                                    }}
                                />
                            ))}
                        </Fragment>
                    );
                })}
        </GoogleMap>
    );
});

const GoogleMaps = () => {
    return (
        <div className="map-card">
            <GoogleScriptLoader>
                <GoogleMapsComponent
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div className="fixHeightMap" style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
            </GoogleScriptLoader>
        </div>
    );
};

export default GoogleMaps;
