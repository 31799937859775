import Constants from "~/helpers/enums/Constants";
//AIzaSyBYGBtii-X_RtTEb2ekWXE1P6oFzOvdeq0
const initialState = {
	apiKey: null,
};

function infoReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.GET_API_KEY:
			return {
				...state,
				apiKey: action.payload.GoogleMapskey,
			};
		default:
			return state;
	}
}

export default infoReducer;
