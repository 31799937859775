import React, { useState } from "react";
import { ReactComponent as IconRefresh } from "~/assets/icons/refresh.svg";
import { SvgIcon, Switch, withStyles } from "@material-ui/core";
import Countdown from "react-countdown";
import "./refreshInterval.scss";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

const CustomSwitch = withStyles({
    switchBase: {
        //color: 'red',
        "&$checked": {
            color: "rgba(113, 193, 84, 1)",
        },
        "&$checked + $track": {
            backgroundColor: "rgba(113, 193, 84, .4)",
        },
    },
    checked: {},
    track: {},
})(Switch);

const RefreshInterval = (props) => {
    const [keyInterval, setKeyInterval] = useState(0);
    const [dateNow, setDateNow] = useState(Date.now());
    const [isRefreshActived, setIsRefreshActived] = useState(true);

    const handleChangeSwitch = (event) => {
        if (!!event.target.checked) resetCountdown();
        setIsRefreshActived(event.target.checked);
    };

    const resetCountdown = () => {
        setDateNow(Date.now());
        setKeyInterval(keyInterval + 1);
    };

    const handlerOnCompleteCountdown = () => {
        resetCountdown();
        props.fnCallback();
    };

    const miliseconds = (props.seconds || 59) * 1000;
    const { t } = useTranslation();

    return (
        <div className={`refreshInterval ${props.className}`}>
            <span className="refresh-button" onClick={handlerOnCompleteCountdown}>
                <SvgIcon component={IconRefresh} className="iconRefresh" fontSize="large" viewBox="0 0 21 17" />
                <span className="text">{t(Labels.REFRESH_INTERVAL)}</span>
            </span>

            <span className="divider" />

            {isRefreshActived ? (
                <Countdown
                    key={keyInterval}
                    date={dateNow + miliseconds}
                    renderer={({ seconds }) => <span className="text">{seconds}s</span>}
                    intervalDelay={0}
                    precision={3}
                    onComplete={handlerOnCompleteCountdown}
                />
            ) : (
                <span className="text">0s</span>
            )}
            <CustomSwitch
                id="refresh-count"
                size="small"
                checked={isRefreshActived}
                onChange={handleChangeSwitch}
                name="isRefreshActived"
            />
        </div>
    );
};

export default RefreshInterval;
