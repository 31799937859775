import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Card from "~/components/common/expansionCard/Card";
import dashboardFiltersAction from "~/actions/dashboardVehicle/dashboardFiltersAction";
import { Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const CardGrid = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { currentFilters } = useSelector((state) => state.dashboardVehicleToolbar);
    const [filter, setFilter] = useState({});

    const handlerHideCard = (card) => {
        dispatch(dashboardFiltersAction.toggleMarkedFilter(card.name, false));
    };

    useEffect(() => {
        setFilter(currentFilters[props.name]);
    }, [currentFilters, props.name]);

    const extraLabel = () => {
        return (
            <div>
                <span>{t(filter.label)}</span>
                <span
                    style={{
                        position: "absolute",
                        right: 0,
                        marginRight: "37%",
                    }}>
                    {t(filter.extraLabel)}
                </span>
            </div>
        );
    };

    return filter && !!filter.checked ? (
        <>
            <Grid {...props}>
                <Card
                    title={props.noTitle ? null : !filter.extraLabel ? t(filter.label) : extraLabel()}
                    className="card-refactor"
                    notPadded={props.notPadded}
                    topButtons={
                        <IconButton onClick={() => handlerHideCard(filter)}>
                            <CloseIcon />
                        </IconButton>
                    }>
                    {props.children}
                </Card>
            </Grid>
        </>
    ) : null;
};

export default CardGrid;
