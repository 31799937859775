import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import MapCard from "~/components/map/MapCard";
import ExpansionCard from "~/components/common/expansionCard/ExpansionCard";
import ExpansionGrid from "~/components/common/expansionCard/ExpansionGrid";
import ExpansionCardEnum from "~/components/common/expansionCard/ExpansionCardEnum";
import DatePickerFilter from "~/components/common/datepicker/DatePickerFilter";
import MenuRange from "~/components/common/menuRange/MenuRange";
import useFuso from "~/components/common/fuso/useFuso";
import TableCardReUse from "~/components/tableCard/TableCardReUse";
import Loading from "~/components/common/loading/Loading";
import googleMapsDataAction from "~/actions/googleMapsDataAction";
import dashboardTabTravelsActions from "~/actions/dashboardVehicle/dashboardTabTravelsActions";

import moment from "moment";
import "./Travels.scss";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

const ReportResult = ({ mode }) => {
    const dispatch = useDispatch();

    const { vehicle } = useSelector((state) => state.dashboardVehicleFilter);
    const { findTravels } = useSelector((state) => state.app.loading);
    const { travels, positions } = useSelector((state) => state.dashboardVehicleTabTravels);
    const { t } = useTranslation();
    const [vehicleSelect, setVehicleSelect] = useState(25);
    const [dateInterval, setDateInterval] = useState({
        DataFim: moment(new Date().setHours(23, 59, 59)).format("DD-MM-YYYY HH:mm:ss"),
        DataInicio: moment(new Date().setHours(0, 0, 0)).format("DD-MM-YYYY HH:mm:ss"),
    });
    const { fromUtc } = useFuso();

    const [travelsIdentifiers, setTravelsIdentifiers] = useState([]);

    useEffect(() => {
        dispatch(googleMapsDataAction.cleanDirections());

        setTravelsIdentifiers(
            travels.map((t, i) => {
                return {
                    ...t,
                    Id: i,
                };
            })
        );
    }, [dispatch, travels]);

    const COLUMNS = [
        {
            name: "Inicio",
            label: "Data Início",
            render: (date) => (
                <span>
                    {fromUtc(date).format("DD/MM/YYYY")}
                    <span style={{ color: "#AFC3D2", margin: "auto 5px" }}>|</span>
                    {fromUtc(date).format("HH:mm:ss")}
                </span>
            ),
        },
        {
            name: "LocalizacaoInicio",
            label: "Localização Inicial",
        },
        {
            name: "Termino",
            label: t(Labels.REPORT_DISTANCE_FINAL_DATE),
            render: (date) => (
                <span>
                    {fromUtc(date).format("DD/MM/YYYY")}
                    <span style={{ color: "#AFC3D2", margin: "auto 5px" }}>|</span>
                    {fromUtc(date).format("HH:mm:ss")}
                </span>
            ),
        },
        {
            name: "LocalizacaoTermino",
            label: "Localização Final",
        },
    ];

    const selectChangeVehicle = (value) => {
        setVehicleSelect(value);
    };

    useEffect(() => {
        const GridRequest = {
            Index: 0,
            Length: vehicleSelect,
            Search: "",
        };

        dispatch(
            dashboardTabTravelsActions.findTravelsVehicle({
                ...dateInterval,
                ObjetoRastreavelId: vehicle.ObjetoRastreavelId,
                GridRequest,
            })
        );
    }, [dispatch, vehicleSelect, vehicle, dateInterval]);

    useEffect(() => {
        if (!positions) return;

        dispatch(
            googleMapsDataAction.updateDirections([
                {
                    Trajeto: positions.map((p) => {
                        return {
                            latitude: parseFloat(p.Latitude),
                            longitude: parseFloat(p.Longitude),
                            ignition: p.Ignicao === "True",
                            direction: p.Direcao,
                        };
                    }),
                },
            ])
        );

        return () => {
            dispatch(googleMapsDataAction.cleanDirections());
        };
    }, [dispatch, positions]);

    return (
        <div className="contentCard">
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                <DatePickerFilter
                    handleChangeAuto={(nome, event) => {
                        event.DataInicio = event.DataInicial;
                        event.DataFim = event.DataFinal;

                        delete event.DataInicial;
                        delete event.DataFinal;

                        setDateInterval(event);
                    }}
                    style={{ width: "100%", marginRight: "5px" }}
                />
                <MenuRange
                    label={t(Labels.MENU_RANGE_SHOW)}
                    className="menu-range-refactor"
                    value={vehicleSelect}
                    onChange={(event) => selectChangeVehicle(event.target.value)}
                    options={[10, 25, 50, 100, 250, 500]}
                />
            </div>

            {findTravels ? (
                <div id="loading-object">
                    <Loading />
                </div>
            ) : (
                <TableCardReUse
                    dataSource={travelsIdentifiers}
                    mode={mode.orientation}
                    columns={COLUMNS}
                    onSelect={(element) => {
                        dispatch(
                            dashboardTabTravelsActions.findLastPositionsWithoutDataTable({
                                ObjetoRastreavelId: vehicle.ObjetoRastreavelId,
                                DataInicio: fromUtc(element.Inicio).format(),
                                DataFim: fromUtc(element.Termino).format(),
                            })
                        );
                    }}
                />
            )}
        </div>
    );
};

const Travels = () => {
    const [alignment, setAlignment] = useState(ExpansionCardEnum.HORIZONTAL);
    const { t } = useTranslation();

    return (
        <Grid container>
            <ExpansionGrid alignment={alignment} fnClick={setAlignment}>
                <ExpansionCard
                    card={"veículos"}
                    flex={"0 1 30%"}
                    key={"1"}
                    title={t(Labels.RESULTS)}
                    alignment={alignment}
                    className="card-refactor"
                    fnClick={setAlignment}>
                    <ReportResult mode={alignment} />
                </ExpansionCard>
                <ExpansionCard
                    notPadded
                    title={t(Labels.ROUTE_MAP)}
                    card={"mapa"}
                    flex={"0 1 70%"}
                    key={"2"}
                    alignment={alignment}
                    fnClick={setAlignment}
                    className="dashboardVehicleSecondaryTabMap card-refactor">
                    <div className="mapScreen">
                        <MapCard className="map-card-refactor" />
                    </div>
                </ExpansionCard>
            </ExpansionGrid>
        </Grid>
    );
};

export default Travels;
