import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Utils from "~/helpers/Utils";
import fleetActions from "~/actions/fleets/fleetsActions";
import fleetAction from "~/actions/fleetAction";

import Labels from "~/helpers/enums/Labels";

import { Form, Formik } from "formik";
import { Button, CircularProgress, Grid, Typography, Box } from "@material-ui/core";
import Constants from "~/helpers/enums/Constants";

import RegisterInputText from "~/components/common/registerInputs/RegisterInputText";
import Card from "~/components/common/expansionCard/Card";
import CheckboxPGF from "~/components/common/inputs/checkbox/CheckboxPGF";
import Autocomplete from "~/components/common/autocomplete/AutoComplete";
import { CancelIcon, ConfirmIcon } from "~/components/common/icons/Icons";

import "../Fleets.scss";

const RegisterFleets = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { loading } = useSelector((state) => state.app);
    const { data } = useSelector((state) => state.fleet);

    let initialValue = { Nome: "", FleetId: "", Active: false };

    useEffect(() => {
        dispatch(fleetAction.fleetData());
    }, [dispatch]);

    return (
        <Card classTitle="boxTitle-refactor" title={t(Labels.LABEL_CARD_FLEET)}>
            <Formik
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={initialValue}
                validate={(values) => {
                    const errors = {};

                    if (!values.Nome) {
                        errors.Nome = t(Labels.GROUP_DRIVERS_REGISTER_REQUIRED);
                    }

                    return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(false);

                    const body = {
                        name: values.Nome,
                        active: values.Active,
                        parentGroup: values.FleetId.id,
                    };

                    dispatch(
                        fleetActions.saveFleets(body, (_res) => {
                            if (typeof _res === "object") {
                                Utils.showTranslatedToast({
                                    type: Constants.ERROR,
                                    description:
                                        "Não foi possível salvar a frota. Favor entrar em contato com a central.",
                                });
                            } else {
                                Utils.showTranslatedToast({
                                    type: Constants.SUCCESS,
                                    description: _res,
                                });

                                dispatch(
                                    fleetActions.updateFilterFleet({
                                        limit: 10,
                                        page: 0,
                                        status: -1,
                                        search: "",
                                    })
                                );
                                dispatch(fleetAction.fleetData());
                                resetForm();
                            }
                        })
                    );
                }}
                render={({ submitForm, resetForm }) => {
                    return (
                        <Form className="ceabs-register-form fleet-form">
                            <Grid container justify="flex-start" alignItems="flex-end" spacing={1}>
                                <Grid item xs={4}>
                                    <RegisterInputText label={t(Labels.GROUP_DRIVERS_REGISTER_NAME)} name="Nome" />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        name="FleetId"
                                        isRegisterForm
                                        label={t(Labels.REPORT_LABEL_FLEET)}
                                        placeholder={t(Labels.REPORT_LABEL_PLACEHOLDER)}
                                        dataSource={data ? data : []}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Box className="report-field">
                                        <Typography variant="subtitle1" className="report-field-label">
                                            {t(Labels.STATUS_LABEL)}
                                        </Typography>
                                        <CheckboxPGF
                                            color="primary"
                                            id="Active"
                                            name="Active"
                                            label={t(Labels.GROUP_DRIVERS_REGISTER_ACTIVE)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className="boxButton">
                                        <Grid container justify="flex-start" alignItems="flex-end" spacing={1}>
                                            <Grid className="button-form button-form-refactor" item xs={5}>
                                                <Button
                                                    id="cancelButton"
                                                    className="cancel-button cancel-button-refactor"
                                                    size="large"
                                                    fullWidth
                                                    variant="contained"
                                                    disableElevation
                                                    onClick={resetForm}>
                                                    <CancelIcon />
                                                    {t(Labels.VEHICLE_FORM_BUTTON_CANCEL)}
                                                </Button>
                                            </Grid>
                                            <Grid className="button-form button-form-refactor" item xs={7}>
                                                <Button
                                                    id="nextButton"
                                                    className="save-button save-button-refactor"
                                                    size="large"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    disableElevation
                                                    disabled={loading.saveFleets}
                                                    onClick={submitForm}
                                                    startIcon={
                                                        loading.saveFleets ? (
                                                            <CircularProgress size={18} />
                                                        ) : (
                                                            <ConfirmIcon />
                                                        )
                                                    }>
                                                    {t(Labels.SAVE_LABEL)}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            />
        </Card>
    );
};

export default RegisterFleets;
