import React from "react";
import { InputLabel, MenuItem } from "@material-ui/core";
import SelectPopper from "~/components/common/popperComponents/SelectPopper";
import { ArrowDownIcon } from "~/components/common/icons/Icons";
import "./MenuRange.scss";

const MenuRange = (props) => {
    return (
        <div className={`menu-range ${props.className}`} id={props.id}>
            <InputLabel className={props.classNameRefactor} id={`menu-label-${props.id}`}>
                {" "}
                {props.label}{" "}
            </InputLabel>
            <SelectPopper
                variant="outlined"
                margin="dense"
                labelId="label"
                data-id={"select-" + props.id}
                value={props.value}
                onChange={props.onChange}
                IconComponent={ArrowDownIcon}>
                {props.options &&
                    props.options.map((option, idx) => {
                        const value = option.value || option;
                        const label = option.label || option;

                        return (
                            <MenuItem
                                data-id={"menu-range-" + props.id + "-" + idx}
                                key={"menu-range-" + props.id + "-" + idx}
                                className={"selectItem"}
                                value={value}>
                                {label}
                            </MenuItem>
                        );
                    })}
            </SelectPopper>
        </div>
    );
};

export default MenuRange;
