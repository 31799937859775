import React, { useEffect, useCallback } from "react";
import { Box, Paper, Portal } from "@material-ui/core";
import Search from "~/components/common/search/Search";
import CardFleet from "~/components/card/fleet/CardFleet";
import { useDispatch, useSelector } from "react-redux";
import fleetAction from "~/actions/fleetAction";

const TabMapFleet = (props) => {
	const dispatch = useDispatch();

	const { filters } = useSelector((state) => state.mapToolbar); //menu retratil
	const { filter, data } = useSelector((state) => state.fleet);
	const isHorizontal = props.mode.orientation === "horizontal";

	useEffect(() => {
		dispatch(fleetAction.fleetData(filter));
	}, [dispatch, filter]);

	const SearchField = useCallback(
		({ filter: _filter }) => (
			<Box className={`map-tab-header`}>
				<Search
					id="searchFleet"
					className={"search search-input-refactor"}
					style={{ margin: 0 }}
					value={_filter.description}
					search={(term) => {
						dispatch(
							fleetAction.fleetFilterData({
								..._filter,
								description: term,
							})
						);
					}}
				/>
			</Box>
		),
		[dispatch]
	);

	return (
		<Box className={"map-tab floating-header"}>
			<Box
				className={`map-tab-main`}
				style={{
					paddingTop: isHorizontal ? "3rem" : "0",
				}}>
				{isHorizontal ? (
					<SearchField filter={filter} />
				) : (
					<Portal container={props.containerSerach.current}>
						<SearchField filter={filter} />
					</Portal>
				)}
				<Paper elevation={0} style={{ padding: "15px 0" }}>
					<CardFleet id="card-fleet" mode={props.mode} columns={filters} data={data} />
				</Paper>
			</Box>
		</Box>
	);
};

export default TabMapFleet;
