import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Grid, TextField as TextFieldMaterial } from "@material-ui/core";
import InputMask from "react-input-mask";
import Utils from "~/helpers/Utils";
import CpfCnpjUtils from "~/helpers/CpfCnpjUtils";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import userAction from "~/actions/userAction";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import Constants from "~/helpers/enums/Constants";
import Labels from "~/helpers/enums/Labels";
import loginTabAction from "~/actions/loginTabAction";
import "./createAccess.scss";
import { makeStyles } from '@material-ui/core/styles';
import tutorialService from "services/tutorialService";
import { useHistory } from 'react-router-dom';


const CreateAccess = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { document: doc, ...others } = useSelector((state) => state.loginTab.form);
    const [loading, setLoad] = useState(false);
    const history = useHistory();
    const [hasDocumentValid, setHasDocumentValid] = useState(null);
    const [mask, setMask] = useState("");
    const useStyles = makeStyles((theme) => ({
		customTextField: {
		  '& label.Mui-focused': {
			color: '#243782', 
		  },
		  '& .MuiInput-underline:after': {
			borderBottomColor: '#243782', 
		  },
		  '& .MuiOutlinedInput-root': {
			'&.Mui-focused fieldset': {
			  borderColor: '#243782',
			},
		  },
		},
	  }));
    const classes = useStyles();

    useEffect(() => {
        if (doc.replaceAll(/[\s.\-/]/ig, "").length > 11) {
            setMask("99.999.999/9999-99");
        } else {
            setMask("999.999.999-999");
        }
    }, [doc]);

    return (
        <>
            {hasDocumentValid == null ? (
                <Grid container direction="row" spacing={1} className="login-tab-container">
                    <Grid item xs={12}>
                        <Box className="text-form">
                            <InputMask
                                mask={mask}
                                maskChar={""}
                                value={doc}
                                onChange={(event) => {
                                    dispatch(
                                        loginTabAction.updateLoginForm({
                                            ...others,
                                            document: event.target.value,
                                        })
                                    );
                                }}>
                                {() => (
                                    <TextFieldMaterial
                                        id="document"
                                        className="inputFilled"
                                        fullWidth
                                        label={t(Labels.ACCESS_DOCUMENT)}
                                        inputProps={{ maxLength: 150 }}
                                    />
                                )}
                            </InputMask>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Button                            
                            id="login-submit-button"
                            style={{ padding: "4.5pxx 0px", minWidth: 370 ,backgroundColor: '#243782',}}
                            variant="contained"
                            fullWidth
                            color="primary"
                            onClick={() => {
                                setLoad(true);
                                if (doc === "") {
                                    Utils.showTranslatedToast({
                                        type: Constants.ERROR,
                                        description: Labels.ACCESS_DOCUMENT_REQUIRED,
                                    });
                                } else {
                                    dispatch(
                                        userAction.validateAccess(CpfCnpjUtils.cleanMask(doc), (isError, res) => {
                                           if(true) {
                                                setLoad(false);
                                                setHasDocumentValid(res);
                                            }
                                            else if (isError) {
                                                Utils.showAllErrors(res);
                                            } else if (res.error) {
                                                Utils.showTranslatedToast({
                                                    type: Constants.ERROR,
                                                    description: res.msg,
                                                });
                                            } else {
                                                setLoad(false);
                                                setHasDocumentValid(res);
                                            }
                                        })
                                    );
                                }
                            }}
                            size="small"
                            disabled={loading.validateAccess}
                            disableElevation
                            startIcon={
                                loading.validateAccess ? (
                                    <CircularProgress
                                        style={{ height: 14, width: 14, marginRight: 8 }}
                                        color={"#fff"}
                                    />
                                ) : null
                            }>
                            {t(Labels.ACCESS_VALIDATE_ACCESS)}
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Formik
                        initialValues={{
                            Login: "",
                            Senha: "",
                            ConfirmaSenha: "",
                            Email: "",
                        }}
                        validate={(values) => {
                            const errors = {};

                            if (!values.Login) {
                                errors.Login = t(Labels.ACCESS_USER_REQUIRED);
                            } else if (!Utils.isValidLogin(values.Login)) {
                                errors.Login = t(Labels.ACCESS_USER_INVALID);
                            }

                            if (!values.Senha) {
                                errors.Senha = t(Labels.ACCESS_PASSWORD_REQUIRED);
                            } else if (!Utils.isValidPassword(values.Senha)) {
                                errors.Senha = t(Labels.ACCESS_PASSWORD_STRONG);
                            }

                            if (!values.ConfirmaSenha) {
                                errors.ConfirmaSenha = t(Labels.ACCESS_CONFIRM_PASSWORD_REQUIRED);
                            } else if (values.ConfirmaSenha !== values.Senha) {
                                errors.ConfirmaSenha = t(Labels.ACCESS_CONFIRM_PASSWORD_CONFIRM);
                            }

                            if (!values.Email) {
                                errors.Email = t(Labels.ACCESS_MAIL_REQUIRED);
                            } else if (!Utils.isValidEmail(values.Email)) {
                                errors.Email = t(Labels.ACCESS_MAIL_INVALID);
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);

                            dispatch(
                                userAction.createAccess(
                                    {
                                        Login: values.Login,
                                        Senha: values.Senha,
                                        Email: values.Email,
                                        ...hasDocumentValid,
                                    },
                                    (isError, msg) => {
                                        Utils.showTranslatedToast({
                                            type: isError ? Constants.ERROR : Constants.SUCCESS,
                                            description: isError ? Labels.ACCESS_MSG_ERROR : Labels.ACCESS_MSG_SUCCESS,
                                        });

                                        if (!isError) {
                                            dispatch(loginTabAction.resetForm());
                                            setHasDocumentValid(null);
                                            history.push('/login');
                                        }
                                    }
                                )
                            );
                        }}>
                        {(form) => {
                            return (
                                <Form>
                                    <Grid container direction="row" spacing={1} className="login-tab-container">
                                        <Grid item xs={12}>
                                            <Field
                                                name="Login"
                                                label={t(Labels.ACCESS_USER)}
                                                component={TextField}
                                                className={classes.customTextField}  
                                                type="text"
                                                fullWidth
                                                margin="dense"
                                                inputProps={{ maxLength: 150 }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field
                                                name="Senha"
                                                label={t(Labels.ACCESS_PASSWORD)}
                                                component={TextField}
                                                className={classes.customTextField}  
                                                type="password"
                                                fullWidth
                                                margin="dense"
                                                inputProps={{ maxLength: 150 }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field
                                                name="ConfirmaSenha"
                                                label={t(Labels.ACCESS_CONFIRM_PASSWORD)}
                                                component={TextField}
                                                className={classes.customTextField}  
                                                type="password"
                                                fullWidth
                                                margin="dense"
                                                inputProps={{ maxLength: 150 }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field
                                                name="Email"
                                                className={classes.customTextField}  
                                                component={TextField}
                                                type="text"
                                                fullWidth
                                                margin="dense"
                                                label={t(Labels.ACCESS_MAIL)}
                                                inputProps={{ maxLength: 150 }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box className="button-form">
                                                <Button
                                                    id="create-accesss-submit-button"
                                                    style={{backgroundColor: '#243782',}}
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    fullWidth
                                                    disableElevation
                                                    onClick={form.submitForm}
                                                    disabled={props.loading}
                                                    startIcon={props.loading ? <CircularProgress size={18} /> : null}>
                                                    {t(Labels.ACCESS_CREATE_ACCESS)}
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}></Grid>

                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>

                    <Grid container>
                        <Grid item xs={6}>
                            <ul className="sugestions">
                                <li className="noBullet">{t(Labels.ACCESS_SUGESTIONS_DESCRIPTION)}</li>
                                <li>{t(Labels.ACCESS_SUGESTIONS_1)}</li>
                                <li>{t(Labels.ACCESS_SUGESTIONS_2)}</li>
                            </ul>
                        </Grid>
                        <Grid item xs={6}>
                            <ul className="sugestions">
                                <li>{t(Labels.ACCESS_SUGESTIONS_3)}</li>
                                <li>{t(Labels.ACCESS_SUGESTIONS_4)}</li>
                                <li>{t(Labels.ACCESS_SUGESTIONS_5)}</li>
                            </ul>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default CreateAccess;
