import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import { lighten } from 'polished';

const useStyles = makeStyles((theme) => ({
  cardStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flex: 1,
    padding: 0,
    height: '35px'
  },
  withBorder: {
    borderBottom: `1px solid ${lighten(0.1, '#AFC3D2')}`
  }
}));

function CardItem(props) {
  const classes = useStyles();

  return (
    <>
      <ListItem style={{ padding: 0 }}>
        <div className={props.border ? `${classes.cardStyle} ${classes.withBorder}` : classes.cardStyle}>
          {props.children}
        </div>
      </ListItem>
    </>
  );
}

export default CardItem;
