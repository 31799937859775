import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import Utils from "~/helpers/Utils";

import pointsInterestAction from "~/actions/pointsInterestAction";
import googleMapsDataAction from "~/actions/googleMapsDataAction";

import { Box } from "@material-ui/core";

import Card from "~/components/common/expansionCard/Card";
import Search from "~/components/common/search/Search";
import MenuRange from "~/components/common/menuRange/MenuRange";
import TableCard from "~/components/tableCard/TableCardReUse";
import Loading from "~/components/common/loading/Loading";
import ModalCommon from "~/components/common/modal/Modal";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import { ColoredEditIcon, IconDelete } from "~/components/common/icons/Icons";

import "./PointsGrid.scss";

const PointsGrid = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    let history = useHistory();

    const loading = useSelector((state) => state.app.loading);
    const { pagination, data, locationObjectsMaps } = useSelector((state) => state.pointsInterest.pointInterestList);
    const { savedPointInterest } = useSelector((state) => state.pointsInterest);
    const { mapContext } = useSelector((state) => state.googleMapsData);

    const [IdrowDelete, setIdRowDelete] = useState();
    const [page, setPage] = useState(1);
    const [itemsPage, setQtdItems] = useState(25);
    const [search, setSearch] = useState("");
    const [modal, setFlagModal] = useState(false);
    const [pointsList, setListPoints] = useState([]);

    const POINTS_COLUMNS = [
        {
            name: "Id",
            label: t(Labels.GROUP_POINTS_COLUMN_CODE),
            render: (code) => <span>{code}</span>,
        },
        {
            name: "Nome",
            label: t(Labels.REGISTER_POINT_INTEREST_FORM_NAME),
            render: (name) => <strong title={name}>{Utils.TextView(name, 25)}</strong>,
        },
        {
            name: "TipoPonto",
            label: t(Labels.REGISTER_POINT_INTEREST_FORM_FORM_TYPE),
            render: (point) => <span>{point}</span>,
        },
        {
            name: "Id",
            label: t(Labels.GROUP_POINTS_COLUMN_ACTIONS),
            render: (Id) => (
                <span className="routes-action-column">
                    <span
                        data-id={"editar-item-tabela-pontos-" + Id}
                        onClick={() => {
                            history.push(`/register/pointsInterest/${Id}`);
                        }}>
                        <ColoredEditIcon
                            style={{
                                strokeWidth: "30px",
                                color: "#6ec8af",
                                marginLeft: -10,
                            }}
                        />
                    </span>
                    <span
                        data-id={"deletar-item-tabela-pontos-" + Id}
                        style={{ marginRight: 10 }}
                        onClick={() => {
                            setIdRowDelete(Id);
                            setFlagModal(true);
                        }}>
                        <IconDelete style={{ height: 17, width: "auto", marginBottom: 5, marginLeft: 5 }} />
                    </span>
                </span>
            ),
        },
    ];

    useEffect(() => {
        if (savedPointInterest.TipoPonto === "Ponto") {
            var circ = new window.google.maps.Circle();
            circ.setRadius(savedPointInterest.Raio);
            circ.setCenter({
                lat: savedPointInterest.Latitude,
                lng: savedPointInterest.Longitude,
            });

            mapContext.fitBounds(circ.getBounds());
       
        } else if (savedPointInterest.TipoPonto === "Poligono") {
            dispatch(
                googleMapsDataAction.centralizePoints(Utils.getLatLngPathsFromString(savedPointInterest.Coordenadas))
            );
        }
    }, [dispatch, mapContext, savedPointInterest]);

    const selectChange = (value) => {
        setPage(1);
        setQtdItems(value);

        const query = {
            page: 0,
            limit: value,
            search: search,
        };

        dispatch(pointsInterestAction.pointsInterestList({ ...query }));
    };

    const getObjectsMaps = (lat, long) => {
        const query = {
            latitude: lat,
            longitude: long,
        };
        dispatch(pointsInterestAction.getObjectsPerPoints({ ...query }));
    };

    useEffect(() => {
        const query = {
            page: 0,
            limit: 25,
            search: "",
        };
        dispatch(pointsInterestAction.pointsInterestList({ ...query }));
    }, [dispatch]);

    useEffect(() => {
        let object;
        object = locationObjectsMaps?.map((item, i) => {
            return (object = {
                ...item,
                Latitude: item.Lat,
                Longitude: item.Long,
            });
        });

        dispatch(googleMapsDataAction.updateObjects(object));
    }, [dispatch, locationObjectsMaps]);

    useEffect(() => {
        dispatch(googleMapsDataAction.cleanPoints());
        dispatch(googleMapsDataAction.cleanObjects());
    }, [dispatch]);

    const mountObject = (data) => {
        let object;
        object = data?.map((item, i) => {
            let dataSplit = item.Coordenadas?.split(/[\s,]+/);
            return (object = {
                ...item,
                type: item.Coordenadas ? "polygon" : "ponto",
                value: item.Coordenadas
                    ? item.Coordenadas
                    : {
                          latitude: item.Latitude,
                          longitude: item.Longitude,
                          radius: item.Raio,
                      },
                Latitude: item.Coordenadas ? dataSplit[0] : item.Latitude,
                Longitude: item.Coordenadas ? dataSplit[dataSplit.length - 1] : item.Longitude,
            });
        });
        return object;
    };

    const updatePoints = (points) => {
        dispatch(googleMapsDataAction.updatePoints(points));
        if (points.length === 1) {
            getObjectsMaps(points[0].Latitude, points[0].Longitude);
        } else {
            dispatch(googleMapsDataAction.cleanObjects());
        }
    };

    const setPointsInMap = (item, data, checked, id) => {
        let pointList;

        if (!Array.isArray(data)) {
            let point = [...pointsList, data];
            setListPoints(point);
            pointList = mountObject(point);
        } else {
            setListPoints(data);
            pointList = mountObject(data);
        }

        if (checked) {
            let points = pointList.filter(function (e) {
                return e.Id !== id;
            });

            if (id === "all") points = [];

            setListPoints(points);
            updatePoints(points);
            return;
        }

        updatePoints(pointList);
    };

    return (
        <Card title={t(Labels.GROUP_POINTS_COLUMN_POINTS)} className={"cardListObject"}>
            <div className={"spaceSearch"}>
                <div className="searchPoints">
                    <Search
                        id={"search-title"}
                        search={(term) => {
                            setPage(1);
                            setSearch(term);

                            const query = {
                                page: 0,
                                limit: itemsPage,
                                search: term,
                            };
                            dispatch(pointsInterestAction.pointsInterestList({ ...query }));
                        }}
                    />
                </div>
                <MenuRange
                    label={t(Labels.MENU_RANGE_SHOW)}
                    value={itemsPage}
                    classNameRefactor="menu-label-range-refactor"
                    onChange={(event) => selectChange(event.target.value)}
                    options={[10, 25, 50, 100, 250, 500]}
                />
            </div>
            <Box className="resultGridPoints">
                {loading.requestGridPointsInterest ? (
                    <Loading size={30} />
                ) : (
                    <TableCard
                        align="start"
                        checkBox
                        rowSelection={{
                            refColName: "Id",
                            onChange: (item, data, checked, id) => {
                                setPointsInMap(item, data, checked, id);
                            },
                        }}
                        columns={POINTS_COLUMNS}
                        dataSource={data}
                        backgroundChanged={props.backgroundChanged}
                        page={page}
                        itemsPerPage={itemsPage}
                        totalItems={pagination.recordsTotal}
                        handlePage={(event, value) => {
                            setPage(value);

                            const query = {
                                page: value * 1 - 1,
                                limit: itemsPage,
                                search: search,
                            };

                            dispatch(pointsInterestAction.pointsInterestList({ ...query }));
                        }}
                    />
                )}
            </Box>
            <ModalCommon
                title={t(Labels.CONFIRM_EXCLUDE)}
                confirmDelete={() => {
                    dispatch(
                        pointsInterestAction.pointsInterestDelete(IdrowDelete, () => {
                            setPage(1);
                            setSearch("");

                            const query = {
                                page: 0,
                                limit: 25,
                                search: "",
                            };
                            dispatch(pointsInterestAction.pointsInterestList({ ...query }));
                        })
                    );
                    setFlagModal(false);
                }}
                handleClose={() => {
                    setFlagModal(false);
                }}
                flag={modal}
            />
        </Card>
    );
};

export default PointsGrid;
