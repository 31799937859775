import Constants from "~/helpers/enums/Constants";

const initialState = {
	fleet: [],
	fleetsData: [],
	groupFleets: [],
	availableCarsFleets: [],
	notAvailableCarsFleets: [],
	filter: {
		limit: 10,
		page: 0,
		status: -1,
	},
};

function fleetsReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.GET_DATA_FLEETS_LIST:
			return {
				...state,
				fleetsData: action.payload.data,
			};
		case Constants.SELECT_FLEETS_DATA:
			return {
				...state,
				fleet: action.fleet,
			};
		case Constants.GET_DATA_GROUP_FLEETS:
			return {
				...state,
				groupFleets: action.payload.data,
			};
		case Constants.GET_DATA_FLEETS_OBJ_AVAILABLE:
			return {
				...state,
				availableCarsFleets: action.payload.data,
			};
		case Constants.GET_DATA_FLEETS_OBJ_NOT_AVAILABLE:
			return {
				...state,
				notAvailableCarsFleets: action.payload.data,
			};
		case Constants.CLEAN_CARS_LINKED_FOR_FLEETS:
			return {
				...state,
				notAvailableCarsFleets: initialState.notAvailableCarsFleets,
			};
		case Constants.CLEAN_AVAILABLE_CARS_FOR_FLEETS:
			return {
				...state,
				availableCarsFleets: initialState.availableCarsFleets,
			};
		case Constants.UPDATE_REGISTER_FLEET_FILTER:
			return {
				...state,
				filter: action.payload,
			};
		default:
			return state;
	}
}

export default fleetsReducer;
