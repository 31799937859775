import React from "react";

import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

import { useHistory } from "react-router-dom";

import { Container, Grid, Button } from "@material-ui/core";

import ImportDriver from "~/components/drivers/import/ImportDriver";
import Page from "~/components/common/page/Page";

import { ComeBackIcon } from "~/components/common/icons/Icons";

import "./ImportDriver.scss";

const url_back = "/register/drivers/list";

const Import = (props) => {
	const { t } = useTranslation();
	let history = useHistory();

	return (
		<>
			<Page
				topButtons={
					<Button
						className="comeback-button comeback-top"
						id="extractReport"
						variant="contained"
						size="small"
						disableElevation
						startIcon={<ComeBackIcon />}
						onClick={() => {
							history.push(url_back);
						}}>
						{t(Labels.REGISTER_DRIVER_COMEBACK)}
					</Button>
				}
				bottomStyle={{ justifyContent: "flex-end" }}
				bottomButtons={
					<Button
						className="comeback-button"
						id="extractReport"
						variant="contained"
						size="small"
						disableElevation
						startIcon={<ComeBackIcon />}
						onClick={() => {
							history.push(url_back);
						}}>
						{t(Labels.REGISTER_DRIVER_COMEBACK)}
					</Button>
				}>
				<main>
					<Container maxWidth="lg" className="container-map-view">
						<Grid container>
							<Grid id="driver-import-view-grid" item xs={12}>
								<div>
									<ImportDriver />
								</div>
							</Grid>
						</Grid>
					</Container>
				</main>
			</Page>
		</>
	);
};

export default Import;
