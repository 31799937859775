import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadingPanel from "~/components/common/loading/LoadingPanel";
import AlertForm from "~/components/alert/register/AlertForm";
import alertAction from "~/actions/registerAlertAction";
import { Box, Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Labels from "~/helpers/enums/Labels";
import { WhiteVehicleIcon, CloseSvgIcon } from "~/components/common/icons/Icons";
import { useTranslation } from "react-i18next";

import "./EditAlert.scss";

const EditAlert = ({ closeTab }) => {
	const dispatch = useDispatch();
	let history = useHistory();
	const { t } = useTranslation();

	const { loading } = useSelector((state) => state.app);
	const {
		alert,
		editForm,
		conditions,
		actions: { acoes },
	} = useSelector((state) => state.alert);

	useEffect(() => {
		if (!editForm || !editForm.current) return;
		const values = {
			...alert.Alerta,
			AlertasAcoes:
				(alert.AlertasAcoes &&
					alert.AlertasAcoes.map((ac) => {
						const acao = acoes.find((c) => c.Id === ac.Codigo) || {};
						return {
							...ac,
							Nome: acao.Nome,
						};
					})) ||
				[],
			AlertasCondicoes:
				(alert.AlertasCondicoes &&
					alert.AlertasCondicoes.map((ac) => {
						const cond = conditions.find((c) => c.Id === ac.Codigo) || {};
						return {
							...ac,
							Nome: cond.Nome,
						};
					})) ||
				[],
		};

		Object.entries(values).forEach(([key, value]) => {
			editForm.current.setFieldValue(key, value);
		});
	}, [alert, acoes, conditions, editForm]);

	return (
		<Box className="edit-alert">
			<Grid container direction="row" spacing={2}>
				<Grid item xs={4}>
					<Button
						startIcon={<WhiteVehicleIcon />}
						color="primary"
						size="large"
						fullWidth
						disableElevation
						className="top-button"
						variant="contained"
						onClick={() => {
							history.push(`/register/alerts/bond/${alert.Alerta.Id}`);
						}}>
						{t(Labels.ALERT_EDIT_BUTTON_BOND)}
					</Button>
				</Grid>
				<Grid item xs={4}>
					<Button
						startIcon={<CloseSvgIcon />}
						size="large"
						fullWidth
						disableElevation
						className="top-button"
						variant="contained"
						onClick={() => {
							dispatch(alertAction.deactivateAlert(alert.Alerta.Id));
							closeTab();
						}}>
						{t(Labels.ALERT_EDIT_BUTTON_DEACTIVE)}
					</Button>
				</Grid>

				<Grid item xs={12}>
					<AlertForm
						compact
						updateAction={alertAction.updateEditForm}
						comeBack={() => {
							closeTab();
						}}
					/>
				</Grid>
			</Grid>
			{loading.alert && <LoadingPanel size={40} />}
		</Box>
	);
};

export default EditAlert;
