import React from "react";
import Card from "~/components/common/expansionCard/Card";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Grow, Box } from "@material-ui/core";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import AlertCards from "~/components/alert/register/AlertCards";
import AlertForm from "~/components/alert/register/AlertForm";
import ListAlerts from "~/components/alert/listing/ListingAlerts";
import alertAction from "~/actions/registerAlertAction";

const AlertMain = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { openIsertForm } = useSelector((state) => state.alert);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <AlertCards />
            </Grid>
            <Grow in={openIsertForm}>
                <Grid item xs={12} hidden={!openIsertForm}>
                    <Box className="card-container">
                        <Card classTitle="boxTitle-refactor" title={t(Labels.ALERT_FORM_TITLE)}>
                            <AlertForm
                                updateAction={alertAction.updateInserForm}
                                comeBack={() => {
                                    dispatch(alertAction.setOpenInsertForm(false));
                                    dispatch(
                                        alertAction.updateAlertFilter({
                                            limit: 10,
                                            status: "Ativo",
                                            search: "",
                                        })
                                    );
                                }}
                            />
                        </Card>
                    </Box>
                </Grid>
            </Grow>
            <Grid item xs={12}>
                <Box className="card-container">
                    <ListAlerts />
                </Box>
            </Grid>
        </Grid>
    );
};

export default AlertMain;
