import Constants from "~/helpers/enums/Constants";

const PAGINATION = {
	index: 0,
	recordsFiltered: 0,
	recordsTotal: 0,
};

const initialState = {
	groups: {
		data: [],
	},
	deleteGroup: [],
	driversLinked: {
		...PAGINATION,
		data: [],
	},
	availableDrivers: {
		...PAGINATION,
		data: [],
	},
};

function groupDriversReducer(state = initialState, action = null) {
	const { payload } = action;
	switch (action.type) {
		case Constants.DELETE_GROUP_DRIVERS_BY_ID:
			return {
				...state,
				deleteGroup: payload,
			};
		case Constants.GET_GROUPS_DRIVERS:
			return {
				...state,
				groups: payload,
			};
		case Constants.GET_DRIVERS_LINKED_GROUP:
			return {
				...state,
				driversLinked: payload,
			};
		case Constants.GET_AVAILABLE_DRIVERS_FOR_GROUP:
			return {
				...state,
				availableDrivers: payload,
			};
		case Constants.CLEAN_DRIVERS_LINKED_GROUP:
			return {
				...state,
				driversLinked: initialState.driversLinked,
			};
		case Constants.CLEAN_AVAILABLE_DRIVERS_FOR_GROUP:
			return {
				...state,
				availableDrivers: initialState.availableDrivers,
			};
		default:
			return state;
	}
}

export default groupDriversReducer;
