// REGISTER
import http from "~/config/httpRequest/http";

const searchAlertList = (filter) => {
    return new Promise((resolve, reject) => {
        http.post(`/Alertas/Buscar`, {
            Status: filter.status,
            GridRequest: {
                Index: filter.page,
                Length: filter.limit,
                Search: filter.search,
                SearchColumns: filter.SearchColumns,
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const saveAlert = (alert) => {
    return new Promise((resolve, reject) => {
        http.post(`/Alertas/Salvar/`, {
            ...alert,
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const saveAlertBond = (alert) => {
    return new Promise((resolve, reject) => {
        http.post(`/Alertas/SalvarObjetosVinculados`, alert)
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const getAlertHistory = (id) => {
    return new Promise((resolve, reject) => {
        http.get(`/Alertas/BuscarHistorico`, {
            params: {
                alertaId: id,
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const getAlertByHistoryId = (id) => {
    return new Promise((resolve, reject) => {
        http.get(`/Alertas/BuscarHistoricoPorId`, {
            params: {
                alertaId: id,
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const getAlertById = (id) => {
    return new Promise((resolve, reject) => {
        http.get(`/Alertas/BuscarPorId`, {
            params: {
                alertaId: id,
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const searchAlertLinked = (filter) => {
    return new Promise((resolve, reject) => {
        http.post(`/Alertas/PesquisarObjetosVinculados`, {
            grupoId: filter.grupoId,
            AlertaId: filter.AlertaId,
            Vinculado: filter.Vinculado,
            GridRequest: {
                Index: filter.GridRequest.Index,
                Length: filter.GridRequest.Length,
                Search: filter.GridRequest.Search,
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const getAlertsConditons = () => {
    return new Promise((resolve, reject) => {
        http.get(`/Alertas/BuscarCondicoes`)
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const getAlertsActions = () => {
    return new Promise((resolve, reject) => {
        http.get(`/Alertas/BuscarAcoes`)
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const downloadFile = () => {
    return new Promise((resolve, reject) => {
        http.get(`/Alertas/ObterPlanilhaAlertas`)
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const importCarsAlerts = (filter) => {
    return new Promise((resolve, reject) => {
        http.post(`/Alertas/ImportarObjetosParaAlerta`, {
            AssocId: filter?.alert?.id,
            RemoverObjetos: filter?.removeObj,
            Documento: {
                FileName: filter?.file?.fileName,
                File: filter?.file?.fileData,
                //LocalFilePath: null,
                //FileLength: filter?.file?.size,
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const importAlertReport = (filter) => {
    return new Promise((resolve, reject) => {
        http.post(`/RelatorioAlertas/Buscar`, {
            ...filter,
            GridRequest: {
                Index: filter?.GridRequest?.Index,
                Length: filter?.GridRequest?.Length,
                SearchColumns: filter?.GridRequest?.SearchColumns.map((values) => ({
                    Name: values.Name,
                    Data: values.Data,
                    OrderTable: values.OrderTable,
                    OrderDirection: values.OrderDirection,
                })),
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const deactivateAlert = (id) => {
    return new Promise((resolve, reject) => {
        http.delete(`/Alertas/Excluir`, {
            params: {
                alertaId: id,
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const exportAlertGet = (filter) => {
    return new Promise((resolve, reject) => {
        http.get(`/ExportarDocumentos/ExportarAlertaViolacoesPDFOUExcel`, {
            params: {
                ...filter
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};
const exportAlert = (filter) => {
    return new Promise((resolve, reject) => {
        http.post(`/Alertas/Exportar`, {
            ...filter,
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

const getNewAlertPopup = () => {
    return new Promise((resolve, reject) => {
        http.get(`/Alertas/ListarAlertasPopUp?`, {
            params: {
                ultimaNotificacao: new Date().toISOString(),
            },
        })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};

export default {
    saveAlert,
    getAlertById,
    searchAlertList,
    downloadFile,
    importCarsAlerts,
    importAlertReport,
    exportAlertGet,
    getAlertHistory,
    getAlertByHistoryId,
    getAlertsConditons,
    getAlertsActions,
    searchAlertLinked,
    saveAlertBond,
    deactivateAlert,
    exportAlert,
    getNewAlertPopup,
};
