import React, { useEffect, useRef } from "react";
import { Form, Formik } from "formik";
import { Box, Button, CircularProgress, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import eventReportAction from "~/actions/reporting/eventReportAction";
import Correct from "~/assets/icons/corrigir.svg";
import ReportPeriodField from "~/components/reporting/common/ReportPeriodField";
import VehicleAutocomplete from "~/components/reporting/common/vehicle/VehicleAutocomplete";
import Labels from "~/helpers/enums/Labels";
import Utils from "~/helpers/Utils";
import "../../common/ReportingCommon.scss";
import "./Object.scss";

const FiltersObject = () => {
    const dispatch = useDispatch();
    const { requestObject } = useSelector((state) => state.app.loading);

    const objectForm = useRef();

    const { t } = useTranslation();

    useEffect(() => {
        dispatch(eventReportAction.updateObjectForm(objectForm.current));
    }, [dispatch, objectForm]);

    return (
        <>
            <Formik
                innerRef={objectForm}
                initialValues={{
                    vehicle: null,
                    speed: 0,
                    period: Utils.getTodayPeriod(),
                    index: 0,
                    limit: 25,
                    typeReport: 22,
                }}
                validate={(values) => {
                    const errors = {};

                    if (!values.vehicle) {
                        errors.vehicle = t(Labels.REPORT_EVENT_VEHICLE_MSG_OBLIGATED_WITHOUT_OR);
                    }

                    if (!values.period) {
                        errors.period = t(Labels.REPORT_EVENT_PERIOD_MSG_OBLIGATED);
                    }

                    if (values.period && Utils.diffInDays(values.period.endDate, values.period.startDate) > 7) {
                        errors.period = t(Labels.REPORT_EVENT_PERIOD_MSG_PERIOD_WEEK);
                    }

                    if (!!values.speed && values.speed < 0) {
                        errors.speed = t(Labels.REPORT_EVENT_PERIOD_MSG_NOT_VALID);
                    }

                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    dispatch(
                        eventReportAction.updateFilterEventObject({
                            ...values,
                            period: {
                                startDate: Utils.utcToLocal(values.period.startDate),
                                endDate: Utils.utcToLocal(values.period.endDate),
                            },
                        })
                    );
                    setSubmitting(false);
                }}
                render={({ submitForm, setFieldValue, handleChange }) => {
                    return (
                        <Form id="formFilterObject">
                            <Grid container justify="flex-start" alignItems="flex-end" spacing={1}>
                                <Grid item xs={3}>
                                    <VehicleAutocomplete
                                        id="vehicle"
                                        name="vehicle"
                                        label={t(Labels.REPORT_EVENT_VEHICLE_LABEL)}
                                        placeholder={t(Labels.REPORT_EVENT_VEHICLE_PLACEHOLDER)}
                                    />
                                </Grid>

                                
                                <Grid item xs={3}>
                                    <Box className="report-autocomplete-field">
                                        <ReportPeriodField
                                            dontShowMonthRanges={true}
                                            id="id-period-date"
                                            label="Período"
                                            name="period"
                                            hours
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button
                                        id="report-confirm-button"
                                        className="report-save-button report-save-button-refactor"
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        fullWidth
                                        disableElevation
                                        onClick={submitForm}
                                        disabled={requestObject}
                                        startIcon={
                                            requestObject ? (
                                                <CircularProgress
                                                    style={{ height: 14, width: 14, marginRight: 8 }}
                                                    color={"#fff"}
                                                />
                                            ) : (
                                                <img style={{ height: "14px" }} src={Correct} alt="iconCorrect" />
                                            )
                                        }>
                                        Confirmar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            />
        </>
    );
};
export default FiltersObject;
