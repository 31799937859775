import Constants from "~/helpers/enums/Constants";

const initialState = {
	dataVehicle: [],
};

function vehicleSearchReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.GET_DATA_VEHICLE:
			return {
				...state,
				dataVehicle: action.payload.data,
			};

		default:
			return state;
	}
}

export default vehicleSearchReducer;
