import Constants from "~/helpers/enums/Constants";

const initialState = {
	filter: {
		code: "",
		name: "",
		active: "",
		page: 0,
		limit: 10,
	},
	systems: [],
	system: {},
	pages: {
		systems: [],
		total: null,
		totalPages: 0,
	},
};

function systemReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.SEARCH_PAGED_SYSTEMS:
			return {
				...state,
				pages: {
					systems: action.payload.systems,
					total: action.payload.total,
					totalPages: action.payload.totalPages,
				},
			};
		case Constants.UPDATE_SYSTEM_FILTER:
			return {
				...state,
				filter: {
					...state.filter,
					...action.payload.filter,
				},
			};
		default:
			return state;
	}
}

export default systemReducer;
