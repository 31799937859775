import React, { useState } from "react";
import FileTypeEnum from "~/helpers/enums/FileTypeEnum";
import ExportButton from "./ExportButton";
import MenuPopper from "~/components/common/popperComponents/MenuPopper";
import { MenuItem, ListItemIcon } from "@material-ui/core";
import { PdfIcon, ExcelIcon } from "~/components/common/icons/Icons";

const OptionExportButton = ({ id, onClick, ...other }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const mountIcon = (value) => {
        switch (value) {
            case FileTypeEnum.PDF.value:
                return <PdfIcon style={{ marginRight: 7 }} />;
            case FileTypeEnum.XLS.value:
                return <ExcelIcon style={{ marginRight: 7 }} />;
            default:
                return <PdfIcon />;
        }
    };

    return (
        <>
            <ExportButton
                {...other}
                id={`extractReportPoints-${id}`}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                }}
            />
            <MenuPopper
                id={`export-types-menu-${id}`}
                keepMounted={false}
                anchorEl={anchorEl || {}}
                open={!!anchorEl}
                onClose={() => {
                    setAnchorEl(null);
                }}>
                {FileTypeEnum.LIST.map((type) => (
                    <MenuItem
                        data-id={`arquivo-id-${type.description}`}
                        key={type.description}
                        className="export-menu-item"
                        value={type.description}
                        onClick={() => {
                            onClick(type);
                        }}>
                        <ListItemIcon>{mountIcon(type.value)}</ListItemIcon>
                        {type.description}
                    </MenuItem>
                ))}
            </MenuPopper>
        </>
    );
};

export default OptionExportButton;
