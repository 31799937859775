import http from '~/config/httpRequest/http';

const listVehicleID = (filter) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/Geolocalizacaoapi/ListarRastreaveisPorGrupo`, {
        params: {
          GrupoId: filter.id
        }
      })
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
};

export default { listVehicleID };
