import Constants from "~/helpers/enums/Constants";

const initialState = {
    profile: null,
    fusos: [],
};

function profileReducer(state = initialState, action = null) {
    switch (action.type) {
        case Constants.GET_PROFILE:
            return {
                ...state,
                profile: action.payload,
            };
        case Constants.GET_FUSOS_BY_COUNTRY:
            return {
                ...state,
                fusos: action.payload,
            };
        case Constants.CLEAN_PROFILE:
            return {
                ...state,
                profile: initialState.profile,
            };
        default:
            return state;
    }
}

export default profileReducer;
