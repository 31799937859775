import Constants from "~/helpers/enums/Constants";

const initialState = {
	data: [],
};

function driverReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.LIST_GROUP_DRIVER:
			return {
				...state,
				data: action.payload.data,
			};
		case Constants.IMPORT_FILE_DRIVER:
			return {
				...state,
				msg: action.payload.data,
			};
		default:
			return state;
	}
}

export default driverReducer;
