import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import fleetVehicleService from "~/services/fleetVehicleService";
import moment from "moment";

const fleetData = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "fleetData") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return fleetVehicleService
		.listVehicleID(filter)
		.then((response) => {
			let data = response.data.Lista;

			data.forEach(item => {
				item.DataHoraEventoLocal = item.DataHoraEventoLocal ? moment(item.DataHoraEventoLocal, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss") : item.DataHoraEventoLocal;
			})

			let payload = {
				filter: filter,
				data: data,
			};

			dispatch({ type: Constants.FLEET_GET_VEHICLE_DATA, payload });
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

export default {
	fleetData,
};
