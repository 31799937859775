import React from "react";
import { Button, Box } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";

const ModalCommon = (props) => {
	const { t } = useTranslation();

	return (
		<div>
			<Dialog  onClose={props.handleClose} varia-labelledby="simple-dialog-title" open={props.flag}>
				<DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
				<Box style={{ display: "flex" , justifyContent: 'flex-end'}}>
					<Button onClick={props.confirmDelete}>{t(Labels.YES)}</Button>
					<Button onClick={props.handleClose}>{t(Labels.NO)}</Button>
				</Box>
			</Dialog>
		</div>
	);
};

export default ModalCommon;
