import Constants from "~/helpers/enums/Constants";

import addressService from "~/services/addressService";

const addressGetData = (filter) => (dispatch) => {
	return addressService
		.list(filter)
		.then((response) => {
			let data = response.data;

			let payload = {
				data: data,
			};

			dispatch({ type: Constants.ADDRESS_GET_DATA, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {});
};

const updateAddressDataFilter = (filter) => (dispatch) => {
	dispatch({ type: Constants.ADDRESS_DATA_FILTER, payload: { filter } });
};

export default {
	addressGetData,
	updateAddressDataFilter,
};
