import Constants from "~/helpers/enums/Constants";
import ReportTypeEnum from "~/helpers/enums/ReportTypeEnum";
import reportingService from "~/services/reportingService";
import Utils, { showError } from "~/helpers/Utils";

const SPEEDING_REPORT_ID = ReportTypeEnum.SPEEDING_ON_ROAD.id;

const getSpeedingReportScheduling = (params) => (dispatch) => {
	return reportingService
		.getReportSchedulingByType(params, SPEEDING_REPORT_ID)
		.then((response) => {
			const { data } = response.data;
			dispatch({ type: Constants.GET_SPEEDING_REPORT_SCHEDULING, payload: { data } });
		})
		.catch((error) => {
			console.log(error);
		});
};

const updateFilterSpeedingPeriod = (filter) => (dispatch) => {
	dispatch({ type: Constants.UPDATE_FILTER_SPEEDING_PERIOD, payload: { filter } });
};

const updateFilterSpeedingObject = (filter) => (dispatch) => {
	dispatch({ type: Constants.UPDATE_FILTER_SPEEDING_OBJECT, payload: { filter } });
};

const getSpeedingReportSent = (params) => (dispatch) => {
	return reportingService
		.getReportSentByType(params, SPEEDING_REPORT_ID)
		.then((response) => {
			const { index, recordsTotal, recordsFiltered, data } = response;
			//TODO Apply pagination
			const pagination = {
				index,
				recordsTotal,
				recordsFiltered,
			};
			dispatch({ type: Constants.GET_SPEEDING_REPORT_SENT, payload: { data: data.data, pagination } });
		})
		.catch((error) => {
			console.log(error);
		});
};

const saveSpeedingReportScheduling = (report, callback = () => {}) => (dispatch) => {
	return reportingService
		.saveDistanceReportScheduling(report, SPEEDING_REPORT_ID)
		.then((response) => {
			callback(false, response);
		})
		.catch((error) => {
			callback(error)
			console.error(error);
		});
};

const finishEditionSpeedingReportScheduling = () => (dispatch) => {
	dispatch({ type: Constants.EDIT_SPEEDING_REPORT_SCHEDULING, payload: {} });
};

const updatePeriodForm = (form) => (dispatch) => {
	dispatch({ type: Constants.UPDATE_FORM_SPEEDING_PERIOD, payload: { form } });
};

const updateObjectForm = (form) => (dispatch) => {
	dispatch({ type: Constants.UPDATE_FORM_SPEEDING_OBJECT, payload: { form } });
};

const updateScheduleForm = (form) => (dispatch) => {
    dispatch({ type: Constants.UPDATE_FORM_SPEEDING_SCHEDULED, payload: { form } });
};

const getSpeedingReportSchedulingById = (id) => (dispatch) => {
	return reportingService
		.getDistanceReportSchedulingById(id)
		.then((response) => {
			const { data } = response;
			dispatch({ type: Constants.EDIT_SPEEDING_REPORT_SCHEDULING, payload: data.data });
		})
		.catch((error) => {
			console.log(error);
		});
};

// ************************************************************************************************************

const _SPEED_LIMIT_TAG = "velocidade_na_via";
const _SPEED_TAG = "velocidade";
const _ONLY_CHARS_REGEX = /[^a-z]/gi;
const _SPACES_REGEX = /\s/gi;
const _EMPTY = "";

const processCategories = (categories) => {
	if (categories && categories.length > 0) {
		return categories.map((c) => {
			return c.replace(_SPACES_REGEX, _EMPTY);
		});
	}

	showError("Erro ao processar retorno do servidor.");
};

const processSpeedSeries = (data) => {
  if (data && data.length > 0) {

    const normalSpeed = [];
    const aboveSpeed = [];

    for (let i = 0; i < data.length; i++) {

      const currentValue = data[i];
      
      if (!Utils.isNumber(currentValue)) {

        if (currentValue.marker.fillColor === "#7CB5EC") {
          normalSpeed.push(currentValue.y);
        } else {
          normalSpeed.push(undefined);
        }

        aboveSpeed.push(currentValue.y);
      } else {
        aboveSpeed.push(undefined);
        normalSpeed.push(currentValue);
      }
    }

    return [normalSpeed, aboveSpeed];
    // return data.map((d) => (Utils.isNumber(d) ? d : d.y));
  }
  showError("Erro ao processar retorno do servidor.");
  return [];
};

const processSpeedLimitSeries = (data) => {
	if (data && data.length > 0) {
		return data.map((d) => (Utils.isNumber(d) ? d : d.y));
	}

	showError("Erro ao processar retorno do servidor.");
};

const processColorSegments = (segments) => {
	const colors = segments.reduce((previous, current) => {
		let el = { lte: current.value, color: current.color };

		if (previous.el) {
			el.gt = previous.el.value;
		}

		previous.el = current;

		if (previous.result) {
			previous.result.push(el);
		} else {
			previous.result = [el];
		}

		return previous;
	}, {});

	return colors.result;
};

const getSeries = (data, name, projection = (s) => s.data) => {
	const serie = data.find((x) => x.name && x.name.toLowerCase().replace(_ONLY_CHARS_REGEX, "_") === name);

	if (serie) {
		return projection(serie);
	}

	showError(`Erro ao processar retorno do servidor. Serie ${name} not found.`);
};

const getCoreData = (rawData) => {
	if (rawData && rawData.length === 1) {
		const data = rawData[0];

		const velocityGraphData = data.GraficoVelocidade;

		const proj = (s) => {
			return {
				data: s.data,
				segments: s.zones,
			};
		};

    const speedSeries = getSeries(velocityGraphData.MultipleData, _SPEED_TAG, proj);
    const speedLimitSeries = getSeries(velocityGraphData.MultipleData, _SPEED_LIMIT_TAG);
    
    const processedSpeeds = processSpeedSeries(speedSeries.data);
    
    return {
      xAxis: processCategories(velocityGraphData.Categories),
      speedSeries: processedSpeeds[0],
      speedSeriesAbove: processedSpeeds[1],
      speedLimitSeries: processSpeedLimitSeries(speedLimitSeries),
      colorSegments: processColorSegments(speedSeries.segments),
      details: data.Detalhes,
    };
  }

	console.log(`Erro ao processar retorno do servidor.`);
};

// ************************************************************************************************************

const getRequestPerObject = (params, callback = () => {}, LOADING_IDENTIFICATOR = "requestObjectSpeeding") => (
	dispatch
) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	reportingService
		.requestReportingPerObject(params)
		.then(({ data }) => {
			callback();

			if (data.data) {
				const coreData = getCoreData(data.data);
				dispatch({ type: Constants.GET_SPEEDING_REPORT_PER_OBJECT, payload: coreData || [] });
			} else if (data.Message) {
				if (!data.Success) {
					Utils.showError(data.Message);
					return;
				}
				Utils.downloadToLink(data.Message);
			}
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

export default {
	getSpeedingReportScheduling,
	getSpeedingReportSent,
	getSpeedingReportSchedulingById,
	saveSpeedingReportScheduling,
	finishEditionSpeedingReportScheduling,
	updatePeriodForm,
	updateObjectForm,
	updateFilterSpeedingPeriod,
	updateFilterSpeedingObject,
	getRequestPerObject,
	updateScheduleForm
};
