import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import Utils from "~/helpers/Utils";

import fleetsActions from '~/actions/fleets/fleetsActions'
import fleetService from "~/services/fleetService";

import { Form, Formik } from "formik";
import { Button, CircularProgress, Grid, Typography, Box } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import FileInput from "~/components/common/inputFile/FileInput";

import { ImportGreenIcon, CloseLightGrayIcon, IconDwGreenIcon } from "~/components/common/icons/Icons";

const ImportCars = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.app);
  const { fleet } = useSelector((state) => state.fleetsRegister);

  const [fleetId, setFleetId] = useState(null);

  const dwFile = async () => {
    const link = await fleetService.downloadFile();
    window.open(link.data);
  };

  const fnCallback = (msg, type) => {
    if (typeof(msg) === "object") {
      msg = t(Labels.ERROR_IMPORT_FILE);
      type = "error";
    } else if (type === "info") {
      type = "success";
    }
    Utils.showTranslatedToast({
      type: type,
      description: msg,
    });
  };

  const convertFile = async (file) => {
    if (file === undefined) {
      return;
    }

    const newFile64 = await Utils.fileToBase64(file);

    let fileBase64 = Utils.stringBase64ToObjectBase64(newFile64);

    const file64 = {
      fileName: file.name,
      fileData: fileBase64.data,
      size: file.size,
    };
    return file64;
  };

  useEffect(() => {
    setFleetId(fleet?.Id)
  }, [fleet])

  return (
    <>
      <Box
        className=" importCarAlert">
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{
            file: null,
            alert: null,
            removeObj: false
          }}
          validate={(values) => { }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);

            if (!values.file) {
              fnCallback(t(Labels.ERROR_IMPORT_CHOICE), "error");
              return;
            }

            if (Utils.isFile(values.file.fileName, [".xls", ".xlsx"])) {
              dispatch(fleetsActions.importFileFleet(values, fnCallback));
            } else {
              fnCallback(t(Labels.ERROR_IMPORT_TYPE_FILE), "error");
              return;
            }
          }}
          render={({ submitForm, setFieldValue, values, resetForm }) => {
            return (
              <Form>
                <Grid
                  container
                  data-id={"boxImportCar"}
                  direction="column"
                  justify="flex-end"
                  alignItems="flex-start"
                  spacing={2}
                  className="content">
                  <Grid direction="column">
                    <Grid>
                      <FileInput
                        forID="file-input"
                        placeHolderInput={t(Labels.PLACEHOLDER_IMPORT_DRIVER)}
                        labelButton={t(Labels.CHOICE_LABEL)}
                        value={values.file?.fileName}
                        backIconStyle={{ border: "2px solid", borderRadius: "20px" }}>
                        <input
                          id="file-input"
                          data-id={t(Labels.FILE_LABEL_IMPORT_DRIVER).replace(/ de /g, "").replace(/\s/g, "")}
                          name="file"
                          type="file"
                          accept=".xlsx, .xls"
                          onChange={async (event) => {
                            const file64 = await convertFile(event.currentTarget.files[0]);
                            setFieldValue("file", file64);
                          }}
                          className="form-control"
                        />
                      </FileInput>
                      <span
                        className="link-import"
                        data-id={t(Labels.LINK_LABEL_IMPORT_DRIVER).replace(/ de /g, "").replace(/\s/g, "")}
                        onClick={dwFile}>
                        <IconDwGreenIcon color="primary" className="iconImportDw" />
                        <Typography color="primary" className="import-font">{t(Labels.LINK_LABEL_IMPORT_DRIVER)}</Typography>
                      </span>
                    </Grid>
                    <Grid className="grid-item">
                      <FormControlLabel
                        key={'form'}
                        control={
                          <Checkbox
                            color="primary"
                            className="import-checkbox"
                            checked={values.removeObj}
                            onClick={() => {
                              setFieldValue("removeObj", !values.removeObj);
                            }}
                            name={"Checkbox"}
                          />
                        }
                        label={
                          <Typography className="fontCheckBox">
                            {t(Labels.FLEET_LABEL_IMPORT_CHECKBOX)}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid container direction="row" className="grid-item grid-buttons">
                      <Grid item xs={3}>
                        <Button
                          data-id="import-cancel"
                          className="import-cancel-button"
                          variant="outlined"
                          disableElevation
                          onClick={() => {
                            resetForm();
                            props.closeTab()
                          }}>
                          <CloseLightGrayIcon />
                          <Typography
                            className="import-font"
                          >
                            {t(Labels.BTN_CANCEL_IMPORT_DRIVER)}
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          data-id="import-button"
                          className="import-confirm-button"
                          variant="outlined"
                          color="primary"
                          disableElevation
                          disabled={loading.importFileFleet}
                          fullWidth
                          onClick={() => {
                            submitForm();
                            setFieldValue("fleetId", { id: fleetId });
                          }}>
                          {loading.importFileFleet ? (
                            <CircularProgress style={{ height: 14, width: 14, marginRight: 8 }} color="secondary" />
                          ) : (
                              <span className="btnConfirmAlert">
                                <ImportGreenIcon className="import-green-icon" color="primary" />
                                <Typography>
                                  {t(Labels.ALERT_LABEL_BTN_IMPORT)}
                                </Typography>
                              </span>
                            )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        />
      </Box>
    </>
  );
}

export default ImportCars;
