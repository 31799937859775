//! linear-gradient(180deg, #6EC8AF 0%, #64C8FF 100%)
export const ComercialColorSolid = "#243782";
export const ComercialColors = {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
        {
            offset: 0,
            color: ComercialColorSolid, // color at 0% position
        },
        {
            offset: 1,
            color: "#243782", // color at 100% position
        },
    ],
    global: true,
};

//! linear-gradient(90deg, #8E63EF 0%, #DEAAFF 100%)
export const NaoComercialColorSolid = "#6EC8AF";
export const NaoComercialColors = {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
        {
            offset: 0,
            color: NaoComercialColorSolid, // color at 0% position
        },
        {
            offset: 1,
            color: "#6EC8AF", // color at 100% position
        },
    ],
    global: false,
};

//! linear-gradient(2deg, #FC5C75 0%, #FF9464 100%)
export const FimSemanaColorSolid = "#FC5C75";
export const FimSemanaColors = {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
        {
            offset: 0,
            color: FimSemanaColorSolid, // color at 0% position
        },
        {
            offset: 1,
            color: "#FC5C75", // color at 100% position
        },
    ],
    global: false,
};

export const names = (Shift) => {
    const SwitchNames = {
        Comercial: "Horário Comercial",
        NaoComercial: "Fora do Horário",
        FimSemana: "Final de Semana",
        default: "#FFF",
    };

    return SwitchNames[Shift] || SwitchNames.default;
};

export const colors = (Shift) => {
    const SwitchColor = {
        Comercial: ComercialColors,
        NaoComercial: NaoComercialColors,
        FimSemana: FimSemanaColors,
        default: "#FFF",
    };

    return SwitchColor[Shift] || SwitchColor.default;
};

export const colorSolid = (Shift) => {
    const SwitchColor = {
        Comercial: ComercialColorSolid,
        NaoComercial: NaoComercialColorSolid,
        FimSemana: FimSemanaColorSolid,
        default: "#FFF",
    };

    return SwitchColor[Shift] || SwitchColor.default;
};

export const getFullValue = (Shift, objectItens) => {
    const SwitchValue = {
        Comercial: objectItens["DistanciaComercial"],
        NaoComercial: objectItens["DistanciaNaoComercial"],
        FimSemana: objectItens["DistanciaFimSemana"],
        default: 0,
    };
    return SwitchValue[Shift].toLocaleString() || SwitchValue.default;
};

export default {
    ComercialColorSolid,
    ComercialColors,
    NaoComercialColorSolid,
    NaoComercialColors,
    FimSemanaColorSolid,
    FimSemanaColors,
    names,
    colors,
    colorSolid,
    getFullValue,
};
