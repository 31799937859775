import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableCard from "~/components/tableCard/TableCard";
import Search from "~/components/common/search/Search";
import Pagination from "~/components/common/pagination/Pagination";
import Loading from "~/components/common/loading/Loading";
import googleMapsDataAction from "~/actions/googleMapsDataAction";
import travelsReportAction from "~/actions/reporting/travelsReportAction";
import * as _ from "lodash";
import Utils from "~/helpers/Utils";
import MenuRange from "~/components/common/menuRange/MenuRange";
import { EVENTS_OBJECT_COLUMNS } from "./Enum/GridResultEnum";
import { directionToWordApproach } from "~/components/map/ArrowResolver";
import useFuso from "~/components/common/fuso/useFuso";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import Portal from "@material-ui/core/Portal";

const GridResultObject = (props) => {
    const dispatch = useDispatch();
    const { fromUtc } = useFuso();
    const { t } = useTranslation();

    const { requestObject } = useSelector((state) => state.app.loading);

    const {
        perObject: { filters, data },
    } = useSelector((state) => state.travelsReport);

    useEffect(() => {
        if (_.isEmpty(filters.period)) {
            return;
        }

        dispatch(travelsReportAction.requestPerObject(filters));
    }, [dispatch, filters]);

    useEffect(() => {
        return () => {
            dispatch(googleMapsDataAction.cleanDirections());
        };
    }, [dispatch]);

    const dataWithCorrectUtc = useMemo(() => {
        return data.data.map((item) => ({
            ...item,
            DataInicio:
                item.DataInicio != null
                    ? fromUtc(item.DataInicio, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY | HH:mm:ss")
                    : "",
            DataFim:
                item.DataInicio != null
                    ? fromUtc(item.DataFim, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY | HH:mm:ss")
                    : "",
            VelocidadeMedia: Utils.formatSpeed(item.VelocidadeMedia),
            VelocidadeMaxima: Utils.formatSpeed(item.VelocidadeMaxima),
            Distancia: Utils.formatKilometers(item.Distancia, 0),
        }));
    }, [data.data, fromUtc]);

    const FilterDiv = () => (
        <>
            <Search
                id="search-events"
                className={"search search-input-refactor"}
                style={{ margin: 0 }}
                value={filters.search || ""}
                search={(search) => {
                    dispatch(
                        travelsReportAction.updateFilterTravelObject({
                            ...filters,
                            search,
                        })
                    );
                }}
            />
            <div style={{ marginLeft: "10px" }}>
                <MenuRange
                    id={"report-events"}
                    label={t(Labels.MENU_RANGE_SHOW)}
                    className="menu-range-refactor"
                    value={filters.limit}
                    onChange={(event) => {
                        dispatch(
                            travelsReportAction.updateFilterTravelObject({
                                ...filters,
                                index: 0,
                                limit: event.target.value,
                            })
                        );
                    }}
                    options={[10, 25, 50, 100, 250, 500]}
                />
            </div>
        </>
    );
    return (
        <div className="contentCard">
            {props.mode.orientation === "horizontal" ? (
                <div className="spaceSearchReportObject">
                    <FilterDiv />
                </div>
            ) : (
                <Portal container={props.refSerachDiv.current}>
                    <div className="spaceSearchReportObjectPortal">
                        <FilterDiv />
                    </div>
                </Portal>
            )}
            {requestObject ? (
                <div id="loading-object">
                    <Loading />
                </div>
            ) : (
                <TableCard
                    mode={props.mode}
                    className="content-table-card"
                    filters={EVENTS_OBJECT_COLUMNS}
                    data={Utils.filterData(dataWithCorrectUtc || [], filters.search || "")}
                    onItemClick={(element) => {
                        dispatch(
                            googleMapsDataAction.updateDirections([
                                {
                                    ...element,
                                    Trajeto: element.Trajeto?.map((t) => {
                                        return {
                                            ...t,
                                            direction: directionToWordApproach(t.direction),
                                        };
                                    }),
                                },
                            ])
                        );
                        dispatch(googleMapsDataAction.centralizeDirections([element]));
                    }}
                />
            )}

            {requestObject ? null : (
                <div className="pagination-tabmap">
                    <Pagination
                        onChange={(event, value) => {
                            dispatch(
                                travelsReportAction.updateFilterTravelObject({
                                    ...filters,
                                    index: value - 1,
                                })
                            );
                        }}
                        page={filters.index + 1}
                        count={Math.ceil(data.recordsTotal / filters.limit)}
                    />
                </div>
            )}
        </div>
    );
};

export default GridResultObject;
