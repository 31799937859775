import React from "react";
import ReactEcharts from "echarts-for-react";
import { useSelector } from "react-redux";
import { getOption } from "~/components/common/defaultsCharts/defaultChartPie";
import roadOffsetIcon from "~/assets/icons/roadOffset.svg";

export default function RoadOffsetChartPie() {
    const data = useSelector((state) => state.dashboardTravels.roadOffset);
    const { waitingTravel } = useSelector((state) => state.app.loading);

    return (
        <ReactEcharts
            option={getOption(data, roadOffsetIcon, "km")}
            showLoading={waitingTravel}
            loadingOption={{ text: "Carregando" }}
        />
    );
}
