import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import dashboardChartsAction from "~/actions/dashboard/dashboardChartsAction";
import dashboardActionsTopTen from "~/actions/dashboard/dashboarTopTenAction";
import TopTenTable from "~/components/common/topTenTable/TopTenTable";
import LoadingPanel from "~/components/common/loading/LoadingPanel";
import VehicleIconComponent from "~/components/common/vehicleIcon/VehicleIconComponent";
import Modal from "@material-ui/core/Modal";
import MaxVelocityModal from "./MaxVelocityModal";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const TopTenMaxVelocityVehicle = (props) => {
    const { t } = useTranslation();
    const loadingId = useMemo(() => "topTenMaxVelocityVehicle", []);
    const [vehicleModal, setVehicleModal] = useState(null);

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.app.loading);

    const { fleetSelected, subFleetSelected, rangeDate } = useSelector((state) => state.dashboardFilter);
    const { topTenMaxVelocityVehicle } = useSelector((state) => state.dashboardCharts);

    const handleOnClose = () => {
        setVehicleModal(null);
    };

    useEffect(() => {
        const filter = {
            grupoId: subFleetSelected?.id || fleetSelected?.id,
            ...rangeDate,
        };

        dispatch(dashboardChartsAction.getTopTenMaxVelocityVehicle(filter, loadingId));
    }, [dispatch, fleetSelected, subFleetSelected, rangeDate, loadingId]);

    return (
        <>
            {loading[loadingId] && <LoadingPanel size={45} />}
            <TopTenTable
                columns={[
                    {
                        title: "",
                        key: "icon",
                        width: "7%",
                    },
                    {
                        title: t(Labels.DASHBOARD_TOP_TEN_MAX_VELOCITY_VEHICLE_PLATE),
                        key: "plate",
                        width: "40%",
                        render: (itemName, item, count) => (
                            <Link to="/dashboardVehicle/" target="_blank" style={{ textDecoration: "none" }}>
                                <Typography
                                    onClick={(ev) => {
                                        ev.stopPropagation()
                                        dispatch(dashboardActionsTopTen.setVehicle(item));
                                    }}
                                    style={{ cursor: "pointer", fontSize: "18px" }}
                                    className="gridDataFont rowFont fontDist">
                                    {itemName}
                                </Typography>
                            </Link>
                        ),
                    },
                    {
                        title: t(Labels.DASHBOARD_TOP_TEN_MAX_VELOCITY_VEHICLE_MAX_VELOCITY),
                        key: "max_velocity",
                        width: "50%",
                        borded: true,
                    },
                ]}
                rows={topTenMaxVelocityVehicle.map((vehicle) => {
                    return {
                        plate: vehicle.Placa,
                        max_velocity: `${vehicle.VelocidadeMaxima} Km/h`,
                        icon: <VehicleIconComponent type={vehicle.TipoVeiculo} />,
                        ObjetoRastreavelId: vehicle.ObjetoRastreavelId,
                        Placa: vehicle.Placa,
                        TipoVeiculo: vehicle.TipoVeiculo,
                        value: vehicle,
                    };
                })}
                onItemClick={(ev, key, value, row) => {
                    ev.stopPropagation()
                    setVehicleModal({...row, VelocidadeMaxima: value.VelocidadeMaxima});
                }}
            />
            <Modal open={!!vehicleModal} onClose={handleOnClose}>
                <MaxVelocityModal onClose={handleOnClose} vehicle={vehicleModal} />
            </Modal>
        </>
    );
};

export default TopTenMaxVelocityVehicle;
