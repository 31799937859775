import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import mapService from "~/services/mapService";
import mapServiceFilter from "~/services/vehicleService";
import moment from "moment";

const toogleMarkedMapFilter = (name, checked) => (dispatch) => {
	dispatch({ type: Constants.TOOGLE_MARKED_MAP_FILTER, name, checked });
};

const getPointInterestGroup = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return mapService
		.getPointInterestGroup(filter)
		.then((response) => {
			if (response) {
				callback();
				dispatch({
					type: Constants.GET_POINT_INTEREST_GROUP,
					payload: response.data,
				});
			}
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getPointInterestForGroup = (filter, groupName, callback = () => {}, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return mapService
		.getPointInterest(filter)
		.then((response) => {
			const data = response.data.map((item) => {
				item.groupName = groupName;
				return item;
			});
			
			callback(false, data);
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getPointInterest = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return mapService
		.getPointInterest(filter)
		.then((response) => {
			if (response) {
				callback();
				dispatch({
					type: Constants.GET_POINT_INTEREST,
					payload: response.data,
				});
			}
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const getRoutes = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	return mapService
		.getRoutes(filter)
		.then((response) => {
			if (response) {
				callback();
				dispatch({
					type: Constants.GET_ROUTES,
					payload: response.data,
				});
			}
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const dataMapFilter = (filter) => (dispatch) => {
	return mapServiceFilter
		.list(filter)
		.then((response) => {
			let data = response.data;

			data.Data.forEach(item => {
				item.DataHoraEventoLocal = item.DataHoraEventoLocal ? moment(item.DataHoraEventoLocal, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss") : item.DataHoraEventoLocal;
			})

			let payload = {
				data
			};

			dispatch({ type: Constants.DATA_FILTER_MAP, payload });
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {});
};

const updateDataMapFilter = (filter) => (dispatch) => {
	dispatch({ type: Constants.UPDATE_DATA_FILTER, payload: { filter } });
};

const cleanPointInterestGroup = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_MAP_POINT_INTEREST_GROUP });
};

const cleanPointInterest = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_MAP_POINT_INTEREST });
};

const cleanRoutes = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_MAP_ROUTES });
};

const cleanFilter = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_MAP_FILTER });
};

export default {
	toogleMarkedMapFilter,
	getPointInterestGroup,
	getPointInterestForGroup,
	getPointInterest,
	getRoutes,
	dataMapFilter,
	updateDataMapFilter,
	cleanPointInterestGroup,
	cleanPointInterest,
	cleanRoutes,
	cleanFilter,
};
