const primaryColor = {
	type: "linear",
	x: 0,
	y: 0,
	x2: 0,
	y2: 1,
	colorStops: [
		{
			offset: 0,
			color: "#6EC8AF",
		},
		{
			offset: 0.46,
			color: "#6EC8B1",
		},
		{
			offset: 1,
			color: "#64C8FF",
		},
	],
	global: false,
};

const secondaryColor = {
	type: "linear",
	x: 0,
	y: 0,
	x2: 0,
	y2: 1,
	colorStops: [
		{
			offset: 0,
			color: "#FFDA5D", // color at 0% position
		},
		{
			offset: 1,
			color: "#FF9464", // color at 100% position
		},
	],
	global: false,
};

export default { secondaryColor, primaryColor };
