import React from "react";

import { Box, Typography } from "@material-ui/core";
import CardDataReUse from "~/components/card/CardDataReUse";
import TableReUse from "~/components/table/TableReUse";

const TableCardReUse = ({ paddingBox, ...props }) => {
    if (props?.dataSource?.length <= 0) {
        return (
            <Typography noWrap style={{ fontSize: "14px" }}>
                Nenhum registro encontrado.
            </Typography>
        );
    }

    //TODO Apply pagination
    return (
        <Box style={{ padding: paddingBox || "10px 0", background: "none" }}>
            {props.mode && "horizontal" === props.mode ? (
                <CardDataReUse columns={props.columns} dataSource={props.dataSource || []} {...props} />
            ) : (
                <TableReUse
                    rowSelection={{ ...props.rowSelection }}
                    columns={props.columns}
                    dataSource={props.dataSource}
                    {...props}
                />
            )}
        </Box>
    );
};

export default TableCardReUse;
