import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import LoadingPanel from "~/components/common/loading/LoadingPanel";
import VehicleForm from "~/components/vehicle/register/VehicleForm";
import { Box, Grid } from "@material-ui/core";

const EditVehicle = ({ closeTab }) => {

    const { loading } = useSelector((state) => state.app);
    const { vehicle, editForm } = useSelector((state) => state.vehicle);

    useEffect(() => {
        if (!editForm || !editForm.current) return;
        const values = { ...vehicle };
        values.Apelido = values.Apelido ? values.Apelido : '';
        values.MotoristaPadrao = { id: values.MotoristaPadraoId, text: values.MotoristaPadraoNome };

        Object.entries(values).forEach(([key, value]) => {
            editForm.current.setFieldValue(key, value);
        });
    }, [vehicle, editForm]);

    return (
        <Box className="edit-vehicle">
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                    <VehicleForm compact
                        comeBack={() => {
                            closeTab();
                        }}
                    />
                </Grid>
            </Grid>
            {(loading.vehicle || loading.getVehicleByID) && <LoadingPanel size={40} />}
        </Box>)
}

export default EditVehicle