import React, { useState } from "react";
import RangeDatePicker from "~/components/common/rangeDatePicker/RangeDatePicker";
import { Box, TextField, Typography, Popover, IconButton, InputAdornment } from "@material-ui/core";
import { DatePickerIcon, CloseIcon } from "~/components/common/icons/Icons";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Field } from "formik";
import "./ReportingCommon.scss";
import "~/components/reporting/Reporting.scss";

const useStyles = makeStyles((theme) => ({
    divIcon: {
        backgroundColor: theme.palette.primary.main,
    },
}));

const ReportPeriodField = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    return (
        <Box className="report-field">
            <Typography variant="subtitle1" className="report-field-label">
                {props.label}
            </Typography>
            <Field name={props.name}>
                {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                }) => {
                    const fieldError = errors[props.name];
                    const isfieldTouched = touched[props.name];

                    return (
                        <Box>
                            <Box
                                className="period-date-picker"
                                onClick={(ev) => {
                                    field.onBlur(ev);
                                    setOpen(!open);
                                    setAnchorEl(ev.currentTarget);
                                }}>
                                <TextField
                                    id={`${props.id}-startDate`}
                                    error={isfieldTouched && !!fieldError}
                                    helperText={isfieldTouched && fieldError}
                                    className="period-start-date"
                                    variant="outlined"
                                    value={moment(field.value.startDate).format("DD-MM-YYYY HH:mm:ss")}
                                    margin="dense"
                                    aria-readonly
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                                <span className="period-divider" />

                                <TextField
                                    id={`${props.id}-endDate`}
                                    error={isfieldTouched && !!fieldError}
                                    className="period-end-date"
                                    variant="outlined"
                                    value={moment(field.value.endDate).format("DD-MM-YYYY HH:mm:ss")}
                                    margin="dense"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" className={classes.divIcon}>
                                                <IconButton>
                                                    <div className={`${classes.divIcon} div-icon-period`}>
                                                        <DatePickerIcon />
                                                    </div>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                dateRange={field.value}
                                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                transformOrigin={{ vertical: "top", horizontal: "left" }}>
                                <RangeDatePicker
                                    {...props}
                                    id={props.id}
                                    closeButton={
                                        <IconButton
                                            onClick={() => {
                                                setOpen(false);
                                            }}>
                                            <CloseIcon />
                                        </IconButton>
                                    }
                                    maxDate={new Date()}
                                    open={true}
                                    onChange={(range) => {
                                        setFieldValue(props.name, range);
                                        setOpen(false);
                                    }}
                                />
                            </Popover>
                        </Box>
                    );
                }}
            </Field>
        </Box>
    );
};

export default ReportPeriodField;
