import ArrowOnLeste from "~/assets/icons/card/arrows/arrow-on-leste.png";
import ArrowOnNordeste from "~/assets/icons/card/arrows/arrow-on-nordeste.png";
import ArrowOnNoroeste from "~/assets/icons/card/arrows/arrow-on-noroeste.png";
import ArrowOnNorte from "~/assets/icons/card/arrows/arrow-on-norte.png";
import ArrowOnOeste from "~/assets/icons/card/arrows/arrow-on-oeste.png";
import ArrowOnSudeste from "~/assets/icons/card/arrows/arrow-on-sudeste.png";
import ArrowOnSudoeste from "~/assets/icons/card/arrows/arrow-on-sudoeste.png";
import ArrowOnSul from "~/assets/icons/card/arrows/arrow-on-sul.png";

import ArrowOffLeste from "~/assets/icons/card/arrows/arrow-off-leste.png";
import ArrowOffNordeste from "~/assets/icons/card/arrows/arrow-off-nordeste.png";
import ArrowOffNoroeste from "~/assets/icons/card/arrows/arrow-off-noroeste.png";
import ArrowOffNorte from "~/assets/icons/card/arrows/arrow-off-norte.png";
import ArrowOffOeste from "~/assets/icons/card/arrows/arrow-off-oeste.png";
import ArrowOffSudeste from "~/assets/icons/card/arrows/arrow-off-sudeste.png";
import ArrowOffSudoeste from "~/assets/icons/card/arrows/arrow-off-sudoeste.png";
import ArrowOffSul from "~/assets/icons/card/arrows/arrow-off-sul.png";

export const arrowResolver = (ignition, direction) => {
	switch (direction) {
		case "ne": // 45
			return ignition ? ArrowOnNordeste : ArrowOffNordeste;
		case "n": // 90
			return ignition ? ArrowOnNorte : ArrowOffNorte;
		case "nw": // 135
			return ignition ? ArrowOnNoroeste : ArrowOffNoroeste;
		case "w": // 180
			return ignition ? ArrowOnOeste : ArrowOffOeste;
		case "sw": // 225
			return ignition ? ArrowOnSudoeste : ArrowOffSudoeste;
		case "s": // 270
			return ignition ? ArrowOnSul : ArrowOffSul;
		case "se": // 315
			return ignition ? ArrowOnSudeste : ArrowOffSudeste;
		case "e": // 360 - 0
		default:
			return ignition ? ArrowOnLeste : ArrowOffLeste;
	}
}; 

export const directionToWordApproach = (direction) => {
	return directionsToWord(directionApproach(direction));
};

export const directionsToWord = (direction) => {
	/* OBS: as direções dessa função levam em conta que o sistema de coordenadas utilizado pelo CPS é diferente do sistema de grau padrão
    no sistema do CPS, 0 graus aponta para o norte ao invés de apontar para o leste, e os graus crescem no sentido horário
    ao invés do sentido anti-horário. Por isso, ao fazer modificações nesse método, leve isso em consideração e compare
    as direções como estão no CPS "antigo" e no CPS "novo"*/

	const directionWord = ["n", "ne", "e", "se", "s", "sw", "w", "nw", "n"];
	const angles = [0, 45, 90, 135, 180, 225, 270, 315, 360];
	return directionWord[angles.indexOf(direction)];
};

export const directionApproach = (direction) => {
	const angles = [0, 45, 90, 135, 180, 225, 270, 315, 360];
	for (var i = 1; i < angles.length; i++) {
		if (direction > angles[i - 1] && direction < angles[i]) {
			return approach(direction, angles[i - 1], angles[i]);
		}
	}
};

export const approach = (num, aprox1, aprox2) => {
	if (Math.abs(num - aprox1) < Math.abs(num - aprox2)) {
		return aprox1;
	} else {
		return aprox2;
	}
};
