import React from "react";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { Box, Typography, FormControl, FormHelperText, MenuItem } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import FormikSelectPopper from "~/components/common/popperComponents/FormikSelectPopper";
import FrequencyEnum from "~/helpers/enums/FrequencyEnum";

import "~/components/reporting/Reporting.scss";
import "~/components/reporting/common/ReportingCommon.scss";

const ReportPeriodicityField = (props) => {
    const { touched, errors } = useFormikContext();

    const error = errors && errors[props.name];
    const isTouched = touched && touched[props.name];

    return (
        <Box className="report-field">
            <FormControl fullWidth margin="dense">
                <Typography variant="subtitle1" className="report-field-label">
                    {props.label}
                </Typography>
                <Field
                    id={props.id}
                    name={props.name}
                    component={FormikSelectPopper}
                    onChange={props.onChange}
                    IconComponent={ExpandMoreRoundedIcon}
                    error={isTouched && !!error}
                    variant="outlined"
                    margin="dense"
                    fullWidth>
                    {FrequencyEnum.LIST.map((f) => (
                        <MenuItem data-id={"periodicidade-" + f.description} key={f.description} value={f.value}>
                            {f.description}
                        </MenuItem>
                    ))}
                </Field>

                <FormHelperText error margin="dense" variant="outlined">
                    {isTouched && error}
                </FormHelperText>
            </FormControl>
        </Box>
    );
};
export default ReportPeriodicityField;
