import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./LoadingPanel.scss";

const LoadingPanel = (props) => {
	return (
		<div className={`loading-panel`}>
			<CircularProgress id="loading" size={props.size || 30} color="primary" />
		</div>
	);
};

export default LoadingPanel;
