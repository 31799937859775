import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import Portal from "@material-ui/core/Portal";
import TableCardReUse from "~/components/tableCard/TableCardReUse";
import Search from "~/components/common/search/Search";
import Loading from "~/components/common/loading/Loading";
import useFuso from "~/components/common/fuso/useFuso";

import Utils from "~/helpers/Utils";

const GridResultObject = (props) => {
    const { requestObjectSpeeding } = useSelector((state) => state.app.loading);
    const [search, setSearch] = useState("");
    const { fromUtc } = useFuso();

    const {
        perObject: { filters },
        chartData,
    } = useSelector((state) => state.speedingReport);

    useEffect(() => {
        setSearch("");
    }, [filters]);

    const SPEEDING_OBJECT_COLUMNS = [
        {
            name: "Placa",
            label: "Placa",
            render: (board) => <strong>{board}</strong>,
        },
        {
            name: "MotoristaNome",
            label: "Motorista",
        },
        {
            name: "DataHoraEvento",
            label: "Data/Hora",
            render: (data) => {
                return <span>{fromUtc(data, "YYYY-MM-DDTHH:mm:ss")?.format("DD/MM/YYYY | HH:mm:ss")}</span>;
            },
        },
        {
            name: "Localizacao",
            label: "Localização",
            render: (loc) => {
                return (
                    <span style={{ fontSize: "12px" }} title={loc}>
                        {loc}
                    </span>
                );
            },
        },
        {
            name: "Latitude",
            label: "Latitude",
            render: (lat) => Utils.formatCoordinates(lat),
        },
        {
            name: "Longitude",
            label: "Longitude",
            render: (long) => Utils.formatCoordinates(long),
        },
        {
            name: "Velocidade",
            label: "Velocidade",
            render: (vel) => {
                return <span>{Utils.formatSpeed(vel)}</span>;
            },
        },
        {
            name: "VelocidadeVia",
            label: "Vel. da via",
            render: (vel) => {
                return <span>{Utils.formatSpeed(vel)}</span>;
            },
        },
    ];

    const FilterDiv = () => (
        <Search
            id="search-events"
            className={"search"}
            value={search}
            style={{ margin: 0 }}
            search={(search) => {
                setSearch(search);
            }}
        />
    );

    return (
        <div className="contentCardSpeeding">
            {props.mode.orientation === "horizontal" ? (
                <div className="spaceSearchReportObject">
                    <FilterDiv />
                </div>
            ) : (
                <Portal container={props.refSerachDiv.current}>
                    <div className="spaceSearchReportObjectPortal">
                        <FilterDiv />
                    </div>
                </Portal>
            )}
            {requestObjectSpeeding ? (
                <div id="loading-object">
                    <Loading />
                </div>
            ) : (
                <TableCardReUse
                    className="tableObjectReportingSpeeding"
                    mode={props.mode.orientation}
                    columns={SPEEDING_OBJECT_COLUMNS}
                    dataSource={Utils.filterData(chartData.details || [], search || "")}
                    printRow={{
                        key01: "Velocidade",
                        key02: "VelocidadeVia",
                    }}
                />
            )}
        </div>
    );
};

export default GridResultObject;
