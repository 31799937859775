import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "~/components/common/expansionCard/Card";
import { Grid, Box, Button, CircularProgress } from "@material-ui/core";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import Utils from "~/helpers/Utils";
import { UserIcon, RegisterTitleIcon, EditIcon, CancelIcon, ConfirmIcon } from "~/components/common/icons/Icons";
import Labels from "~/helpers/enums/Labels";
import Constants from "~/helpers/enums/Constants";
import CpfCnpjUtils from "~/helpers/CpfCnpjUtils";
import RegisterInputText from "~/components/common/registerInputs/RegisterInputText";
import RegisterMaskedTextInput from "~/components/common/registerInputs/RegisterMaskedTextInput";
import RegisterCheckbox from "~/components/common/registerInputs/RegisterCheckbox";
import RegisterDatePicker from "~/components/common/registerInputs/RegisterDatePicker";
import RegisterSelect from "~/components/common/registerInputs/RegisterSelect";
import RegisterImageInput from "~/components/common/registerInputs/RegisterImageInput";
import driverAction from "~/actions/driverAction";
import moment from "moment";
import "./DriverForm.scss";

const DriverForm = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { loading } = useSelector((state) => state.app);

	useEffect(() => {}, [dispatch]);
	const hasdriver = !!props.driver;

	let driver = {
		Imagem: {},
		Nome: "",
		DataNascimento: null,
		Telefone: "",
		Cpf: "",
		Cnpj: "",
		Rg: "",
		UfRg: "",
		NumeroCNH: "",
		CategoriaCNH: "B",
		DataRenovacaoCNH: null,
		CidadeCNH: "",
		Identificador: "",
		Ativo: true,
		Descricao: "",
		PossuiIntegracaoPendente: false,
	};

	if (hasdriver) {
		driver = {
			...driver,
			...props.driver,
			Imagem: {
				url: !Utils.isNullOrBlank(props.driver.UrlImagem) ? props.driver.UrlImagem : null,
			},
		};
	}

	return (
		<Card
			title={
				<Box className="register-title">
					{hasdriver ? (
						<>
							<EditIcon />
							{t(Labels.REGISTER_DRIVER_FORM_EDIT_TITLE)}
						</>
					) : (
						<>
							<RegisterTitleIcon />
							{t(Labels.REGISTER_DRIVER_FORM_ADD_TITLE)}
						</>
					)}
				</Box>
			}
			className="form-card driver-form-card">
			<Formik
				initialValues={{
					...driver,
				}}
				validate={(values) => {
					const errors = {};

					if (values.Imagem.file && !Utils.isFile(values.Imagem.file.name, [".png", "jpg", "jpeg"])) {
						errors.Imagem = t(Labels.REGISTER_DRIVER_FORM_IMAGE_MSG_INVALID_TYPE);
					}

					if (!values.Nome) {
						errors.Nome = t(Labels.REGISTER_DRIVER_FORM_NAME_MSG_REQUIRED);
					}

					const cpf = CpfCnpjUtils.cleanMask(values.Cpf);
					if (!cpf) {
						errors.Cpf = t(Labels.REGISTER_DRIVER_FORM_CPF_MSG_REQUIRED);
					} else if (!CpfCnpjUtils.validateCPF(cpf)) {
						errors.Cpf = t(Labels.REGISTER_DRIVER_FORM_CPF_MSG_INVALID);
					}

					const cnpj = CpfCnpjUtils.cleanMask(values.Cnpj);
					if (cnpj && !CpfCnpjUtils.validateCNPJ(cnpj)) {
						errors.Cnpj = t(Labels.REGISTER_DRIVER_FORM_CNPJ_MSG_INVALID);
					}

					const dataNascimento = values.DataNascimento;

					if (dataNascimento) {
						const anos = moment().diff(dataNascimento, "years");
						if (anos < 18) {
							errors.DataNascimento = t(Labels.REGISTER_DRIVER_FORM_BIRTHDAY_MINIMUM_AGE)
						}
					}

					return errors;
				}}
				onSubmit={(values, { setSubmitting }) => {
					let { Imagem, ...newDriver } = values;

					if (Imagem.base64) {
						newDriver = {
							...newDriver,
							Imagem: {
								FileName: Imagem.file.name,
								File: Utils.stringBase64ToObjectBase64(Imagem.base64).data,
							},
						};
					}

					newDriver = {
						...newDriver,
						Cpf: CpfCnpjUtils.cleanMask(newDriver.Cpf),
						Cnpj: CpfCnpjUtils.cleanMask(newDriver.Cnpj),
						Telefone: CpfCnpjUtils.cleanMask(newDriver.Telefone),
						Rg: newDriver.Rg || null,
						UrlImagem: Imagem.url || " ",
					};

					dispatch(
						driverAction.saveDriver(newDriver, (error) => {
							setSubmitting(false);
							if (error) {
								Utils.showError(error);
								return;
							}

							Utils.showTranslatedToast({
								type: Constants.SUCCESS,
								description: Labels.REGISTER_DRIVER_FORM_MSG_SUCCESS,
							});

							props.comeback();
						})
					);
				}}>
				{({ submitForm, resetForm }) => (
					<Form className="ceabs-register-form">
						<Grid container direction="row">
							<Grid item xs={9}>
								<Grid container direction="row" alignItems="stretch" spacing={0}>
									<Grid item xs={12}>
										<Grid container direction="row" alignItems="stretch" spacing={0}>
											<Grid item xs={12} md={2}>
												<RegisterImageInput withButton name={"Imagem"} placholderIcon={<UserIcon />} accept=".jpg, .png, .jpeg" />
											</Grid>
											<Grid item xs={12} md={10}>
												<Grid container direction="row" alignItems="stretch" spacing={0}>
													<Grid item xs={12} md={8}>
														<RegisterInputText label={t(Labels.REGISTER_DRIVER_FORM_NAME)} name="Nome" />
													</Grid>
													<Grid className="nowrap-error-msg" item xs={12} md={2}>
														<RegisterDatePicker maxDate={new Date()} label={t(Labels.REGISTER_DRIVER_FORM_BIRTHDAY)} name="DataNascimento" />
													</Grid>
													<Grid item xs={12} md={2}>
														<RegisterMaskedTextInput
															label={t(Labels.REGISTER_DRIVER_FORM_PHONE)}
															name="Telefone"
															mask="(99) 99999-9999"
														/>
													</Grid>

													<Grid item xs={12} md={3}>
														<RegisterMaskedTextInput
															label={t(Labels.REGISTER_DRIVER_FORM_CPF)}
															name="Cpf"
															mask="999.999.999-99"
														/>
													</Grid>
													<Grid item xs={12} md={3}>
														<RegisterMaskedTextInput
															label={t(Labels.REGISTER_DRIVER_FORM_CNPJ)}
															name="Cnpj"
															mask="99.999.999/9999-99"
														/>
													</Grid>
													<Grid item xs={12} md={3}>
														<RegisterMaskedTextInput
															label={t(Labels.REGISTER_DRIVER_FORM_RG)}
															name="Rg"
															mask="99999999999999999999" // 20
														/>
													</Grid>
													<Grid item xs={12} md={3}>
														<RegisterInputText
															label={t(Labels.REGISTER_DRIVER_FORM_STATE_RG)}
															name="UfRg"
															inputProps={{ maxLength: 2 }}
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={12} md={3}>
										<RegisterMaskedTextInput
											label={t(Labels.REGISTER_DRIVER_FORM_CNH_NUMBER)}
											name="NumeroCNH"
											mask="99999999999"
										/>
									</Grid>

									<Grid item xs={12} md={1}>
										<RegisterSelect
											label={t(Labels.REGISTER_DRIVER_FORM_CATEGORY)}
											name="CategoriaCNH"
											options={Constants.CNH_CATEGORIES}
										/>
									</Grid>

									<Grid item xs={12} md={2}>
										<RegisterDatePicker
											label={t(Labels.REGISTER_DRIVER_FORM_CNH_RENOVATION_DAY)}
											name="DataRenovacaoCNH"
										/>
									</Grid>

									<Grid item xs={12} md={2}>
										<RegisterInputText label={t(Labels.REGISTER_DRIVER_FORM_CNH_CITY)} name="CidadeCNH" />
									</Grid>

									<Grid item xs={12} md={2}>
										<RegisterInputText label={t(Labels.REGISTER_DRIVER_FORM_CARD)} name="Identificador" />
									</Grid>

									<Grid item xs={12} md={2}>
										<RegisterCheckbox
											label={t(Labels.REGISTER_DRIVER_FORM_STATUS)}
											name="Ativo"
											size="medium"
											color="primary"
											labelLegend={t(Labels.REGISTER_DRIVER_FORM_STATUS_LABEL)}
										/>
									</Grid>

									<Grid item xs={12} md={8}>
										<RegisterInputText
											label={t(Labels.REGISTER_DRIVER_FORM_COMENTARY)}
											name="Descricao"
											inputProps={{ maxLength: 100 }}
										/>
									</Grid>

									<Grid item xs={12} md={4} style={{ display: "flex" }}>
										<Grid container direction="row" justify="flex-end" spacing={0}>
											<Grid item xs={12} md={5}>
												<Box className="button-form button-form-refactor">
													<Button
														id="driver-cancel-button"
														className="cancel-button cancel-button-refactor"
														variant="contained"
														size="large"
														disableElevation
														fullWidth
														onClick={() => {
															props.comeback();
														}}
														disabled={loading.driver}>
														<CancelIcon />

														{t(Labels.REGISTER_DRIVER_FORM_BUTTON_CANCEL)}
													</Button>
												</Box>
											</Grid>
											<Grid item xs={12} md={7}>
												<Box className="button-form button-form-refactor">
													<Button
														id="driver-submit-button"
														variant="contained"
														className="save-button save-button-refactor"
														color="primary"
														size="large"
														fullWidth
														disableElevation
														onClick={submitForm}
														disabled={loading.driver}
														startIcon={loading.driver ? <CircularProgress size={18} /> : <ConfirmIcon />}>
														{t(Labels.REGISTER_DRIVER_FORM_BUTTON_CONFIRM)}
													</Button>
												</Box>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={3}></Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Card>
	);
};

export default DriverForm;
