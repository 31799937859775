import { Button, Container, Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ComeBackIcon } from "~/components/common/icons/Icons";
import Page from "~/components/common/page/Page";
import RegisterGroupOfDrivers from "~/components/groupOfDrivers/register/RegisterGroupOfDrivers";
import Labels from "~/helpers/enums/Labels";

const RegisterGroupOfDriversView = () => {
	const { t } = useTranslation();
	let history = useHistory();

	const comeBack = () => {
		history.push("/register/groupOfdrivers");
	};

	return (
		<>
			<Page
				topButtons={
					<Button
						className="comeback-button comeback-top"
						id="extractReport"
						variant="contained"
						size="small"
						disableElevation
						startIcon={<ComeBackIcon />}
						onClick={() => {
							comeBack();
						}}>
						{t(Labels.GROUP_DRIVER_COMEBACK)}
					</Button>
				}
				bottomStyle={{ justifyContent: "flex-end" }}
				bottomButtons={
					<Button
						className="comeback-button"
						id="extractReport"
						variant="contained"
						size="small"
						disableElevation
						startIcon={<ComeBackIcon />}
						onClick={() => {
							comeBack();
						}}>
						{t(Labels.GROUP_DRIVER_COMEBACK)}
					</Button>
				}>
				<main>
					<Container maxWidth="lg" className="containerRegisterGroupDrivers container-map-view">
						<Grid container>
							<Grid item xs={12}>
								<RegisterGroupOfDrivers />
							</Grid>
						</Grid>
					</Container>
				</main>
			</Page>
		</>
	);
};

export default RegisterGroupOfDriversView;
