import React from "react";
import { Modal, Backdrop, Fade, makeStyles, IconButton } from "@material-ui/core";
import { CloseIcon } from "~/components/common/icons/Icons"; // Seu ícone personalizado

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "8px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(5, 3),
        position: "relative",
        width: 500,
        textAlign: "center",
        maxWidth: "95%", // Para garantir que a modal seja responsiva
    },
    closeButton: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
    title: {
        fontSize: "20px",
        fontWeight: "bold",
        marginBottom: theme.spacing(2),
    },
    text: {
        fontSize: "14px",
        lineHeight: 1.5,
        margin: theme.spacing(2, 0),
    },
    link: {
        color: "#007bff",
        fontWeight: "bold",
        textDecoration: "none",
    },
    separete: {
        width: "100%",
        height: "1px",
        backgroundColor: "#838584",
        border: "none",
        margin: theme.spacing(2, 0),
    },
    understoodLink: {
        position: "absolute",
        right: theme.spacing(3),
        bottom: theme.spacing(2),
        color: "#007bff",
        textDecoration: "underline",
        fontSize: "16px",
        cursor: "pointer",
        '&:hover': {
            color: "#0056b3",
        },
    },
}));

const RenewInfoAlert = ({ isOpen, onClose }) => {
    const classes = useStyles();

    return (
        <Modal
            open={isOpen}
            onClose={null}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            className={classes.modal}>
            <Fade in={isOpen}>
                <div className={classes.paper}>
                    <IconButton className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                    <h2 className={classes.title}>Pedido Concluído</h2>
                    <div className={classes.separete}></div>
                    <span className={classes.text}>
                        Pronto! Seu pedido foi concluído com sucesso.
                    </span>
                    <span className={classes.text}>
                        Agora é só aguardar que em breve você irá receber um e-mail do nosso parceiro{" "}
                        <a href="https://www.ceabs.com.br/" className={classes.link}>
                            CEABS Serviços S.A
                        </a>
                        {" "}com detalhes, contrato e link para aceite eletrônico.
                    </span>

                    {/* Link "Entendi" no canto inferior direito */}
                    <a className={classes.understoodLink} onClick={onClose}>
                        Entendi
                    </a>
                </div>
            </Fade>
        </Modal>
    );
};

export default RenewInfoAlert;
