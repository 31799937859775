import axios from "axios";


function base64Encode(credentials) {
    return Buffer.from(credentials).toString("base64");
}

const username = "t_disys.clermans";
const password = "Clip@3070";
const encodedCredentials = base64Encode(`${username}:${password}`);


const apiInstance = axios.create({
    baseURL: "https://core.ceabs.com.br/CEABS_Api/rest/AV",
    headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
    },
    timeout: 10000,
});


export const fetchAddressByCEP = async (cep) => {
    try {
        const response = await apiInstance.get(`/BuscaCEP?CEP=${cep}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`Erro na requisição: ${response.status}`);
        }
    } catch (error) {
        console.error("Erro ao buscar o endereço:", error);
        throw error;
    }
};
