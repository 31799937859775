// REGISTER

import Constants from "~/helpers/enums/Constants";

const initialState = {
	editForm: {},
	odometerForm: {},
	vehicle: {},
	vehicleFilter: {
		limit: 10,
		search: "",
		page: 0
	},
	vehicles: [],
	msg: "",
};

function vehicleReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.LIST_VEHICLES:
			return {
				...state,
				vehicles: action.payload.data,
			};
		case Constants.CLEAN_VEHICLE_LIST:
			return initialState;
		case Constants.UPDATE_VEHICLE_FILTER:
			return {
				...state,
				vehicleFilter: {
					...state.vehicleFilter,
					...action.payload,
				},
			};
		case Constants.GET_VEHICLE_BY_ID:
			return {
				...state,
				vehicle: action.payload
			}
		case Constants.GET_ODOMETER_BY_ID:
			return {
				...state,
				odometer: action.payload
			}
		case Constants.UPDATE_VEHICLE_EDIT_FORM:
			return {
				...state,
				editForm: action.payload,
			};
		case Constants.UPDATE_VEHICLE_ODOMETER_FORM:
			return {
				...state,
				odometerForm: action.payload,
			};
		default:
			return state;
	}
}

export default vehicleReducer;
