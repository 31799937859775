import Constants from "~/helpers/enums/Constants";

import Utils from "~/helpers/Utils";

import dashboardTopTenService from "~/services/dashboard/dashboardTopTenService";

const getKmRunVehicles = (filter, LOADING_IDENTIFICATOR = "getKmRunVehicles") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunVehicles(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_VEHICLE, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

const getKmRunCommercialVehicles = (filter, LOADING_IDENTIFICATOR = "getKmRunVehicles") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunCommercialVehicles(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_COMMERCIAL_VEHICLE, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

const getKmRunNotCommercialVehicles = (filter, LOADING_IDENTIFICATOR = "getKmRunVehicles") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunNotCommercialVehicles(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_NOT_COMMERCIAL_VEHICLE, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

const getKmRunWeekendVehicles = (filter, LOADING_IDENTIFICATOR = "getKmRunVehicles") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunWeekendVehicles(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_WEEKEND_VEHICLE, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

/* DRIVERS */

const getKmRunDrivers = (filter, LOADING_IDENTIFICATOR = "getKmRunDrivers") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunDrivers(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_DRIVER, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

const getKmRunCommercialDrivers = (filter, LOADING_IDENTIFICATOR = "getKmRunDrivers") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunCommercialDrivers(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_COMMERCIAL_DRIVER, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

const getKmRunNotCommercialDrivers = (filter, LOADING_IDENTIFICATOR = "getKmRunDrivers") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunNotCommercialDrivers(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_NOT_COMMERCIAL_DRIVER, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

const getKmRunWeekendDrivers = (filter, LOADING_IDENTIFICATOR = "getKmRunDrivers") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunWeekendDrivers(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_WEEKEND_DRIVER, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

/* analyze VEHICLES */

const getKmRunAnalyzeExcessSpeedingVehicles = (filter, LOADING_IDENTIFICATOR = "getKmRunAnalyzeVehicles") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunAnalyzeExcessSpeedingVehicles(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_ANALYZE_SPEEDING_VEHICLE, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

const getKmRunAnalyzeBrusqueAccelerationVehicles = (filter, LOADING_IDENTIFICATOR = "getKmRunAnalyzeVehicles") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunAnalyzeBrusqueAccelerationVehicles(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_ANALYZE_BRUSQUE_ACCELARATION_VEHICLE, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

const getKmRunAnalyzeBrusqueCurveVehicles = (filter, LOADING_IDENTIFICATOR = "getKmRunAnalyzeVehicles") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunAnalyzeBrusqueCurveVehicles(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_ANALYZE_BRUSQUE_CURVE_VEHICLE, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

const getKmRunAnalyzeBrusqueBrakedVehicles = (filter, LOADING_IDENTIFICATOR = "getKmRunAnalyzeVehicles") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunAnalyzeBrusqueBrakedVehicles(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_ANALYZE_BRUSQUE_BRAKED_VEHICLE, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

const getKmRunAnalyzeDisplacementNotCommercialVehicles = (filter, LOADING_IDENTIFICATOR = "getKmRunAnalyzeVehicles") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunAnalyzeDisplacementNotCommercialVehicles(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_ANALYZE_DISPLACEMENT_NOT_COMMERCIAL_VEHICLE, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

const getKmRunAnalyzeDisplacementWeekendVehicles = (filter, LOADING_IDENTIFICATOR = "getKmRunAnalyzeVehicles") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunAnalyzeDisplacementWeekendVehicles(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_ANALYZE_DISPLACEMENT_WEEKEND_VEHICLE, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

/* analyze DRIVERS */

const getKmRunAnalyzeExcessSpeedingDrivers = (filter, LOADING_IDENTIFICATOR = "getKmRunAnalyzeDrivers") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunAnalyzeExcessSpeedingDrivers(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_ANALYZE_SPEEDING_DRIVER, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

const getKmRunAnalyzeBrusqueAccelerationDrivers = (filter, LOADING_IDENTIFICATOR = "getKmRunAnalyzeDrivers") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunAnalyzeBrusqueAccelerationDrivers(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_ANALYZE_BRUSQUE_ACCELARATION_DRIVER, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

const getKmRunAnalyzeBrusqueCurveDrivers = (filter, LOADING_IDENTIFICATOR = "getKmRunAnalyzeDrivers") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunAnalyzeBrusqueCurveDrivers(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_ANALYZE_BRUSQUE_CURVE_DRIVER, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

const getKmRunAnalyzeBrusqueBrakedDrivers = (filter, LOADING_IDENTIFICATOR = "getKmRunAnalyzeDrivers") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunAnalyzeBrusqueBrakedDrivers(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_ANALYZE_BRUSQUE_BRAKED_DRIVER, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

const getKmRunAnalyzeDisplacementNotCommercialDrivers = (filter, LOADING_IDENTIFICATOR = "getKmRunAnalyzeDrivers") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunAnalyzeDisplacementNotCommercialDrivers(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_ANALYZE_DISPLACEMENT_NOT_COMMERCIAL_DRIVER, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

const getKmRunAnalyzeDisplacementWeekendDrivers = (filter, LOADING_IDENTIFICATOR = "getKmRunAnalyzeDrivers") => (dispatch) => {
  dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

  return dashboardTopTenService
    .getKmRunAnalyzeDisplacementWeekendDrivers(filter)
    .then((response) => {
      dispatch({ type: Constants.GET_KM_RUN_ANALYZE_DISPLACEMENT_WEEKEND_DRIVER, payload: response.data });
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
    });
};

const setVehicle = (item) => (dispatch) => {
  dispatch({ type: Constants.SET_VEHICLE, payload: item });
}

export default {
  getKmRunVehicles,
  getKmRunCommercialVehicles,
  getKmRunNotCommercialVehicles,
  getKmRunWeekendVehicles,
  setVehicle,

  /* DRIVERS */
  getKmRunDrivers,
  getKmRunCommercialDrivers,
  getKmRunNotCommercialDrivers,
  getKmRunWeekendDrivers,

  /* analyze vehicles */
  getKmRunAnalyzeExcessSpeedingVehicles,
  getKmRunAnalyzeBrusqueAccelerationVehicles,
  getKmRunAnalyzeBrusqueBrakedVehicles,
  getKmRunAnalyzeBrusqueCurveVehicles,
  getKmRunAnalyzeDisplacementNotCommercialVehicles,
  getKmRunAnalyzeDisplacementWeekendVehicles,

    /* analyze DRIVERS */
    getKmRunAnalyzeExcessSpeedingDrivers,
    getKmRunAnalyzeBrusqueAccelerationDrivers,
    getKmRunAnalyzeBrusqueBrakedDrivers,
    getKmRunAnalyzeBrusqueCurveDrivers,
    getKmRunAnalyzeDisplacementNotCommercialDrivers,
    getKmRunAnalyzeDisplacementWeekendDrivers

};
