import React, { useCallback, useEffect } from "react";
import { Box } from "@material-ui/core";
import TableCard from "~/components/tableCard/TableCard";
import Search from "~/components/common/search/Search";
import Pagination from "~/components/common/pagination/Pagination";
import MenuRange from "~/components/common/menuRange/MenuRange";
import { useDispatch, useSelector } from "react-redux";
import googleMapsDataAction from "~/actions/googleMapsDataAction";
import mapAction from "~/actions/mapAction";
import Portal from "@material-ui/core/Portal";
import useFuso from "~/components/common/fuso/useFuso";
import Utils from "~/helpers/Utils";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import "./Tab.scss";

const TabMapVehicle = (props) => {
    const dispatch = useDispatch();
    const { fromUtc } = useFuso();
    const { t } = useTranslation();
    const { filters } = useSelector((state) => state.mapToolbar); // menu retratil
    const { filter, data } = useSelector((state) => state.mapFilterData);
    const isHorizontal = props.mode.orientation === "horizontal";

    useEffect(() => {
        dispatch(mapAction.dataMapFilter(filter));
    }, [dispatch, filter]);

    useEffect(() => {
        dispatch(googleMapsDataAction.updateObjects(data.Data));
        return () => {
            dispatch(googleMapsDataAction.cleanObjects());
        };
    }, [dispatch, data.Data]);

    useEffect(() => {
        return () => {
            dispatch(googleMapsDataAction.cleanObjects());
        };
    }, [dispatch]);

    const searchColumnsformat = (filterLabel, filter) => {
        const selectedHeader = filters.find((item) => item.label === filterLabel);
        const name = selectedHeader.searchName;
        const data = selectedHeader.searchData;
        return [
            {
                Name: name,
                Data: data,
                OrderTable: true,
                OrderDirection:
                    filter.selectedLabel === filterLabel
                        ? filter.SearchColumns[0].OrderDirection === "desc"
                            ? "asc"
                            : "desc"
                        : "desc",
            },
        ];
    };

    const updateHeaderSortOrder = (event) => {
        dispatch(
            mapAction.updateDataMapFilter({
                ...filter,
                selectedLabel: event,
                SearchColumns: searchColumnsformat(event, filter),
            })
        );
    };

    const SearchField = useCallback(
        ({ filter: _filter }) => (
            <Box className={`map-tab-header`}>
                <Search
                    id="searchVehicle"
                    className={"search search-input-refactor"}
                    value={_filter.term}
                    search={(term) => {
                        dispatch(
                            mapAction.updateDataMapFilter({
                                ..._filter,
                                index: 0,
                                term: term,
                            })
                        );
                    }}
                />
                <div style={{ marginLeft: "10px" }}>
                    <MenuRange
                        id={"vehicle"}
                        label={t(Labels.MENU_RANGE_SHOW)}
                        className="menu-range-refactor"
                        value={_filter.limit}
                        onChange={(event) => {
                            dispatch(
                                mapAction.updateDataMapFilter({
                                    ..._filter,
                                    index: 0,
                                    limit: event.target.value,
                                })
                            );
                        }}
                        options={[10, 25, 50, 100, 250, 500]}
                    />
                </div>
            </Box>
        ),
        [dispatch, t]
    );

    return (
        <Box className={"map-tab floating-header"}>
            <Box
                className="map-tab-main"
                style={{
                    paddingTop: isHorizontal ? "3rem" : "0",
                }}>
                {isHorizontal ? (
                    <SearchField filter={filter} />
                ) : (
                    <Portal container={props.containerSerach.current}>
                        <SearchField filter={filter} />
                    </Portal>
                )}
                <TableCard
                    id={"card-item-vehicle"}
                    mode={props.mode}
                    filters={filters}
                    data={data.Data.map((element) => {
                        return {
                            ...element,
                            Velocidade: Utils.formatSpeed(element.Velocidade),
                            DataHoraEventoLocal:
                                element.DataHoraEventoLocal &&
                                fromUtc(element.DataHoraEventoLocal, "YYYY-MM-DDTHH:mm:ss").format(
                                    "DD/MM/YYYY | HH:mm:ss"
                                ),
                        };
                    })}
                    onItemClick={(element) => {
                        dispatch(
                            googleMapsDataAction.centralizePoints([
                                {
                                    lat: element.Latitude,
                                    lng: element.Longitude,
                                },
                            ])
                        );
                    }}
                    onHeaderClick={updateHeaderSortOrder}
                    selectedLabel={filter.selectedLabel}
                    order={filter.SearchColumns[0].OrderDirection}
                />
            </Box>

            <Box className="pagination-tabmap">
                <Pagination
                    onChange={(event, value) => {
                        dispatch(
                            mapAction.updateDataMapFilter({
                                ...filter,
                                index: value - 1,
                            })
                        );
                    }}
                    page={filter.index + 1}
                    count={Math.ceil(data.iTotalRecords / filter.limit)}
                />
            </Box>
        </Box>
    );
};

export default TabMapVehicle;
