import React from "react";

import { Container, Grid } from "@material-ui/core";

import Page from "~/components/common/page/Page";
import CommunicatedList from "~/components/communicated/CommunicatedList";

const Communicated = () => {
	
	return (
		<>
			<Page>
				<main>
					<Container maxWidth="lg" className="container-map-view">
						<Grid container>
							<Grid item xs={12}>
								<CommunicatedList />
							</Grid>
						</Grid>
					</Container>
				</main>
			</Page>
		</>
	);
};

export default Communicated;
