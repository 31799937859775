import Constants from "~/helpers/enums/Constants";
import dashboardService from "~/services/dashboard/dashboardService";
import Utils from "~/helpers/Utils";
import PeriodTripsFormatter from "~/components/dashboard/chartPie/PeriodTripsFormatter";
import RoadOffsetFormatter from "~/components/dashboard/chartPie/RoadOffsetFormatter";
import KmRunFormatter from "~/components/dashboard/kmRun/KmRunFormatter";
import KmRunPeriodWeekFormatter from "~/components/dashboard/kmRunWeek/KmRunPeriodWeekFormatter";
import AnalysisItemsFormatter from "~/components/dashboard/analysisItems/AnalysisItemsFormatter";
import _ from "lodash";
import ProbabilityFormatter from "~/components/dashboard/chartFunnel/AccidentProbabilityFormatter";

const getTravel = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

    return dashboardService
        .travel(filter)
        .then((response) => {
		   
			if (response.data.length === 0) {
                dispatch({ type: Constants.CLEAN_DASHBOARD_TRAVELS });
				return
			}

            dispatch({ type: Constants.GET_KM_RUN, payload: KmRunFormatter.customChart(response) });
            dispatch({ type: Constants.GET_PERIOD_TRIPS, payload: PeriodTripsFormatter.customChart(response) });
            dispatch({ type: Constants.GET_KM_RUN_INDICATOR, payload: KmRunFormatter.extractData(response) });
            dispatch({ type: Constants.GET_ROAD_OFFSET, payload: RoadOffsetFormatter.customChart(response) });
            dispatch({ type: Constants.GET_ANALYSIS_ITEMS, payload: AnalysisItemsFormatter.extractData(response) });
            dispatch({ type: Constants.GET_ACCIDENT_PROBABILITY, payload: ProbabilityFormatter.extractData(response) });
        })
        .catch((error) => console.error(error))
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getKmRunWeek = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return dashboardService
        .peerWeek(filter)
        .then((response) => {
            dispatch({ type: Constants.GET_KM_RUN_WEEK, payload: KmRunPeriodWeekFormatter.customChart(response) });
        })
        .catch((error) => console.log(error))
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getFleetEvolution = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return dashboardService
        .fleetEvolution(filter)
        .then(({ data }) => {
            let payload = _.isString(data) ? {} : data;
            dispatch({ type: Constants.GET_FLEET_EVOLUTION, payload });
        })
        .catch((error) => console.log(error))
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getMonthlyFleetEvolution = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
    return dashboardService
        .monthlyFleetEvolution(filter)
        .then(({ data }) => {
            let payload = _.isString(data) ? [] : data;
            dispatch({ type: Constants.GET_MONTHLY_FLEET_EVOLUTION, payload });
        })
        .catch((error) => console.log(error))
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getScores = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

    return dashboardService
        .scores(filter)
        .then((response) => {
            dispatch({ type: Constants.GET_SCORE, payload: response.data || {} });
        })
        .catch((error) => console.log(error))
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getTopTenMaxVelocityVehicle = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

    return dashboardService
        .topTenMaxVelocityVehicle(filter)
        .then(({ data }) => {
            dispatch({ type: Constants.GET_TOP_TEN_MAX_VELOCITY_VEHICLE, payload: data });
        })
        .catch((error) => console.log(error))
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getTopTenMaxVelocityDriver = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

    return dashboardService
        .topTenMaxVelocityDriver(filter)
        .then(({ data }) => {
            dispatch({ type: Constants.GET_TOP_TEN_MAX_VELOCITY_DRIVER, payload: data });
        })
        .catch((error) => console.log(error))
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getTravelMaxVelocity = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

    return dashboardService
        .travelMaxVelocity(filter)
        .then(({ data }) => {
            dispatch({ type: Constants.GET_TRAVEL_MAX_VELOCITY, payload: data });
        })
        .catch((error) => console.log(error))
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const getTravelMaxVelocityPositions = (filter, LOADING_IDENTIFICATOR = "") => (dispatch) => {
    dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

    return dashboardService
        .travelMaxVelocityPositions(filter)
        .then(({ data }) => {
            dispatch({ type: Constants.GET_TRAVEL_MAX_VELOCITY_POSITIONS, payload: data });
        })
        .catch((error) => console.log(error))
        .finally(() => {
            dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
        });
};

const toogleDetailsKmRun = (isVisible) => (dispatch) => {
    dispatch({ type: Constants.TOGGLE_DETAILS_KM_RUN, payload: isVisible });
};

export default {
    // getPeriodTrips,
    getFleetEvolution,
    getMonthlyFleetEvolution,
    getKmRunWeek,
    // getKmRun,
    getTravel,
    getScores,
    getTopTenMaxVelocityVehicle,
    getTopTenMaxVelocityDriver,
    getTravelMaxVelocity,
    getTravelMaxVelocityPositions,
    toogleDetailsKmRun,
};
