import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import dashboardActionsTopTen from "~/actions/dashboard/dashboarTopTenAction";
import VehicleIconComponent from "~/components/common/vehicleIcon/VehicleIconComponent";
import { Typography } from "@material-ui/core";
import { prefixWithZeros } from "~/helpers/Utils";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import BoxTab from "~/components/common/boxTab/BoxTab";
import ProgressBar from "~/components/common/progressBar/ProgressBar";
import { Link } from "react-router-dom";

const TopAnalyzeVehicles = ({ filter }) => {
    const dispatch = useDispatch();
    const [kmActive, setKmActive] = useState("KmRunAnalyzeSpeedingVehicles");
    const { t } = useTranslation();

    const { dataAnalyzeVehiclesTop } = useSelector((state) => state.dashboardTopTen);
    const { loading } = useSelector((state) => state.app);

    const getKmRunAnalyzeSpeedingVehicles = () => {
        setKmActive("KmRunAnalyzeSpeedingVehicles");
        dispatch(dashboardActionsTopTen.getKmRunAnalyzeExcessSpeedingVehicles({ ...filter }));
    };

    const getKmRunAnalyzeBrusqueAccelerationVehicles = () => {
        setKmActive("KmRunAnalyzeBrusqueAccelerationVehicles");
        dispatch(dashboardActionsTopTen.getKmRunAnalyzeBrusqueAccelerationVehicles({ ...filter }));
    };

    const getKmRunAnalyzeBrusqueCurveVehicles = () => {
        setKmActive("KmRunAnalyzeBrusqueCurveVehicles");
        dispatch(dashboardActionsTopTen.getKmRunAnalyzeBrusqueCurveVehicles({ ...filter }));
    };

    const getKmRunAnalyzeBrusqueBrakedVehicles = () => {
        setKmActive("KmRunAnalyzeBrusqueBrakedVehicles");
        dispatch(dashboardActionsTopTen.getKmRunAnalyzeBrusqueBrakedVehicles({ ...filter }));
    };

    const getKmRunAnalyzeDisplacementNotCommercialVehicles = () => {
        setKmActive("KmRunAnalyzeDisplacementNotCommercialVehicles");
        dispatch(dashboardActionsTopTen.getKmRunAnalyzeDisplacementNotCommercialVehicles({ ...filter }));
    };

    const getKmRunAnalyzeDisplacementWeekendVehicles = () => {
        setKmActive("KmRunAnalyzeDisplacementWeekendVehicles");
        dispatch(dashboardActionsTopTen.getKmRunAnalyzeDisplacementWeekendVehicles({ ...filter }));
    };

    useEffect(() => {
        if (!filter) return;

        switch (kmActive) {
            case "KmRunAnalyzeSpeedingVehicles":
                getKmRunAnalyzeSpeedingVehicles();
                break;
            case "KmRunAnalyzeBrusqueAccelerationVehicles":
                getKmRunAnalyzeBrusqueAccelerationVehicles();
                break;
            case "KmRunAnalyzeBrusqueCurveVehicles":
                getKmRunAnalyzeBrusqueCurveVehicles();
                break;
            case "KmRunAnalyzeBrusqueBrakedVehicles":
                getKmRunAnalyzeBrusqueBrakedVehicles();
                break;
            case "KmRunAnalyzeDisplacementNotCommercialVehicles":
                getKmRunAnalyzeDisplacementNotCommercialVehicles();
                break;
            case "KmRunAnalyzeDisplacementWeekendVehicles":
                getKmRunAnalyzeDisplacementWeekendVehicles();
                break;
            default:
                getKmRunAnalyzeSpeedingVehicles();
                break;
        }

        // eslint-disable-next-line
    }, [filter]);

    const getDataHighestValue = useCallback(
        (dist) => {
            let highest = dataAnalyzeVehiclesTop[0]["Valor"];
            return (dist / highest) * 100;
        },
        [dataAnalyzeVehiclesTop]
    );

    const LISTBUTTON = [
        {
            label: t(Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_SPEEDING),
            onClick: () => {
                getKmRunAnalyzeSpeedingVehicles();
            },
            column: "KmRunAnalyzeSpeeding",
        },
        {
            label: t(Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_BRUSQUE_ACCELERATION),
            onClick: () => {
                getKmRunAnalyzeBrusqueAccelerationVehicles();
            },
            column: "KmRunAnalyzeBrusqueAcceleration",
        },
        {
            label: t(Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_BRUSQUE_CURVE),
            onClick: () => {
                getKmRunAnalyzeBrusqueCurveVehicles();
            },
            column: "KmRunAnalyzeBrusqueCurve",
        },
        {
            label: t(Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_BRUSQUE_BRAKED),
            onClick: () => {
                getKmRunAnalyzeBrusqueBrakedVehicles();
            },
            column: "KmRunAnalyzeBrusqueBraked",
        },
        {
            label: t(Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_DISPLACEMENT_NOT_COMMERCIAL),
            onClick: () => {
                getKmRunAnalyzeDisplacementNotCommercialVehicles();
            },
            column: "KmRunAnalyzeDisplacementNotCommercial",
        },
        {
            label: t(Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TAB_KM_RUN_ANALYZE_DISPLACEMENT_WEEKEND),
            onClick: () => {
                getKmRunAnalyzeDisplacementWeekendVehicles();
            },
            column: "KmRunAnalyzeDisplacementWeekend",
        },
    ];

    const defaultLine = useMemo(() => {
        return [
            {
                name: "",
                label: "",
                width: "50px",
                render: (itemName, item, count) => (
                    <Typography className="countItem">{prefixWithZeros(count + 1, 2)}</Typography>
                ),
            },
            {
                name: "TipoVeiculo",
                label: "",
                width: "10%",
                render: () => (
                    <span className="flex-justify-center">
                        <VehicleIconComponent type={"veiculo"} />
                    </span>
                ),
            },
            {
                name: "Placa",
                label: t(Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TABLE_PLATE),
                width: "20%",
                render: (itemName, item, count) => (
                    <Link to="/dashboardVehicle/" target="_blank" style={{ textDecoration: "none" }}>
                        <Typography
                            onClick={() => {
                                dispatch(dashboardActionsTopTen.setVehicle(item));
                            }}
                            style={{ cursor: "pointer" }}
                            className="gridDataFont rowFont fontDist">
                            {itemName}
                        </Typography>
                    </Link>
                ),
            },
            {
                name: "Valor",
                label: t(Labels.DASHBOARD_TOP_TEN_ANALYSIS_ITEMS_TABLE_VALUE),
                render: (dist) => (
                    <span className="progressAnalyze" style={{ display: "flex" }}>
                        <ProgressBar value={getDataHighestValue(dist)} />
                        <Typography className="gridDataFont rowFont fontDist progressBar-label">
                            {dist ? dist.toLocaleString("pt-br") : 0}
                        </Typography>
                    </span>
                ),
            },
        ];
    }, [dispatch, getDataHighestValue, t]);

    const COLUMNS = [
        {
            KmRunAnalyzeSpeeding: [...defaultLine],
            KmRunAnalyzeBrusqueAcceleration: [...defaultLine],
            KmRunAnalyzeBrusqueCurve: [...defaultLine],
            KmRunAnalyzeBrusqueBraked: [...defaultLine],
            KmRunAnalyzeDisplacementNotCommercial: [...defaultLine],
            KmRunAnalyzeDisplacementWeekend: [...defaultLine],
        },
    ];

    return (
        <BoxTab
            data={dataAnalyzeVehiclesTop}
            listButton={LISTBUTTON}
            columns={COLUMNS}
            loading={loading.getKmRunAnalyzeVehicles}
            defaultColumn={"KmRunAnalyzeSpeeding"}
        />
    );
};

export default TopAnalyzeVehicles;
