import React from "react";

import { Button, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    progressButton: {
        width: "100%",
    },
    button: {
        background: theme.palette.primary.main,
        color: "#fff",
        height: "20px",
        "&:hover": {
            background: theme.palette.primary.main,
        },
    },
    boxProgress: {
        position: "relative",
    },
    percent: {
        position: "absolute",
        top: 3,
        left: "50%",
        width: 60,
        color: theme.palette.primary.main,
        fontFamily: "'Trebuchet MS', sans-serif",
        textAlign: "center",
        fontSize: 11,
    },
    progressBar: {
        background: `${theme.palette.primary.main}17`,
        height: 19,
        borderRadius: 3,
        "& > div": {
            background: `${theme.palette.primary.main}35`,
        },
    },
}));

const ProgressButton = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.progressButton} id={`box${props.id || ""}`}>
            {props.progress === 100  && props.error !== "Erro" ? (
                <Button
                    id={props.id || ""}
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={props.onClick}>
                    {props.children}
                </Button>
            ) : (
                <div className={classes.boxProgress}>
                    <LinearProgress className={classes.progressBar} variant="determinate" value={props.error !== "Erro" ? props.progress || 0 : 0} />
                    <span className={classes.percent}>{props.error !== "Erro" ? Math.round(props.progress) || 0 : 0}%</span>
                </div>
            )}
        </div>
    );
};

export default ProgressButton;
