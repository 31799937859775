import React from 'react';
import LoginMain from '~/components/login/LoginMain';
import BuildTag from '~/components/common/info/buildTag';

const Login = () => {

  return (
    <>
      <BuildTag/>
      <LoginMain />
    </>
  );
};

export default Login;