import { Button, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import travelsReportAction from "~/actions/reporting/travelsReportAction";
import { SaveReportIcon } from "~/components/common/icons/Icons";
import DriverAutoComplete from "~/components/reporting/common/driver/AutoCompleteDriver";
import AutoCompleteFleet from "~/components/reporting/common/fleet/AutoCompleteFleet";
import ReportPeriodField from "~/components/reporting/common/ReportPeriodField";
import VehicleAutocomplete from "~/components/reporting/common/vehicle/VehicleAutocomplete";
import Labels from "~/helpers/enums/Labels";
import Utils from "~/helpers/Utils";
import "../../common/ReportingCommon.scss";
import "./Object.scss";

const FiltersObject = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const objectForm = useRef();

    useEffect(() => {
        dispatch(travelsReportAction.updateObjectForm(objectForm.current));
    }, [dispatch, objectForm]);

    return (
        <Formik
            innerRef={objectForm}
            initialValues={{
                fleet: null,
                period: Utils.getTodayPeriod(),
                vehicle: null,
                driver: null,
                limit: 25,
                index: 0,
                typeReport: 30,
            }}
            validate={(values) => {
                const errors = {};

                if (!values.vehicle && !values.fleet) {
                    errors.vehicle = t(Labels.REPORT_EVENT_VEHICLE_MSG_OBLIGATED);
                    errors.fleet = t(Labels.REPORT_EVENT_FLEET_MSG_OBLIGATED);
                }

                if (!values.period) {
                    errors.period = t(Labels.REPORT_EVENT_PERIOD_MSG_OBLIGATED);
                }

                if (values.period && Utils.diffInDays(values.period.endDate, values.period.startDate) > 7) {
                    errors.period = t(Labels.REPORT_EVENT_PERIOD_MSG_PERIOD_WEEK);
                }

                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                dispatch(
                    travelsReportAction.updateFilterTravelObject({
                        ...values,
                        period: {
                            startDate: Utils.utcToLocal(values.period.startDate),
                            endDate: Utils.utcToLocal(values.period.endDate),
                        },
                    })
                );
                setSubmitting(false);
            }}>
            {({ submitForm, setFieldValue, handleChange }) => (
                <Form>
                    <Grid container justify="right" alignItems="flex-end" spacing={1}>
                        <Grid item xs={3}>
                            <VehicleAutocomplete
                                id="vehicle-Combo"
                                name="vehicle"
                                label="Veículo"
                                placeholder="Digite Placa, Frota, Marca ou Modelo"
                                onChange={() => {
                                    setFieldValue("fleet", null);
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <AutoCompleteFleet
                                id="fleet-Combo"
                                name="fleet"
                                label={t(Labels.REPORT_LABEL_FLEET)}
                                placeholder={t(Labels.REPORT_LABEL_PLACEHOLDER)}
                                onChange={() => {
                                    setFieldValue("vehicle", null);
                                }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <ReportPeriodField
                                dontShowMonthRanges={true}
                                id="id-period-date"
                                label="Período"
                                name="period"
                                hours
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <DriverAutoComplete
                                id="driver-combo"
                                name="driver"
                                label="Motorista"
                                placeholder="Digite para Pesquisar..."
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <Button
                                id="travel-filter-object-save-button"
                                className="report-save-button report-save-button-refactor"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disableElevation
                                startIcon={<SaveReportIcon />}
                                onClick={submitForm}>
                                {t(Labels.REPORT_EVENT_SAVE_BUTTON)}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
export default FiltersObject;
