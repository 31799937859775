import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Box, Button, CircularProgress } from "@material-ui/core";
import { CancelIcon, ConfirmIcon } from "~/components/common/icons/Icons";
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import vehicleAction from "~/actions/vehicleAction";
import RegisterInputText from "~/components/common/registerInputs/RegisterInputText";
import AutoCompleteDriver from "~/components/reporting/common/driver/AutoCompleteDriver";
import "./Form.scss";

const VehicleForm = (props) => {
    const { t } = useTranslation();
    const { vehicleFilter } = useSelector((state) => state.vehicle);
    const { comeBack } = props;
    const dispatch = useDispatch();
    const refVehicleForm = useRef();
    const updateAction = vehicleAction.updateEditForm;

    useEffect(() => {
        if (refVehicleForm.current) dispatch(updateAction(refVehicleForm));
    }, [dispatch, updateAction]);

    return (
        <Formik
            innerRef={refVehicleForm}
            initialValues={{
                Id: null,
                Apelido: "",
                MotoristaPadrao: {},
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                dispatch(
                    vehicleAction.saveVehicle(
                        {
                            Id: values.Id,
                            Apelido: values.Apelido,
                            MotoristaPadraoId: values.MotoristaPadrao?.id,
                        },
                        (error) => {
                            setSubmitting(false);
                            if (error) {
                                Utils.showError(error);
                                return;
                            }

                            Utils.showTranslatedToast({
                                type: Constants.SUCCESS,
                                description: Labels.VEHICLE_FORM_MSG_SAVE_SUCCESS,
                            });

                            props.comeBack();
                            dispatch(vehicleAction.getListVehicles({ ...vehicleFilter }));
                        }
                    )
                );
            }}>
            {(form) => {
                return (
                    <Form className="ceabs-register-form vehicle-form">
                        <Grid container direction="row">
                            <Grid item xs={12} md={props.compact ? 12 : 5}>
                                <RegisterInputText label={t(Labels.VEHICLE_FORM_NICKNAME)} name="Apelido" />
                            </Grid>

                            <Grid className="vehicle-form-driver-autocomplete" item xs={12} md={props.compact ? 12 : 4}>
                                <AutoCompleteDriver
                                    isRegisterForm
                                    id="driver-combo"
                                    name="MotoristaPadrao"
                                    label={t(Labels.VEHICLE_FORM_DEFAULT_DRIVER)}
                                    placeholder="Digite para Pesquisar..."
                                />
                            </Grid>
                            <Grid item xs={12} md={props.compact ? 12 : 3}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    style={{ height: "100%" }}
                                    spacing={0}>
                                    <Grid item xs={3} md={3}>
                                        <Box className="button-form button-form-refactor">
                                            <Button
                                                id="profile-cancel-button"
                                                className="cancel-button cancel-button-refactor"
                                                variant="contained"
                                                size="large"
                                                disableElevation
                                                fullWidth
                                                onClick={() => {
                                                    form.resetForm();
                                                    comeBack && comeBack();
                                                }}
                                                disabled={props.loading}>
                                                <CancelIcon />

                                                {t(Labels.VEHICLE_FORM_BUTTON_CANCEL)}
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Box className="button-form button-form-refactor">
                                            <Button
                                                id="profile-submit-button"
                                                variant="contained"
                                                className="save-button save-button-refactor"
                                                color="primary"
                                                size="large"
                                                fullWidth
                                                disableElevation
                                                onClick={form.submitForm}
                                                disabled={props.loading}
                                                startIcon={
                                                    props.loading ? <CircularProgress size={18} /> : <ConfirmIcon />
                                                }>
                                                {t(Labels.VEHICLE_FORM_BUTTON_CONFIRM)}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default VehicleForm;
