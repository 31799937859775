import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
	pendingNotices: false,
	communicated: [],
	checkNotification: "",
};

function communicatedReducer(state = INITIAL_STATE, action = null) {
	switch (action.type) {
		case Constants.GET_PENDING_NOTICES:
			return {
				...state,
				pendingNotices: action.payload,
			};
		case Constants.GET_ALL_COMMUNICATED:
			return {
				...state,
				communicated: action.payload,
			};
		case Constants.COMMUNICATE_CHECK_NOTIFICATION:
			return {
				...state,
				checkNotification: action.payload,
			};
		default:
			return state;
	}
}

export default communicatedReducer;
