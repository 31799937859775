import http from "~/config/httpRequest/http";

const recoverPassword = (req) => {
	return new Promise((resolve, reject) => {
		http
			.get(`login/recuperar`, {
				params: {
					email: req
				},
			})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
};

const confirmRecoverPassword = (password, code, username) => {
    return new Promise((resolve, reject) => {
        const apiUrl = "login/ConfirmRecoverPassword"; 

        const requestData = {
                NewPassword: password,
                Code: code,
                Username: username,
        };
        http
            .post(apiUrl, requestData)
            .then((res) => resolve(res))
            .catch((error) => reject(error));
    });
};
export default { recoverPassword,confirmRecoverPassword };
