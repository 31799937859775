import { Card, Typography } from "@material-ui/core";
import React from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { makeStyles } from "@material-ui/core/styles";

import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";

import "./cardTutorial.scss";

const useStyles = makeStyles((theme) => ({
	buttonTutorial: {
		background: "#fff",
		fontWeight: "bold",
		padding: "5px 0",
		border: `1px solid ${theme.palette.primary.main}`,
		display: "inline-block",
		textAlign: "center",
		width: "100%",
		color: theme.palette.primary.main,
		borderRadius: "2px",
		cursor: "pointer",
		textDecoration: "none",
		transition: "0.2s",
		"&:hover": {
			background: theme.palette.primary.main,
			color: "#fff",
		},
	},
}));

function CardTutorial(props) {
	const { t } = useTranslation();

	const { title, imgUrl, description, urlDica } = props;
	const classes = useStyles();
	return (
		<Card className="card-tutorial">
			<div className="title">
				<Typography variant="h3" style={{ fontSize: "20px", fontWeight: "bold", fontColor: "#3D5564" }}>
					{title}
				</Typography>
			</div>

			<img src={imgUrl} alt={title} className="img-tutorial"></img>

			<div>
				<Typography>{description}</Typography>
			</div>
			<a href={urlDica} className={classes.buttonTutorial} target="_blank" rel="noopener noreferrer">
				{t(Labels.CARD_TUTORIAL_BUTTON)}
				<ArrowForwardIosIcon style={{ position: "relative", top: "2px" }} />
			</a>
		</Card>
	);
}

export default CardTutorial;
