import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CardAlert, { AlertCardItem } from "./CardAlert";
import { TextField } from "formik-material-ui";
import { Field } from "formik";
import {
    AlertIcon,
    ArchiveIcon,
    CalendarDarkIcon,
    CheckCircleIcon,
    CloseCircleIcon,
    ForwardNoFillIcon,
    SpeedometerIcon,
    ConfirmIcon,
} from "~/components/common/icons/Icons";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import Labels from "~/helpers/enums/Labels";
import Utils from "~/helpers/Utils";
import Constants from "~/helpers/enums/Constants";
import useFuso from "~/components/common/fuso/useFuso";
import AccordionAlert from "~/components/common/accordion/ContentAccordion";
import alertsActions from "~/actions/alertsActions";
import alertReportAction from "~/actions/reporting/alertReportAction";
import googleMapsDataAction from "~/actions/googleMapsDataAction";
import "./AlertDetails.scss";

const useStyles = makeStyles((theme) => ({
    dividerBox: {
        display: "flex",
        paddingLeft: 30,
    },
    leftDivider: {
        flex: "1 1 auto",
        paddingLeft: 5,
        paddingRight: 3,
        borderLeft: "1px solid #AFC3D2",
    },
    middle: {
        flex: "1",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
    },
}));

const AlertDetails = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { fromUtc } = useFuso();

    const { loading } = useSelector((state) => state.app);
    const { alertHistory } = useSelector((state) => state.alertReport);
    const { alertIdSelected } = useSelector((state) => state.alertsViolated);
    const { alertDetail: alertDetails } = useSelector((state) => state.alertReport);

    useEffect(() => {
        if (!alertDetails || !alertDetails.ObjetoRastreavel) return;

        dispatch(
            googleMapsDataAction.updateObjects([
                {
                    ...alertDetails.ObjetoRastreavel,
                    Latitude: alertDetails.Violacao.Evento.Gps.Latitude,
                    Longitude: alertDetails.Violacao.Evento.Gps.Longitude,
                    TipoEventoTxt: alertDetails.Violacao.Evento.EventoTipo,
                    VelocidadeTxt: alertDetails.Violacao.Evento.Gps.Velocidade,
                    MotoristaNome: alertDetails.Violacao.Evento.MotoristaNome,
                    Localizacao: alertDetails.Violacao.Evento.RevGeo.Localizacao,
                    Ignicao: alertDetails.Violacao.Evento.Ignicao,
                    DataHoraEventoLocal: alertDetails.Violacao.Evento.DataHoraEvento,
                },
            ])
        );

        dispatch(
            googleMapsDataAction.centralizePoints([
                {
                    lat: alertDetails.Violacao.Evento.Gps.Latitude,
                    lng: alertDetails.Violacao.Evento.Gps.Longitude,
                },
            ])
        );

        return () => {
            dispatch(googleMapsDataAction.cleanObjects());
        };
    }, [dispatch, alertDetails]);

    return (
        <div className="alertDetails">
            {alertDetails?.Id ? (
                <Grid container spacing={2} style={{ flexDirection: "column" }}>
                    <Grid item xs={12}>
                        <CardAlert icon={<AlertIcon />} title={t(Labels.ALERT_VIOLATED_DETAILS_ALERT_TITLE)}>
                            <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_ALERT_NAME)}>
                                {alertDetails.Violacao.AlertaNome}
                            </AlertCardItem>
                            <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_ALERT_CRITERY)}>
                                {alertDetails.Violacao.TodasCondicoesVerdadeiras ? "Todas verdadeiras" : "Contém Falsa"}
                            </AlertCardItem>
                            <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_ALERT_ACTIVE_DAYS)}>
                                {Utils.translateDayOfWeek(alertDetails.Violacao.DiasSemana)}
                            </AlertCardItem>
                            <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_ALERT_HOUR)}>
                                {alertDetails.Violacao.HorarioValidacaoFinal}
                            </AlertCardItem>
                            <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_ALERT_TREATMENT)}>
                                {alertDetails.Violacao.DataHoraTratamento &&
                                    fromUtc(alertDetails.Violacao.DataHoraTratamento).format("DD/MM/YYYY | HH:mm")}
                            </AlertCardItem>
                            <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_ALERT_USER_TREATMENT)}>
                                {alertDetails.Violacao.UsuarioTratamento || alertDetails.Violacao.UsuarioConclusao}
                            </AlertCardItem>
                        </CardAlert>

                        <CardAlert icon={<SpeedometerIcon />} title={t(Labels.ALERT_VIOLATED_DETAILS_CONDITIONS)}>
                            {alertDetails.Violacao.Violacoes.map((v) => (
                                <AlertCardItem>
                                    <div className={classes.dividerBox}>
                                        <span className={classes.leftDivider}>{v.DescricaoViolacao}</span>
                                        <span className={classes.leftDivider}>{v.CondicaoViolacao}</span>
                                        <span className={classes.leftDivider}>{v.ValorViolacao}</span>
                                    </div>
                                </AlertCardItem>
                            ))}
                        </CardAlert>

                        <CardAlert icon={<ForwardNoFillIcon />} title={t(Labels.ALERT_VIOLATED_DETAILS_ACTIONS)}>
                            {alertDetails.Violacao.Acoes.map((a) => (
                                <AlertCardItem>{a.AcaoNome}</AlertCardItem>
                            ))}
                        </CardAlert>

                        <CardAlert icon={<CalendarDarkIcon />} title={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_TITLE)}>
                            <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_TYPE)}>
                                {alertDetails.Violacao.Evento.EventoTipo}
                            </AlertCardItem>
                            <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_DATE)}>
                                {alertDetails.Violacao.Evento.DataHoraEvento &&
                                    fromUtc(alertDetails.Violacao.Evento.DataHoraEvento).format("DD/MM/YYYY | HH:mm")}
                            </AlertCardItem>
                            <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_PLATE)}>
                                {alertDetails.ObjetoRastreavel.Placa}
                            </AlertCardItem>
                            <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_LOCALIZATION)}>
                                {Utils.formatCoordinates(alertDetails.Violacao.Evento.Gps.Latitude)},{" "}
                                {Utils.formatCoordinates(alertDetails.Violacao.Evento.Gps.Longitude)}
                            </AlertCardItem>
                            <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_ADDRESS)}>
                                {alertDetails.Violacao.Evento.RevGeo.Localizacao}
                            </AlertCardItem>
                            <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_DRIVER)}>
                                {alertDetails.Violacao.Evento.MotoristaNome}
                            </AlertCardItem>
                            <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_MARK)}>
                                {alertDetails.ObjetoRastreavel.Marca}
                            </AlertCardItem>
                            <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_MODEL)}>
                                {alertDetails.ObjetoRastreavel.Modelo}
                            </AlertCardItem>
                            <AlertCardItem legend={t(Labels.ALERT_VIOLATED_DETAILS_EVENT_VELOCITY)}>
                                {alertDetails.Violacao.Evento.Gps.Velocidade || 0} Km/h
                            </AlertCardItem>
                            <AlertCardItem legendWidth={"auto"}>
                                <div className={classes.dividerBox} style={{ padding: 0 }}>
                                    <span className={classes.middle}>
                                        <span className="legend">{t(Labels.ALERT_VIOLATED_DETAILS_IGNITION)}</span>
                                        <span
                                            className={`ignition ${
                                                alertDetails.Violacao.Evento.Ignicao === 1
                                                    ? "ignitionOn"
                                                    : "ignitionOff"
                                            }`}></span>
                                    </span>
                                    <span className={`${classes.leftDivider} ${classes.middle}`}>
                                        <span className="legend">{t(Labels.ALERT_VIOLATED_DETAILS_GPS)}</span>
                                        <span className="gps">
                                            {alertDetails.Violacao.Evento.Gps.Fixado === 1 ? (
                                                <CheckCircleIcon />
                                            ) : (
                                                <CloseCircleIcon />
                                            )}
                                        </span>
                                    </span>
                                </div>
                            </AlertCardItem>
                        </CardAlert>

                        <CardAlert
                            icon={<ArchiveIcon style={{ width: 14 }} />}
                            title={t(Labels.ALERT_VIOLATED_DETAILS_HISTORIC)}>
                            <AlertCardItem style={{ padding: 0 }}>
                                <Formik
                                    initialValues={{
                                        descricao: "",
                                    }}
                                    validate={(values) => {
                                        const errors = {};

                                        if (!values.descricao) {
                                            errors.descricao = t(Labels.ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED);
                                        }

                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                        const req = {
                                            descricao: values.descricao,
                                            alertaId: alertIdSelected,
                                            violacaoId: alertDetails.Id,
                                        };

                                        dispatch(
                                            alertsActions.saveHistory(req, (res) => {
                                                resetForm();
                                                setSubmitting(false);
                                                dispatch(alertReportAction.getHistory({ violacaoId: alertDetails.Id }));
                                            })
                                        );
                                    }}>
                                    {({ values, submitForm, setFieldValue, errors, touched }) => (
                                        <Form className="ceabs-register-form ceabs-register">
                                            <Grid container spacing={0} alignItems="stretch" justify="space-between">
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    margin="dense"
                                                    multiline
                                                    rowsMax={4}
                                                    inputProps={{
                                                        width: "100%",
                                                        height: "calc(100% - 30px)",
                                                        fontSize: 12,
                                                        maxLength: 120,
                                                    }}
                                                    name="descricao"
                                                    className="boxTextArea"
                                                />

                                                <Button
                                                    id="done-alert-submit-button"
                                                    variant="contained"
                                                    className="doneAlert"
                                                    color="primary"
                                                    size="large"
                                                    disableElevation
                                                    onClick={submitForm}
                                                    disabled={loading.pointInterest}
                                                    startIcon={
                                                        loading.pointInterest ? (
                                                            <CircularProgress size={18} />
                                                        ) : (
                                                            <ConfirmIcon />
                                                        )
                                                    }></Button>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </AlertCardItem>
                            <AlertCardItem style={{ padding: 0 }}>
                                <AccordionAlert
                                    title={t(Labels.ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED_CADASTRED_TITLE)}
                                    defaultExpanded>
                                    <div style={{ overflowY: "auto", height: 275, width: "100%" }}>
                                        {alertHistory.map((register) => (
                                            <div>
                                                <AlertCardItem legendWidth={"auto"}>
                                                    <div className={classes.dividerBox} style={{ padding: 0 }}>
                                                        <span>
                                                            <span>
                                                                {t(
                                                                    Labels.ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED_CADASTRED_USER
                                                                )}
                                                            </span>{" "}
                                                            {`${register.UsuarioNome}`}
                                                        </span>
                                                        <span className={classes.leftDivider}>
                                                            <span>
                                                                {t(
                                                                    Labels.ALERT_VIOLATED_DETAILS_HISTORIC_REQUIRED_CADASTRED_DATE
                                                                )}
                                                            </span>{" "}
                                                            {register.DataHoraCadastro &&
                                                                fromUtc(register.DataHoraCadastro).format("DD/MM/YYYY")}
                                                        </span>
                                                    </div>
                                                </AlertCardItem>
                                                <AlertCardItem
                                                    legend={t(Labels.ALERT_VIOLATED_DETAILS_ALERT_TREATMENT)}>
                                                    {register.Descricao}
                                                </AlertCardItem>
                                                <div />
                                            </div>
                                        ))}
                                    </div>
                                </AccordionAlert>
                            </AlertCardItem>
                        </CardAlert>

                        <Button
                            id="point-interest-submit-buttond"
                            variant="contained"
                            className="save-button alert-conclude-button"
                            color="primary"
                            size="large"
                            fullWidth
                            disableElevation
                            onClick={() => {
                                dispatch(
                                    alertsActions.markBoardDoneAlertsViolated(
                                        {
                                            idAlert: alertDetails.params.alertaId,
                                            board: alertDetails.params.placa,
                                            violacaoId: alertDetails.params.violacaoId,
                                        },
                                        (error) => {
                                            if (error) {
                                                Utils.showAllErrors(error);
                                                return;
                                            }

                                            Utils.showTranslatedToast({
                                                type: Constants.SUCCESS,
                                                description: Labels.ALERT_VIOLATED_MSG_SUCESSS,
                                            });

                                            dispatch(alertReportAction.cleanAlertDetails());
                                            dispatch(
                                                alertReportAction.updateFilterAlertPagination({
                                                    Length: 10,
                                                })
                                            );
                                        }
                                    )
                                );
                            }}
                            disabled={loading.pointInterest || !!alertDetails.Violacao.DataHoraConclusao}
                            startIcon={loading.pointInterest ? <CircularProgress size={18} /> : <ConfirmIcon />}>
                            {t(Labels.ALERT_VIOLATED_DETAILS_CONCLUDE)}
                        </Button>
                    </Grid>
                </Grid>
            ) : alertDetails.params?.error ? (
                <span style={{ color: "red" }}>{t(Labels.ALERT_GET_ERROR)}</span>
            ) : (
                <span style={{ color: "#3D5564" }}>{t(Labels.ALERT_NONE_SELECTED)}</span>
            )}
        </div>
    );
};

export default AlertDetails;
