import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Utils, { uniqueBy } from "~/helpers/Utils";
import routeAction from "~/actions/routeAction";
import googleMapsDataAction from "~/actions/googleMapsDataAction";
import { Box, IconButton } from "@material-ui/core";
import TableCardReUse from "~/components/tableCard/TableCardReUse";
import Loading from "~/components/common/loading/Loading";
import ModalCommon from "~/components/common/modal/Modal";
import { useTranslation } from "react-i18next";
import { ColoredEditIcon, IconDelete } from "~/components/common/icons/Icons";
import Labels from "~/helpers/enums/Labels";
import "./RoutesGrid.scss";

const RoutesGrid = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [idRowDelete, setIdRowDelete] = useState(null);

	const history = useHistory();

	const loading = useSelector((state) => state.app.loading);
	const { routes, objectsRoutes, filteredRoutes, searchField } = useSelector((state) => state.route);

	useEffect(() => {
		dispatch(
			googleMapsDataAction.updateObjects(
				uniqueBy(
					Object.values(objectsRoutes)
						.reduce((a, b) => [...a, ...b], [])
						.map((o) => {
							return {
								...o,
								Latitude: o.Lat,
								Longitude: o.Long,
							};
						}),
					(o) => o.Id
				)
			)
		);

		return () => {
			dispatch(googleMapsDataAction.cleanObjects());
		};
	}, [dispatch, objectsRoutes]);

	const ROUTES_COLUMNS = [
		{
			name: "Id",
			label: t(Labels.ROUTE_LIST_CODE),
			render: (code) => <span>{code}</span>,
		},
		{
			name: "Nome",
			label: t(Labels.ROUTE_LIST_NAME),
			render: (name) => <strong title={name}>{Utils.TextView(name, 25)}</strong>,
		},
		{
			name: "Id",
			label: t(Labels.ROUTE_LIST_ACTIONS),
			render: (Id) => (
				<span className="routes-action-column">
					<IconButton
						data-id={"editar-item-tabela-route-" + Id}
						onClick={() => {
							history.push(`/register/route/${Id}`);
						}}>
						<ColoredEditIcon
							fontSize="large"
							style={{
								strokeWidth: "30px",
								color: "#6ec8af",
								marginLeft: -10
							}}
						/>
					</IconButton>
					<IconButton
						data-id={"editar-item-tabela-route-" + Id}
						onClick={() => {
							setIdRowDelete(Id);
						}}>
						<IconDelete style={{ height: 17, width: "auto", marginBottom: 5, marginLeft: 5 }} />
					</IconButton>
				</span>
			),
		},
	];

	useEffect(() => {
		dispatch(routeAction.listRoutes());
		return () => {
			dispatch(routeAction.cleanRoutes());
		};
	}, [dispatch]);

	useEffect(() => {
		dispatch(routeAction.cleanObjectRoutes());

		return () => {
			dispatch(routeAction.cleanObjectRoutes());
			dispatch(googleMapsDataAction.cleanRoutes());
			dispatch(googleMapsDataAction.cleanObjects());
		};
	}, [dispatch]);

	return (
		<>
			<Box className="resultGridRoutes">
				{loading.gridRoutes ? (
					<Loading size={30} />
				) : (
					<TableCardReUse
						onSelect={(route) => {
							dispatch(routeAction.cleanObjectRoutes());
							dispatch(
								googleMapsDataAction.updateRoutes([
									{
										...route,
										value: route.Coordenadas,
									},
								])
							);

							const latlngs = Utils.getLatLngPathsFromString(route.Coordenadas);
							const first = latlngs[0];
							const last = latlngs[latlngs.length - 1];

							dispatch(
								routeAction.getObjectsPerRoutes(route.Id, {
									latitude: first.lat,
									longitude: first.lng,
								})
							);

							dispatch(
								routeAction.getObjectsPerRoutes(route.Id, {
									latitude: last.lat,
									longitude: last.lng,
								})
							);
						}}
						columns={ROUTES_COLUMNS}
						dataSource={searchField !== "" ? filteredRoutes : routes}
						backgroundChanged={props.backgroundChanged}
					/>
				)}
			</Box>
			<ModalCommon
				title={t(Labels.CONFIRM_EXCLUDE)}
				confirmDelete={() => {
					dispatch(
						routeAction.deleteRoute(idRowDelete, () => {
							dispatch(routeAction.listRoutes());
						})
					);
					setIdRowDelete(null);
				}}
				handleClose={() => {
					setIdRowDelete(null);
				}}
				flag={!!idRowDelete}
			/>
		</>
	);
};

export default RoutesGrid;
