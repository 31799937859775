import React from 'react';

import { fade, makeStyles } from '@material-ui/core';
import TabMain from '~/components/common/tab/TabMain'
import EditVehicle from '~/components/vehicle/listing/tabs/editVehicle/EditVehicle'
import Odometer from '~/components/vehicle/listing/tabs/odometer/Odometer'
import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTabs-root": {
            background: fade(theme.palette.primary.main, 0.15),
            padding: '20px 0px 0 15px'
        }
    },
}));

const TabsVehicles = ({ closeTab }) => {

    const { t } = useTranslation();
    const TABS = [
        {
            id: 0,
            label: t(Labels.VEHICLE_EDIT_FORM_TITLE),
            children: (<EditVehicle closeTab={closeTab} />)
        },
        {
            id: 1,
            label: t(Labels.VEHICLE_ODOMETER_FORM_TITLE),
            children: (<Odometer closeTab={closeTab} />)
        },
    ]

    const classes = useStyles();
    return (
        <div className="">
            <TabMain
                id="TabsVehicles"
                tabs={TABS}
                className={classes.root}
                classNameLabelRefactor="tab-label-refactor"
            />
        </div>
    );
}

export default TabsVehicles;
