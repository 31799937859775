import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    boxAlertCard: {
        border: "1px solid #AFC3D2",
        borderRadius: "5px",
        color: "#3D5564",
        background: "#fff",
        marginBottom: 20,
        "&:last-child": {
            marginBottom: 0,
        },
    },
    titleBox: {
        padding: 8,
        borderBottom: "1px solid #AFC3D2",
        borderRadius: "5px 5px 0 0",
        background: "#EEF2F5",
        cursor: "pointer",
    },
    titleIcon: {
        marginRight: 14,
        "& img": {
            position: "relative",
            top: 2,
            width: 18,
        },
    },
    contentBox: {
        borderRadius: "0 0 5px 5px",
        background: "#fff",
    },
    boxCardAlertItem: {
        display: "flex",
        minHeight: 33,
        padding: (props) => props.padding,
        borderBottom: "1px solid #AFC3D2",
        boxSizing: "border-box",
        "&:last-child": {
            borderBottom: "none !important",
        },
    },
    itemLegendBox: {
        width: (props) => 85,
        paddingRight: 8,
        color: "#AFC3D2",
    },
    itemContentBox: {
        flex: "3 1",
        color: "#3D5564",
    },
}));

const AlertCard = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.boxAlertCard}>
            <div className={classes.titleBox}>
                <span>
                    <span className={classes.titleIcon}>{props.icon}</span>
                    {props.title}
                </span>
            </div>
            <div className={classes.contentBox}>{props.children}</div>
        </div>
    );
};

export const AlertCardItem = (props) => {
    const styleProps = { legendWidth: props.legendWidth, padding: !!props.noPadding ? 0 : 8 };
    const classes = useStyles(styleProps);

    return (
        <div className={classes.boxCardAlertItem} style={props.style}>
            {props.legend && <div className={classes.itemLegendBox}>{props.legend}:</div>}
            <span className={classes.itemContentBox}>{props.children}</span>
        </div>
    );
};

export default AlertCard;
