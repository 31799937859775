import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
	route: null,
	routes: [],
	objectsRoutes: {},
	filteredRoutes: [],
	searchField: ""
};

function routeReducer(state = INITIAL_STATE, action = null) {
	switch (action.type) {
		case Constants.LIST_ROUTES:
			return {
				...state,
				routes: action.payload,
			};
		case Constants.CLEAN_ROUTES:
			return {
				...state,
				routes: INITIAL_STATE.routes,
			};

		case Constants.GET_OBJECTS_ROUTES:
			return {
				...state,
				objectsRoutes: {
					...state.objectsRoutes,
					[action.payload.id]: [...(state.objectsRoutes[action.payload.id] || []), ...action.payload.data],
				},
			};

		case Constants.DELETE_OBJECTS_ROUTES:
			return {
				...state,
				objectsRoutes: {
					...state.objectsRoutes,
					[action.payload]: [],
				},
			};

		case Constants.CLEAN_OBJECTS_ROUTES:
			return {
				...state,
				objectsRoutes: INITIAL_STATE.objectsRoutes,
			};
		case Constants.GET_ROUTES_BY_ID:
			return {
				...state,
				route: action.payload,
			};
		case Constants.CLEAN_ROUTE:
			return {
				...state,
				route: INITIAL_STATE.route,
			};
		case Constants.SEARCH_ROUTE_NAME: 
			return {
				...state,
				filteredRoutes: action.payload.filteredData,
				searchField: action.payload.searchField
			};
		case Constants.CLEAN_ROUTE_SEARCH:
			return {
				...state,
				filteredRoutes: [],
				searchField: ""
			}
		default:
			return state;
	}
}

export default routeReducer;
