import React, { useEffect, } from "react";
import ReactEcharts from "echarts-for-react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import "./KmRunPeriodDay.scss";
import { baseColors, veiculoColors } from "./KmRunPeriodDayFormatter";

import Labels from "~/helpers/enums/Labels";
import dashboardChartsAction from "~/actions/dashboardVehicle/dashboardChartsAction";
import { useTranslation } from "react-i18next";

export default function KmRunPeriodDayBar() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { waitingTravel } = useSelector((state) => state.app.loading);
    const { baseVehicle } = useSelector((state) => state.dashboardTravelsDay);
    const { rangeDate, vehicle } = useSelector((state) => state.dashboardVehicleFilter);

    useEffect(() => {
        if (!rangeDate.AnoInicio || !rangeDate.AnoFim) {
            return;
        }

        dispatch(
            dashboardChartsAction.getKmRunDay(
                { ObjetoRastreavelId: vehicle?.ObjetoRastreavelId, ...rangeDate },
                "waitingBaseDay"
            )
        );
    }, [dispatch, rangeDate, vehicle]);

    const getOption = () => {
        return {
            legend: {
                orient: "horizontal",
                icon: "circle",
                bottom: 0,
                align: "left",
                itemGap: 20,
                textStyle: {
                    fontSize: 15,
                },
            },
            tooltip: {},
            dataset: {
                source: baseVehicle,
            },
            xAxis: { type: "category" },
            yAxis: {
                axisLabel: {
                    formatter: "{value}",
                },
            },
            // Declare several bar series, each will be mapped
            // to a column of dataset.source by default.
            series: [
                {
                    type: "bar",
                    itemStyle: {
                        color: baseColors,
                    },
                },
                {
                    type: "bar",
                    itemStyle: {
                        color: veiculoColors,
                    },
                },
            ],
        };
    };
    return (
        <Box className="chart">
            <Box className="x-axis">
                <Typography variant="h4">{t(Labels.CHART_COMMON_KMRUN)}</Typography>
            </Box>
            <Box className="echart">
                <ReactEcharts
                    option={getOption()}
                    showLoading={waitingTravel}
                    loadingOption={{ text: "Carregando" }}
                    style={{ height: "100%", width: "100%" }}
                />
            </Box>
        </Box>
    );
}
