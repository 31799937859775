import React from "react";
import "./styles.scss";
import { useSelector } from "react-redux";
import CheckBox from "@material-ui/icons/Check";
import CircularProgress from '@material-ui/core/CircularProgress';

const PlanCard = ({ titleType, plan, planPlus, planCombo, onSelectPlan = () => {}, isLoading }) => {
    const {
        user: { colors },
    } = useSelector((state) => state.auth);

    function formatMoney(valor) {
        return valor?.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        });
    }

    function formatName(name) {
        if (name) {
            const values = name.split("/");
            return (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <h5 style={{ margin: 0, marginTop: "1px", fontStyle: "bold" }}>{values[0]}</h5>
                    <span>/{values[1]}</span>
                </div>
            );
        }
    }

    return (
        <div
            className="plan-card"
            style={{
                background:
                    "linear-gradient(to bottom right, #000000, #000000 40%," +
                    colors.topBarColor +
                    " 99%," +
                    colors.topBarColor +
                    ")",
            }}>
            <div className="header">
                <span className="title">{titleType}</span>
            </div>
            <div className="separete"></div>
            <div className="plan-section">
                <div style={{ "marginTop": "15px" }}>{formatName(plan?.Nome)}</div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}>
                    <h1 style={{ margin: 0 }}>{formatMoney(plan?.ValorMensal)}</h1>
                    <span>/mês por veículo</span>
                </div>
                <p className="desc">{plan?.Descricao}</p>
                <div className="list">
                    <ul>
                        {plan?.ProdutoContratoDescricao.map((item, index) => (
                            <li key={index}>
                                <CheckBox /> {item.Descricao}
                            </li>
                        ))}
                    </ul>
                </div>

                <button disabled={isLoading} onClick={() => onSelectPlan(plan?.ValorMensal, plan?.Vigencia, plan?.id)}>
                    {isLoading ? <CircularProgress size={20} /> : "Quero contratar"}
                </button>
            </div>
            <div className="separete"></div>
            <div className="plan-section">
                <div style={{ "marginTop": "15px" }}>{formatName(planPlus?.Nome)}</div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}>
                    <h1 style={{ margin: 0 }}>{formatMoney(planPlus?.ValorMensal)}</h1>
                    <span>/mês por veículo</span>
                </div>
                <p className="desc">{planPlus?.Descricao}</p>
                <div className="list">
                    <ul>
                        {planPlus?.ProdutoContratoDescricao.map((item, index) => (
                            <li key={index}>
                                <CheckBox /> {item.Descricao}
                                {item?.values && Array.isArray(item.values) && (
                                    <div className="list">
                                        <ul>
                                            {item.values.map((i, idx) => (
                                                <li key={idx}>
                                                    <CheckBox /> {i.Descricao}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                <button
                    disabled={isLoading}
                    onClick={() => onSelectPlan(planPlus?.ValorMensal, planPlus?.Vigencia, planPlus?.id)}>
                    {isLoading ? <CircularProgress size={20} /> : "Quero contratar"}
                </button>
            </div>
            <div className="separete"></div>
            <div className="plan-section">
                <div style={{ "marginTop": "15px" }}>{formatName(planCombo?.Nome)}</div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}>
                    <h1 style={{ margin: 0 }}>{formatMoney(planCombo?.ValorMensal)}</h1>
                    <span>/mês por veículo</span>
                </div>
                <p className="desc">{planCombo?.Descricao}</p>
                <div className="list">
                    <ul>
                        {planCombo?.ProdutoContratoDescricao.map((item, index) => (
                            <li key={index}>
                                <CheckBox /> {item.Descricao}
                            </li>
                        ))}
                    </ul>
                </div>
                <button
                    disabled={isLoading}
                    onClick={() => onSelectPlan(planCombo?.ValorMensal, planCombo?.Vigencia, planCombo?.id)}>
                    {isLoading ? <CircularProgress size={20} /> : "Quero contratar"}
                </button>
            </div>
        </div>
    );
};

const StepPlans = ({ onSelectPlan = () => {}, isLoading }) => {
    const planBase = {
        Nome: "RECUPERAÇÃO/stolen",
        Descricao: "Indicado para recuperação veicular",
        ProdutoContratoDescricao: [
            {
                Id: 1,
                Descricao: "Central de Ocorrência 24h",
            },
            {
                Id: 2,
                Descricao: "Pronta Resposta Nacional",
            },
            {
                Id: 3,
                Descricao: "Relatórios",
            },
        ],
    };

    const planPlus = {
        Nome: "FROTA/Fleet",
        Descricao: "Indicado para gestão de frota",
        ProdutoContratoDescricao: [
            {
                Id: 10,
                Descricao: "Rastreamento GSM",
                values: [],
            },
            {
                Id: 11,
                Descricao: "Relatórios Web",
                values: [
                    {
                        Id: 12,
                        Descricao: "Alertas",
                    },
                    {
                        Id: 13,
                        Descricao: "Eventos",
                    },
                    {
                        Id: 14,
                        Descricao: "Viagens",
                    },
                    {
                        Id: 15,
                        Descricao: "Distância Percorrida",
                    },
                    {
                        Id: 16,
                        Descricao: "Excesso de Velocidade",
                    },
                ],
            },
        ],
    };
    const planCombo = {
        Nome: "RECUPERAÇÃO + FROTA/Stolen + Fleet",
        Descricao: "Indicado para recuperação e gestão de frota",
        ProdutoContratoDescricao: [
            {
                Id: 31,
                Descricao: "Tudo de Frota",
            },
            {
                Id: 32,
                Descricao: "Tudo de Recuperação",
            },
        ],
    };
    return (
        <div className="step-plans">
            <PlanCard
                titleType="Plano Anual"
                plan={{ ...planBase, id: 1, ValorMensal: 45.0, Vigencia: 12 }}
                planPlus={{ ...planPlus, id: 4, ValorMensal: 45.0, Vigencia: 12 }}
                planCombo={{ ...planCombo, id: 7, ValorMensal: 72.0, Vigencia: 12 }}
                onSelectPlan={onSelectPlan}
                isLoading={isLoading}
            />
            <PlanCard
                titleType="Plano 2 Anos"
                plan={{ ...planBase, id: 2, ValorMensal: 40.0, Vigencia: 24 }}
                planPlus={{ ...planPlus, id: 5, ValorMensal: 40.0, Vigencia: 24 }}
                planCombo={{ ...planCombo, id: 8, ValorMensal: 64.0, Vigencia: 24 }}
                onSelectPlan={onSelectPlan}
                isLoading={isLoading}
            />
            <PlanCard
                titleType="Plano 3 Anos"
                plan={{ ...planBase, id: 3, ValorMensal: 35.0, Vigencia: 36 }}
                planPlus={{ ...planPlus, id: 6, ValorMensal: 35.0, Vigencia: 36 }}
                planCombo={{ ...planCombo, id: 9, ValorMensal: 56.0, Vigencia: 36 }}
                onSelectPlan={onSelectPlan}
                isLoading={isLoading}
            />
        </div>
    );
};

export default StepPlans;
